(function () {
    'use strict';

    angular.module('pages.admin').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.customer.admin',
                config: {
                    url: '/admin',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { anonymous_access: false, sidebar: 'admin' },
                    resolve: {
                        removeDomain: [
                            'coreDomainService',
                            'ng2MonEventsService',
                            function (coreDomainService, ng2MonEventsService) {
                                coreDomainService.setDomain(null);
                                coreDomainService.removeDomainGroup();
                                return ng2MonEventsService.run('changed-domain'); // Make sure that no domain is set when entering administrator pages
                            },
                        ],
                        isCustomerAdmin: [
                            '$filter',
                            '$state',
                            'ng2SessionService',
                            'ng2MonUIRouterTransitionService',
                            function ($filter, $state, ng2SessionService, ng2MonUIRouterTransitionService) {
                                var customer = ng2SessionService.customer;
                                if (!$filter('role')(['customer_admin']) || !customer || ng2SessionService.isLiteCustomer()) {
                                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('base.dashboard'),
                                    );
                                }
                            },
                        ],
                    },
                },
            },
        ];
    }
})();
