(function () {
    'use strict';
    /**
     * @memberof modules.globalPolicy
     * @ngdoc service
     * @name GlobalPolicyExchangeCenterTemplate
     * @description Get region details of current active region
     */
    angular
        .module('modules.globalPolicy')
        .service('GlobalPolicyExchangeCenterTemplate', GlobalPolicyExchangeCenterTemplate);

    GlobalPolicyExchangeCenterTemplate.$inject = ['gettextCatalog'];
    /* @ngInject*/
    function GlobalPolicyExchangeCenterTemplate (gettextCatalog) {
        var vm = this;
        function init () {
            setupSubjectIcons();
            setupVerticals();
            setupCountries();

            return {
                getSubjectIcon: getSubjectIcon,
                getVerticalIcon: getVerticalIcon,
                getVerticalDescription: getVerticalDescription,
                getVerticalBackground: getVerticalBackground,
                getVerticalColor: getVerticalColor,
                getCountryIcon: getCountryIcon,
                getCountryDescription: getCountryDescription,
            };
        }
        return init();

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param template - Template object from the POLICY_EXCHANGE_CENTER_DB
         * @return {string} - Subject class name (Icon class)
         */
        function getSubjectIcon (template) {
            return vm.subjectIcons[template.policy.subject];
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param vertical - Key name of vertical option
         * @return {string} - Vertical Icon class
         */
        function getVerticalIcon (vertical) {
            return vm.verticalIcons[vertical].icon;
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param vertical - Key name of vertical option
         * @return {string} - Vertical description (Tooltip description)
         */
        function getVerticalDescription (vertical) {
            return vm.verticalIcons[vertical].description;
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param vertical - Key name of vertical option
         * @return {string} - Vertical background color (Circle color)
         */
        function getVerticalBackground (vertical) {
            return vm.verticalIcons[vertical].backgroundColor;
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param vertical - Key name of vertical option
         * @return {string} - Vertical front icon color (Icon inside the circle icon)
         */
        function getVerticalColor (vertical) {
            return vm.verticalIcons[vertical].color;
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param country - Key name of country option (Currently region, but oh well..)
         * @return {string} - Country icon class
         */
        function getCountryIcon (country) {
            return vm.countries[country].icon;
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @param country - Key name of country option (Currently region, but oh well..)
         * @return {string} - Country Description (Tooltip description)
         */
        function getCountryDescription (country) {
            return vm.countries[country].description;
        }

        // PROTECTED

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @desc Setup subject icons
         */
        function setupSubjectIcons () {
            vm.subjectIcons = {
                page: 'fas fa-globe',
                page_html: 'far fa-copy',
                page_document: 'far fa-file-alt',
                link: 'far fa-link',
            };
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @desc Setup Vertical options
         */
        function setupVerticals () {
            vm.verticalIcons = {
                finance: {
                    description: gettextCatalog.getString('Finance'),
                    icon: 'far fa-money-bill-wave-alt',
                    backgroundColor: 'text-link',
                    color: 'fa-inverse',
                },
                education: {
                    description: gettextCatalog.getString('Education'),
                    icon: 'far fa-graduation-cap',
                    backgroundColor: 'text-purple',
                    color: 'fa-inverse',
                },
                government: {
                    description: gettextCatalog.getString('Government'),
                    icon: 'far fa-building',
                    backgroundColor: null,
                    color: 'fa-inverse',
                },
            };
        }

        /**
         * @memberOf GlobalPolicyExchangeCenterTemplate
         * @desc Setup Country options
         */
        function setupCountries () {
            vm.countries = {
                europe: {
                    description: gettextCatalog.getString('Europe'),
                    icon: 'fas fa-globe-europe',
                },
                africa: {
                    description: gettextCatalog.getString('Africa'),
                    icon: 'fas fa-globe-africa',
                },
                asia: {
                    description: gettextCatalog.getString('Asia'),
                    icon: 'fas fa-globe-asia',
                },
                north_america: {
                    description: gettextCatalog.getString('North America'),
                    icon: 'fas fa-globe-americas',
                },
            };
        }
    }
})();
