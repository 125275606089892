(function () {
    'use strict';

    angular.module('pages.qa.readability.summary').component('readabilitySummary', {
        templateUrl: 'app/pages/qa/readability/summary/summary.html',
        controller: ReadabilityController,
        controllerAs: 'vm',
    });

    ReadabilityController.$inject = [
        'ReadabilityRepo',
        '$filter',
        '$state',
        'activeFeatures',
        'gettextCatalog',
        'Lodash',
        'ng2MonUIRouterTransitionService',
    ];

    function ReadabilityController (
        ReadabilityRepo,
        $filter,
        $state,
        activeFeatures,
        gettextCatalog,
        Lodash,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.onFiltersChanges = onFiltersChanges;

        function activate () {
            if (!$filter('activeFeature')('readability')) {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
            }
            vm.summary = {};
            vm.isFiltersOpen = false;
            vm.showFilters = activeFeatures.table_filters;
            vm.filterType = null;
            vm.loading = false;
            vm.subheaderTranslation = gettextCatalog.getString('Readability scores across all web pages (based on');
            getSummary();
            setupFilters();
        }

        function getSummary () {
            var params = {
                type: vm.filterType,
            };
            vm.loading = true;
            ReadabilityRepo.summary(params)
                .then(function (data) {
                    vm.summary = data;
                    vm.summary.plain_distribution = vm.summary.distribution;
                    vm.summary.distribution = Object.keys(vm.summary.distribution).map(function (key) {
                        return { name: key, page_count: vm.summary.distribution[key] };
                    });
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function onFiltersChanges (changes) {
            if (changes[vm.filterTypesName].length > 0) {
                vm.filters[vm.filterTypesName] = [Lodash.last(changes[vm.filterTypesName])];
                vm.filterType = vm.filters[vm.filterTypesName][0].value;
            } else {
                vm.filterType = null;
            }
            getSummary();
        }

        function setupFilters () {
            vm.filterTypesName = gettextCatalog.getString('Type');
            vm.filters = {};
            vm.filters[vm.filterTypesName] = [];
            vm.availableFilters = {};
            vm.availableFilters[vm.filterTypesName] = [
                {
                    name: gettextCatalog.getString('HTML'),
                    value: 'html',
                },
                {
                    name: gettextCatalog.getString('PDF'),
                    value: 'pdf',
                },
                {
                    name: gettextCatalog.getString('Documents'),
                    value: 'document',
                },
            ];
        }
    }
})();
