import { $InjectorLike } from '@uirouter/core';

export const createAngularJsProvider = <T>(providerKey: string): {
    deps: ['$injector'];
    provide: string;
    useFactory: (injector: $InjectorLike) => T;
} => ({
    deps: ['$injector'],
    provide: providerKey,
    useFactory: (injector): T => injector.get<T>(providerKey),
});
