(function () {
    'use strict';

    angular.module('services.api').factory('PagePolicyRepo', SearchRepo);

    SearchRepo.$inject = ['ng2ApiService', 'ng2SessionService'];

    /* @ngInject */
    function SearchRepo (ng2ApiService, ng2SessionService) {
        return {
            getAll: getAll,
            update: update,
            destroy: destroy,
        };

        // /////////////

        function getAll (pageId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + pageId + '/policies/', params, config);
        }

        function update (pageId, data, params, config) {
            data = data || {};
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + pageId + '/policies/' + data.id,
                data,
                params,
                config,
            );
        }

        function destroy (pageId, id, config) {
            config = config || {};
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/pages/' + pageId + '/policies/' + id, config);
        }
    }
})();
