(function () {
    'use strict';

    angular.module('app.layout').component('submenuPolicy', {
        templateUrl: 'app/layout/submenu/submenu.template.html',
        controller: SubmenuController,
        controllerAs: 'vm',
    });

    SubmenuController.$inject = ['gettextCatalog', '$scope', '$filter', '$state', 'ng2MonEventsService'];
    function SubmenuController (gettextCatalog, $scope, $filter, $state, ng2MonEventsService) {
        var vm = this;

        vm.isSubentryActive = isSubentryActive;
        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.todos = 12;
            ng2MonEventsService.addListener('changed-domain', function () {
                getLinks();
            });
            getLinks();
        }

        // TODO: Tooltip parameter has been added, but does not work. This is a preparation for https://github.com/Monsido/frontend/issues/1843

        function isSubentryActive (destination) {
            return $state.current.name.indexOf(destination) > -1;
        }

        function getLinks () {
            vm.links = [
                {
                    notification: false,
                    name: gettextCatalog.getString('Global Policy Dashboard'),
                    section: 'base.customer.global.policy.dashboard',
                    href: 'base.customer.global.policy.dashboard',
                    icon: $filter('monIcons')(['MENUES', 'POLICIES']),
                    tooltip: gettextCatalog.getString('Global Policy Dashboard'),
                },
                {
                    notification: false,
                    name: gettextCatalog.getString('Policy List'),
                    section: 'base.customer.global.policy.policies',
                    href: 'base.customer.global.policy.policies',
                    icon: $filter('monIcons')(['POLICIES', 'LIST']),
                    tooltip: gettextCatalog.getString('Policy List'),
                    event: 'policy-list',
                },
            ];
        }
    }
})();
