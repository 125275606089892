(function () {
    'use strict';

    angular.module('services.api').factory('HeatmapRepo', HeatmapRepo);

    HeatmapRepo.$inject = ['ng2ApiService', 'HeatmapModel'];

    /* @ngInject */
    function HeatmapRepo (ng2ApiService, HeatmapModel) {
        return {
            getAll: getAll,
            create: create,
            update: update,
            destroy: destroy,
            getAllFromCustomer: getAllFromCustomer,
        };

        // /////////////

        function getAll (domain_id, params, headers) {
            params = params || {};
            return ng2ApiService.get('domains/' + domain_id + '/heatmaps_rules/', params, headers);
        }

        function create (domain_id, params, headers) {
            params = params || {};
            return ng2ApiService.post('domains/' + domain_id + '/heatmaps_rules/', params, headers);
        }

        function update (domain_id, rule, params, headers) {
            return destroy(domain_id, rule.id, params, headers).then(function (response) {
                var ruleParams = {
                    heatmap_type: rule.heatmap_type,
                    page_id: rule.page_id,
                };

                if (rule.heatmap_type.length > 0) {
                    return create(domain_id, ruleParams, headers);
                } else {
                    return response;
                }
            });
        }

        function destroy (domain_id, rule_id, params, headers) {
            params = params || {};
            return ng2ApiService.delete('domains/' + domain_id + '/heatmaps_rules/' + rule_id, params, headers);
        }

        function getAllFromCustomer (params, headers) {
            params = params || {};
            return ng2ApiService.get('heatmaps_rules/', params, headers).then(toModels);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new HeatmapModel(model);
        }
    }
})();
