(function () {
    'use strict';

    angular.module('models').factory('PageLinkModel', PageLinkModel);

    PageLinkModel.$inject = ['PAGE_FIX_FIXABLE_TYPES', 'PageFixModelHelperService'];
    function PageLinkModel (PAGE_FIX_FIXABLE_TYPES, PageFixModelHelperService) {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getPageFixType = getPageFixType;
        Model.prototype.getPageFixSelector = getPageFixSelector;

        return Model;

        // /////////

        function getPageFixType () {
            return PAGE_FIX_FIXABLE_TYPES.link;
        }

        function getPageFixSelector () {
            if (this.link.type === 'link') {
                return PageFixModelHelperService.getSelectorValue('<a href="' + this.link.url + '"></a>');
            } else {
                return PageFixModelHelperService.getSelectorValue('<img href="' + this.url + '"/>');
            }
        }
    }
})();
