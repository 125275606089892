(function () {
    'use strict';

    angular.module('pages.search').component('policyDashboard', {
        templateUrl: 'app/pages/search/policy.html',
        controller: PolicyOverviewController,
        controllerAs: 'vm',
    });

    PolicyOverviewController.$inject = ['Dialog', 'SearchRepo'];

    function PolicyOverviewController (Dialog, SearchRepo) {
        var vm = this;
        vm.addPolicy = addPolicy;
        vm.$onInit = activate;

        function activate () {
            vm.loading = true;
            vm.policies = [];
            getPolicies();
        }

        function getPolicies () {
            vm.loading = true;
            var params = {
                page_size: 1000,
            };

            SearchRepo.getAll(params).then(function (data) {
                vm.policiesGroupedByType = sortPoliciesByType(data);
                vm.policiesGroupedByPriority = sortPoliciesByPriority(data);
                vm.loading = false;
            }, angular.noop);
        }

        function addPolicy () {
            var dialog = Dialog.create('formPolicy', 'md', {});
            if (dialog) {
                dialog.closePromise.then(function () {
                    getPolicies();
                }, angular.noop);
            }
        }

        function sortPoliciesByType (policies) {
            var unwantedPolicies = [];
                var requiredPolicies = [];
                var matchesPolicies = [];

            for (var i = 0; i < policies.length; i++) {
                if (policies[i].is_errors && policies[i].mode === 1) {
                    unwantedPolicies.push(policies[i]);
                } else if (policies[i].is_errors && policies[i].mode === 2) {
                    requiredPolicies.push(policies[i]);
                } else if (!policies[i].is_errors) {
                    matchesPolicies.push(policies[i]);
                }
            }

            return {
                unwantedPolicies: unwantedPolicies,
                requiredPolicies: requiredPolicies,
                matchesPolicies: matchesPolicies,
                total: policies.length,
            };
        }

        function sortPoliciesByPriority (policies) {
            var lowPriorityPolicies = [];
                var mediumPriorityPolicies = [];
                var highPriorityPolicies = [];
                var nonePriorityPolicies = [];

            for (var i = 0; i < policies.length; i++) {
                switch (policies[i].priority) {
                    case 'low':
                        lowPriorityPolicies.push(policies[i]);
                        break;
                    case 'medium':
                        mediumPriorityPolicies.push(policies[i]);
                        break;
                    case 'high':
                        highPriorityPolicies.push(policies[i]);
                        break;
                    case null:
                        nonePriorityPolicies.push(policies[i]);
                        break;
                    case 'not':
                        nonePriorityPolicies.push(policies[i]);
                        break;
                }
            }

            return {
                lowPriorityPolicies: lowPriorityPolicies,
                mediumPriorityPolicies: mediumPriorityPolicies,
                highPriorityPolicies: highPriorityPolicies,
                nonePriorityPolicies: nonePriorityPolicies,
                total: policies.length,
            };
        }
    }
})();
