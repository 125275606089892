(function () {
    'use strict';

    angular.module('models').factory('LinkModel', LinkModel);

    LinkModel.$inject = ['PAGE_FIX_FIXABLE_TYPES', 'PageFixModelHelperService'];
    function LinkModel (PAGE_FIX_FIXABLE_TYPES, PageFixModelHelperService) {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getPageFixType = getPageFixType;
        Model.prototype.getPageFixSelector = getPageFixSelector;

        return Model;

        // /////////

        function getPageFixType () {
            return PAGE_FIX_FIXABLE_TYPES.link;
        }

        function getPageFixSelector () {
            if (this.type === 'link') {
                return PageFixModelHelperService.getSelectorValue('<a href="' + this.url + '"></a>');
            } else {
                return PageFixModelHelperService.getSelectorValue('<img src="' + this.url + '"/>');
            }
        }
    }
})();
