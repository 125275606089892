(function () {
    'use strict';

    angular.module('modules.shortcut').component('formShortcut', {
        templateUrl: 'app/modules/shortcut/forms/shortcut.html',
        controller: FormShortcutController,
        controllerAs: 'vm',
        bindings: {
            shortcut: '<',
            monDialogId: '@',
            setForm: '&?',
        },
    });

    FormShortcutController.$inject = [
        'monDialog',
        'ng2ToastService',
        'gettextCatalog',
        '$timeout',
        'ng2SessionService',
        'ng2MonModalService',
        'ShortcutService',
        'shortcutService',
    ];

    /* @ngInject */
    function FormShortcutController (
        monDialog,
        toastService,
        gettextCatalog,
        $timeout,
        ng2SessionService,
        ng2MonModalService,
        ShortcutService,
        shortcutService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.removeShortcut = removeShortcut;
        vm.showDelete = showDelete;
        vm.changeIconType = changeIconType;

        function activate () {
            vm.initializing = true;
            vm.urlTest = /^(http|https):\/\/[^ "]+$/;
            vm.ngModel = vm.shortcut || {};
            vm.ngModel.url_target = vm.ngModel.url_target || '_blank';
            vm.ngModel.icon_type = vm.ngModel.icon_type || 'icon';
            vm.saving = false;
            vm.deleting = false;
            vm.icons = ShortcutService.getPreSelectedIcons();
            vm.targets = ShortcutService.getUrlTargets();
            setupIconTypes();
            $timeout(function () {
                if (typeof vm.setForm === 'function') {
                    vm.setForm({ id: vm.monDialogId, form: vm.dialogForm });
                }
            });
        }

        function submit () {
            var promise;
            vm.saving = true;
            if (ng2SessionService.hasRole('customer_admin')) {
                // Only admin can set private setting
                vm.ngModel.private = Boolean(vm.ngModel.private);
            } else {
                vm.ngModel.private = true;
            }

            if (typeof vm.ngModel.id === 'undefined') {
                promise = shortcutService.create(vm.ngModel);
            } else {
                promise = shortcutService.update(vm.ngModel);
            }

            promise
                .then(
                    function (shortcut) {
                        if (vm.dialogForm) {
                            vm.dialogForm.$setPristine();
                        }
                        toastService.success(gettextCatalog.getString('Shortcut Saved'));
                        closeDialog(shortcut);
                    },
                    function () {
                        toastService.error(gettextCatalog.getString('Something went wrong'));
                    },
                )
                .then(function () {
                    vm.saving = false;
                });
        }

        function removeShortcut () {
            var params = {
                shortcutName: '<strong>' + vm.shortcut.title + '</strong>',
            };
            var translation = gettextCatalog.getString('Are you sure you wan to delete the shortcut {{ shortcutName }}', params);
            ng2MonModalService.confirm(translation).then(function () {
                vm.deleting = true;
                shortcutService
                    .destroy(vm.shortcut.id)
                    .then(function () {
                        toastService.error(gettextCatalog.getString('Shortcut deleted'));
                        closeDialog(vm.shortcut);
                    })
                    .finally(function () {
                        vm.deleting = false;
                    });
            }, angular.noop);
        }

        function showDelete () {
            if (typeof vm.shortcut !== 'undefined' && vm.shortcut.id > 0) {
                if (ng2SessionService.hasRole('customer_admin')) {
                    return true;
                } else {
                    return vm.shortcut.private;
                }
            }

            return false;
        }

        function changeIconType () {
            if (vm.initializing) {
                vm.initializing = false;
                return;
            }
            vm.ngModel.icon_uri = '';
        }

        // PROTECTED

        function setupIconTypes () {
            vm.iconTypes = [
                {
                    name: gettextCatalog.getString('Icon'),
                    value: 'icon',
                },
                {
                    name: gettextCatalog.getString('URI'),
                    value: 'uri',
                },
            ];
        }

        function closeDialog (data) {
            monDialog.close(vm.monDialogId, data);
        }
    }
})();
