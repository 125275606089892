import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.uptimes').component('uptimeShow', {
        templateUrl: 'app/pages/uptimes/uptime-show/uptime-show.html',
        controller: UptimeShowController,
        controllerAs: 'vm',
        bindings: {
            checkpoints: '<',
        },
    });

    UptimeShowController.$inject = [
        'UptimeRepo',
        '$stateParams',
        'moment',
        'gettextCatalog',
        'RESPONSE_TIME',
        '$filter',
        '$state',
        'ng2MonEventsService',
        'Lodash',
        'FORM_CONSTANT',
        'dateRangeService',
        'ng2MonUIRouterTransitionService',
        'DATE_FORMAT',
    ];

    /* @ngInject */
    function UptimeShowController (
        UptimeRepo,
        $stateParams,
        moment,
        gettextCatalog,
        RESPONSE_TIME,
        $filter,
        $state,
        ng2MonEventsService,
        Lodash,
        FORM_CONSTANT,
        dateRangeService,
        ng2MonUIRouterTransitionService,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.showCheckpointList = showCheckpointList;
        vm.uptimeCreate = uptimeCreate;
        vm.uptimeEdit = uptimeEdit;
        vm.duration = duration;
        vm.updateChartRange = updateChartRange;
        vm.onDateChange = onDateChange;
        vm.openScheduleDowntime = openScheduleDowntime;
        vm.showApology = showApology;

        function activate () {
            vm.uptimeId = $stateParams.uptimeId;
            vm.downtimes = 0;
            vm.avgResponseTime = 0;
            vm.uptime = [];
            vm.lastresultReport = false;
            vm.noResult = 0;
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.downtimesList = [];
            vm.sortedDownTimesList = [];
            setupDatePicker();
            setupLineChart();
            getUptime();
            getScheduledDowntimes();
            var category_ref_startDate = moment(vm.date.startDate._d).format('YYYY-MM-DD');
            var category_ref_endDate = moment(vm.date.endDate._d).format('YYYY-MM-DD');
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export list of downtimes for the domain'),
                    name: gettextCatalog.getString('Heartbeat Downtime'),
                    data: {
                        category: 'heartbeat_downtime',
                        category_ref: category_ref_startDate + ',' + category_ref_endDate + ',' + vm.uptimeId,
                    },
                },
                {
                    tooltip: gettextCatalog.getString('Export list of response times'),
                    name: gettextCatalog.getString('Heartbeat Response Time'),
                    data: {
                        category: 'heartbeat_response_time',
                        category_ref: category_ref_startDate + ',' + category_ref_endDate + ',' + vm.uptimeId,
                    },
                },
            ];
        }

        function getScheduledDowntimes () {
            var params = {
                page_size: 1,
                page: 1,
            };

            vm.allScheduledDowntimes = 0;

            UptimeRepo.getAllScheduledDowntimes(vm.uptimeId, params).then(function (scheduledDowntimes) {
                vm.allScheduledDowntimes = parseInt(scheduledDowntimes.total, 10);
            }, angular.noop);
        }

        function getUptime () {
            vm.loading = true;

            var params = {
                start_date: vm.date.startDate.format(),
                end_date: vm.date.endDate.format(),
            };

            UptimeRepo.get(vm.uptimeId, { headers: { noGlobal: true } }).then(function (uptime) {
                vm.uptime = uptime;
            }, angular.noop);

            vm.promise = UptimeRepo.getReport(vm.uptimeId, params, { headers: { noGlobal: true } })
                .then(function (data) {
                    vm.report = data;
                    vm.downtimes = data.downtimes_count;
                    vm.avgResponseTime = Math.round(data.avg_response_time);
                    vm.lastresultReport = data.last_result;
                })
                .then(
                    function () {
                        params = {
                            start_date: vm.date.startDate.format(),
                            end_date: vm.date.endDate.format(),
                            page_size: 0,
                        };

                        return UptimeRepo.getDowntimes(vm.uptimeId, params).then(function (downTimesData) {
                            vm.downtimesList = downTimesData;
                            vm.sortedDownTimesList = vm.downtimesList.sort(function (a, b) {
                                var dateA = new Date(a.start_at);
                                var dateB = new Date(b.start_at);
                                if (dateA > dateB) {
                                    return -1;
                                }
                                if (dateA < dateB) {
                                    return 1;
                                }
                                return 0;
                            });
                            setupHeartBeat();
                            vm.loading = false;
                        }, angular.noop);
                    },
                    function () {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.uptimes.index'),
                        );
                    },
                );
        }

        function setupHeartBeat () {
            vm.lineChartLabels = [];
            vm.lineChartData[0] = new Array(vm.report.data.length);
            vm.lineChartData[1] = [];
            vm.lineChartData[2] = [];

            vm.report.data.forEach(function (data) {
                vm.lineChartLabels.push(moment(data.timestamp).format('MMM DD LT'));
                vm.lineChartData[1].push(data.avg);
                vm.lineChartData[2].push(vm.avgResponseTime);
            });

            vm.downtimesList.forEach(function (data) {
                var startAt = moment(data.start_at).startOf('hour')
                    .format('MMM DD LT');
                var downtimeIndex = Lodash.findIndex(vm.lineChartLabels, function (date) {
                    return date === startAt;
                });
                if (downtimeIndex === -1) {
                    downtimeIndex = Lodash.findIndex(vm.lineChartLabels, function (date) {
                        return moment(date, 'MMM DD LT').isAfter(moment(data.start_at));
                    });
                    if (downtimeIndex === -1) {
                        downtimeIndex = vm.lineChartLabels.length;
                        vm.lineChartLabels.push(startAt);
                        vm.lineChartData[0].push(0);
                        vm.lineChartData[1].push(0);
                        vm.lineChartData[2].push(vm.avgResponseTime);
                    } else {
                        vm.lineChartLabels.splice(downtimeIndex, 0, startAt);
                    }
                }

                vm.lineChartData[0][downtimeIndex] = moment(data.end_at).diff(moment(data.start_at), 'minutes') || 1;
            });

            updateChartRange();
        }

        function showCheckpointList () {
            var params = {
                body: 'incidentList',
                size: 'sm',
                data: {
                    uptime: vm.uptime,
                    startDate: vm.date.startDate.format(),
                    endDate: vm.date.endDate.format(),
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openScheduleDowntime () {
            var params = {
                body: FORM_CONSTANT.FORM_UPTIME_SCHEDULED_DOWNTIMES,
                size: 'md',
                data: { uptimeId: vm.uptimeId },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getScheduledDowntimes });
        }

        function uptimeCreate () {
            const params = { body: 'uptime', data: { size: 'md' } };
            const callback = function (uptime) {
                if (angular.isObject(uptime)) {
                    ng2MonUIRouterTransitionService.onTransitionFinished(
                        () => $state.go('base.customer.domain.uptimes.index'),
                    );
                }
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function duration (start, end) {
            var tmpStart = moment(start);
            if (!end) {
                return moment.duration(moment() - tmpStart).humanize();
            }

            var tmpEnd = moment(end);
            return moment.duration(tmpEnd - tmpStart).humanize();
        }

        function uptimeEdit (uptime) {
            const params = { body: 'uptime', data: { uptime: uptime, size: 'md' } };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getUptime });
        }

        function updateChartRange () {
            // vm.lineChartOptions.scales.xAxes[0].time.min = moment(vm.date.startDate);
            // vm.lineChartOptions.scales.xAxes[0].time.max = moment(vm.date.endDate);
        }

        function onDateChange () {
            getUptime();
            var category_ref_startDate = moment(vm.date.startDate._d).format('YYYY-MM-DD');
            var category_ref_endDate = moment(vm.date.endDate._d).format('YYYY-MM-DD');
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export list of downtimes for the domain'),
                    name: gettextCatalog.getString('Heartbeat Downtime'),
                    data: {
                        category: 'heartbeat_downtime',
                        category_ref: category_ref_startDate + ',' + category_ref_endDate + ',' + vm.uptimeId,
                    },
                },
                {
                    tooltip: gettextCatalog.getString('Export list of response times'),
                    name: gettextCatalog.getString('Heartbeat Response Time'),
                    data: {
                        category: 'heartbeat_response_time',
                        category_ref: category_ref_startDate + ',' + category_ref_endDate + ',' + vm.uptimeId,
                    },
                },
            ];
        }

        function setupLineChart () {
            vm.lineChartSeries = [
                gettextCatalog.getString('Incidents'),
                gettextCatalog.getString('Response time'),
                gettextCatalog.getString('Avg. response'),
            ];
            vm.lineChartLabels = [];
            vm.lineChartColors = ['#e76468', '#249ff9', '#dc7ec7'];
            vm.lineChartData = [];

            vm.lineChartOptions = {
                scales: {
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            // type: "linear",
                            gridLines: {
                                offsetGridLines: true,
                                display: false,
                            },
                            ticks: {
                                autoSkip: false,
                                callback: function (date, index) {
                                    var difference = Math.abs(vm.date.endDate.diff(vm.date.startDate, 'hour'));
                                    var result = Math.ceil(difference / 90);
                                    return index % result === 0 ? date : '';
                                },
                            },
                        },
                        {
                            id: 'x-axis-1',
                            barPercentage: 0.1,
                            categoryPercentage: 0.5,
                            barThickness: 1,
                            display: false,
                        },
                    ],
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Response time (Milliseconds)'),
                                fontStyle: 500,
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                        {
                            id: 'y-axis-1',
                            position: 'right',
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Downtime (Minutes)'),
                                fontStyle: 500,
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: function (value, index, values) {
                                    var maxSteps = 10;
                                    if (values.length < maxSteps) {
                                        return value;
                                    }
                                    var divider = (values.length - 1) / maxSteps || 0;
                                    var indexes = [];
                                    values.forEach(function (val, ind) {
                                        if (ind === 0 || Math.round(indexes.length * divider) === ind) {
                                            indexes.push(ind);
                                        }
                                    });

                                    if (indexes.indexOf(index) > -1) {
                                        return $filter('numeraljs')(value, '0,0');
                                    }
                                },
                                maxTicksLimit: 3,
                                stepSize: 10,
                            },
                        },
                    ],
                },
                backgroundAreas: [
                    {
                        range: [RESPONSE_TIME.SECONDS.OK.FROM, RESPONSE_TIME.SECONDS.OK.TO], // Area percentage: 0% to 25%, fill the background with #def7e7 color
                        color: RESPONSE_TIME.SECONDS.OK.GRAPHCOLOR,
                    },
                    {
                        range: [RESPONSE_TIME.SECONDS.WARNING.FROM, RESPONSE_TIME.SECONDS.WARNING.TO],
                        color: RESPONSE_TIME.SECONDS.WARNING.GRAPHCOLOR,
                    },
                    {
                        range: [RESPONSE_TIME.SECONDS.ERROR.FROM, RESPONSE_TIME.SECONDS.ERROR.TO],
                        color: RESPONSE_TIME.SECONDS.ERROR.GRAPHCOLOR,
                    },
                ],
                legend: {
                    display: true,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: function (item, data) {
                            var dataset = data.datasets[item.datasetIndex];
                            var value = $filter('numeraljs')(dataset.data[item.index] || 0, '0,0');
                            if (vm.lineChartSeries[0] === dataset.label) {
                                // Downtime label
                                return value + ' ' + gettextCatalog.getString('minutes');
                            } else {
                                // Response time label
                                return value + ' ' + gettextCatalog.getString('milliseconds');
                            }
                        },
                    },
                },
            };

            vm.overrideDatasets = [
                {
                    type: 'bar',
                    backgroundColor: vm.lineChartColors[0],
                    yAxisID: 'y-axis-1',
                    xAxisID: 'x-axis-1',
                },
                {
                    type: 'line',
                    backgroundColor: vm.lineChartColors[1],
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    fill: false,
                },
                {
                    type: 'line',
                    backgroundColor: vm.lineChartColors[2],
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    fill: false,
                },
            ];

            for (var k = 0; k < vm.lineChartSeries.length; k++) {
                vm.lineChartData[k] = [];
            }
        }

        function showApology () {
            var startRange = moment('2018-10-06', 'YYYY-MM-DD');
            var endRange = moment('2018-10-10', 'YYYY-MM-DD');
            return (
                $filter('activeRegion')('US') &&
                (startRange.isBetween(vm.date.startDate, vm.date.endDate) || endRange.isBetween(vm.date.startDate, vm.date.endDate))
            );
        }

        // PROTECTED

        function setupDatePicker () {
            vm.datePickerOptions = {
                maxDate: moment().toDate(),
                ranges: {
                    'Last Week': [moment().subtract(7, 'days')
                        .startOf('week'), moment().subtract(7, 'days')
                        .endOf('week')],
                    'Last Month': [moment().subtract(1, 'month')
                        .startOf('month'), moment().subtract(1, 'month')
                        .endOf('month')],
                    'Last Quarter': [moment().subtract(1, 'quarter')
                        .startOf('quarter'), moment().subtract(1, 'quarter')
                        .endOf('quarter')],
                    'Last Half Year': [
                        moment().subtract(2, 'quarter')
                            .startOf('quarter'),
                        moment().subtract(1, 'quarter')
                            .endOf('quarter'),
                    ],
                    'Last Year': [moment().subtract(1, 'year')
                        .startOf('year'), moment().subtract(1, 'year')
                        .endOf('year')],
                },
            };
        }
    }
})();
