(function () {
    'use strict';

    angular.module('pages.global.users').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.global.users',
                config: {
                    url: '/users',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - User List') },
                    resolve: {
                        isCustomerAdmin: [
                            '$filter',
                            '$state',
                            'ng2MonUIRouterTransitionService',
                            function ($filter, $state, ng2MonUIRouterTransitionService) {
                                // Redirect user if he or she does not have access to this page
                                if (!$filter('role')(['customer_admin'])) {
                                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('base.dashboard'),
                                    );
                                }
                            },
                        ],
                    },
                },
            },
            {
                state: 'base.customer.global.users.index',
                config: {
                    url: '',
                    template: '<pages-users></pages-users>',
                    data: {},
                },
            },
            {
                state: 'base.customer.global.users.user',
                config: {
                    url: '/{user_id}',
                    template: '<pages-user></pages-user>',
                    data: {},
                },
            },
        ];
    }
})();
