(function () {
    'use strict';
    angular.module('pages.search.content').component('policiesContent', {
        templateUrl: 'app/pages/search/content/content.html',
        controller: PoliciesContentController,
        controllerAs: 'vm',
    });

    PoliciesContentController.$inject = ['gettextCatalog', 'SearchRepo', 'Dialog', '$timeout', '$controller'];

    function PoliciesContentController (gettextCatalog, SearchRepo, Dialog, $timeout, $controller) {
        var vm = this;
        vm.getPage = getPage;
        vm.addPolicy = addPolicy;
        vm.$onInit = activate;
        vm.$onDestroy = destroyTimer;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.policies = [];

            vm.pages = [];
            vm.tab = 'All';
            vm.tabsArray = [
                {
                    name: gettextCatalog.getString('All'),
                    value: 'All',
                },
                {
                    name: gettextCatalog.getString('Unwanted'),
                    value: 'unwanted',
                },
                {
                    name: gettextCatalog.getString('Required'),
                    value: 'required',
                },
                {
                    name: gettextCatalog.getString('Search'),
                    value: 'search',
                },
                {
                    name: gettextCatalog.getString('Scheduled'),
                    value: 'Only scheduled',
                },
                {
                    name: gettextCatalog.getString('Not scheduled'),
                    value: 'Not scheduled',
                },
            ];

            vm.currentSort = {
                by: 'pages_count',
                direction: 'desc',
            };

            getPage(true);
            startAutoReload();
        }

        function startAutoReload () {
            vm.timerPromise = $timeout(function () {
                getPage(false);
                startAutoReload();
            }, 30000);
        }

        function addPolicy () {
            var dialog = Dialog.create('formPolicy', 'md', {});
            if (dialog) {
                dialog.closePromise.then(function () {
                    getPage(false);
                }, angular.noop);
            }
        }

        function destroyTimer () {
            if (typeof vm.timerPromise === 'object') {
                $timeout.cancel(vm.timerPromise);
            }
        }

        function getPage (returnPromise) {
            var params = {
                page: vm.page,
                search: vm.search,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            returnPromise = returnPromise === false ? false : true;

            switch (vm.activeTab) {
                case 'unwanted':
                    params.is_errors = true;
                    params.mode = 1;
                    break;
                case 'required':
                    params.is_errors = true;
                    params.mode = 2;
                    break;
                case 'search':
                    params.is_errors = 'false';
                    params.mode = null;
                    break;
                case 'Only scheduled':
                    params.scheduled = true;
                    break;
                case 'Not scheduled':
                    params.scheduled = 'false';
                    break;
            }

            var promise = SearchRepo.getAll(params).then(function (data) {
                vm.policies = data;
            }, angular.noop);
            if (returnPromise !== false) {
                vm.promise = promise;
            }
        }
    }
})();
