(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardWcag22', {
        templateUrl: 'app/pages/accessibility/dashboard/wcag22/wcag22.html',
        controller: AccessibilityDashboardWCAG22Controller,
        controllerAs: 'vm',
    });

    AccessibilityDashboardWCAG22Controller.$inject = [
        'ng2SessionService',
        'AccessibilityRepo',
        'CrawlsRepo',
        'AccessibilityChecksRepo',
        '$q',
        'rx',
        '$timeout',
        'AccessibilityResultTypeService',
        'ChangesHistoryService',
        'ng2ActiveFeatureService',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function AccessibilityDashboardWCAG22Controller (
        ng2SessionService,
        AccessibilityRepo,
        CrawlsRepo,
        AccessibilityChecksRepo,
        $q,
        rx,
        $timeout,
        AccessibilityResultTypeService,
        ChangesHistoryService,
        ng2ActiveFeatureService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.selectItem = selectItem;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;
        vm.onToggleAction = onToggleAction;

        function activate () {
            vm.pdfscan = ng2SessionService.domain.scan.scan_documents;
            vm.summary = null;
            vm.history = [];
            vm.accessibilityChecks = [];
            vm.total = 0;
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.dropdownOptions = AccessibilityResultTypeService.getResultTypeOptions().map(option => {
                return {
                    ...option,
                    action: () => {
                        vm.selectItem(option);
                        ng2MonEventsService.run('actionTrigger',
                            {
                                action: 'accessibility-diagnostic-show-filter-select',
                                params: { selected: option.value },
                            });
                    },
                };
            });
            vm.selectedObservable = new rx.BehaviorSubject(null);

            var promises = [getHistory(), getAccessibilityChecks(), getSummary()];
            vm.progress = $q.all(promises).then(function (values) {
                vm.summary = values[2];
                vm.accessibilityChecks = values[1];
                vm.history = values[0]
                    .filter(function (crawl) {
                        return angular.isObject(crawl.accessibility_checks) && crawl.accessibility_checks.hasOwnProperty('A');
                    })
                    .reverse();
                selectItem(vm.dropdownOptions[0]);
                getAccessibilityChecksExcludeIgnored();
            }, angular.noop);
        }

        function onToggleAction () {
            ng2MonEventsService.run('actionTrigger', { action: 'accessibility-diagnostic-show-filter' });
        }

        function getSummary () {
            return AccessibilityRepo.getSummary();
        }

        function getHistory () {
            var params = {
                page_size: 5,
            };
            return CrawlsRepo.getAll(params);
        }

        function getAccessibilityChecks () {
            return AccessibilityChecksRepo.getAll();
        }

        function selectItem (item) {
            vm.selected = item;
            $timeout(function () {
                vm.selectedObservable.onNext(vm.selected.value);
            });
        }

        function getHistoryCenterParameters (module) {
            return ChangesHistoryService.getHistoryCenterParameters(module);
        }

        function getAccessibilityChecksExcludeIgnored () {
            vm.total = AccessibilityResultTypeService.getAccessibilityChecksExcludeIgnored(vm.accessibilityChecks);
        }
    }
})();
