(function () {
    'use strict';

    angular.module('modules.heatmaps').component('heatmapsDialogsPages', {
        templateUrl: 'app/modules/heatmaps/dialogs/pages/pages.html',
        controller: HeatmapsDialogsPagesController,
        controllerAs: 'vm',
        bindings: {
            rules: '<',
            selectedRule: '<?',
            close: '&',
            show: '<?',
        },
    });

    HeatmapsDialogsPagesController.$inject = [
        'PageRepo',
        'Lodash',
        'HeatmapApiService',
        '$q',
        'HEATMAPS_HEATMAP_TYPES',
        'ng2SessionService',
    ];

    /* @ngInject */
    function HeatmapsDialogsPagesController (PageRepo, Lodash, HeatmapApiService, $q, HEATMAPS_HEATMAP_TYPES, ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.hasHeatmapRule = hasHeatmapRule;
        vm.selectPage = selectPage;
        vm.closeDialog = closeDialog;

        function activate () {
            vm.show = vm.show || false;
            vm.pageLengthLimit = 3;
            vm.pages = [];
            vm.loading = true;
            vm.search = '';
            vm.domains = [];
        }

        function getPage () {
            if ((typeof vm.search !== 'string' || vm.search.length === 0) && vm.search.length <= vm.pageLengthLimit) {
                vm.pages = [];
                vm.pages.total = 0;
                return;
            }

            var params = {
                page: 1,
                page_size: 100,
                search: vm.search,
                type: 'html',
                sort_by: 'url',
                sort_dir: 'asc',
            };

            vm.loading = true;
            vm.progress = PageRepo.allExistingPages(params)
                .then(function (pages) {
                    vm.pages = pages;
                    mapDomains(pages);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function hasHeatmapRule (page) {
            return Lodash.some(vm.rules, function (rule) {
                return rule.page !== null && rule.page.id === page.id;
            });
        }

        function selectPage (page) {
            var defer = $q.defer();
            var params = {
                page_id: page.id,
                heatmap_type: [HEATMAPS_HEATMAP_TYPES.scroll, HEATMAPS_HEATMAP_TYPES.movement, HEATMAPS_HEATMAP_TYPES.click],
            };
            if (vm.selectedRule) {
                HeatmapApiService.destroy(vm.selectedRule.domain_id, vm.selectedRule.id)
                    .then(angular.noop, angular.noop)
                    .finally(function () {
                        defer.resolve();
                    });
            } else {
                defer.resolve();
            }

            defer.promise.then(function () {
                HeatmapApiService.create(page.domain_id, params).then(function (rule) {
                    closeDialog(rule);
                }, angular.noop);
            });
        }

        function closeDialog (rule) {
            vm.search = '';
            vm.show = false;
            vm.close({ rule: rule });
        }

        function mapDomains (pages) {
            pages.map(function (page) {
                var target = ng2SessionService.domains.value.find(function (domain) {
                    return domain.id === page.domain_id;
                });
                if (target) {
                    vm.domains[page.id] = target;
                }
            });
        }
    }
})();
