(function () {
    'use strict';

    angular.module('services.api').factory('AccessibilityChecksRepo', AccessibilityChecksRepo);

    AccessibilityChecksRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'AccessibilityCheckModel'];
    /* @ngInject */
    function AccessibilityChecksRepo (ng2ApiService, ng2SessionService, AccessibilityCheckModel) {
        var service = {
            getAll: getAll,
            getHelp: getHelp,
            getAllPages: getAllPages,
            update: update,
        };

        return service;

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/checks', params, config).then(toModels);
        }

        function update (check, params) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/checks/' + check.id, check, params).then(
                toModel,
            );
        }

        function getHelp (check_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/checks/' + check_id + '/help', params, config);
        }

        function getAllPages (check_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/checks/' + check_id + '/pages',
                params,
                config,
            );
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new AccessibilityCheckModel(model);
        }
    }
})();
