(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name labelService
     * @description Service to handle accounts
     */
    angular.module('services.entities').factory('labelService', labelService);

    labelService.$inject = ['LabelRepo'];
    /* @ngInject*/
    function labelService (LabelRepo) {
        return {
            createLabel: createLabel,
            updateLabel: updateLabel,
            destroy: destroy,
        };

        function createLabel (params, headers) {
            return LabelRepo.createLabel(params, headers);
        }

        function updateLabel (data, headers) {
            return LabelRepo.updateLabel(data, headers);
        }

        function destroy (params, headers) {
            return LabelRepo.deleteLabel(params, headers);
        }
    }
})();
