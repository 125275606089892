(function () {
    'use strict';
    /**
     * @memberof modules.cookie_banner.services.api
     * @ngdoc service
     * @name CookieBannerEntitiesStatisticsService
     * @description Service to query Consent Manager Statistics Data
     */
    angular
        .module('modules.cookie_banner.services.api')
        .service('CookieBannerEntitiesStatisticsService', CookieBannerEntitiesStatisticsService);

    CookieBannerEntitiesStatisticsService.$inject = ['CookieBannerRepo'];
    /* @ngInject*/
    function CookieBannerEntitiesStatisticsService (CookieBannerRepo) {
        return {
            getAll: getAll,
        };

        /**
         * @memberOf CookieBannerEntitiesStatisticsService
         * @desc Get Statistics Data
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function getAll (params, config) {
            return CookieBannerRepo.getStatistics(params, config);
        }
    }
})();
