(function () {
    'use strict';

    angular.module('modules.cache').run([
        '$http',
        'CacheFactory',
        function ($http, CacheFactory) {
            if (!CacheFactory.get('stats')) {
                CacheFactory('stats', {
                    maxAge: 60 * 60 * 1000, // 1 hour
                    deleteOnExpire: 'aggressive',
                    storageMode: 'localStorage',
                });
            }
        },
    ]);
})();
