(function () {
    'use strict';

    angular.module('pages.statistics').component('mostVisitsPages', {
        templateUrl: 'app/pages/statistics/summary/most-visits-pages/most-visits-pages.html',
        controller: MostVisitsPagesController,
        controllerAs: 'vm',
        bindings: {
            startDate: '<?',
            endDate: '<?',
        },
    });

    MostVisitsPagesController.$inject = ['StatsRepo'];
    /* @ngInject */
    function MostVisitsPagesController (StatsRepo) {
        var vm = this;

        vm.$onChanges = update;
        vm.$onInit = activate;

        function activate () {
            vm.pages = [];
            getSummary();
        }

        function update () {
            getSummary();
        }

        function getSummary () {
            var params = {
                to: vm.endDate.format('YYYY-MM-DD'),
                from: vm.startDate.format('YYYY-MM-DD'),
                limit: 7,
            };
            vm.promise = StatsRepo.getMostPopularPages(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }
    }
})();
