(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideLegacyPageAssistSettings', {
        templateUrl: 'app/forms/domain-script-guide/legacy/page-assist/settings/settings.html',
        controller: DomainScriptGuideFeaturesPageAssistSettingsController,
        controllerAs: 'vm',
        bindings: {
            prefixName: '@?',
            pageAssistModel: '<',
        },
    });

    DomainScriptGuideFeaturesPageAssistSettingsController.$inject = [
        '$timeout',
        'FormDomainScriptGuideLegacyPageAssistService',
        'FormDomainScriptGuideService',
    ];
    /* @ngInject */
    function DomainScriptGuideFeaturesPageAssistSettingsController (
        $timeout,
        FormDomainScriptGuideLegacyPageAssistService,
        FormDomainScriptGuideService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDomainSettings = updateDomainSettings;

        function activate () {
            vm.prefixName = vm.prefixName || '';
            vm.settings = vm.pageAssistModel.legacySettings;
            vm.settings.theme = FormDomainScriptGuideLegacyPageAssistService.getTheme(vm.settings.theme);
            vm.settings.direction = FormDomainScriptGuideLegacyPageAssistService.getDirection(vm.settings.direction);
            vm.directions = FormDomainScriptGuideLegacyPageAssistService.getDirections();
            vm.themes = FormDomainScriptGuideLegacyPageAssistService.getThemes();
        }

        function updateDomainSettings () {
            $timeout(function () {
                FormDomainScriptGuideService.setPageAssistEdit(vm.pageAssistModel);
            });
        }
    }
})();
