import { BehaviorSubject, combineLatest, interval, Subscription, Subject } from 'rxjs';
import { takeUntil, startWith, distinctUntilChanged } from 'rxjs/operators';
import { Component, Inject, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SortType } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Reporting } from '@monsido/modules/models/api/reporting';
import isEqual from 'lodash/isEqual';
import { LoginService } from '../../../../angularjs-providers/login-service/login-service.types';
import { MeReportingsRepo, ReportingsGetParams } from '../../../../angularjs-providers/me-reporting-repo/me-reporting-repo.types';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';

@Component({
    selector: 'mon-received-reports-table',
    templateUrl: './received-reports-table.component.html',
    styleUrls: ['./received-reports-table.component.scss'],
})
export class ReceivedReportsTableComponent implements OnInit, OnDestroy {
    @Input() set search (term: string) {
        this._search = term;
        this.search$.next(term);
    }
    get search (): string {
        return this._search;
    }
    @Input() showPagination: boolean = false;
    @Output() total: EventEmitter<number> = new EventEmitter<number>();
    loading: boolean = false;
    search$ = new BehaviorSubject<string>('');
    currentSort$: BehaviorSubject<{by: string; direction: SortType;}> = new BehaviorSubject({ by: 'created_at', direction: 'desc' });
    receivedReports: MonTableCollection<Reporting> = [];
    page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(5);
    destroy$ = new Subject();
    interval$ = interval(10000).pipe(startWith(0), takeUntil(this.destroy$));
    intervalSub?: Subscription;

    baseUrl: string = this.loginService.getApiPath();
    private _search: string = '';

    constructor (
        @Inject('coreLoginService') private loginService: LoginService,
        @Inject('MeReportingsRepo') private meReportingsRepo: MeReportingsRepo,
    ) {}

    ngOnInit (): void {
        this.setInitPageSize();
        combineLatest([this.page$, this.pageSize$, this.currentSort$, this.search$])
            .pipe(distinctUntilChanged(isEqual))
            .subscribe(() => {
                this.getReports();
            });
    }

    ngOnDestroy (): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setInitPageSize (): void {
        if (this.showPagination) {
            this.pageSize$.next(10);
        }
    }

    getReports (): void {
        const params: ReportingsGetParams = {
            page: this.page$.value,
            page_size: this.pageSize$.value,
            sort_dir: this.currentSort$.value.direction,
            sort_by: this.currentSort$.value.by,
            search: this.search,
        };
        this.loading = true;

        this.intervalSub = this.interval$
            .subscribe({
                next: () => {
                    this.meReportingsRepo.get(params)
                        .then((reports) => {
                            this.total.emit(reports.total);
                            if (!this.showPagination) {
                                reports.total = reports.length;
                            }
                            this.receivedReports = reports;
                            const isAnyProcessing = reports.some(report => report.state === 'processing');

                            if (!isAnyProcessing) {
                                this.destroy$.next();
                            }
                        })
                        .finally(() => this.loading = false);
                },
                error: () => this.loading = false,
            });
    }

    onSortContent (sortPayload: {by?: string; direction?: SortType;}): void {
        const { direction = 'asc', by = '' } = sortPayload;

        this.currentSort$.next({ by, direction });
    }
}
