(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PagePerformanceResults
     * @description Service to handle Page Performance results
     */
    angular.module('services.entities').factory('PagePerformanceResults', PagePerformanceResults);

    PagePerformanceResults.$inject = ['PagePerformanceResultsRepo'];
    /* @ngInject*/
    function PagePerformanceResults (PagePerformanceResultsRepo) {
        return {
            getAll: getAll,
        };

        function getAll (params, config) {
            return PagePerformanceResultsRepo.getAll(params, config);
        }
    }
})();
