(function () {
    'use strict';
    angular.module('modules.page_fix.forms').service('PageFixBrokenLinkService', PageFixBrokenLinkService);

    PageFixBrokenLinkService.$inject = [
        'PAGE_FIX_SELECTOR_QUERIES',
        'PageFixModelHelperService',
        'PAGE_FIX_CHANGE_TYPES',
    ];
    /* @ngInject*/
    function PageFixBrokenLinkService (PAGE_FIX_SELECTOR_QUERIES, PageFixModelHelperService, PAGE_FIX_CHANGE_TYPES) {
        return function () {
            var vm = this;
            vm.model = {};
            vm.pageFix = {};
            vm.selector = '';
            vm.fixableId = null;

            return {
                setModel: setModel,
                setPageFix: setPageFix,
                getPageFix: getPageFix,
                setFixableId: setFixableId,
                getFixableId: getFixableId,
                getSelector: getSelector,
                parseQuerySelector: parseQuerySelector,
                getChangeType: getChangeType,
                getAttribute: getAttribute,
                getLinkReplacement: getLinkReplacement,
            };

            // ///////////////

            function setModel (model) {
                vm.model = model;
            }

            function setPageFix (pageFix) {
                vm.pageFix = pageFix;
            }

            function getPageFix () {
                return vm.pageFix;
            }

            function setFixableId (id) {
                vm.fixableId = id;
            }

            function getFixableId () {
                return vm.fixableId;
            }

            function getSelector () {
                return vm.selector;
            }

            function parseQuerySelector (originalUrls) {
                var collection = originalUrls.map(function (originalUrl) {
                    return getQuerySelector(originalUrl.url);
                });

                vm.selector = PAGE_FIX_SELECTOR_QUERIES.query_selector + ';' + JSON.stringify(collection);
            }

            function getAttribute () {
                if (isImageType()) {
                    return 'src';
                } else {
                    return 'href';
                }
            }

            function getChangeType () {
                if (isImageType()) {
                    return PAGE_FIX_CHANGE_TYPES.attribute + ':href';
                } else {
                    return PAGE_FIX_CHANGE_TYPES.attribute + ':src';
                }
            }

            function getLinkReplacement () {
                if (vm.pageFix) {
                    return vm.pageFix.change.value;
                }
                return '';
            }

            // PROTECTED

            function getQuerySelector (url) {
                if (isImageType()) {
                    return PageFixModelHelperService.getSelectorValue('<img src="' + url + '"/>');
                } else {
                    return PageFixModelHelperService.getSelectorValue('<a href="' + url + '"></a>');
                }
            }

            function isImageType () {
                if (vm.pageFix) {
                    return vm.pageFix.change.attribute === 'src';
                } else {
                    var link = vm.model;
                    if (vm.model.link) {
                        // PageDetails nests the actual broken link model in "link" property
                        link = vm.model.link;
                    }

                    return link.type === 'image';
                }
            }
        };
    }
})();
