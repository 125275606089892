(function () {
    'use strict';

    angular.module('app.forms').component('formPolicy', {
        templateUrl: 'app/forms/policy/policy.html',
        controller: FormPolicyController,
        controllerAs: 'vm',
        bindings: {
            policy: '<?',
            domainId: '<?',
        },
    });

    FormPolicyController.$inject = ['Dialog', 'searchService', 'gettextCatalog', 'ng2SessionService'];
    /* @ngInject */
    function FormPolicyController (Dialog, searchService, gettextCatalog, ng2SessionService) {
        var vm = this;
        vm.submit = submit;
        vm.$onInit = activate;

        function activate () {
            calculateType();
            vm.queryOptionsChosen = [];
            vm.policy = vm.policy || {};
            setupQueryOptions();
            setupPriorityOptions();
            setupTargetOptions();
            setupModeOptions();

            if (!vm.policy.id) {
                vm.policy.scheduled = true;
                vm.policy.priority = 'not';
                vm.policy.target = 'html';
                vm.policy.mode = 1;
            }

            if (!vm.policy.domain_id) {
                vm.policy.domain_id = vm.domainId ? vm.domainId : ng2SessionService.domain.id;
            }

            if (vm.policy.hasOwnProperty('case_sensitive')) {
                setQueryOptions();
            }
        }

        function setupQueryOptions () {
            vm.policyQueryOptions = [
                {
                    name: gettextCatalog.getString('aA Case sensitive'),
                    value: 'case_sensitive',
                },
                {
                    name: gettextCatalog.getString('Regex'),
                    value: 'regex',
                },
            ];
        }

        function setupPriorityOptions () {
            vm.policyPriorityOptions = [
                {
                    name: gettextCatalog.getString('None'),
                    value: 'not',
                },
                {
                    name: gettextCatalog.getString('Low'),
                    value: 'low',
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 'medium',
                },
                {
                    name: gettextCatalog.getString('High'),
                    value: 'high',
                },
            ];
        }

        function setupTargetOptions () {
            vm.policyTargetOptions = [
                {
                    name: gettextCatalog.getString('HTML pages'),
                    value: 'html',
                },
                {
                    name: gettextCatalog.getString('Scripts'),
                    value: 'script',
                },
                {
                    name: gettextCatalog.getString('Text on HTML pages'),
                    value: 'html_text',
                },
            ];

            if (ng2SessionService.domain.scan.scan_documents) {
                vm.policyTargetOptions.splice(0, 0, {
                    name: gettextCatalog.getString('HTML pages and documents'),
                    value: 'content',
                });

                vm.policyTargetOptions.splice(3, 0, {
                    name: gettextCatalog.getString('Text on HTML pages and documents'),
                    value: 'text',
                });

                vm.policyTargetOptions.splice(4, 0, {
                    name: gettextCatalog.getString('Text in Documents'),
                    value: 'document',
                });
            }
        }

        function setupModeOptions () {
            // Only available when choosing Search
            vm.poliyModeOptions = [
                {
                    name: gettextCatalog.getString('Contains'),
                    value: 1,
                },
                {
                    name: gettextCatalog.getString('Not contains'),
                    value: 2,
                },
            ];
        }

        function submit () {
            var promise;
            vm.onSave = true;
            calculateSettingsForType();
            assignQueryOptions();

            if (vm.policy.id === undefined || vm.policy.id === null) {
                promise = searchService.create(vm.policy);
            } else {
                promise = searchService.update(vm.policy);
            }

            promise.then(function (data) {
                searchService.run(data.id).then(function () {
                    close(data);
                }, angular.noop);
            });

            promise.catch(function () {
                vm.onSave = false;
            });
        }

        function setQueryOptions () {
            vm.policyQueryOptions.forEach(function (data) {
                if (vm.policy[data.value]) {
                    vm.queryOptionsChosen.push(data.value);
                }
            });
        }

        function assignQueryOptions () {
            vm.policyQueryOptions.forEach(function (data) {
                vm.policy[data.value] = vm.queryOptionsChosen.indexOf(data.value) > -1;
            });
        }

        function close (policy) {
            Dialog.close('formPolicyDialog', policy);
        }

        function calculateType () {
            vm.policyType = null;
            if (vm.policy) {
                if (vm.policy.is_errors && vm.policy.mode == 1) {
                    vm.policyType = 'Unwanted';
                } else if (vm.policy.is_errors && vm.policy.mode == 2) {
                    vm.policyType = 'Required';
                } else if (!vm.policy.is_errors) {
                    vm.policyType = 'Search';
                }
            } else {
                vm.policyType = 'Unwanted';
            }
        }

        function calculateSettingsForType () {
            if (vm.policyType == 'Unwanted') {
                vm.policy.is_errors = true;
                vm.policy.mode = 1;
            } else if (vm.policyType == 'Required') {
                vm.policy.is_errors = true;
                vm.policy.mode = 2;
            } else if (vm.policyType == 'Search') {
                vm.policy.is_errors = false;
            }
        }
    }
})();
