(function () {
    'use strict';

    angular
        .module('modules.export')
        .service('exporterPerformanceAuditsDetailsParser', exporterPerformanceAuditsDetailsParser);

    exporterPerformanceAuditsDetailsParser.$inject = [
        'gettextCatalog',
        'PagePerformanceAuditDetailsService',
        '$filter',
    ];
    /* @ngInject */
    function exporterPerformanceAuditsDetailsParser (gettextCatalog, PagePerformanceAuditDetailsService, $filter) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            var headlines = [
                gettextCatalog.getString('URL'),
                gettextCatalog.getString('Size'),
                gettextCatalog.getString('Potential Savings'),
            ];

            return headlines;
        }

        function getDiagnosticsHeadlinesEnsureTextRemainsVisibleDuringWebfontLoad () {
            var headlines = [gettextCatalog.getString('URL'), gettextCatalog.getString('Potential Savings')];

            return headlines;
        }

        function getDiagnosticsHeadlinesAvoidsEnormousNetworkPayloads () {
            var headlines = [gettextCatalog.getString('URL'), gettextCatalog.getString('Size')];

            return headlines;
        }

        function getDiagnosticsHeadlinesKeepRequestCountsLowAndTransferSizesSmall () {
            var headlines = [
                gettextCatalog.getString('Resource Type'),
                gettextCatalog.getString('Requests'),
                gettextCatalog.getString('Transfer Size'),
            ];

            return headlines;
        }

        function getDiagnosticsHeadlinesMinimizeMainThreadWork () {
            var headlines = [gettextCatalog.getString('Category'), gettextCatalog.getString('Time Spent')];

            return headlines;
        }

        function getDiagnosticsHeadlinesServeStaticAssetsWithAnEfficientCachePolicy () {
            var headlines = [
                gettextCatalog.getString('URL'),
                gettextCatalog.getString('Cache TTL'),
                gettextCatalog.getString('Size'),
            ];

            return headlines;
        }

        function getDiagnosticsHeadlinesReduceJavaScriptExecutionTime () {
            var headlines = [
                gettextCatalog.getString('URL'),
                gettextCatalog.getString('Total CPU Time'),
                gettextCatalog.getString('Script Evaluation'),
                gettextCatalog.getString('Script Parse'),
            ];

            return headlines;
        }

        function getDiagnosticsHeadlinesAvoidsAnExcessiveDOMSize () {
            var headlines = [
                gettextCatalog.getString('Statistic'),
                gettextCatalog.getString('Element'),
                gettextCatalog.getString('Value'),
            ];

            return headlines;
        }

        function getDiagnosticsHeadlinesThirdPartyUsage () {
            var headlines = [
                gettextCatalog.getString('Third-Party'),
                gettextCatalog.getString('Size'),
                gettextCatalog.getString('Main-Thread Blocking Time'),
            ];

            return headlines;
        }

        function getDiagnosticsHeadlinesMinimizeCriticalRequestsDepth () {
            var headlines = [
                gettextCatalog.getString('URL'),
                gettextCatalog.getString('Response Time'),
                gettextCatalog.getString('Size'),
            ];

            return headlines;
        }

        function parse (data) {
            var csv = [];
                var entry = [];
            var currentData;
            if (!data || data.length === 0) {
                return csv;
            }
            if (
                data[0].totalBytes &&
                (data[0].wastedMs || data[0].wastedBytes) &&
                typeof data[0].cacheLifetimeMs === 'undefined'
            ) {
                csv.push(getHeadlines());

                for (var i = 0; i < data.length; i++) {
                    currentData = data[i];
                    entry = [];
                    entry.push(currentData.url);
                    entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.totalBytes));
                    if (currentData.wastedMs) {
                        entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.wastedMs));
                    } else {
                        entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.wastedBytes));
                    }
                    csv.push(entry);
                }
                return csv;
            } else if (
                !data[0].totalBytes &&
                !data[0].label &&
                !data[0].requestCount &&
                !data[0].size &&
                !data[0].groupLabel &&
                !data[0].duration &&
                typeof data[0].total === 'undefined' &&
                typeof data[0].scripting === 'undefined' &&
                typeof data[0].scriptParseCompile === 'undefined' &&
                typeof data[0].statistic === 'undefined' &&
                typeof data[0].element === 'undefined' &&
                typeof data[0].value === 'undefined' &&
                typeof data[0].entity === 'undefined' &&
                typeof data[0].transferSize === 'undefined' &&
                typeof data[0].request === 'undefined'
            ) {
                csv.push(getDiagnosticsHeadlinesEnsureTextRemainsVisibleDuringWebfontLoad());
                for (var indexOne = 0; indexOne < data.length; indexOne++) {
                    currentData = data[indexOne];
                    entry = [];
                    entry.push(currentData.url);
                    if (currentData.wastedMs) {
                        entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.wastedMs));
                    } else {
                        entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.wastedBytes));
                    }
                    csv.push(entry);
                }
                return csv;
            } else if (
                data[0].totalBytes &&
                !data[0].label &&
                !data[0].requestCount &&
                !data[0].size &&
                !data[0].groupLabel &&
                !data[0].duration &&
                typeof data[0].cacheLifetimeMs === 'undefined'
            ) {
                csv.push(getDiagnosticsHeadlinesAvoidsEnormousNetworkPayloads());
                for (var indexTwo = 0; indexTwo < data.length; indexTwo++) {
                    currentData = data[indexTwo];
                    entry = [];
                    entry.push(currentData.url);
                    entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.totalBytes));
                    csv.push(entry);
                }
                return csv;
            } else if (data[0].label && data[0].requestCount && data[0].size) {
                csv.push(getDiagnosticsHeadlinesKeepRequestCountsLowAndTransferSizesSmall());
                for (var indexThree = 0; indexThree < data.length; indexThree++) {
                    currentData = data[indexThree];
                    entry = [];
                    entry.push(currentData.label);
                    entry.push(currentData.requestCount);
                    entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.size));
                    csv.push(entry);
                }
                return csv;
            } else if (data[0].groupLabel && data[0].duration) {
                csv.push(getDiagnosticsHeadlinesMinimizeMainThreadWork());
                for (var indexFour = 0; indexFour < data.length; indexFour++) {
                    currentData = data[indexFour];
                    entry = [];
                    entry.push(currentData.groupLabel);
                    entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.duration));
                    csv.push(entry);
                }
                return csv;
            } else if (
                data[0].url &&
                (data[0].cacheLifetimeMs === 0 || data[0].cacheLifetimeMs) &&
                data[0].totalBytes
            ) {
                csv.push(getDiagnosticsHeadlinesServeStaticAssetsWithAnEfficientCachePolicy());
                for (var indexFive = 0; indexFive < data.length; indexFive++) {
                    currentData = data[indexFive];
                    entry = [];
                    entry.push(currentData.url);
                    entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.cacheLifetimeMs));
                    entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.totalBytes));
                    csv.push(entry);
                }
                return csv;
            } else if (data[0].url && data[0].total && data[0].scripting && data[0].scriptParseCompile) {
                csv.push(getDiagnosticsHeadlinesReduceJavaScriptExecutionTime());
                for (var indexSix = 0; indexSix < data.length; indexSix++) {
                    currentData = data[indexSix];
                    entry = [];
                    entry.push(currentData.url);
                    entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.total));
                    entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.scripting));
                    entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.scriptParseCompile));
                    csv.push(entry);
                }
                return csv;
            } else if (data[0].statistic && data[0].value) {
                csv.push(getDiagnosticsHeadlinesAvoidsAnExcessiveDOMSize());
                for (var indexSeven = 0; indexSeven < data.length; indexSeven++) {
                    currentData = data[indexSeven];
                    entry = [];
                    entry.push(currentData.statistic);
                    entry.push(PagePerformanceAuditDetailsService.getTextHTMLSnippet(currentData.element.value));
                    entry.push(currentData.value);
                    csv.push(entry);
                }
                return csv;
            } else if (data[0].entity && (data[0].blockingTime === 0 || data[0].blockingTime) && data[0].transferSize) {
                csv.push(getDiagnosticsHeadlinesThirdPartyUsage());
                for (var indexEight = 0; indexEight < data.length; indexEight++) {
                    currentData = data[indexEight];
                    entry = [];
                    entry.push(currentData.entity.text);
                    entry.push(PagePerformanceAuditDetailsService.getBytesSnippet(currentData.transferSize));
                    entry.push(PagePerformanceAuditDetailsService.getMSSnippet(currentData.blockingTime));
                    csv.push(entry);
                }
                return csv;
            } else if (data[0].request) {
                csv.push(getDiagnosticsHeadlinesMinimizeCriticalRequestsDepth());
                for (var indexNine = 0; indexNine < data.length; indexNine++) {
                    currentData = data[indexNine];
                    entry = [];
                    entry.push(currentData.request.url);
                    entry.push(
                        $filter('monNumber')(
                            (currentData.request.endTime - currentData.request.startTime) * 1000,
                            '0',
                        ) + 'ms',
                    );
                    entry.push($filter('monNumber')(currentData.request.transferSize, '0.00 ib'));
                    csv.push(entry);
                }
                return csv;
            }
        }
    }
})();
