/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import {
    Customer,
    DomainGroupMembersEntity,
    DomainUsersEntity,
    EmailNotifications,
    PrivacyPolicy,
    Settings,
    SocialMedia,
    UserInterface,
} from '@monsido/modules/models/api/interfaces/user.interface';
import { LanguageType } from '@angular-shared-components';


export class User implements UserInterface {
    constructor (user: Partial<UserInterface>) {
        this.id = user.id as number;
        this.email = user.email as string;
        this.first_name = user.first_name as string;
        this.last_name = user.last_name as string;
        this.locale = user.locale;
        this.timezone = user.timezone;
        this.contact_info = user.contact_info;
        this.social_media = user.social_media;
        this.title = user.title;
        this.description = user.description;
        this.phone = user.phone;
        this.otp = user.otp;
        this.created_at = user.created_at;
        this.updated_at = user.updated_at;
        this.last_login_at = user.last_login_at;
        this.customer_admin = user.customer_admin;
        this.customer = user.customer;
        this.domain_users = user.domain_users;
        this.domain_group_members = user.domain_group_members;
        this.settings = user.settings;
        this.email_notifications = user.email_notifications;
        this.association_level = user.association_level;
        this.api_key = user.api_key;
        this.intercom_user_hash = user.intercom_user_hash;
        this.privacy_policy = user.privacy_policy;
        this.user = user.user;
    }

    id: number;
    email: string;
    first_name: string;
    last_name: string;
    locale?: LanguageType;
    timezone?: string;
    contact_info?: null;
    social_media?: SocialMedia;
    title?: null;
    description?: null;
    phone?: null;
    otp?: boolean;
    created_at?: string;
    updated_at?: string;
    last_login_at?: string;
    customer_admin?: boolean;
    customer?: Customer;
    domain_users?: DomainUsersEntity[];
    domain_group_members?: DomainGroupMembersEntity[];
    settings?: Settings;
    email_notifications?: EmailNotifications;
    association_level?: number;
    api_key?: string;
    intercom_user_hash?: string;
    privacy_policy?: PrivacyPolicy;
    user?: {
        id?: number;
        email?: string;
        first_name?: string;
        last_name?: string;
    };

    getAvatarInitials (): string {
        return `${this.getFirstName()
            .charAt(0)
            .toUpperCase()} ${this.getLastName()
            .charAt(0)
            .toUpperCase()}`;
    }

    getName (): string {
        return `${this.getFirstName()} ${this.getLastName()}`;
    }

    getFirstName (): string {
        return (typeof this.first_name === 'string' ? this.first_name : '').trim();
    }
    getLastName (): string {
        return (typeof this.last_name === 'string' ? this.last_name : '').trim();
    }
}

