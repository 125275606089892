(function () {
    'use strict';

    angular.module('services.api').factory('CommentRepo', CommentRepo);

    CommentRepo.$inject = ['ng2ApiService', 'Comment'];
    /* @ngInject */
    function CommentRepo (ng2ApiService, Comment) {
        return {
            getAll: getAll,
            get: get,
            create: create,
            update: update,
            destroy: destroy,
        };

        // /////////////

        function get (commentId, params, config) {
            return ng2ApiService.get('comments/' + commentId, params, config).then(toModel);
        }

        function getAll (params, config) {
            return ng2ApiService.get('comments', params, config).then(toModels);
        }

        function update (comment, config) {
            return ng2ApiService.patch('comments/' + comment.id, comment, null, config).then(toModel);
        }

        function create (comment, config) {
            return ng2ApiService.post('comments', comment, config).then(toModel);
        }

        function destroy (commentId, params, config) {
            return ng2ApiService.delete('comments/' + commentId, params, config);
        }

        function toModels (models) {
            return models.map(function (model) {
                return toModel(model);
            });
        }

        function toModel (model) {
            return new Comment(model);
        }
    }
})();
