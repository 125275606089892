(function () {
    'use strict';

    angular.module('pages.qa.broken').component('brokenPagesIndex', {
        templateUrl: 'app/pages/qa/broken-links/pages/index.html',
        controller: BrokenPagesIndex,
        controllerAs: 'vm',
    });

    BrokenPagesIndex.$inject = ['BrokenPageRepo', '$location', 'ParamService', 'ng2SessionService', '$controller', 'gettextCatalog'];
    /* @ngInject */
    function BrokenPagesIndex (BrokenPageRepo, $location, ParamService, ng2SessionService, $controller, gettextCatalog) {
        var vm = this;

        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));

            vm.onSearch = onSearch;
            vm.search = null;
            vm.exports = [
                {
                    name: gettextCatalog.getString('Content with broken links'),
                    data: {
                        category: 'pages_with_broken_links',
                    },
                },
            ];
            vm.pages = [];
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    faIcon: 'fas fa-copy',
                    value: '',
                },
                {
                    name: gettextCatalog.getString('Pages'),
                    faIcon: 'fas fa-window-maximize',
                    value: 'html',
                },
            ];
            vm.activeTab = '';

            if (ng2SessionService.domain.scan.scan_documents) {
                vm.tabs.push({ name: 'PDF Documents', faIcon: 'fas fa-file-pdf', value: 'pdf' });
                vm.tabs.push({ name: 'Text Documents', faIcon: 'fas fa-file-alt', value: 'document' });
            }
            vm.showNotifications = ['dead-links', 'broken-images'];
            loadLocations();
            getPage();
        }

        function loadLocations () {
            vm.currentSort = {};
            vm.search = $location.search().search || '';
            vm.currentSort.by = $location.search().sort_by || 'priority_score';
            vm.currentSort.direction = $location.search().sort_dir || 'desc';
            vm.activeTab = $location.search().type || '';
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
                type: vm.activeTab || null,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by === 'notification_count' ? 'dead_count' : vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.activeTab && vm.activeTab !== '') {
                params['types[]'] = [vm.activeTab];
            }

            params = vm.updateParamsWithFilters(params);

            vm.promise = BrokenPageRepo.get(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            getPage();
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            getPage();
        }
    }
})();
