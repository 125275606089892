(function () {
    'use strict';

    angular.module('pages.search.violations').component('policyViolations', {
        templateUrl: 'app/pages/search/violations/violations.html',
        controller: PolicyOverviewController,
        controllerAs: 'vm',
    });

    PolicyOverviewController.$inject = ['SearchRepo', '$stateParams', 'pagesTabsHelper', '$controller'];

    function PolicyOverviewController (SearchRepo, $stateParams, pagesTabsHelper, $controller) {
        var vm = this;
        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.pages = [];
            vm.search = '';
            vm.tabs = [];
            vm.activeTab = pagesTabsHelper.getActiveTab();

            vm.currentSort = { by: 'priority_score', direction: 'desc' };

            getPage();
            setupTabs();
        }

        function setupTabs () {
            vm.tabs = pagesTabsHelper.getTabs();
        }

        function getPage () {
            var params = {
                domainId: $stateParams.domainId,
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.activeTab && vm.activeTab !== 'all') {
                params['types[]'] = [vm.activeTab];
            }

            if (vm.search) {
                params.search = vm.search;
            }

            vm.promise = SearchRepo.getAllPages(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }
    }
})();
