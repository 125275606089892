(function () {
    'use strict';
    angular.module('modules.notifications').component('allNotificationsDialog', {
        templateUrl: 'app/modules/notifications/dialog/dialog.html',
        controller: NotificationsDialogController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@?',
        },
    });
    NotificationsDialogController.$inject = [
        'monDialog',
        'NotificationRepo',
        '$controller',
        'notificationRedirectService',
    ];
    /* @ngInject */
    function NotificationsDialogController (monDialog, NotificationRepo, $controller, notificationRedirectService) {
        var vm = this;
        vm.getPage = getPage;
        vm.redirect = redirect;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.notifications = [];
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };
            vm.promise = NotificationRepo.getAll(params).then(function (notifications) {
                vm.notifications = notifications;
            }, angular.noop);
        }

        function redirect (e, row) {
            e.preventDefault();
            e.stopPropagation();
            monDialog.closeAll();
            notificationRedirectService.redirect(row);
        }
    }
})();
