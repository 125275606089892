(function () {
    'use strict';

    angular.module('modules.support.services.api').factory('SupportApi', Api);

    Api.$inject = ['Client', 'supportPath'];
    /* @ngInject */
    function Api (Client, supportPath) {
        var api = new Client({
            url: supportPath,
            headers: {
                'Accept-Version': 'v1',
            },
        });
        api.defaultSuccessCallback = function (response) {
            return response && response.data;
        };
        return api;
    }
})();
