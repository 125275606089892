(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name HeatmapApiService
     * @description Service to handle domains
     */
    angular.module('services.entities').factory('HeatmapApiService', HeatmapApiService);

    HeatmapApiService.$inject = ['HeatmapRepo', 'ng2DemoHasAccessService', 'DemoHeatmapsRepo'];
    /* @ngInject*/
    function HeatmapApiService (HeatmapRepo, ng2DemoHasAccessService, DemoHeatmapsRepo) {
        return {
            getAll: getAll,
            create: create,
            update: update,
            destroy: destroy,
            getAllFromCustomer: getAllFromCustomer,
        };

        function getAll (domainId, params, headers) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.getAll(domainId, params);
            }

            return HeatmapRepo.getAll(domainId, params, headers);
        }

        function update (domainId, rule, params, headers) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.update(domainId, rule, params);
            }

            return HeatmapRepo.update(domainId, rule, params, headers);
        }

        function create (domainId, params, headers) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.create(domainId, params);
            }

            return HeatmapRepo.create(domainId, params, headers);
        }

        function destroy (domainId, ruleId, params, headers) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.destroy(domainId, ruleId, params);
            }

            return HeatmapRepo.destroy(domainId, ruleId, params, headers);
        }

        function getAllFromCustomer (params, headers) {
            if (ng2DemoHasAccessService.isDemoHeatmaps()) {
                return DemoHeatmapsRepo.getAllFromCustomer(params);
            }

            return HeatmapRepo.getAllFromCustomer(params, headers);
        }
    }
})();
