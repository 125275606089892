(function () {
    'use strict';
    angular.module('modules.page_fix.services').service('PageFixFormService', PageFixFormService);

    PageFixFormService.$inject = [
        'PAGE_FIX_FIXABLE_TYPES',
        'Lodash',
        'PAGE_FIX_CHANGE_TYPES',
        'gettextCatalog',
        '$deepmerge',
    ];
    /* @ngInject*/
    function PageFixFormService (PAGE_FIX_FIXABLE_TYPES, Lodash, PAGE_FIX_CHANGE_TYPES, gettextCatalog, $deepmerge) {
        return {
            getChangeType: getChangeType,
            getHeadline: getHeadline,
        };

        // ///////////////

        function getChangeType (type, id, change) {
            if (type === PAGE_FIX_FIXABLE_TYPES.misspelling) {
                return PAGE_FIX_CHANGE_TYPES.word;
            } else if (
                type === PAGE_FIX_FIXABLE_TYPES.accessibility_source_code ||
                type === PAGE_FIX_FIXABLE_TYPES.accessibility_check
            ) {
                return getAccessibilityCheckChangeType(id);
            } else if (type === PAGE_FIX_FIXABLE_TYPES.link) {
                if (change.attribute === 'src') {
                    return PAGE_FIX_CHANGE_TYPES.attribute + ':src';
                } else {
                    return PAGE_FIX_CHANGE_TYPES.attribute + ':href';
                }
            }
        }

        function getHeadline (templateInfo, model, pageFix) {
            var text = 'Use PageCorrect to fix this issue';
            var scope = {};
            if (templateInfo.headline) {
                if (pageFix) {
                    scope.error = pageFix.custom_selector;
                } else {
                    scope.error = model.getPageFixSelector();
                }
                text = templateInfo.headline;
            }
            if (templateInfo.headlineScope) {
                scope = $deepmerge(scope, templateInfo.headlineScope);
            }
            return gettextCatalog.getString(text, scope);
        }

        // PROTECTED

        function getAccessibilityCheckChangeType (checkId) {
            var altIds = [1, 2, 3, 4, 5, 6, 7, 11, 16, 178];
            if (Lodash.indexOf(altIds, checkId) > -1) {
                return PAGE_FIX_CHANGE_TYPES.attribute + ':alt';
            } else if (checkId === 28) {
                return PAGE_FIX_CHANGE_TYPES.element + ':skip-to-content';
            } else if (checkId === 116) {
                return PAGE_FIX_CHANGE_TYPES.element + ':b';
            } else if (checkId === 117) {
                return PAGE_FIX_CHANGE_TYPES.element + ':i';
            } else if (checkId === 27) {
                return PAGE_FIX_CHANGE_TYPES.element + ':blink';
            } else if (checkId === 18) {
                return PAGE_FIX_CHANGE_TYPES.element + ':a';
            } else if (checkId === 69) {
                return PAGE_FIX_CHANGE_TYPES.element + ':marquee';
            } else if (checkId === 31) {
                return PAGE_FIX_CHANGE_TYPES.attribute + ':title';
            }
        }
    }
})();
