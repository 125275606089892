(function () {
    'use strict';

    angular.module('models').factory('PagePerformanceProfileModel', PagePerformanceProfileModel);

    PagePerformanceProfileModel.$inject = ['PagePerformanceProfileService'];

    function PagePerformanceProfileModel (PagePerformanceProfileService) {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getConnectionName = getConnectionName;
        Model.prototype.getDeviceName = getDeviceName;
        Model.prototype.getCountryCode = getCountryCode;
        Model.prototype.getCity = getCity;
        Model.prototype.getCountry = getCountry;
        return Model;

        // /////////

        function getConnectionName () {
            var vm = this;
            return PagePerformanceProfileService.getConnectionProfileName(vm);
        }

        function getDeviceName () {
            var vm = this;
            return PagePerformanceProfileService.getDeviceTranslation(vm);
        }

        function getCountryCode () {
            var vm = this;
            return PagePerformanceProfileService.getProfileCountryCode(vm);
        }

        function getCity () {
            var vm = this;
            return PagePerformanceProfileService.getProfileCity(vm);
        }

        function getCountry () {
            var vm = this;
            return PagePerformanceProfileService.getProfileCountry(vm);
        }
    }
})();
