(function () {
    'use strict';

    angular.module('pages.uptimes').component('incidentList', {
        templateUrl: 'app/pages/uptimes/uptime-show/incident-list/incident-list.html',
        controller: IncidentListController,
        controllerAs: 'vm',
        bindings: {
            uptime: '<',
            startDate: '<',
            endDate: '<',
        },
    });

    IncidentListController.$inject = ['UptimeRepo', '$controller', 'moment'];
    /* @ngInject */
    function IncidentListController (UptimeRepo, $controller, moment) {
        var vm = this;

        vm.duration = duration;
        vm.$onInit = activate;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.downtimes = [];
            vm.progress = null;
            getPage();
        }

        function duration (start, end) {
            var tmpStart = moment(start);
            if (!end) {
                return moment.duration(moment() - tmpStart).humanize();
            }

            var tmpEnd = moment(end);
            return moment.duration(tmpEnd - tmpStart).humanize();
        }

        function getPage () {
            var params = {
                start_date: vm.startDate,
                end_date: vm.endDate,
                page_size: vm.pageSize,
                page: vm.page,
            };

            vm.progress = UptimeRepo.getDowntimes(vm.uptime.id, params).then(function (downTimesData) {
                vm.downtimes = downTimesData;
            }, angular.noop);
            return vm.progress;
        }
    }
})();
