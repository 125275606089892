(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionStatisticsSummary', {
        templateUrl: 'app/components/pagedetails/details/sections/statistics/summary/summary.html',
        controller: SectionSummaryController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            startDate: '<',
            endDate: '<',
        },
    });

    SectionSummaryController.$inject = ['pageService', '$q', '$filter', 'gettextCatalog', '$deepmerge', 'moment'];

    /* @ngInject */
    function SectionSummaryController (pageService, $q, $filter, gettextCatalog, $deepmerge, moment) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = updateDateRange;
        vm.setType = setType;

        function activate () {
            vm.currentType = 'visits';
        }

        function updateDateRange (changes) {
            if (changes.startDate || changes.endDate) {
                var params = {
                    from: vm.startDate.format('YYYY-MM-DD'),
                    to: vm.endDate.format('YYYY-MM-DD'),
                };
                setupLineChart();
                vm.loading = true;
                $q.all([getStatisticsSummary(params), renewData(params)]).finally(function () {
                    vm.loading = false;
                });
            }
        }

        function setType (type) {
            vm.currentType = type;
            setupLineChartData();
        }

        function setupLineChart () {
            vm.lineChartSeries = [gettextCatalog.getString('Visits')];
            vm.lineChartData = [[]];
            vm.lineChartColors = ['#50a6fb'];
            vm.lineChartLabels = [];
            vm.lineChartOverride = [
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderColor: '#50a6fb',
                },
            ];

            vm.lineChartOptions = {
                tooltips: {
                    mode: 'label',
                    intersect: false,
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: function (tooltipItem, data) {
                            if (vm.currentType === 'avg_time') {
                                return (
                                    getChartLabel() +
                                    $filter('numeraljs')(
                                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                        '00:00:00',
                                    )
                                );
                            } else if (vm.currentType === 'bounce_rate') {
                                return (
                                    getChartLabel() +
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
                                    '%'
                                );
                            }
                            return (
                                getChartLabel() +
                                $filter('numeraljs', '0,0')(
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                )
                            );
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value, index) {
                                    if (vm.currentType === 'avg_time') {
                                        return $filter('numeraljs')(value, '00:00:00');
                                    } else if (vm.currentType === 'bounce_rate') {
                                        return parseInt(value) + '%';
                                    }
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: function (value) {
                                    if (value) {
                                        return moment(value, 'll').format('MMM DD');
                                    }
                                    return '';
                                },
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            };
        }

        function renewData (params) {
            return pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: 'total' })).then(function (data) {
                vm.visitorData = data;
                setupLineChartData();
            }, angular.noop);
        }

        function setupLineChartData () {
            vm.lineChartData = [[]];
            vm.lineChartLabels = [];
            for (var k = 0; k < vm.visitorData.length; k++) {
                vm.lineChartLabels.push($filter('userDate')(vm.visitorData[k].date, 'll'));
                vm.lineChartData[0].push(getVisits(vm.visitorData[k]));
            }
        }

        function getStatisticsSummary (params) {
            return pageService
                .getStatSummary(vm.page.id, $deepmerge(params, { type: 'referring' }))
                .then(function (summary) {
                    vm.summary = summary;
                });
        }

        function getVisits (statistic) {
            if (typeof statistic === 'undefined') {
                return 0;
            }
            if (vm.currentType === 'bounce_rate') {
                return Number(statistic.bounce_rate.replace('%', ''));
            } else if (vm.currentType === 'avg_time') {
                return statistic.avg_time;
            }
            return Number(statistic[vm.currentType] || 0);
        }

        function getChartLabel () {
            var txt = gettextCatalog.getString('Visits');
            switch (vm.currentType) {
                case 'visits':
                    txt = gettextCatalog.getString('Visits');
                    break;
                case 'uniq_visitors':
                    txt = gettextCatalog.getString('Total Visitors');
                    break;
                case 'pageviews':
                    txt = gettextCatalog.getString('Page Views');
                    break;
                case 'avg_time':
                    txt = gettextCatalog.getString('Avg. Visit Time');
                    break;
                case 'bounce_rate':
                    txt = gettextCatalog.getString('Bounce rates');
                    break;
            }

            return ' ' + txt + ': ';
        }
    }
})();
