(function () {
    'use strict';

    angular.module('core.services.check-for-duplicates').service('checkForDuplicatesService', checkForDuplicatesService);

    checkForDuplicatesService.$inject = ['ng2MonEventsService', 'ng2SessionService'];

    /* @ngInject*/
    function checkForDuplicatesService (ng2MonEventsService, ng2SessionService) {
        return {
            checkForDuplicatePages: checkForDuplicatePages,
            checkForDuplicateLinks: checkForDuplicateLinks,
        };

        function checkForDuplicatePages (pages) {
            var duplicates = checkForDuplicateIds(pages);
            if (duplicates.length) {
                return ng2MonEventsService.run(
                    'newException',
                    'Duplicate Page IDs ' +
                        duplicates.join(', ') +
                        ' on page ' +
                        pages.currentPage +
                        ' with page size ' +
                        pages.perPage +
                        ' found on domain ' +
                        ng2SessionService.getDomainId(),
                    'error',
                );
            }
        }

        function checkForDuplicateLinks (pages) {
            var duplicates = checkForDuplicateIds(pages);
            if (duplicates.length) {
                return ng2MonEventsService.run(
                    'newException',
                    'Duplicate Link IDs ' +
                        duplicates.join(', ') +
                        ' on page ' +
                        pages.currentPage +
                        ' with page size ' +
                        pages.perPage +
                        ' found on domain ' +
                        ng2SessionService.getDomainId(),
                    'error',
                );
            }
        }

        function checkForDuplicateIds (collection) {
            if (!Array.isArray(collection)) {
                return [];
            }

            var uniquePageIds = new Set();
            var duplicates = [];
            var len = collection.length;

            for (var i = 0; i < len; i++) {
                if (uniquePageIds.has(collection[i].id)) {
                    duplicates = duplicatedPages.push(collection[i].id);
                }
            }

            return duplicates;
        }
    }
})();
