(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').component('privacyList', {
        templateUrl: 'app/modules/data-privacy/pages/list/list.html',
        controller: DataPrivacyListController,
        controllerAs: 'vm',
    });

    DataPrivacyListController.$inject = [
        'PageRepo',
        'pagesTabsHelper',
        'activeFeatures',
        '$controller',
        'REGULATIONS',
        'gettextCatalog',
        '$filter',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DataPrivacyListController (
        PageRepo,
        pagesTabsHelper,
        activeFeatures,
        $controller,
        REGULATIONS,
        gettextCatalog,
        $filter,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getEmptyText = getEmptyText;
        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.pageTitle = pageTitle;

        vm.exports = [
            {
                name: gettextCatalog.getString('Content with Data Issues export'),
                data: {
                    category: 'content_with_data_issues',
                },
            },
        ];

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.currentSort = { by: 'notification_count', direction: 'desc' };
            vm.search = null;
            vm.pages = [];
            vm.tabs = pagesTabsHelper.getTabs();
            vm.activeTab = pagesTabsHelper.getActiveTab();
            vm.showFilters = activeFeatures.table_filters;
            vm.showNotifications = ['data-privacy'];
            vm.hideNotifications = vm.hideNotifications || [];
            vm.target = 'page-details-section-data-privacy';
            vm.subheaderTranslation = '';

            vm.filters = { 'Data Regulations': [] };
            vm.availableFilters = {
                'Data Regulations': REGULATIONS,
            };
            vm.isFiltersOpen = false;

            getPage();
        }

        function getEmptyText () {
            return gettextCatalog.getString('Good job! No issues were found');
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                data_protection_violations: true,
                search: vm.search || null,
                'regulations[]': vm.filters['Data Regulations'].map(function (regulation) {
                    return regulation.value;
                }),
            };

            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            if (vm.selectedLanguages && vm.selectedLanguages.length > 0) {
                params['languages[]'] = vm.selectedLanguages;
            }

            if (vm.activeTab) {
                params['types[]'] = [vm.activeTab];
            }

            vm.promise = PageRepo.getAll(params).then(function (data) {
                vm.pages = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.pages.total, '0,0'),
                '1 page',
                '{{$count}} pages',
                {},
            );
        }

        function onFiltersChanges (changes) {
            vm.filters = changes;
            getPage();
        }

        function pageTitle (title) {
            return title || '(' + gettextCatalog.getString('No title found') + ')';
        }
    }
})();
