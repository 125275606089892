(function () {
    'use strict';

    angular.module('modules.cache').run([
        'ng2MonEventsService',
        'CacheFactory',
        function (ng2MonEventsService, CacheFactory) {
            ng2MonEventsService.addListener('logout', function () {
                CacheFactory.clearAll();
            });
        },
    ]);
})();
