(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name shortcutService
     * @description Service to handle shortcuts
     */
    angular.module('services.entities').factory('shortcutService', shortcutService);

    shortcutService.$inject = ['ShortcutRepo'];
    /* @ngInject*/
    function shortcutService (ShortcutRepo) {
        return {
            create: create,
            update: update,
            destroy: destroy,
        };

        function create (shortcut, headers) {
            return ShortcutRepo.create(shortcut, headers);
        }

        function update (shortcut, headers) {
            return ShortcutRepo.update(shortcut, headers);
        }

        function destroy (id, params, headers) {
            return ShortcutRepo.destroy(id, params, headers);
        }
    }
})();
