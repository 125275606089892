(function () {
    'use strict';
    angular.module('modules.page_fix.forms').service('PageFixMisspellingService', PageFixMisspellingService);

    PageFixMisspellingService.$inject = [];
    /* @ngInject*/
    function PageFixMisspellingService () {
        return function () {
            var vm = this;
            vm.model = {};
            vm.pageFix = {};
            vm.misspelling = '';
            vm.correctSpelling = '';

            return {
                setModel: setModel,
                getMisspelling: getMisspelling,
                setPageFix: setPageFix,
                getPageFix: getPageFix,
                setupSettings: setupSettings,
                getCorrectSpelling: getCorrectSpelling,
            };

            // ///////////////

            function setModel (model) {
                vm.model = model;
            }

            function setPageFix (pageFix) {
                vm.pageFix = pageFix;
            }

            function getPageFix () {
                return vm.pageFix;
            }

            function setupSettings () {
                if (vm.pageFix) {
                    vm.misspelling = vm.pageFix.custom_selector;
                    vm.correctSpelling = vm.pageFix.change.value;
                } else if (vm.model) {
                    vm.misspelling = vm.model.word;
                }
            }

            function getMisspelling () {
                return vm.misspelling;
            }

            function getCorrectSpelling () {
                return vm.correctSpelling;
            }
        };
    }
})();
