(function () {
    'use strict';

    angular.module('modules.export').factory('exporterQaParser', exporterQaParser);

    exporterQaParser.$inject = ['historyHelper', '$filter', 'gettextCatalog', 'complianceService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function exporterQaParser (historyHelper, $filter, gettextCatalog, complianceService, ng2ActiveFeatureService) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines (allowVerticalAverage) {
            var result = [];

            result.push(gettextCatalog.getString('Crawled at'));
            result.push(gettextCatalog.getString('Pages Crawled'));
            result.push(gettextCatalog.getString('Documents Crawled'));
            result.push(gettextCatalog.getString('Domain Compliance'));

            if (allowVerticalAverage) {
                result.push(gettextCatalog.getString('Comparison to average'));
            }

            result.push(gettextCatalog.getString('Broken Links'));
            result.push(gettextCatalog.getString('Broken Images'));
            result.push(gettextCatalog.getString('Misspellings'));
            result.push(gettextCatalog.getString('Changes since Previous Crawl'));

            return result;
        }

        function parse (data) {
            var csv = [];
                var entry = [];
                var issuesStack = ['dead_links_count', 'dead_images_count', 'spelling_errors_confirmed_count'];
            var allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');

            if (!Array.isArray(data) || data.length === 0) {
                return csv;
            }
            csv.push(getHeadlines(allowVerticalAverage));

            for (var i = 0; i < data.length; i++) {
                entry = [];
                entry.push($filter('userDate')(data[i].post_processing_done_at || data[i].date, 'LL LT'));
                entry.push(data[i].page_count);
                entry.push(data[i].documents_count);

                if (data[i].compliancePercentage != null) {
                    entry.push($filter('monNumber')(data[i].compliancePercentage / 100, '0.[00]%'));
                } else {
                    entry.push(getCompliance(data[i]));
                }

                if (allowVerticalAverage) {
                    entry.push(data[i].comparisonToAverage);
                }

                entry.push(data[i].dead_links_count);
                entry.push(data[i].dead_images_count);
                entry.push(data[i].spelling_errors_confirmed_count);
                entry.push(
                    getCaret(data[i].id, data, issuesStack) +
                        ' ' +
                        $filter('monNumber')(historyHelper.getChangePercentageValue(data[i].id, data, issuesStack) / 100, '0.[00]%'),
                );
                csv.push(entry);
            }
            return csv;
        }

        function getCompliance (history) {
            if (!history.qaComplianceData) {
                history.qaComplianceData = complianceService.qaHistoryCompliance(history);
            }
            if (history.qaComplianceData && history.qaComplianceData[0]) {
                return $filter('monNumber')(history.qaComplianceData[0] / 100, '0.[00]%');
            }
            return '';
        }

        function getCaret (id, data, issueStack) {
            var value = historyHelper.getChangeValue(id, data, issueStack);
            if (value > 0) {
                return String.fromCharCode(94);
            } else if (value < 0) {
                return 'ˇ';
            }
            return '';
        }
    }
})();
