(function () {
    'use strict';

    angular.module('modules.moments.services').factory('momentTimezones', momentTimezones);

    momentTimezones.$inject = [];
    /* @ngInject*/
    function momentTimezones () {
        return function () {
            return moment.tz
                .names()
                .map(function (name) {
                    return moment.tz.zone(name);
                })
                .filter(function (zone) {
                    return zone.population > 0;
                });
        };
    }
})();
