import * as _ from 'lodash';

(function () {
    'use strict';

    angular.module('services.api').factory('CookiesRepo', CookiesRepo);

    CookiesRepo.$inject = ['ng2ApiService', 'SupportApi', 'ng2SessionService', 'CookieModel', '$q', 'ng2ErrorHandler'];
    /* @ngInject */
    function CookiesRepo (Api, SupportApi, ng2SessionService, CookieModel, $q, ng2ErrorHandler) {
        var service = {
            getAll: getAll,
            update: update,
            create: create,
            destroy: destroy,
            getCookiesFromCentralDb: getCookiesFromCentralDb,
        };

        return service;

        // /////////////
        async function getCookiesFromCentralDb () {
            let receivedCookies = [];
            let cookies = null;
            let pageIndex = 1;
            const pageSize = 1000;

            do {
                cookies = await SupportApi.get(`cookies?page=${pageIndex}&page_size=${pageSize}`);

                receivedCookies = [...receivedCookies, ...cookies];
                ++pageIndex;
            } while (cookies?.length > 0);
            return receivedCookies;
        }

        function getAll (params, headers) {
            params = params || {};
            var domainId = params.domain_id ? params.domain_id : ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + domainId + '/cookie_banner/cookies', params, headers).then(toModels);
        }

        function update (domainId, cookie, params, headers) {
            params = params || {};
            return ng2ApiService.patch('domains/' + domainId + '/cookie_banner/cookies/' + cookie.id, cookie, params, headers).then(
                function (updatedCookie) {
                    return toModel(updatedCookie);
                },
                function (error) {
                    ng2ErrorHandler.noopError(error, 'info');
                    return $q.reject(error);
                },
            );
        }

        function create (domainId, params, headers) {
            params = params || {};
            return ng2ApiService.post('domains/' + domainId + '/cookie_banner/cookies', params, headers).then(
                function (cookie) {
                    return toModel(cookie);
                },
                function (error) {
                    ng2ErrorHandler.noopError(error, 'info');
                    return $q.reject(error);
                },
            );
        }

        function destroy (domainId, id, params, headers) {
            params = params || {};
            return ng2ApiService.delete('domains/' + domainId + '/cookie_banner/cookies/' + id, params, headers);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new CookieModel(model);
        }
    }
})();
