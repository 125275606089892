import { Component, Inject, OnInit } from '@angular/core';
import numeral from 'numeral';
import { Report } from '@monsido/modules/models/api/report';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import {
    FormScheduleReportService,
} from '@monsido/modules/report-center/services/form-schedule-report/form-schedule-report.service';
import { DomainRepo } from '../../../../../angularjs-providers/domain-repo/domain-repo.types';
import { MeService, TargetType } from '../../../../../angularjs-providers/me-service/me-service.types';
import { ReportCenterService } from '../../../../../angularjs-providers/report-center-service/report-center-service.types';
import { DomainList, ReportTarget } from './schedule-report-form.component.types';
import { User } from '@monsido/modules/models/api/user';
import { SessionService } from '@monsido/core/session/session.service';
import { Targets } from '@monsido/modules/models/api/interfaces/report.interface';
import {
    DomainWithDomainUsers,
} from '@monsido/modules/report-center/services/form-schedule-report/form-schedule-report.types';
import { Domain } from '@monsido/modules/models/api/domain';
import { DomainGroup } from '@monsido/ng2/modules/models/api/domain-group';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { TranslateService } from '@client/app/services/translate/translate.service';


@Component({
    selector: 'mon-schedule-report-form',
    templateUrl: './schedule-report-form.component.html',
    styleUrls: ['./schedule-report-form.component.scss'],
})
export class ScheduleReportFormComponent implements OnInit {
    saving: boolean = false;
    maxIcons: number = 0;
    domainList: DomainList = {};
    open: boolean = false;
    domains: Array<DomainWithDomainUsers> = [];
    report: Report | null = null;
    promise?: Promise<unknown>;
    loading: boolean = false;

    constructor (
        @Inject('DomainRepo') private domainRepo: DomainRepo,
        @Inject('meService') private meService: MeService,
        @Inject('ReportCenterService') private reportCenterService: ReportCenterService,
        private formScheduleReportService: FormScheduleReportService,
        private activeDialog: ActiveDialog,
        private sessionService: SessionService,
        private eventService: MonEventService,
        private translateService: TranslateService,
    ) {}

    ngOnInit (): void {
        this.maxIcons = 10;
        this.domainList = {};
        this.open = true;
        this.promise = this.fetchDomains();
    }

    private async fetchDomains (): Promise<void> {
        if (!this.report) {
            throw new Error('No report');
        }

        this.loading = true;
        const domains = await this.domainRepo.getAll({ page_size: 1000 });
        const filteredDomains = this.reportCenterService.filterDomainsByReport(this.report, domains);
        this.domains = this.formScheduleReportService.setupDomainUsers(filteredDomains, this.report);
        this.loading = false;
    }

    private receivedReport (): Targets | undefined {
        return this.report?.targets.find((target) => {
            if (this.sessionService.me) {
                return this.hasUser(this.sessionService.me, target.users);
            }
        });
    }

    private hasUser (currentUser: User, users: User[]): User | undefined {
        return users.find((user) => {
            if (user.user) {
                return currentUser.id === user.user.id;
            }
        });
    }

    private closeDialog (data: Report): void {
        this.activeDialog.close(data);
    }

    private createReport (): void {
        if (!this.report) {
            return;
        }
        this.saving = true;
        const targets: Array<{id: number, type: 'Domain' | 'DomainGroup'}> = [];
        const params = {
            template_id: this.report.template.id,
            targets: targets,
        };

        for (const domainID in this.domainList) {
            if (this.domainList[domainID]) {
                params.targets.push({
                    id: parseInt(domainID),
                    type: this.domainList[domainID] as unknown as 'Domain',
                });
            }
        }

        this.promise = this.meService
            .createReport(params)
            .then((data) => {
                this.report = data;
                this.closeDialog(data);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    private updateReport (): void {
        if (!this.report) {
            return;
        }
        this.saving = true;
        const targets: {id: number, type: TargetType}[] = [];
        const params = {
            targets: targets,
        };

        for (const domainId in this.domainList) {
            if (this.domainList[domainId]) {
                params.targets.push({
                    id: parseInt(domainId),
                    type: this.domainList[domainId],
                });
            }
        }

        this.promise = this.meService
            .updateReport(this.report.id, params)
            .then((data) => {
                this.closeDialog(data);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    onChange (type: ReportTarget['type'], domainId: Domain['id']): void {
        if (this.domainList[domainId]) {
            delete this.domainList[domainId];
        } else {
            this.domainList[domainId] = type;
        }
    }

    domainGroupById (index: number, domainGroup: DomainGroup): number {
        return domainGroup.id;
    }

    getUsersName (users: User[]): string {
        let userName = '';
        const userLength = users.length;
        const maxLength = Math.min(this.maxIcons + 10, userLength); // We only intend to list 10 users name in the tooltip.
        for (let i = this.maxIcons; i < maxLength; i++) {
            const user = users[i];
            userName += user.first_name + ' ' + user.last_name + '<br>';
        }

        if (userLength > maxLength) {
            userName = this.translateService.getString('{{users}}<strong>and {{userCountLeft}} more</strong>', {
                users: userName,
                userCountLeft: numeral(userLength - maxLength).value() || -1,
            });
        }

        return userName;
    }

    sendReport (): void {
        this.eventService.run('actionTrigger', { action: 'schedule-report-save' });
        if (!this.receivedReport()) {
            this.createReport();
        } else {
            this.updateReport();
        }
    }
}
