(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboard', {
        templateUrl: 'app/pages/global/changes-history/dashboard/dashboard.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            selectedModule: '<',
            progress: '<',
            loading: '<',
            selectedGroupOption: '=',
            setupLineChart: '&',
            domainGroup: '<',
            showScores: '<',
        },
    });

    DashboardController.$inject = ['gettextCatalog', '$filter', 'ChangesHistoryDashboardService'];
    /* @ngInject */
    function DashboardController (gettextCatalog, $filter, ChangesHistoryDashboardService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.selectGroupOption = selectGroupOption;

        vm.showIssuesSummary = false;
        vm.hasAttributeWithName = false;
        vm.showTotalContent = false;
        vm.modulesWithoutIssuesSummary = ['readability', 'performance'];
        vm.modulesWithoutTotalContent = ['performance'];

        vm.issuesTooltip;
        vm.totalIssuesTooltip;
        vm.pageStackStartValue;
        vm.pageStackEndValue;
        vm.pageStackChangeValue;
        vm.issuesStackStartValue;
        vm.issuesStackEndValue;
        vm.issuesStackChangeValue;
        vm.pagesStackByModuleStartValue;
        vm.pagesStackByModuleEndValue;
        vm.pagesStackByModuleChangeValue;
        vm.history = [];

        function activate () {
            vm.selectedGroupOption = vm.selectedGroupOption || null;
            vm.override = {};
            vm.series = [];
            vm.colors = [];
            vm.labels = [];
            vm.data = [];
            vm.options = {};
            vm.pagesStack = [];
            vm.pagesStackByModule = [];
            vm.issuesStack = [];
            vm.scoresChartData = [];
            vm.scoresChartSeries = [];
            setupWatchers();
        }

        function onChanges (changes) {
            if (changes.selectedModule) {
                vm.issuesStack = getIssuesStack();
                vm.pagesStackByModule = getPagesStackByModule();
                vm.totalIssuesTooltip = getTotalIssuesTooltip();
                vm.issuesTooltip = getIssuesTooltip();
                vm.showIssuesSummary = showIssuesSummary(vm.selectedModule);
                vm.showTotalContent = showTotalContent(vm.selectedModule);
            }

            if (changes.showScores) {
                setupData();
            }
        }

        function setupWatchers () {
            ChangesHistoryDashboardService.chartChangeObs.subscribe(setupData);
            ChangesHistoryDashboardService.pagesStackObs.subscribe(function () {
                vm.pagesStack = ChangesHistoryDashboardService.getPagesStack();
                updatePageStack();
            });
        }

        function selectGroupOption (selectedGroup) {
            ChangesHistoryDashboardService.setGroupOption(selectedGroup);
        }

        function getChangeValue (arrayValues) {
            var end = $filter('addObjectAttributes')(vm.history[0], arrayValues);
            var start = $filter('addObjectAttributes')(vm.history[vm.history.length - 1], arrayValues);
            return getChange(start, end);
        }

        function getStartValue (arrayValues) {
            return $filter('addObjectAttributes')(vm.history[0], arrayValues);
        }

        function getEndValue (arrayValues) {
            return $filter('addObjectAttributes')(vm.history[vm.history.length - 1], arrayValues);
        }

        function getIssuesStack () {
            var issues = [];

            if (vm.selectedModule === 'accessibility' || vm.selectedModule === 'all') {
                issues = issues.concat(['accessiblity_issues_count']);
            }

            if (vm.selectedModule === 'seo' || vm.selectedModule === 'all') {
                issues = issues.concat(['seo_errors_alerts_count', 'seo_errors_warnings_count', 'seo_errors_infos_count']);
            }

            if (vm.selectedModule === 'qa' || vm.selectedModule === 'all') {
                issues = issues.concat(['dead_links_count', 'dead_images_count', 'spelling_errors_confirmed_count']);
            }

            if (vm.selectedModule === 'policy' || vm.selectedModule === 'all') {
                issues = issues.concat(['policy_with_violations_count', 'policy_with_required_count']);
            }

            if (vm.selectedModule === 'data-privacy') {
                issues = ['data_protection_violations_count'];
            }

            return issues;
        }

        function getTotalIssuesTooltip () {
            switch (vm.selectedModule) {
                case 'accessibility':
                    return gettextCatalog.getString('Accessibility checks not in compliance with domain');
                case 'seo':
                    return gettextCatalog.getString('Contains high, medium or low opportunities');
                case 'qa':
                    return gettextCatalog.getString('Contains confirmed misspellings, broken links or images');
                case 'policy':
                    return gettextCatalog.getString('Policies marked as required or unwanted');
                default:
                    return gettextCatalog.getString('Total number of issues across all modules');
            }
        }

        function getIssuesTooltip () {
            switch (vm.selectedModule) {
                case 'accessibility':
                    return gettextCatalog.getString('Pages with accessibility checks not in compliance');
                case 'seo':
                    return gettextCatalog.getString('Pages with high, medium or low opportunities');
                case 'qa':
                    return gettextCatalog.getString('Pages & documents with confirmed misspellings, broken links or images');
                case 'policy':
                    return gettextCatalog.getString('Pages and documents with policies marked as unwanted or required');
                case 'data-privacy':
                    return gettextCatalog.getString('Pages with Data Privacy violations');
                default:
                    return gettextCatalog.getString('Summarized pages and documents count');
            }
        }

        function getPagesStackByModule () {
            switch (vm.selectedModule) {
                case 'accessibility':
                    return ['pages_with_accessibility_errors_count'];
                case 'seo':
                    return ['pages_with_seo_errors_count'];
                case 'qa':
                    return ['pages_with_notifications_count'];
                case 'policy':
                    return ['pages_with_policy_matches_count'];
                case 'readability':
                    return ['pages_count', 'documents_count'];
                case 'data-privacy':
                    return ['data_protection_pages_with_violations_count'];
                case 'performance':
                    return ['performance_page_count'];
                default:
                    return ['pages_with_errors_count'];
            }
        }

        function hasAttributeWithName (collection, param, val) {
            for (var i = 0; i < collection.length; i++) {
                if (collection[i][param] === val) {
                    return true;
                }
            }
            return false;
        }

        function getChange (start, end) {
            if (!angular.isNumber(start) || !angular.isNumber(end)) {
                return 0;
            }
            var highestNumber = Math.max(start, end);
            var lowestNumber = Math.min(start, end);
            return 100 - (lowestNumber / highestNumber) * 100 || 0;
        }

        function setupData () {
            vm.history = ChangesHistoryDashboardService.getHistory();
            vm.scoresChartData = [];
            vm.groupOptions = ChangesHistoryDashboardService.getGroupOptions();
            vm.selectedGroupOption = ChangesHistoryDashboardService.getDefaultGroupOption();

            if (vm.showScores) {
                setupScoresChart();
            } else {
                setupChartData();
            }

            updatePageStack();

            if (vm.issuesStack.length) {
                vm.issuesStackStartValue = getStartValue(vm.issuesStack);
                vm.issuesStackEndValue = getEndValue(vm.issuesStack);
                vm.issuesStackChangeValue = getChangeValue(vm.issuesStack);
            }

            if (vm.pagesStackByModule.length) {
                vm.pagesStackByModuleStartValue = getStartValue(vm.pagesStackByModule);
                vm.pagesStackByModuleEndValue = getEndValue(vm.pagesStackByModule);
                vm.pagesStackByModuleChangeValue = getChangeValue(vm.pagesStackByModule);
            }

            if (vm.selectedModule) {
                vm.hasAttributeWithName = hasAttributeWithName(vm.groupOptions, 'belongsTo', vm.selectedModule) && !vm.showScores;
            }
        }

        function updatePageStack () {
            if (vm.history && vm.history.length && vm.pagesStack && vm.pagesStack.length) {
                vm.pageStackStartValue = getStartValue(vm.pagesStack);
                vm.pageStackEndValue = getEndValue(vm.pagesStack);
                vm.pageStackChangeValue = getChangeValue(vm.pagesStack);
            }
        }

        function showIssuesSummary (module) {
            return vm.modulesWithoutIssuesSummary.indexOf(module) === -1;
        }

        function showTotalContent (module) {
            return vm.modulesWithoutTotalContent.indexOf(module) === -1;
        }

        function setupChartData () {
            vm.colors = ChangesHistoryDashboardService.getColors();
            vm.override = ChangesHistoryDashboardService.getOverride();
            vm.labels = ChangesHistoryDashboardService.getLabels();
            ChangesHistoryDashboardService.getOptions().then(function (options) {
                vm.options = options;
                vm.series = ChangesHistoryDashboardService.getSeries();
                vm.data = ChangesHistoryDashboardService.getData();
            }, angular.noop);
        }

        function setupScoresChart () {
            vm.scoresChartData = [
                ChangesHistoryDashboardService.getProcessedScoresData(vm.selectedModule, vm.history),
                ChangesHistoryDashboardService.getVerticalScores(vm.selectedModule, vm.history),
            ];
            vm.scoresChartOptions = ChangesHistoryDashboardService.getScoresChartOptions();
            vm.scoreLabels = ChangesHistoryDashboardService.getLabels();
            vm.scoresChartSeries = ChangesHistoryDashboardService.getScoresChartSeries(vm.selectedModule);
            vm.scoresChartColors = ChangesHistoryDashboardService.getScoresChartColors();
            vm.scoresChartOverride = ChangesHistoryDashboardService.getScoresChartOverride();
        }
    }
})();
