angular.module('services').service('uibModalOpenerService', uibModalOpenerService);

uibModalOpenerService.$inject = [
    '$document',
    '$uibModal',
    '$timeout',
];

/* @ngInject*/
function uibModalOpenerService (
    $document,
    $uibModal,
    $timeout,
) {
    return {
        open: open,
    };

    function open (options) {
        // This is a hack for common-dialog-wrapper.
        // uibModal attaches itself as a first child of parent element, what breaks
        // our logic of dialog detection

        let $dialogWrapper = $document.find('#common-dialog-wrapper').eq(0);

        if (!$dialogWrapper[0]) {
            $dialogWrapper = $document.find('body');
        }

        const $modalParent = angular.element('<div>');
        $dialogWrapper.append($modalParent);

        const modal = $uibModal.open(Object.assign(options, {
            appendTo: $modalParent,
        }));

        modal.result.finally(() => {
            $timeout(function () {
                $modalParent.remove();
            }, 300);
        });

        return modal;
    }
}
