(function () {
    'use strict';

    angular.module('core.services', [
        'core.services.domain',
        'core.services.login',
        'core.services.error-handler',
        'core.services.check-for-duplicates',
        'core.services.redirect-whitelist',
    ]);
})();
