(function () {
    'use strict';
    /**
     * @desc Service that contains all PageFix Accessibility Checks that has been patched within the last 5 minutes or until the check has been updated.
     */
    angular
        .module('modules.page_fix.components')
        .service('PageFixAccessibilityChecksMapService', PageFixAccessibilityChecksMapService);

    PageFixAccessibilityChecksMapService.$inject = [];

    /* @ngInject*/
    function PageFixAccessibilityChecksMapService () {
        var vm = this;
        vm.checksMap = new Set();
        vm.updatedAccessibilityCheck = new Set();
        vm.deletedPageFix = new Set();

        return {
            addAccessibilityCheck: addAccessibilityCheck,
            hasDirtyAccessibilityCheck: hasDirtyAccessibilityCheck,
            clearAccessibilityCheck: clearAccessibilityCheck,
            addDeletedPageFix: addDeletedPageFix,
            hasDeletedPageFix: hasDeletedPageFix,
            removeUpdatedAccessibilityCheck: removeUpdatedAccessibilityCheck,
            addUpdatedAccessibilityCheck: addUpdatedAccessibilityCheck,
            hasAccessibilityCheckUpdated: hasAccessibilityCheckUpdated,
        };

        // ///////////////

        function addAccessibilityCheck (check) {
            vm.checksMap.add(check.id);
        }

        function hasDirtyAccessibilityCheck (check) {
            return vm.checksMap.has(check.id);
        }

        function clearAccessibilityCheck (checkId) {
            vm.checksMap.delete(checkId);
        }

        function addDeletedPageFix (pageFix) {
            vm.deletedPageFix.add(pageFix.id);
        }

        function hasDeletedPageFix (pageFix) {
            return vm.deletedPageFix.has(pageFix.id);
        }

        function removeUpdatedAccessibilityCheck (check) {
            vm.updatedAccessibilityCheck.delete(check.id);
        }

        function addUpdatedAccessibilityCheck (check) {
            vm.updatedAccessibilityCheck.add(check.id);
        }

        function hasAccessibilityCheckUpdated (check) {
            return vm.updatedAccessibilityCheck.has(check.id);
        }
    }
})();
