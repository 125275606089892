(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name accountService
     * @description Service to handle accounts
     */
    angular.module('services.entities').factory('accountService', accountService);

    accountService.$inject = ['AccountRepo'];
    /* @ngInject*/
    function accountService (AccountRepo) {
        return {
            update: update,
        };

        function update (data, headers) {
            return AccountRepo.update(data, headers);
        }
    }
})();
