(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name SEOHelpMessageService
     * @description Manage help messages for SEO Checkpoints
     */
    angular.module('blocks.service').factory('SEOHelpMessageService', SEOHelpMessageService);

    SEOHelpMessageService.$inject = ['gettextCatalog'];

    function SEOHelpMessageService (gettextCatalog) {
        var vm = this;

        return {
            canShowMessageForDomain: canShowMessageForDomain,
            getHelpMessage: getHelpMessage,
        };

        function getHelpMessage (checkpoint) {
            var message = '';
            switch (checkpoint.name) {
                case 'seo_missing_h1':
                    message = gettextCatalog.getString('There is no H1 on one or more pages');
                    break;
                case 'seo_missing_meta_description':
                    message = gettextCatalog.getString('There are no META descriptions on one or more pages');
                    break;
                case 'seo_missing_meta_keyword':
                    message = gettextCatalog.getString('There are zero META keywords on one or more pages');
                    break;
                case 'seo_missing_subheadlines':
                    message = gettextCatalog.getString('There are no sub headlines on one or more pages');
                    break;
                case 'seo_missing_title':
                    message = gettextCatalog.getString('No title found on one or more pages');
                    break;
                case 'seo_no_index':
                    message = gettextCatalog.getString('One or more pages has been marked as no index');
                    break;
            }
            return message;
        }

        /**
         * @memberOf SEOHelpMessageService
         * @param {object} checkpoint - SEO checkpoint
         * @return {boolean}
         */
        function canShowMessageForDomain (checkpoint) {
            switch (checkpoint.name) {
                case 'seo_missing_h1':
                case 'seo_missing_title':
                case 'seo_missing_subheadlines':
                case 'seo_missing_meta_description':
                case 'seo_missing_meta_keyword':
                case 'seo_no_index':
                    return true;
                case 'seo_too_short_meta_description':
                case 'seo_too_short_copy':
                case 'seo_too_many_page_links':
                case 'seo_too_long_url':
                case 'seo_too_long_meta_description':
                case 'seo_no_follow':
                case 'seo_multiple_h1':
                case 'seo_duplicate_h1':
                case 'seo_duplicate_title':
                case 'seo_missing_alt':
                    return false;
                default:
                    return false;
            }
        }

        // PROTECTED
    }
})();
