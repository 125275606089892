import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import colors from '@monsido/colors/dist/es6/colors';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@client/app/modules/translate.module';

type TargetIcons = 'unwanted' | 'required' | 'search' | 'text' | 'seo' | 'error' | 'warning' | 'review' | 'content';


@Component({
    selector: 'mon-table-policy-type',
    standalone: true,
    imports: [CommonModule, IconsModule, TranslateModule, NgbTooltipModule],
    templateUrl: './table-policy-type.component.html',
    styleUrls: ['./table-policy-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePolicyTypeComponent {
    @Input() target!: TargetIcons;
    @Input() tooltip?: string;
    @Input() passed?: boolean;
    @Input() isMobileCheck?: boolean = false;
    @Input() color: string = '';

    options = {
        unwanted: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'UNWANTED'],
        required: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'REQUIRED'],
        search: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'SEARCH'],
        text: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'TEXT'],
        seo: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'SEO'],
        error: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'ERROR'],
        warning: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'WARNING'],
        review: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'REVIEW'],
        content: ['GLOBAL_POLICY_OVERVIEW', 'POLICY_TYPE', 'CONTENT'],
    };

    iconColors = {
        'wcag-a': colors['secondary-12'],
        'wcag-aa': colors['secondary-13'],
        'wcag-aaa': colors['secondary-14'],
        'passed': colors['secondary-16'],
    };
}
