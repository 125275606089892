(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name accessibilityHasGuidelineId
     * @description Check if domain uses selected guideline id
     */

    angular.module('filters.monsido').filter('accessibilityHasGuidelineId', AccessibilityHasGuidelineIdFilter);

    AccessibilityHasGuidelineIdFilter.$inject = ['ng2SessionService'];

    /* @ngInject */
    function AccessibilityHasGuidelineIdFilter (sessionService) {
        const guidelines = {
            'WCAG2-A': ['WCAG2-A'],
            'WCAG2-AA': ['WCAG2-A', 'WCAG2-AA'],
            'WCAG2-AAA': ['WCAG2-A', 'WCAG2-AA', 'WCAG2-AAA'],
            'WCAG21-A': ['WCAG21-A'],
            'WCAG21-AA': ['WCAG21-A', 'WCAG21-AA'],
            'WCAG21-AAA': ['WCAG21-A', 'WCAG21-AA', 'WCAG21-AAA'],
            'WCAG22-A': ['WCAG22-A'],
            'WCAG22-AA': ['WCAG22-A', 'WCAG22-AA'],
            'WCAG22-AAA': ['WCAG22-A', 'WCAG22-AA', 'WCAG22-AAA'],
            'WCAG21-A_mobile': ['WCAG21-A_MOBILE'],
            'WCAG21-AA_mobile': ['WCAG21-A_MOBILE', 'WCAG21-AA_MOBILE'],
            'WCAG21-AAA_mobile': ['WCAG21-A_MOBILE', 'WCAG21-AA_MOBILE', 'WCAG21-AAA_MOBILE'],
        };

        return filter;

        /**
         * @param {String} guidelineId
         * @param {Object} [domain] - Selected domain
         * @return {Boolean}
         */
        function filter (guidelineId, domain) {
            if (typeof guidelineId !== 'string') {
                return false;
            }

            const selectedDomain = domain || sessionService.domain;
            guidelineId = guidelineId.toUpperCase();

            if (selectedDomain === null || typeof selectedDomain === 'undefined') {
                return false;
            }

            if (typeof selectedDomain.features.accessibility !== 'string') {
                return false;
            }

            let accessibility = selectedDomain.features.accessibility;

            const indexOfVersion = accessibility.search(/_v(\d+)?/);
            /* Makes accessibility guidelines independent of version for displaying */
            if (indexOfVersion > -1) {
                accessibility = accessibility.slice(0, indexOfVersion);
            }

            return guidelines[accessibility] && guidelines[accessibility].includes(guidelineId);
        }
    }
})();
