(function () {
    'use strict';
    angular.module('pages.search.content').component('policyContent', {
        templateUrl: 'app/pages/search/content/policy-content/policy-content.html',
        controller: PolicyContentController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
        },
    });

    PolicyContentController.$inject = ['SearchRepo', '$controller'];

    function PolicyContentController (SearchRepo, $controller) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.pages = [];
            vm.tabs = [];

            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                search_violations: true,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            vm.promise = SearchRepo.getAllSearchPages(vm.policy.id, params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }
    }
})();
