(function () {
    'use strict';

    angular.module('services.api').factory('DomainRepo', DomainRepo);

    DomainRepo.$inject = ['ng2ApiService', 'DomainGroupModel', 'ng2SessionService', 'Domain', 'Lodash', '$deepmerge', '$q', 'ng2ErrorHandler'];
    /* @ngInject */
    function DomainRepo (ng2ApiService, DomainGroupModel, ng2SessionService, Domain, Lodash, $deepmerge, $q, ng2ErrorHandler) {
        var service = {
            get: get,
            getAll: getAll,
            create: create,
            rescan: rescan,
            update: update,
            destroy: destroy,
            stopCrawl: stopCrawl,
            updateDomainGroupRules: updateDomainGroupRules,
            getExcludedIps: getExcludedIps,
            flattenDomains: flattenDomains,
        };

        return service;

        // /////////////

        function getAll (params, headers) {
            params = params || {};
            return ng2ApiService.get('domains', params, headers).then(function (domains) {
                domains.forEach(function (domain) {
                    if (domain) {
                        domain.domain_groups = domainGroupToModels(domain.domain_groups);
                    }
                });
                domains.flattenDomains = flattenDomains;

                return domainToModels(domains);
            });
        }

        function get (id, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + id, params, headers).then(function (domain) {
                if (domain) {
                    domainGroupToModels(domain.domain_groups);
                }
                return domainToModel(domain);
            });
        }

        function create (params, headers) {
            return ng2ApiService.post('domains', params, headers).then(
                function (domain) {
                    if (domain) {
                        domainGroupToModels(domain.domain_groups);
                    }
                    return domainToModel(domain);
                },
                function (error) {
                    ng2ErrorHandler.noopError(error, 'info');
                    return $q.reject(error);
                },
            );
        }

        function rescan (id, params) {
            params = params || {};
            return ng2ApiService.post('domains/' + id + '/rescan', params);
        }

        function update (id, data, headers) {
            data = data || {};
            return ng2ApiService.patch('domains/' + id, data, null, headers).then(
                function (domain) {
                    if (domain) {
                        domainGroupToModels(domain.domain_groups);
                    }
                    return domainToModel(domain);
                },
                function (error) {
                    ng2ErrorHandler.noopError(error, 'info');
                    return $q.reject(error);
                },
            );
        }

        function updateDomainGroupRules (id, params, headers) {
            params = params || {};
            return ng2ApiService.post('domains/' + id + '/domain_groups/update', params, headers);
        }

        function destroy (id, params, headers) {
            params = params || {};
            return ng2ApiService.delete('domains/' + id, params, headers);
        }

        function stopCrawl (id) {
            return ng2ApiService.delete('domains/' + id + '/crawls/running');
        }

        function flattenDomains () {
            var result = [];
            var domains = $deepmerge([], this);
            Lodash.forEach(domains, function (domain) {
                domain.type = 'Domain';
                result.push(domain);
                domain.domain_groups.forEach(function (group) {
                    group.domainId = domain.id;
                    group.type = 'DomainGroup';
                    result.push(group);
                });
            });
            return result;
        }

        // PROTECTED

        function domainGroupToModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = domainGroupToModel(models[i]);
            }
            return models;
        }

        function domainGroupToModel (model) {
            if (model) {
                return new DomainGroupModel(model);
            }
            return {};
        }

        function domainToModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = domainToModel(models[i]);
            }
            return models;
        }

        function domainToModel (model) {
            return new Domain(model);
        }

        function getExcludedIps (id, params, headers) {
            return ng2ApiService.get(`domains/${id}/excluded_ips`, params, headers);
        }
    }
})();
