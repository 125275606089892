(function () {
    'use strict';

    angular.module('models').factory('ReportModel', ReportModel);

    ReportModel.$inject = ['User'];

    function ReportModel (User) {
        function Model (attributes) {
            angular.extend(this, attributes);
            setupTargets(this);
        }

        return Model;

        // /////////

        function setupTargets (model) {
            if (model !== null && typeof model === 'object' && Array.isArray(model.targets)) {
                model = model.targets.map(function (target) {
                    target.users = setupUsers(target.users);
                    return target;
                });
            }

            return model;
        }

        function setupUsers (users) {
            if (Array.isArray(users)) {
                return users.map(toUserModel);
            }

            return [];
        }

        function toUserModel (user) {
            return new User(user);
        }
    }
})();
