import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('core.services.domain').factory('coreDomainService', coreDomainService);

    coreDomainService.$inject = [
        'ng2SessionService',
        'rx',
        '$interval',
        'DomainRepo',
        'ng2ErrorHandler',
        '$localStorage',
        '$stateParams',
        'Lodash',
        'ng2MonEventsService',
        'gettextCatalog',
        'ng2MonModalService',
        'domainService',
        '$q',
        'AdminDomainsService',
        'meService',
    ];

    /* @ngInject*/
    function coreDomainService (
        ng2SessionService,
        rx,
        $interval,
        DomainRepo,
        ng2ErrorHandler,
        $localStorage,
        $stateParams,
        Lodash,
        ng2MonEventsService,
        gettextCatalog,
        ng2MonModalService,
        domainService,
        $q,
        AdminDomainsService,
        meService,
    ) {
        var vm = this;
        vm.updateDomainFailedAttempts = 0;
        vm.getDomainsPromise = null;
        vm.updateDomainPromise = null;

        var service = {
            disposeDomains: function () {
                var me = this;
                if (ng2SessionService.domains !== null) {
                    me.emptyDomains();
                    me.cancelDomainPromise();
                    if (!ng2SessionService.domains.isStopped) {
                        ng2SessionService.domains.unsubscribe();
                    }
                    ng2SessionService.domains = null;
                }
            },
            setDomain: function (domain) {
                ng2SessionService.domain = domain;
                if (ng2SessionService.domain !== null && ng2SessionService.domainGroup !== null) {
                    // Check if domain and domainGroup is set
                    var domainGroups = ng2SessionService.domain.domain_groups.map(function (group) {
                        return group.id;
                    });
                    if (domainGroups.indexOf(ng2SessionService.domainGroup.id) === -1) {
                        // If domain group doesn't exist under the domain
                        this.removeDomainGroup();
                    }
                }
                ng2SessionService.domainObs.next(domain);
            },
            setDomainGroup: function (domainGroup) {
                ng2SessionService.domainGroup = domainGroup;
                $localStorage.domainGroup = domainGroup;
            },

            clearDomain: function () {
                ng2SessionService.domain = null;
            },

            removeDomainGroup: function () {
                delete $stateParams.domain_group;
                ng2SessionService.domainGroup = null;
                $localStorage.domainGroup = null;
            },
            emptyDomains: function () {
                if (ng2SessionService.domains) {
                    ng2SessionService.domains.next([]);
                }
            },
            setupDomainGroupAfterReload: function () {
                ng2SessionService.domainGroup = $localStorage.domainGroup || null;
            },
            getDomainsData: function (params, domainArray) {
                var me = this;

                if (!params.page) {
                    params.page = 1;
                } else {
                    params.page++;
                }

                return DomainRepo.getAll(params).then(function (domains) {
                    domainArray = domainArray.concat(domains);
                    if (domains.length < params.page_size) {
                        return domainArray;
                    }
                    return me.getDomainsData(params, domainArray);
                }, angular.noop);


            },
            getDomains: function () {
                var me = this;
                var params = { page_size: 100, mini: true };
                var domainsArray = [];

                me.getDomainsData(params, domainsArray).then(function (domains) {
                    domains = domains.sort(function (a, b) {
                        return a.favorite && !b.favorite ? -1 : !a.favorite && b.favorite ? 1 : 0;
                    });
                    domains.forEach(function (domain) {
                        domain.domain_groups = Lodash.sortBy(domain.domain_groups, ['title']);
                    });
                    // HOTFIX please replace later - https://acquia.atlassian.net/browse/MON-7638
                    domains.flattenDomains = DomainRepo.flattenDomains;
                    ng2SessionService.domains.next(domains);
                })
                    .finally(function () {
                        me.cancelDomainPromise();
                        vm.getDomainsPromise = $interval(function () {
                            me.getDomains();
                        }, 900000);
                    });
            },
            disposeDomain: function () {
                if (!ng2SessionService.domainObs.isStopped) {
                    ng2SessionService.domainObs.unsubscribe();
                }
            },
            cancelDomainPromise: function () {
                if (vm.getDomainsPromise !== null) {
                    $interval.cancel(vm.getDomainsPromise);
                    vm.getDomainsPromise = null;
                }
            },
            refreshDomain: function () {
                return updateDomain(true);
            },
            init: function () {
                ng2SessionService.resetDomains();
                cancelUpdateDomainPromise();
                vm.updateDomainPromise = $interval(function () {
                    updateDomain();
                }, 90000); // 1 minute and 30 seconds
            },

            openDomainForm: function (domain, domainFormComponent) {
                var deferred = $q.defer();
                domain = domain || {};
                var params = {
                    body: domainFormComponent,
                    size: 'sm',
                    data: {
                        domain: domain,
                    },
                };

                const callback = function (domain) {
                    deferred.resolve(domain);
                };

                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
                return deferred.promise;
            },

            deleteDomain: function (domain) {
                var msg = gettextCatalog.getString(
                    'You are about to delete the domain <span class=\'text-link\'>{{domainTitle}}</span>' +
                        'please type <code>Delete</code> to confirm this action' +
                        '<div class=\'alert alert-danger py-1 px-1 mb-0 mt-3\'>' +
                        'By deleting this domain all domain groups related to this domain, will also be deleted</div>',
                    { domainTitle: domain.title },
                );

                var defer = $q.defer();
                ng2MonModalService.prompt(msg).then(function (result) {
                    if ('delete' !== result.toLowerCase().trim()) {
                        defer.reject();
                    } else {
                        domainService.destroy(domain.id).then(defer.resolve);
                    }
                }, angular.noop);
                return defer.promise;
            },

            toggleDomainPinned: function (domain) {
                return meService.updateDomain(domain.id, {
                    favorite: !domain.favorite,
                });
            },

            openScriptGuide: function (domain, bodyComponentName) {
                var params = {
                    body: bodyComponentName,
                    size: 'sm',
                    data: { domain: domain },
                };

                const callback = function () {
                    AdminDomainsService.updateDomainSettings(domain);
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
            },

            showCmsUrl: function (domain) {
                return ['sitecore', 'omniupdate', 'publicera', 'sitefinity', 'custom', 'cascade', 'aem', 'acos'].indexOf(domain.cms) !== -1;
            },

            showOptionalCmsUrl: function (domain) {
                return ['drupal', 'kentico', 'episerver'].indexOf(domain.cms) !== -1;
            },

            showSpecialKey: function (domain) {
                return ['special'].indexOf(domain.cms) !== -1;
            },

            showPrefixPath: function (domain) {
                return ['wordpress_multisite'].indexOf(domain.cms) !== -1;
            },

            showTemplatePath: function (domain) {
                return ['umbraco'].indexOf(domain.cms) !== -1;
            },

            getTemplatePlaceholder: function (domain) {
                if (['umbraco'].indexOf(domain.cms) !== -1) {
                    return '/umbraco/umbraco.aspx?app=content&rightAction=editContent&id=<%= id %>#content';
                }
            },

            getUrl: function (domain) {
                if (domain.cms === 'custom') {
                    return 'base_url';
                }
                return 'url';
            },

            setupScanLogin: function (loginOptions) {
                var login = null;
                if (loginOptions && loginOptions.type !== 'none') {
                    var optionsType = loginOptions.type;
                    login = {
                        type: optionsType,
                        verify: loginOptions.verify,
                    };
                    login[optionsType] = loginOptions[optionsType];
                }

                return login;
            },

            openScanDetails: function () {
                var params = {
                    body: 'scanDetailsDialog',
                    size: 'sm',
                    classes: 'fade-animation middle center',
                    data: {
                        domainObservable: ng2SessionService.domainObs,
                    },
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
            },

            hasCrawlStatus: function () {
                return ng2SessionService.domain !== null && ng2SessionService.domain.crawl_status !== null;
            },

            getDomainCrawlStatus: function () {
                if (ng2SessionService.domain !== null && ng2SessionService.domain.crawl_status !== null) {
                    return ng2SessionService.domain.crawl_status.progress;
                } else {
                    return 0;
                }
            },
        };

        return service;

        // Private

        function updateDomain (force) {
            if (force || ng2SessionService.domain !== null) {
                var params = {};
                params.group_id = ng2SessionService.domainGroup ? ng2SessionService.domainGroup.id : null;
                if (!ng2SessionService.domain) {
                    return;
                }
                return DomainRepo.get(ng2SessionService.domain.id, params).then(
                    function (domain) {
                        vm.updateDomainFailedAttempts = 0;
                        // Check if domain changed after fetching details
                        if (ng2SessionService.domain !== null && domain.id === ng2SessionService.domain.id) {
                            service.setDomain(domain);
                        }
                    },
                    function (error) {
                        vm.updateDomainFailedAttempts++;
                        if (vm.updateDomainFailedAttempts < 3) {
                            updateDomain();
                        } else {
                            ng2ErrorHandler.noopError(error, 'warning');
                        }
                    },
                );
            }
        }

        function cancelUpdateDomainPromise () {
            if (vm.updateDomainPromise !== null) {
                $interval.cancel(vm.updateDomainPromise);
                vm.updateDomainPromise = null;
            }
        }
    }
})();
