import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Observable } from 'rxjs';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';

@Injectable({ providedIn: 'root' })
export class ExportService {

    constructor (private client: ApiClient, private ng2SessionService: SessionService) { }

    public create (params): Observable<unknown> {
        return this.client.postObservable('exports' , params);
    }

    public get (exportId: number, params?): Observable<unknown> {
        params = params || {};
        params.group_id = this.ng2SessionService.getGroupId(params);
        if (params.group_id == null) {
            delete params.group_id;
        }
        return this.client.getObservable('exports/' + exportId, params);
    }

    public getExports (params): Observable<unknown> {
        params = params || {};
        params.group_id = this.ng2SessionService.getGroupId(params);
        if (params.group_id == null) {
            delete params.group_id;
        }
        return this.client.getObservable('exports', params);
    }
}
