(function () {
    'use strict';

    angular.module('models').factory('CookieModel', CookieModel);

    CookieModel.$inject = [];
    function CookieModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        return Model;
    }
})();
