(function () {
    'use strict';

    angular.module('pages.search').component('policyDashboardSummary', {
        templateUrl: 'app/pages/search/dashboard/summary/summary.html',
        controller: PolicyDashboardSummaryController,
        controllerAs: 'vm',
        bindings: {
            policies: '<',
        },
    });

    PolicyDashboardSummaryController.$inject = ['gettextCatalog'];

    function PolicyDashboardSummaryController (gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            var max;
            vm.chartLabels = [
                gettextCatalog.getString('Unwanted'),
                gettextCatalog.getString('Required'),
                gettextCatalog.getString('Searches'),
            ];
            vm.chartData = [
                [
                    vm.policies.unwantedPolicies.filter(function (policy) {
                        return policy.pages_count > 0;
                    }).length,
                    vm.policies.requiredPolicies.filter(function (policy) {
                        return policy.pages_count > 0;
                    }).length,
                    vm.policies.matchesPolicies.filter(function (policy) {
                        return policy.pages_count > 0;
                    }).length,
                ],
            ];
            vm.chartSeries = [''];
            max = vm.chartData[0].reduce(function (a, b) {
                return Math.max(a, b);
            }, 0);
            vm.chartOptions = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            ticks: {
                                min: 0,
                                max: max * 1.5,
                            },
                            display: false,
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            categoryPercentage: 0.5,
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                fontColor: '#a6a6a6',
                                fontSize: 11,
                            },
                        },
                    ],
                },
                drawLabelOnChart: true,
            };

            vm.barColors = ['#a8d6f3', '#a8d6f3', '#a8d6f3'];

            vm.chartOverrides = [
                {
                    borderWidth: 0,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
            ];
        }
    }
})();
