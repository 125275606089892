(function () {
    'use strict';

    angular.module('services.api').factory('SpellingToolLanguagesRepo', SpellingToolLanguagesRepo);

    SpellingToolLanguagesRepo.$inject = ['ng2ApiService'];
    /* @ngInject */
    function SpellingToolLanguagesRepo (ng2ApiService) {
        return {
            get: get,
        };

        // /////////////

        function get (params, config) {
            params = params || {};
            return ng2ApiService.get('spelling_tool/languages', params, config);
        }
    }
})();
