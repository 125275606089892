(function () {
    'use strict';

    angular.module('pages.admin.domain').component('adminDomains', {
        templateUrl: 'app/pages/admin/domains/domains.html',
        controller: DomainsController,
        controllerAs: 'vm',
    });

    DomainsController.$inject = [
        'DomainRepo',
        'LabelRepo',
        'hotkeys',
        'ng2MonEventsService',
        'DomainGroupsRepo',
        'ng2SessionService',
        '$state',
        'activeFeatures',
        '$controller',
        'ng2MonModalService',
        'gettextCatalog',
        '$scope',
        'domainService',
        'MON_MODULES',
        'coreDomainService',
        'coreLoginService',
        'domainGroupService',
        'ng2ErrorHandler',
        'AdminDomainsService',
        'FORM_CONSTANT',
        'Lodash',
        'MON_EVENTS',
        'ng2ActiveFeatureService',
        '$filter',
        'accountService',
    ];
    /* @ngInject */
    function DomainsController (
        DomainRepo,
        LabelRepo,
        hotkeys,
        ng2MonEventsService,
        DomainGroupsRepo,
        ng2SessionService,
        $state,
        activeFeatures,
        $controller,
        ng2MonModalService,
        gettextCatalog,
        $scope,
        domainService,
        MON_MODULES,
        coreDomainService,
        coreLoginService,
        domainGroupService,
        ng2ErrorHandler,
        AdminDomainsService,
        FORM_CONSTANT,
        Lodash,
        MON_EVENTS,
        ng2ActiveFeatureService,
        $filter,
        accountService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPage = getPage;
        vm.createDomain = createDomain;
        vm.editDomain = editDomain;
        vm.onConfirmDelete = onConfirmDelete;
        vm.startOnDemandScan = startOnDemandScan;
        vm.stopCurrentCrawl = stopCurrentCrawl;
        vm.openScriptGuide = openScriptGuide;
        vm.editDomainGroup = editDomainGroup;
        vm.onConfirmDeleteGroup = onConfirmDeleteGroup;
        vm.editExcludedIps = editExcludedIps;
        vm.goToDomain = goToDomain;
        vm.updateDomainLabels = updateDomainLabels;
        vm.getLabels = getLabels;
        vm.hasConstraintsOrExcludes = hasConstraintsOrExcludes;
        vm.onFiltersChanges = onFiltersChanges;
        vm.cloneDomain = cloneDomain;
        vm.updateDomainGroup = updateDomainGroup;
        vm.openDataPrivacy = openDataPrivacy;
        vm.updateCustomer = updateCustomer;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.destroyed = false;
            vm.switchDomain = false;
            vm.switchDomainGroup = false;
            vm.search = null;
            vm.customer = ng2SessionService.customer;
            vm.showLabels = activeFeatures.labels;
            vm.collection = [];
            vm.domainUpdateCollection = {};
            vm.subheaderTranslation = '';
            vm.modules = MON_MODULES;
            vm.allLabelsMap = {};
            vm.domainCount = 0;
            vm.scriptSetupGuideIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.hasOnDemandScans = ng2ActiveFeatureService.isFeatureActivePure('on_demand_scans');
            coreLoginService.getUser().then(function (user) {
                vm.user = user;
            }, angular.noop);
            getPage();
            getLabels();
            hotkeysInit();
        }

        function onDestroy () {
            vm.destroyed = true;
        }

        function hotkeysInit () {
            hotkeys.bindTo($scope).add({
                combo: 'n',
                description: 'Create domain',
                callback: function (event) {
                    event.preventDefault();
                    createDomain();
                },
            });
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
            };
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }
            vm.promise = DomainRepo.getAll(params).then(
                function (data) {
                    vm.domainCount = data.total;
                    setSubHeaderDescription()
                    vm.collection = data;
                    updateDropdowns();
                },
                function (error) {
                    ng2ErrorHandler.noopError(error);
                },
            );
        }

        function updateDropdown (index) {
            const domain = vm.collection[index];

            const domainDropdown = [
                {
                    label: gettextCatalog.getString('Go to the dashboard'),
                    leftIcon: 'faHome',
                    action: ()=>goToDomain(domain),
                    eventParams: { action: 'admin-domain-dashboard' },
                    dataTestAttribute: 'go-to-dashboard-button',
                },
                {
                    label: gettextCatalog.getString('Script setup guide'),
                    leftIcon: 'faCode',
                    action: ()=>openScriptGuide(domain),
                    eventParams: { action: 'admin-domain-script-setup' },
                    shouldShow: vm.scriptSetupGuideIsAvailable,
                    dataTestAttribute: 'script-setup-button',
                },
                {
                    label: gettextCatalog.getString('Edit domain'),
                    leftIcon: 'faCog',
                    action: ()=>editDomain(domain, index),
                    eventParams: { action: 'admin-domain-edit' },
                    dataTestAttribute: 'edit-domain-button',
                },
                {
                    label: gettextCatalog.getString('Statistics excluded IP addresses'),
                    leftIcon: 'faChartLine',
                    action: ()=>editExcludedIps(domain),
                    eventParams: { action: 'admin-domain-excluded-ip' },
                    shouldShow: domain.features.statistics == true,
                    dataTestAttribute: 'exclude-ips-button',
                },
                {
                    label: gettextCatalog.getString('Start on demand scan'),
                    leftIcon: 'faSyncAlt',
                    action: ()=>startOnDemandScan(domain),
                    eventParams: { action: 'admin-domain-start-demand-scan' },
                    disabled: !vm.hasOnDemandScans || domain.crawl_status,
                    tooltip: vm.hasOnDemandScans ? '' : gettextCatalog.getString('Your current plan does not include on-demand scans. Please reach out to your Monsido representative to learn more about this option'),
                    dataTestAttribute: 'start-on-demand-scan-button',
                },
                {
                    label: gettextCatalog.getString('Clone'),
                    leftIcon: 'faClone',
                    action: ()=>cloneDomain(domain),
                    eventParams: { action: 'admin-domain-clone' },
                    dataTestAttribute: 'clone-domain-button',
                },
                {
                    label: gettextCatalog.getString('Open Data Privacy'),
                    leftIcon: 'faLockAlt',
                    action: ()=>openDataPrivacy(domain),
                    shouldShow: ng2ActiveFeatureService.isFeatureActive('data_privacy', domain) && $filter('hasAccess')(vm.user, vm.modules.data_privacy),
                    dataTestAttribute: 'open-data-privacy-button',
                },
                {
                    divider: true,
                    shouldShow: domain.domain_groups.length > 0,
                },
                {
                    label: gettextCatalog.getString('Update domain groups'),
                    leftIcon: 'faRedoAlt',
                    rightIcon: domain.running_groups ? 'faSpinner' : undefined,
                    spinRightIcon: true,
                    action: ()=>{
                        const message = gettextCatalog.getString('Updating groups can take more than 30 minutes. During the operation you will not be able to create and update groups.');
                        ng2MonModalService.confirm(message).then(()=>updateDomainGroup(domain));
                    },
                    disabled: domain.running_groups,
                    shouldShow: domain.domain_groups.length > 0,
                    dataTestAttribute: 'update-domain-group-button',
                },
                {
                    divider: true,
                },
                {
                    label: gettextCatalog.getString('Remove domain'),
                    leftIcon: 'faTrashAlt',
                    action: ()=>onConfirmDelete(domain),
                    eventParams: { action: 'admin-domain-remove' },
                    dataTestAttribute: 'delete-domain-button',
                },
                {
                    divider: true,
                    shouldShow: domain.getCanStopCrawl(),
                },
                {
                    label: gettextCatalog.getString('Stop current crawl'),
                    leftIcon: 'faStopCircle',
                    acton: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want to stop current crawl?');
                        ng2MonModalService.confirm(message).then(()=>stopCurrentCrawl(domain));
                    },
                    eventParams: { action: 'admin-domain-stop-crawl' },
                    shouldShow: domain.getCanStopCrawl(),
                    dataTestAttribute: 'stop-current-crawl-button',
                },
            ];

            vm.collection[index].dropdown = domainDropdown.filter((option)=>option.shouldShow !== false).map(item => {
                return {
                    label: item.label,
                    leftIcon: item.leftIcon,
                    rightIcon: item.rightIcon,
                    spinRightIcon: item.spinRightIcon,
                    divider: item.divider,
                    disabled: item.disabled,
                    tooltip: item.tooltip,
                    action: ()=>{
                        if (item.eventParams) {
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                        }

                        if (item.action) {
                            item.action();
                        }
                    },
                    dataTestAttribute: item.dataTestAttribute,
                };
            });

            if (domain.domain_groups) {
                for (let j = 0; j < domain.domain_groups.length; j++) {
                    const domainGroup = domain.domain_groups[j];

                    const domainGroupDropdown = [
                        {
                            label: gettextCatalog.getString('Open group'),
                            leftIcon: 'faClone',
                            action: ()=>goToDomain(domain, domainGroup),
                            eventParams: { action: 'admin-domain-open-group' },
                        },
                        {
                            label: gettextCatalog.getString('Edit domain group'),
                            leftIcon: 'faCog',
                            action: ()=>editDomainGroup(domain, domainGroup),
                            eventParams: { action: 'admin-domain-edit-group' },
                        },
                        {
                            divider: true,
                        },
                        {
                            label: gettextCatalog.getString('Remove domain group'),
                            leftIcon: 'faTrashAlt',
                            action: ()=>onConfirmDeleteGroup(domain, domainGroup),
                            eventParams: { action: 'admin-domain-remove-group' },
                        },
                    ];

                    vm.collection[index].domain_groups[j].dropdown = domainGroupDropdown;
                }
            }
        }

        function updateDropdowns () {
            for (let i = 0; i < vm.collection.length; i++) {
                updateDropdown(i);
            }
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
                vm.allLabelsMap = Lodash.keyBy(data, 'id');
            }, angular.noop);
        }

        function createDomain (domain) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN,
                size: 'md',
                data: {
                    domain: domain,
                },
            };

            const options = {
                params,
                callback: (updatedDomain) => {
                    if (updatedDomain) {
                        changedDomain();
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function editDomain (selectedDomain, index) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN,
                size: 'md',
                data: { domain: selectedDomain },
            };

            const options = {
                params,
                callback: () => {
                    vm.domainUpdateCollection[selectedDomain.id] = true;
                    domainService.get(selectedDomain.id)
                        .then(function (updatedDomain) {
                            if (vm.collection[index] && vm.collection[index].id === updatedDomain.id) {
                                vm.collection[index] = updatedDomain;
                                updateDropdown(index);
                            }
                        }, angular.noop)
                        .finally(function () {
                            delete vm.domainUpdateCollection[selectedDomain.id];
                        });
                    broadcastUpdateDomains();
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function editDomainGroup (domain, domainGroup) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN_GROUPS_EDIT,
                size: 'lg',
                data: { domainGroup: domainGroup, domain: domain },
            };

            const options = {
                params,
                callback: (updatedDomainGroup) => {
                    if (updatedDomainGroup) {
                        changedDomain();
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function updateDomainGroup (domain) {
            domainGroupService.updateDomainGroupRules(domain.id).then(function () {
                getPage();
            }, angular.noop);
        }

        function onConfirmDelete (domain) {
            // Message is put in here, because the message in the dom is not updated on search
            var msg =
                gettextCatalog.getString('You are about to delete the domain') +
                ' <span class=\'text-link\'>' +
                domain.title +
                '</span> ' +
                gettextCatalog.getString('please type') +
                ' <code>Delete</code> ' +
                gettextCatalog.getString('to confirm this action');

            msg +=
                '<div class=\'alert alert-danger inner-t-mini inner-b-mini inner-l-mini inner-r-mini outer-b-none outer-t-medium\'>' +
                gettextCatalog.getString('By deleting this domain all domain groups related to this domain, will also be deleted') +
                '</div>';

            ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }
                domainService.destroy(domain.id).then(function () {
                    if (domain.crawl_history) {
                        updateCustomer(domain);
                    } else {
                        changedDomain();
                    }

                }, angular.noop);
            }, angular.noop);
        }

        function startOnDemandScan (domain) {
            if (domain.crawl_status) {
                return;
            }
            ng2MonModalService.confirm(gettextCatalog.getString('Are you sure you want to start on-demand scan?')).then(function () {
                domainService.rescan(domain.id).then(function () {
                    changedDomain();
                }, angular.noop);
            }, angular.noop);
        }

        function openScriptGuide (domain) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN_SCRIPT_GUIDE,
                size: 'md',
                data: { domain: domain },
            };

            const options = {
                params,
                callback: (updatedDomain) => {
                    if (updatedDomain) {
                        ng2MonEventsService.run('change-editedScriptBlock-status');
                        AdminDomainsService.updateDomainSettings(domain);
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function changedDomain () {
            getPage();
            broadcastUpdateDomains();
        }

        function broadcastUpdateDomains () {
            coreDomainService.getDomains();
        }

        function onConfirmDeleteGroup (domain, domainGroup) {
            // Message is put in here, because the message in the dom is not updated on search
            var msg =
                gettextCatalog.getString('You are about to delete the domain group') +
                ' <span class=\'text-link\'>' +
                domainGroup.title +
                '</span> ' +
                gettextCatalog.getString('please type') +
                ' <code>Delete</code> ' +
                gettextCatalog.getString('to confirm this action');

            ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                domainGroupService.destroy(domain.id, domainGroup.id).then(function () {
                    if (domain.crawl_history) {
                        updateCustomer(domain);
                    } else {
                        changedDomain();
                    }
                }, angular.noop);
            }, angular.noop);
        }
        function updateCustomer (domain) {
            var updatedDocumentsCount = vm.customer.documents_count - domain.crawl_history.documents_count;
            var updatedHtmlPagesCount = vm.customer.html_pages_count - domain.crawl_history.page_count;
            var updatedCustomer = angular.copy(vm.customer);
            updatedCustomer.documents_count = updatedDocumentsCount;
            updatedCustomer.html_pages_count = updatedHtmlPagesCount;
            if (vm.customer.documents_count !== updatedDocumentsCount || vm.customer.html_pages_count !== updatedHtmlPagesCount) {
                accountService
                    .update(updatedCustomer)
                    .then(function (data) {
                        vm.customer = data;
                        changedDomain();
                    }, angular.noop);
            } else {
                changedDomain();
            }
        }

        function editExcludedIps (domain) {
            var params = {
                body: FORM_CONSTANT.FORM_DOMAIN_EXCLUDE_IP,
                size: 'md',
                data: { domainId: domain.id },
            };

            const options = {
                params,
                callback: () => {
                    changedDomain();
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function goToDomain (domain, domainGroup) {
            var page;
            vm.switchDomain = domain.id;
            var params = {
                domainId: domain.id,
            };
            if (domainGroup === undefined) {
                coreDomainService.removeDomainGroup();
            } else {
                coreDomainService.setDomainGroup(domainGroup);
                vm.switchDomainGroup = domainGroup.id;
                params.domain_group = domainGroup.id;
            }
            page = 'base.customer.domain.dashboard';

            $state
                .go(page, params)
                .then(function () {
                    ng2MonEventsService.run('changed-domain');
                }, angular.noop)
                .finally(function () {
                    vm.switchDomain = false;
                    vm.switchDomainGroup = false;
                });
        }

        function updateDomainLabels (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });

            return domainService.update(domain.id, params);
        }

        function hasConstraintsOrExcludes (domain) {
            return domain.path_constraints.length + domain.link_excludes.length > 0;
        }

        function stopCurrentCrawl (domain) {
            domainService.stopCrawl(domain.id).then(function () {
                changedDomain();
            }, angular.noop);
        }

        function cloneDomain (domain) {
            createDomain(domain.clone());
        }

        function openDataPrivacy (domain) {
            var params = {
                body: 'domainDataPrivacySettings',
                size: 'md',
                data: {
                    domain: domain,
                },
            };

            const options = {
                params,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function setSubHeaderDescription () {
            var params = {
                domains: vm.domainCount,
            };
            vm.subheaderTranslation = gettextCatalog.getString(
                'You have {{domains}} domain(s) on your account',
                params,
            );
        }

        // PROTECTED

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }
    }
})();
