(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardStatistics', {
        templateUrl: 'app/pages/domain/charts/statistics/statistics.html',
        controller: DashboardStatisticsController,
        controllerAs: 'vm',
        bindings: {},
    });

    DashboardStatisticsController.$inject = ['StatsRepo', '$filter', 'gettextCatalog', 'moment'];
    /* @ngInject */
    function DashboardStatisticsController (StatsRepo, $filter, gettextCatalog, moment) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.loading = true;
            vm.dates = [];

            vm.lineChartSeries = [];
            vm.lineChartData = [];
            vm.lineChartColors = [];
            vm.lineChartLabels = [];
            vm.lineChartOverride = [];
            vm.lineChartOptions = [];
            vm.firstDate = null;
            vm.lastDate = null;
            vm.totalVisits = 0;

            getPage();
        }

        function getPage () {
            var params = {
                to: moment()
                    .subtract(1, 'days')
                    .endOf('day')
                    .format('YYYY-MM-DD'),
                from: moment()
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD'),
            };
            vm.loading = true;

            StatsRepo.getSummaryPerDate(params)
                .then(function (data) {
                    data.forEach(function (date) {
                        vm.dates.push({
                            date: date.date,
                            visits: date.visits,
                        });
                    });
                    vm.totalVisits = vm.dates.reduce(function (sum, date) {
                        return sum + parseInt(date.visits);
                    }, 0);
                    setupChart();
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setupChart () {
            vm.lineChartSeries = [gettextCatalog.getString('Visits')];
            vm.lineChartLabels = [];
            vm.lineChartData = [];
            vm.lineChartColors = ['rgba(87,188,219,1)'];
            vm.lineChartOverride = [
                {
                    label: gettextCatalog.getString('Visits'),
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    borderColor: '#57BCDB',
                    xAxisID: 'domain-dashboard-statistics-x',
                    yAxisID: 'domain-dashboard-statistics-y',
                    pointRadius: 0,
                },
            ];

            vm.lineChartOptions = {
                tooltips: {
                    mode: 'label',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var set = data.datasets[tooltipItem.datasetIndex];
                            return set.label + ': ' + $filter('numeraljs', '0,0')(set.data[tooltipItem.index]);
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'domain-dashboard-statistics-y',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            id: 'domain-dashboard-statistics-x',
                            display: false,
                        },
                    ],
                },
                pointRadius: 0,
            };

            angular.forEach(vm.dates, function (date) {
                vm.lineChartLabels.push($filter('userDate')(date.date));
            });

            var chartData = vm.dates.map(function (date) {
                return date.visits;
            });
            vm.lineChartData.push(chartData);

            if (vm.dates.length > 0) {
                vm.firstDate = vm.dates[0];
                vm.lastDate = vm.dates[vm.dates.length - 1];
            } else {
                vm.firstDate = null;
                vm.lastDate = null;
            }

            vm.loading = false;
        }
    }
})();
