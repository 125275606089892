(function () {
    'use strict';
    angular
        .module('app.forms')
        .service('FormDomainScriptGuidePageAssistService', FormDomainScriptGuidePageAssistService);

    FormDomainScriptGuidePageAssistService.$inject = ['ng2ActiveFeatureService'];

    /* @ngInject*/
    function FormDomainScriptGuidePageAssistService (ng2ActiveFeatureService) {
        return {
            getAccessToFeature: getAccessToFeature,
        };

        function getAccessToFeature (domain) {
            return Boolean(
                domain &&
                    domain.settings.scripts.page_assist &&
                    ng2ActiveFeatureService.isFeatureActive('page_assist', domain),
            );
        }
    }
})();
