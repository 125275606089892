(function () {
    'use strict';

    angular.module('modules.demodata.endpoints').factory('DemoPageRepo', DemoPageRepo);

    DemoPageRepo.$inject = ['$q', '$timeout', 'ng2SessionService', 'ng2PageModelFactoryService'];

    /* @ngInject */
    function DemoPageRepo ($q, $timeout, ng2SessionService, ng2PageModelFactoryService) {
        var vm = this;

        function init () {
            vm.hostNameRegex = /^https?:\/\/(?:w{3}\.)?([a-z0-9\.-]+)\.(?:[a-z\.]{2,10})(?:[a-z\.-])/;
            generateData();

            return {
                get: get,
                update: update,
                isDemo: isDemo,
                getStatSummary: getStatSummary,
                getStatVisitors: getStatVisitors,
            };
        }

        return init();

        // /////////////

        function get (pageId) {
            return getData('get').then(function (pages) {
                return findPage(pageId, pages);
            });
        }

        function update (pageId) {
            return getData('update').then(function (pages) {
                return findPage(pageId, pages);
            });
        }

        function getStatSummary (pageId) {
            var id = parseInt(pageId);
            return getData('getStatSummary').then(function (summaries) {
                return summaries[id % summaries.length];
            });
        }

        function getStatVisitors (pageId) {
            var id = parseInt(pageId);
            return getData('getStatVisitors').then(function (visitors) {
                return visitors[id % visitors.length];
            });
        }

        function isDemo (page) {
            return vm.pages.some(function (mockPage) {
                return mockPage.id === page.id;
            });
        }

        // PROTECTED

        function getData (fnName) {
            var defer = $q.defer();
            var timer = Math.round(1000 * Math.random());
            $timeout(function () {
                switch (fnName) {
                    case 'update':
                    case 'get':
                        defer.resolve(parsePages(vm.pages));
                        break;
                    case 'getStatSummary':
                        defer.resolve(vm.statSummaries);
                        break;
                    case 'getStatVisitors':
                        defer.resolve(vm.statVisitors);
                        break;
                    default:
                        defer.resolve([]);
                }
            }, timer);
            return defer.promise;
        }

        function findPage (pageId, pages) {
            var page = pages.find(function (page) {
                return page.id === pageId;
            });
            return ng2PageModelFactoryService.getModel(page);
        }

        function parsePages (pages) {
            return angular.copy(pages).map(function (page) {
                page.url = page.url.replace(vm.hostNameRegex, getDomainUrl());
                page.cms_url = page.cms_url.replace(vm.hostNameRegex, getDomainUrl());
                return page;
            });
        }

        function getDomainUrl () {
            if (ng2SessionService.domain) {
                var domain = ng2SessionService.domain;
                if (domain.url[domain.url.length - 1] === '/') {
                    return domain.url.substring(0, domain.url.length - 1);
                }
                return domain.url;
            }
            return '';
        }

        function generateData () {
            vm.pages = [
                {
                    id: 'demo-1',
                    domain_id: 1,
                    title: 'Lorem ipsum',
                    url: 'https://sample.tld/path_to_file.pdf',
                    mrank: 1,
                    hit_score: 1,
                    link_score: 11,
                    error_score: 1,
                    priority_score: 11,
                    detected_language: 'da_DK',
                    language_override: null,
                    no_index: false,
                    hits: 0,
                    type: 'pdf',
                    groups: [],
                    dead_links_count: 0,
                    ignored_links_count: 0,
                    fixed_links_count: 0,
                    dead_images_count: 0,
                    ignored_images_count: 0,
                    fixed_images_count: 0,
                    seo_issues_count: 0,
                    spelling_errors_count: 0,
                    notification_count: 0,
                    spelling_errors_confirmed_count: 0,
                    spelling_errors_potential_count: 0,
                    accessibility_checks_with_errors_count: 0,
                    nin_count: 0,
                    quality_notification_count: 0,
                    quality_notifications_not_searches: 0,
                    cms_url: 'https://sample.tld/path_to_file.pdf',
                    words_count: 1281,
                    language: 'da_DK',
                    priority_score_as_text: 'low',
                    readability: 44.6,
                    readability_level: 'na',
                    searches_count: 0,
                    error_searches_count: 0,
                    policy_violation_count: 0,
                    policy_search_count: 2,
                    policy_required_count: 1,
                    policy_with_required_by_tag_count: { seo: 1, accessibility: 1 },
                    policy_with_violations_by_tag_count: null,
                    policy_with_search_by_tag_count: { qa: 2, seo: 1 },
                    lastcheck: '2018-12-20T05:37:00+01:00',
                    created_at: '2018-06-19T05:15:47+02:00',
                    updated_at: '2018-12-20T11:03:42+01:00',
                    labels: [],
                },
                {
                    id: 'demo-2',
                    domain_id: 1,
                    title: 'Ipsum dolor',
                    url: 'https://sample.tld/files/dolor.pdf',
                    mrank: 1,
                    hit_score: 1,
                    link_score: 1,
                    error_score: 1,
                    priority_score: 1,
                    detected_language: 'da_DK',
                    language_override: null,
                    no_index: false,
                    hits: 0,
                    type: 'pdf',
                    groups: [],
                    dead_links_count: 0,
                    ignored_links_count: 0,
                    fixed_links_count: 0,
                    dead_images_count: 0,
                    ignored_images_count: 0,
                    fixed_images_count: 0,
                    seo_issues_count: 0,
                    spelling_errors_count: 0,
                    notification_count: 0,
                    spelling_errors_confirmed_count: 0,
                    spelling_errors_potential_count: 0,
                    accessibility_checks_with_errors_count: 0,
                    nin_count: 0,
                    quality_notification_count: 0,
                    quality_notifications_not_searches: 0,
                    cms_url: 'https://sample.tld/files/dolor.pdf',
                    words_count: 1040,
                    language: 'da_DK',
                    priority_score_as_text: 'low',
                    readability: 38.7,
                    readability_level: 'na',
                    searches_count: 0,
                    error_searches_count: 0,
                    policy_violation_count: 0,
                    policy_search_count: 2,
                    policy_required_count: 1,
                    policy_with_required_by_tag_count: { seo: 1, accessibility: 1 },
                    policy_with_violations_by_tag_count: null,
                    policy_with_search_by_tag_count: { qa: 2, seo: 1 },
                    lastcheck: '2018-12-20T05:37:00+01:00',
                    created_at: '2017-10-16T14:51:04+02:00',
                    updated_at: '2018-12-20T11:01:05+01:00',
                    labels: [],
                },
                {
                    id: 'demo-3',
                    domain_id: 1,
                    title: 'Etiam feugiat vehicula dolor et rutrum. Aenean at tellus',
                    url: 'https://sample.tld/sites/default/files/Etiam_.pdf',
                    mrank: 1,
                    hit_score: 1,
                    link_score: 1,
                    error_score: 1,
                    priority_score: 1,
                    detected_language: 'da_DK',
                    language_override: null,
                    no_index: false,
                    hits: 0,
                    type: 'pdf',
                    groups: [],
                    dead_links_count: 0,
                    ignored_links_count: 0,
                    fixed_links_count: 0,
                    dead_images_count: 0,
                    ignored_images_count: 0,
                    fixed_images_count: 0,
                    seo_issues_count: 0,
                    spelling_errors_count: 0,
                    notification_count: 0,
                    spelling_errors_confirmed_count: 0,
                    spelling_errors_potential_count: 0,
                    accessibility_checks_with_errors_count: 0,
                    nin_count: 0,
                    quality_notification_count: 0,
                    quality_notifications_not_searches: 0,
                    cms_url: 'https://sample.tld/sites/default/files/Etiam_.pdf',
                    words_count: 1108,
                    language: 'da_DK',
                    priority_score_as_text: 'low',
                    readability: 40,
                    readability_level: 'na',
                    searches_count: 0,
                    error_searches_count: 0,
                    policy_violation_count: 0,
                    policy_search_count: 2,
                    policy_required_count: 1,
                    policy_with_required_by_tag_count: { seo: 1, accessibility: 1 },
                    policy_with_violations_by_tag_count: null,
                    policy_with_search_by_tag_count: { qa: 2, seo: 1 },
                    lastcheck: '2018-12-20T05:37:00+01:00',
                    created_at: '2018-02-25T09:36:29+01:00',
                    updated_at: '2018-12-20T11:01:24+01:00',
                    labels: [],
                },
                {
                    id: 'demo-4',
                    domain_id: 1,
                    title: 'Etiam nec quam egestas',
                    url: 'https://sample.tld/Etiam_nec_quam_egestas.pdf',
                    mrank: 1,
                    hit_score: 1,
                    link_score: 1,
                    error_score: 1,
                    priority_score: 1,
                    detected_language: 'da_DK',
                    language_override: null,
                    no_index: false,
                    hits: 0,
                    type: 'pdf',
                    groups: [],
                    dead_links_count: 0,
                    ignored_links_count: 0,
                    fixed_links_count: 0,
                    dead_images_count: 0,
                    ignored_images_count: 0,
                    fixed_images_count: 0,
                    seo_issues_count: 0,
                    spelling_errors_count: 0,
                    notification_count: 0,
                    spelling_errors_confirmed_count: 0,
                    spelling_errors_potential_count: 0,
                    accessibility_checks_with_errors_count: 0,
                    nin_count: 0,
                    quality_notification_count: 0,
                    quality_notifications_not_searches: 0,
                    cms_url: 'https://sample.tld/Etiam_nec_quam_egestas.pdf',
                    words_count: 1272,
                    language: 'da_DK',
                    priority_score_as_text: 'low',
                    readability: 38.1,
                    readability_level: 'na',
                    searches_count: 0,
                    error_searches_count: 0,
                    policy_violation_count: 0,
                    policy_search_count: 2,
                    policy_required_count: 1,
                    policy_with_required_by_tag_count: { seo: 1, accessibility: 1 },
                    policy_with_violations_by_tag_count: null,
                    policy_with_search_by_tag_count: { qa: 2, seo: 1 },
                    lastcheck: '2018-12-20T05:37:00+01:00',
                    created_at: '2017-10-16T14:51:04+02:00',
                    updated_at: '2018-12-20T11:01:05+01:00',
                    labels: [],
                },
                {
                    id: 'demo-5',
                    domain_id: 1,
                    title: 'Dolor as nec',
                    url: 'https://sample.tld/files/dolor_as.pdf',
                    mrank: 1,
                    hit_score: 1,
                    link_score: 1,
                    error_score: 1,
                    priority_score: 1,
                    detected_language: 'da_DK',
                    language_override: null,
                    no_index: false,
                    hits: 0,
                    type: 'pdf',
                    groups: [],
                    dead_links_count: 0,
                    ignored_links_count: 0,
                    fixed_links_count: 0,
                    dead_images_count: 0,
                    ignored_images_count: 0,
                    fixed_images_count: 0,
                    seo_issues_count: 0,
                    spelling_errors_count: 0,
                    notification_count: 0,
                    spelling_errors_confirmed_count: 0,
                    spelling_errors_potential_count: 0,
                    accessibility_checks_with_errors_count: 0,
                    nin_count: 0,
                    quality_notification_count: 0,
                    quality_notifications_not_searches: 0,
                    cms_url: 'https://sample.tld/files/dolor_as.pdf',
                    words_count: 1272,
                    language: 'da_DK',
                    priority_score_as_text: 'low',
                    readability: 38.1,
                    readability_level: 'na',
                    searches_count: 0,
                    error_searches_count: 0,
                    policy_violation_count: 0,
                    policy_search_count: 2,
                    policy_required_count: 1,
                    policy_with_required_by_tag_count: { seo: 1, accessibility: 1 },
                    policy_with_violations_by_tag_count: null,
                    policy_with_search_by_tag_count: { qa: 2, seo: 1 },
                    lastcheck: '2018-12-20T05:37:00+01:00',
                    created_at: '2017-10-16T14:51:04+02:00',
                    updated_at: '2018-12-20T11:01:05+01:00',
                    labels: [],
                },
            ];
            vm.statVisitors = [
                [
                    {
                        date: moment().subtract(1, 'days')
                            .toISOString(),
                        visits: '2',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '8',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(2, 'days')
                            .toISOString(),
                        visits: '0',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(3, 'days')
                            .toISOString(),
                        visits: '2',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(4, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '57',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(5, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(6, 'days')
                            .toISOString(),
                        visits: '0',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(7, 'days')
                            .toISOString(),
                        visits: '0',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                ],
                [
                    {
                        date: moment().subtract(1, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '8',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(2, 'days')
                            .toISOString(),
                        visits: '2',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(3, 'days')
                            .toISOString(),
                        visits: '2',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(4, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '57',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(5, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(6, 'days')
                            .toISOString(),
                        visits: '0',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(7, 'days')
                            .toISOString(),
                        visits: '0',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                ],
                [
                    {
                        date: moment().subtract(1, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '8',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(2, 'days')
                            .toISOString(),
                        visits: '0',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(3, 'days')
                            .toISOString(),
                        visits: '2',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(4, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '57',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(5, 'days')
                            .toISOString(),
                        visits: '1',
                        url: null,
                        visitors: null,
                        uniq_visitors: '1',
                        label: null,
                        bounce_rate: '0%',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '1',
                    },
                    {
                        date: moment().subtract(6, 'days')
                            .toISOString(),
                        visits: '3',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                    {
                        date: moment().subtract(7, 'days')
                            .toISOString(),
                        visits: '2',
                        url: null,
                        visitors: null,
                        uniq_visitors: '0',
                        label: null,
                        bounce_rate: '0',
                        bounces: null,
                        actions: '0',
                        avg_time: '0',
                        pageviews: '0',
                    },
                ],
            ];
            vm.statSummaries = [
                {
                    date: '2019-02-18T00:00:00.000+00:00',
                    visits: '8',
                    visitors: null,
                    avg_time: '17',
                    bounce_rate: '0%',
                    avg_actions: null,
                    pageviews: '5',
                    uniq_pageviews: null,
                    bounce_count: '0',
                },
                {
                    date: '2019-02-18T00:00:00.000+00:00',
                    visits: '11',
                    visitors: null,
                    avg_time: '17',
                    bounce_rate: '0%',
                    avg_actions: null,
                    pageviews: '5',
                    uniq_pageviews: null,
                    bounce_count: '0',
                },
                {
                    date: '2019-02-18T00:00:00.000+00:00',
                    visits: '12',
                    visitors: null,
                    avg_time: '17',
                    bounce_rate: '0%',
                    avg_actions: null,
                    pageviews: '5',
                    uniq_pageviews: null,
                    bounce_count: '0',
                },
            ];
        }
    }
})();
