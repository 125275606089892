(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionQaReadability', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/subsections/readability/readability.html',
        controller: ReadabilityIndex,
        controllerAs: 'vm',
        bindings: {
            pageObj: '<',
        },
    });

    ReadabilityIndex.$inject = ['ng2SessionService'];
    /* @ngInject */
    function ReadabilityIndex (ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.chooseCount = chooseCount;

        function activate () {
            vm.domain = ng2SessionService.domain;
        }

        function chooseCount () {
            if (vm.pageObj.type === 'pdf') {
                return vm.pageObj.readability_words_count === 0 ? vm.pageObj.words_count : vm.pageObj.readability_words_count;
            } else {
                return vm.pageObj.readability_words_count;
            }
        }
    }
})();
