(function () {
    'use strict';

    angular.module('modules.spotlight').component('monSpotlightNotificationResult', {
        controller: NotificationResultController,
        controllerAs: 'vm',
        templateUrl: 'app/modules/spotlight/components/search/notification-result/notification-result.html',
        bindings: {
            page: '<',
            goToPageDetails: '&',
        },
    });

    NotificationResultController.$inject = ['gettextCatalog', 'ng2SessionService', 'Lodash', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function NotificationResultController (gettextCatalog, sessionService, Lodash, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = update;
        vm.goToDetails = goToDetails;
        vm.hasAccessToDetailsOverlay = ng2ActiveFeatureService.isFeatureActive('page_details');

        function activate () {
            vm.domains = [];
            sessionService.domains.subscribe(function (domains) {
                vm.domains = domains || [];
            });
        }

        function update () {
            setupNotifications();
            vm.hasDomainGroup = hasDomainGroup();
            vm.getDomainTitle = getDomainTitle();
            vm.getDomainGroupTitle = getDomainGroupTitle();
        }

        function setupNotifications () {
            vm.notifications = [
                {
                    attr: ['policy_required_count', 'policy_search_count', 'policy_violation_count'],
                    name: gettextCatalog.getString('Policies'),
                    icon: 'fas fa-gavel',
                    target: 'policy',
                    attrCount: countAttributes(['policy_required_count', 'policy_search_count', 'policy_violation_count']),
                    component: 'page-details-section-policies',
                },
                {
                    attr: 'dead_links_count',
                    name: gettextCatalog.getString('Broken links'),
                    icon: 'fas fa-unlink',
                    target: 'dead-links',
                    attrCount: countAttributes('dead_links_count'),
                    component: 'page-details-section-qa',
                    subComponent: 'brokenlinks',
                },
                {
                    attr: 'dead_images_count',
                    name: gettextCatalog.getString('Broken images'),
                    icon: 'fas fa-image',
                    target: 'broken-images',
                    attrCount: countAttributes('dead_images_count'),
                    component: 'page-details-section-qa',
                    subComponent: 'brokenimages',
                },
                {
                    attr: 'spelling_errors_confirmed_count',
                    name: gettextCatalog.getString('Misspellings'),
                    icon: 'fas fa-language',
                    target: 'misspellings',
                    attrCount: countAttributes('spelling_errors_confirmed_count'),
                    component: 'page-details-section-qa',
                    subComponent: 'misspellings',
                },
                {
                    attr: 'accessibility_checks_with_errors_count',
                    name: gettextCatalog.getString('Accessibility'),
                    icon: 'fas fa-universal-access',
                    target: 'accessibility',
                    attrCount: countAttributes('accessibility_checks_with_errors_count'),
                    component: 'page-details-section-accessibility',
                },
                {
                    attr: 'seo_issues_count',
                    name: gettextCatalog.getString('SEO'),
                    icon: 'fas fa-chart-line',
                    target: 'seo',
                    attrCount: countAttributes('seo_issues_count'),
                    component: 'page-details-section-seo',
                },
            ];
        }

        function countAttributes (attr) {
            var total;
            if (Array.isArray(attr)) {
                total = attr.reduce(function (num, attribute) {
                    return num + getTotal(attribute);
                }, 0);
            } else {
                total = getTotal(attr);
            }
            return total;
        }

        function getTotal (attr) {
            var result = vm.page && vm.page[attr];
            return !isNaN(result) ? result : 0;
        }

        function goToDetails (notification) {
            if (countAttributes(notification.attr) > 0) {
                vm.goToPageDetails({ target: notification });
            }
        }

        function hasPage () {
            return Boolean(vm.page);
        }

        function getDomainTitle () {
            if (!hasPage()) {
                return;
            }
            var domain = getDomain();
            return domain ? domain.title : '';
        }

        function getDomainGroupTitle () {
            var domainGroup = getDomainGroup();
            return domainGroup ? domainGroup.title : '';
        }

        function hasDomainGroup () {
            return hasPage() && vm.page.domain_group_id > 0;
        }

        function getDomain () {
            return Lodash.find(vm.domains, function (domain) {
                return vm.page.domain_id === domain.id;
            });
        }

        function getDomainGroup () {
            if (hasDomainGroup()) {
                return Lodash.find(getDomain().domain_groups, function (domainGroup) {
                    return vm.page.domain_group_id === domainGroup.id;
                });
            }

            return null;
        }
    }
})();
