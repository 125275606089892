import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('urlTitlePageDetails', {
        template:
            '<url-title title="vm.title" go-to="vm.openPageDetails()" active="vm.active" url="vm.url"></url-title>',
        controller: UrlTitlePageDetailsController,
        controllerAs: 'vm',
        bindings: {
            active: '<',
            target: '<',
            subTarget: '<?',
            page: '<',
            onClose: '&?',
            title: '<',
            url: '<',
        },
    });

    UrlTitlePageDetailsController.$inject = ['ng2MonEventsService'];

    function UrlTitlePageDetailsController (ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.openPageDetails = openPageDetails;

        function activate () {
            vm.subTarget = vm.subTarget || null;
        }

        function openPageDetails () {
            var params = {
                body: 'pageDetails',
                classes: 'page-details-app',
                size: 'page-details',
                data: {
                    page: vm.page,
                    target: vm.target,
                    subTarget: vm.subTarget,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: vm.onClose });
        }
    }
})();
