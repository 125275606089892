import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideFeaturesPageAssist', {
        templateUrl: 'app/forms/domain-script-guide/features/page-assist/page-assist.html',
        controller: DomainScriptGuideFeaturesPageAssistController,
        controllerAs: 'vm',
        bindings: {
            prefixName: '@?',
            domain: '<',
            pageAssistModel: '<',
            supportLegacy: '<',
        },
    });

    DomainScriptGuideFeaturesPageAssistController.$inject = [
        '$timeout',
        'FormDomainScriptGuideService',
        'FormDomainScriptGuidePageAssistService',
        'monDialog',
        'DOMAIN_SCRIPT_GUIDE_EVENTS',
        'ng2MonEventsService',
        'PAGE_ASSIST_SETTINGS',
        'Lodash',
        'ng2MonModalService',
        'gettextCatalog',
    ];

    /* @ngInject */
    function DomainScriptGuideFeaturesPageAssistController (
        $timeout,
        FormDomainScriptGuideService,
        FormDomainScriptGuidePageAssistService,
        monDialog,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DOMAIN_SCRIPT_GUIDE_EVENTS,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        PAGE_ASSIST_SETTINGS,
        Lodash,
        ng2MonModalService,
        gettextCatalog,
    ) {
        var vm = this;
        const discardChangesConfirmationText = gettextCatalog.getString(
            'The settings form contains incorrect data. Close the form anyway?',
        );

        let paSettingsFormIsValid = true;
        vm.$onInit = activate;
        vm.onEnabledChange = onEnabledChange;
        vm.canUseFeature = canUseFeature;
        vm.switchPageAssistSetting = switchPageAssistSetting;
        vm.openPageAssistScriptSettings = openPageAssistScriptSettings;
        vm.dialogId;

        function activate () {
            vm.firstTimeEnablePA = !(vm.pageAssistModel && vm.pageAssistModel.settings && vm.pageAssistModel.settings.enabled);
            if (typeof vm.supportLegacy !== 'boolean') {
                vm.supportLegacy = true;
            }
            vm.settingEnabled = vm.pageAssistModel.getIsEnabled();
            vm.pageAssistModel.settings.canSavePaSetting = true;
            if (vm.firstTimeEnablePA) {
                vm.pageAssistModel.settings = Lodash.assign(vm.pageAssistModel.settings, PAGE_ASSIST_SETTINGS.DEFAULT_SETTINGS);
            }

            ng2MonEventsService.addListener('updatePaSettings', (paSettings) => {
                vm.pageAssistModel = Object.assign(vm.pageAssistModel, {
                    settings: paSettings,
                });
            });

            ng2MonEventsService.addListener('closePaSettingsDialog', () => {
                if (Array.isArray(vm.dialogId)) {
                    monDialog.close(vm.dialogId[0]);
                } else {
                    monDialog.close(vm.dialogId);
                }
            });

            ng2MonEventsService.addListener('paSettingsFormValidStatus', (valid) => {
                paSettingsFormIsValid = valid;
            });
        }

        function onEnabledChange () {
            ng2MonEventsService.run('formUnsavedUpdate');
            ng2MonEventsService.run(DOMAIN_SCRIPT_GUIDE_EVENTS.FORM_UNSAVED_UPDATE);
            vm.settingEnabled = !vm.settingEnabled;
            $timeout(function () {
                if (vm.pageAssistModel.getVersionState() === 'v1') {
                    vm.pageAssistModel.settings.enabled = false;
                    vm.pageAssistModel.legacySettings.enabled = vm.settingEnabled;
                } else {
                    vm.pageAssistModel.settings.enabled = vm.settingEnabled;
                    vm.pageAssistModel.legacySettings.enabled = false;
                }

                updateDomainSettings();
            });
        }

        function canUseFeature () {
            return FormDomainScriptGuidePageAssistService.getAccessToFeature(vm.domain);
        }

        function switchPageAssistSetting (pageAssistVersion) {
            vm.pageAssistModel.setVersionState(pageAssistVersion);
            if (pageAssistVersion === 'v1') {
                vm.pageAssistModel.settings.enabled = false;
                vm.pageAssistModel.legacySettings.enabled = vm.settingEnabled;
            } else {
                vm.pageAssistModel.settings.enabled = vm.settingEnabled;
                vm.pageAssistModel.legacySettings.enabled = false;
            }
        }

        async function openPageAssistScriptSettings () {
            var params = {
                body: 'paScriptSettingsComponent',
                size: 'md',
            };

            const onBeforeClose = () => new Promise((resolve) => {
                if (paSettingsFormIsValid) {
                    resolve(true);
                } else {
                    ng2MonModalService.confirm(discardChangesConfirmationText).then(() => {
                        resolve(true);
                    }, () => {
                        resolve(false);
                    });
                }
            });

            /* vm.dialogId = monDialog.create(params, null, () => new Promise((resolve) => {
                if (paSettingsFormIsValid) {
                    resolve(true);
                } else {
                    ng2MonModalService.confirm(discardChangesConfirmationText).then(() => {
                        resolve(true);
                    }, () => {
                        resolve(false);
                    });
                }
            })); */

            vm.dialogId = await ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, onBeforeClose });
            $timeout(() => {
                ng2MonEventsService.run('setupPaSettings', vm.pageAssistModel.settings);
            });
        }

        // PROTECTED

        function updateDomainSettings () {
            $timeout(function () {
                FormDomainScriptGuideService.setPageAssistEdit(vm.pageAssistModel);
            });
        }
    }
})();
