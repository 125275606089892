(function () {
    'use strict';
    /**
     * @memberof services.entities.user
     * @ngdoc factory
     * @name userService
     * @description Service to handle users
     */
    angular.module('services.entities.user').factory('userService', userService);

    userService.$inject = ['UserRepo', 'userCacheService', '$q', 'Lodash'];
    /* @ngInject*/
    function userService (UserRepo, userCacheService, $q, Lodash) {
        return {
            create: create,
            update: update,
            get: get,
            getAll: getAll,
            destroy: destroy,
        };

        /**
         * @memberOf userService
         * @desc Create user
         * @param {object} user The user to create
         * @returns {Promise} Promise containing the user that is created
         */
        function create (user) {
            return UserRepo.create(user).then(function (returnUser) {
                userCacheService.put(returnUser);
                return returnUser;
            }, angular.noop);
        }

        /**
         * @memberOf userService
         * @desc Update user
         * @param {object} user The user to update
         * @returns {Promise} Promise containing the user that is updated
         */
        function update (user) {
            return UserRepo.update(user).then(function (returnUser) {
                userCacheService.put(returnUser);
                return returnUser;
            }, angular.noop);
        }

        /**
         * @memberOf userService
         * @desc Get user
         * @param {object} userId The userId to use
         * @param {boolean} byPassCache The userId to use
         * @returns {Promise} Promise containing the user that is updated
         */
        function get (userId, byPassCache) {
            var deferred = $q.defer();
            var user = undefined;
            if (byPassCache !== true) {
                user = userCacheService.get(String(userId));
            }
            if (user === undefined && userId !== undefined) {
                var config = {
                    headers: {
                        noGlobal: true,
                    },
                };
                UserRepo.get(userId, {}, config).then(
                    function (returnUser) {
                        var tempUser = Lodash.cloneDeep(returnUser);
                        userCacheService.put(returnUser);
                        deferred.resolve(tempUser);
                    },
                    function () {
                        deferred.reject('Can\'t find the user');
                    },
                );
            } else {
                deferred.resolve(user);
            }
            return deferred.promise;
        }

        function getAll (params, config) {
            return UserRepo.getAll(params);
        }

        function destroy (user, config) {
            return UserRepo.destroy(user, config);
        }
    }
})();
