(function () {
    'use strict';

    angular.module('core.run').run([
        'ng2SessionService',
        'ng2MonEventsService',
        'coreDomainService',
        'ng2ApiService',
        'SupportApi',
        'ng2OauthService',
        '$localStorage',
        'UserLocaleService',
        '$numeraljsConfig',
        'coreLoginService',
        'ng2CoreAgreementService',
        'LabelTableService',
        'monDialog',
        '$window',
        '$rootScope',
        'extensionService',
        'MON_EVENTS',
        function (
            ng2SessionService,
            ng2MonEventsService,
            coreDomainService,
            ng2ApiService,
            SupportApi,
            oauthService,
            $localStorage,
            UserLocaleService,
            $numeraljsConfig,
            coreLoginService,
            coreAgreementService,
            LabelTableService,
            monDialog,
            $window,
            $rootScope,
            extensionService,
            MON_EVENTS,
        ) {
            ng2MonEventsService.addListener('exitDomain', function () {
                monDialog.closeAll(undefined, true);
                ng2MonEventsService.run('changed-domain').then(function () {
                    coreDomainService.clearDomain();
                }, angular.noop);
            });

            ng2MonEventsService.addListener('afterCustomer', function () {
                coreDomainService.init();
                coreDomainService.getDomains();
            });

            ng2MonEventsService.addListener('logout', function () {
                coreDomainService.disposeDomains();
                coreDomainService.disposeDomain();

                ng2ApiService.removeHeader('Authorization');
                ng2ApiService.removeHeader('Monsido-Account-Id');

                delete SupportApi.config.headers.Authorization;
                delete SupportApi.config.headers['Monsido-Account-Id'];
                oauthService.clear();
            });

            ng2MonEventsService.addListener('clearAgreement', function () {
                coreDomainService.emptyDomains();
                coreDomainService.cancelDomainPromise();
                ng2SessionService.roles = [];
            });

            ng2MonEventsService.addListener('refreshDomain', function () {
                return coreDomainService.refreshDomain();
            });

            ng2MonEventsService.addListener('beforeLoadUser', function () {

                ng2ApiService.setHeader('Authorization', oauthService.getTokenHeaderValue());
                ng2ApiService.setUrlPrefix(oauthService.getApiPath() + '/api/');


                SupportApi.config.headers.Authorization = oauthService.getTokenHeaderValue();
            });

            ng2MonEventsService.addListener('newAccessToken', () => {
                ng2ApiService.setHeader('Authorization', oauthService.getTokenHeaderValue());
                SupportApi.config.headers.Authorization = oauthService.getTokenHeaderValue();
            });

            ng2MonEventsService.addListener('afterLoadUser', function (user) {
                ng2SessionService.roles = $localStorage.roles || [];
                $numeraljsConfig.locale(UserLocaleService.getNumeralValue(user.locale));
                coreLoginService.setMe(user);
            });

            ng2MonEventsService.addListener('setAgreement', function (agreement) {
                ng2ApiService.setHeader('Monsido-Account-Id', agreement.account.id);

                SupportApi.config.headers['Monsido-Account-Id'] = agreement.account.id;
                LabelTableService.setEditable(ng2SessionService.hasRole('customer_admin')); // Set allow Edit in Labels
                $rootScope.accessibilityMode = agreement.settings.accessibility;
            });

            ng2MonEventsService.addListener(MON_EVENTS.OPEN_EXTENSION_DEEPLINK, async function (payload) {
                await extensionService.openExtensionDeeplink(payload);
            });

        },
    ]);
})();
