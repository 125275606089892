(function () {
    'use strict';
    /**
     * @memberof pages.admin.auditlog
     * @ngdoc service
     * @name AuditlogService
     * @description Service to handle audit log
     */
    angular.module('pages.admin.auditlog').service('AuditlogService', AuditlogService);

    AuditlogService.$inject = [];
    /* @ngInject*/
    function AuditlogService () {
        return {
            IsAnUndoLog: IsAnUndoLog,
            CanUndo: CanUndo,
        };

        /**
         * @memberOf AuditlogService
         * @desc Check if an action is an undo action
         * @param {string} action - Check's action
         * @returns {Boolean}
         */
        function IsAnUndoLog (action) {
            return action === 'undo_ignored' || action === 'undo_reviewed';
        }

        /**
         * @memberOf AuditlogService
         * @desc Check if a log is an AccessibilitySourceCode log
         * @param {string} target - target name
         * @returns {Boolean}
         */
        function CanUndo (target) {
            return target === 'AccessibilitySourceCode';
        }
    }
})();
