(function () {
    'use strict';

    angular.module('app.components.pages').component('pagesFilesPages', {
        templateUrl: 'app/components/pages/files/pages/pages.html',
        controller: PagesEmailController,
        controllerAs: 'vm',
        bindings: {
            link: '<',
            domainId: '<',
            active: '<?',
            icon: '<',
            type: '<?',
        },
    });

    PagesEmailController.$inject = ['LinkPagesRepo', '$controller', 'gettextCatalog', 'PagesFilesPagesService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function PagesEmailController (LinkPagesRepo, $controller, gettextCatalog, PagesFilesPagesService, ng2ActiveFeatureService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.pages.total = 0;
            vm.search = '';
            vm.currentSort = { by: 'hits', direction: 'desc' };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export Pages With Specific Link'),
                    data: {
                        category: 'pages_with_specific_link',
                        category_ref: vm.link.id,
                    },
                },
            ];
            vm.dialogHeader = PagesFilesPagesService.getHeaderTranslation(vm.link.type, vm.type);
            getPage();
        }

        function getPage () {
            var params = {
                domainId: vm.domainId,
                link_id: vm.link.id,
                page_size: vm.pageSize,
                page: vm.page,
                search: vm.search || null,
                'types[]': vm.type == 'document' ? ['document', 'pdf'] : ['html'],
                dialogHeader: vm.dialogHeader,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            vm.promise = LinkPagesRepo.get(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }
    }
})();
