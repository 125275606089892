(function () {
    'use strict';

    angular.module('modules.data_privacy.components').component('domainDashboardDataPrivacy', {
        templateUrl: 'app/modules/data-privacy/components/domain-dashboard-card/domain-dashboard-card.html',
        controller: DashboardDataPrivacyController,
        controllerAs: 'vm',
        bindings: {},
    });

    DashboardDataPrivacyController.$inject = [
        'DataPrivacySummaryRepo',
        '$filter',
        'gettextCatalog',
        'ng2SessionService',
        'complianceDataPrivacyService',
    ];
    /* @ngInject */
    function DashboardDataPrivacyController (DataPrivacySummaryRepo, $filter, gettextCatalog, ng2SessionService, complianceDataPrivacyService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.loading = true;
            getPage();
        }

        function getPage () {
            vm.progress = DataPrivacySummaryRepo.getSummary(ng2SessionService.domain.id).then(function (summary) {
                vm.summaryData = summary;
                setupChart(vm.summaryData);
                vm.loading = false;
            }, angular.noop);
        }

        function setupChart (summary) {
            vm.chartColors = ['#4dc0bd', '#dcdcdc'];
            var percentage = complianceDataPrivacyService.dataPrivacyCompliancePercentage(summary);
            vm.doughnutData = complianceDataPrivacyService.dataPrivacyCompliance(summary);
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: $filter('monNumber')(percentage / 100, '0.[00] %'),
                    totalLabel: gettextCatalog.getString('Overall compliance'),
                    subLabel: gettextCatalog.getString('achieved'),
                    totalFontSize: 150,
                    subFontSize: 150,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
            };
        }
    }
})();
