(function () {
    'use strict';

    angular.module('services.apiRequestCanceller').service('ApiRequestCancellerService', ApiRequestCancellerService);

    ApiRequestCancellerService.$inject = ['$q'];

    /* @ngInject */
    function ApiRequestCancellerService ($q) {
        var promises = new Map();

        return {
            add: add,
            remove: remove,
            abort: abort,
            clear: clear,
        };

        // /////////////

        function add (api, settings, thenResolveCallback, thenRejectCallback) {
            if (!settings || !settings.verb) {
                throw new Error('Settings & HTTP Verb must be defined in Canceller service');
            }
            var promise;
            var defer = $q.defer();
            var route = settings.route;
            var params = settings.params;
            var config = settings.config || {};
            var data = settings.data;
            var verb = settings.verb;
            config.timeout = defer.promise;
            if (settings.verb === 'get' || settings.verb === 'delete') {
                promise = api[verb](route, params, config).then(thenResolveCallback, thenRejectCallback);
            } else {
                promise = api[verb](route, data, params, config).then(thenResolveCallback, thenRejectCallback);
            }
            promises.set(promise, defer);
            setupFinally(promise);
            return promise;
        }

        function remove (promise) {
            if (promises.has(promise)) {
                promises.delete(promise);
            }
        }

        function abort (promise) {
            if (promises.has(promise)) {
                var defer = promises.get(promise);
                defer.resolve();
                promises.delete(promise);
            }
        }

        function clear () {
            promises.clear();
        }

        // PROTECTED

        function setupFinally (promise) {
            promise.finally(function () {
                remove(promise);
            });
        }
    }
})();
