(function () {
    'use strict';

    angular.module('services.api').factory('SpellingRepo', SpellingRepo);

    SpellingRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'SpellingModel'];
    /* @ngInject */
    function SpellingRepo (ng2ApiService, ng2SessionService, SpellingModel) {
        var service = {
            addToDictionary: addToDictionary,
            confirm: confirm,
            getSummary: getSummary,
            getAllWords: getAllWords,
            destroyWord: destroyWord,
            getAllPageSpellingErrors: getAllPageSpellingErrors,
            destroyIgnoredPageSpellingError: destroyIgnoredPageSpellingError,
            destroySpellingError: destroySpellingError,
            destroyPageSpellingError: destroyPageSpellingError,
            getAllPages: getAllPages,
            getAllPagesWithError: getAllPagesWithError,
            getAllErrors: getAllErrors,
            getAllLanguages: getAllLanguages,
            updatePageIgnoreSpellingError: updatePageIgnoreSpellingError,
            getAllIgnoredPageSpellingErrors: getAllIgnoredPageSpellingErrors,
            getAllIgnoredSpellingErrors: getAllIgnoredSpellingErrors,
        };

        return service;

        // /////////////

        function destroyPageSpellingError (page_id, error_id) {
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/pages/' + page_id + '/spelling_errors/' + error_id);
        }

        function destroySpellingError (error_id) {
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/spelling/errors/' + error_id);
        }

        function updatePageIgnoreSpellingError (page_id, error_id) {
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId() + '/pages/' + page_id + '/spelling_errors/' + error_id + '/ignore');
        }

        function getAllPageSpellingErrors (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/spelling_errors', params, config).then(
                toSpellingModels,
            );
        }

        function getAllIgnoredSpellingErrors (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/spelling/dictionary?classification=ignore', params, config);
        }

        function getAllIgnoredPageSpellingErrors (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/ignored_spelling_errors',
                params,
                config,
            );
        }

        function destroyIgnoredPageSpellingError (page_id, spelling_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.delete(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/ignored_spelling_errors/' + spelling_id,
                params,
                config,
            );
        }

        function getSummary (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/spelling/summary', params, config);
        }

        function getAllPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/spelling/pages', params, config);
        }

        function getAllWords (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/spelling/dictionary?classification=dictionary',
                params,
                config,
            );
        }

        function destroyWord (wordId, config) {
            // Remove word from dictionary
            config = config || {};
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/spelling/dictionary/' + wordId, config);
        }

        function getAllErrors (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/spelling/errors', params, config).then(toSpellingModels);
        }

        function getAllPagesWithError (error_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/spelling/errors/' + error_id + '/pages', params, config);
        }

        function addToDictionary (params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/spelling/dictionary', params, config);
        }

        function confirm (wordId, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/spelling/errors/' + wordId,
                params,
                config,
            ).then(toSpellingModel);
        }

        function getAllLanguages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/spelling/languages', params, config);
        }

        // PROTECTED

        function toSpellingModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toSpellingModel(models[i]);
            }
            return models;
        }

        function toSpellingModel (model) {
            return new SpellingModel(model);
        }
    }
})();
