(function () {
    'use strict';

    angular.module('pages.statistics').component('topKeywords', {
        templateUrl: 'app/pages/statistics/sources/summary/top-keywords/top-keywords.html',
        controller: TopKeywordsController,
        controllerAs: 'vm',
        bindings: {
            startDate: '<?',
            endDate: '<?',
        },
    });

    TopKeywordsController.$inject = ['StatsRepo'];
    /* @ngInject */
    function TopKeywordsController (StatsRepo) {
        var vm = this;

        vm.$onChanges = update;
        vm.$onInit = activate;

        function activate () {
            vm.keywords = [];
            getSummary();
        }

        function update () {
            getSummary();
        }

        function getSummary () {
            var params = {
                to: vm.endDate.format('YYYY-MM-DD'),
                from: vm.startDate.format('YYYY-MM-DD'),
                limit: 5,
            };
            vm.promise = StatsRepo.getKeywords(params).then(function (data) {
                vm.keywords = data;
            }, angular.noop);
        }
    }
})();
