(function () {
    'use strict';
    angular.module('modules.accessibility').component('accDocClarityMultipleRemediationsBtn', {
        template:
            '<a ng-if="vm.hasAccess()" ng-class="{\'mon-disabled\': vm.disabled()}" ng-click="!vm.disabled() && vm.confirmation()" mon-event-trigger="actionTrigger" mon-event-params="{action:\'accessibility-bulk-remediation\'}" title="{{\'Request Remediation from selection\' | translate}}"' +
            'translate>Request Remediation from selection</a>',
        controller: AccessibilityDocumentClarityRemediationBtnController,
        controllerAs: 'vm',
        bindings: {
            pdfs: '<',
            afterResolve: '&?',
        },
    });

    AccessibilityDocumentClarityRemediationBtnController.$inject = [
        'ng2SessionService',
        'AccClarityRemediationService',
        '$q',
        'Lodash',
        'ng2MonEventsService',
    ];

    /* @ngInject */
    function AccessibilityDocumentClarityRemediationBtnController (
        ng2SessionService,
        AccClarityRemediationService,
        $q,
        Lodash,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.hasAccess = hasAccess;
        vm.confirmation = confirmation;
        vm.isPDFArray = isPDFArray;
        vm.disabled = disabled;

        function activate () {
            vm.loading = false;
        }

        function isPDFArray () {
            return Array.isArray(vm.pdfs);
        }

        function hasAccess () {
            return Boolean(ng2SessionService.customer && ng2SessionService.customer.plan_traits.clarity_pdf_remediation);
        }

        function disabled () {
            return vm.loading || !vm.isPDFArray() || vm.pdfs.length === 0 || areAllPDFSRemediated();
        }

        function confirmation () {
            if (disabled()) {
                return;
            }
            var successes = 0;
            var failures = 0;
            var filteredPdfs = vm.pdfs.filter(function (pdf) {
                return !pdf.clarity_remediated;
            });
            var chain = $q.when();
            var config = {
                headers: {
                    noGlobal: true,
                },
            };
            ng2MonEventsService.run('remediationMultiBtnClick', { files: filteredPdfs.length });
            AccClarityRemediationService.createRemediationDialog(() => {
                vm.loading = true;
                filteredPdfs.forEach(function (pdf) {
                    chain = chain.then(function () {
                        return createRemediation(pdf, undefined, undefined, config).then(
                            function () {
                                successes = successes + 1;
                            },
                            function () {
                                failures = failures + 1;
                            },
                        );
                    });
                });
                chain.then(function () {
                    vm.loading = false;
                    vm.afterResolve({ success: successes, failure: failures });
                });
            });
        }

        // PROTECTED

        function createRemediation (pdf, data, params, config) {
            return AccClarityRemediationService.createRemediation(pdf, data, params, config);
        }

        function areAllPDFSRemediated () {
            return Lodash.every(vm.pdfs, { clarity_remediated: true });
        }
    }
})();
