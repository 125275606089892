(function () {
    'use strict';

    angular.module('services.api').factory('AgreementRepo', AgreementRepo);

    AgreementRepo.$inject = ['ng2ApiService'];
    /* @ngInject */
    function AgreementRepo (ng2ApiService) {
        var service = {
            getAll: getAll,
        };

        return service;

        // /////////////

        function getAll () {
            return ng2ApiService.get('agreements');
        }
    }
})();
