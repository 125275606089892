(function () {
    'use strict';

    function DataPrivacyRulesetModel (attributes) {
        angular.extend(this, attributes);
    }

    angular.module('modules.data_privacy.services.api').service('DataPrivacyRulesetRepo', DataPrivacyRulesetRepo);

    DataPrivacyRulesetRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function DataPrivacyRulesetRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            getAllForPage: getAllForPage,
        };

        return service;

        // /////////////

        function getAll (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            if (page_id) {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/data_protection/custom_ruleset', params, config).then(
                    toModels,
                );
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/data_protection/custom_ruleset', params, config).then(
                    toModels,
                );
            }
        }

        function getAllForPage (page_id, params) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/data_protection/custom_ruleset',
                params,
            ).then(toModels);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new DataPrivacyRulesetModel(model);
        }
    }
})();
