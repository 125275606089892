import {
    Branding,
    CustomerInterface,
    PlanTraits,
    Reseller,
    Settings,
} from '@monsido/modules/models/api/interfaces/customer.interface';

export class Customer implements CustomerInterface {
    id: number;
    name: string;
    address: string;
    zipcode: string;
    city: string;
    state: string;
    country: string;
    vat_number: string;
    phone_number: string;
    timezone: string;
    settings: Settings;
    branding: Branding;
    plan_traits: PlanTraits;
    plan_type: string;
    reseller: Reseller;
    html_pages_count: number;
    documents_count: number;
    vertical: string;

    constructor (customer: CustomerInterface) {
        /** account.js - AccountModel
         * function Model(attributes) {
            angular.extend(this, attributes);
            this.plan_traits.heatmaps_tracked_number_of_pages = this.plan_traits.heatmaps_tracked_number_of_pages || 10;
            this.country = this.country || "US";
        }
         */
        this.id = customer.id;
        this.name = customer.name;
        this.address = customer.address;
        this.zipcode = customer.zipcode;
        this.city = customer.city;
        this.state = customer.state;
        this.country = customer.country || 'US';
        this.vat_number = customer.vat_number;
        this.phone_number = customer.phone_number;
        this.timezone = customer.timezone;
        this.settings = customer.settings;
        this.branding = customer.branding;
        this.plan_traits = customer.plan_traits;
        this.plan_type = customer.plan_type;
        this.reseller = customer.reseller;
        this.html_pages_count = customer.html_pages_count;
        this.documents_count = customer.documents_count;
        this.vertical = customer.vertical;

        this.plan_traits.heatmaps_tracked_number_of_pages = this.plan_traits.heatmaps_tracked_number_of_pages || 10;
    }
}
