import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components').component('notificationDropdown', {
        templateUrl: 'app/components/notification-dropdown/notification-dropdown.html',
        controller: NotificationDropdownController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            showCategories: '<?',
        },
    });

    NotificationDropdownController.$inject = [
        'ng2MonEventsService',
        'gettextCatalog',
        'ng2SessionService',
        '$filter',
        'MON_MODULES',
        'coreLoginService',
        'ComponentDetailsView',
        'ng2ActiveFeatureService',
    ];

    /* @ngInject */
    function NotificationDropdownController (
        ng2MonEventsService,
        gettextCatalog,
        ng2SessionService,
        $filter,
        MON_MODULES,
        coreLoginService,
        ComponentDetailsView,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openPageDetails = openPageDetails;
        vm.openDocumentDetails = openDocumentDetails;
        vm.notificationStackCount = notificationStackCount;
        vm.countAttributes = countAttributes;

        function activate () {
            vm.stack = [];
            vm.showCategories = vm.showCategories || [];
            vm.blackList = [];
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            coreLoginService.getUser().then(function (user) {
                vm.user = user;
                setCategories();
                scanCpr();
            }, angular.noop);
        }

        function setCategories () {
            var stack = [
                {
                    attr: ['policy_violation_count', 'policy_required_count', 'policy_search_count'],
                    name: gettextCatalog.getString('Policies'),
                    icon: 'fas fa-gavel',
                    target: 'policy',
                    attrCount: countAttributes(['policy_violation_count', 'policy_required_count', 'policy_search_count']),
                    component: 'page-details-section-policies',
                },
                {
                    attr: 'dead_links_count',
                    name: gettextCatalog.getString('Broken Links'),
                    icon: 'fas fa-unlink',
                    target: 'dead-links',
                    attrCount: countAttributes('dead_links_count'),
                    component: 'page-details-section-qa',
                    subComponent: 'brokenlinks',
                },
                {
                    attr: 'dead_images_count',
                    name: gettextCatalog.getString('Broken Images'),
                    icon: 'fas fa-file-image',
                    target: 'broken-images',
                    attrCount: countAttributes('dead_images_count'),
                    component: 'page-details-section-qa',
                    subComponent: 'brokenimages',
                },
                {
                    attr: 'spelling_errors_confirmed_count',
                    name: gettextCatalog.getString('Misspellings'),
                    icon: 'fas fa-language',
                    target: 'misspellings',
                    attrCount: countAttributes('spelling_errors_confirmed_count'),
                    component: 'page-details-section-qa',
                    subComponent: 'misspellings',
                },
                {
                    attr: 'accessibility_checks_with_errors_count',
                    name: gettextCatalog.getString('Accessibility'),
                    icon: 'fas fa-universal-access',
                    target: 'accessibility',
                    attrCount: countAttributes('accessibility_checks_with_errors_count'),
                    component: 'page-details-section-accessibility',
                },
                {
                    attr: 'seo_issues_count',
                    name: gettextCatalog.getString('SEO'),
                    icon: 'fas fa-chart-line',
                    target: 'seo',
                    attrCount: countAttributes('seo_issues_count'),
                    component: 'page-details-section-seo',
                },
                {
                    attr: 'data_protection_violations_counts.active_violations',
                    name: gettextCatalog.getString('Data privacy'),
                    icon: 'fas fa-lock-alt',
                    target: 'data-privacy',
                    attrCount: countAttributes('data_protection_violations_counts.active_violations'),
                    component: 'page-details-section-data-privacy',
                },
            ];

            removeAccessToStackItems();
            setStack(stack);
        }

        function setStack (stack) {
            stack = stack.filter(function (data) {
                return vm.blackList.indexOf(data.target) === -1;
            });

            if (vm.showCategories.length === 0) {
                vm.stack = stack;
            } else {
                vm.stack = stack.filter(function (data) {
                    return vm.showCategories.indexOf(data.target) > -1;
                });
            }
        }

        function openPageDetails (target) {
            target = target || null;
            var data = {
                page: vm.page,
            };

            if (angular.isObject(target)) {
                if (angular.isDefined(target.component)) {
                    data.target = target.component;
                }

                if (angular.isDefined(target.subComponent)) {
                    data.subTarget = target.subComponent;
                }
            }

            var params = {
                body: 'pageDetails',
                size: 'page-details',
                classes: 'page-details-app',
                data: data,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openDocumentDetails (target) {
            target = target || null;
            var params = {};

            if (angular.isObject(target)) {
                if (angular.isDefined(target.component)) {
                    params.target = target.component;
                }
            }

            ComponentDetailsView.goToDocumentDetails(vm.page, params);
        }

        function notificationStackCount () {
            return calculateAllowedStack();
        }

        function calculateAllowedStack () {
            var count = 0;
            for (var i = 0; i < vm.stack.length; i++) {
                count += countAttributes(vm.stack[i].attr);
            }
            return count;
        }

        function countAttributes (attr) {
            var result = propAccessor(attr);
            if (Array.isArray(attr)) {
                result = attr.reduce(function (num, attribute) {
                    return num + (propAccessor(attribute) || 0);
                }, 0);
            }
            return result;
        }

        function propAccessor (attr) {
            if (attr.indexOf('.') > -1) {
                var properties = attr.split('.');
                var propAccessor = Object.assign({}, vm.page);
                properties.forEach(function (property) {
                    if (propAccessor !== null && propAccessor !== undefined) {
                        propAccessor = propAccessor[property] || 0;
                    }
                });
                if (isNaN(propAccessor)) {
                    return 0;
                } else {
                    return propAccessor;
                }
            }
            return vm.page[attr] || 0;
        }

        function removeAccessToStackItems () {
            // Insert conditions here if a component are not allowed for a domain.
            if (
                !$filter('activeFeature')('global_policy') ||
                !$filter('hasAccess')(vm.user, MON_MODULES.policies) ||
                !ng2ActiveFeatureService.isFeatureActivePure('policies_all')
            ) {
                insertToBlackList('policy');
            }

            if (!$filter('hasAccess')(vm.user, MON_MODULES.qa) || !ng2ActiveFeatureService.isFeatureActivePure('qa_links')) {
                insertToBlackList('dead-links');
                insertToBlackList('broken-images');
            }
            if (!$filter('hasAccess')(vm.user, MON_MODULES.qa) || !ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck')) {
                insertToBlackList('misspellings');
            }

            if (!$filter('hasAccess')(vm.user, MON_MODULES.qa)) {
                insertToBlackList('nin-count');
            }

            if (!$filter('hasAccess')(vm.user, MON_MODULES.accessibility)) {
                insertToBlackList('accessibility');
            }

            if (!$filter('hasAccess')(vm.user, MON_MODULES.seo) || !ng2ActiveFeatureService.isFeatureActivePure('seo_all')) {
                insertToBlackList('seo');
            }

            if (!ng2ActiveFeatureService.isFeatureActive(MON_MODULES.data_privacy, ng2SessionService.domain)) {
                insertToBlackList('data-privacy');
            }
        }

        function insertToBlackList (item) {
            if (vm.blackList.indexOf(item) === -1) {
                vm.blackList.push(item);
            }
        }

        function scanCpr () {
            if (ng2SessionService.domain && ng2SessionService.domain.scan) {
                if (ng2SessionService.domain.scan.scan_cpr) {
                    vm.stack.push({
                        attr: 'nin_count',
                        name: gettextCatalog.getString('National Identification Number'),
                        icon: 'fas fa-user-shield',
                        target: 'nin-count',
                        component: 'page-details-section-qa',
                        subComponent: 'nin-count',
                    });
                }
            }
        }
    }
})();
