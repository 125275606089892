(function () {
    'use strict';

    angular.module('modules.data_privacy.services.api').factory('DataPrivacyHelpCenterRepo', DataPrivacyHelpCenterRepo);

    DataPrivacyHelpCenterRepo.$inject = ['SupportApi'];
    /* @ngInject */
    function DataPrivacyHelpCenterRepo (SupportApi) {
        var service = {
            get: get,
        };

        return service;

        // /////////////

        function get (params, config) {
            config = config || {};
            params = params || {};

            return SupportApi.get('info_types', params, config);
        }
    }
})();
