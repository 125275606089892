(function () {
    'use strict';

    angular.module('models').factory('CrawlModel', CrawlFactory);

    CrawlFactory.$inject = ['moment'];

    function CrawlFactory (moment) {
        function Model (attributes) {
            angular.extend(this, attributes);
            this.date = moment(this.date + 'T00:00:00+00:00', moment.ISO_8601).format();
            if (this.queued_at) {
                this.queued_at = moment(this.queued_at, moment.ISO_8601).toISOString();
            }
        }

        return Model;

        // /////////
    }
})();
