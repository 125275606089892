(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityIgnoredPages', {
        templateUrl: 'app/pages/accessibility/pages-with-ignored-checks/pages-with-ignored-checks.html',
        controller: AccessibilityIgnoredPagesController,
        controllerAs: 'vm',
    });

    AccessibilityIgnoredPagesController.$inject = [
        'AccessibilityRepo',
        'ng2SessionService',
        'gettextCatalog',
        '$controller',
        '$filter',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2ActiveFeatureService',
    ];

    function AccessibilityIgnoredPagesController (
        AccessibilityRepo,
        ng2SessionService,
        gettextCatalog,
        $controller,
        $filter,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.accessibility = ng2SessionService.domain.features.accessibility;
            vm.search = '';
            vm.pages = [];
            vm.subheaderTranslation = '';
            vm.currentSort = {
                by: 'priority_score',
                direction: 'desc',
            };
            vm.issueCount = 0;

            setIssuesCounter();
            setupFilters();
            getPage();
        }

        function getSubHeaderDescription () {
            var guideline;
            if ($filter('accessibilityHasGuideline')('wcag2')) {
                guideline = 'WCAG 2.0';
            } else if ($filter('accessibilityHasGuideline')('wcag21')) {
                guideline = 'WCAG 2.1';
            } else if ($filter('accessibilityHasGuideline')('wcag22')) {
                guideline = 'WCAG 2.2';
            }
            var scope = {
                guideline: guideline,
            };
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.pages.total, '0,0'),
                '1 page with one or more ignored checks {{guideline}}',
                '{{$count}} pages with ignored checks {{guideline}}',
                scope,
            );
        }

        function setupFilters () {
            vm.accessibilityAbbr = [];

            vm.filters[gettextCatalog.getString('Accessibility level')] = [];
            vm.availableFilters[gettextCatalog.getString('Accessibility level')] = getAccessibilityOptions();

            if (vm.availableFilters[gettextCatalog.getString('Accessibility level')].length === 0) {
                delete vm.availableFilters[gettextCatalog.getString('Accessibility level')];
            }
        }

        function getAccessibilityOptions () {
            var accessibilityOptions = [];

            switch (vm.accessibility) {
                case 'WCAG2-AAA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AAA'),
                        value: 'AAA',
                    });
                    break;
                case 'WCAG2-AA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    break;
                case 'WCAG21-AAA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AAA'),
                        value: 'AAA',
                    });
                    break;
                case 'WCAG21-AA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    break;
            }

            return accessibilityOptions;
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };
            params['abbr[]'] = vm.accessibilityAbbr;
            params = vm.updateParamsWithFilters(params);

            vm.progress = AccessibilityRepo.getPagesWithIgnoredChecks(params).then(function (data) {
                vm.pages = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function setIssuesCounter () {
            AccessibilityRepo.getSummary().then(function (summary) {
                vm.issueCount = summary.checks;
            });
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);

            if (changes.changes) {
                changes = changes.changes;
            }

            if (angular.isArray(changes[gettextCatalog.getString('Accessibility level')])) {
                vm.accessibilityAbbr = changes[gettextCatalog.getString('Accessibility level')].map(function (abbr) {
                    return abbr.value;
                });
            }

            getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
