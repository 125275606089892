import { downgradeInjectable } from '@angular/upgrade/static';
import { OauthService } from '@monsido/oauth/oauth.service';
import { SessionService } from '@monsido/core/session/session.service';
import { LocalStorageService, SessionStorageService, ToastService, MonPromptService } from '@angular-shared-components';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { CoreAgreementService } from '@monsido/core/session/services/agreement/agreement.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { AccessibilityGuidelineVersionService } from '@monsido/modules/accessibility/services/accessibility-guideline-version.service';
import { AccessibilityGuidelinesInfoService } from '../modules/accessibility/services/mon-accessibility-guidelines/accessibility-guideline-info.service';
import { ScoresService } from '../modules/score/services/scores/scores.service';
import { MonBrowserService } from '@monsido/services/mon-browser/mon-browser.service';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import angular from 'angular';
import { DataPrivacyLikelihoodService } from '@monsido/modules/data-privacy/services/data-privacy-likelihood.service';
import { DirectNavigationService } from '@monsido/services/direct-navigation-service/direct-navigation.service';
import { ExportService } from '@monsido/core/services/export.service';
import { StatisticsDateComparisonService } from '@monsido/modules/statistics/services/date-comparison/statistics-date-comparison.service';
import { MonNotificationsService } from 'ng2/core/services/notifications/mon-notifications.service';
import { DebugLogService } from 'ng2/core/services/debug-log/debug-log.service';
import { HttpLegacyService } from '@monsido/http/http-legacy-service/http-legacy.service';
import { LabelService } from '@monsido/services/label-service/label.service';
import {
    FormScheduleReportService,
} from '@monsido/modules/report-center/services/form-schedule-report/form-schedule-report.service';
import { MonUIRouterTransitionService } from '@monsido/services/ui-router-transition-service/ui-router-transition-service';
import { RollbarErrorHandlerService } from 'ng2/external/rollbar/rollbar-error-handler.service';
import { MonLogService } from 'ng2/services/mon-log-service/mon-log.service';
import { DefaultConstraintsAndExcludesService } from 'app/forms/domain/default-constraints-and-excludes-service/default-constraints-and-excludes.service';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import { FormDomainScriptPageAssistService } from 'app/forms/domain-script-guide/models/page-assist/form-domain-script-page-assist.service';
import { FastTrackSessionService } from '../modules/fast-track/services/session.service';
import { MonUtilsService } from 'app/blocks/service/mon-utils/mon-utils.service';
import { PrivacyFastTrackModelFactoryService } from 'app/modules/data-privacy/services/privacy-fast-track-model-factory/privacy-fast-track-model-factory.service';
import { LightPageFixService } from 'app/modules/light/services/light-page-fix/light-page-fix.service';
import { PageModelFactoryService } from '../models/page-model-factory.service';
import { DeeplinkService } from 'app/modules/deeplink/services/deeplink/deeplink.service';
import { MonModalService } from 'app/modules/modals/services/mon-modal/mon-modal.service';
import { DemoPdfRepoService } from 'app/modules/demodata/endpoints/demo-pdf-repo/demo-pdf-repo.service';
import { ApiService } from 'app/services/api/api-service/api.service';
import { GlobalHelperService } from 'app/services/global-helper/global-helper.service';
import { DemoPageRepoService } from '../modules/demodata/endpoints/pages/demo-page-repo.service';
import { ErrorHandler } from 'app/core/services/error-handler/error-handler';
import { DemoHasAccessService } from 'app/modules/demodata/services/demo-has-access.service';
import { TextSearchService } from '@monsido/ng2/services/text-search/text-search.service';


(function () {
    'use strict';
    angular
        .module('app.core')
        .factory('ng2PrivacyFastTrackModelFactoryService', downgradeInjectable(PrivacyFastTrackModelFactoryService))
        .factory('ng2MonPromptService', downgradeInjectable(MonPromptService))
        .factory('ng2StatisticsDateComparisonService', downgradeInjectable(StatisticsDateComparisonService))
        .factory('ng2ScoresService', downgradeInjectable(ScoresService))
        .factory('ng2MonEventsService', downgradeInjectable(MonEventService))
        .factory('ng2SessionService', downgradeInjectable(SessionService))
        .factory('ng2OauthService', downgradeInjectable(OauthService))
        .factory('ng2ToastService', downgradeInjectable(ToastService))
        .factory('ng2SessionStorageService', downgradeInjectable(SessionStorageService))
        .factory('ng2LocalStorageService', downgradeInjectable(LocalStorageService))
        .factory('ng2CoreAgreementService', downgradeInjectable(CoreAgreementService))
        .factory('ng2MonLoading', downgradeInjectable(MonLoadingService))
        .factory('ng2AccessibilityGuidelineVersionService', downgradeInjectable(AccessibilityGuidelineVersionService))
        .factory('ng2AccessibilityGuidelinesInfoService', downgradeInjectable(AccessibilityGuidelinesInfoService))
        .factory('ng2MonBrowserService', downgradeInjectable(MonBrowserService))
        .factory('ng2DirectNavigationService', downgradeInjectable(DirectNavigationService))
        .factory('ng2ExportService', downgradeInjectable(ExportService))
        .factory('ng2ConfigFocusTrapFactory', downgradeInjectable(ConfigurableFocusTrapFactory))
        .factory('ng2FormScheduleReportService', downgradeInjectable(FormScheduleReportService))
        .factory('ng2LabelService', downgradeInjectable(LabelService))
        .factory('ng2MonNotificationsService', downgradeInjectable(MonNotificationsService))
        .factory('ng2DebugLogService', downgradeInjectable(DebugLogService))
        .factory('ng2HttpLegacyService', downgradeInjectable(HttpLegacyService))
        .factory('ng2MonUIRouterTransitionService', downgradeInjectable(MonUIRouterTransitionService))
        .factory('ng2RollbarErrorHandlerService', downgradeInjectable(RollbarErrorHandlerService))
        .factory('ng2MonLogService', downgradeInjectable(MonLogService))
        .factory('ng2DefaultConstraintsAndExcludesService', downgradeInjectable(DefaultConstraintsAndExcludesService))
        .factory('ng2TextSearchService', downgradeInjectable(TextSearchService))
        .factory('ng2MonPromptService', downgradeInjectable(MonPromptService))
        .factory('ng2ActiveFeatureService', downgradeInjectable(ActiveFeatureService))
        .factory('ng2FormDomainScriptPageAssistService', downgradeInjectable(FormDomainScriptPageAssistService))
        .factory('ng2MonUtilsService', downgradeInjectable(MonUtilsService))
        .factory('ng2FastTrackSessionService', downgradeInjectable(FastTrackSessionService))
        .factory('ng2MonUtilsService', downgradeInjectable(MonUtilsService))
        .factory('ng2LightPageFixService', downgradeInjectable(LightPageFixService))
        .factory('ng2PageModelFactoryService', downgradeInjectable(PageModelFactoryService))
        .factory('ng2DeeplinkService', downgradeInjectable(DeeplinkService))
        .factory('ng2MonModalService', downgradeInjectable(MonModalService))
        .factory('ng2PdfRepoService', downgradeInjectable(DemoPdfRepoService))
        .factory('ng2ApiService', downgradeInjectable(ApiService))
        .factory('ng2GlobalHelperService', downgradeInjectable(GlobalHelperService))
        .factory('ng2DemoPageRepoService', downgradeInjectable(DemoPageRepoService))
    ;

    angular.module('modules.data_privacy.services')
        .factory('ng2DataPrivacylikelihoodService', downgradeInjectable(DataPrivacyLikelihoodService));

    angular.module('core.services.error-handler')
        .factory('ng2ErrorHandler', downgradeInjectable(ErrorHandler));

    angular.module('modules.demodata')
        .factory('ng2DemoHasAccessService', downgradeInjectable(DemoHasAccessService));
})();
