(function () {
    'use strict';

    angular.module('modules.export').factory('exporterAccessibilityParser', exporterAccessibilityParser);

    exporterAccessibilityParser.$inject = ['historyHelper', '$filter', 'gettextCatalog', 'complianceService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function exporterAccessibilityParser (historyHelper, $filter, gettextCatalog, complianceService, ng2ActiveFeatureService) {
        return {
            parse: parse,
        };

        // ///////////////

        function getHeadlines (data, allowVerticalAverage) {
            var result = [];

            result.push(gettextCatalog.getString('Crawled at'));
            result.push(gettextCatalog.getString('Pages crawled'));
            result.push(gettextCatalog.getString('Domain compliance'));

            if (allowVerticalAverage) {
                result.push(gettextCatalog.getString('Comparison to average'));
            }

            result.push(gettextCatalog.getString('Failing checks - A'));
            result.push(gettextCatalog.getString('Failing checks - AA'));
            result.push(gettextCatalog.getString('Failing checks - AAA'));

            result.push(gettextCatalog.getString('Issues'));
            result.push(gettextCatalog.getString('Issues changes since previous crawl'));

            return result;
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            var issuesStack = ['accessiblity_issues_count'];
            var allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');

            csv.push(getHeadlines(data[0], allowVerticalAverage));

            for (var i = 0; i < data.length; i++) {
                entry = [];
                entry.push($filter('userDate')(data[i].post_processing_done_at || data[i].date, 'LL LT'));
                entry.push(data[i].page_count);

                if (data[i].compliancePercentage != null) {
                    entry.push($filter('monNumber')(data[i].compliancePercentage / 100, '0.[00]%'));
                } else {
                    entry.push(getCompliance(data[i]));
                }

                if (allowVerticalAverage) {
                    entry.push(data[i].comparisonToAverage);
                }

                entry.push(data[i].accessibility_checks['A']);
                entry.push(data[i].accessibility_checks['AA']);
                entry.push(data[i].accessibility_checks['AAA']);

                entry.push(data[i].accessiblity_issues_count);
                entry.push(
                    getCaret(data[i].id, data, issuesStack) +
                        ' ' +
                        $filter('monNumber')(
                            historyHelper.getChangePercentageValue(data[i].id, data, issuesStack, 'accessibility') / 100,
                            '0.[00]%',
                        ),
                );
                csv.push(entry);
            }
            return csv;
        }

        function getCompliance (history) {
            if (!history.accessibilityComplianceData) {
                history.accessibilityComplianceData = complianceService.accessibilityHistoryCompliance(history);
            }
            if (history.accessibilityComplianceData && history.accessibilityComplianceData[0]) {
                return $filter('monNumber')(history.accessibilityComplianceData[0] / 100, '0.[00]%');
            }
            return '';
        }

        function getCaret (id, data, issueStack) {
            var value = historyHelper.getChangeValue(id, data, issueStack);
            if (value > 0) {
                return String.fromCharCode(94);
            } else if (value < 0) {
                return 'ˇ';
            }
            return '';
        }
    }
})();
