(function () {
    'use strict';

    angular.module('modules.export').factory('exporterAuditLogParser', exporterAuditLogParser);

    exporterAuditLogParser.$inject = ['$filter', 'gettextCatalog', 'AuditRepo', 'Lodash', 'MonModules', 'AccessibilityCheckNameService'];
    /* @ngInject */
    function exporterAuditLogParser ($filter, gettextCatalog, AuditRepo, Lodash, MonModules, AccessibilityCheckNameService) {
        var donGetData = false;
        var service = {
            parse: parse,
            getData: getData,
            stopGetData: stopGetData,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            return [
                gettextCatalog.getString('Date'),
                gettextCatalog.getString('By user'),
                gettextCatalog.getString('Domain'),
                gettextCatalog.getString('Module'),
                gettextCatalog.getString('Target'),
                gettextCatalog.getString('Identifier'),
                gettextCatalog.getString('Action'),
                gettextCatalog.getString('Comment'),
                gettextCatalog.getString('Page'),
                gettextCatalog.getString('Check'),
            ];
        }

        function parse (data) {
            var csv = [];
                var entry = [];
                var len = data.length;
                var logItem;
                var logItemData;
            csv.push(getHeadlines());

            for (var i = 0; i < len; i++) {
                entry = [];
                logItem = data[i] || {};
                logItemData = logItem.data || {
                    identifier: null,
                    comment: null,
                };

                entry.push($filter('userDate')(logItem.created_at));
                entry.push(logItem.author);
                entry.push(logItem.domain ? logItem.domain.title : null);
                entry.push(MonModules.getModuleNameFromValue(logItem.module));
                entry.push(parseTargetURI(logItem.target_uri));
                if (!logItemData.identifier && logItemData.source_code) {
                    entry.push(logItemData.source_code);
                } else {
                    entry.push(logItemData.identifier);
                }
                entry.push(logItem.action);
                entry.push(logItemData.comment);
                entry.push(logItemData.page);
                entry.push(AccessibilityCheckNameService.getNameSync(logItemData.check_id));
                csv.push(entry);
            }
            return csv;
        }

        function parseTargetURI (target) {
            var temp = target.substring(0, target.indexOf(':'));

            if (temp === 'PageCorrect') {
                return temp;
            }
            return temp.replace(/([A-Z])/g, ' $1').trim();
        }

        function getData (params, dataArray) {
            if (donGetData) {
                donGetData = false;
                return null;
            }
            return AccessibilityCheckNameService.init().then(function () {
                if (!params.page) {
                    params.page = 1;
                } else {
                    params.page++;
                }
                return AuditRepo.getAuditLogs(params).then(function (data) {
                    dataArray = dataArray.concat(data);
                    if (data.length < Lodash.concat(params.page_size)) {
                        return parse(dataArray);
                    }
                    return getData(params, dataArray);
                }, angular.noop);
            }, angular.noop);
        }

        function stopGetData () {
            donGetData = true;
        }
    }
})();
