(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name QAReadabilityService
     * @description Get region details of current active region
     */
    angular.module('blocks.service').factory('QAReadabilityService', QAReadabilityService);

    QAReadabilityService.$inject = ['gettextCatalog', 'READABILITY_HELPER', 'MONSIDO_COLOR'];
    /* @ngInject*/
    function QAReadabilityService (gettextCatalog, READABILITY_HELPER, MONSIDO_COLOR) {
        var vm = this;
        vm.readabilityColorPallete = [
            MONSIDO_COLOR['main-1'],
            MONSIDO_COLOR['secondary-7'],
            MONSIDO_COLOR['secondary-8'],
            '#5E3EA8',
            MONSIDO_COLOR['branding-6'],
            MONSIDO_COLOR['secondary-16'],
            '#FDC50D',
            MONSIDO_COLOR['branding-1'],
        ];
        vm.omitLevels = ['na'];

        var translations = {
            flesch_kincaid_re: {
                1: gettextCatalog.getString('Below 5th grade'),
                5: gettextCatalog.getString('5th grade'),
                6: gettextCatalog.getString('6th grade'),
                7: gettextCatalog.getString('7th grade'),
                8: gettextCatalog.getString('8th to 9th grade'),
                10: gettextCatalog.getString('10th to 12th grade'),
                15: gettextCatalog.getString('College'),
                17: gettextCatalog.getString('College graduate'),
                na: gettextCatalog.getString('No readability score'),
                test_name: gettextCatalog.getString('Flesch Kincaid'),
            },
            lix: {
                'very easy': gettextCatalog.getString('Very easy'),
                easy: gettextCatalog.getString('Easy'),
                medium: gettextCatalog.getString('Medium'),
                hard: gettextCatalog.getString('Hard'),
                'very hard': gettextCatalog.getString('Very hard'),
                na: gettextCatalog.getString('No readability score'),
                test_name: gettextCatalog.getString('LIX'),
            },
        };
        var tooltips = {
            flesch_kincaid_re: {
                1: gettextCatalog.getString('Pages that are easily understood by children below 5\'th grade'),
                5: gettextCatalog.getString(
                    'Pages that are very easy to read. Easily understood by an average 11-year-old student',
                ),
                6: gettextCatalog.getString('Pages that are easy to read. Conversational English for consumers'),
                7: gettextCatalog.getString('Pages that are fairly easy to read'),
                8: gettextCatalog.getString(
                    'Pages that are in plain English. Easily understood by 13- to 15-year-old students',
                ),
                10: gettextCatalog.getString('Pages that are fairly difficult to read'),
                15: gettextCatalog.getString('Pages that are difficult to read'),
                17: gettextCatalog.getString(
                    'Pages that are very difficult to read. Best understood by university graduates',
                ),
                na: gettextCatalog.getString('Pages we could not determine Readability score'),
                test_name: gettextCatalog.getString('Unknown'),
            },
            lix: {
                'very easy': gettextCatalog.getString('The lowest level of readability. Example: children\'s books'),
                easy: gettextCatalog.getString(
                    'Easy to read pages understood by most. Example: fiction literature for adults',
                ),
                medium: gettextCatalog.getString('Medium complexity of text. Example: newspapers and magazines'),
                hard: gettextCatalog.getString(
                    'Hard to read text. Example: popular science magazines and easier academic publications',
                ),
                'very hard': gettextCatalog.getString(
                    'Highest level of readability. Example: complex academic and legal texts',
                ),
                na: gettextCatalog.getString('Pages we could not determine Readability score'),
                test_name: gettextCatalog.getString('Unknown'),
            },
        };

        var subTitle = {
            lix: {
                'very easy': gettextCatalog.getString('Example: children\'s literature'),
                easy: gettextCatalog.getString('Example: fiction books'),
                medium: gettextCatalog.getString('Example: daily newspapers'),
                hard: gettextCatalog.getString('Example: academic publications'),
                'very hard': gettextCatalog.getString('Example: legal texts'),
            },
        };

        return {
            getTranslationScore: getTranslationScore,
            getTooltipScore: getTooltipScore,
            hasTooltipScore: hasTooltipScore,
            getReaderLevelName: getReaderLevelName,
            getColorPallete: getColorPallete,
            getReadabilityLevels: getReadabilityLevels,
            getReadabilityLevelSubTitle: getReadabilityLevelSubTitle,
            sortReadability: sortReadability,
        };

        /**
         * @memberOf QAReadabilityService
         * @desc Get Score translation
         * @param {string} score
         * @param {string} readabilityType
         * @return {string}
         */
        function getTranslationScore (score, readabilityType) {
            // This is only a temporary solution until POEditor translates the words
            if (!readabilityType) {
                return (
                    (translations[score] && translations[score].test_name) || gettextCatalog.getString('Unknown Score')
                );
            }
            return translations[readabilityType][score]; // How should we handle none existing words?
        }

        /**
         * @memberOf QAReadabilityService
         * @desc Determine if Tooltip score has translation
         * @param {string} score
         * @param {string} readabilityType
         * @return {boolean}
         */
        function getTooltipScore (score, readabilityType) {
            if (!readabilityType) {
                return (tooltips[score] && tooltips[score].test_name) || '';
            }
            return tooltips[readabilityType][score];
        }

        /**
         * @memberOf QAReadabilityService
         * @desc Get Score Tooltip translation
         * @param {string} score
         * @param {string} readabilityType
         * @return {string}
         */
        function hasTooltipScore (score, readabilityType) {
            if (!readabilityType || !tooltips[readabilityType]) {
                return false;
            }
            var keys = Object.keys(tooltips[readabilityType]);
            return keys.indexOf(score) > -1;
        }

        // PROTECTED

        /**
         * @memberOf QAReadabilityService
         * @desc Get Reader Level Name
         * @param {*} score
         * @return {string}
         */
        function getReaderLevelName (score) {
            var readerLevelName;
            switch (score) {
                case '1':
                    readerLevelName = 'below_5th';
                    break;
                case '5':
                    readerLevelName = '5th';
                    break;
                case '6':
                    readerLevelName = '6th';
                    break;
                case '7':
                    readerLevelName = '7th';
                    break;
                case '8':
                    readerLevelName = '8th_to_9th';
                    break;
                case '10':
                    readerLevelName = '10th_to_12th';
                    break;
                case '15':
                    readerLevelName = 'college';
                    break;
                case '17':
                    readerLevelName = 'college_graduate';
                    break;
                case 'na':
                    readerLevelName = 'no_score';
                    break;
            }
            return readerLevelName;
        }

        function getColorPallete () {
            return vm.readabilityColorPallete;
        }

        function getReadabilityLevels (readabilityTest, omitNA) {
            if (!READABILITY_HELPER[readabilityTest]) {
                return [];
            }
            return READABILITY_HELPER[readabilityTest].filter(function (level) {
                if (omitNA) {
                    return true;
                } else {
                    return vm.omitLevels.indexOf(level) === -1;
                }
            });
        }

        function getReadabilityLevelSubTitle (score, readabilityType) {
            if (!readabilityType || !subTitle[readabilityType]) {
                return '';
            }
            return subTitle[readabilityType][score] || '';
        }

        /**
         * @memberOf QAReadabilityService
         * @desc Sort Readability levels
         * @param {String} test
         * @param {Array} list
         * @return {Array}
         */

        function sortReadability (test, list) {
            list = list || [];

            return list.slice().sort(function (a, b) {
                return READABILITY_HELPER.weights[test][a.name] - READABILITY_HELPER.weights[test][b.name];
            });
        }
    }
})();
