(function () {
    'use strict';

    angular.module('modules.cache').factory('cacheBaseService', cacheBaseService);

    cacheBaseService.$inject = ['CacheFactory', 'ng2SessionService'];
    /* @ngInject*/
    function cacheBaseService (CacheFactory, ng2SessionService) {
        var service = {
            getCache: getCache,
            get: get,
            put: put,
        };

        return service;

        // ///////////

        function get (cacheType, options) {
            var cache = getCache(cacheType, options).get(getCacheName());
            return cache || {};
        }

        function put (cacheType, value, options) {
            getCache(cacheType, options).put(getCacheName(), value);
        }

        function getCache (cacheType, options) {
            options = options || {
                storageMode: 'localStorage',
                maxAge: 60 * 60 * 1000 * 24 * 365, // 1 year
            };
            var cache = CacheFactory.get(cacheType);
            if (!cache) {
                cache = new CacheFactory(cacheType, options);
            }
            return cache;
        }

        // ///// PROTECTED
        // added this, so cached fro difference customers don't get mixed
        function getCacheName () {
            if (ng2SessionService.customer) {
                return ng2SessionService.me.id + '~' + ng2SessionService.customer.id;
            } else if (ng2SessionService.me) {
                return ng2SessionService.me.id;
            }
            return '';
        }
    }
})();
