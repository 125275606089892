import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboard', {
        templateUrl: 'app/pages/domain/dashboard.html',
        controller: DashboardController,
        controllerAs: 'vm',
    });

    DashboardController.$inject = [
        'CrawlsRepo',
        'ng2SessionService',
        '$stateParams',
        'ng2MonEventsService',
        'PageRepo',
        'MON_MODULES',
        '$q',
        'LinksRepo',
        'pageService',
        'coreLoginService',
        'ApiRequestCancellerService',
        'ng2ActiveFeatureService',
    ];

    /* @ngInject */
    function DashboardController (
        CrawlsRepo,
        ng2SessionService,
        $stateParams,
        ng2MonEventsService,
        PageRepo,
        MON_MODULES,
        $q,
        LinksRepo,
        pageService,
        coreLoginService,
        ApiRequestCancellerService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        var promises = [];
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.domainScanNearLimit = domainScanNearLimit;

        function activate () {
            vm.domain = null;
            vm.domainGroup = null;
            vm.showNotifications = ng2ActiveFeatureService.isFeatureActive('dashboard_important_notifications');
            vm.showPolicy = ng2ActiveFeatureService.isFeatureActivePure('policies_all');
            vm.showSEO = ng2ActiveFeatureService.isFeatureActivePure('seo_all');
            vm.showQA = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck') || ng2ActiveFeatureService.isFeatureActivePure('qa_links');
            vm.customer = ng2SessionService.customer;
            vm.showHeartbeat = vm.customer !== null && vm.customer.plan_traits.heartbeat;
            vm.domainItemCount = 0;
            vm.showAccessibility = false;
            vm.showStatistics = false;
            vm.showDataPrivacy = false;
            vm.authUser = null;
            vm.modules = MON_MODULES;

            if ($stateParams.page_id) {
                openPage();
            }

            vm.authUser = null;
            coreLoginService.getUser().then(function (user) {
                vm.authUser = user;
            }, angular.noop);
            promises.push(getCrawls(), getPages('html'), getPages('document'), getPages('pdf'), getLinks());
            $q.all(promises).then(function (values) {
                setupDomain();
                vm.historyData = values[0];
                vm.pagesCount = parseInt(values[1].total);
                vm.documentsCount = parseInt(values[2].total);
                vm.pdfsCount = parseInt(values[3].total);
                vm.totalContentCount = vm.pagesCount + vm.documentsCount + vm.pdfsCount;
                vm.linksCount = parseInt(values[4].total);
                if (vm.historyData && vm.historyData[0]) {
                    vm.domainItemCount = vm.historyData[0].page_count;
                    if (vm.domain.scan.scan_documents) {
                        vm.domainItemCount = vm.domainItemCount + vm.historyData[0].documents_count;
                    }
                }
            }, angular.noop);
        }

        function onDestroy () {
            for (var i = 0; i < promises.length; i++) {
                ApiRequestCancellerService.abort(promises[i]);
            }
        }

        function getCrawls () {
            var params = {
                page_size: 6,
            };
            return CrawlsRepo.getAll(params);
        }

        function getPages (type) {
            var params = {
                page_size: 1,
                page: 1,
                'types[]': type,
            };

            return PageRepo.getAll(params);
        }

        function getLinks () {
            var params = {
                page_size: 1,
                page: 1,
                page_fixed: false,
            };
            return LinksRepo.getAll(params);
        }

        function openPage () {
            pageService.get($stateParams.page_id).then(function (page) {
                var data = {
                    page: page,
                };

                var params = {
                    body: 'pageDetails',
                    size: 'lg',
                    classes: 'page-details-app',
                    data: data,
                };

                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
            }, angular.noop);
        }

        function domainScanNearLimit () {
            if (!vm.customer || !vm.domain) {
                return false;
            }

            return vm.domainItemCount >= vm.customer.plan_traits.max_scanned_pages;
        }

        function setupDomain () {
            vm.domain = ng2SessionService.domain;
            vm.domainGroup = ng2SessionService.domainGroup;
            vm.showAccessibility = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.accessibility);
            vm.showStatistics = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.statistics);
            vm.showDataPrivacy = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.data_privacy);
            vm.domainItemCount = vm.domain.crawled_pages || 0;

            if (vm.domainGroup === null && vm.domain.scan.scan_documents) {
                var documentCount = 0;
                if (vm.domain.crawl_history) {
                    documentCount = parseInt(vm.domain.crawl_history.documents_count, 10) || 0;
                }
                vm.domainItemCount = vm.domainItemCount + documentCount;
            } else if (vm.domainGroup !== null) {
                vm.domainItemCount = parseInt(vm.domainGroup.page_count, 10) || 0;
            }
        }
    }
})();
