(function () {
    'use strict';

    angular.module('pages.domain').component('pageDetailsDashboardChartsPolicies', {
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/charts/policies/policies.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
        },
    });

    DashboardController.$inject = ['$filter', 'PolicyRepo', 'PAGE_DETAILS_SECTION_CONSTANT'];
    /* @ngInject */
    function DashboardController ($filter, PolicyRepo, PAGE_DETAILS_SECTION_CONSTANT) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.unwantedPolicyErrors = vm.page.policy_violation_count || 0;
            vm.requiredPolicyErrors = vm.page.policy_required_count || 0;
            vm.searchPolicyErrors = vm.page.policy_search_count || 0;
            vm.options = {};
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.data = [];
            vm.labels = ['', ''];
            vm.pageDetailsSectionPolicies = PAGE_DETAILS_SECTION_CONSTANT.POLICIES;

            getPolicyDomain().then(function (values) {
                setupDoughnut(values);
            }, angular.noop);
        }

        function setupDoughnut (policies) {
            var totalPolicies = parseInt(policies.total);
            vm.totalPolicyErrors = vm.unwantedPolicyErrors + vm.requiredPolicyErrors + vm.searchPolicyErrors;
            vm.total = 0;
            if (totalPolicies > 0) {
                vm.percentage = (vm.totalPolicyErrors / totalPolicies) * 100;
                vm.data = [totalPolicies - vm.totalPolicyErrors, vm.totalPolicyErrors];
            } else {
                vm.percentage = 0;
                vm.data = [1, 0];
            }

            vm.options = {
                centerText: {
                    percentageLabel: $filter('number')(100 - vm.percentage, 2) + ' %',
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: totalPolicies > 0,
                    callbacks: {
                        beforeLabel: function (tooltipItem, data) {
                            return '';
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs')(
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                '0,0',
                            );
                        },
                    },
                },
            };
        }

        function getPolicyDomain () {
            return PolicyRepo.domainGetAll({ page: 1, page_size: 1 });
        }
    }
})();
