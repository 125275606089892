(function () {
    'use strict';
    angular.module('app.components').component('tableDictionary', {
        // TODO: Rename dictionary to tableSpellingActions - https://zube.io/monsido/monsido/c/26178
        templateUrl: 'app/components/dictionary/dictionary.html',
        controller: DictionaryController,
        controllerAs: 'vm',
        bindings: {
            word: '@',
            language: '@?',
            callback: '&?',
            classification: '@?',
            wordId: '@?',
        },
    });

    DictionaryController.$inject = [
        'SpellingRepo',
        'spellService',
        'LoadingService',
        'gettextCatalog',
        'ng2MonEventsService',
        'ng2MonModalService',
    ];
    /* @ngInject */
    function DictionaryController (
        SpellingRepo,
        spellService,
        LoadingService,
        gettextCatalog,
        ng2MonEventsService,
        ng2MonModalService,
    ) {
        const vm = this;
        vm.$onInit = activate;

        function activate () {
            const dropdown = [
                {
                    label: gettextCatalog.getString('Confirm as misspelling'),
                    icon: 'faCheck',
                    action: confirmAsMisspelling,
                    eventParams: { action: 'spellcheck-confirm-misspelling' },
                    shouldShow: vm.classification === 'potential',
                },
                {
                    label: gettextCatalog.getString('Add to dictionary'),
                    icon: 'faBook',
                    action: addToDictionary,
                    eventParams: { action: 'spellcheck-add-to-dictionary' },
                },
                {
                    label: gettextCatalog.getString('Add to dictionary for all languages'),
                    icon: 'faAtlas',
                    action: addToAllDictionaries,
                    eventParams: { action: 'spellcheck-add-to-all-dictionary' },
                },
                {
                    label: gettextCatalog.getString('Ignore misspelling'),
                    icon: 'faEyeSlash',
                    action: ignoreMisspelling,
                    eventParams: { action: 'spellcheck-ignore-misspellings' },
                },
                {
                    label: gettextCatalog.getString('Mark as fixed'),
                    icon: 'faCheck',
                    action: markMisspellingAsFixed,
                    eventParams: { action: 'spellcheck-markasfix-misspellings' },
                },
            ];

            vm.dropdownOptions = dropdown.map(item => {
                if (item.shouldShow !== false) {
                    return {
                        label: item.label,
                        leftIcon: item.icon,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                            item.action();
                        },
                    };
                }
            }).filter(Boolean);
        }

        function promptConfirmation (message, callback) {
            ng2MonModalService.confirm(message).then(callback);
        }

        function addToDictionary () {
            const message = gettextCatalog.getString('Are you sure you want to add the word <strong>{{word}}</strong> to the dictionary?', { word: vm.word });
            promptConfirmation(message, ()=> {
                const params = {
                    word: vm.word,
                    language: vm.language,
                    classification: 'dictionary',
                };

                vm.dialog = LoadingService(gettextCatalog.getString('Please wait - Adding word to dictionary'));
                SpellingRepo.addToDictionary(params).then(function () {
                    if (vm.callback) {
                        vm.callback();
                    }
                    vm.dialog.close();
                }, angular.noop);
            });
        }

        function markMisspellingAsFixed () {
            const message = gettextCatalog.getString('Are you sure you want to mark the misspelling <strong>{{word}}</strong> as fixed?', { word: vm.word });
            promptConfirmation(message, ()=>{
                vm.dialog = LoadingService(gettextCatalog.getString('Please wait - Marking word as fixed'));
                spellService.destroySpellingError(vm.wordId).then(function () {
                    if (vm.callback) {
                        vm.callback();
                    }
                    vm.dialog.close();
                }, angular.noop);
            });
        }

        function addToAllDictionaries () {
            const message = gettextCatalog.getString('Are you sure you want to add the word <strong>{{word}}</strong> to the dictionary for all languages?', { word: vm.word });
            promptConfirmation(message,()=>{
                const params = {
                    word: vm.word,
                    classification: 'dictionary',
                };

                vm.dialog = LoadingService(gettextCatalog.getString('Please wait - Adding words to dictionary'));
                SpellingRepo.addToDictionary(params).then(function () {
                    if (vm.callback) {
                        vm.callback();
                    }
                    vm.dialog.close();
                }, angular.noop);
            });
        }

        function ignoreMisspelling () {
            const message = gettextCatalog.getString('Are you sure you want to ignore the misspelling <strong>{{word}}</strong> for all languages?', { word: vm.word });
            promptConfirmation(message, ()=>{
                const params = {
                    word: vm.word,
                    language: vm.language,
                    classification: 'ignore',
                };

                vm.dialog = LoadingService(gettextCatalog.getString('Please wait - Adding word to ignored list'));
                SpellingRepo.addToDictionary(params).then(function () {
                    if (vm.callback) {
                        vm.callback();
                    }
                    vm.dialog.close();
                }, angular.noop);
            });
        }

        function confirmAsMisspelling () {
            const message = gettextCatalog.getString('Are you sure you want to confirm the word <strong>{{word}}</strong> as misspelling?', { word: vm.word });
            promptConfirmation(message,()=>{
                vm.dialog = LoadingService(gettextCatalog.getString('Please wait - Confirming misspelling'));
                spellService.confirmWord(vm.wordId).then(function () {
                    if (vm.callback) {
                        vm.callback();
                    }
                    vm.dialog.close();
                }, angular.noop);
            });
        }
    }
})();
