import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';


(function () {
    'use strict';

    let closeAllDialogsListenerId;
    let loadDialogListenerId;

    angular.module('app.layout').controller('BaseController', BaseController);

    BaseController.$inject = [
        '$state',
        '$sce',
        '$transitions',
        'ng2MonBrowserService',
        'ng2MonEventsService',
        'monDialog',
    ];
    /* @ngInject */
    function BaseController (
        $state,
        $sce,
        $transitions,
        monBrowserService,
        ng2MonEventsService,
        monDialog,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToMainContent = goToMainContent;
        vm.minimizeIeLegacyNotification = minimizeIeLegacyNotification;
        vm.maximizeIeLegacyNotification = maximizeIeLegacyNotification;

        function activate () {
            $transitions.onExit({ exiting: (state) => !!state.onExit }, () => {});
            registerDialogEvents();

            var stateObj = $state.current.data;

            handleSubMenu(stateObj);

            $transitions.onSuccess({}, function (transition) {
                var toState = transition.to();
                handleSubMenu(toState.data);
            });

            vm.ieLegacyNotificationMinimized = false;
            if (monBrowserService.isIe11() || monBrowserService.isEdgeLegacy()) {
                vm.hasIeLegacyNotification = true;
            }
        }

        function registerDialogEvents () {
            removeExistingDialogEvents();
            closeAllDialogsListenerId = ng2MonEventsService.addListener(MON_EVENTS.CLOSE_ALL_DIALOGS, (index) => {
                monDialog.closeAll();
                return index;
            });

            loadDialogListenerId = ng2MonEventsService.addListener(MON_EVENTS.LOAD_DIALOG, (options) => {
                const { params, callback, onBeforeClose } = options;
                const paramsCopy = {
                    ...params,
                    manualFocus: true,
                    parentSelector: '#common-dialog-wrapper',
                };

                const dialogId = monDialog.create(paramsCopy, callback, onBeforeClose);

                return dialogId;
            });
        }

        function removeExistingDialogEvents () {
            if (loadDialogListenerId) {
                ng2MonEventsService.remove(loadDialogListenerId);
                loadDialogListenerId = undefined;
            }
            if (closeAllDialogsListenerId) {
                ng2MonEventsService.remove(closeAllDialogsListenerId);
                closeAllDialogsListenerId = undefined;
            }
        }

        function handleSubMenu (data) {
            vm.activeMenuEntry = data.activeSidebarEntry;
            vm.sidebar = data.sidebar;
            vm.withSidebar = data.sidebar !== undefined;
            vm.menu = $sce.trustAsHtml('<side-menu-' + data.sidebar + '></side-menu-' + data.sidebar + '>');
            vm.submenu = $sce.trustAsHtml('<submenu-' + data.topmenu + '></submenu-' + data.topmenu + '>');
            vm.showSubMenu = data.topmenu !== undefined;
        }

        function goToMainContent () {
            var mainContent = document.getElementById('maincontent');
            mainContent.scrollIntoView();
            mainContent.focus();
        }

        function minimizeIeLegacyNotification () {
            vm.ieLegacyNotificationMinimized = true;
        }

        function maximizeIeLegacyNotification () {
            vm.ieLegacyNotificationMinimized = false;
        }
    }
})();
