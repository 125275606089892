import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.global.users').component('pagesUser', {
        templateUrl: 'app/pages/global/users/user/user.html',
        controller: UserController,
        controllerAs: 'vm',
    });

    UserController.$inject = ['$stateParams', 'ng2SessionService', 'ng2MonEventsService', 'UserRepo', 'FORM_CONSTANT'];
    /* @ngInject */
    function UserController ($stateParams, ng2SessionService, ng2MonEventsService, UserRepo, FORM_CONSTANT) {
        var vm = this;

        vm.openProfile = openProfile;
        vm.$onInit = activate;

        function activate () {
            vm.me = ng2SessionService.me;
            vm.userId = $stateParams.user_id;
            getUser();
        }

        function getUser () {
            UserRepo.get(vm.userId).then(function (user) {
                vm.user = user;
            }, angular.noop);
        }

        function openProfile () {
            var params = {
                body: FORM_CONSTANT.FORM_PROFILE,
                size: 'sm',
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getUser });
        }
    }
})();
