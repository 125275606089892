import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { cloneDeep } from 'lodash';

(function () {
    'use strict';

    angular.module('modules.page-performance').component('formPagePerformancePage', {
        templateUrl: 'app/modules/page-performance/forms/page/page.html',
        controller: FormPagePerformancePagesController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@',
            page: '<',
            assignedProfile: '<',
        },
    });

    FormPagePerformancePagesController.$inject = [
        'PagePerformanceAssignedProfile',
        'monDialog',
        '$deepmerge',
        '$timeout',
        'PageRepo',
        'FormPagePerformancePageService',
        'Lodash',
        'PAGE_PERFORMANCE_LOCATIONS',
        'ng2MonModalService',
        'gettextCatalog',
        'smartScroll',
        'PagePerformanceService',
        'ng2ToastService',
        'ng2MonEventsService',
        'PagePerformanceProfileObsService',
        'FORM_CONSTANT',
        '$document',
        '$element',
    ];

    /* @ngInject */
    function FormPagePerformancePagesController (
        PagePerformanceAssignedProfile,
        monDialog,
        $deepmerge,
        $timeout,
        PageRepo,
        FormPagePerformancePageService,
        Lodash,
        PAGE_PERFORMANCE_LOCATIONS,
        ng2MonModalService,
        gettextCatalog,
        smartScroll,
        PagePerformanceService,
        toastService,
        ng2MonEventsService,
        PagePerformanceProfileObsService,
        FORM_CONSTANT,
        $document,
        $element,
    ) {
        const vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.getPages = getPages;
        vm.selectFrequency = selectFrequency;
        vm.getFrequency = getFrequency;
        vm.setPage = setPage;
        vm.setProfile = setProfile;
        vm.isPageSelected = isPageSelected;
        vm.removeProfile = removeProfile;
        vm.isProfilesSelected = isProfilesSelected;
        vm.locationDetails = locationDetails;
        vm.getSelectedProfiles = getSelectedProfiles;
        vm.openProfile = openProfile;
        vm.getAvailableProfiles = getAvailableProfiles;
        vm.getHeaderText = getHeaderText;
        vm.getProfilePlaceHolder = getProfilePlaceHolder;
        vm.hasProfiles = hasProfiles;
        vm.hasValidDefaultProfile = hasValidDefaultProfile;
        vm.canSubmit = canSubmit;
        vm.showPagesListDropdown = showPagesListDropdown;
        vm.search = '';

        const PAGE_SIZE = 50;
        const ROWS_OFFSET = 10;

        function activate () {
            vm.frequencyOptions = FormPagePerformancePageService.getFrequencyOptions();
            vm.loading = false; // Submitting..

            vm.loadingPages = false; // Loading Pages from the search page overview
            vm.pages = [];
            vm.openDropDown = false;

            vm.model = $deepmerge({}, vm.assignedProfile || {});
            vm.selectedPage = $deepmerge({}, vm.model.page || vm.page || {});
            vm.model.profile_ids = vm.model.profile_ids || [];
            vm.model.page_id = vm.selectedPage.id;
            vm.profiles = [];
            vm.availableProfiles = [];
            vm.profileDict = {};
            vm.page = 1;
            vm.currentTarget = null;

            vm.observer = null;
            vm.indexPage = null;

            if (typeof vm.model.scan_frequency !== 'number') {
                vm.model.scan_frequency = FormPagePerformancePageService.getDefaultFrequencyOption();
            }

            if (typeof vm.model.default_profile_id !== 'number' && vm.model.profile_ids.length > 0) {
                vm.model.default_profile_id = vm.model.profile_ids[0];
            }
            setupListeners();
            getIndexPage()
                .then(getPages);
        }

        const getNewPages = () => {
            vm.page += 1;

            const params = {
                page: vm.page,
                page_size: PAGE_SIZE,
                search: vm.search,
                type: 'html',
                has_performance: false,
            };

            return PageRepo.getAll(params).then(
                (data) => {
                    vm.pages = vm.pages.concat(data);
                    return Promise.resolve(data);
                },
            );
        };

        function resetObserver () {
            if (vm.observer) {
                vm.page = 1;
                vm.observer.disconnect();
                vm.observer = null;
            }
        }

        function getPages () {
            $timeout(function () {
                if (typeof vm.search !== 'string' || vm.search.length < 3) {
                    vm.pages = prependIndexPage([]);
                }

                resetObserver();

                const params = {
                    page: vm.page,
                    page_size: PAGE_SIZE,
                    search: vm.search,
                    type: 'html',
                    has_performance: false,
                };
                vm.loadingPages = PageRepo.getAll(params).then(
                    (pages) => {
                        vm.pages = prependIndexPage(pages);

                        // we have to wait rendering of the rows for setting observer
                        $timeout(() => {
                            if (vm.pages.length >= PAGE_SIZE) {
                                const searchContainer = $element[0].querySelector('.search-container');
                                const targets = $element[0].querySelectorAll('.page-row');
                                vm.currentTarget = targets[targets.length - ROWS_OFFSET];
                                if (vm.currentTarget) {
                                    vm.observer = new IntersectionObserver((entries) => {
                                        if (entries.some(entry => entry.isIntersecting)) {
                                            getNewPages().then((newPages) => {
                                                vm.observer.unobserve(vm.currentTarget);
                                                if (newPages.length === PAGE_SIZE) {
                                                    const updatedTargets = $element[0].querySelectorAll('.page-row');
                                                    vm.currentTarget = updatedTargets[updatedTargets.length - ROWS_OFFSET];
                                                    vm.observer.observe(vm.currentTarget);
                                                } else {
                                                    resetObserver();
                                                }
                                            });
                                        }
                                    }, {
                                        root: searchContainer,
                                    });

                                    vm.observer.observe(vm.currentTarget);
                                }
                            }
                        });

                    },
                    () => {
                        vm.pages = [];
                    },
                );
            });
        }

        function setPage (page) {
            vm.search = '';
            vm.openDropDown = false;
            vm.selectedPage = page;
            vm.model.page_id = page ? page.id : undefined;
        }

        function setProfile (profile) {
            if (profile) {
                vm.model.profile_ids.push(profile.id);
                const profileId = Lodash.findIndex(vm.availableProfiles, { id: profile.id });
                vm.availableProfiles.splice(profileId, 1);
                setDefaultProfileId();
                goToElement('visitor-profile-' + profile.id);
            }
        }

        function removeProfile (profile) {
            const index = vm.model.profile_ids.indexOf(profile.id);
            if (index > -1) {
                const message = gettextCatalog.getString('Are you sure you want to remove the Profile <strong>{{profileName}}</strong>>?', {
                    profileName: profile.name,
                });
                ng2MonModalService.confirm(message).then(function () {
                    vm.model.profile_ids.splice(index, 1);
                    vm.availableProfiles.push(profile);
                    vm.availableProfiles = sortProfiles(vm.availableProfiles);
                    setDefaultProfileId();
                }, angular.noop);
            }
        }

        function selectFrequency (frequency) {
            vm.model.scan_frequency = frequency.value;
        }

        function canSubmit (formCtrl) {
            return formCtrl.$valid && !vm.saving && vm.isPageSelected() && vm.hasProfiles() && vm.hasValidDefaultProfile();
        }

        function submit () {
            vm.loading = true;
            let promise;
            const model = $deepmerge({}, vm.model);
            model.page_id = vm.selectedPage.id;

            if (vm.model.id > 0) {
                promise = PagePerformanceAssignedProfile.update(model);
            } else {
                promise = PagePerformanceAssignedProfile.create(model);
            }
            promise.then(
                function (assignedProfile) {
                    close(assignedProfile);
                },
                function () {
                    vm.loading = false;
                },
            );
        }

        function locationDetails (location) {
            return PAGE_PERFORMANCE_LOCATIONS[location];
        }

        function getFrequency () {
            return Lodash.find(vm.frequencyOptions, { value: vm.model.scan_frequency });
        }

        function isPageSelected () {
            return vm.selectedPage ? !(Object.entries(vm.selectedPage).length <= 1) : false;
        }

        function isProfilesSelected () {
            return vm.selectedProfiles.length > 0;
        }

        function getSelectedProfiles () {
            return Lodash.filter(vm.profiles, function (profile) {
                return Lodash.indexOf(vm.model.profile_ids, profile.id) > -1;
            });
        }

        function openProfile (profile) {
            if (getAvailableProfiles() > 0) {
                const params = {
                    body: FORM_CONSTANT.FORM_PAGE_PERFORMANCE_PROFILE,
                    size: 'md',
                    data: {
                        profile: profile,
                    },
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
            } else {
                const text = gettextCatalog.getString(
                    'You have reached maximum number of profiles<br><br>' +
                    'To discuss further options and packages with our team please press ok, and we will have someone reach out to you.',
                );
                ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-shown' });
                ng2MonModalService.confirm(text).then(requestMoreProfiles, function () {
                    ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-rejected' });
                });
            }
        }

        function getProfilePlaceHolder () {
            if (vm.availableProfiles.length > 0) {
                return gettextCatalog.getString('Select a profile');
            }
            return gettextCatalog.getString('You have no available profiles');
        }

        function hasProfiles () {
            return vm.model.profile_ids.length > 0;
        }

        function hasValidDefaultProfile () {
            return !hasProfiles() || !!vm.profileDict[vm.model.default_profile_id];
        }

        function showPagesListDropdown () {
            vm.openDropDown = true;
        }

        // PROTECTED
        function prependIndexPage (pages) {
            const result = cloneDeep(pages);

            if (!vm.indexPage) {
                return result;
            }

            const shouldPrependIndexPage = (vm.search === '') || (vm.search !== '' && result.length > 0);
            if (!shouldPrependIndexPage) {
                return result;
            }

            // Put index page on top of the list if it exists
            const existedIndexPageIndex = result.findIndex(page => page.id === vm.indexPage.id);
            if (existedIndexPageIndex !== -1) {
                result.splice(existedIndexPageIndex, existedIndexPageIndex);
                result.unshift(vm.indexPage);
            }

            return result;
        }

        async function getIndexPage () {
            const params = {
                page: 1,
                page_size: 1,
                sort_by: 'url',
                sort_dir: 'asc',
                has_performance: false,
            };

            const data = await PageRepo.getAll(params);

            if (data?.length > 0) {
                const [maybeIndexPage] = data;
                try {
                    const urlObj = new URL(maybeIndexPage.url);
                    if (urlObj.pathname === '/') {
                        vm.indexPage = maybeIndexPage;
                        vm.pages = prependIndexPage(vm.pages);
                    }
                } catch (_e) {
                    vm.indexPage = null;
                }
            }
        }

        function close (value) {
            monDialog.close(vm.monDialogId, value);
        }

        function setupProfiles (profiles) {
            vm.profiles = sortProfiles(profiles);
            vm.availableProfiles = Lodash.filter(profiles, function (profile) {
                return vm.model.profile_ids.indexOf(profile.id) === -1;
            });
            vm.profileDict = {};
            Lodash.forEach(vm.profiles, function (profile) {
                vm.profileDict[profile.id] = profile;
            });
            vm.model.profile_ids = vm.model.profile_ids.filter(function (profileId) {
                return !!vm.profileDict[profileId];
            });
        }

        function sortProfiles (profiles) {
            return Lodash.sortBy(profiles, 'id');
        }

        function setDefaultProfileId () {
            if (vm.model.profile_ids.length > 0) {
                if (Lodash.indexOf(vm.model.profile_ids, vm.model.default_profile_id) === -1) {
                    vm.model.default_profile_id = vm.model.profile_ids[0];
                }
            } else {
                vm.model.default_profile_id = null;
            }
        }

        function requestMoreProfiles () {
            ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-confirmed' });
            vm.saving = true;
            PagePerformanceService.requestMoreProfiles(gettextCatalog.getString('Contact me about more performance profiles'))
                .then(function () {
                    toastService.success(gettextCatalog.getString('Our team has been notified of your request. We will soon be in touch.'));
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }

        function getAvailableProfiles () {
            return FormPagePerformancePageService.getMaxProfileCount() - (vm.profiles.length || 0);
        }

        function goToElement (elementId) {
            $timeout(function () {
                const container = document.querySelector('.mon-dialog-body-with-footer');
                smartScroll.scrollTo(container, elementId, 120);
            });
        }

        function getHeaderText () {
            if (vm.assignedProfile && !isNaN(vm.assignedProfile.id)) {
                return gettextCatalog.getString('Edit Performance Page');
            }
            return gettextCatalog.getString('New Performance Page');
        }


        function setupListeners () {
            PagePerformanceProfileObsService.profilesObs.subscribe(function (profiles) {
                setupProfiles(profiles);
            });
        }
    }
})();
