(function () {
    'use strict';

    angular.module('services.api').factory('BrokenPageRepo', BrokenPageRepo);

    BrokenPageRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function BrokenPageRepo (ng2ApiService, ng2SessionService) {
        var service = {
            get: get,
        };

        return service;

        // /////////////

        function get (params) {
            params = params || {};
            if (params.search) {
                params.search = '%' + params.search + '%';
            }
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/broken/pages', params);
        }
    }
})();
