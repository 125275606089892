(function () {
    'use strict';

    angular.module('pages.global.policy').component('globalPolicyDialogsTargets', {
        templateUrl: 'app/pages/global/policy/dialogs/targets/targets.html',
        controller: DomainsController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
            monDialogId: '@?',
        },
    });

    DomainsController.$inject = [
        '$state',
        'ng2MonEventsService',
        'monDialog',
        'ng2SessionService',
        'Lodash',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject */
    function DomainsController ($state, ng2MonEventsService, monDialog, ng2SessionService, Lodash, ng2MonUIRouterTransitionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToDomain = goToDomain;
        vm.getDomainFromTarget = getDomainFromTarget;

        function activate () {
            vm.loading = true;
            vm.cPolicy = angular.copy(vm.policy);
            vm.maxCount = vm.cPolicy.targets.reduce(function (num, domain) {
                return Math.max(num, domain.matches_count);
            }, 0);
            vm.cPolicy.targets = vm.cPolicy.targets.sort(function (a, b) {
                return a.matches_count > b.matches_count ? -1 : a.matches_count < b.matches_count ? 1 : 0;
            });
            getDomains();
            vm.exports = [
                {
                    data: {
                        category: 'urls_with_policy_across_domains',
                        category_ref: vm.policy.id,
                        isGlobalExport: true,
                    },
                },
            ];
        }

        function getDomains () {
            ng2SessionService.domains.subscribe(function (domains) {
                vm.domains = domains && domains.flattenDomains && domains.flattenDomains() || [];
                vm.loading = false;
            });
        }

        function goToDomain (domain) {
            var params = {
                domainId: domain.id,
                policy_id: vm.policy.id,
                search: vm.policy.name,
            };
            if (domain.type === 'DomainGroup') {
                params.domainId = domain.domainId;
                params.domain_group = domain.id;
            }
            monDialog.close(vm.monDialogId);

            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.policy.policies', params).then(function () {
                    ng2MonEventsService.run('changed-domain');
                }, angular.noop),
            );
        }

        function getDomainFromTarget (domainTarget) {
            return Lodash.find(vm.domains, { type: domainTarget.type, id: domainTarget.id });
        }
    }
})();
