(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name uptimeService
     * @description Service to handle uptimes
     */
    angular.module('services.entities').factory('uptimeService', uptimeService);

    uptimeService.$inject = ['UptimeRepo'];
    /* @ngInject*/
    function uptimeService (UptimeRepo) {
        return {
            create: create,
            update: update,
            destroy: destroy,
            createScheduledDowntime: createScheduledDowntime,
            updateScheduledDowntime: updateScheduledDowntime,
            destroyScheduledDowntime: destroyScheduledDowntime,
        };

        function destroy (id, config) {
            return UptimeRepo.destroy(id, config);
        }

        function create (params, config) {
            return UptimeRepo.create(params, config);
        }

        function update (data, params, config) {
            return UptimeRepo.update(data, params, config);
        }

        function createScheduledDowntime (uptimeId, params, config) {
            return UptimeRepo.createScheduledDowntime(uptimeId, params, config);
        }

        function updateScheduledDowntime (uptimeId, id, data, params, config) {
            return UptimeRepo.updateScheduledDowntime(uptimeId, id, data, params, config);
        }

        function destroyScheduledDowntime (uptimeId, id, params, config) {
            return UptimeRepo.destroyScheduledDowntime(uptimeId, id, params, config);
        }
    }
})();
