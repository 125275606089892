(function () {
    'use strict';
    angular
        .module('modules.light.pages.domain.page_fix')
        .service('LightDomainPageFixDashboardCategoryModelService', LightDomainPageFixDashboardCategoryModelService);

    LightDomainPageFixDashboardCategoryModelService.$inject = ['PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG'];

    /* @ngInject*/
    function LightDomainPageFixDashboardCategoryModelService (PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG) {
        function Model (params) {
            var vm = this;
            vm.name = params.name;
            vm.totalFixes = 0;
            vm.fixes = 0;
            vm.fixCollection = [];
            var category = params.category;
            for (var key in PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG) {
                if (PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG.hasOwnProperty(key)) {
                    var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[key];
                    if (config.work_types && config.work_types.indexOf(category) > -1) {
                        vm.totalFixes++; // Count the total count upwards
                    }
                }
            }
        }

        Model.prototype.addPageFix = addPageFix;

        return Model;

        // ///////////////

        function addPageFix (pageFix) {
            var vm = this;
            vm.fixes = vm.fixCollection.push(pageFix);
        }
    }
})();
