(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.helpers
     * @ngdoc service
     * @name ChartsDataHelper
     * @description
     * Generate the necessary data for the chart's axes type
     */
    angular.module('blocks.helpers').service('ChartsDataHelper', ChartsDataHelper);

    ChartsDataHelper.$inject = ['$filter', 'moment'];
    /* @ngInject*/
    function ChartsDataHelper ($filter, moment) {
        var vm = this;

        /**
         * @memberOf ChartsDataHelper
         * @description Set the dataType of the service should generate
         * @param {String} dataType
         * @return {Object}
         */
        function init (dataType) {
            vm.dataType = dataType;

            return {
                setData: setData,
                getCount: getCount,
            };
        }

        return init;

        // ///////////////

        /**
         * @memberOf ChartsDataHelper
         * @description Return the data that should be generated for the chart
         * @param crawl
         * @param params
         * @return {*}
         */
        function setData (crawl, params) {
            switch (vm.dataType) {
                case 'time':
                    return getDateAndCount(crawl, params);
                default:
                    return getCount(crawl, params);
            }
        }

        /**
         * @memberOf ChartsDataHelper
         * @description Return the needed object to make the time cartesian
         * @param {Object} crawl
         * @param {String|Array} params
         * @return {Number}
         */
        function getCount (crawl, params) {
            if (angular.isString(params)) {
                params = [params];
            }
            return $filter('addObjectAttributes')(crawl, params);
        }

        /**
         * @memberOf ChartsDataHelper
         * @description Return the needed object to make the time cartesian
         * @param {Object} crawl
         * @param {String|Array} param
         * @return {Object}
         */
        function getDateAndCount (crawl, param) {
            if (angular.isString(param)) {
                param = [param];
            }

            return {
                x: crawl.date,
                y: $filter('addObjectAttributes')(crawl, param),
            };
        }
    }
})();
