(function () {
    'use strict';

    angular.module('modules.moments.run').run([
        'translation',
        'ng2MonEventsService',
        function (translation, ng2MonEventsService) {
            ng2MonEventsService.addListener('afterLoadUser', function (user) {
                translation.setDateLocale(user.locale);
            });
        },
    ]);
})();
