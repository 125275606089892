(function () {
    'use strict';

    angular.module('core.run').run([
        'ng2MonEventsService',
        'hotkeys',
        'gettextCatalog',
        '$rootScope',
        'meService',
        'ng2SessionService',
        'ng2MonLoading',
        function (ng2MonEventsService, hotkeys, gettextCatalog, $rootScope, meService, ng2SessionService, monLoading) {
            var saving = false;
            ng2MonEventsService.addListener('afterLoadUser', function () {
                hotkeys.bindTo($rootScope).add({
                    combo: 'shift+a',
                    description: gettextCatalog.getString('Enable / Disable Accessibility mode'),
                    callback: function (event, hotkey) {
                        if (!saving) {
                            saving = true;
                            monLoading.show();
                            var me = angular.copy(ng2SessionService.me);
                            me.settings.accessibility = !me.settings.accessibility;
                            meService
                                .update(me)
                                .then(function () {
                                    monLoading.hide();
                                }, angular.noop)
                                .finally(function () {
                                    saving = false;
                                });
                        }
                    },
                });
            });
        },
    ]);
})();
