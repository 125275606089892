import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.layout').component('sideMenu', {
        templateUrl: 'app/layout/sidemenu/sidemenu.template.html',
        controller: SideMenuComponent,
        controllerAs: 'vm',
        bindings: {
            menu: '<',
        },
    });

    SideMenuComponent.$inject = ['$state', 'monDialog', 'ng2MonEventsService', 'ng2MonUIRouterTransitionService'];

    function SideMenuComponent ($state, monDialog, ng2MonEventsService, ng2MonUIRouterTransitionService) {
        var vm = this;
        vm.menuClick = menuClick;
        vm.subActiveRoute = subActiveRoute;
        vm.$onInit = activate;
        vm.openDialog = openDialog;

        // ///////////////////////////////////////

        function activate () {
            angular.forEach(vm.menu, function (menu) {
                if (
                    menu.href === $state.current.name ||
                    (menu.activeRoutes && menu.activeRoutes.indexOf($state.current.name) !== -1)
                ) {
                    menu.open = true;
                    menu.active = true;
                }
            });
        }

        function openDialog (params) {
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function menuClick (menuIndex) {
            var menu = vm.menu[menuIndex];

            if (menu.subs) {
                if (menu.open === true) {
                    menu.open = false;
                } else {
                    angular.forEach(vm.menu, function (menu) {
                        menu.open = false;
                    });
                    menu.open = true;
                }
            } else {
                if (menu.href) {
                    ng2MonUIRouterTransitionService.onTransitionFinished(
                        () => $state.go(menu.href),
                    );
                } else {
                    openDialog(menu.dialogParams);
                }
            }
        }

        function subActiveRoute (sub) {
            return sub.activeRoutes ? sub.activeRoutes.indexOf($state.current.name) !== -1 : false;
        }
    }
})();
