(function () {
    'use strict';
    angular
        .module('modules.light.pages.domain.page_fix')
        .service('LightDomainPageFixDashboardService', LightDomainPageFixDashboardService);

    LightDomainPageFixDashboardService.$inject = [
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        'PAGE_FIX_ACCESSIBILITY_CHECK_TAB',
        'gettextCatalog',
        'LightDomainPageFixDashboardCategoryModelService',
        'LightDomainPageFixDashboardPageFixModelService',
    ];

    /* @ngInject*/
    function LightDomainPageFixDashboardService (
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        PAGE_FIX_ACCESSIBILITY_CHECK_TAB,
        gettextCatalog,
        LightDomainPageFixDashboardCategoryModelService,
        LightDomainPageFixDashboardPageFixModelService,
    ) {
        function init () {
            return {
                getPageFixesByCategory: getPageFixesByCategory,
                parseToPageFixModels: parseToPageFixModels,
            };
        }

        return init();

        // ///////////////

        function getPageFixesByCategory (pageFixes) {
            var websiteStructure = new LightDomainPageFixDashboardCategoryModelService({
                name: gettextCatalog.getString('Website Structure'),
                category: PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE,
            });
            var design = new LightDomainPageFixDashboardCategoryModelService({
                name: gettextCatalog.getString('Design'),
                category: PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN,
            });
            var links = new LightDomainPageFixDashboardCategoryModelService({
                name: gettextCatalog.getString('Links'),
                category: PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS,
            });
            var media = new LightDomainPageFixDashboardCategoryModelService({
                name: gettextCatalog.getString('Media'),
                category: PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA,
            });

            pageFixes.forEach(function (pageFix) {
                var category = null;

                if (isPartOfCategory(pageFix, PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE)) {
                    category = websiteStructure;
                } else if (isPartOfCategory(pageFix, PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN)) {
                    category = design;
                } else if (isPartOfCategory(pageFix, PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS)) {
                    category = links;
                } else if (isPartOfCategory(pageFix, PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA)) {
                    category = media;
                }

                if (category) {
                    category.addPageFix(pageFix);
                }
            });

            return [websiteStructure, design, links, media];
        }

        function parseToPageFixModels (pageFixes) {
            for (var i = 0; i < pageFixes.length; i++) {
                pageFixes[i] = new LightDomainPageFixDashboardPageFixModelService(pageFixes[i]);
            }
            return pageFixes;
        }

        // PROTECTED

        function isPartOfCategory (pageFix, category) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[pageFix.change.check_id];
            if (config) {
                return config.work_types.indexOf(category) > -1;
            }
        }
    }
})();
