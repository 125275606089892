(function () {
    'use strict';

    angular.module('pages.search').component('policyViolationsTable', {
        templateUrl: 'app/pages/search/violations-table/violations.html',
        controller: ViolationsController,
        controllerAs: 'vm',
        bindings: {
            progress: '<',
            pages: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            buttonIcon: '@?',
            showNormalViolations: '<?',
            showUnwantedViolations: '<?',
            currentSort: '<',
            onPageSort: '&',
            tab: '<?',
            callBack: '&?',
        },
    });

    ViolationsController.$inject = ['$controller', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function ViolationsController ($controller, ng2ActiveFeatureService) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.sortPage = sortPage;

        vm.$onInit = activate;
        function activate () {
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.buttonIcon = vm.buttonIcon || 'fas fa-circle';
            vm.showNormalViolations = vm.showNormalViolations || true;
            vm.showUnwantedViolations = vm.showUnwantedViolations || true;
            vm.tab = vm.tab || '';
            vm.getLabels();
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ size: size });
        }

        function sortPage ({ by, direction }) {
            vm.currentSort = { by, direction };
            vm.onPageSort({ sort: vm.currentSort });
        }
    }
})();
