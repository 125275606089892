(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsCities', {
        templateUrl: 'app/pages/statistics/visitors/locations/cities/cities.html',
        controller: statisticsVisitorsCitiesController,
        controllerAs: 'vm',
    });

    statisticsVisitorsCitiesController.$inject = [
        'StatsRepo',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$timeout',
        '$filter',
        'moment',
        'ng2SessionService',
        'dateRangeService',
    ];
    /* @ngInject */
    function statisticsVisitorsCitiesController (
        StatsRepo,
        $stateParams,
        ParamService,
        gettextCatalog,
        $timeout,
        $filter,
        moment,
        ng2SessionService,
        dateRangeService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.updateDateRange = updateDateRange;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.totalVisits = 0;
            vm.date = dateRangeService.setRangeDates();
            vm.countryCode = $stateParams.countryCode;
            vm.regionCode = $stateParams.regionCode;
            vm.countryName = $stateParams.countryName || vm.countryCode;
            vm.regionName = $stateParams.regionName || vm.regionCode;

            setupDoughnutSettings();
            updateDateRange();
        }

        function setupDoughnutSettings () {
            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.doughnutOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: function (element) {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: function (element) {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
        }

        function getPage () {
            var params = {
                limit: 100,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                region_label: vm.regionName,
                country_code: vm.countryCode,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };

            var paramsVisitors = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
                search: vm.regionName,
            };

            vm.loading = true;

            vm.promise = StatsRepo.getCities(params).then(function (data) {
                vm.pages = data;
                vm.totalVisits = data.reduce(function (total, page) {
                    return total + parseInt(page.visits);
                }, 0);
                setupDoughnutData();
            }, angular.noop);

            StatsRepo.getRegions(paramsVisitors, { headers: { noParseSearch: true } })
                .then(function (data) {
                    if (data.length >= 1) {
                        vm.summary = data[0];
                        vm.summary.pageviews = 1;
                        if (parseInt(vm.summary.visits) > 0 && parseInt(vm.summary.avg_time) > 0) {
                            vm.summary.avg_time = vm.summary.avg_time / vm.summary.visits;
                        }

                        if (parseInt(vm.summary.visits) > 0 && parseInt(vm.summary.bounce_rate) > 0) {
                            var bounceRate = vm.summary.bounce_rate / vm.summary.visits;
                            vm.summary.bounce_rate = $filter('monNumber')(bounceRate, '0.[00]%');
                        }

                        if (parseInt(vm.summary.visits) > 0 && parseInt(vm.summary.actions) > 0) {
                            var avgActions = vm.summary.actions / vm.summary.visits;
                            vm.summary.avg_actions = $filter('monNumber')(avgActions, '0.[00]');
                        }
                    } else {
                        vm.summary = null;
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loaded = true;
                });
        }

        function setupDoughnutData () {
            var others = 0;
            vm.doughnutLabels = [];
            vm.doughnutData = [];
            for (var i = 0; i < vm.pages.length; i++) {
                if (i < 5) {
                    vm.doughnutLabels.push(vm.pages[i].label);
                    vm.doughnutData.push(vm.pages[i].visits);
                } else {
                    others += parseInt(vm.pages[i].visits);
                }
            }
            if (vm.pages.length > 5) {
                vm.doughnutLabels.push(gettextCatalog.getString('Others'));
                vm.doughnutData.push(others);
            }
        }

        function pageChange (page) {
            vm.page = page;
            getPage();
        }

        function perPageChange (pageSize) {
            vm.pageSize = pageSize;
            getPage();
        }

        function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
                countryName: vm.countryName,
                regionName: vm.regionName,
            });
            getPage();
        }
    }
})();
