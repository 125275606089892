import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pages').component('pagesSeoIssues', {
        templateUrl: 'app/pages/seo/issues/seo-issues/seo-issues.html',
        controller: SeoIssuesController,
        controllerAs: 'vm',
        bindings: {
            header: '@',
            classification: '@',
            pageCount: '<',
            subHeader: '@?',
            faIcon: '@?',
            theme: '@?',
            category: '@?',
        },
    });

    SeoIssuesController.$inject = [
        'SeoRepo',
        'ng2MonEventsService',
        'ng2MonModalService',
        'gettextCatalog',
        '$filter',
        'SEOHelpMessageService',
        'seoService',
    ];

    /* @ngInject */
    function SeoIssuesController (
        SeoRepo,
        ng2MonEventsService,
        ng2MonModalService,
        gettextCatalog,
        $filter,
        SEOHelpMessageService,
        seoService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.ignoreCheckpoint = ignoreCheckpoint;
        vm.sortPage = sortPage;
        vm.getPage = getPage;
        vm.pagesList = pagesList;
        vm.unignoreCheckpoint = unignoreCheckpoint;
        vm.canShowHelpMessage = SEOHelpMessageService.canShowMessageForDomain;
        vm.getHelpMessage = SEOHelpMessageService.getHelpMessage;

        function activate () {
            vm.target = 'seo';
            vm.theme = vm.theme || 'link';
            vm.pageCount = vm.pageCount || 0;
            vm.pages = [];
            getPage();
        }

        function getPage () {
            var params = {
                classification: vm.classification,
            };

            vm.promise = SeoRepo.getAll(params).then(function (data) {
                vm.pages = data.sort(function (x, y) {
                    if (x.ignored || y.ignored) {
                        if (x.ignored && !y.ignored) {
                            return 1;
                        } else if (!x.ignored && y.ignored) {
                            return -1;
                        }
                    }
                    return x.pages_count > y.pages_count ? -1 : x.pages_count < y.pages_count ? 1 : 0;
                });
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const seo of vm.pages) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Ignore'),
                        action: ()=>ignoreCheckpoint(seo),
                        eventParams: { action: 'seo-ignore' },
                        shouldShow: !seo.ignored,
                    },
                    {
                        label: gettextCatalog.getString('Unignore'),
                        action: ()=>unignoreCheckpoint(seo),
                        eventParams: { action: 'seo-unignore' },
                        shouldShow: seo.ignored,
                    },
                ];

                seo.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                            item.action();
                        },
                    };
                });
            }
        }

        function ignoreCheckpoint (checkpoint) {
            var confirmText = '<strong>' + $filter('translateSeoIssue')(checkpoint.name) + '</strong>';
            confirmText += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');

            ng2MonModalService.prompt(confirmText).then(function (result) {
                checkpoint.comment = result;
                checkpoint.ignored = true;
                vm.progress = seoService.update(checkpoint.id, checkpoint).then(getPage, getPage);
            }, angular.noop);
        }

        function unignoreCheckpoint (checkpoint) {
            var confirmText = '<strong>' + $filter('translateSeoIssue')(checkpoint.name) + '</strong>';
            confirmText += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');

            ng2MonModalService.prompt(confirmText).then(function (result) {
                checkpoint.comment = result;
                checkpoint.ignored = false;
                vm.progress = seoService
                    .update(checkpoint.id, checkpoint)
                    .then(function () {
                        if (!checkpoint.ignored) {
                            var alertMessage =
                                '<strong>' + gettextCatalog.getString('Checkpoint has been updated.') + '</strong><br/><br/>';
                            alertMessage += gettextCatalog.getString(
                                'The Checkpoint {{checkpoint}} will be updated with data after next crawl',
                                {
                                    checkpoint: '<code>' + $filter('translateSeoIssue')(checkpoint.name) + '</code>',
                                },
                            );
                            ng2MonModalService.alert(alertMessage);
                        }
                    }, angular.noop)
                    .finally(getPage);
            }, angular.noop);
        }

        function sortPage () {
            getPage();
        }

        function pagesList (issue) {
            var dialogBody;

            switch (issue.name) {
                case 'seo_duplicate_title':
                case 'seo_duplicate_h1':
                    dialogBody = 'seoDialogPagesWithDuplicateTitle';
                    break;
                default:
                    dialogBody = 'pagesFromSeoIssues';
            }

            var params = {
                body: dialogBody,
                size: 'lg',
                data: { issue: issue, pageCount: vm.pageCount },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

    }
})();
