import { Injectable } from '@angular/core';
import { DomainType } from '@monsido/modules/models/api/domain';
import type { Domain } from '@monsido/modules/models/api/domain';
import type { Report } from '@monsido/modules/models/api/report';
import type { User } from '@monsido/modules/models/api/user';
import { DomainWithDomainUsers } from './form-schedule-report.types';

@Injectable({
    providedIn: 'root',
})
export class FormScheduleReportService {

    setupDomainUsers (domains: Array<Domain>, report: Report): Array<DomainWithDomainUsers> {
        return domains.map((domain) => ({
            ...domain,
            users: this.getUsersFromTargets(domain.id, DomainType.Domain, report) || [],
            domain_groups: domain.domain_groups?.map((group) => ({
                ...group,
                users: this.getUsersFromTargets(group.id, DomainType.DomainGroup, report) || [],
            })) || [],
        }));
    }

    private getUsersFromTargets (
        domainId: number, domainType: DomainType, report: Report,
    ): User[] | undefined {
        return report.targets.find(target => target.id === domainId && target.type === domainType)?.users;
    }
}
