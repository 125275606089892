(function() {
    "use strict";
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name reportService
     * @description Service to handle reports
     */
    angular.module("services.entities").factory("reportService", reportService);

    reportService.$inject = ["ReportRepo"];
    /* @ngInject*/
    function reportService(ReportRepo) {
        return {
            update: update,
            create: create,
            remove: remove,
        };

        function update(id, params, headers) {
            return ReportRepo.update(id, params, headers);
        }

        function create(params, headers) {
            return ReportRepo.create(params, headers);
        }

        function remove(id, params, headers) {
            return ReportRepo.remove(id, params, headers);
        }
    }
})();
