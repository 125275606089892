import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('accessibilityDialogsHelpCenter', {
        templateUrl: 'app/modules/accessibility/dialogs/help-center/help-center.html',
        controller: SectionAccessibilityController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
            data: '<',
        },
    });

    SectionAccessibilityController.$inject = ['AccessibilityChecksRepo', '$filter', 'ng2MonEventsService', 'ng2MonLogService'];
    /* @ngInject */
    function SectionAccessibilityController (AccessibilityChecksRepo, $filter, ng2MonEventsService, ng2MonLogService) {
        var vm = this;
        vm.$onInit = activate;
        vm.setTypeColor = setTypeColor;
        vm.openNewHelpCenter = openNewHelpCenter;

        function activate () {
            getPage();
        }

        function getPage () {
            // AccessibilityChecksRepo.getHelp(vm.check.id).then(function(data) {
            // ng2MonLogService.log('data is', data);
            var html = vm.data.suggestion;
            html = searchAndReplace(html, /\/accessibility\/images\/arrow.gif/g, '/images/accessibility/arrow.gif');
            html = searchAndReplace(
                html,
                /<h2>Error Type<\/h2>(\r\n|\r|\n)<span class="msg">.*<\/span>/,
                '<h2>Error Type</h2><span class="msg">' + $filter('capitalize')(vm.check.result_type) + '</span>',
            );
            vm.html = html;
            // }, angular.noop);
        }

        function searchAndReplace (content, search, replace) {
            return content.replace(search, replace);
        }

        function setTypeColor () {
            if (vm.check.issue_abbr === 'A') {
                return 'wcag-a';
            } else if (vm.check.issue_abbr === 'AA') {
                return 'wcag-aa';
            } else if (vm.check.issue_abbr === 'AAA') {
                return 'wcag-aaa';
            }
            return 'info';
        }

        function openNewHelpCenter () {
            var params = {
                body: 'accessibilityDialogsNewHelpCenter',
                data: { check: vm.check, data: vm.data },
                size: 'lg',
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
