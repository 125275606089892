(function () {
    'use strict';

    angular.module('models').factory('AccessibilityFastTrackModel', AccessibilityFastTrackModel);

    AccessibilityFastTrackModel.$inject = ['PageFixModelHelperService'];
    function AccessibilityFastTrackModel (PageFixModelHelperService) {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getPageFixSelector = getPageFixSelector;

        return Model;

        // /////////

        function getPageFixSelector () {
            return 'q;' + PageFixModelHelperService.getSelectorValue(this.source_code);
        }
    }
})();
