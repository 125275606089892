(function () {
    'use strict';

    angular.module('modules.demodata').factory('SampleStats', SampleStatsService);

    SampleStatsService.$inject = ['ng2SessionService', 'timeZone'];

    /* @ngInject */
    function SampleStatsService (ng2SessionService, timeZone) {
        return {
            label: label,
            countryCode: countryCode,
        };

        // /////////////

        function label () {
            var country_code = region();
            var country = timeZone.countries[country_code];
            if (!country) {
                country = timeZone.countries.US;
            }
            return country.name;
        }

        function countryCode () {
            return region();
        }

        // PROTECTED

        function region () {
            return ng2SessionService.customer && ng2SessionService.customer.country;
        }
    }
})();
