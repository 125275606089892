(function () {
    'use strict';
    angular
        .module('modules.page_fix.services')
        .service('PageFixFormHtmlElementOptionsService', PageFixFormHtmlElementOptionsService);

    PageFixFormHtmlElementOptionsService.$inject = [];
    /* @ngInject*/
    function PageFixFormHtmlElementOptionsService () {
        var vm = this;
        vm.elementOptions = [
            {
                name: '<em>',
                value: 'em',
            },
            {
                name: '<p>',
                value: 'p',
            },
            {
                name: '<strong>',
                value: 'strong',
            },
            {
                name: '<mark>',
                value: 'mark',
            },
        ];

        return {
            getOptions: getOptions,
        };

        // ///////////////

        function getOptions () {
            return vm.elementOptions;
        }
    }
})();
