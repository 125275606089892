(function () {
    'use strict';

    angular.module('models').factory('DomainGroupModel', DomainGroupModel);

    function DomainGroupModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
            this.seo_count = this.seo_count || 0;
            this.qa_count = this.qa_count || 0;
            this.policy_with_violations_count = this.policy_with_violations_count || 0;
            this.policy_with_required_count = this.policy_with_required_count || 0;
            this.policy_with_search_hits_count = this.policy_with_search_hits_count || 0;
            this.accessibility_checks = this.accessibility_checks || [];
            this.date = this.date || '';
        }

        return Model;

        // /////////
    }
})();
