(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PDFService
     * @description Service to handle Accessibility PDF request
     */
    angular.module('services.entities').factory('PDFService', PDFService);

    PDFService.$inject = ['PdfRepo', 'ng2DemoHasAccessService', 'ng2PdfRepoService'];
    /* @ngInject*/
    function PDFService (PdfRepo, ng2DemoHasAccessService, ng2PdfRepoService) {
        return {
            get: get,
            update: update,
            createClarity: createClarity,
            deleteClarity: deleteClarity,
            createRemediation: createRemediation,
            createAllClarity: createAllClarity,
            markAllPdfsAsReviewed: markAllPdfsAsReviewed,
            requestRemeditionQuoteForAllPdfs: requestRemeditionQuoteForAllPdfs,
        };

        /**
         * @memberOf PDFService
         * @desc Get PDF collection
         * @param {object} params - The parameters to inject to the get
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the PDF collection
         */
        function get (params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return ng2PdfRepoService.get(params);
            }

            return PdfRepo.get(params, config);
        }

        /**
         * @memberOf PDFService
         * @desc Update PDF object
         * @param {object} pdf - Document object
         * @param {object} params - The parameters to inject
         * @returns {Promise} - Promise containing the updated PDF Document
         */
        function update (pdf, params) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return ng2PdfRepoService.update(pdf, params);
            }

            return PdfRepo.update(pdf, params);
        }

        /**
         * @memberOf PDFService
         * @desc Update PDF object
         * @param {object} pdf - PDF to request
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise}
         */
        function createClarity (pdf, params, config) {
            return PdfRepo.createClarity(pdf);
        }

        /**
         * @memberOf PDFService
         * @desc Update PDF object
         * @param {object} pdf - PDF to request
         * @param {object} data - The data parameters to post
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise}
         */
        function createRemediation (pdf, data, params, config) {
            return PdfRepo.createRemediation(pdf, data, params, config);
        }

        function deleteClarity (pdf, params, headers) {
            return PdfRepo.deleteClarity(pdf, params, headers);
        }

        /**
         * @memberOf PDFService
         * @desc Request All Clarity Checks
         * @param {object} model
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise}
         */
        function createAllClarity (model, params, config) {
            return PdfRepo.createAllClarity(model, params, config);
        }

        /**
         * @memberOf PDFService
         * @desc Mark All PDFs as reviewed
         * @returns {Promise}
         */
        function markAllPdfsAsReviewed () {
            return PdfRepo.markAllPdfsAsReviewed();
        }

        /**
         * @memberOf PDFService
         * @desc Request Remediation quote for all PDFs
         * @returns {Promise}
         */
        function requestRemeditionQuoteForAllPdfs () {
            return PdfRepo.requestRemeditionQuoteForAllPdfs();
        }
    }
})();
