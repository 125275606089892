(function () {
    'use strict';

    angular.module('pages.qa.readability.summary').component('readabilityPagesWithScore', {
        templateUrl: 'app/pages/qa/readability/summary/pages-with-score/pages-with-score.html',
        controller: PagesWithScoreController,
        controllerAs: 'vm',
        bindings: {
            score: '<',
            filterType: '<',
        },
    });

    PagesWithScoreController.$inject = [
        '$scope',
        'ReadabilityRepo',
        '$controller',
        'ng2SessionService',
        '$filter',
        'QAReadabilityService',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2LabelService',
        'ng2ActiveFeatureService',
    ];

    function PagesWithScoreController (
        $scope,
        ReadabilityRepo,
        $controller,
        ng2SessionService,
        $filter,
        QAReadabilityService,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2LabelService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.createOrUpdateLabel = createOrUpdateLabel;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.domain = ng2SessionService.domain;
            vm.pages = [];
            vm.total = 0;
            vm.totalPages = ng2SessionService.domain.crawl_history.page_count + ng2SessionService.domain.crawl_history.documents_count;
            vm.totalPercentage = 0;
            vm.remaningPagesPercentage = 0;
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.progress = null;
            vm.search = null;
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.exports = [
                {
                    data: {
                        category: 'readability_by_score',
                        category_ref: getReaderLevelName(vm.score),
                    },
                },
            ];

            $scope.$watch('vm.score', function (oldValue, newValue) {
                if (oldValue === null || oldValue === undefined || oldValue != newValue) {
                    getPage();
                }
            });
            getPage();
            vm.getLabels();
        }

        function getPage () {
            var params = {
                readability_level: vm.score,
                page: vm.page,
                search: vm.search || null,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                type: vm.filterType,
            };

            vm.progress = ReadabilityRepo.pages(params).then(function (data) {
                vm.pages = data;
                vm.total = data.total;
                vm.totalPercentage = $filter('monNumber')((vm.total / vm.totalPages) * 100, '0.[00]');
                vm.remaningPagesPercentage = $filter('monNumber')(((vm.totalPages - vm.total) / vm.totalPages) * 100, '0.[00]');
            }, angular.noop);
        }

        function getReaderLevelName (score) {
            return QAReadabilityService.getReaderLevelName(score);
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
