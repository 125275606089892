(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name meService
     * @description Service to handle me requests
     */
    angular.module('services.entities').factory('meService', meService);

    meService.$inject = [
        'MeRepo',
        'ng2SessionService',
        'ng2ToastService',
        'gettextCatalog',
        '$numeraljsConfig',
        'UserLocaleService',
        'coreLoginService',
        'MeReportingsRepo',
    ];

    /* @ngInject*/
    function meService (
        MeRepo,
        ng2SessionService,
        toastService,
        gettextCatalog,
        $numeraljsConfig,
        UserLocaleService,
        coreLoginService,
        MeReportingsRepo,
    ) {
        return {
            update: update,
            createReport: createReport,
            updateReport: updateReport,
            deleteReport: deleteReport,
            updateDomain: updateDomain,
            createReporting: createReporting,
            updateReporting: updateReporting,
        };

        /**
         * @memberOf meService
         * @desc Update Me
         * @param {object} profile The profile to update
         * @returns {Promise} Promise containing the me object that is updated
         */
        function update (profile) {
            return MeRepo.update(profile).then(function (me) {
                $numeraljsConfig.locale(UserLocaleService.getNumeralValue(me.locale));
                coreLoginService.setMe(me);
                toastService.success(gettextCatalog.getString('Profile updated'));
                // Set the updated settings to current agreement
                if (ng2SessionService.agreement) {
                    ng2SessionService.agreement.settings = angular.copy(me.settings);
                }
                return me;
            });
        }

        function createReport (params, headers) {
            return MeRepo.createReport(params, headers);
        }

        function updateReport (reportId, data, headers) {
            return MeRepo.updateReport(reportId, data, headers);
        }

        function deleteReport (reportId, params, headers) {
            return MeRepo.deleteReport(reportId, params, headers);
        }

        function updateDomain (domainId, data, headers) {
            return MeRepo.updateDomain(domainId, data, headers);
        }

        function createReporting (params, headers) {
            return MeReportingsRepo.create(params, headers);
        }

        function updateReporting (id, data, headers) {
            return MeReportingsRepo.update(id, data, headers);
        }
    }
})();
