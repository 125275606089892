import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { Customer } from '@monsido/modules/models/api/customer';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { AccountRepo } from '@monsido/modules/endpoints/api/account.repo';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CoreAgreementService {
    public hasMultipleAgreements$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor (private ng2SessionService: SessionService, private monEventService: MonEventService, private accountRepo: AccountRepo) {}

    initAgreements (): void {
        if (this.ng2SessionService.agreements === null) {
            this.ng2SessionService.resetAgreements();
        }
    }

    setAgreements (agreements: Agreement[]): void {
        this.initAgreements();
        this.hasMultipleAgreements$.next(agreements.length > 1);
        this.ng2SessionService.agreements?.next(Object.assign([], agreements));
    }

    clearAgreement (): Promise<void | void[]> {
        this.setAgreements([]);
        this.ng2SessionService.customer = null;
        this.ng2SessionService.agreement = null;
        return this.monEventService.run('clearAgreement');
    }

    setAgreement (agreement: Agreement): Promise<Customer> {
        this.ng2SessionService.agreement = agreement;
        return this.monEventService.run('setAgreement', agreement, this.ng2SessionService.me, this.ng2SessionService.isSudo).then(() => {
            return this.getAccount();
        });
    }

    getAccount (): Promise<Customer> {
        return this.accountRepo.get().then((customer: Customer) => {
            this.ng2SessionService.customer = customer;
            return this.monEventService
                .run('setAccount', customer, this.ng2SessionService.agreement, this.ng2SessionService.me, this.ng2SessionService.isSudo)
                .then(() => {
                    return customer;
                });
        });
    }
}
