(function () {
    'use strict';

    angular.module('services.api').factory('LabelRepo', LabelRepo);

    LabelRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function LabelRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getLabels: getLabels,
            createLabel: createLabel,
            deleteLabel: deleteLabel,
            updateLabel: updateLabel,
            getDomainGroupsUsingLabel: getDomainGroupsUsingLabel,
        };

        return service;

        // /////////////

        function getLabels (params, headers) {
            params = params || {};
            params.page_size = params.page_size || 0;
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('labels', params, headers);
        }

        function createLabel (params, headers) {
            return ng2ApiService.post('labels', params, headers);
        }

        function deleteLabel (params, headers) {
            return ng2ApiService.delete('labels/' + params.label_id, params, headers);
        }

        function updateLabel (data, headers) {
            return ng2ApiService.patch('labels/' + data.label_id, data, null, headers);
        }

        function getDomainGroupsUsingLabel (labelId, params, headers) {
            return ng2ApiService.get('labels/' + labelId + '/domains_with_blocking_groups', params, headers);
        }
    }
})();
