(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableReadability', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/readability/readability.html',
        controller: ReadabilityController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
        },
    });

    ReadabilityController.$inject = ['$filter', 'ChangesHistoryReadabilityService'];

    /* @ngInject */
    function ReadabilityController ($filter, ChangesHistoryReadabilityService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = update;

        vm.historyOutput = [];
        vm.readabilityLevels = [];

        function update (changes) {
            getReadabilityLevels();

            if (changes.history) {
                vm.historyOutput = prepareHistoryOutput(vm.history);
            }
        }

        function activate () {
            getReadabilityLevels();
        }

        function prepareHistoryOutput (history) {
            var result = [];
            var len = history.length;
            var story;
            var entry;

            // for is faster than map
            for (var i = 0; i < len; i += 1) {
                story = history[i];
                entry = {
                    id: story.id,
                    post_processing_done_at: story.post_processing_done_at,
                    date: story.date,
                    page_count: story.page_count,
                    documents_count: story.documents_count,
                    levels: {},
                };

                vm.readabilityLevels.forEach(function (level) {
                    entry.levels[level.attr] = $filter('monNumber')(getReadabilityLevelDistributeValue(story, level.attr));
                });

                result.push(entry);
            }

            return result;
        }

        function getReadabilityLevels () {
            vm.readabilityLevels = ChangesHistoryReadabilityService.getReadabilityLevels(vm.domain.features.readability_test);
        }

        function getReadabilityLevelDistributeValue (story, attr) {
            if (story.readability_distribution) {
                return story.readability_distribution[attr] || 0;
            }
            return 0;
        }
    }
})();
