(function () {
    'use strict';

    angular.module('modules.support.models').factory('SupportAccessibilityCheckModel', SupportAccessibilityCheckModel);

    SupportAccessibilityCheckModel.$inject = [];

    function SupportAccessibilityCheckModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        return Model;

        // /////////
    }
})();
