<form class="form-horizontal" (ngSubmit)="sendReport()">
    <div class="mon-dialog-header">
            <div class="outer-t-smurf">
                <mon-page-header header="{{'Report Administration' | translate}}"
                                 panelLeftClass="col-48 outer-t-mini">
                </mon-page-header>
            </div>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="row outer-t outer-r ml-1">
            <div class="col-lg-32">
                <div class="panel-monsido-card panel mb-2">
                    <div class="mr-auto card-header card-title pr-0 pl-2 pb-4" translate>Select a domain and recipients</div>
                    <mon-spinner *ngIf="promise" [progress]="promise">
                        <div *ngFor="let domain of domains">
                            <div class="domain">
                                <mon-url-title [title]="domain.title"
                                 [url]="domain.url"
                                 [active]="false"
                                 class="mr-auto ml-2 text-ellipsis">
                                </mon-url-title>

                                <ng-container *ngIf="domain.users">
                                    <div class="users">
                                        <ng-container *ngFor="let user of domain.users; let i = index">
                                            <span [ngbTooltip]="user | getUserName"
                                         placement="top"
                                         container="body"
                                         *ngIf="i < maxIcons"
                                         class="inline-block ml-1">
                                                <ngx-avatar
                                                        [name]="user | getUserName"
                                                        size="20"
                                                        [textSizeRatio]="1.9">
                                                </ngx-avatar>
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="domain.users.length > maxIcons">
                                            <span class="text-grey ml-1">
                                                {{ ' + ' + '[[count]] more' | translate:undefined:{ count: domain.users.length - maxIcons } }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div class="ml-4 icon">
                                    <button class="btn-no-style btn-icon"
                                            attr.aria-label="{{'Edit users' | translate}}"
                                            (click)="openUsersDialog($event, domain, domainType.Domain)">
                                        <span class="cursor-pointer text-link fas fa-cog fa-lg"></span>
                                    </button>
                                </div>
                            </div>
                            <ng-container *ngIf="domain.domain_groups">
                                <div class="domain-group" *ngFor="let domain_group of domain.domain_groups | orderBy: 'title'; trackBy:domainGroupById">
                                    <div class="mx-3">
                                        <span class="visibility-45 fas fa-clone fa-2x"></span>
                                    </div>
                                    <div class="mr-auto text-ellipsis">
                                        <a class="primary-text-grey">{{domain_group.title}}</a>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="domain.domain_groups">
                                            <ng-container *ngFor="let user of domain_group.users; let i = index; trackBy:userByNameEmail">
                                                <span [ngbTooltip]="user | getUserName"
                                           placement="top"
                                           container="body"
                                           *ngIf="i < maxIcons"
                                           class="inline-block ml-1">
                                                    <ngx-avatar
                                                            [name]="user | getUserName"
                                                            size="20"
                                                            [textSizeRatio]="1.9">
                                                    </ngx-avatar>
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="domain_group.users && domain_group.users.length > maxIcons">
                                                <span class="ml-1 text-grey">
                                                    {{ ' + ' + '[[count]] more' | translate:undefined:{ count: domain_group.users.length - maxIcons } }}
                                                </span>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="ml-4">
                                        <button class="btn-no-style btn-icon"
                                                (click)="openUsersDialog($event, domain_group, domainType.DomainGroup)"
                                                attr.aria-label="{{'Edit users' | translate}}">
                                            <span class="cursor-pointer text-link fas fa-cog fa-lg"></span>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </mon-spinner>
                </div>
            </div>
            <div *ngIf="report" class="col-lg-16 pr-6">
                <h3 class="mt-0">{{report.template.title}}</h3>
                <div class="label ruler-info font-size-1-1 outer-b-medium inline-block">{{report.template.name | translateReport }}</div>
                <div class="mb-9">
                    <h4 translate>Report overview:</h4>
                </div>
                <div class="mb-2">
                    <div class="mb-1">
                        <strong translate>Domains/groups selected</strong>
                    </div>
                    <div>
                        <ng-container *ngIf="report.targets">
                            <span class="text-grey" *ngFor="let target of report.targets; let i = index; trackBy:targetByTitle" >
                                {{target.title}}
                                <span *ngIf="i < report.targets.length - 1">&middot;</span>
                            </span>
                        </ng-container>
                        <p class="text-grey report-center-suggestion py-2" *ngIf="!report.targets || report.targets.length == 0" translate>
                        No Domains or Groups are yet selected for this report
                        </p>
                    </div>
                </div>
                <div>
                    <div class="mb-1">
                        <strong translate>Monsido users receiving this report</strong>
                    </div>
                    <div>
                        <ng-container *ngFor="let user of users">
                            <span
                                class="inline-block outer-r-mini user"
                                [ngbTooltip]="user | getUserName"
                                placement="top"
                                container="body"
                                *ngIf="users && users.length > 0">
                                <ngx-avatar
                                        [name]="user | getUserName"
                                        size="25"
                                        [textSizeRatio]="1.9">
                                </ngx-avatar>
                            </span>
                        </ng-container>
                        <p class="text-grey report-center-suggestion py-2"
                           *ngIf="!users || users.length == 0" translate
                           >No Users are yet selected to receive this report
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button data-test="save-button" class="btn btn-border-radius btn-secondary" [disabled]="saving" type="submit">
            <span *ngIf="!saving" translate>Save</span>
            <span aria-hidden="true" *ngIf="saving">
                <mon-icon [spin]="true" [icon]="['SPINNER', 'ICON']"></mon-icon>
            </span>
        </button>
    </div>
</form>
