(function () {
    'use strict';

    angular.module('modules.source-code-excludes').component('formSourceCodeExcludeStepsForm', {
        templateUrl: 'app/modules/source-code-excludes/form/source-code-exclude/steps/form/form.html',
        controller: FormSourceCodeExcludeController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
        },
    });

    FormSourceCodeExcludeController.$inject = ['GlobalAccessibilityChecksRepo', 'SourceCodeExcludesService', 'Lodash'];

    /* @ngInject */
    function FormSourceCodeExcludeController (GlobalAccessibilityChecksRepo, SourceCodeExcludesService, Lodash) {
        var vm = this;
        vm.$onInit = activate;
        vm.isAccessibility = SourceCodeExcludesService.isAccessibility;

        function activate () {
            vm.accessibilityChecksLoading = null;
            setupDefaultAccessibilityChecksOptions();
        }

        function setupDefaultAccessibilityChecksOptions () {
            vm.accessibilityChecksLoading = GlobalAccessibilityChecksRepo.getAll({ page_size: 0 }).then(
                function (checks) {
                    return Lodash.sortBy(checks, ['name']);
                },
                function () {
                    return [];
                },
            );
        }
    }
})();
