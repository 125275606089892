(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceChartsService
     * @description Service help page performance charts rendering
     */
    angular.module('modules.page-performance').service('PagePerformanceChartsService', PagePerformanceChartsService);

    PagePerformanceChartsService.$inject = ['PagePerformanceAuditScoreService', 'MONSIDO_COLOR'];

    /* @ngInject*/
    function PagePerformanceChartsService (PagePerformanceAuditScoreService, MONSIDO_COLOR) {
        var vm = this;
        vm.schema = 'dark';
        vm.colors = {
            light: ['#fde3e3', '#fce0c3', '#65cccf'],
            dark: [MONSIDO_COLOR['secondary-7'], MONSIDO_COLOR['secondary-15'], MONSIDO_COLOR['secondary-16']],
        };

        return {
            getFailedColor: getFailedColor,
            getAverageColor: getAverageColor,
            getPassedColor: getPassedColor,
            setColorSchema: setColorSchema,
            getColorByScore: getColorByScore,
        };

        /**
         * @memberOf PagePerformanceChartsService
         * @desc Get color value that fits the score of the audit
         * @param {Number} score - Performance score
         * @return {String}
         */
        function getColorByScore (score, schema) {
            var val = PagePerformanceAuditScoreService.getProperScoreNo(score);
            if (PagePerformanceAuditScoreService.hasScoreFailed(val)) {
                return getFailedColor(schema);
            } else if (PagePerformanceAuditScoreService.hasScoreAverage(val)) {
                return getAverageColor(schema);
            } else if (PagePerformanceAuditScoreService.hasScorePassed(val)) {
                return getPassedColor(schema);
            }
            return '';
        }

        /**
         * @memberOf PagePerformanceChartsService
         * @desc Sets the Color Schema for the Performance charts and Indicators
         * @param schema - schema to choose
         */
        function setColorSchema (schema) {
            vm.colorSchema = schema || 'dark';
        }

        /**
         * @memberOf PagePerformanceChartsService
         * @desc Get Failed Audit Color
         * @return {String}
         */
        function getFailedColor (schema) {
            return vm.colors[schema || vm.schema][0];
        }

        /**
         * @memberOf PagePerformanceChartsService
         * @desc Get Average Audit Color
         * @return {String}
         */
        function getAverageColor (schema) {
            return vm.colors[schema || vm.schema][1];
        }

        /**
         * @memberOf PagePerformanceChartsService
         * @desc Get Passed Audit Color
         * @return {String}
         */
        function getPassedColor (schema) {
            return vm.colors[schema || vm.schema][2];
        }
    }
})();
