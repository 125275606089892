(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceDialogsAuditDetailsService
     * @description Service to administer data logic for the pagePerformanceDialogsAuditDetails component
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceDialogsAuditDetailsService', PagePerformanceDialogsAuditDetailsService);

    PagePerformanceDialogsAuditDetailsService.$inject = [];
    /* @ngInject*/
    function PagePerformanceDialogsAuditDetailsService () {
        return {};
    }
})();
