(function () {
    'use strict';

    angular.module('modules.support.services.api', [
        'services.client',
        'angular-cache',
        'modules.support.models',
        'plugins',
    ]);
})();
