(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditDisplayInformativeService
     * @description Service to handle data of the Page Performance display "informative"
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceAuditDisplayInformativeService', PagePerformanceAuditDisplayInformativeService);

    PagePerformanceAuditDisplayInformativeService.$inject = ['PagePerformanceAuditDetailsService'];
    /* @ngInject*/
    function PagePerformanceAuditDisplayInformativeService (PagePerformanceAuditDetailsService) {
        return {
            getFormatAuditItem: getFormatAuditItem,
            isDetailTypeTable: isDetailTypeTable,
            isDetailTypeOpportunity: isDetailTypeOpportunity,
            isTypeCriticalRequestChain: isTypeCriticalRequestChain,
            getTableHeadingClass: getTableHeadingClass,
        };

        function isDetailTypeTable (type) {
            return PagePerformanceAuditDetailsService.isTypeTable(type);
        }

        function isDetailTypeOpportunity (type) {
            return PagePerformanceAuditDetailsService.isTypeOpportunity(type);
        }

        function isTypeCriticalRequestChain (type) {
            return PagePerformanceAuditDetailsService.isTypeCriticalRequestChain(type);
        }

        function getFormatAuditItem (item, heading) {
            return PagePerformanceAuditDetailsService.getFormatAuditItem(item, heading);
        }

        function getTableHeadingClass (heading) {
            return PagePerformanceAuditDetailsService.getTableHeadingClass(heading);
        }
    }
})();
