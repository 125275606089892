(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.chat.intercom').factory('intercomService', intercomService);

    intercomService.$inject = ['$window', 'ng2SessionService'];
    /* @ngInject*/
    function intercomService ($window, ng2SessionService) {
        return {
            addMsg: addMsg,
            boot: boot,
            shutdown: shutdown,
            update: update,
            onShow: onShow,
            onHide: onHide,
            onUnreadCountChange: onUnreadCountChange,
            trackEvent: trackEvent,
        };

        function trackEvent (event, metadata) {
            if (hasIntercom()) {
                $window.Intercom('trackEvent', event, metadata);
            }
        }

        function onShow (cb) {
            if (hasIntercom()) {
                $window.Intercom('onShow', cb);
            }
        }

        function onHide (cb) {
            if (hasIntercom()) {
                $window.Intercom('onHide', cb);
            }
        }

        function onUnreadCountChange (cb) {
            if (hasIntercom()) {
                $window.Intercom('onUnreadCountChange', cb);
            }
        }

        function update (obj) {
            if (hasIntercom()) {
                $window.Intercom('update', obj);
            }
        }

        function shutdown () {
            if (hasIntercom()) {
                $window.Intercom('shutdown');
            }
        }

        function boot (obj) {
            if (hasIntercom()) {
                $window.Intercom('boot', obj);
            }
        }

        function addMsg (msg) {
            if (hasIntercom()) {
                $window.Intercom('showNewMessage', msg);
            }
        }

        function hasIntercom () {
            return typeof $window.Intercom === 'function' && ng2SessionService.getChatSystem() === 'intercom';
        }
    }
})();
