(function () {
    'use strict';
    angular.module('app.components').component('monCommentsComment', {
        templateUrl: 'app/components/comments/comment/comment.html',
        controller: MonCommentsController,
        controllerAs: 'vm',
        bindings: {
            comment: '<',
            getPage: '&',
        },
    });

    MonCommentsController.$inject = ['$controller', 'commentService', 'ng2SessionService', '$deepmerge'];
    /* @ngInject */
    function MonCommentsController ($controller, commentService, ng2SessionService, $deepmerge) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.setEditMode = setEditMode;
        vm.commentMadeByMe = commentMadeByMe;
        vm.onRemoveComment = onRemoveComment;

        function activate () {
            vm.saving = false;
            vm.editMode = false;
            vm.disableDelete = false;
        }

        function setEditMode () {
            vm.editMode = !vm.editMode;
            if (vm.editMode) {
                vm.currentComment = angular.copy(vm.comment);
            }
        }

        function commentMadeByMe () {
            if (angular.isObject(ng2SessionService.me)) {
                return ng2SessionService.me.id === vm.comment.user_id;
            }

            return false;
        }

        function onRemoveComment () {
            if (vm.disableDelete && vm.comment) {
                return;
            }
            vm.disableDelete = true;
            commentService.destroy(vm.comment.id).then(function () {
                vm.getPage();
                vm.disableDelete = false;
            }, angular.noop);
        }

        function submit () {
            vm.saving = true;
            commentService.update(vm.currentComment).then(function (comment) {
                vm.comment = $deepmerge(vm.comment, comment);
                vm.saving = false;
                vm.editMode = false;
            }, angular.noop);
        }
    }
})();
