(function () {
    'use strict';

    angular.module('services.api').factory('LinksRepo', LinksRepo);

    LinksRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'LinkModel'];
    /* @ngInject */
    function LinksRepo (ng2ApiService, ng2SessionService, LinkModel) {
        var service = {
            getAll: getAll,
            get: get,
            update: update,
            getOriginalUrls: getOriginalUrls,
            getAllPageLinks: getAllPageLinks,
        };

        return service;

        // /////////////

        function getAll (params, headers) {
            params = params || {};
            var domainId = params.domain_id ? params.domain_id : ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + domainId + '/links', params, headers).then(toModels);
        }

        function getAllPageLinks (params, headers) {
            params = params || {};
            var domainId = params.domain_id ? params.domain_id : ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + domainId + '/page-links', params, headers).then(toModels);
        }

        function get (id) {
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/links/' + id).then(toModel);
        }

        function update (link, params) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/links/' + link.id, link, params).then(toModel);
        }

        function getOriginalUrls (link, params, headers) {
            params = params || {};
            var domainId = params.domain_id ? params.domain_id : ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + domainId + '/links/' + link.id + '/original-urls', params, headers);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new LinkModel(model);
        }
    }
})();
