<div class="policy-target" [ngbTooltip]="tooltip" placement="top">
    <mon-icon-circle [color]="iconColors[color]"
                 [icon]="options[target]"
                 [monAriaText]="'[[target]]' | translate:'':{target}"
                 *ngIf="!passed && !isMobileCheck && options[target]">
    </mon-icon-circle>
    <mon-icon-circle [color]="iconColors.passed"
                 [monIconDirect]="'faCheck'"
                 [monAriaText]="'Passed' | translate"
                 *ngIf="passed">
    </mon-icon-circle>


    <div class="icon-circle" [class]="{ 'text-wcag-a' : color === 'wcag-a' }" *ngIf="isMobileCheck && !passed">
        <mon-icon class="icon"
                  [icon]="['ACCESSIBILITY_CHECKS', 'MOBILE_WARNING']"
                  *ngIf="target === 'warning'">
        </mon-icon>
        <mon-icon class="icon"
                  [icon]="['ACCESSIBILITY_CHECKS', 'MOBILE_ERROR']"
                  *ngIf="target === 'error'">
        </mon-icon>
        <mon-icon class="icon"
                  [icon]="['ACCESSIBILITY_CHECKS', 'MOBILE_REVIEW']"
                  *ngIf="target === 'review'">
        </mon-icon>
    </div>
</div>
