(function () {
    'use strict';

    angular.module('services.api').factory('SeoRepo', SeoRepo);

    SeoRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function SeoRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            update: update,
            getSeoIssuePages: getSeoIssuePages,
            getAllPages: getAllPages,
            getSummary: getSummary,
            getIssueContent: getIssueContent,
        };

        return service;

        // /////////////

        function getAll (params, headers) {
            params = params || {};
            var domainId = params.domain_id ? params.domain_id : ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + domainId + '/seo/issues/', params, headers);
        }

        function update (issue_id, data, params, headers) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/seo/issues/' + issue_id, data, params, headers);
        }

        function getAllPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/seo/pages', params, config);
        }

        function getSummary (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/seo/summary', params, config);
        }

        function getSeoIssuePages (issue_id, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/seo/issues/' + issue_id + '/pages', params, headers);
        }

        function getIssueContent (issue_id, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/seo/issues/' + issue_id + '/content', params, headers);
        }
    }
})();
