<form #usersForm="ngForm" class="form-horizontal" name="form" (ngSubmit)="close()">
    <div class="mon-dialog-header">
        <div class="row">
            <div class="col-48 outer-t-smurf">
                <mon-page-header header="{{'Select users' | translate}}"
                             panelClass="row"
                             panelLeftClass="col-48 outer-t-mini">
                </mon-page-header>
            </div>
        </div>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card panel-monsido-card outer-t outer-r outer-l">
            <div class="card-body px-0">
                <mon-spinner *ngIf="progress" [progress]="progress" [loading]="loading">
                    <table class="table table-fixed table-hover">
                        <tbody>
                        <tr *ngIf="currentUser?.customer_admin">
                            <td  colspan="2" class="cursor-pointer" (click)="openNonUserDialog()">
                                <span class="fas fa-plus fa-kg text-link add-icon"></span>
                                <span translate>Add a non-user email</span>
                            </td>
                        </tr>
                        <tr *ngFor="let user of selectedTempUsers; let i = index">
                            <td class="text-v-middle">
                                <div class="d-flex text-ellipsis">
                                    <div class="ml-8 text-ellipsis">
                                        <a>{{ user.first_name }} {{ user.last_name}}</a>
                                        <br/>
                                        <div class="d-flex text-ellipsis">
                                            <div class="pr-1">
                                                <a class="fas fa-envelope"></a>
                                            </div>
                                            <div class="text-grey text-ellipsis">
                                                {{ user.email }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-right text-v-middle">
                                <span
                                    class="fas fa-times label-danger pull-right delete-non-user"
                                    monConfirm="{{'Are you sure you want to remove manually added user?' | translate}}"
                                    [monPromptOptions]="promptOptions"
                                    (monConfirmAction)="removeTempUser(i)"
                                >
                                </span>
                            </td>
                        </tr>
                        <tr *ngFor="let user of userList">
                            <td class="text-v-middle">
                                <div class="d-flex text-ellipsis">
                                    <div>
                                        <ngx-avatar
                                                [initialsSize]="2"
                                                [name]="user | getUserName"
                                                [round]="true"
                                                [textSizeRatio]="2"
                                                size="30"
                                        ></ngx-avatar>
                                    </div>
                                    <div class="ml-2 text-ellipsis">
                                        <a>{{ user | getUserName }}</a>
                                        <br/>
                                        <div class="d-flex text-ellipsis">
                                            <div class="pr-1">
                                                <a class="fas fa-envelope"></a>
                                            </div>
                                            <div class="text-grey text-ellipsis">
                                                {{ user.email }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-right text-v-middle">
                                <span class="outer-r-medium d-flex align-items-center justify-content-end">
                                    <input type="checkbox"
                                           [(ngModel)]="selectedUsers[user.id]"
                                           name="user_name_{{ user.id }}" />
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </mon-spinner>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer">
        <div class="row">
            <div class="col-md-48 text-right">
                <button data-test="save-button" [disabled]="loading" class="btn btn-border-radius btn-secondary" type="submit" translate>
                    Save
                </button>
            </div>
        </div>
    </div>
</form>
