(function () {
    'use strict';

    angular.module('services.api').factory('AccessibilityRepo', AccessibilityRepo);

    AccessibilityRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function AccessibilityRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getSummary: getSummary,
            getAllPages: getAllPages,
            getPagesWithIgnoredChecks: getPagesWithIgnoredChecks,
        };

        return service;

        // /////////////

        function getSummary (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/summary', params, config);
        }

        function getAllPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pages', params, config);
        }

        function getPagesWithIgnoredChecks (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/ignored-checks/pages', params, config);
        }
    }
})();
