(function () {
    'use strict';

    angular.module('modules.notifications').filter('notificationNameFilter', notificationNameFilter);

    notificationNameFilter.$inject = ['ng2SessionService', 'NOTIFICATION_CONSTANT', 'gettextCatalog'];
    /* @ngInject */
    function notificationNameFilter (sessionService, NOTIFICATION_CONSTANT, gettextCatalog) {
        return actionFilter;

        // //////////

        function actionFilter (args) {
            const target = args[0];
            const targetId = args[1];
            const payload = args[2];
            const action = args[3];

            switch (target) {
                case NOTIFICATION_CONSTANT.TARGET.SEO_PAGE_ISSUE:
                    return 'SEO issue status';
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_SOURCE_CODE:
                    return 'accessibility for ' + payload.check_name;
                case NOTIFICATION_CONSTANT.TARGET.SPELLING_CONFIRMED:
                    if (payload && payload.word) {
                        return payload.word + ' as misspelling';
                    }
                    return ' word as misspelling';
                case NOTIFICATION_CONSTANT.TARGET.SPELLING_DICTIONARY:
                    if (payload && payload.word) {
                        return '"' + payload.word + '"' + ' to dictionary';
                    }
                    return ' word to dictionary';
                case NOTIFICATION_CONSTANT.TARGET.LINK:
                    return payload.link_url;
                case NOTIFICATION_CONSTANT.TARGET.SPELLING_IGNORED:
                    return 'spelling "' + payload.word + '"';
                case NOTIFICATION_CONSTANT.TARGET.EXPORT:
                    return '';
                case NOTIFICATION_CONSTANT.TARGET.DOMAIN:
                    const domains = sessionService && sessionService.domains && sessionService.domains.value;
                    const domain = (domains || []).filter(function (domain) {
                        return domain.id === targetId;
                    })[0] || {};
                    const domainTitle = domain.title || '';

                    if (action === NOTIFICATION_CONSTANT.ACTION.COOKIES_FOUND) {
                        return gettextCatalog.getPlural(
                            payload.cookies_count,
                            '1 new cookie on ',
                            '{{$count}} new cookies on ',
                            {},
                        ) + domainTitle;
                    } else {
                        if (domainTitle) {
                            return domainTitle;
                        }
                    }

                    break;
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_ERRORS_PAGE:
                    return 'an accessibility error on page';
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_CHECK:
                    return 'an accessibility check';
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_ERROR:
                    return 'an accessibility error';
            }
            return target;
        }
    }
})();
