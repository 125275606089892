(function () {
    'use strict';

    angular.module('services.api').factory('DomainGroupsRepo', DomainGroupsRepo);

    DomainGroupsRepo.$inject = [
        'ng2ApiService',
        'ng2SessionService',
        'ng2MonEventsService',
        'DomainGroupModel',
        'CrawlModel',
        'ApiRequestCancellerService',
    ];
    /* @ngInject */
    function DomainGroupsRepo (ng2ApiService, ng2SessionService, ng2MonEventsService, DomainGroupModel, CrawlModel, ApiRequestCancellerService) {
        var service = {
            get: get,
            getAll: getAll,
            create: create,
            update: update,
            getHistory: getHistory,
            destroy: destroy,
        };

        return service;

        // /////////////

        function getAll (domain_id, params, headers) {
            params = params || {};
            domain_id = domain_id || ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + domain_id + '/domain_groups', params, headers).then(toModels);
        }

        function get (domain_id, id, params, headers) {
            params = params || {};
            domain_id = domain_id || ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + domain_id + '/domain_groups/' + id, params, headers).then(toModel);
        }

        function create (domain, params, headers) {
            var domain_id = domain.id || ng2SessionService.getDomainId(params);
            return ng2ApiService.post('domains/' + domain_id + '/domain_groups', params, headers)
                .then(function (group) {
                    ng2MonEventsService.run('domainGroupAdded', { domain: domain, group: group });
                    return group;
                }, angular.noop)
                .then(toModel);
        }

        function update (domain, group, headers) {
            var domain_id = domain.id || ng2SessionService.getDomainId();
            return ng2ApiService.patch('domains/' + domain_id + '/domain_groups/' + group.id, group, null, headers)
                .then(function (group) {
                    ng2MonEventsService.run('domainGroupUpdated', { domain: domain, group: group });
                    return group;
                }, angular.noop)
                .then(toModel);
        }

        function destroy (domain_id, id) {
            domain_id = domain_id || ng2SessionService.getDomainId();
            return ng2ApiService.delete('domains/' + domain_id + '/domain_groups/' + id);
        }

        function getHistory (params) {
            params = params || {};
            var domainId = ng2SessionService.getDomainId(params);
            var domainGroupId = ng2SessionService.getGroupId(params);
            var settings = {
                route: 'domains/' + domainId + '/domain_groups/' + domainGroupId + '/history',
                params: params,
                verb: 'get',
            };

            return ApiRequestCancellerService.add(ng2ApiService, settings, toCrawlModels);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            if (model) {
                return new DomainGroupModel(model);
            }
            return {};
        }

        function toCrawlModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toCrawlModel(models[i]);
            }
            return models;
        }

        function toCrawlModel (model) {
            return new CrawlModel(model);
        }
    }
})();
