(function () {
    'use strict';

    angular.module('services.api').factory('SearchRepo', SearchRepo);

    SearchRepo.$inject = ['ng2ApiService', 'ng2SessionService'];

    /* @ngInject */
    function SearchRepo (ng2ApiService, ng2SessionService) {
        var service = {
            update: update,
            create: create,
            getAll: getAll,
            destroy: destroy,
            get: get,
            getAllPages: getAllPages,
            getAllSearchPages: getAllSearchPages,
            getSummary: getSummary,
            run: run,
        };

        return service;

        // /////////////

        function getAllSearchPages (search_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/searches/' + search_id + '/pages', params, config);
        }

        function getAllPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/searches/pages', params, config);
        }

        function destroy (id, config) {
            config = config || {};
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/searches/' + id, {}, config);
        }

        function create (params, config) {
            config = config || {};
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/searches', params, config);
        }

        function get (id, config) {
            config = config || {};
            var params = {};
            params.group_id = ng2SessionService.getDomainId();
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/searches/' + id, params, config);
        }

        function update (params, config) {
            config = config || {};
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/searches/' + params.id, params, null, config);
        }

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/searches', params, config);
        }

        function getSummary (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getDomainId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/searches/summary', params, config);
        }

        function run (id, params, config) {
            config = config || {};
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/searches/' + id + '/run', params, config);
        }
    }
})();
