(function () {
    'use strict';

    angular
        .module('modules.data_privacy.services.api.demo-data')
        .factory('DataPrivacyDemoViolationIgnoredRepo', DataPrivacyDemoViolationIgnoredRepo);

    DataPrivacyDemoViolationIgnoredRepo.$inject = ['$q', '$timeout', 'ng2SessionService', 'DataPrivacyViolationModel'];
    /* @ngInject */
    function DataPrivacyDemoViolationIgnoredRepo ($q, $timeout, ng2SessionService, DataPrivacyViolationModel) {
        var vm = this;
        function init () {
            generateData();
            return {
                getAll: getAll,
                remove: remove,
            };
        }

        return init();

        // /////////////

        function getAll () {
            return $q.resolve(toModels(vm.violations));
        }

        function remove (page_id, ignored_content) {
            return $q.resolve(function () {
                vm.violations = vm.violations.filter(function (item) {
                    return item.id !== ignored_content.id;
                });
            });
        }
        function toModel (model) {
            return new DataPrivacyViolationModel(model);
        }
        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }
        function generateData () {
            vm.violations = [
                {
                    id: 4,
                    content: 'male',
                    identifier: 'GENDER',
                    likelihood: 'VERY_LIKELY',
                    info_type: {
                        id: 65,
                        identifier: 'GENDER',
                        name: 'Gender',
                        priority: 100,
                        group: 'general_personal_data',
                        regions: ['global'],
                        regulations: ['gdpr', 'ccpa', 'apa'],
                    },
                },
            ];
        }
    }
})();
