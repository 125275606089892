(function() {
    "use strict";

    angular.module("modules.translations.run").run([
        "translation",
        "devMode",
        "gettextCatalog",
        "coreLoginService",
        function(translation, devMode, gettextCatalog, coreLoginService) {
            if (!coreLoginService.isLoggedIn()) {
                translation.setLanguage("en");
            }

            gettextCatalog.debug = devMode;
        },
    ]);
})();
