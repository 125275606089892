(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name searchService
     * @description Service to handle searches
     */
    angular.module('services.entities').factory('searchService', searchService);

    searchService.$inject = ['SearchRepo'];
    /* @ngInject*/
    function searchService (SearchRepo) {
        return {
            update: update,
            create: create,
            destroy: destroy,
            run: run,
        };

        function destroy (id, config) {
            return SearchRepo.destroy(id, config);
        }

        function create (params, config) {
            return SearchRepo.create(params, config);
        }

        function update (params, config) {
            return SearchRepo.update(params, config);
        }

        function run (id, params, config) {
            return SearchRepo.run(id, params, config);
        }
    }
})();
