(function () {
    'use strict';
    angular.module('app.forms').service('FormDomainScriptGuidePageFixService', FormDomainScriptGuidePageFixService);

    FormDomainScriptGuidePageFixService.$inject = [
        'ng2ActiveFeatureService',
        'FormDomainScriptGuideService',
        'rx',
        'ng2MonEventsService',
        'DOMAIN_SCRIPT_GUIDE_EVENTS',
    ];

    /* @ngInject*/
    function FormDomainScriptGuidePageFixService (
        ng2ActiveFeatureService,
        FormDomainScriptGuideService,
        rx,
        monEventsService,
        DOMAIN_SCRIPT_GUIDE_EVENTS,
    ) {
        var vm = this;
        vm.showSetting = false;

        var service = {
            settingChangeObs: new rx.BehaviorSubject(),
            getValue: getValue,
            setValue: setValue,
            hasAccessToFeature: hasAccessToFeature,
        };

        return service;

        function getValue () {
            return vm.showSetting;
        }

        function setValue (value, manualEdit) {
            vm.showSetting = hasAccessToFeature() && value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function hasAccessToFeature () {
            return ng2ActiveFeatureService.isFeatureActive('page_fix', FormDomainScriptGuideService.getDomain());
        }

        // PROTECTED

        function updateSettings (manualEdit) {
            monEventsService.run(DOMAIN_SCRIPT_GUIDE_EVENTS.FORM_UNSAVED_UPDATE);
            FormDomainScriptGuideService.setScriptSettingBlock(
                'page_correct',
                {
                    enabled: !!vm.showSetting,
                },
                manualEdit,
            );
        }
    }
})();
