(function () {
    'use strict';

    angular.module('models').factory('Me', Me);

    function Me () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getAvatarInitials = getAvatarInitials;
        Model.prototype.getName = getName;
        Model.prototype.getFirstName = getFirstName;
        Model.prototype.getLastName = getLastName;

        return Model;

        // /////////

        function getName () {
            var me = this;
            return [me.getFirstName(), me.getLastName()].join(' ');
        }

        function getAvatarInitials () {
            var me = this;
            return [
                me
                    .getFirstName()
                    .charAt(0)
                    .toUpperCase(),
                me
                    .getLastName()
                    .charAt(0)
                    .toUpperCase(),
            ].join('');
        }

        function getFirstName () {
            // Apparently first_name does not need to be set.

            var me = this;
            return (!angular.isString(me.first_name) ? '' : me.first_name).trim();
        }

        function getLastName () {
            // Apparently last_name does not need to be set.

            var me = this;
            return (!angular.isString(me.last_name) ? '' : me.last_name).trim();
        }
    }
})();
