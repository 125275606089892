(function () {
    'use strict';

    angular.module('modules.demodata.endpoints').factory('DemoPageClarityRepo', DemoPageClarityRepo);

    DemoPageClarityRepo.$inject = ['$q', '$timeout'];

    /* @ngInject */
    function DemoPageClarityRepo ($q, $timeout) {
        var vm = this;

        function init () {
            generateData();

            return {
                getAllChecks: getAllChecks,
                getCheckErrors: getCheckErrors,
                updateCheckError: updateCheckError,
            };
        }

        return init();

        // /////////////

        function getAllChecks (documentId) {
            return getData('getAllChecks').then(function (checks) {
                return checks.map(function (check) {
                    check.errors_count = vm.checkErrors.reduce(function (num, error) {
                        if (error.page_id === documentId && error.clarity_accessibility_check_id === check.id) {
                            num++;
                        }
                        return num;
                    }, 0);
                    return check;
                });
            });
        }

        function getCheckErrors (documentId, checkId) {
            return getData('getCheckErrors').then(function (errors) {
                return errors.filter(function (error) {
                    return error.page_id === documentId && error.clarity_accessibility_check_id === checkId;
                });
            });
        }

        function updateCheckError (params) {
            return getData('updateCheckError').then(function (errors) {
                return errors.find(function (error) {
                    return error.id === params.error_id;
                });
            });
        }

        // PROTECTED

        function getData (fnName) {
            var defer = $q.defer();
            var timer = Math.round(1000 * Math.random());
            $timeout(function () {
                switch (fnName) {
                    case 'getAllChecks':
                        defer.resolve(angular.copy(vm.checks));
                        break;
                    case 'getCheckErrors':
                        defer.resolve(angular.copy(vm.checkErrors));
                        break;
                    case 'updateCheckError':
                        defer.resolve(angular.copy(vm.checkErrors));
                        break;
                    default:
                        defer.resolve([]);
                }
            }, timer);
            return defer.promise;
        }

        function generateData () {
            vm.checks = [
                {
                    id: 45,
                    name: '4.1.2 Name, Role, Value',
                    errors_count: 0,
                },
                {
                    id: 1,
                    name: 'Alternative Representation for Figures',
                    errors_count: 0,
                },
                {
                    id: 4,
                    name: 'Alternative Representation for Forms',
                    errors_count: 0,
                },
                {
                    id: 2,
                    name: 'Alternative Representation for Formulas',
                    errors_count: 0,
                },
                {
                    id: 3,
                    name: 'Alternative Representation for Links',
                    errors_count: 0,
                },
                {
                    id: 5,
                    name: 'Alternative Representation for Other Annotations',
                    errors_count: 0,
                },
                {
                    id: 29,
                    name: 'Article Threads',
                    errors_count: 0,
                },
                {
                    id: 6,
                    name: 'Captions',
                    errors_count: 0,
                },
                {
                    id: 42,
                    name: 'Change of context',
                    errors_count: 0,
                },
                {
                    id: 16,
                    name: 'Correct Structure - Ruby',
                    errors_count: 0,
                },
                {
                    id: 21,
                    name: 'Correct Structure - Warichu',
                    errors_count: 0,
                },
                {
                    id: 22,
                    name: 'Correct Structure - WT and WP',
                    errors_count: 0,
                },
                {
                    id: 7,
                    name: 'Form Annotations - Valid Tagging',
                    errors_count: 0,
                },
                {
                    id: 31,
                    name: 'Format, layout and color',
                    errors_count: 0,
                },
                {
                    id: 44,
                    name: 'Form fields value validation',
                    errors_count: 0,
                },
                {
                    id: 25,
                    name: 'Header Cells',
                    errors_count: 0,
                },
                {
                    id: 41,
                    name: 'Header/Footer pagination artifacts',
                    errors_count: 0,
                },
                {
                    id: 23,
                    name: 'Heading Levels',
                    errors_count: 0,
                },
                {
                    id: 37,
                    name: 'Headings defined',
                    errors_count: 0,
                },
                {
                    id: 33,
                    name: 'Images of text - OCR',
                    errors_count: 0,
                },
                {
                    id: 40,
                    name: 'Language specified',
                    errors_count: 0,
                },
                {
                    id: 8,
                    name: 'Lbl - Valid Parent',
                    errors_count: 0,
                },
                {
                    id: 9,
                    name: 'LBody - Valid Parent',
                    errors_count: 0,
                },
                {
                    id: 10,
                    name: 'Link Annotations',
                    errors_count: 0,
                },
                {
                    id: 11,
                    name: 'Links',
                    errors_count: 0,
                },
                {
                    id: 13,
                    name: 'List',
                    errors_count: 0,
                },
                {
                    id: 12,
                    name: 'List Item',
                    errors_count: 0,
                },
                {
                    id: 24,
                    name: 'ListNumbering',
                    errors_count: 0,
                },
                {
                    id: 28,
                    name: 'Meaningful Sequence',
                    errors_count: 0,
                },
                {
                    id: 39,
                    name: 'Metadata - Title and Viewer Preferences',
                    errors_count: 0,
                },
                {
                    id: 32,
                    name: 'Minimum Contrast',
                    errors_count: 0,
                },
                {
                    id: 14,
                    name: 'Other Annotations - Valid Tagging',
                    errors_count: 0,
                },
                {
                    id: 38,
                    name: 'Outlines (Bookmarks)',
                    errors_count: 0,
                },
                {
                    id: 43,
                    name: 'Required fields',
                    errors_count: 0,
                },
                {
                    id: 15,
                    name: 'RP, RT and RB - Valid Parent',
                    errors_count: 0,
                },
                {
                    id: 27,
                    name: 'Scope attribute',
                    errors_count: 0,
                },
                {
                    id: 34,
                    name: 'Server-side image maps',
                    errors_count: 0,
                },
                {
                    id: 26,
                    name: 'Summary attribute',
                    errors_count: 0,
                },
                {
                    id: 20,
                    name: 'Table',
                    errors_count: 0,
                },
                {
                    id: 17,
                    name: 'Table Cells',
                    errors_count: 0,
                },
                {
                    id: 19,
                    name: 'Table Rows',
                    errors_count: 0,
                },
                {
                    id: 30,
                    name: 'Tabs Key',
                    errors_count: 0,
                },
                {
                    id: 18,
                    name: 'THead, TBody and TFoot',
                    errors_count: 0,
                },
                {
                    id: 36,
                    name: 'Three Flashes or Below Threshold',
                    errors_count: 0,
                },
                {
                    id: 35,
                    name: 'Timing Adjustable',
                    errors_count: 0,
                },
            ];
            vm.checkErrors = [
                {
                    id: 21,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 0,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e3',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 22,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 0,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e15',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 23,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 0,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e21',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 24,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 0,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e23',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 25,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 1,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e26',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 26,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 1,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e40',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 27,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 1,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e54',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 28,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 1,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e68',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
                {
                    id: 29,
                    result_type: 'error',
                    ignored_reviewed: false,
                    clarity_accessibility_check_id: 1,
                    page_id: 'demo-5',
                    page_number: 1,
                    element_tree_path: 'Tags-\u003e0-\u003e0-\u003e80',
                    reason: 'A tag of type Figure does not define the Alt or Actual Text attributes.',
                    clarity_comments: '',
                    comments: [],
                    created_at: '2018-11-22T15:20:36+01:00',
                    updated_at: '2018-11-22T15:20:36+01:00',
                    latest_check_at: '2018-11-22T15:20:36+01:00',
                },
            ];
        }
    }
})();
