(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSeoInformation', {
        templateUrl: 'app/components/pagedetails/details/sections/seo/seo-information/seo-information.html',
        controller: SectionPoliciesInformationController,
        controllerAs: 'vm',
        bindings: {
            selectedSeoIssue: '<',
            page: '<',
            getPageSeoIssues: '&',
        },
    });

    SectionPoliciesInformationController.$inject = [
        'gettextCatalog',
        'LoadingService',
        '$q',
        '$timeout',
        'pageService',
        'seoService',
        'ng2MonModalService',
        'ng2MonEventsService',
    ];

    function SectionPoliciesInformationController (
        gettextCatalog,
        LoadingService,
        $q,
        $timeout,
        pageService,
        seoService,
        ng2MonModalService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.onTabChange = onTabChange;
        vm.updateAllIssues = updateAllIssues;
        vm.ignoreOnDomain = ignoreOnDomain;
        vm.updateIssue = updatePageIssues;

        function activate () {
            vm.policy = {};
            vm.target = 'seo';
            vm.classificationColors = {
                1: 'danger',
                2: 'warning',
                3: 'link',
                4: 'primary',
                ignored: 'grey',
            };
            vm.dropdownOptions = [];
            setupTabs();
        }

        function onChanges (changes) {
            if (changes.selectedSeoIssue) {
                updateDropdown();
            }
        }

        function setupTabs () {
            vm.tabOptions = [
                {
                    name: gettextCatalog.getString('Information'),
                    value: 'information',
                },
                {
                    name: gettextCatalog.getString('Quick help'),
                    value: 'quick-help',
                },
            ];

            vm.tab = vm.tabOptions[0].value;
        }

        function onTabChange (tab) {
            vm.tab = tab;
        }

        function updateDropdown () {
            if (!vm.selectedSeoIssue) {
                vm.dropdownOptions = [];
                return;
            }

            const dropdown = [
                {
                    label: gettextCatalog.getString('Ignore on this page'),
                    leftIcon: 'faEyeSlash',
                    action: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want to ignore the selected issue on this page?');
                        promptConfirmation(message,()=>{
                            updatePageIssues('ignored');
                        });
                    },
                    eventParams: { action: 'page-details-seo-ignore' },
                    shouldShow: !vm.selectedSeoIssue.ignored_on_page && vm.selectedSeoIssue.issues.length > 0,
                },
                {
                    label: gettextCatalog.getString('Unignore on this page'),
                    leftIcon: 'faEye',
                    action: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want to unignore the selected issue on this page?');
                        promptConfirmation(message,()=>{
                            updatePageIssues('active');
                        });
                    },
                    eventParams: { action: 'page-details-seo-unignore' },
                    shouldShow: vm.selectedSeoIssue.ignored_on_page && vm.selectedSeoIssue.issues.length > 0,
                },
                {
                    label: gettextCatalog.getString('Mark as fixed'),
                    leftIcon: 'faCheck',
                    action: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want mark as fixed');
                        promptConfirmation(message,()=>{
                            updatePageIssues('fixed');
                        });
                    },
                    eventParams: { action: 'page-details-seo-mark-fixed' },
                    shouldShow: !vm.selectedSeoIssue.fixed && !vm.selectedSeoIssue.ignored,
                },
                {
                    divider: true,
                    shouldShow: !vm.selectedSeoIssue.ignored && !vm.selectedSeoIssue.fixed,
                },
                {
                    label: gettextCatalog.getString('Ignore this check for this domain'),
                    leftIcon: 'faEyeSlash',
                    action: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want to ignore this check for this domain?');
                        promptConfirmation(message,ignoreOnDomain);
                    },
                    eventParams: { action: 'page-details-seo-ignore-on-domain' },
                    shouldShow: !vm.selectedSeoIssue.ignored,
                },
                {
                    label: gettextCatalog.getString('Unignore this check for this domain'),
                    leftIcon: 'faEye',
                    action: ()=>{
                        const message = gettextCatalog.getString('Are you sure you want to unignore this check for this domain?');
                        promptConfirmation(message,ignoreOnDomain);
                    },
                    eventParams: { action: 'page-details-seo-ignore-on-domain' },
                    shouldShow: vm.selectedSeoIssue.ignored,
                },
            ];

            vm.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                return {
                    label: item.label,
                    leftIcon: item.leftIcon,
                    disabled: item.disabled,
                    divider: item.divider,
                    action: ()=>{
                        if (item.eventParams) {
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                        }

                        if (item.action) {
                            item.action();
                        }
                    },
                };
            });
        }

        function promptConfirmation (message, callback) {
            ng2MonModalService.confirm(message).then(callback);
        }

        function updatePageIssues (status) {
            // Ignore all issues for this page etc.
            var params = {
                status: status,
            };
            vm.dialog = LoadingService();
            var promises = [];

            vm.selectedSeoIssue.issues.forEach(function (issue) {
                promises.push(pageService.updateSeoIssue(vm.page.id, issue.id, params));
            });

            $q.all(promises)
                .then(function () {
                    vm.getPageSeoIssues({ refreshPage: true });
                }, angular.noop)
                .finally(closeLoadingDialog);
        }

        function updateAllIssues (status) {
            var params = {
                status: status,
            };
            vm.dialog = LoadingService();

            pageService
                .updateSeoIssue(vm.page.id, vm.selectedSeoIssue.issues[0].id, params)
                .then(function () {
                    vm.getPageSeoIssues({ refreshPage: true });
                }, angular.noop)
                .finally(closeLoadingDialog);
        }

        function ignoreOnDomain () {
            var params = {
                ignored: !vm.selectedSeoIssue.ignored,
            };
            vm.dialog = LoadingService();
            seoService
                .update(vm.selectedSeoIssue.id, params)
                .then(function () {
                    vm.getPageSeoIssues({ refreshPage: true });
                }, angular.noop)
                .finally(closeLoadingDialog);
        }

        function closeLoadingDialog () {
            $timeout(function () {
                // The dialog isn't created immediately, if a there are no issues to wait on
                vm.dialog.close();
            }, 200);
        }
    }
})();
