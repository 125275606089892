(function () {
    'use strict';
    angular.module('app.components').component('monCommentsCreate', {
        templateUrl: 'app/components/comments/create/create.html',
        controller: MonCommentsController,
        controllerAs: 'vm',
        bindings: {
            subjectType: '@', // Model name of the element Ask @tlw or @jenssoren for specifics
            subjectId: '<', // Id of the element, such as Accessibility check, policy, SEO issue, etc.
            getPage: '&',
        },
    });

    MonCommentsController.$inject = ['$controller', 'commentService', 'ng2SessionService'];
    /* @ngInject */
    function MonCommentsController ($controller, commentService, ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.getMyInitials = getMyInitials;

        function activate () {
            vm.saving = false;
            vm.comment = {};
        }

        function submit () {
            var params = {
                subject_type: vm.subjectType,
                subject_id: vm.subjectId,
                comment: vm.comment.comment,
            };

            commentService.create(params).then(function (comment) {
                vm.comment = {};
                vm.getPage({ comment: comment });
            }, angular.noop);
        }

        function getMyInitials () {
            if (angular.isObject(ng2SessionService.me)) {
                return ng2SessionService.me.getAvatarInitials();
            }
            return 'NA';
        }
    }
})();
