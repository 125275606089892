import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ColorHelperService } from 'app/modules/tools/services/color-contrast/color-helper.service';
import { cloneDeep, debounce } from 'lodash';
import { timer, Subscription } from 'rxjs';
import { skipUntil } from 'rxjs/operators';
import { ConsentManagerOptionsInterface } from './consent-manager-options.interface';
import { ConsentManagerOptionsService } from './consent-manager-options.service';
import {
    ConsentManagerSettingsOptionsInterface,
    RegulationSettings,
} from './consent-manager-settings-options.interface';
import { ConsentManagerModel } from './consent-manager.model';
import { CONSENT_MANAGER_CONSTANT } from './consent-manager.constant';

@Component({
    selector: 'consent-manager',
    templateUrl: 'consent-manager.html',
    styleUrls: ['consent-manager.scss'],
})
export class ConsentManagerComponent implements OnDestroy {

    @ViewChild('consentManagerForm', { static: true }) consentManagerForm?: NgForm;
    @ViewChild('dialogBody') dialogBody?: ElementRef<HTMLElement>;

    calculateMainDarkRatio: () => void = () => {};

    options?: ConsentManagerOptionsInterface;
    dataModel?: ConsentManagerSettingsOptionsInterface;
    CONSENT_MANAGER_CONSTANT = CONSENT_MANAGER_CONSTANT;

    buttonColorRation: number | null = null;
    mainDarkColorRatio: number | null = null;
    validAccessibilityStatementURL = true;
    availableDefaultLanguages: Array<{ name: string, value: string }> = [];

    highlightTitle: boolean = false;
    privacyRegulations: Array<RegulationSettings> = [
        {
            name: 'CCPA',
            value: CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.CCPA,
            tooltip: 'California Consumer Privacy Act',
        },
        {
            name: 'GDPR',
            value: CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.GDPR,
            tooltip: 'General Data Protection Regulation',
        },
        {
            name: 'Both CCPA & GDPR',
            value: CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.CCPA + ' ' + CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.GDPR,
        },
    ];
    selectedRegulationAsString: string | Array<string> = '';
    showCCPAOptions: boolean = false;
    showGDPROptions: boolean = false;
    isCookieOverviewLinkValid: boolean = true;
    isCookiePolicyUrlValid: boolean = true;
    isOptOutUrlValid: boolean = true;

    private updateGeneralSetting: () => void = () => {};
    private formChangesSubscription?: Subscription;
    private eventServiceSubscribeId: number = -1;

    constructor (
        private consentManagerOptionsService: ConsentManagerOptionsService,
        private colorService: ColorHelperService,
        private eventService: MonEventService,
    ) {
        this.updateGeneralSetting = debounce(() => {
            this.eventService.run('updateConsentManagerSettings', this.dataModel);
        }, 10);

        // eslint-disable-next-line
        // @ts-ignore
        this.eventServiceSubscribeId = this.eventService.addListener('setupConsentManager',
            (options: ConsentManagerSettingsOptionsInterface) => {
                this.init(options);
            });
    }

    ngOnDestroy (): void {
        if (this.formChangesSubscription) {
            this.formChangesSubscription.unsubscribe();
        }
        if (this.eventServiceSubscribeId) {
            this.eventService.remove(this.eventServiceSubscribeId);
        }
    }

    updateButtonColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.theme.buttonColor = value;
            this.updateButtonColorRatio();
        }
    }

    updateButtonTextColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.theme.buttonTextColor = value;
            this.updateButtonColorRatio();
        }
    }

    isValidUrl (url: string, controlName: string): boolean {
        try {
            new URL(url);
        } catch {
            if (this.consentManagerForm) {
                this.consentManagerForm.form.controls[controlName].setErrors({ 'incorrect': true });
            }
            return false;
        }
        if (this.consentManagerForm) {
            this.consentManagerForm.form.controls[controlName] && this.consentManagerForm.form.controls[controlName].setErrors(null);
            return true;
        }
        return false;
    }

    onIncludedLanguagesChange (): void {
        // Need timeout for Defaultlanguage select to update values
        setTimeout(() => {
            if (this.options && this.dataModel) {
                this.availableDefaultLanguages = this.options.languages.filter((language) => {
                    return this.dataModel?.included_languages.includes(language.value);
                });
            }
        });
    }

    setCookieOverview (isCookieOverviewLinkEnabled: boolean): void {
        if (this.dataModel) {
            this.dataModel.cookieOverviewLinkEnabled = isCookieOverviewLinkEnabled;
            this.updateGeneralSetting();
        }
    }

    setPrivacyRegulation (value: string | Array<string>): void {
        this.selectedRegulationAsString = typeof value === 'string' ? value : JSON.stringify(value);

        if (typeof value === 'string') {
            value = value.split(' ');
        }
        if (this.dataModel) {
            this.dataModel.privacyRegulation = value;
            this.setVisibleOptions();
        }
    }


    setVisibleOptions (): void {
        if (this.dataModel) {
            this.showCCPAOptions = this.dataModel.privacyRegulation.indexOf(CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.CCPA) > -1;
            this.showGDPROptions = this.dataModel.privacyRegulation.indexOf(CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.GDPR) > -1;
        }
    }

    submit (form: NgForm, e: Event): void {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (form.valid) {
            this.eventService.run('closeConsentManagerDialog');
        } else {
            const invalidInput = this.dialogBody?.nativeElement.querySelector('.ng-invalid');
            if (invalidInput) {
                if (invalidInput.getAttribute('type') === 'hidden') {
                    invalidInput.previousElementSibling?.scrollIntoView(true);
                } else {
                    invalidInput.scrollIntoView(true);
                }
            }
        }
    }

    private init (modelOptions: ConsentManagerSettingsOptionsInterface): void {
        modelOptions = modelOptions;
        this.dataModel = new ConsentManagerModel(cloneDeep(modelOptions));
        this.options = this.consentManagerOptionsService.options;

        // TODO: tabbing doesn't focus all items: https://optimere.atlassian.net/browse/MON-2734

        const regulation = this.dataModel.privacyRegulation;
        if (Array.isArray(regulation) && regulation.length === 1) {
            this.selectedRegulationAsString = regulation[0];
        } else {
            this.selectedRegulationAsString = typeof regulation === 'string' ? regulation : regulation.join(' ');
        }
        this.setVisibleOptions();
        this.updateButtonColorRatio();
        this.onIncludedLanguagesChange();


        if (this.consentManagerForm) {
            this.formChangesSubscription = this.consentManagerForm.form.valueChanges.pipe(skipUntil(timer(0))).subscribe(() => {
                if (this.consentManagerForm) {
                    if (this.buttonColorRation) {
                        this.validateColorField('buttonColor',
                            this.consentManagerForm.form.controls,
                            this.buttonColorRation < 4);
                    }
                    this.eventService.run('cmSettingsFormValidStatus', this.consentManagerForm.form.valid);
                    if (this.consentManagerForm.form.valid) {
                        this.updateGeneralSetting();
                    }
                }
            });
        }
    }

    private validateColorField (name: string, controls: Record<string, AbstractControl>, isTrue: boolean): void {
        if (controls[name]) {
            controls[name].setErrors(isTrue ? { 'incorrect': true } : null);
        }
    }

    private updateButtonColorRatio (): void {
        if (this.dataModel) {
            this.buttonColorRation = this.colorService.hexContrast(this.dataModel.theme.buttonColor, this.dataModel.theme.buttonTextColor);
        }
    }

}
