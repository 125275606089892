(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name feedbackService
     * @description Service to handle feedbacks
     */
    angular.module('services.entities').factory('feedbackService', feedbackService);

    feedbackService.$inject = ['SupportFeedbackRepo'];
    /* @ngInject*/
    function feedbackService (SupportFeedbackRepo) {
        return {
            create: create,
        };

        function create (params, config) {
            return SupportFeedbackRepo.create(params, config);
        }
    }
})();
