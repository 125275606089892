import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Customer } from '@monsido/modules/models/api/customer';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { HttpRequestOptionsType } from '@monsido/ng2/external/http/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class AccountRepo {
    constructor (private client: ApiClient) {}

    get (): Promise<Customer> {
        return this.client.getPromise('account').then((model: Customer) => {
            if (model) {
                return this.toModel(model);
            }
            return {} as Customer;
        });
    }

    update (data, params?: HttpRequestOptionsType): Promise<Customer> {
        return this.client.patchPromise('account', data, params).then(this.toModel);
    }

    private toModel (model: CustomerInterface): Customer {
        return new Customer(model);
    }
}
