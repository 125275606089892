(function () {
    'use strict';

    angular.module('pages.qa').component('qualityPages', {
        templateUrl: 'app/pages/qa/pages/pages.html',
        controller: QaErrorsController,
        controllerAs: 'vm',
    });

    QaErrorsController.$inject = [
        'QualityRepo',
        'pagesTabsHelper',
        'ng2SessionService',
        '$controller',
        'activeFeatures',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        '$filter',
        'gettextCatalog',
    ];

    function QaErrorsController (
        QualityRepo,
        pagesTabsHelper,
        ng2SessionService,
        $controller,
        activeFeatures,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        $filter,
        gettextCatalog,
    ) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.search = null;
            vm.pages = [];
            vm.tabs = pagesTabsHelper.getTabs();
            vm.activeTab = pagesTabsHelper.getActiveTab();
            vm.showNotifications = ['dead-links', 'broken-images', 'misspellings'];
            vm.isFiltersOpen = false;
            vm.showFilters = activeFeatures.table_filters;
            vm.issues = [];
            vm.subheaderTranslation = '';
            if (ng2SessionService.domain.scan.scan_cpr) {
                vm.showNotifications.push('nin-count');
            }

            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by === 'notification_count' ? 'quality_notification_count' : vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                qa_errors: true,
                search: vm.search || null,
            };

            params = updateParamsWithIssuesFilters(params);

            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            if (vm.selectedLanguages && vm.selectedLanguages.length > 0) {
                params['languages[]'] = vm.selectedLanguages;
            }

            if (vm.activeTab) {
                params['types[]'] = [vm.activeTab];
            }

            vm.promise = QualityRepo.getAll(params).then(function (data) {
                vm.pages = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.pages.total, '0,0'),
                '1 page',
                '{{$count}} pages',
                {},
            );
        }

        function updateParamsWithIssuesFilters (params) {
            for (var i = 0; i < vm.issues.length; i++) {
                params[vm.issues[i].id] = true;
            }
            return params;
        }

        function onFiltersChanges (changes) {
            if (changes.labels) {
                vm.selectedLabels = changes.labels.map(function (label) {
                    return label.id;
                });
            } else {
                vm.selectedLabels = [];
            }

            if (changes.languages) {
                vm.selectedLanguages = changes.languages.map(function (language) {
                    return language.lang;
                });
            } else {
                vm.selectedLanguages = [];
            }

            if (changes.contains) {
                vm.issues = changes.contains;
            } else {
                vm.issues = [];
            }

            getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
