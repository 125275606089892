(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsSummaryDoughnut', {
        templateUrl: 'app/pages/statistics/visitors/summary/charts/doughnut/doughnut.html',
        controller: StatisticsVisitorsSummaryDoughnutController,
        controllerAs: 'vm',
        bindings: {
            title: '@',
            linkText: '@',
            link: '@',
            type: '@',
            startDate: '<?',
            endDate: '<?',
        },
    });

    StatisticsVisitorsSummaryDoughnutController.$inject = [
        'StatsRepo',
        'API_CONSTANTS',
        '$stateParams',
        'gettextCatalog',
        '$filter',
        'MON_ICONS',
    ];
    /* @ngInject */
    function StatisticsVisitorsSummaryDoughnutController (
        StatsRepo,
        API_CONSTANTS,
        $stateParams,
        gettextCatalog,
        $filter,
        MON_ICONS,
    ) {
        var vm = this;

        vm.$onChanges = update;
        vm.itemClass = itemClass;
        vm.itemIcon = itemIcon;

        function update () {
            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.doughnutColors = ['#3da3e9', '#89cdfd', '#3669d3'];
            vm.doughnutOptions = {
                cutoutPercentage: 75,
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };

            updateType();
        }

        function updateType () {
            switch (vm.type) {
                case 'devices':
                    getDevices();
                    break;
                case 'browsers':
                    getBrowsers();
                    break;
                case 'os':
                    getOs();
                    break;
            }
        }

        function getDevices () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            vm.loading = true;
            vm.promise = StatsRepo.getDevices(params)
                .then(function (data) {
                    vm.pages = data.filter(function (device) {
                        return device.visits > 0;
                    });
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.doughnutLabels = [];
                    vm.doughnutData = [];
                    for (var i = 0; i < vm.pages.length; i++) {
                        if (i < 3) {
                            vm.doughnutLabels.push(vm.pages[i].label);
                            vm.doughnutData.push(vm.pages[i].visits);
                        }
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getBrowsers () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            vm.loading = true;
            vm.promise = StatsRepo.getBrowsers(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.doughnutLabels = [];
                    vm.doughnutData = [];
                    for (var i = 0; i < vm.pages.length; i++) {
                        if (i < 3) {
                            vm.doughnutLabels.push(vm.pages[i].label);
                            vm.doughnutData.push(vm.pages[i].visits);
                        }
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getOs () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            vm.loading = true;
            vm.promise = StatsRepo.getOs(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.doughnutLabels = [];
                    vm.doughnutData = [];
                    for (var i = 0; i < vm.pages.length; i++) {
                        if (i < 3) {
                            vm.doughnutLabels.push(vm.pages[i].label);
                            vm.doughnutData.push(vm.pages[i].visits);
                        }
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function itemClass (index) {
            switch (index) {
                case 0:
                    return 'visitory-summary-doughnut__first';
                case 1:
                    return 'visitory-summary-doughnut__second';
                case 2:
                    return 'visitory-summary-doughnut__third';
            }
        }

        function itemIcon (item) {
            var icon;
            switch (vm.type) {
                case 'devices':
                    icon = MON_ICONS.STATISTICS.SOURCES.DEVICES[item.label.toLowerCase()];
                    break;
                case 'browsers':
                    icon = MON_ICONS.STATISTICS.SOURCES.BROWSERS[item.label.toLowerCase()];
                    break;
                case 'os':
                    icon = MON_ICONS.STATISTICS.SOURCES.OPERATION_SYSTEMS[item.label.toLowerCase()];
                    break;
            }

            if (icon == undefined) {
                icon = MON_ICONS.STATISTICS.SOURCES.UNKNOWN;
            }

            return icon;
        }
    }
})();
