(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSummaryMostFrequent', {
        templateUrl: 'app/pages/statistics/summary/most-frequent/most-frequent.html',
        controller: StatisticsSummaryMostFrequentController,
        controllerAs: 'vm',
        bindings: {
            startDate: '<',
            endDate: '<',
        },
    });

    StatisticsSummaryMostFrequentController.$inject = [
        'ParamService',
        'StatsRepo',
        '$q',
        '$filter',
        '$stateParams',
        'gettextCatalog',
        'MON_ICONS',
    ];

    /* @ngInject */
    function StatisticsSummaryMostFrequentController (
        ParamService,
        StatsRepo,
        $q,
        $filter,
        $stateParams,
        gettextCatalog,
        MON_ICONS,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.$onChanges = update;
        vm.mostFrequentClass = mostFrequentClass;

        function activate () {
            vm.allEmpty = false;
        }

        function update () {
            vm.mostFrequent = {};
            vm.loading = true;
            var promises = [];
            var types = ['from', 'using', 'browser', 'os', 'resolution', 'language'];
            var typeTranslations = {
                from: gettextCatalog.getString('Location'),
                using: gettextCatalog.getString('Device'),
                browser: gettextCatalog.getString('Browser'),
                os: gettextCatalog.getString('Operating System'),
                resolution: gettextCatalog.getString('Resolution'),
                language: gettextCatalog.getString('Language'),
            };
            var params = {
                limit: 100,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            promises.push(getLocations(params));
            promises.push(getDevices(params));
            promises.push(getBrowsers(params));
            promises.push(getOs(params));
            promises.push(getResolutions(params));
            promises.push(getLanguages(params));

            $q.all(promises)
                .then(
                    function (data) {
                        vm.emptyTypes = [];
                        types.map(function (type, index) {
                            vm.mostFrequent[type] = {
                                type: type,
                                title: typeTranslations[type],
                            };

                            if (data[index][0]) {
                                vm.mostFrequent[type].label = data[index][0].label || '';
                                vm.mostFrequent[type].value = data[index][0].visits || 0;
                                vm.mostFrequent[type].total = data[index].reduce(function (total, page) {
                                    return total + parseInt(page.visits);
                                }, 0);
                            } else {
                                vm.emptyTypes.push(type);
                                vm.mostFrequent[type].label = gettextCatalog.getString('Unknown');
                                vm.mostFrequent[type].value = 1;
                                vm.mostFrequent[type].total = 1;
                            }
                        });

                        vm.allEmpty = vm.emptyTypes.length === data.length;
                    },
                    function () {
                        vm.allEmpty = true;
                    },
                )
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getLocations (params) {
            return StatsRepo.getLocations(params);
        }

        function getDevices (params) {
            return StatsRepo.getDevices(params);
        }

        function getBrowsers (params) {
            return StatsRepo.getBrowsers(params);
        }

        function getOs (params) {
            return StatsRepo.getOs(params);
        }

        function getResolutions (params) {
            return StatsRepo.getResolutions(params);
        }

        function getLanguages (params) {
            return StatsRepo.getLanguages(params);
        }

        function mostFrequentClass (data) {
            var result;
            switch (data.type) {
                case 'browser':
                    angular.forEach(MON_ICONS.STATISTICS.SOURCES.BROWSERS, function (icon, browser) {
                        if (data.label.toLowerCase().indexOf(browser) > -1) {
                            result = icon;
                        }
                    });
                    break;
                case 'using':
                    angular.forEach(MON_ICONS.STATISTICS.SOURCES.DEVICES, function (icon, device) {
                        if (data.label.toLowerCase().indexOf(device) > -1) {
                            result = icon;
                        }
                    });
                    break;
                case 'os':
                    angular.forEach(MON_ICONS.STATISTICS.SOURCES.OPERATION_SYSTEMS, function (icon, os) {
                        if (data.label.toLowerCase().indexOf(os) > -1) {
                            result = icon;
                        }
                    });
                    break;
                default:
                    result = MON_ICONS.STATISTICS.SOURCES[data.type.toUpperCase()];
                    break;
            }

            if (typeof result === 'undefined') {
                result = MON_ICONS.STATISTICS.SOURCES.UNKNOWN;
            }

            return result;
        }
    }
})();
