(function () {
    'use strict';

    /**
   * Chart
   *
   * @memberof blocks.service
   * @ngdoc factory
   * @name AccessibilityLineChartService
   * @description
   * Create the necessary objects to shape the Accessibility line chart
   */
    angular
        .module('blocks.service')
        .factory('AccessibilityLineChartService', AccessibilityLineChartService);

    AccessibilityLineChartService.$inject = [
        '$filter',
        'gettextCatalog',
        'LineChart',
        'ChartsDataHelper',
        'ng2SessionService',
        'AccessibilityResultTypeService',
        'moment',
        'MONSIDO_COLOR',
        '$window',
        'ng2AccessibilityGuidelinesInfoService',
        'ng2AccessibilityGuidelineVersionService',
        '$q',
        'LegendCursorService',
    ];

    function AccessibilityLineChartService (
        $filter,
        gettextCatalog,
        LineChart,
        ChartsDataHelper,
        ng2SessionService,
        AccessibilityResultTypeService,
        moment,
        MONSIDO_COLOR,
        $window,
        ng2AccessibilityGuidelinesInfoService,
        ng2AccessibilityGuidelineVersionService,
        $q,
        LegendCursorService,
    ) {
        var vm = this;

        /**
     * @memberOf AccessibilityLineChartService
     * @param accessibilityType - (WCAG2-A, WCAG2-AA, WCAG2-AAA, WCAG21-A, WCAG21-AA, WCAG21-AAA, WCAG22-A, WCAG22-AA, WCAG22-AAA)
     * @param selectedGroup (issues, checks)
     * @description Initialize the SEOLineChartService factory
     */
        function init (accessibilityType, selectedGroup) {
            vm.lineChartSettings = new LineChart();
            if (accessibilityType) {
                vm.accessibilityType = accessibilityType;
            } else if (ng2SessionService.domain !== null) {
                vm.accessibilityType = ng2SessionService.domain.features.accessibility;
            }
            vm.selectedGroup = selectedGroup;
            setChecks();
            return {
                setup: setup,
                getOption: getOption,
                getColors: getColors,
                getSeries: getSeries,
                getOverride: getOverride,
                getLabels: getLabels,
                getData: getData,
            };
        }

        return init;

        /**
     * @memberOf AccessibilityLineChartService
     * @param accessibilityType - (WCAG2-A, WCAG2-AA, WCAG2-AAA, WCAG21-A, WCAG21-AA, WCAG21-AAA, WCAG22-A, WCAG22-AA, WCAG22-AAA)
     * @param selectedGroup (issues, checks)
     * @description Initialize the SEOLineChartService factory
     */
        function setup () {
            var abbrs = vm.accessibilityType ? [vm.accessibilityType] : [];
            return ng2AccessibilityGuidelinesInfoService.get(abbrs);
        }

        /**
     * @memberOf AccessibilityLineChartService
     * @param {String} resultType Selected resultType (total, error, review, warning)
     * @description Promise of option object for the Accessibility line chart or null if there isn't enough data to render chart
     * @return {Promise<Object | null>}
     */
        function getOption (resultType, crawls) {
            var abbrs =
        crawls.map(function (crawl) {
            return crawl.accessibility_guideline;
        }) || [];
            if (abbrs.length === 0) {
                return $q.resolve(null);
            }
            return ng2AccessibilityGuidelinesInfoService.get(abbrs).then(function () {
                var checkCount = Math.max.apply(
                    undefined,
                    crawls.map(function (crawl) {
                        return AccessibilityResultTypeService.getCheckCountByResultType(
                            resultType,
                            crawl.accessibility_guideline,
                        );
                    }),
                );
                var showIssues = vm.selectedGroup === 'issues';
                vm.lineChartSettings.setTooltipSettings({
                    intersect: true,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var result =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    .y;
                            var label = data.datasets[tooltipItem.datasetIndex].label;
                            return label + ': ' + $filter('numeraljs', '0,0')(result);
                        },
                        title: function (tooltipItem) {
                            return moment(tooltipItem[0].xLabel).format('MMM DD');
                        },
                    },
                });

                vm.lineChartSettings.setLegendSettingsOverride({
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 11,
                        boxWidth: 10,
                    },
                });

                vm.lineChartSettings.setScalesSettings({
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            type: 'time',
                            barPercentage: 1,
                            categoryPercentage: 0.5,
                            stacked: true,
                            gridLines: {
                                drawBorder: false,
                                display: false,
                            },
                            ticks: {
                                fontSize: 11,
                                maxTicksLimit: 10,
                            },
                            time: {
                                minUnit: 'day',
                                displayFormats: {
                                    // Since the scans always only appear once or twice a week, there's no need for any other date formats to be displayed
                                    millisecond: 'MMM D',
                                    second: 'MMM D',
                                    minute: 'MMM D',
                                    hour: 'MMM D',
                                    day: 'MMM D',
                                    week: 'MMM D',
                                    month: 'MMM D',
                                    quarter: 'MMM D',
                                    year: 'MMM D',
                                },
                            },
                        },
                    ],
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            position: 'right',
                            display: !showIssues,
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                maxTicksLimit: 4,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0a').toUpperCase();
                                },
                            },
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Pages'),
                                fontSize: 11,
                            },
                            afterDataLimits: function (axis) {
                                axis.options.display = !showIssues && axis.max > 1;
                            },
                        },
                        {
                            id: 'y-axis-1',
                            stacked: true,
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                maxTicksLimit: 4,
                                stepSize: Math.ceil(checkCount / 4),
                                max: checkCount,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                            },
                            gridLines: {
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Checks'),
                                fontSize: 11,
                            },
                        },
                        {
                            id: 'y-axis-2',
                            position: 'right',
                            display: showIssues,
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                maxTicksLimit: 4,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0a').toUpperCase();
                                },
                            },
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Issues'),
                                fontSize: 11,
                            },
                            afterDataLimits: function (axis) {
                                axis.options.display = showIssues && axis.max > 1;
                            },
                        },
                    ],
                });

                vm.lineChartSettings.options = LegendCursorService.setHoverState(vm.lineChartSettings.options);

                if (crawls) {
                    return ng2AccessibilityGuidelineVersionService
                        .getVersions(crawls)
                        .then(function (versionChanges) {
                            var lines = [];
                            for (var i = 0; i < crawls.length; i++) {
                                var element = crawls[i];
                                for (var j = 0; j < versionChanges.length; j++) {
                                    var versionChange = versionChanges[j];
                                    if (element.post_processing_done_at === versionChange.time) {
                                        lines.push({
                                            xAxisID: 'x-axis-0',
                                            x: element.date,
                                            style: {
                                                lineDash: [5, 5],
                                                color: MONSIDO_COLOR['secondary-15'],
                                                width: 3,
                                            },
                                            label: {
                                                text: versionChange.title,
                                                color: MONSIDO_COLOR['secondary-15'],
                                                position: 'top',
                                            },
                                            data: {
                                                link: versionChange.infoLink,
                                            },
                                        });
                                        break;
                                    }
                                }
                            }

                            vm.lineChartSettings.options.verticalLines = {
                                lines: lines,
                            };

                            return vm.lineChartSettings.options;
                        }, angular.noop);
                } else {
                    return vm.lineChartSettings.options;
                }
            }, angular.noop);
        }

        /**
     * @memberOf AccessibilityLineChartService
     * @description The colors for the Accessibility line chart
     * @return {string[]}
     */
        function getColors () {
            var colors = {
                A: MONSIDO_COLOR['secondary-12'],
                AA: MONSIDO_COLOR['secondary-13'],
                AAA: MONSIDO_COLOR['secondary-14'],
            };

            if (vm.selectedGroup === 'issues') {
                vm.lineChartSettings.addColor('#ff101a');
            } else {
                for (var key in vm.accessibilityChecks) {
                    vm.lineChartSettings.addColor(colors[key]);
                }
            }
            vm.lineChartSettings.addColors(['#57b2b7', '']);
            vm.lineChartSettings.addColors(['#ff9d4e', '#a9dad6']);
            return vm.lineChartSettings.colors;
        }

        /**
     * @memberOf AccessibilityLineChartService
     * @description The series for the Accessibility line chart
     * @return {string[]}
     */
        function getSeries () {
            var series = {
                A: gettextCatalog.getString('A'),
                AA: gettextCatalog.getString('AA'),
                AAA: gettextCatalog.getString('AAA'),
            };

            if (vm.selectedGroup === 'issues') {
                vm.lineChartSettings.addSerie(
                    gettextCatalog.getString('Accessibility issues'),
                );
            } else {
                for (var key in vm.accessibilityChecks) {
                    vm.lineChartSettings.addSerie(series[key]);
                }
            }
            vm.lineChartSettings.addSeries([
                gettextCatalog.getString('Checks passed'),
                gettextCatalog.getString('Failing checks'),
                gettextCatalog.getString('Pages with issues'),
                gettextCatalog.getString('Scanned pages'),
            ]);
            return vm.lineChartSettings.series;
        }

        /**
     * @memberOf AccessibilityLineChartService
     * @description The override object for the Accessibility line chart
     */
        function getOverride () {
            if (vm.selectedGroup === 'issues') {
                vm.lineChartSettings.addOverride({
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-2',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                });
            } else {
                Object.keys(vm.accessibilityChecks).forEach(function () {
                    vm.lineChartSettings.addOverride({
                        xAxisID: 'x-axis-0',
                        yAxisID: 'y-axis-1',
                        type: 'line',
                        fill: false,
                        lineTension: 0,
                        radius: 0,
                        borderWidth: 1,
                        pointRadius: 3,
                        pointHitRadius: 34,
                    });
                });
            }
            vm.lineChartSettings.addOverrides([
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'bar',
                    borderWidth: 0,
                    backgroundColor: '#57b2b7',
                    borderColor: '#57b2b7',
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'bar',
                    borderWidth: 0,
                    backgroundColor: '#eee',
                    borderColor: '#eee',
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: true,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    borderDash: [5, 5],
                    pointHitRadius: 34,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: true,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    borderDash: [5, 5],
                    pointHitRadius: 34,
                },
            ]);

            return vm.lineChartSettings.override;
        }

        /**
     * @memberOf AccessibilityLineChartService
     * @param {Array} crawls crawl history collection
     * @description The labels for the Accessibility line chart
     * @return {Array}
     */
        function getLabels (crawls) {
            crawls = crawls || [];
            return crawls.map(function (crawl) {
                return new Date(crawl.date).getTime();
            });
        }

        /**
     * @memberOf AccessibilityLineChartService
     * @param {Array} crawls crawl history collection
     * @param {String} resultType Selected resultType (total, error, review, warning)
     * @description The data object for the Accessibility line chart
     * @return {Array}
     */
        function getData (crawls, resultType) {
            crawls = crawls || [];
            var helper = new ChartsDataHelper('time');
            var lineChart = [];
            var updatedCrawls = crawls
                .filter(function (crawl, index) {
                    return index === 0 || crawl.date !== crawls[index - 1].date;
                })
                .map(function (crawl) {
                    var resultTypeParams =
            AccessibilityResultTypeService.getChecksParameters(
                resultType,
                crawl.accessibility_guideline,
            );
                    var checkCount =
            AccessibilityResultTypeService.getCheckCountByResultType(
                resultType,
                crawl.accessibility_guideline,
            );
                    crawl.accessibility_checks = crawl.accessibility_checks || {};
                    crawl.accessibility_checks.date = crawl.date;
                    crawl.accessibility_checks_in_error = helper.getCount(
                        crawl.accessibility_checks,
                        resultTypeParams,
                    );
                    crawl.accessibility_checks_in_compliance =
            checkCount - crawl.accessibility_checks_in_error;
                    return crawl;
                });

            /* jshint ignore:start */
            if (vm.selectedGroup === 'issues') {
                lineChart.push(
                    updatedCrawls.map(function (crawl) {
                        return helper.setData(crawl, 'accessiblity_issues_count');
                    }),
                );
            } else {
                for (var i in vm.accessibilityChecks) {
                    lineChart.push(
                        updatedCrawls.map(function (crawl) {
                            return helper.setData(
                                {
                                    result: AccessibilityResultTypeService.getCrawlIssueCount(
                                        crawl,
                                        i,
                                        resultType,
                                    ),
                                    date: crawl.date,
                                },
                                'result',
                            );
                        }),
                    );
                }
            }
            /* jshint ignore:end */

            lineChart.push(
                updatedCrawls.map(function (crawl) {
                    return helper.setData(crawl, 'accessibility_checks_in_compliance');
                }),
            );
            lineChart.push(
                updatedCrawls.map(function (crawl) {
                    return helper.setData(crawl, 'accessibility_checks_in_error');
                }),
            );
            lineChart.push(
                updatedCrawls.map(function (crawl) {
                    return helper.setData(crawl, 'pages_with_accessibility_errors_count');
                }),
            );
            lineChart.push(
                updatedCrawls.map(function (crawl) {
                    return helper.setData(crawl, 'page_count');
                }),
            );
            return lineChart;
        }

        // PROTECTED

        /**
     * @memberOf AccessibilityLineChartService
     * @description Set the accessibilityChecks object with available parameters
     * @return void
     */
        function setChecks () {
            vm.accessibilityChecks = {};

            // WCAG2
            if (
                [
                    'WCAG2-A',
                    'WCAG2-AA',
                    'WCAG2-AAA',
                    'WCAG21-A',
                    'WCAG21-AA',
                    'WCAG21-AAA',
                ].indexOf(vm.accessibilityType) > -1
            ) {
                vm.accessibilityChecks.A = 0;
            }

            if (
                ['WCAG2-AA', 'WCAG2-AAA', 'WCAG21-AA', 'WCAG21-AAA'].indexOf(
                    vm.accessibilityType,
                ) > -1
            ) {
                vm.accessibilityChecks.AA = 0;
            }

            if (['WCAG2-AAA', 'WCAG21-AAA'].indexOf(vm.accessibilityType) > -1) {
                vm.accessibilityChecks.AAA = 0;
            }
        }
    }
})();
