import { HttpClient, HttpContext, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { Params } from './params';
import { Injectable } from '@angular/core';
import { Config } from '@monsido/http/config';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


export type HttpRequestOptionsType = {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable()
export class MonHttpClientService extends HttpClient {
    private urlPrefix: string = '';
    private shouldCancelAllRequests$ = new Subject<void>();
    config: Config = {};

    get onCancelRequests (): Observable<void> {
        return this.shouldCancelAllRequests$.asObservable();
    }

    constructor (handler: HttpHandler) {
        super(handler);
        this.setUrlPrefix();
        this.setConfig();
    }

    public cancelRequests (): void {
        this.shouldCancelAllRequests$.next();
    }

    public setConfig (config?: Config): void {
        config = config || {
            headers: new HttpHeaders(),
        };
        this.config = config;
    }

    public setUrlPrefix (url: string = ''): void {
        this.urlPrefix = url;
    }

    public patchObservable<T = unknown> (url: string, body: unknown, options: Params = {}): Observable<T> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers as unknown as Record<string, string>);
        }
        return super.patch(this.urlPrefix + url, body, {
            ...this.config,
            ...options,
        } as HttpRequestOptionsType)
            .pipe(takeUntil(this.onCancelRequests)) as Observable<T>;
    }

    public patchPromise<T = unknown> (url: string, body: unknown, options?: HttpRequestOptionsType): Promise<T> {
        return this.patchObservable<T>(url, body, options as unknown as Params)
            .toPromise()
            .catch(err => {
                return Promise.reject(err.error || 'Server error');
            });
    }

    public getObservable<T = unknown> (url: string, options: Params = {}): Observable<T> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers as unknown as Record<string, string>);
        }

        if (options.params) {
            Object.keys(options.params).forEach(k => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (options.params![k] == null || options.params![k] === 'undefined') {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    delete options.params![k];
                }
            });
        }

        return super.get(this.urlPrefix + url, {
            ...this.config,
            ...options,
        } as HttpRequestOptionsType)
            .pipe(takeUntil(this.onCancelRequests)) as Observable<T>;
    }

    public getPromise<T = unknown> (url: string, options?: HttpRequestOptionsType): Promise<T> {
        return this.getObservable<T>(url, options as unknown as Params)
            .toPromise()
            .catch(err => {
                return Promise.reject(err || 'Server error');
            });
    }

    public postObservable<T = unknown> (url: string, body: unknown, options: Params = {}): Observable<T> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers as unknown as Record<string, string>);
        }
        return super.post(this.urlPrefix + url, body, {
            ...this.config,
            ...options,
        } as HttpRequestOptionsType)
            .pipe(takeUntil(this.onCancelRequests)) as Observable<T>;
    }

    public postPromise<T = unknown> (url: string, body: unknown, options?: { headers: Record<string, string>}): Promise<T> {
        return this.postObservable<T>(url, body, options as unknown as Params)
            .toPromise()
            .catch(err => {
                return Promise.reject(err || 'Server error');
            });
    }

    public deleteObservable<T = unknown> (url: string, options: Params = {}): Observable<T> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers as unknown as Record<string, string>);
        }
        return super.delete(this.urlPrefix + url, {
            ...this.config,
            ...options,
        } as HttpRequestOptionsType)
            .pipe(takeUntil(this.onCancelRequests)) as Observable<T>;
    }

    public deletePromise<T = unknown> (url: string, options?: HttpRequestOptionsType): Promise<T> {
        return this.deleteObservable<T>(url, options as unknown as Params)
            .toPromise()
            .catch(err => {
                return Promise.reject(err.error || 'Server error');
            });
    }

    protected setHeader (name: string, value: string): void {
        if (this.config) {
            if (typeof value !== 'string') {
                value = String(value);
            }
            this.config.headers = this.config.headers?.set(name, value);
        }
    }

    protected removeHeader (name: string): void {
        if (this.config) {
            this.config.headers = this.config.headers?.delete(name);
        }
    }

    protected setHeaders (optHeaders?: Record<string, string>): HttpHeaders | undefined {
        let headers: HttpHeaders = this.config?.headers || new HttpHeaders();
        if (optHeaders) {
            Object.keys(optHeaders).forEach((key: string) => {
                if (headers?.has(key)) {
                    headers = headers.delete(key);
                }

                const optHeaderValue = optHeaders[key];
                if (optHeaderValue != null) {
                    headers = headers?.append(key, optHeaderValue);
                }
            });
        }
        return headers;
    }
}
