(function () {
    'use strict';

    angular.module('modules.page-performance.page-details').component('pagePerformancePageDetailsLoadTimesTrend', {
        templateUrl: 'app/modules/page-performance/page-details/dashboard/load-times-trend/load-times-trend.html',
        controller: PagePerformancePageDetailsLoadTimesTrendController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            report: '<',
        },
    });

    PagePerformancePageDetailsLoadTimesTrendController.$inject = [
        'gettextCatalog',
        '$filter',
        'moment',
        'PAGE_PERFORMANCE_AUDIT_ID',
        'MONSIDO_COLOR',
        'PagePerformanceVersionService',
    ];
    /* @ngInject */
    function PagePerformancePageDetailsLoadTimesTrendController (
        gettextCatalog,
        $filter,
        moment,
        PAGE_PERFORMANCE_AUDIT_ID,
        MONSIDO_COLOR,
        PagePerformanceVersionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;

        function activate () {
            vm.versions = [];
            vm.seriesByVersion = {};
            var versionInfo = PagePerformanceVersionService.getVersionInfo(vm.report.version);
            var metricUseDisplayValue = ['cumulative-layout-shift'];
            vm.summary = versionInfo.metrics?.map(function (metric) {
                var useDisplayValue = metricUseDisplayValue.indexOf(metric.auditId) > -1;
                return {
                    id: metric.auditId,
                    label: gettextCatalog.getString(metric.label),
                    value:
                        $filter('monNumber')(vm.report.audits[metric.auditId][useDisplayValue ? 'displayValue' : 'numericValue']) +
                        ' ' +
                        metric.unit,
                    class: metric.rulerClass,
                    weight: '',
                };
            });
            vm.history = vm.history || [];
            setWeights();
            setupChart();
            setupChartData();
        }

        function onChanges () {
            setupChart();
            setupChartData();
        }

        function setWeights () {
            if (
                !vm.summary ||
                !vm.report ||
                !vm.report.categories ||
                !vm.report.categories.performance ||
                !vm.report.categories.performance.auditRefs
            ) {
                return;
            }
            for (var i = 0; i < vm.summary.length; i++) {
                var metric = vm.summary[i];
                var auditRef = vm.report.categories.performance.auditRefs.find(function (ref) {
                    return ref.id === metric.id;
                });
                if (auditRef) {
                    metric.weight = auditRef.weight + '%';
                }
            }
        }

        function setupChart () {
            var availableColors = [
                '#f4151e',
                '#6122ee',
                MONSIDO_COLOR['secondary-12'],
                '#00DEDB',
                '#FF9879',
                MONSIDO_COLOR['secondary-15'],
                MONSIDO_COLOR['secondary-11'],
                MONSIDO_COLOR['secondary-16'],
            ];
            var availableOverrides = [
                {
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    backgroundColor: '#ffffff',
                    borderDash: [6, 6],
                },
                {
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    backgroundColor: '#ffffff',
                },
                {
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderDash: [4, 4],
                },
                {
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                },
                {
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderDash: [2, 2],
                },
                {
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                },
            ];

            var seriesByVersion = PagePerformanceVersionService.getSeriesByVersion(vm.history, 'version');
            vm.versions = seriesByVersion.versions;
            vm.seriesByVersion = seriesByVersion.series;

            if (vm.versions.length === 1) {
                vm.metrics = vm.versions[0].metrics;
            } else {
                vm.metrics = [];
                vm.metricsIds = {};
                for (var i = 0; i < vm.versions.length; i++) {
                    var versionMetrics = vm.versions[i].metrics;
                    for (var j = 0; j < versionMetrics.length; j++) {
                        var versionMetric = versionMetrics[j];
                        if (!vm.metricsIds[versionMetric.id]) {
                            vm.metrics.push(versionMetric);
                            vm.metricsIds[versionMetric.id] = versionMetric;
                        }
                    }
                }
            }

            vm.series = [];
            vm.colors = [];
            vm.override = [];

            vm.metrics.forEach(function (metric, index) {
                vm.series.push(metric.chartLabel);
                vm.colors.push(availableColors[index]);
                var override = availableOverrides[index];
                if (!override) {
                    override = availableOverrides[index - availableOverrides.length];
                }
                override.yAxisID = getYAxis(metric.unit);
                vm.override.push(override);
            });
            vm.options = {
                layout: {
                    padding: {
                        top: 5,
                        right: 30,
                    },
                },
                scaleShowValues: true,
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                data.datasets[tooltipItem.datasetIndex].label +
                                ': ' +
                                $filter('monNumber')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / 1000, '0.[00]') +
                                (tooltipItem.datasetIndex === 5 ? '' : 's')
                            );
                        },
                    },
                },
                scales: {
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            type: 'time',
                            gridLines: {
                                offsetGridLines: true,
                                drawBorder: false,
                                display: false,
                            },
                            ticks: {
                                fontSize: 11,
                                maxTicksLimit: 30,
                            },
                            time: {
                                minUnit: 'day',
                                displayFormats: {
                                    millisecond: 'MMM D',
                                    second: 'MMM D',
                                    minute: 'MMM D',
                                    hour: 'MMM D',
                                    day: 'MMM D',
                                    week: 'MMM D',
                                    month: 'MMM D',
                                    quarter: 'MMM D',
                                    year: 'MMM D',
                                },
                            },
                        },
                    ],
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                fontSize: 11,
                                min: 0,
                                beginAtZero: true,
                                suggestedMax: 5,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('monNumber')(value, '0,0');
                                },
                            },
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Milliseconds'),
                                fontSize: 11,
                            },
                        },
                        {
                            id: 'y-axis-2',
                            type: 'linear',
                            position: 'right',
                            ticks: {
                                fontSize: 11,
                                min: 0,
                                max: 1,
                                beginAtZero: true,
                                suggestedMax: 1,
                                maxTicksLimit: 2,
                                callback: function (value) {
                                    return $filter('monNumber')(value, '0,00');
                                },
                            },
                            gridLines: {
                                drawBorder: false,
                                display: false,
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 13,
                    },
                },
            };

            var entriesWithVersionChanged = PagePerformanceVersionService.getEntriesWithVersionChanged(vm.history, 'version');
            if (entriesWithVersionChanged.length > 0) {
                vm.options.verticalLines = {
                    lines: entriesWithVersionChanged.map(function (entry) {
                        var versionInfo = PagePerformanceVersionService.getVersionInfo(entry.version);
                        return {
                            xAxisID: 'x-axis-0',
                            x: entry.scanned_at,
                            style: {
                                lineDash: [5, 5],
                                color: MONSIDO_COLOR['secondary-15'],
                                width: 3,
                            },
                            label: {
                                text: versionInfo.label,
                                color: MONSIDO_COLOR['secondary-15'],
                                position: 'top',
                            },
                            data: {
                                link: versionInfo.link,
                            },
                        };
                    }),
                };
            }
        }

        function getYAxis (unit) {
            switch (unit) {
                case 'ms':
                    return 'y-axis-1';
                default:
                    return 'y-axis-2';
            }
        }

        function setupChartData () {
            if (!Array.isArray(vm.history) || vm.history.length === 0) {
                return;
            }
            vm.data = vm.metrics.map(function () {
                return [];
            });
            vm.labels = [];
            vm.lastScan = moment(vm.history[0].scanned_at).format('LL LTS');
            vm.history.forEach(function (entry) {
                vm.labels.push(moment(entry.scanned_at).format('ll LT'));
                vm.metrics.forEach(function (metric, index) {
                    vm.data[index].push(parseFloat(entry[metric.id]));
                });
            });
        }
    }
})();
