import { Component, OnDestroy, OnInit } from '@angular/core';
import { MonPromptService, PromptOptions, SortType } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { UIRouter, UrlService } from '@uirouter/core';
import { AccessibilityIssueService } from './accessibility-issue.service';
import moment from 'moment';
import { IssueInterface } from '../../interfaces/issue-interface';
import { SnippetInterface } from '../../interfaces/snippet-interface';
import { BaseComponent } from '@monsido/core/components/base.component';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { AccessibilityError } from '@monsido/modules/page-details/models/accessibility-error.interface';
import { Page } from '@monsido/modules/page-details/models';
import { GetPagesParamsInterface } from 'app/modules/qa/repos/qa-issue-repo.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IssueStatusEnum } from 'app/modules/common/enum/issue-status.enum';
import { MonTableCollection } from '../../interfaces/mon-table-collection.interface';
import { IssueService } from '@monsido/modules/issue/services/issue.service';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import type { ActionMenuItemType } from '@monsido/ng2/shared/components/action-menu-panel/action-menu-panel.type';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'mon-accessibility-issue',
    templateUrl: './accessibility-issue.component.html',
    styleUrls: ['./accessibility-issue.component.scss'],
})
export class AccessibilityIssueComponent extends BaseComponent implements OnInit, OnDestroy {
    issueId: number = -1;
    title: string = '';
    issue?: IssueInterface;
    issues: MonTableCollection<IssueInterface> = [];
    issueStatusEnum = IssueStatusEnum;
    snippet?: SnippetInterface;
    loading: boolean = false;
    snippetType: string = '';
    currentPage?: Page;
    markAllCheckAsFixedFromTheDB: boolean = false;
    pages: MonTableCollection<Page> = [];
    openingPageDetails = false;
    subscription?: Subscription;
    getPagesParams: BehaviorSubject<GetPagesParamsInterface> = new BehaviorSubject({
        page: 1,
        page_size: 10,
        sort_by: 'title',
        sort_dir: 'desc',
    });

    promptOptions: PromptOptions = {
        parentSelector: '#common-dialog-wrapper',
        size: 'md',
    };
    dropdownOptions: ActionMenuItemType[] = [];

    reason: string = '';
    viewsColumIsAvailable: boolean = true;
    private checkId: number = -1;
    private pageId: number = -1;
    private sourceCodeId: number = -1;
    private pageDetailsOpeningMs = 1000;
    private pageDetailsOpeningTimeout: number | null = null;

    private _error?: AccessibilityError;
    set error (err) {
        this._error = err;
        this.updateDropdown();
    }

    get error (): AccessibilityError | undefined {
        return this._error;
    }

    private _status = '';
    set status (status) {
        this._status = status;
        this.updateDropdown();
    }

    get status (): string {
        return this._status;
    }

    constructor (
        private uiRouter: UIRouter,
        private translateService: TranslateService,
        private accessibilityIssueService: AccessibilityIssueService,
        private eventsService: MonEventService,
        private issueService: IssueService,
        private monPromptService: MonPromptService,
        private activeFeatureService: ActiveFeatureService,
        $location: UrlService,
    ) {
        super($location);
        ({ issueOverlayIssueId: this.issueId,
            issueOverlayCheckId: this.checkId,
            issueOverlaySourceCodeId: this.sourceCodeId,
            issueOverlayPageId: this.pageId,
        } = this.uiRouter.urlService.search());

        this.issueId = Number(this.issueId);
        this.checkId = Number(this.checkId);
        this.sourceCodeId = Number(this.sourceCodeId);
        this.pageId = Number(this.pageId);

        this.promptOptions.title = this.translateService.getString('Confirmation required');
        this.title = this.translateService.getString('Accessibility issue');
        this.snippetType = this.translateService.getString('Code');
    }

    ngOnInit (): void {
        this.getPage();
        this.viewsColumIsAvailable = this.activeFeatureService.isFeatureActive('script_setup_guide');
        this.updateDropdown();
    }

    async getPage (statusChanged = false): Promise<void> {
        this.loading = true;

        if (this.sourceCodeId) {
            await this.getAccessibilitySnippet(this.sourceCodeId);
        }

        if (!this.snippet) {
            this.loading = false;
            return;
        }

        await this.getAccessibilityIssues(this.sourceCodeId);
        if (this.checkId) {
            if (!this.subscription) {
                this.subscription = this.getPagesParams.subscribe(params => {
                    this.getAccessibilityIssuePages(params);
                });
            }
        }

        if (this.checkId && this.pageId) {
            await this.getErrorDetails();

            if (this.error && this.error.ignored && Array.isArray(this.error.comments)) {
                const lastComment = this.error.comments.find(comment => this.error?.updated_at === comment.created_at);
                if (lastComment) {
                    this.reason = lastComment.comment;
                } else {
                    this.reason = '';
                }
            }
        }

        if (!this.currentPage?.id) {
            await this.getPageDetails();
        }

        this.loading = false;

        if (statusChanged) {
            this.eventsService.run(MON_EVENTS.RELOAD_ACCESSIBILITY_ISSUES);
        }
    }

    ngOnDestroy (): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    markAsFixedPrompt () : void {
        this.monPromptService.confirm(
            '',
            {
                message: this.translateService.getString('Are you sure you want to mark this check as fixed?'),
                size: 'md',
                parentSelector: '#common-dialog-wrapper',
                title: this.translateService.getString('Confirmation required'),
            })
            .then(() => {
                this.markAllCheckAsFixed();
            })
            .catch(() => {});
    }

    async markAllCheckAsFixed (): Promise<void> {
        if (this.loading) {
            return;
        }

        this.loading = true;
        await this.accessibilityIssueService.deleteAccessibilityCheck(this.pageId, this.issueId).then(() => {
            this.getPage(true);
            this.markAllCheckAsFixedFromTheDB = true;
            this.status = IssueStatusEnum.fixed;
            this.reason = '';
        }, ()=> {})
            .finally( ()=> {
                this.loading = false;
            });
    }

    markAsReviewed (): void {
        this.askConfirmation({
            question: this.translateService.getString('Are you sure you want to review this issue?'),
            ignoredReviewed: true,
        });
    }

    markAsIgnored (): void {
        this.askConfirmation({
            question: this.translateService.getString('Are you sure you want to ignore this issue?'),
            ignoredReviewed: true,
        });
    }

    markAsUnreviewed (): void {
        this.askConfirmation({
            question: this.translateService.getString('Are you sure you want to remove review for this issue?'),
            ignoredReviewed: false,
        });
    }

    markAsUnignored (): void {
        this.askConfirmation({
            question: this.translateService.getString('Are you sure you want to unignore this issue?'),
            ignoredReviewed: false,
        });
    }

    async removeReview (): Promise<void> {

    }

    getIconColorClass (level: string): string {
        let colorClass = 'grey';
        switch (level.toUpperCase()) {
            case 'A':
                colorClass = 'wcag-a';
                break;
            case 'AA':
                colorClass = 'wcag-aa';
                break;
            case 'AAA':
                colorClass = 'wcag-aaa';
                break;
            case 'P. A':
            case 'P. C':
                colorClass = 'link';
                break;
        }
        return colorClass;
    }

    onSortContent (sortPayload: Record<string, string>, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        const { direction, by } = sortPayload;
        const params: GetPagesParamsInterface = {
            ...subject.value,
            sort_dir: direction as SortType,
            sort_by: by,
        };

        if (!direction) {
            params.sort_dir = 'asc';
        }

        if (!by) {
            params.sort_by = '';
        }

        subject.next(params);
    }

    onContentPageChange (page: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page,
        });
    }

    onContentPerPageChange (perPage: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page: 1,
            page_size: perPage,
        });
    }

    goToPageDetails (page: Page): void {
        if (!this.pageDetailsOpeningTimeout) {
            this.openingPageDetails = true;
            const target = 'page-details-section-accessibility';
            const callback = (): void => {
                this.openingPageDetails = false;
            };
            this.issueService.goToPageDetails(page, target, this.checkId, false, callback);

            this.pageDetailsOpeningTimeout = setTimeout(() => {
                this.pageDetailsOpeningTimeout = null;
            }, this.pageDetailsOpeningMs) as unknown as number;
        }
    }

    openInExtension (page: Page): void {
        const params = {
            type: 'acc',
            checkId: this.checkId,
            pageId: this.pageId,
            issueId: Number(this.issueId), // issueId is set from outside, and comes as string, due to ui-router params handling
            data: {
                check: this.issue,
            },
            sourceCodeId: this.sourceCodeId,
            issueAbbr: this.issue?.issue_abbr,

        };
        this.issueService.openInExtension(page, params);
    }

    private updateDropdown (): void {
        const dropdown: Array<ActionMenuItemType & { shouldShow?: boolean}> = [
            {
                label: this.translateService.getString('Mark as fixed'),
                leftIcon: 'faCheck',
                action: ()=>this.markAsFixedPrompt(),
                shouldShow: this.status !== this.issueStatusEnum.fixed && this.error?.type === 'error',
            },
            {
                label: this.translateService.getString('Mark as reviewed'),
                leftIcon: 'faEye',
                action: ()=>this.markAsReviewed(),
                shouldShow: this.error?.type !== 'error' && this.status !== 'reviewed',
            },
            {
                label: this.translateService.getString('Mark as ignored'),
                leftIcon: 'faEyeSlash',
                action: ()=>this.markAsIgnored(),
                shouldShow: this.status !== this.issueStatusEnum.ignored && this.error?.type === 'error',
            },
            {
                label: this.translateService.getString('Remove review'),
                leftIcon: 'faEye',
                action: ()=>this.markAsUnreviewed(),
                shouldShow: this.status === 'reviewed',
            },
            {
                label: this.translateService.getString('Unignore'),
                leftIcon: 'faEyeSlash',
                action: ()=>this.markAsUnignored(),
                shouldShow: this.status === this.issueStatusEnum.ignored,
            },
        ];

        this.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
            return {
                label: item.label,
                leftIcon: item.leftIcon,
                action: item.action,
            };
        });
    }

    private async askConfirmation (options: {question: string, ignoredReviewed: boolean}): Promise<void> {
        return this.monPromptService.prompt(
            options.question,
            {
                message: this.translateService.getString('Add reason (optional)'),
                size: 'md',
                parentSelector: '#common-dialog-wrapper',
            })
            .then(result => {
                this.changeIgnoredReviewed({ reason: result, ignoredReviewed: options.ignoredReviewed });
            })
            .catch(() => {});
    }

    private async changeIgnoredReviewed (options: {reason?: string; ignoredReviewed: boolean}): Promise<void> {
        const { reason, ignoredReviewed } = options;
        if (this.loading) {
            return;
        }
        this.loading = true;
        const cacheStatus = this.status;
        this.status = ignoredReviewed ? 'reviewed' : '';
        const params = {
            id: this.issueId,
            ignored_reviewed: ignoredReviewed,
            ...(reason != undefined && reason !== '' ? { comment: reason } : null),
        };

        try {
            await this.accessibilityIssueService.updateAccessibilityError(this.pageId, params);
            await this.getPage(true);
        } catch (e) {
            this.status = cacheStatus;
        } finally {
            this.loading = false;
        }
    }

    private getAccessibilityIssues (sourceCodeId: number): Promise<unknown> {
        return this.accessibilityIssueService.getAccessibilityIssues(sourceCodeId).then((data: IssueInterface[]) => {
            this.issues = data;
            this.issues = this.getDifficultyLevel(this.issues);
            this.issue = this.issues.find(issue => this.checkId === issue.id);
            this.issues = this.issues.filter((value) => {
                return value.id !== this.checkId;
            } );
            if (this.issues?.length === 0) {
                this.issues.total = 0;
            }
        }, ()=> {});
    }

    private getAccessibilitySnippet (sourceCodeId: number): Promise<unknown> {
        return this.accessibilityIssueService.getAccessibilitySnippet(sourceCodeId).then((data: SnippetInterface) => {
            if (data) {
                this.snippet = data;
                this.snippet.created_at = moment(this.snippet.created_at).format('DD MMM. YYYY');
                if (this.snippet?.source_code?.includes('img')) {
                    this.snippetType = this.translateService.getString('Image');
                }
            }
        }, ()=> {});
    }

    private getDifficultyLevel (issues: IssueInterface[]): IssueInterface[] {
        if (!Array.isArray(issues)) {
            return [];
        }
        return issues.map((issue) => {
            let difficulty = 'Difficult';
            if (issue && typeof issue.difficulty === 'number') {
                if (issue.difficulty > 3 && issue.difficulty <= 6) {
                    difficulty = 'Moderate';
                } else if (issue.difficulty <= 3) {
                    difficulty = 'Easy';
                }
            }

            return {
                ...issue,
                difficulty,
            };
        });
    }

    private getAccessibilityIssuePages (params: GetPagesParamsInterface): void {
        this.accessibilityIssueService.getAccessibilityIssuePages(this.checkId, params).then((data: Page[]) => {
            this.pages = data;
            this.pages = this.pages.filter((page) => {
                return page.id !== this.pageId;
            } );
        }, ()=> {});
    }

    private getPageDetails (): Promise<void> {
        return this.issueService.getPage(this.pageId).then((page: Page)=> {
            this.currentPage = page;
        }, ()=> {});
    }

    private getErrorDetails (): Promise<void> {
        const params = {
            page_size: 0,
        };

        return this.accessibilityIssueService.getAccessibilityErrors(this.pageId, this.checkId, params).then(
            (errors: AccessibilityError[])=> {
                if (Array.isArray(errors)) {
                    const currentError = errors.find(error => error.id === Number(this.issueId));

                    if (currentError) {
                        this.error = currentError;
                        if (currentError.ignored) {
                            this.status = this.error.type === 'error' ? IssueStatusEnum.ignored : 'reviewed';
                        } else {
                            this.status = '';
                        }
                    }
                }
            }, ()=> {});
    }
}
