import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.admin.auditlog').component('adminAuditlog', {
        templateUrl: 'app/pages/admin/auditlog/auditlog.html',
        controller: CustomerInfoController,
        controllerAs: 'vm',
    });

    CustomerInfoController.$inject = [
        'gettextCatalog',
        'AuditRepo',
        'activeFeatures',
        '$state',
        '$controller',
        'MonModules',
        'ng2MonEventsService',
        'exporter',
        'moment',
        'ng2SessionService',
        'userCacheService',
        'ParamService',
        '$location',
        'ng2MonModalService',
        'accessibilityService',
        'ng2ToastService',
        'AuditlogService',
        'ng2MonUIRouterTransitionService',
        'DATE_FORMAT',
    ];

    /* @ngInject */
    function CustomerInfoController (
        gettextCatalog,
        AuditRepo,
        activeFeatures,
        $state,
        $controller,
        MonModules,
        ng2MonEventsService,
        exporter,
        moment,
        ng2SessionService,
        userCacheService,
        ParamService,
        $location,
        ng2MonModalService,
        accessibilityService,
        ng2ToastService,
        AuditlogService,
        ng2MonUIRouterTransitionService,
        DATE_FORMAT,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onDateChange = onDateChange;
        vm.showChanges = showChanges;
        vm.showMore = showMore;
        vm.parseTargetURI = parseTargetURI;
        vm.isBlackListed = isBlackListed;
        vm.getModuleNameFromValue = getModuleNameFromValue;
        vm.exportCsv = exportCsv;
        vm.undoAccessibilityCheck = undoAccessibilityCheck;
        vm.IsAnUndoLog = IsAnUndoLog;
        vm.CanUndo = CanUndo;
        vm.updateDomainId = updateDomainId;
        vm.updateUserId = updateUserId;
        vm.updateModule = updateModule;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            if (!activeFeatures.audit_log) {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
            }
            vm.domains = [];
            vm.running = false;
            vm.useDate = true;
            vm.logs = [];
            vm.date = {};
            vm.dateFormat = DATE_FORMAT;
            vm.options = { maxDate: moment().toDate() };
            vm.domainIdModel = {};
            vm.userIdModel = {};
            vm.moduleModel = {};

            loadLocations();
            setupModules();
            setupDomains();
            setupUsers();
        }

        function loadLocations () {
            vm.search = $location.search().search || null;

            vm.module = $location.search().module || 'all';
            vm.domain_id = parseInt($location.search().domain_id) || 'all';
            vm.user_id = parseInt($location.search().user_id) || 'all';

            var from = $location.search().from;
            var to = $location.search().to;
            if (to) {
                vm.date.endDate = moment(to);
            } else {
                vm.date.endDate = moment().endOf('day');
            }
            if (from) {
                vm.date.startDate = moment(from);
            } else {
                vm.date.startDate = moment().subtract(30, 'days')
                    .startOf('day');
            }
            getPage();
        }

        function updateDomainId () {
            vm.domain_id = vm.domainIdModel.value;
        }

        function updateUserId () {
            vm.user_id = vm.userIdModel.value;
        }

        function updateModule () {
            vm.module = vm.moduleModel.value;
        }

        function getParams () {
            var params = {};
            params.module = vm.module === 'all' ? null : vm.module;
            params.domain_id = vm.domain_id === 'all' ? null : vm.domain_id;
            params.user_id = vm.user_id === 'all' ? null : vm.user_id;
            params.page = vm.page;
            params.page_size = vm.pageSize;
            if (vm.useDate) {
                params.from = vm.date.startDate.format();
                params.to = vm.date.endDate.format();
            }
            return params;
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                module: vm.module,
                user_id: vm.user_id,
                domain_id: vm.domain_id,
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });

            getPages();
        }

        function getPages () {
            vm.promise = AuditRepo.getAuditLogs(getParams()).then(function (data) {
                vm.logs = data;
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const log of vm.logs) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Show details'),
                        leftIcon: 'faInfoCircle',
                        action: ()=>showMore(log),
                    },
                    {
                        label: gettextCatalog.getString('Undo check review'),
                        leftIcon: 'faUndo',
                        action: ()=>undoAccessibilityCheck(log),
                    },
                ];

                log.dropdown = dropdown;
            }
        }

        function parseTargetURI (target) {
            var temp = target.substring(0, target.indexOf(':'));

            if (temp === 'PageCorrect') {
                return temp;
            }
            return temp.replace(/([A-Z])/g, ' $1').trim();
        }

        function prepareDomains (domains) {
            if (domains.length) {
                const domainsArr = domains.map(function (domain) {
                    return { name: domain.title, value: domain.id };
                });
                domainsArr.unshift({ value: 'all', name: gettextCatalog.getString('Domain') + ': ' + gettextCatalog.getString('All') });
                vm.domainIdModel = domainsArr.find(domain => domain.value === vm.domain_id);
                vm.domains = domainsArr;
            }
        }

        function setupDomains () {
            ng2SessionService.domains.subscribe(prepareDomains);
        }

        function setupUsers () {
            vm.users = [{ value: 'all', name: gettextCatalog.getString('User') + ': ' + gettextCatalog.getString('All') }];
            Object.values(userCacheService.getAll()).forEach(function (user) {
                vm.users.push({ name: user.email, value: user.id });
            });
            vm.userIdModel = vm.users.find(user => user.value === vm.user_id);
        }

        function setupModules () {
            vm.modules = [
                {
                    value: 'all',
                    name: gettextCatalog.getString('Module') + ':' + gettextCatalog.getString('All'),
                },
            ];
            vm.modules = vm.modules.concat(MonModules.getList());
            vm.moduleModel = vm.modules.find(module => module.value === vm.module);
        }

        function onDateChange () {
            vm.page = 1;
            getPage();
        }

        function showChanges (log) {
            var params = {
                body: 'adminAuditlogChanges',
                size: 'sm',
                data: {
                    identifier: log.data.identifier,
                    ngModel: log.data,
                    created: ['created', 'create'].indexOf(log.action) !== -1,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function showMore (log) {
            var params = {
                body: 'adminAuditlogMoreInfo',
                size: 'sm',
                data: {
                    data: log.data,
                    created: ['created', 'create'].indexOf(log.action) !== -1,
                    action: log.action,
                    target: log.apiTarget,
                    sourceCodeId: log.apiTargetId,
                    domainId: log.domain.id,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPages });
        }

        function isBlackListed (changes) {
            var attrs = Object.getOwnPropertyNames(changes);
            return attrs.indexOf('updated_at') !== -1 && attrs.length === 1;
        }

        function getModuleNameFromValue (value) {
            return MonModules.getModuleNameFromValue(value);
        }

        function exportCsv () {
            vm.running = true;
            var params = getParams();
            params.page_size = 100;
            delete params.page;

            return exporter
                .parseAuditLog(params, { total: vm.logs.total })
                .then(function (data) {
                    return data;
                }, angular.noop)
                .finally(function () {
                    vm.running = false;
                });
        }

        function undoAccessibilityCheck (log) {
            var sourceCodeId = log.apiTargetId;
            var msg = '<div class=\'text-line\'>' + gettextCatalog.getString('Add comment (Optional)');

            ng2MonModalService.prompt(msg).then(function (comment) {
                var params = {
                    comment: comment,
                    ignored_reviewed: false,
                };
                accessibilityService
                    .undoAccessibilityCheck(log.domain.id, sourceCodeId, log.data.check_id, params)
                    .then(function () {
                        ng2ToastService.success(gettextCatalog.getString('Snippet reset'));
                    })
                    .then(function () {
                        getPages();
                    });
            }, angular.noop);
        }

        function IsAnUndoLog (action) {
            return AuditlogService.IsAnUndoLog(action);
        }

        function CanUndo (target) {
            return AuditlogService.CanUndo(target);
        }
    }
})();
