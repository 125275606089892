(function () {
    'use strict';

    angular.module('services.api').factory('SupportRequestRepo', SupportRequestRepo);

    SupportRequestRepo.$inject = ['ng2ApiService'];
    /* @ngInject */
    function SupportRequestRepo (ng2ApiService) {
        var service = {
            create: create,
        };

        return service;

        // /////////////

        function create (params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.post('support/request', params, config);
        }
    }
})();
