(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceProfileService
     * @description Page Performance Profile Service
     */
    angular.module('modules.page-performance').service('PagePerformanceProfileService', PagePerformanceProfileService);

    PagePerformanceProfileService.$inject = [
        '$deepmerge',
        'gettextCatalog',
        'Lodash',
        'ConnectionProfileService',
        'PAGE_PERFORMANCE_LOCATIONS',
    ];

    /* @ngInject*/
    function PagePerformanceProfileService (
        $deepmerge,
        gettextCatalog,
        Lodash,
        ConnectionProfileService,
        PAGE_PERFORMANCE_LOCATIONS,
    ) {
        var devices = [
            {
                name: gettextCatalog.getString('Desktop'),
                value: 'desktop',
            },
            {
                name: gettextCatalog.getString('Mobile'),
                value: 'mobile',
            },
        ];
        return {
            getDeviceCollection: getDeviceCollection,
            getConnectionProfileName: getConnectionProfileName,
            getDeviceTranslation: getDeviceTranslation,
            getProfileCountryCode: getProfileCountryCode,
            getProfileCity: getProfileCity,
            getProfileCountry: getProfileCountry,
        };

        /**
         * @memberOf PagePerformanceProfileService
         * @desc Get Available Device collection that can be chosen for a single Profile
         */
        function getDeviceCollection () {
            return $deepmerge([], devices);
        }

        /**
         * @memberOf PagePerformanceProfileService
         * @desc Get the Connection Profile Name
         * @param profile - Profile object
         */
        function getConnectionProfileName (profile) {
            if (profile) {
                return ConnectionProfileService.getProfileName(profile);
            }
            return '';
        }

        /**
         * @memberOf PagePerformanceProfileService
         * @desc Get Device translation
         * @param profile - Profile object
         */
        function getDeviceTranslation (profile) {
            if (!profile) {
                return '';
            }

            var device = Lodash.find(devices, { value: profile.device });
            if (device) {
                return device.name;
            }
            return '';
        }

        /**
         * @memberOf PagePerformanceProfileService
         * @desc Get Profile Country Code
         * @param profile - Profile object
         */
        function getProfileCountryCode (profile) {
            if (!profile) {
                return '';
            }

            var pagePerformanceLocation = PAGE_PERFORMANCE_LOCATIONS[profile.location];
            if (pagePerformanceLocation) {
                return pagePerformanceLocation.country_code;
            }
            return '';
        }

        /**
         * @memberOf PagePerformanceProfileService
         * @desc Get the Profile City Translation
         * @param profile - Profile object
         */
        function getProfileCity (profile) {
            if (!profile) {
                return '';
            }

            var pagePerformanceLocation = PAGE_PERFORMANCE_LOCATIONS[profile.location];
            if (pagePerformanceLocation) {
                return pagePerformanceLocation.city;
            }
            return '';
        }

        /**
         * @memberOf PagePerformanceProfileService
         * @desc Get the Profile Country Translation
         * @param profile - Profile object
         */
        function getProfileCountry (profile) {
            if (!profile) {
                return '';
            }

            var pagePerformanceLocation = PAGE_PERFORMANCE_LOCATIONS[profile.location];
            if (pagePerformanceLocation) {
                return pagePerformanceLocation.country;
            }
            return '';
        }
    }
})();
