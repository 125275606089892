(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionDashboard', {
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/dashboard.html',
        controller: SectionInventoryController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
            updatePage: '&',
            languages: '<',
        },
    });

    SectionInventoryController.$inject = [
        'ng2SessionService',
        'MON_MODULES',
        'Lodash',
        'gettextCatalog',
        '$filter',
        'coreLoginService',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function SectionInventoryController (
        ng2SessionService,
        MON_MODULES,
        Lodash,
        gettextCatalog,
        $filter,
        coreLoginService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.navigateTo = navigateTo;
        vm.$onInit = activate;
        vm.getLanguageName = getLanguageName;
        vm.onUpdatePage = onUpdatePage;

        function activate () {
            vm.showLanguageForm = false;
            vm.page = vm.page || {};
            vm.showStatistics = false;
            vm.showAccessibility = false;
            vm.showQA = ng2ActiveFeatureService.isFeatureActivePure('qa_links') || ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck');
            vm.showSEO = ng2ActiveFeatureService.isFeatureActivePure('seo_all');
            vm.showPolicy = ng2ActiveFeatureService.isFeatureActivePure('policies_all');
            vm.modules = MON_MODULES;
            vm.authUser = null;
            vm.loading = true;
            vm.domain = ng2SessionService.domain;
            coreLoginService
                .getUser()
                .then(function (user) {
                    vm.loading = false;
                    vm.authUser = user === null ? ng2SessionService.me : user;
                }, angular.noop)
                .finally(function () {
                    if (ng2SessionService.domain !== null) {
                        vm.showStatistics = ng2SessionService.domain.features.statistics || $filter('monCustomerHasPlan')('demo');
                        vm.showAccessibility = ng2SessionService.domain.features.accessibility;
                    }
                });
        }

        function navigateTo (params) {
            vm.onClickHandler({ params: params });
        }

        function onUpdatePage () {
            vm.showLanguageForm = false;
            vm.updatePage({ page: vm.page });
        }

        function getLanguageName () {
            if (vm.page.language === null) {
                return gettextCatalog.getString('NA');
            }

            var index = Lodash.findIndex(vm.languages, function (language) {
                return language.code === vm.page.language;
            });

            if (index === -1) {
                return gettextCatalog.getString('NA');
            }

            return vm.languages[index].name;
        }
    }
})();
