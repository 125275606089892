(function () {
    'use strict';
    angular.module('app.components').component('monComments', {
        templateUrl: 'app/components/comments/comments.html',
        controller: MonCommentsController,
        controllerAs: 'vm',
        bindings: {
            subjectType: '@', // Model name of the element Ask @tlw or @jenssoren for specifics
            subjectId: '<', // Id of the element, such as Accessibility check, policy, SEO issue, etc.
            scrollContainer: '@', // Container to track scroll on
            pageSize: '<?',
        },
    });

    MonCommentsController.$inject = ['$controller', 'CommentRepo', '$timeout'];
    /* @ngInject */
    function MonCommentsController ($controller, CommentRepo, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.addComment = addComment;
        vm.getPage = getPage;
        vm.getNextPage = getNextPage;

        function activate () {
            vm.page = 1;
            vm.pageSize = vm.pageSize || 8;
            vm.comments = [];
            vm.commentsBusy = false;
        }

        function getPage () {
            vm.commentsBusy = true;
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                subject_type: vm.subjectType,
                subject_id: vm.subjectId,
            };
            var commentLength = vm.comments.length;

            vm.progress = CommentRepo.getAll(params)
                .then(function (comments) {
                    vm.comments = distinctComments(comments).sort(function (a, b) {
                        return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                    });
                }, angular.noop)
                .finally(function () {
                    var delay = 1500;
                    if (vm.comments.length < commentLength + vm.pageSize) {
                        vm.page = vm.page - 1;
                        delay = 8000;
                    }
                    $timeout(function () {
                        vm.commentsBusy = false;
                    }, delay);
                });
        }

        function distinctComments (comments) {
            var currentComments = vm.comments;
            comments.forEach(function (comment) {
                var hasComment = currentComments.some(function (currentComment) {
                    return currentComment.id === comment.id;
                });

                if (!hasComment) {
                    currentComments.push(comment);
                }
            });
            return currentComments;
        }

        function getNextPage () {
            if (vm.commentsBusy) {
                return;
            }
            vm.page = vm.page + 1;
            vm.getPage();
        }

        function addComment (comment) {
            vm.comments.push(comment);
            vm.comments = vm.comments.sort(function (a, b) {
                return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
            });
        }
    }
})();
