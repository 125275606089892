import {
    CmsExtra,
    CrawlHistory,
    DomainGroupsEntity,
    DomainInterface,
    Features,
    LabelsEntity,
    LinkExcludesEntity,
    PageAssistSettings,
    PathConstraintsEntity,
    Scan,
    Settings,
} from '@monsido/modules/models/api/interfaces/domain.interface';
import { User } from './user';

// eslint-disable-next-line @typescript-eslint/no-shadow
export enum DomainType { Domain = 'Domain', DomainGroup = 'DomainGroup'}

export class Domain implements DomainInterface {
    active: boolean;
    id: number;
    customer_id: number;
    title: string;
    url: string;
    timezone: string;
    cms: string;
    language: string;
    crawl_status?: null;
    crawl_history?: CrawlHistory;
    features: Features;
    scan: Scan;
    path_constraints: PathConstraintsEntity[];
    link_excludes: LinkExcludesEntity[];
    domain_groups?: DomainGroupsEntity[];
    running_groups: boolean;
    cms_extra: CmsExtra;
    additional_internal_urls?: [{
        operator: string;
        value: string;
    }];
    page_assist_settings?: PageAssistSettings;
    created_at: string;
    updated_at: string;
    favorite: boolean;
    monsido_script_installed?: boolean;
    labels?: LabelsEntity[];
    users?: User[];
    settings?: Settings;

    constructor (domain: DomainInterface) {
        this.active = domain.active;
        this.id = domain.id;
        this.customer_id = domain.customer_id;
        this.title = domain.title;
        this.url = domain.url;
        this.timezone = domain.timezone;
        this.cms = domain.cms;
        this.language = domain.language;
        this.crawl_status = domain.crawl_status;
        this.crawl_history = domain.crawl_history;
        this.features = domain.features;
        this.scan = domain.scan;
        this.path_constraints = domain.path_constraints;
        this.link_excludes = domain.link_excludes;
        this.domain_groups = domain.domain_groups;
        this.running_groups = domain.running_groups;
        this.cms_extra = domain.cms_extra;
        this.additional_internal_urls = domain.additional_internal_urls;
        this.page_assist_settings = domain.page_assist_settings;
        this.created_at = domain.created_at;
        this.updated_at = domain.updated_at;
        this.favorite = domain.favorite;
        this.monsido_script_installed = domain.monsido_script_installed;
        this.labels = domain.labels;
        this.settings = domain.settings;
    }
}
