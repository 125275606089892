(function () {
    'use strict';

    angular.module('services.api').factory('UserRepo', UserRepo);

    UserRepo.$inject = ['ng2ApiService', 'User', 'ng2SessionService', 'ng2MonEventsService'];
    /* @ngInject */
    function UserRepo (ng2ApiService, User, ng2SessionService, ng2MonEventsService) {
        var service = {
            update: update,
            create: create,
            getAll: getAll,
            destroy: destroy,
            get: get,
        };

        return service;

        // /////////////

        function destroy (user, config) {
            config = config || {};
            return ng2ApiService.delete('users/' + user.id, {}, config).then(function () {
                ng2MonEventsService.run('userDeleted', user);
            }, angular.noop);
        }

        function create (params, config) {
            config = config || {};
            return ng2ApiService.post('users', params, config).then(function (user) {
                ng2MonEventsService.run('userAdded', user);
                return toModel(user);
            }, angular.noop);
        }

        function get (id, params, config) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config = config || {};
            return ng2ApiService.get('users/' + id, params, config).then(toModel);
        }

        function update (params, config) {
            config = config || {};
            return ng2ApiService.patch('users/' + params.id, params, null, config).then(toModel);
        }

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('users', params, config).then(toModels);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new User(model);
        }
    }
})();
