(function () {
    'use strict';

    angular.module('app.components.documentDetails').component('documentDetailsSectionDashboard', {
        templateUrl: 'app/components/documentdetails/details/dashboard/dashboard.html',
        controller: SectionDashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            document: '<',
            callback: '&',
            updatePage: '&',
            languages: '<',
        },
    });

    SectionDashboardController.$inject = [
        'ng2SessionService',
        'MON_MODULES',
        'Lodash',
        'gettextCatalog',
        'coreLoginService',
        'ng2DemoHasAccessService',
    ];
    /* @ngInject */
    function SectionDashboardController (ng2SessionService, MON_MODULES, Lodash, gettextCatalog, coreLoginService, ng2DemoHasAccessService) {
        var vm = this;
        vm.doCallback = doCallback;
        vm.$onInit = activate;
        vm.getLanguageName = getLanguageName;
        vm.onUpdatePage = onUpdatePage;
        vm.hasClarityAccess = hasClarityAccess;

        function activate () {
            vm.showLanguageForm = false;
            vm.page = vm.page || {};
            vm.showStatistics = false;
            vm.showAccessibility = false;
            vm.modules = MON_MODULES;
            vm.authUser = null;
            vm.loading = true;
            vm.domain = ng2SessionService.domain;
            coreLoginService
                .getUser()
                .then(function (user) {
                    vm.loading = false;
                    vm.authUser = user === null ? ng2SessionService.me : user;
                }, angular.noop)
                .finally(function () {
                    if (ng2SessionService.domain !== null) {
                        vm.showStatistics = ng2SessionService.domain.features.statistics;
                        vm.showAccessibility = ng2SessionService.domain.features.accessibility;
                    }
                });
        }

        function doCallback (params) {
            vm.callback({ params: params });
        }

        function onUpdatePage () {
            vm.showLanguageForm = false;
            vm.updatePage({ page: vm.page });
        }

        function getLanguageName () {
            if (vm.page.language === null) {
                return gettextCatalog.getString('NA');
            }

            var index = Lodash.findIndex(vm.languages, function (language) {
                return language.code === vm.page.language;
            });

            if (index === -1) {
                return gettextCatalog.getString('NA');
            }

            return vm.languages[index].name;
        }

        function hasClarityAccess () {
            var clarity = Boolean(ng2SessionService.customer && ng2SessionService.customer.plan_traits.clarity_pdf_accessibility);
            return ng2DemoHasAccessService.canAccessAccessibilityPDF() || clarity;
        }
    }
})();
