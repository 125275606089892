<form #sendMeReportForm="ngForm" (ngSubmit)="sendMeReportForm.valid && save()">
    <div class="mon-dialog-header">
        <div class="outer-t-smurf">
            <mon-page-header header="{{'Report' | translate}}"
                             panelLeftClass="col-48 outer-t-mini">
            </mon-page-header>
        </div>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card panel-monsido-card outer-t outer-r outer-l">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-3">
                    <div class="text-ellipsis" *ngIf="report">
                        <h3 class="mt-0 text-ellipsis">{{report.template.title}}</h3>
                        <div class="label ruler-info font-size-1-1">{{report.template.name | translateReport}}</div>
                    </div>
                    <div>
                        <mon-table-search [containerClass]="'px-3 inline-block'"
                             [model]="search"
                             (getPage)="onSearch($event)"></mon-table-search>
                    </div>
                </div>
                <h4 class="card-title d-flex justify-content-between align-items-center">
                    {{'Select a domain or group' | translate}}
                </h4>
                <div [class]="{loading}">
                    <mon-spinner *ngIf="promise" [progress]="promise">
                        <div class="domains-container" *ngFor="let domain of domains; index as i">
                            <div class="domain col-48 d-flex">
                                <input type="radio"
                                       [name]="domain.title"
                                       class="radio-button pull-left"
                                       [(ngModel)]="targetDomain"
                                       [value]="{type: domainType.Domain, id: domain.id}" />
                                <mon-url-title [title]="domain.title"
                                       [url]="domain.url"
                                       [active]="false"></mon-url-title>
                            </div>

                            <ng-container *ngIf="domain.domain_groups">
                                <div class="domain-group"
                                     *ngFor="let domainGroup of domain.domain_groups">
                                    <input type="radio"
                                           [name]="domainGroup.title"
                                           class="radio-button pull-left"
                                           [(ngModel)]="targetDomain"
                                           [value]="{type: domainType.DomainGroup, id: domainGroup.id}">
                                    <mon-icon [icon]="['ACTIONS', 'CLONE']"
                                           [size]="'2x'"
                                           class="visibility-45 pull-left ml-1"></mon-icon>
                                    <a class="domain-group-title inner-t-mini primary-text-grey inline-block">{{domainGroup.title}}</a>
                                </div>
                            </ng-container>
                        </div>
                    </mon-spinner>
                </div>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer">
        <div class="row">
            <div class="col-md-48 text-right">
                <button [disabled]="saving"
                        class="btn btn-border-radius btn-secondary"
                        type="submit"
                        data-test="save-button"
                >
                    <span *ngIf="!saving">
                        {{'Save' | translate}}
                    </span>
                    <span aria-hidden="true" *ngIf="saving">
                        <mon-icon [spin]="true" [icon]="['SPINNER', 'ICON']"></mon-icon>
                    </span>
                </button>
            </div>
        </div>
    </div>
</form>
