(function () {
    'use strict';

    angular.module('pages.domain').component('pageDetailsDashboardChartsSeo', {
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/charts/seo/seo.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
        },
    });

    DashboardController.$inject = ['SeoRepo', '$filter', 'PageRepo', 'PAGE_DETAILS_SECTION_CONSTANT'];
    /* @ngInject */
    function DashboardController (SeoRepo, $filter, PageRepo, PAGE_DETAILS_SECTION_CONSTANT) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.issuesOnThisPage = 0;
            vm.pageDetailsSectionSeo = PAGE_DETAILS_SECTION_CONSTANT.SEO;
            setupDoughnutChart();
            getSeoIssues();
        }

        function setupDoughnutChart () {
            var params = {
                page_id: vm.page.id,
                page: 1,
                page_size: 1,
            };
            vm.doughnutLabels = ['', ''];
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            SeoRepo.getAll(params).then(function (data) {
                vm.totalSeoIssues = data.total;
                vm.percentage = (vm.page.seo_issues_count / vm.totalSeoIssues) * 100;
                vm.doughnutData = [vm.totalSeoIssues - vm.page.seo_issues_count, vm.page.seo_issues_count];
                vm.doughnutOptions = {
                    centerText: {
                        percentageLabel: $filter('monNumber')((100 - vm.percentage) / 100, '0.[00] %'),
                    },
                    cutoutPercentage: 80,
                    tooltips: {
                        enabled: vm.totalSeoIssues > 0,
                        callbacks: {
                            beforeLabel: function (tooltipItem, data) {
                                return '';
                            },
                            label: function (tooltipItem, data) {
                                return $filter('numeraljs')(
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                    '0,0',
                                );
                            },
                        },
                    },
                };
            }, angular.noop);
        }

        function getSeoIssues () {
            vm.seoClassifications = {
                '1': 0,
                '2': 0,
                '3': 0,
                '4': 0,
            };

            PageRepo.getSeoIssues(vm.page.id).then(function (data) {
                var pageSeoIssues = data.filter(function (seo) {
                    var issueNotFixed = seo.issues.find(function (seoIssue) {
                        // Find at least one issue that is not ignored or fixed
                        return seoIssue.marked_as_fixed_at === null && seoIssue.ignored_at === null;
                    });

                    return angular.isObject(issueNotFixed);
                });
                pageSeoIssues.forEach(function (seo) {
                    vm.seoClassifications[seo.classification] += 1;
                });
                vm.issuesOnThisPage = pageSeoIssues.length;
            }, angular.noop);
        }
    }
})();
