export const REGIONS = [
    {
        name: 'Global',
        value: 'global',
    },
    {
        name: 'Argentina',
        value: 'argentina',
    },
    {
        name: 'Australia',
        value: 'australia',
    },
    {
        name: 'Belgium',
        value: 'belgium',
    },
    {
        name: 'Brazil',
        value: 'brazil',
    },
    {
        name: 'Canada',
        value: 'canada',
    },
    {
        name: 'Chile',
        value: 'chile',
    },
    {
        name: 'China',
        value: 'china',
    },
    {
        name: 'Colombia',
        value: 'colombia',
    },
    {
        name: 'Denmark',
        value: 'denmark',
    },
    {
        name: 'France',
        value: 'france',
    },
    {
        name: 'Finland',
        value: 'finland',
    },
    {
        name: 'Germany',
        value: 'germany',
    },
    {
        name: 'Hong Kong',
        value: 'hong_kong',
    },
    {
        name: 'India',
        value: 'india',
    },
    {
        name: 'Indonesia',
        value: 'indonesia',
    },
    {
        name: 'Israel',
        value: 'israel',
    },
    {
        name: 'Ireland',
        value: 'ireland',
    },
    {
        name: 'Italy',
        value: 'italy',
    },
    {
        name: 'Japan',
        value: 'japan',
    },
    {
        name: 'Korea',
        value: 'korea',
    },
    {
        name: 'Mexico',
        value: 'mexico',
    },
    {
        name: 'The Netherlands',
        value: 'the_netherlands',
    },
    {
        name: 'Norway',
        value: 'norway',
    },
    {
        name: 'Paraguay',
        value: 'paraguay',
    },
    {
        name: 'Peru',
        value: 'peru',
    },
    {
        name: 'Poland',
        value: 'poland',
    },
    {
        name: 'Portugal',
        value: 'portugal',
    },
    {
        name: 'Singapore',
        value: 'singapore',
    },
    {
        name: 'Spain',
        value: 'spain',
    },
    {
        name: 'Sweden',
        value: 'sweden',
    },
    {
        name: 'Taiwan',
        value: 'taiwan',
    },
    {
        name: 'Thailand',
        value: 'thailand',
    },
    {
        name: 'Turkey',
        value: 'turkey',
    },
    {
        name: 'United Kingdom',
        value: 'united_kingdom',
    },
    {
        name: 'United States',
        value: 'united_states',
    },
    {
        name: 'Uruguay',
        value: 'uruguay',
    },
    {
        name: 'Venezuela',
        value: 'venezuela',
    },
];

