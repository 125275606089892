import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { SessionService } from '@monsido/core/session/session.service';
import { Label } from '@monsido/angular-shared-components/dist/angular-shared-components';

@Injectable({
    providedIn: 'root',
})
export class LabelRepo {
    constructor (private client: ApiClient,
        private sessionService: SessionService) {}


    getLabels (params: Record<string, string | boolean | number | null>): Promise<Label[]> {
        params = params || {};
        params.page_size = params.page_size || 0;
        params.group_id = this.sessionService.getGroupId(params);
        if (params.group_id == null) {
            delete params.group_id;
        }
        return this.client.getPromise('labels', params);
    }

    createLabel (params: Record<string, string | boolean | number>): Promise<void> {
        return this.client.postPromise('labels', params);
    }

    deleteLabel (params: Record<string, string | boolean | number>): Promise<void> {
        return this.client.deletePromise(`labels/${params.label_id}`, params);
    }

    updateLabel (params: Record<string, string | boolean | number>): Promise<void> {
        return this.client.patchPromise(`labels/${params.labelId}`, params);
    }

    getDomainGroupsUsingLabel (params: Record<string, string | boolean | number>): Promise<Label[]> {
        return this.client.getPromise(`labels/${params.labelId}/domains_with_blocking_groups`, params);
    }
}
