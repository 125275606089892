(function () {
    'use strict';

    angular.module('modules.data_privacy.services.api.demo-data').factory('DataPrivacyDemoViolationRepo', DataPrivacyViolationRepo);

    DataPrivacyViolationRepo.$inject = ['$q', 'DataPrivacyViolationModel'];
    /* @ngInject */
    function DataPrivacyViolationRepo ($q, DataPrivacyViolationModel) {
        var vm = this;
        function init () {
            generateData();
            return {
                getAll: getAll,
                get: get,
                update: update,
            };
        }

        return init();

        // /////////////

        function getAll (params) {
            params = params || {};
            if (params.priority) {
                return $q.resolve(
                    toModels(
                        vm.violations.filter(function (elem) {
                            return elem.info_type.priority === params.priority;
                        }),
                    ),
                );
            }
            return $q.resolve(toModels(vm.violations));
        }

        function get (page_id, violation_id) {
            var violation = vm.violations.find(function (element) {
                return element.id === violation_id;
            });

            return $q.resolve(toModel(violation));
        }

        function update (page_id, violation) {
            return $q.resolve(toModel(violation));
        }
        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new DataPrivacyViolationModel(model);
        }

        function generateData () {
            vm.violations = [
                {
                    id: 1,
                    content: '4586 2156 8770 0135',
                    identificator: 'CREDIT_CARD_NUMBER',
                    likelihood: 'VERY_LIKELY',
                    info_type: {
                        id: 1,
                        identificator: 'CREDIT_CARD_NUMBER',
                        name: 'Credit card number',
                        priority: 'medium',
                        group: 'banking_data',
                        region: ['global'],
                        regulations: ['gdpr', 'ccpa'],
                    },
                },
                {
                    id: 2,
                    content: '1234 5678 9012 3456',
                    identificator: 'CREDIT_CARD_NUMBER',
                    likelihood: 'VERY_LIKELY',
                    info_type: {
                        id: 1,
                        identificator: 'CREDIT_CARD_NUMBER',
                        name: 'Credit card number',
                        priority: 'high',
                        group: 'banking_data',
                        region: ['global'],
                        regulations: ['gdpr', 'ccpa'],
                    },
                },
                {
                    id: 3,
                    content: '010160-8869',
                    identifier: 'DENMARK_CPR_NUMBER',
                    likelihood: 'LIKELY',
                    info_type: {
                        id: 2,
                        identifier: 'DENMARK_CPR_NUMBER',
                        name: 'Danish Personal Identification Number',
                        priority: 'medium',
                        group: 'general_personal_data',
                        regions: ['denmark', 'europe'],
                        regulations: ['gdpr'],
                    },
                },
                {
                    id: 5,
                    content: 'C7-92-D6-68-B1-CB',
                    identifier: 'MAC_ADDRESS',
                    likelihood: 'POSSIBLE',
                    info_type: {
                        id: 38,
                        identifier: 'MAC_ADDRESS',
                        name: 'MAC Address',
                        priority: 'low',
                        group: 'technical',
                        regions: ['global'],
                        regulations: [],
                    },
                },
            ];
        }
    }
})();
