import { Component, OnInit, Inject } from '@angular/core';
import { RolePipe } from '@monsido/core/pipes/role/role.pipe';
import { Report } from '@monsido/modules/models/api/report';
import { ReportRepo } from '../../../../angularjs-providers/report-repo/report-repo.types';
import { ReportCenterDashboardService } from '../../services/dashboard/report-center-dashboard.service';
import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'mon-report-center-dashboard',
    templateUrl: './report-center-dashboard.component.html',
    styleUrls: ['./report-center-dashboard.component.scss'],
})
export class ReportCenterDashboardComponent implements OnInit {
    subHeader: string;
    monHref: string = 'https://help.monsido.com/en/articles/4885326-report-center';
    reports: Report[] = [];
    loading: boolean = false;
    scheduledReports: Report[] = [];

    constructor (
        private translateService: TranslateService,
        private monRole: RolePipe,
        private dashboardService: ReportCenterDashboardService,
        private activeFeatureService: ActiveFeatureService,
        @Inject('ReportRepo') private reportRepo: ReportRepo,
    ) {
        const adminText = this.monRole.transform(['customer_admin']) ? 'or other users' : '';
        this.subHeader = this.translateService.getString('Schedule reports for yourself [[adminText]] by email after every Monsido scan', {
            adminText: adminText,
        });
    }

    ngOnInit (): void {
        this.getPage();
    }

    getPage (): void {
        this.loading = true;
        this.reportRepo.get().then((reports) => {
            this.reports = reports.filter(report => {
                const qaSummaryTrait = this.activeFeatureService.isFeatureActive('qa_summary');
                const accessibilitySummaryTrait = this.activeFeatureService.isFeatureActive('accessibility_summary');
                if (report.template.name.startsWith('qa') && qaSummaryTrait) {
                    return report.template.name === 'qa_summary';
                }

                if ((report.template.name.startsWith('accessibility') || report.template.name.startsWith('pdf')) && accessibilitySummaryTrait) {
                    return report.template.name === 'accessibility_summary';
                }

                if (report.template.name.startsWith('uptimes')) {
                    return this.activeFeatureService.isFeatureActive('admin_heartbeat');
                }

                if (report.template.name.startsWith('stats')) {
                    return this.activeFeatureService.isFeatureActive('admin_statistics');
                }

                if (report.template.name.startsWith('heatmaps')) {
                    return this.activeFeatureService.isFeatureActive('heatmaps');
                }

                return true;
            });

            this.scheduledReports = this.reports.filter(report => {
                const users = this.dashboardService.getUniqueUsersByIdFromReport(report);
                return this.dashboardService.isReceivingReport(users);
            });
        })
            .finally(() => {
                this.loading = false;
            });
    }

}
