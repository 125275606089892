(function () {
    'use strict';
    /**
     * Filter
     *
     * @memberOf filters.monsido
     * @ngdoc filter
     * @name searchDomain
     * @description Filter domains list by search string
     */
    angular.module('filters.monsido').filter('searchDomain', searchObj);

    searchObj.$inject = ['searchService'];
    /* @ngInject */
    function searchObj (searchService) {
        return searchInObj;

        // //////////////////
        /**
         * @memberOf searchDomain
         * @desc Filter domains collection by search
         * @param {Array} input
         * @param {String} search
         * @param {Function} fn - OPTIONAL: Function to execute with the input result
         * @return {Array}
         */
        function searchInObj (input, search, fn) {
            if (!input || !search) {
                return input;
            }

            var result = input.filter(function (domain) {
                if (domain.type === 'Domain') {
                    return hasDomainGroups(domain.domain_groups, search) || hasDomain(domain, search);
                } else {
                    return hasDomainGroup(domain, search);
                }
            });

            if (typeof fn === 'function') {
                fn(result);
            }

            return result;
        }

        /**
         * @memberOf searchDomain
         * @desc Check if domain matches search
         * @param {Object} domain
         * @param {String} search
         * @return {Boolean}
         */
        function hasDomain (domain, search) {
            return searchService.fuzzy(search, domain.title) || searchService.fuzzy(search, domain.url);
        }

        /**
         * @desc Check if domain has domain groups that matches search
         * @param {Array} groups
         * @param {String} search
         * @return {Boolean}
         */
        function hasDomainGroups (groups, search) {
            if (!groups) {
                return false;
            }
            return groups.some(function (domainGroup) {
                return hasDomainGroup(domainGroup, search);
            });
        }

        /**
         * @desc Check if domain group matches search
         * @param {Object} domainGroup
         * @param {String} search
         * @return {Boolean}
         */
        function hasDomainGroup (domainGroup, search) {
            return searchService.fuzzy(search, domainGroup.title);
        }
    }
})();
