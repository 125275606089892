(function () {
    angular.module('services').service('MonModules', ModulesController);

    ModulesController.$inject = ['ng2ActiveFeatureService', 'ng2SessionService'];

    function ModulesController (ng2ActiveFeatureService, ng2SessionService) {
        var moduleList = [
            {
                value: 'accessibility',
                name: 'Accessibility',
                hasAccess: ng2ActiveFeatureService.isFeatureActive('admin_accessibility'),
            },
            {
                value: 'settings',
                name: 'Settings',
                hasAccess: true,
            },
            {
                value: 'qa',
                name: 'Quality Assurance',
                hasAccess: true,
            },
            {
                value: 'policies',
                name: 'Policies',
                hasAccess: true,
            },
            {
                value: 'seo',
                name: 'SEO',
                hasAccess: true,
            },
            {
                value: 'page_correct',
                name: 'PageCorrect',
                hasAccess: ng2ActiveFeatureService.isFeatureActive('admin_page_fix'),
            },
            {
                value: 'data_protection',
                name: 'Data privacy',
                hasAccess: ng2SessionService.customer !== null &&
                    ng2SessionService.customer.plan_traits &&
                    ng2SessionService.customer.plan_traits.data_protection,
            },
            {
                value: 'report',
                name: 'Report',
                hasAccess: true,
            },
            {
                value: 'uptime',
                name: 'Heartbeat',
                hasAccess: ng2SessionService.customer !== null &&
                    ng2SessionService.customer.plan_traits &&
                    ng2SessionService.customer.plan_traits.heartbeat,
            },
            {
                value: 'user',
                name: 'User',
                hasAccess: true,
            },
            {
                value: 'api_users',
                name: 'API Users',
                hasAccess: ng2ActiveFeatureService.isFeatureActive('api_users'),
            },
            {
                value: 'single_page_scan',
                name: 'Single Page Scan',
                hasAccess: true,
            },
            {
                value: 'core',
                name: 'Core',
                hasAccess: true,
            },
        ];

        moduleList = moduleList.filter(function (module) {
            return module.hasAccess;
        });

        function getList () {
            return moduleList.sort(function (a, b) {
                var aShort = a.name.toLowerCase();
                var bShort = b.name.toLowerCase();
                return aShort < bShort ? -1 : bShort < aShort ? 1 : 0;
            });
        }

        function getModule (name) {
            var module = moduleList.find(function (module) {
                return module.value === name;
            });
            return module ? module : {};
        }

        function getModuleNameFromValue (value) {
            var modul = moduleList.find(function (module) {
                return module.value === value;
            });
            return modul ? modul.name : '';
        }

        return {
            getList: getList,
            getModule: getModule,
            getModuleNameFromValue: getModuleNameFromValue,
        };
    }
})();
