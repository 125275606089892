(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name QALineChartService
     * @description
     * Create the necessary objects to shape the QA line chart
     */
    angular.module('blocks.service').factory('QALineChartService', QALineChartService);

    QALineChartService.$inject = ['$filter', 'gettextCatalog', 'LineChart', 'ChartsDataHelper', 'moment', 'LegendCursorService'];

    function QALineChartService ($filter, gettextCatalog, LineChart, ChartsDataHelper, moment, LegendCursorService) {
        var vm = this;

        /**
         * @memberOf QALineChartService
         * @description Initialize the QALineChartService factory
         */
        function init () {
            vm.lineChartSettings = new LineChart();

            return {
                getOption: getOption,
                getColors: getColors,
                getSeries: getSeries,
                getOverride: getOverride,
                getLabels: getLabels,
                getData: getData,
            };
        }

        return init;

        /**
         * @memberOf QALineChartService
         * @description The option object for the QA line chart
         */
        function getOption () {
            vm.lineChartSettings.setTooltipSettings({
                intersect: false,
                mode: 'x-axis',
                callbacks: {
                    label: function (tooltipItem, data) {
                        var result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
                        return (
                            data.datasets[tooltipItem.datasetIndex].label + ': ' + $filter('numeraljs', '0,0')(result)
                        );
                    },
                    title: function (tooltipItem, data) {
                        return moment(tooltipItem[0].xLabel).format('MMM DD');
                    },
                },
            });

            vm.lineChartSettings.setLegendSettingsOverride({
                display: true,
                position: 'bottom',
                labels: {
                    fontSize: 11,
                    boxWidth: 10,
                },
            });

            vm.lineChartSettings.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Issues'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Content'),
                            fontSize: 11,
                        },
                    },
                ],
                xAxes: [
                    {
                        id: 'x-axis-0',
                        type: 'time',
                        gridLines: {
                            offsetGridLines: true,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            maxTicksLimit: 30,
                        },
                        time: {
                            minUnit: 'day',
                            displayFormats: {
                                // Since the scans always only appear once or twice a week, there's no need for any other date formats to be displayed
                                millisecond: 'MMM D',
                                second: 'MMM D',
                                minute: 'MMM D',
                                hour: 'MMM D',
                                day: 'MMM D',
                                week: 'MMM D',
                                month: 'MMM D',
                                quarter: 'MMM D',
                                year: 'MMM D',
                            },
                        },
                    },
                ],
            });
            vm.lineChartSettings.options = LegendCursorService.setHoverState(vm.lineChartSettings.options);
            return vm.lineChartSettings.options;
        }

        /**
         * @memberOf QALineChartService
         * @description The colors for the QA line chart
         */
        function getColors () {
            vm.lineChartSettings.addColors(['#da5c5b', '#b960e2', '#ea9a5c', '#ff9d4e', '#a9dad6']);
            return vm.lineChartSettings.colors;
        }

        /**
         * @memberOf QALineChartService
         * @description The series for the QA line chart
         */
        function getSeries () {
            vm.lineChartSettings.addSeries([
                gettextCatalog.getString('Broken links'),
                gettextCatalog.getString('Broken images'),
                gettextCatalog.getString('Misspellings'),
                gettextCatalog.getString('Content with issues'),
                gettextCatalog.getString('Scanned content'),
            ]);
            return vm.lineChartSettings.series;
        }

        /**
         * @memberOf QALineChartService
         * @description The override object for the QA line chart
         */
        function getOverride () {
            vm.lineChartSettings.addOverrides([
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                },
                {
                    yAxisID: 'y-axis-1',
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: true,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    borderDash: [5, 5],
                    pointHitRadius: 34,
                },
                {
                    yAxisID: 'y-axis-1',
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: true,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    borderDash: [5, 5],
                    pointHitRadius: 34,
                },
            ]);

            return vm.lineChartSettings.override;
        }

        /**
         * @memberOf QALineChartService
         * @description The labels for the QA line chart
         */
        function getLabels (crawls) {
            crawls = crawls || [];
            return crawls.map(function (crawl) {
                return new Date(crawl.date).getTime();
            });
        }

        /**
         * @memberOf QALineChartService
         * @description The data object for the QA line chart
         */
        function getData (crawls) {
            var helper = new ChartsDataHelper('time');
            return [
                crawls.map(function (crawl) {
                    return helper.setData(crawl, 'dead_links_count');
                }),
                crawls.map(function (crawl) {
                    return helper.setData(crawl, 'dead_images_count');
                }),
                crawls.map(function (crawl) {
                    return helper.setData(crawl, 'spelling_errors_confirmed_count');
                }),
                crawls.map(function (crawl) {
                    return helper.setData(crawl, 'pages_with_notifications_count');
                }),
                crawls.map(function (crawl) {
                    return helper.setData(crawl, ['documents_count', 'page_count']);
                }),
            ];
        }
    }
})();
