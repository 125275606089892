(function () {
    'use strict';

    angular.module('modules.demodata.endpoints').factory('DemoHeatmapsRepo', DemoHeatmapsRepo);

    DemoHeatmapsRepo.$inject = ['$q', '$timeout', 'PageRepo', 'Lodash', '$deepmerge', 'moment'];

    /* @ngInject */
    function DemoHeatmapsRepo ($q, $timeout, PageRepo, Lodash, $deepmerge, moment) {
        var vm = this;

        function init () {
            vm.pk = 1;
            generateData();

            return {
                getAll: getAll,
                update: update,
                destroy: destroy,
                create: create,
                getAllFromCustomer: getAllFromCustomer,
            };
        }

        return init();

        // /////////////

        function getAll (domainId, params) {
            params = params || {};
            params.domain_id = domainId;
            return getData('getAll', params);
        }

        function update (domainId, model, params) {
            return updateData(domainId, model, params);
        }

        function destroy (domainId, ruleId, params) {
            params = params || {};
            params.rule_id = ruleId;
            params.domain_id = domainId;
            return destroyData(domainId, ruleId, params);
        }

        function create (domainId, params) {
            return createData(domainId, params);
        }

        function getAllFromCustomer (params) {
            return getData('getAllFromCustomer', params);
        }

        // PROTECTED

        function getData (fnName, params) {
            var defer = $q.defer();
            delay(function () {
                switch (fnName) {
                    case 'getAll':
                        defer.resolve(getAllData(params));
                        break;
                    case 'getAllFromCustomer':
                        defer.resolve(getAllFromCustomerData(params));
                        break;
                    default:
                        defer.resolve([]);
                }
            });
            return defer.promise;
        }

        // PROTECTED

        function getAllData (params) {
            return paginate(vm.rules, params);
        }

        function updateData (domainId, model) {
            var defer = $q.defer();
            delay(function () {
                var index = Lodash.findIndex(vm.rules, { id: model.id, domain_id: domainId });
                if (index > -1) {
                    vm.rules[index] = $deepmerge(vm.rules[index], model);
                    defer.resolve(vm.rules[index]);
                }
                defer.reject();
            });
            return defer.promise;
        }

        function createData (domainId, model) {
            return getPage(model.page_id, domainId).then(function (page) {
                vm.rules.push({
                    id: vm.pk,
                    domain_id: domainId,
                    page_id: model.page_id,
                    heatmap_type: model.heatmap_type,
                    page: {
                        id: page.id,
                        url: page.url,
                        title: page.title,
                    },
                    updated_at: moment().toISOString(),
                    created_at: moment().toISOString(),
                });
                vm.rules.total = vm.rules.length;
                vm.pk++;
                return vm.rules[vm.rules.length - 1];
            });
        }

        function destroyData (domainId, ruleId) {
            var defer = $q.defer();
            delay(function () {
                var index = Lodash.findIndex(vm.rules, { domain_id: domainId, id: ruleId });
                if (index > -1) {
                    vm.rules.splice(index, 1);
                }
                defer.resolve(null);
            });
            return defer.promise;
        }

        function getAllFromCustomerData () {
            return vm.rules;
        }

        function paginate (data, params) {
            params.page = params.page || 1;
            params.page_size = params.page_size || 10;
            var max = params.page * params.page_size;
            var min = params.page - 1 * params.page_size;
            var filter = data.filter(function (item, index) {
                if (min <= index && max > index) {
                    return item;
                }
            });
            filter.total = filter.length;
            return filter;
        }

        function delay (fn) {
            var timer = Math.round(1000 * Math.random());
            $timeout(function () {
                fn();
            }, timer);
        }

        function generateData () {
            vm.rules = [];
            vm.rules.total = vm.rules.length;
        }

        function getPage (pageId, domainId) {
            return PageRepo.get(pageId, { domain_id: domainId });
        }
    }
})();
