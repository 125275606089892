(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformancePageDetailsService
     * @description Service to handle data of the Page Performance display "binary"
     */
    angular.module('modules.page-performance').service('PagePerformancePageDetailsService', PagePerformancePageDetailsService);

    PagePerformancePageDetailsService.$inject = ['PAGE_PERFORMANCE_ICON', '$deepmerge', 'ng2MonEventsService', 'PAGE_PERFORMANCE_METRICS', 'PAGE_PERFORMANCE_AUDIT_INFO','PagePerformanceAuditScoreService'];

    /* @ngInject*/
    function PagePerformancePageDetailsService (PAGE_PERFORMANCE_ICON, $deepmerge, ng2MonEventsService, PAGE_PERFORMANCE_METRICS, PAGE_PERFORMANCE_AUDIT_INFO, PagePerformanceAuditScoreService) {
        var vm = this;
        vm.report = null;
        vm.loadingReport = true;

        /**
         * @memberOf PagePerformancePageDetailsService
         * @desc Initialize the Service
         */
        function init () {
            ng2MonEventsService.addListener('pagePerformancePDBeforeSetReport', function () {
                vm.loadingReport = true;
            });

            ng2MonEventsService.addListener('pagePerformancePDOnSetReport', function (report) {
                vm.report = report;
            });

            ng2MonEventsService.addListener('pagePerformancePDAfterSetReport', function () {
                vm.loadingReport = false;
            });

            return {
                getChevronDirection: getChevronDirection,
                getLoadingReportStatus: getLoadingReportStatus,
                getReport: getReport,
                initFilters: initFilters,
                filterAudits: filterAudits,
                getUnassignedAuditIds: getUnassignedAuditIds,
            };
        }

        return init();

        /**
         * @memberOf PagePerformancePageDetailsService
         * @desc Checks if an audit category is opened
         * @param {Boolean} open
         */
        function getChevronDirection (open) {
            if (open) {
                return PAGE_PERFORMANCE_ICON.OPEN;
            }

            return PAGE_PERFORMANCE_ICON.CLOSE;
        }

        /**
         * @memberOf PagePerformancePageDetailsService
         * @desc Check if the report is pending
         */
        function getLoadingReportStatus () {
            return vm.loadingReport;
        }

        /**
         * @memberOf PagePerformancePageDetailsService
         * @desc Get selected report
         */
        function getReport () {
            if (vm.report) {
                return $deepmerge({}, vm.report);
            } else {
                return null;
            }
        }

        function initFilters () {
            return {
                filters: { audits: [] },
                availableFilters: {
                    audits: [
                        {
                            value: PAGE_PERFORMANCE_METRICS.FCP,
                            name: PAGE_PERFORMANCE_METRICS.FCP,
                        },
                        {
                            value: PAGE_PERFORMANCE_METRICS.LCP,
                            name: PAGE_PERFORMANCE_METRICS.LCP,
                        },
                        {
                            value: PAGE_PERFORMANCE_METRICS.TBT,
                            name: PAGE_PERFORMANCE_METRICS.TBT,
                        },
                        {
                            value: PAGE_PERFORMANCE_METRICS.CLS,
                            name: PAGE_PERFORMANCE_METRICS.CLS,
                        },
                    ],
                },
            };
        }

        function filterAudits (filters, audits, tab, searchKeyword) {
            const tabFiltered = audits.filter((audit) => {
                if (tab === 'errors') {
                    return typeof audit.score !== 'number' || !PagePerformanceAuditScoreService.hasScorePassed(audit.score * 100);
                }
                return typeof audit.score === 'number' && PagePerformanceAuditScoreService.hasScorePassed(audit.score * 100);
            });

            const searchFilter = tabFiltered.filter((audit)=>{
                if (searchKeyword) {
                    searchKeyword = searchKeyword.toLowerCase();
                    return (
                        audit.title.toLowerCase().includes(searchKeyword) ||
                        audit?.displayValue?.toLowerCase().includes(searchKeyword) ||
                        audit?.description?.toLowerCase().includes(searchKeyword)
                    );
                }

                return true;
            });

            const auditFilters = filters.audits || [];
            if (auditFilters.length) {
                return searchFilter.filter(function (audit) {
                    var matches = true;
                    auditFilters.forEach(function (filterAudit) {
                        matches = matches && audit.metrics.indexOf(filterAudit.value) >= 0;
                    });
                    return matches;
                });
            }

            return searchFilter;
        }

        /**
         * @memberOf PagePerformancePageDetailsService
         * @desc Returns an array of auditIds that aren't part of a group and are also defined in `PAGE_PERFORMANCE_AUDIT_INFO` constant
         */
        function getUnassignedAuditIds (auditRefs) {
            const unassignedAuditRefs = auditRefs.filter((ref) => !ref.group);
            const auditRefIds = unassignedAuditRefs.filter((ref) => {
                return Object.values(PAGE_PERFORMANCE_AUDIT_INFO).some(auditInfo => auditInfo.id === ref.id);
            }).map((ref) => ref.id);

            return auditRefIds;
        }
    }
})();
