(function () {
    'use strict';

    /**
     * @memberof modules.accessibility
     * @ngdoc service
     * @name AccessibilityCheckNameService
     * @description Service to provide check names
     */
    angular.module('modules.accessibility').service('AccessibilityCheckNameService', AccessibilityCheckNameService);

    AccessibilityCheckNameService.$inject = ['GlobalAccessibilityChecksRepo', '$q'];
    /* @ngInject*/
    function AccessibilityCheckNameService (GlobalAccessibilityChecksRepo, $q) {
        var checks = [];

        return {
            init: init,
            getName: getName,
            getNameSync: getNameSync,
        };

        /**
         * @memberOf AccessibilityCheckNameService
         * @desc Setup checks from API
         */
        function init () {
            return getAllChecks();
        }

        function getNameSync (id) {
            return findName(id);
        }

        /**
         * @memberOf AccessibilityCheckNameService
         * @desc Get the name of the check, based on check id
         * @param id - Check id
         */
        function getName (id) {
            if (checks.length === 0) {
                return getAllChecks().then(function () {
                    return findName(id);
                });
            }

            return $q.resolve(findName(id));
        }

        // /////

        function getAllChecks () {
            return GlobalAccessibilityChecksRepo.getAll({ page_size: 300 }).then(function (data) {
                checks = data;
            }, angular.noop);
        }

        function findName (id) {
            for (var i = 0; i < checks.length; i++) {
                if (checks[i].id === id) {
                    return checks[i].name;
                }
            }
        }
    }
})();
