(function () {
    'use strict';

    angular.module('app.layout').component('menuSearchInput', {
        templateUrl: 'app/layout/menu/search-input/search-input.html',
        controller: MenuSearchInputController,
        controllerAs: 'vm',
    });

    MenuSearchInputController.$inject = [
        'moment',
        'gettextCatalog',
        'ng2SessionService',
        'hotkeys',
        '$scope',
        'focus',
        'ng2MonEventsService',
    ];
    function MenuSearchInputController (moment, gettextCatalog, ng2SessionService, hotkeys, $scope, focus, ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.setPlaceholder = setPlaceholder;
        vm.getLastScan = getLastScan;
        vm.setSwitchDomain = setSwitchDomain;
        vm.showProjectSearch = showProjectSearch;
        vm.closeProjectSearch = closeProjectSearch;

        function activate () {
            vm.projectSearch = false;
            vm.mouseOverSearch = false;
            vm.switchDomain = false;
            vm.domain = ng2SessionService.domain;
            vm.domainGroup = ng2SessionService.domainGroup;
            vm.search = '';
            setPlaceholder();
            hotkeysInit();
            listeners();
        }

        function hotkeysInit () {
            hotkeys
                .bindTo($scope)
                .add({
                    combo: 'ctrl+d',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Show/Hide the Domain Picker'),
                    callback: function (event, hotkey) {
                        if (ng2SessionService.customer) {
                            event.preventDefault();
                            showProjectSearch(!vm.projectSearch);
                        }
                        if (vm.projectSearch) {
                            focus('project-search-input-field');
                        }
                    },
                })
                .add({
                    combo: 'd',
                    description: gettextCatalog.getString('Show the Domain Picker'),
                    callback: function (event, hotkey) {
                        if (ng2SessionService.customer) {
                            event.preventDefault();
                            showProjectSearch(!vm.projectSearch);
                        }
                        if (vm.projectSearch) {
                            focus('project-search-input-field');
                        }
                    },
                })
                .add({
                    combo: 'ctrl+shift+d',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Clear search in the domain picker.'),
                    callback: function (event, hotkey) {
                        vm.search = '';
                    },
                })
                .add({
                    combo: 'escape',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Close project search and domain picker'),
                    callback: function () {
                        closeProjectSearch();
                    },
                });
        }

        function listeners () {
            ng2MonEventsService.addListener('changed-domain', function () {
                vm.domain = ng2SessionService.domain;
                vm.domainGroup = ng2SessionService.domainGroup;
                vm.search = '';
                if (ng2SessionService.domain) {
                    vm.focusedDomainTitle = ng2SessionService.domain.title;
                }
                setPlaceholder();
            });
        }

        function setPlaceholder () {
            if (vm.focusedDomainTitle) {
                if (ng2SessionService.domain && ng2SessionService.domainGroup) {
                    vm.placeholder = ng2SessionService.domain.title + ' > ' + ng2SessionService.domainGroup.title;
                } else {
                    vm.placeholder = vm.focusedDomainTitle;
                }
                vm.focusedDomainTitle = '';
            } else if (vm.domain) {
                vm.placeholder = vm.domain.title;
                if (vm.domainGroup !== null) {
                    vm.placeholder += ' > ' + vm.domainGroup.title;
                }
            } else {
                vm.placeholder = gettextCatalog.getString('Select domain');
            }

            return vm.placeholder;
        }

        function getLastScan () {
            if (vm.domain) {
                if (vm.domain.crawl_history) {
                    var tmpStart = moment(vm.domain.crawl_history.post_processing_done_at);
                    var tmpEnd = moment();

                    return gettextCatalog.getString('Last scan: {{duration}} ago', {
                        duration: moment.duration(tmpEnd - tmpStart).humanize(),
                    });
                } else {
                    return gettextCatalog.getString('Last scan never');
                }
            }

            return '';
        }

        function setSwitchDomain (switchDomain) {
            vm.switchDomain = switchDomain;
        }

        function showProjectSearch (show) {
            vm.projectSearch = show;
        }

        function closeProjectSearch () {
            vm.search = '';
            showProjectSearch(false);
        }
    }
})();
