(function () {
    'use strict';

    angular.module('modules.fastTrack').component('fastTrackTable', {
        templateUrl: 'app/modules/fast-track/table/table.html',
        controller: FastTrackTableController,
        controllerAs: 'vm',
        bindings: {
            snippets: '<',
            activeSnippet: '<',
            setActiveSnippet: '&',
        },
    });

    FastTrackTableController.$inject = ['FastTrackTableService'];

    function FastTrackTableController (FastTrackTableService) {
        var vm = this;
        vm.isSnippetSelected = isSnippetSelected;
        vm.getCompliance = getCompliance;
        vm.getSourceCode = getSourceCode;
        vm.floorOrCeil = floorOrCeil;
        vm.setFocusedSnippet = setFocusedSnippet;
        vm.focusedSnippet = null;

        function isSnippetSelected (snippet) {
            return Boolean(vm.activeSnippet && vm.activeSnippet.id === snippet.id);
        }

        function getCompliance (snippet) {
            return FastTrackTableService.getCompliance(snippet);
        }

        function getSourceCode (snippet) {
            return FastTrackTableService.getSourceCode(snippet);
        }

        function floorOrCeil (val) {
            return FastTrackTableService.floorOrCeil(val);
        }

        function setFocusedSnippet (index) {
            vm.focusedSnippet = index;
        }
    }
})();
