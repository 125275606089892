(function () {
    'use strict';

    angular.module('modules.data_privacy.services.api').factory('DataPrivacyIgnoredViolationRepo', DataPrivacyIgnoredViolationRepo);

    DataPrivacyIgnoredViolationRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'DataPrivacyViolationModel'];
    /* @ngInject */
    function DataPrivacyIgnoredViolationRepo (ng2ApiService, ng2SessionService, DataPrivacyViolationModel) {
        var service = {
            getAll: getAll,
            remove: remove,
        };

        return service;

        // /////////////

        function getAll (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/ignored_data_protection_content',
                params,
                config,
            ).then(toModels);
        }

        function remove (page_id, ignored_content, params) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.delete(
                'domains/' +
                    ng2SessionService.getDomainId(params) +
                    '/pages/' +
                    page_id +
                    '/ignored_data_protection_content/' +
                    ignored_content.id,
                params,
            );
        }
        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new DataPrivacyViolationModel(model);
        }
    }
})();
