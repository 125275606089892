(function () {
    'use strict';

    angular.module('services.api').factory('StatsRepo', StatsRepo);

    StatsRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'CacheFactory', 'DemoStatsRepo', '$filter', 'StatisticsEntryPageModel'];

    /* @ngInject */
    function StatsRepo (ng2ApiService, ng2SessionService, CacheFactory, DemoStatsRepo, $filter, StatisticsEntryPageModel) {
        var service = {
            getResolutions: getResolutions,
            getOs: getOs,
            getOrganizations: getOrganizations,
            getLocations: getLocations,
            getRegions: getRegions,
            getCities: getCities,
            getLanguages: getLanguages,
            getBrowsers: getBrowsers,
            getSearchEngines: getSearchEngines,
            getReferrers: getReferrers,
            getPagesWithoutVisits: getPagesWithoutVisits,
            getPagesDocumentsWithoutDownloads: getPagesDocumentsWithoutDownloads,
            getDownloadsByUrl: getDownloadsByUrl,
            getLeastPopularPages: getLeastPopularPages,
            getExitPages: getExitPages,
            getEntryPages: getEntryPages,
            getKeywords: getKeywords,
            getVisitors: getVisitors,
            getMostDownloads: getMostDownloads,
            getMostPopularPages: getMostPopularPages,
            getDevices: getDevices,
            getSummary: getSummary,
            getSummaryPerDate: getSummaryPerDate,
            getSocials: getSocials,
            getPagesByUrl,
            getSiteSearchKeywords,
            getSiteSearchNoResultsKeywords,
        };

        return service;

        // /////////////

        function getResolutions (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getResolutions(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/resolutions', params, config);
            }
        }

        function getOs (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getOs(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/os', params, config);
            }
        }

        function getOrganizations (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getOrganizations(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/organizations', params, config);
            }
        }

        function getLocations (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getLocations(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/locations', params, config);
            }
        }

        function getRegions (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getRegions(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/regions', params, config);
            }
        }

        function getCities (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getCities(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/cities', params, config);
            }
        }

        function getLanguages (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getLanguages(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/languages', params, config);
            }
        }

        function getBrowsers (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getBrowsers(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/browsers', params, config);
            }
        }

        function getSearchEngines (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getSearchEngines(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/search_engines', params, config);
            }
        }

        function getReferrers (params, config) {
            config = config || {};
            params = params || {};
            config.cache = CacheFactory.get('stats');
            params.group_id = ng2SessionService.getGroupId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getReferrers(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/referrers', params, config);
            }
        }

        function getPagesWithoutVisits (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getPagesWithoutVisits(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/pages_without_visits', params, config);
            }
        }

        async function getPagesDocumentsWithoutDownloads (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getPagesDocumentsWithoutDownloads(params);
            } else {
                return ng2ApiService.get('domains/' +
                    ng2SessionService.getDomainId(params) +
                    '/statistics/documents_without_downloads', params, config);
            }
        }

        async function getDownloadsByUrl (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getDownloadsByUrl(params);
            }
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/statistics/downloads_by_url', params, config);
        }

        function getPagesByUrl (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getPagesByUrl(params);
            }
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/statistics/pages_by_url', params, config);
        }

        function getLeastPopularPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getLeastPopularPages(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/least_popular_pages', params, config);
            }
        }

        function getExitPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getExitPages(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/exit_pages', params, config);
            }
        }

        function getEntryPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getEntryPages(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/entry_pages', params, config).then(
                    toEntryPageModels,
                );
            }
        }

        function getKeywords (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getKeywords(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/keywords', params, config);
            }
        }

        function getMostPopularPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getMostPopularPages(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/most_popular_pages', params, config);
            }
        }

        function getMostDownloads (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getMostDownloads(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/downloads', params, config);
            }
        }

        function getSummary (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getSummary(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/summary', params, config);
            }
        }

        function getSummaryPerDate (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getSummaryPerDate(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/summary_per_date', params, config);
            }
        }

        function getVisitors (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getVisitors(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/visitors', params, config);
            }
        }

        function getDevices (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getDevices(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/devices', params, config);
            }
        }

        function getSocials (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            if (useSampleData()) {
                return DemoStatsRepo.getSocials(params);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/statistics/socials', params, config);
            }
        }

        function getSiteSearchKeywords (params, config) {
            config = config || {};
            params = params || {};
            const domainId = ng2SessionService.getDomainId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getSiteSearchKeywords();
            }
            return ng2ApiService.get(`domains/${domainId}/statistics/site_search/keywords`, params,
                {
                    headers: {
                        noParseSearch: true,
                        ...config,
                    },
                });
        }

        function getSiteSearchNoResultsKeywords (params, config) {
            config = config || {};
            params = params || {};
            const domainId = ng2SessionService.getDomainId(params);
            if (useSampleData()) {
                return DemoStatsRepo.getSiteSearchNoResultsKeywords();
            }
            return ng2ApiService.get(`domains/${domainId}/statistics/site_search/no_results_keywords`, params,
                {
                    headers: {
                        noParseSearch: true,
                        ...config,
                    },
                });
        }

        // PROTECTED

        function useSampleData () {
            var statistics = ng2SessionService.domain !== null && ng2SessionService.domain.features.statistics;
            return !statistics && $filter('monCustomerHasPlan')('demo');
        }

        function toEntryPageModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toEntryPageModel(models[i]);
            }
            return models;
        }

        function toEntryPageModel (model) {
            return new StatisticsEntryPageModel(model);
        }
    }
})();
