(function () {
    'use strict';

    angular.module('modules.chat.velaro').run([
        'ng2MonEventsService',
        'Velaro',
        'ng2SessionService',
        function (ng2MonEventsService, Velaro, ng2SessionService) {
            ng2MonEventsService.addListener('afterCustomer', function (account, agreement, me, isSudo) {
                if (ng2SessionService.getChatSystem() === 'velaro') {
                    if (!isSudo) {
                        Velaro.addScript();
                    }
                }
            });
        },
    ]);
})();
