(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name supportRequestService
     * @description Service to handle requests for support
     */
    angular.module('services.entities').factory('supportRequestService', supportRequestService);

    supportRequestService.$inject = ['SupportRequestRepo'];
    /* @ngInject*/
    function supportRequestService (SupportRequestRepo) {
        return {
            create: create,
        };

        function create (params, config) {
            return SupportRequestRepo.create(params, config);
        }
    }
})();
