(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionAccessibility', {
        templateUrl: 'app/components/pagedetails/details/sections/accessibility/accessibility.html',
        controller: SectionAccessibilityController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            active: '<', // subTarget attribute from page-details button
            refreshPage: '&',
        },
    });

    SectionAccessibilityController.$inject = [
        'PageAccessibilityRepo',
        'ng2SessionService',
        'gettextCatalog',
        '$q',
        '$filter',
        'AccessibilityFiltersService',
    ];
    /* @ngInject */
    function SectionAccessibilityController (
        PageAccessibilityRepo,
        ng2SessionService,
        gettextCatalog,
        $q,
        $filter,
        AccessibilityFiltersService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.refreshChecks = refreshChecks;
        vm.onTabChange = onTabChange;
        vm.allowIgnoreAction = false;

        function activate () {
            if ($filter('accessibilityHasGuideline')('wcag2')) {
                vm.header = gettextCatalog.getString('WCAG 2.0 Accessibility Compliance');
            } else if ($filter('accessibilityHasGuideline')('wcag21')) {
                vm.header = gettextCatalog.getString('WCAG 2.1 Accessibility Compliance');
            } else if ($filter('accessibilityHasGuideline')('wcag22')) {
                vm.header = gettextCatalog.getString('WCAG 2.2 Accessibility Compliance');
            }
            vm.tab = null;
            setupFilters();
            refreshChecks();
            setExports();
        }

        function setupFilters () {
            vm.availableFilters = {};
            vm.availableFilters.type = AccessibilityFiltersService.getTypes();
            vm.availableFilters[AccessibilityFiltersService.getReponsibilityTranslation()] =
                AccessibilityFiltersService.getResponsibilities();
            vm.filters = {};
            vm.filters.type = [];
            vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()] = [];
        }

        function refreshChecks () {
            vm.progress = getPageChecks().then(function (checks) {
                var sortedChecks = sortChecks(checks);
                if (vm.active === null && sortedChecks.length > 0) {
                    vm.active = sortedChecks[0].id;
                }
                groupByStandard(sortedChecks);
            }, $q.reject);
        }

        function onTabChange (tab) {
            vm.tab = tab instanceof Object ? tab.tab : tab;
            refreshChecks();
            setExports();
        }

        function getPageChecks () {
            var params = {
                page_size: 0,
                abbr: ['passed_checks', 'ignored_issues'].indexOf(vm.tab) === -1 ? vm.tab : null,
            };

            var isFilteringIssuesByType = vm.filters.type.length > 0;
            var isFilteringMobileIssues = isFilteringIssuesByType && vm.filters.type[0].value === 'mobile';

            if (isFilteringIssuesByType && !isFilteringMobileIssues) {
                params.type = vm.filters.type[0].value;
            }

            if (vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()].length > 0) {
                var selectedResponsibility =
                    vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()][
                        vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()].length - 1
                    ];
                vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()] = [selectedResponsibility];
                params.responsibility = selectedResponsibility.value;
            }

            return PageAccessibilityRepo.getAllChecks(vm.page.id, params).then(function (data) {
                vm.allowIgnoreAction = !ng2SessionService.isDomainGroup();

                if (params.abbr === null) {
                    vm.standardCheckCount = data.reduce(function (num, check) {
                        if (!check.ignored && !check.ignored_on_page) {
                            num++;
                        }
                        return num;
                    }, 0);
                    vm.accCheckErrorCount = data.reduce(function (num, check) {
                        if (check.errors_count > 0 && check.ignored === false && check.ignored_on_page === false) {
                            num++;
                        }
                        return num;
                    }, 0);
                }

                if (isFilteringMobileIssues) {
                    return data.filter(function (check) {
                        return check.mobile === true;
                    });
                }

                return data;
            }, $q.reject);
        }

        function sortChecks (checks) {
            if (!angular.isArray(checks)) {
                return [];
            }

            return checks.sort(function (a, b) {
                if (a.ignored || b.ignored) {
                    if (a.ignored && !b.ignored) {
                        return 1;
                    } else if (!a.ignored && b.ignored) {
                        return -1;
                    }
                }

                if (a.errors_count === 0 && b.errors_count > 0) {
                    return 1;
                } else if (b.errors_count === 0 && a.errors_count > 0) {
                    return -1;
                }
                return a.issue_number < b.issue_number ? -1 : a.issue_number > b.issue_number ? 1 : 0;
            });
        }

        function groupByStandard (checks) {
            wcag20(checks);
        }

        function wcag20 (checks) {
            vm.issues = {};
            vm.issues.A = checks.filter(function (data) {
                return data.issue_abbr === 'A';
            });
            vm.issues.AA = checks.filter(function (data) {
                return data.issue_abbr === 'AA';
            });
            vm.issues.AAA = checks.filter(function (data) {
                return data.issue_abbr === 'AAA';
            });
        }

        function setExports () {
            var categoryRef = [vm.page.id];
            if (vm.tab !== null) {
                categoryRef.push(vm.tab);
            }

            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'accessibility_checks_for_page',
                        category_ref: categoryRef.join(),
                    },
                },
            ];
        }
    }
})();
