import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('app.components.pages').component('projectsAndDomains', {
        templateUrl: 'app/pages/dashboard/projects-and-domains/projects-and-domains.html',
        controller: ProjectsAndDomainsController,
        controllerAs: 'vm',
        bindings: {
            progress: '<',
            domainObservable: '<',
            sort: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            getPage: '&',
            onSort: '&',
            isFiltersOpen: '=?',
            onFiltersChanges: '&',
            filters: '<',
            availableFilters: '<',
        },
    });

    ProjectsAndDomainsController.$inject = [
        'LabelRepo',
        '$state',
        'ng2MonEventsService',
        'ng2SessionService',
        'activeFeatures',
        'MON_MODULES',
        '$filter',
        'rx',
        'DomainGroupsRepo',
        'Lodash',
        'domainService',
        'coreDomainService',
        'coreLoginService',
        'maxDomainGroupVisible',
        'ng2ActiveFeatureService',
    ];

    function ProjectsAndDomainsController (
        LabelRepo,
        $state,
        ng2MonEventsService,
        ng2SessionService,
        activeFeatures,
        MON_MODULES,
        $filter,
        rx,
        DomainGroupsRepo,
        Lodash,
        domainService,
        coreDomainService,
        coreLoginService,
        maxDomainGroupVisible,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = destroy;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.goTo = goTo;
        vm.domainAccCheckCount = domainAccCheckCount;
        vm.updateDomain = updateDomain;
        vm.openScanDetails = openScanDetails;
        vm.getLabels = getLabels;
        vm.onHover = onHover;
        vm.onHoverLeave = onHoverLeave;
        vm.toSection = toSection;
        vm.getGroupHistory = getGroupHistory;
        vm.getUnavailableModuleCount = getUnavailableModuleCount;
        vm.getNoDataWidth = getNoDataWidth;
        vm.hasAccessToSEOModule = hasAccessToSEOModule;
        vm.hasAccessToQAModule = hasAccessToQAModule;
        vm.hasAccessToPoliciesModule = hasAccessToPoliciesModule;
        vm.hasAccessToAccessibilityModule = hasAccessToAccessibilityModule;
        vm.hasAccessToDataPrivacyModule = hasAccessToDataPrivacyModule;

        function activate () {
            // angular.extend(vm, $controller('BaseApiController', {vm: vm}));
            vm.hoverDomain = null;
            vm.destroyed = false;
            ng2SessionService.domain = null;
            ng2MonEventsService.run('changed-domain');
            vm.showLabels = activeFeatures.labels;
            vm.sort = vm.sort || {
                by: 'favorite',
                direction: 'asc',
            };
            vm.groupsData = [];
            vm.modules = MON_MODULES;
            vm.switchDomain = false;
            vm.switchDomainGroup = false;
            vm.showLabel = [];
            vm.isFiltersOpen = vm.isFiltersOpen || false;
            coreLoginService.getUser().then(
                function (user) {
                    vm.user = user;
                },
                function () {
                    vm.user = ng2SessionService.me;
                    vm.colSpanSpace = 0;
                },
            );
            getLabels();
            subscribeDomains();
        }

        function destroy () {
            vm.destroyed = true;
        }


        function subscribeDomains () {
            vm.domainObservable.subscribe(function (domains) {
                vm.domains = domains;
                setupGroupData();
            });
        }

        function isDataInGroups (group_id) {
            var group = Lodash.find(vm.groupsData, { id: group_id });
            return !!group;
        }
        function isOverrideInGroups (group_id) {
            var group = Lodash.find(vm.groupsData, { id: group_id });
            return group && group.overridden;
        }

        function addGroupData (group_id, data) {
            data = data || null;

            if (!data) {
                data = Lodash.find(vm.groupsData, { id: group_id });
            }

            firstLoop: for (var i = 0; i < vm.domains.length; i++) {
                if (vm.domains[i].domain_groups.length) {
                    for (var u = 0; u < vm.domains[i].domain_groups.length; u++) {
                        if (vm.domains[i].domain_groups[u].id === group_id) {
                            vm.domains[i].domain_groups[u].seo_count = data.seo_count;
                            vm.domains[i].domain_groups[u].qa_count = data.qa_count;
                            vm.domains[i].domain_groups[u].policy_with_violations_count = data.policy_with_violations_count;
                            vm.domains[i].domain_groups[u].policy_with_required_count = data.policy_with_required_count;
                            vm.domains[i].domain_groups[u].policy_with_search_hits_count = data.policy_with_search_hits_count;
                            vm.domains[i].domain_groups[u].accessibility_checks = data.accessibility_checks;
                            vm.domains[i].domain_groups[u].date = data.date;
                            vm.domains[i].domain_groups[u].page_count = data.page_count;
                            vm.domains[i].domain_groups[u].document_count = data.documents_count;
                            vm.domains[i].domain_groups[u].no_data = data.no_data || false;
                            vm.domains[i].domain_groups[u].manual_data = data.manual_data || false;
                            break firstLoop;
                        }
                    }
                }
            }
        }

        function setupGroupData () {
            var groups = [];
            var group;
            for (var i = 0; i < vm.domains.length; i++) {
                if (vm.domains[i].domain_groups.length) {
                    for (var u = 0; u < vm.domains[i].domain_groups.length; u++) {
                        if (!isDataInGroups(vm.domains[i].domain_groups[u].id) || isOverrideInGroups(vm.domains[i].domain_groups[u].id)) {
                            group = vm.domains[i].domain_groups[u];
                            group.domain_id = vm.domains[i].id;
                            groups.push(group);
                        } else {
                            addGroupData(vm.domains[i].domain_groups[u].id);
                        }
                    }
                }
            }

            if (groups.length) {
                getGroupHistory(groups, 0);
            }
        }

        function parseGroup (group) {
            group.qa_count = group.dead_links_count + group.dead_images_count + group.spelling_errors_confirmed_count;

            group.seo_count = group.seo_errors_alerts_count + group.seo_errors_infos_count + group.seo_errors_warnings_count;

            return group;
        }

        function getGroupHistory (groups, counter, overrideMax, $event) {
            if ($event) {
                $event.stopPropagation();
            }
            if (vm.destroyed) {
                return;
            }
            if (overrideMax === null) {
                overrideMax = false;
            }
            if (groups.length <= maxDomainGroupVisible || overrideMax) {
                var params = {
                    page_size: 1,
                    group_id: groups[counter].id,
                    domain_id: groups[counter].domain_id,
                };
                DomainGroupsRepo.getHistory(params)
                    .then(function (group) {
                        if (group.length > 0) {
                            var newGroup = parseGroup(group[0]);
                            newGroup.id = groups[counter].id;
                            vm.groupsData.push(newGroup);
                            addGroupData(newGroup.id, newGroup);
                        } else {
                            var fakeGroup = groups[counter];
                            if (overrideMax) {
                                vm.groupsData.push({ id: fakeGroup.id, overridden: true });
                            }
                            fakeGroup.no_data = true;
                            fakeGroup.manual_data = false;
                            addGroupData(fakeGroup.id, fakeGroup);
                        }
                    }, angular.noop)
                    .finally(function () {
                        if (groups.length > counter + 1) {
                            getGroupHistory(groups, counter + 1);
                        }
                    });
            } else {
                var fakeGroup = groups[counter];
                fakeGroup.no_data = true;
                if (!isOverrideInGroups(fakeGroup.id)) {
                    fakeGroup.manual_data = true;
                }
                addGroupData(fakeGroup.id, fakeGroup);

                if (groups.length > counter + 1) {
                    getGroupHistory(groups, counter + 1, overrideMax);
                }
            }
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ pageSize: size });
        }

        function toSection (event, type, domain, domain_group) {
            if (event) {
                event.stopPropagation();
            }
            var state;
            var params = {
                domainId: domain.id,
            };

            switch (type) {
                case 'policy':
                    state = 'base.customer.domain.policy.dashboard';
                    break;
                case 'qa':
                    state = 'base.customer.domain.qa.summary';
                    break;
                case 'accessibility':
                    state = 'base.customer.domain.accessibility.summary';
                    break;
                case 'seo':
                    state = 'base.customer.domain.seo.summary';
                    break;
                case 'pages':
                    state = 'base.customer.domain.pages.index';
                    break;
                case 'documents':
                    state = 'base.customer.domain.inventory.content.documents';
                    break;
                case 'dataprivacy':
                    state = 'base.customer.domain.data_privacy.summary';
                    break;
                default:
                    state = 'base.customer.domain.dashboard';
            }

            vm.switchDomain = domain.id;
            if (domain_group !== undefined) {
                vm.switchDomainGroup = domain_group.id;
                params.domain_group = domain_group.id;
                coreDomainService.setDomainGroup(domain_group);
            } else {
                coreDomainService.removeDomainGroup();
            }

            $state
                .go(state, params)
                .then(function () {
                    ng2MonEventsService.run('changed-domain');
                }, angular.noop)
                .finally(function () {
                    vm.switchDomain = false;
                    vm.switchDomainGroup = false;
                });
        }

        function goTo (domain, domain_group) {
            toSection(null, 'dashboard', domain, domain_group);
        }

        function domainAccCheckCount (accChecks) {
            var check;
            var total = 0;
            for (check in accChecks) {
                if (['error', 'warning', 'review'].indexOf(check) === -1) {
                    total += accChecks[check] || 0;
                }
            }
            return total;
        }

        function updateDomain (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });

            domainService.update(domain.id, params);
        }

        function openScanDetails (domain, event) {
            var domainObservable = new rx.BehaviorSubject(domain);
            event.stopPropagation();
            vm.domainObservable.subscribe(function (domains) {
                var nextDomain = domains.find(function (listDomain) {
                    return listDomain.id === domain.id;
                });

                if (nextDomain) {
                    domainObservable.onNext(nextDomain);
                } else {
                    domainObservable.onNext(domain);
                }
            });
            var params = {
                body: 'scanDetailsDialog',
                size: 'sm',
                classes: 'fade-animation middle center',
                data: {
                    domainObservable: domainObservable,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getUnavailableModuleCount (domain, domainGroup) {
            if ((domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.date)) {
                return getNoDataTitleWidth();
            }
            return [
                hasAccessToSEOModule(domain, domainGroup),
                hasAccessToQAModule(domain, domainGroup),
                hasAccessToAccessibilityModule(domain, domainGroup),
                hasAccessToPoliciesModule(domain, domainGroup),
                hasAccessToDataPrivacyModule(domain, domainGroup),
            ].reduce(function (num, hasAccess) {
                if (!hasAccess) {
                    return num + 1;
                }
                return num;
            }, 1);
        }

        function getNoDataWidth () {
            // not using helper function because they always return false on no data
            return [
                $filter('hasAccess')(vm.user, MON_MODULES.seo),
                $filter('hasAccess')(vm.user, MON_MODULES.data_privacy),
                $filter('hasAccess')(vm.user, MON_MODULES.qa),
                $filter('hasAccess')(vm.user, MON_MODULES.policies),
                $filter('hasAccess')(vm.user, MON_MODULES.accessibility),
            ].reduce(function (num, hasAccess) {
                if (!hasAccess) {
                    return num - 1;
                }
                return num;
            }, 7);
        }

        function getNoDataTitleWidth () {
            return [
                $filter('hasAccess')(vm.user, MON_MODULES.seo),
                $filter('hasAccess')(vm.user, MON_MODULES.data_privacy),
                $filter('hasAccess')(vm.user, MON_MODULES.qa),
                $filter('hasAccess')(vm.user, MON_MODULES.policies),
                $filter('hasAccess')(vm.user, MON_MODULES.accessibility),
            ].reduce(function (num, hasAccess) {
                if (!hasAccess) {
                    return num + 1;
                }
                return num;
            }, 1);
        }

        function hasAccessToSEOModule (domain, domainGroup) {
            if (!ng2ActiveFeatureService.isFeatureActivePure('seo_all')) {
                return false;
            }
            if ((domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.date)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.seo);
        }

        function hasAccessToDataPrivacyModule (domain, domainGroup) {
            if ((domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.date)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.data_privacy);
        }

        function hasAccessToQAModule (domain, domainGroup) {
            if (!ng2ActiveFeatureService.isFeatureActivePure('qa_module')) {
                return false;
            }
            if ((domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.date)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.qa);
        }

        function hasAccessToPoliciesModule (domain, domainGroup) {
            if (!ng2ActiveFeatureService.isFeatureActivePure('policies_all')) {
                return false;
            }
            if ((domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.date)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.policies);
        }

        function hasAccessToAccessibilityModule (domain, domainGroup) {
            if ((domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.date)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.accessibility);
        }

        function onHover (domain) {
            if (domain) {
                vm.showLabel[domain.id] = true;
                if ($filter('hasDomainAccess')(domain)) {
                    vm.hoverDomain = domain.id;
                }
            }
        }

        function onHoverLeave (domain) {
            if (domain) {
                vm.showLabel[domain.id] = false;
                vm.hoverDomain = false;
            }
        }
    }
})();
