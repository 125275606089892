(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableDataPrivacy', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/data-privacy/data-privacy.html',
        controller: DataPrivacyController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
            domainGroup: '<',
        },
    });

    DataPrivacyController.$inject = ['$filter', 'complianceDataPrivacyService', 'historyHelper'];
    /* @ngInject */
    function DataPrivacyController ($filter, complianceDataPrivacyService, historyHelper) {
        var vm = this;
        vm.$onChanges = update;
        vm.getChangeValue = getChangeValue;
        vm.getChangePercentageValue = getChangePercentageValue;

        function update () {
            vm.issuesStack = [
                'data_protection_high_priority_page_violations_count',
                'data_protection_medium_priority_page_violations_count',
                'data_protection_low_priority_page_violations_count',
            ];
        }

        function getChangeValue (id) {
            return historyHelper.getChangeValue(id, vm.history, vm.issuesStack);
        }

        function getChangePercentageValue (id) {
            return historyHelper.getChangePercentageValue(id, vm.history, vm.issuesStack);
        }
    }
})();
