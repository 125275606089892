(function () {
    'use strict';
    angular.module('blocks.helpers').service('historyHelper', historyHelper);

    historyHelper.$inject = ['$filter'];
    /* @ngInject*/
    function historyHelper ($filter) {
        return {
            getChangeValue: getChangeValue,
            getChangePercentageValue: getChangePercentageValue,
            getChangeIconClass: getChangeIconClass,
            getChangeColorClass: getChangeColorClass,
        };

        // ///////////////

        function getChangeValue (id, data, issuesStack, type) {
            // This is specific for the vm.issuesStack right now
            const index = getIndex(id, data);
            if (index - 1 >= 0) {
                let currentCrawl;
                let lastCrawl;
                if (type === 'accessibility') {
                    currentCrawl = data[index][issuesStack] || 0;
                    lastCrawl = data[index - 1][issuesStack] || 0;
                } else {
                    currentCrawl = $filter('addObjectAttributes')(data[index], issuesStack);
                    lastCrawl = $filter('addObjectAttributes')(data[index - 1], issuesStack);
                }
                return getChange(currentCrawl, lastCrawl);
            }

            return 0;
        }

        function getChangePercentageValue (id, data, issuesStack, type) {
            // This is specific for the vm.issuesStack right now
            const index = getIndex(id, data);
            if (index - 1 >= 0) {
                let currentCrawl;
                let lastCrawl;
                if (type === 'accessibility') {
                    currentCrawl = data[index][issuesStack] || 0;
                    lastCrawl = data[index - 1][issuesStack] || 0;
                } else {
                    currentCrawl = $filter('addObjectAttributes')(data[index], issuesStack);
                    lastCrawl = $filter('addObjectAttributes')(data[index - 1], issuesStack);
                }
                return getChangePercentage(currentCrawl, lastCrawl);
            }

            return 0;
        }

        /**
         * @memberOf historyHelper
         * @desc Get the icon css class based on the change value between current history entry and last history entry
         * @param {number} value - Change value between current history entry and last history entry
         * @return {string}
         */
        function getChangeIconClass (value) {
            return {
                'fas fa-chevron-down': value < 0,
                'fas fa-chevron-up': value > 0,
            };
        }

        /**
         * @memberOf historyHelper
         * @desc Get the color css class based on the change value between current history entry and last history entry
         * @param {number} value - Change value between current history entry and last history entry
         * @return {string}
         */
        function getChangeColorClass (value) {
            return {
                'text-success': value < 0,
                'text-danger': value > 0,
                'text-grey': value === 0,
            };
        }

        // PROTECTED

        function getChange (start, end) {
            if (angular.isUndefined(start) || angular.isUndefined(end)) {
                return 0;
            }

            return start - end;
        }

        function getChangePercentage (start, end) {
            if (angular.isUndefined(start) || angular.isUndefined(end)) {
                return 0;
            }
            const highestNumber = Math.max(start, end);
            const lowestNumber = Math.min(start, end);
            return 100 - (lowestNumber / highestNumber) * 100 || 0;
        }

        function getIndex (id, data) {
            return data.map(story => story.id).indexOf(id);
        }
    }
})();
