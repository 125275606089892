(function () {
    'use strict';
    angular.module('app.components').component('pagesFromSeoIssues', {
        templateUrl: 'app/components/pages/seo/seo.html',
        controller: PagesFromSeoIssueController,
        controllerAs: 'vm',
        bindings: {
            issue: '<',
            pageCount: '<',
        },
    });

    PagesFromSeoIssueController.$inject = [
        'SeoRepo',
        '$controller',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2LabelService',
        'ng2ActiveFeatureService',
    ];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    function PagesFromSeoIssueController (SeoRepo, $controller, ng2MonEventsService, ENTITY_CONSTANT, BaseEntityService, ng2LabelService, ng2ActiveFeatureService) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanged = onFiltersChanged;
        vm.onSearchChanged = onSearchChanged;
        vm.onPageDetailsClose = onPageDetailsClose;
        vm.$onDestroy = onDestroy;
        vm.createOrUpdateLabel = createOrUpdateLabel;


        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            getPage();
            vm.getLabels();
            vm.isFiltersOpen = false;
            vm.exports = [
                {
                    data: {
                        category: 'seo',
                        category_ref: vm.issue.name,
                    },
                },
            ];
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                status: vm.activeTab,
                issue_id: vm.issue.id,
                sort_by: vm.currentSort.by === 'notification_count' ? 'seo_issues_count' : vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.search && vm.search !== '') {
                params.search = vm.search;
            }

            params = vm.updateParamsWithFilters(params);

            vm.progress = SeoRepo.getSeoIssuePages(vm.issue.id, params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }

        function onFiltersChanged (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }

        function onSearchChanged (search) {
            vm.search = search;
            vm.getPage();
        }

        function onPageDetailsClose () {
            getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
