(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormItalic', {
        templateUrl: 'app/modules/page-fix/forms/italic/italic.html',
        controller: PageFixFormItalicController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    PageFixFormItalicController.$inject = [
        'PageFixModelHelperService',
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        'ng2MonEventsService',
        'PAGE_FIX_EVENT',
        'PAGE_FIX_FORM_VIEW_NAME',
        'PAGE_FIX_FORM_VIEW_TYPE',
        'PAGE_FIX_FORM_FOOTER',
    ];
    /* @ngInject */
    function PageFixFormItalicController (
        PageFixModelHelperService,
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        ng2MonEventsService,
        PAGE_FIX_EVENT,
        PAGE_FIX_FORM_VIEW_NAME,
        PAGE_FIX_FORM_VIEW_TYPE,
        PAGE_FIX_FORM_FOOTER,
    ) {
        var customValidationId;
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.closeModal = closeModal;

        function activate () {
            vm.goToViewId = 0;
            vm.pageFix = vm.resolve.pageFix;
            vm.pageFixId = vm.resolve.typeId || vm.resolve.model.id;
            vm.pageFixSelector = JSON.stringify([
                PageFixModelHelperService.getSelectorValue(PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[vm.pageFixId].snippet),
            ]);
            vm.customValidation = true;
            vm.infoViewName = PAGE_FIX_FORM_VIEW_NAME.INFO;
            vm.infoViewType = PAGE_FIX_FORM_VIEW_TYPE.INFO;
            vm.infoFooter = PAGE_FIX_FORM_FOOTER.GO_TO;
            vm.optionsViewName = PAGE_FIX_FORM_VIEW_NAME.OPTIONS;
            vm.optionsViewType = PAGE_FIX_FORM_VIEW_TYPE.OPTIONS;
            vm.optionsFooter = PAGE_FIX_FORM_FOOTER.SUBMIT;
            vm.testingViewName = PAGE_FIX_FORM_VIEW_NAME.TESTING;
            vm.testingViewType = PAGE_FIX_FORM_VIEW_TYPE.TESTING;
            vm.testingFooter = PAGE_FIX_FORM_FOOTER.GO_TO;

            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.goToViewId);
            ng2MonEventsService.remove(customValidationId);
        }

        function setupListeners () {
            vm.goToViewId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.FORM_REQUEST_GO_TO_VIEW, function () {
                setView(PAGE_FIX_FORM_VIEW_NAME.OPTIONS);
            });
            customValidationId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.CUSTOM_VALIDATION, function (isValid) {
                vm.customValidation = isValid;
            });
        }

        function setView (viewName) {
            ng2MonEventsService.run(PAGE_FIX_EVENT.FORM_UPDATE_VIEW, viewName);
        }

        function closeModal (pageFix) {
            vm.close({ $value: pageFix });
        }
    }
})();
