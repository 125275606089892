(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixList', {
        templateUrl: 'app/modules/page-fix/pages/list/list.html',
        controller: AutofixListController,
        controllerAs: 'vm',
    });

    AutofixListController.$inject = [
        'PageFixRepo',
        '$location',
        'ParamService',
        '$controller',
        '$filter',
        'gettextCatalog',
        'PageFixService',
        'PAGE_FIX_FIXABLE_TYPES',
        'PageFixListService',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function AutofixListController (
        PageFixRepo,
        $location,
        ParamService,
        $controller,
        $filter,
        gettextCatalog,
        PageFixService,
        PAGE_FIX_FIXABLE_TYPES,
        PageFixListService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.removeShield = removeShield;
        vm.updateShield = updateShield;
        vm.getErrors = getErrors;
        vm.openCustom = openCustom;
        vm.deleteAll = deleteAll;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.currentSort = { by: 'created_at', direction: 'desc' };
            vm.showCustomPageFix = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_custom');
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    faIcon: $filter('monIcons')(['MENUES', 'PAGE_FIX']),
                    value: null,
                    show: true,
                },
                {
                    name: gettextCatalog.getString('Spelling'),
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'QA', 'MISSPELLINGS']),
                    value: PAGE_FIX_FIXABLE_TYPES.misspelling,
                    show: ng2ActiveFeatureService.isFeatureActive('page_fix_qa_misspelling'),
                },
                {
                    name: gettextCatalog.getString('Accessibility Source Code'),
                    faIcon: $filter('monIcons')(['MENUES', 'ACCESSIBILITY']),
                    value: PAGE_FIX_FIXABLE_TYPES.accessibility_source_code,
                    show: ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_source_code'),
                },
                {
                    name: gettextCatalog.getString('Accessibility Check'),
                    faIcon: $filter('monIcons')(['MENUES', 'ACCESSIBILITY']),
                    value: PAGE_FIX_FIXABLE_TYPES.accessibility_check,
                    show: ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_check'),
                },
                {
                    name: gettextCatalog.getString('Links'),
                    faIcon: $filter('monIcons')(['SIDEMENUES', 'QA', 'BROKEN_LINKS']),
                    value: PAGE_FIX_FIXABLE_TYPES.link,
                    show: ng2ActiveFeatureService.isFeatureActive('page_fix_qa_links'),
                },
                {
                    name: gettextCatalog.getString('Custom'),
                    faIcon: $filter('monIcons')(['PAGE_FIX', 'CUSTOM']),
                    value: 'custom',
                    show: ng2ActiveFeatureService.isFeatureActive('page_fix_qa_custom'),
                },
            ].filter(function (tab) {
                return tab.show;
            });
            vm.autofixes = [];
            loadLocations();
        }

        function loadLocations () {
            vm.activeTab = $location.search().tab || null;
            vm.search = $location.search().search || null;
            getPage();
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                tab: vm.activeTab,
                search: vm.search,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search,
            };

            if (vm.activeTab) {
                params.fixable_type = vm.activeTab;
            }

            vm.progress = PageFixRepo.getAll(params).then(function (data) {
                vm.autofixes = data;
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const autofix of vm.autofixes) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Update'),
                        leftIcon: 'faCog',
                        action: ()=>updateShield(autofix),
                    },
                    {
                        label: gettextCatalog.getString('Remove'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>removeShield(autofix),
                    },
                ];

                autofix.dropdown = dropdown;
            }
        }

        function updateShield (autofix) {
            if (!autofix.fixable_id && autofix.fixable_type === 'custom') {
                openCustom(autofix);
                return;
            }
            var model = {
                id: autofix.change.check_id || autofix.fixable_id,
            };

            PageFixService.update(autofix.normalizeFixableType(), model, autofix.change.attribute, autofix, function (
                result,
            ) {
                if (result !== undefined) {
                    getPages();
                }
            });
        }

        function removeShield (autofix) {
            PageFixService.remove(autofix).then(function (result) {
                if (result !== undefined) {
                    getPages();
                }
            }, angular.noop);
        }

        function getErrors (pageFix) {
            return PageFixListService.getError(pageFix);
        }

        function openCustom (pageFix) {
            PageFixService.openCustom(pageFix, function (model) {
                if (model !== undefined) {
                    getPages();
                }
            });
        }

        function deleteAll () {
            var params = {
                fixable_type: vm.activeTab || 'all',
            };
            PageFixService.removeAll(params).then(function (result) {
                if (result !== undefined) {
                    getPages();
                }
            }, angular.noop);
        }
    }
})();
