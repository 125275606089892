(function () {
    'use strict';
    angular.module('modules.light.services').service('LightDomainViewService', LightDomainViewService);

    LightDomainViewService.$inject = ['$state', 'ng2MonUIRouterTransitionService'];

    /* @ngInject*/
    function LightDomainViewService ($state, ng2MonUIRouterTransitionService) {
        return {
            goToDashboard: goToDashboard,
        };

        // ///////////////

        function goToDashboard (domain) {
            var params = {
                domainId: domain.id,
            };
            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.light.domain.page_fix.home', params),
            );
        }
    }
})();
