(function () {
    'use strict';

    function DataPrivacyInfoTypeModel (attributes) {
        angular.extend(this, attributes);
    }

    angular.module('modules.data_privacy.services.api').factory('DataPrivacyInfoTypeRepo', DataPrivacyInfoTypeRepo);

    DataPrivacyInfoTypeRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function DataPrivacyInfoTypeRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            getAllForDomain: getAllForDomain,
        };

        return service;

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};

            return ng2ApiService.get('info_types', params, config).then(toModels);
        }

        function getAllForDomain (domain_id, params) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get('domains/' + domain_id + '/data_protection/custom_ruleset', params).then(toModels);
        }
        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new DataPrivacyInfoTypeModel(model);
        }
    }
})();
