(function () {
    'use strict';
    angular.module('pages.search.content').component('policyContentTable', {
        templateUrl: 'app/pages/search/content/table/table.html',
        controller: PagesContentPoliciesController,
        controllerAs: 'vm',
        bindings: {
            policies: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            dialog: '&',
            getPage: '&',
            progress: '<',
            currentSort: '<',
            onPageSort: '&',
        },
    });

    PagesContentPoliciesController.$inject = [
        'LabelRepo',
        'Dialog',
        '$filter',
        'ng2SessionService',
        'gettextCatalog',
        'activeFeatures',
        'searchService',
    ];

    function PagesContentPoliciesController (LabelRepo, Dialog, $filter, ng2SessionService, gettextCatalog, activeFeatures, searchService) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.setFields = setFields;
        vm.openDialog = openDialog;
        vm.editPolicy = editPolicy;
        vm.runPolicy = runPolicy;
        vm.deletePolicy = deletePolicy;
        vm.getPolicyTarget = getPolicyTarget;
        vm.pageSort = pageSort;
        vm.getTooltipMessage = getTooltipMessage;
        vm.updatePolicy = updatePolicy;
        vm.getLabels = getLabels;
        vm.$onInit = activate;

        function activate () {
            vm.pageCount = ng2SessionService.domain.crawl_history ? ng2SessionService.domain.crawl_history.page_count : 0;
            if (ng2SessionService.domain.crawl_history) {
                vm.documentCount = ng2SessionService.domain.crawl_history.documents_count;
            } else {
                vm.documentCount = 0;
            }
            vm.labels = ['pages_count', 'errors'];
            vm.showLabels = activeFeatures.labels;
            getLabels();
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ size: size });
        }

        function setFields (policy) {
            if (!policy.hasOwnProperty('bottomFields')) {
                var fields = [
                    {
                        icon: 'fas fa-info-circle',
                        label: 'Created: ',
                        content: $filter('userDate')(policy.created_at, 'lll'),
                    },
                    {
                        icon: 'fas fa-clock',
                        label: 'Scheduled',
                    },
                    {
                        icon: 'fa-clock-o',
                        label: 'Last run:',
                        content: $filter('userDate')(policy.updated_at, 'lll'),
                    },
                ];

                if (policy.is_errors && policy.mode === 1) {
                    fields.push({
                        icon: 'fas fa-ban text-danger',
                        label: 'Unwanted content',
                    });
                }

                if (policy.is_errors && policy.mode === 2) {
                    fields.push({
                        icon: 'fas fa-exclamation-triangle text-link',
                        label: 'Required content',
                    });
                }

                policy.bottomFields = fields;
            }

            return policy.bottomFields;
        }

        function deletePolicy (policy) {
            searchService.destroy(policy.id).then(function () {
                vm.getPage();
            }, angular.noop);
        }

        function editPolicy (policy) {
            var dialog = Dialog.create('formPolicy', 'md', { policy: policy });
            if (dialog) {
                dialog.closePromise.then(function () {
                    vm.getPage();
                }, angular.noop);
            }
        }

        function runPolicy (policy) {
            searchService.run(policy.id).then(function () {
                vm.getPage();
            }, angular.noop);
        }

        function openDialog (policy) {
            Dialog.create('policy-content', 'md', { policy: policy });
        }

        function pageSort ({ by, direction }) {
            vm.currentSort = { by, direction }
            vm.onPageSort({ sort: vm.currentSort });
        }

        function getPolicyTarget (policy) {
            var type = 'search';
            if (policy.is_errors && policy.mode == 1) {
                type = 'unwanted';
            } else if (policy.is_errors && policy.mode == 2) {
                type = 'required';
            } else if (!policy.is_errors) {
                type = 'search';
            }

            return type;
        }

        function getTooltipMessage (policy) {
            var message = '';
            if (policy.is_errors && policy.mode == 1) {
                message = gettextCatalog.getString('Unwanted content');
            } else if (policy.is_errors && policy.mode == 2) {
                message = gettextCatalog.getString('Required content');
            } else if (!policy.is_errors) {
                message = gettextCatalog.getString('Search');
            }
            return message;
        }

        function updatePolicy (policy) {
            var params = {};
            params.labels = policy.labels.map(function (label) {
                return label.id;
            });
            params.id = policy.id;

            searchService.update(params);
        }
    }
})();
