/* eslint-disable max-len */
(function () {
    'use strict';

    angular.module('modules.cookie_banner.pages').component('consentLog', {
        templateUrl: 'app/modules/cookie-banner/pages/consent-log/consent-log.html',
        controller: ConsentLogController,
        controllerAs: 'vm',
    });

    ConsentLogController.$inject = [
        'CookieBannerEntitiesConsentService',
        'CookieBannerHelperService',
        '$controller',
        'gettextCatalog',
        'timeZone',
        'COOKIE_CATEGORIES',
        'ng2DemoHasAccessService',
        'moment',
        'ParamService',
        'MON_CONSENT_MESSAGE_ENCODER',
        'dateRangeService',
        '$location',
        'DATE_FORMAT',
    ];

    /* @ngInject */
    function ConsentLogController (
        CookieBannerEntitiesConsentService,
        CookieBannerHelperService,
        $controller,
        gettextCatalog,
        timeZone,
        COOKIE_CATEGORIES,
        ng2DemoHasAccessService,
        moment,
        ParamService,
        MON_CONSENT_MESSAGE_ENCODER,
        dateRangeService,
        $location,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.clearUUID = clearUUID;
        vm.submitForm = submitForm;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.emptySubmit = false;
            vm.hideDatepicker = false;
            vm.subheaderTranslation = gettextCatalog.getString('A log of all the consents given by your website visitors');
            vm.consents = [];
            vm.uuid = '';
            vm.currentSort = { by: 'timestamp', direction: 'desc' };
            vm.date = dateRangeService.setRangeDates({
                todayAsDefaultEnd: true,
            });
            vm.dateFormat = DATE_FORMAT;
            vm.datePickerOptions = { maxDate: moment().toDate() };
            loadLocations();
        }

        function submitForm () {
            if (!/^([0-9a-z]+-){4}([0-9a-z]+)$/.test(vm.uuid)) {
                vm.emptySubmit = true;
            } else {
                getPage();
            }
        }

        function clearUUID (search) {
            vm.uuid = search;
            if (!search) {
                getPage();
            }
        }

        function loadLocations () {
            vm.uuid = $location.search().uuid || null;
            const from = $location.search().from;
            const to = $location.search().to;

            if (to) {
                vm.date.endDate = moment(to);
            } else {
                vm.date.endDate = moment().endOf('day');
            }

            if (from) {
                vm.date.startDate = moment(from);
            } else {
                vm.date.startDate = moment().subtract(7, 'days')
                    .startOf('day');
            }

            getPage();
        }

        function getPage () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
                uuid: vm.uuid,
            });

            getConsents();
        }

        function getConsents () {
            vm.emptySubmit = false;
            vm.loading = true;
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_dir: vm.currentSort.direction,
                sort_by: vm.currentSort.by,
            };
            if (vm.uuid) {
                params.session_id = vm.uuid;
                vm.hideDatepicker = true;
            } else {
                params.from = vm.date.startDate;
                params.to = vm.date.endDate;
                vm.hideDatepicker = false;
            }

            vm.isDemo = ng2DemoHasAccessService.isDemoConsentManager();
            if (vm.isDemo) {
                updateConsents(generateData());
            } else {
                vm.progress = CookieBannerEntitiesConsentService.getAll(params)
                    .then(function (consents) {
                        try {
                            updateConsents(consents);
                        } catch (e) {
                            vm.consents = [];
                        }
                    }, angular.noop)
                    .finally(function () {
                        vm.loading = false;
                    });
            }
        }

        function updateConsents (consents) {
            vm.consents = consents.map(function (consent) {
                var content = parseContent(consent.tc_string);
                var consentedCookies = content.consents || [];
                return {
                    timestamp: consent.timestamp,
                    date: moment(consent.timestamp).format('ll'),
                    country_code: countryView(consent.country_code),
                    anon_ip: consentIpView(consent.anon_ip),
                    consentedCookies: consentedCookies,
                    consentedCookiesView: consentedCookiesView(consentedCookies),
                    UserSessionId: content.session_id,
                };
            });
            vm.consents.total = consents.total;
        }

        function parseContent (tcString) {
            return MON_CONSENT_MESSAGE_ENCODER.MessageEncoder.tcStringDecode(tcString);
        }

        function countryView (countryCode) {
            if (!countryCode || typeof countryCode !== 'string') {
                return null;
            }
            var countryObj = timeZone.countries[countryCode.toUpperCase()];
            if (!countryObj) {
                return null;
            }

            return countryObj.name;
        }

        function consentIpView (ip) {
            try {
                var firstColonOccurrence = ip.indexOf(':');
                if (firstColonOccurrence > -1) {
                    // is ipv6
                    return ip.substring(0, ip.indexOf(':', firstColonOccurrence + 1));
                } else {
                    return ip.substring(0, ip.lastIndexOf('.0.0'));
                }
            } catch (e) {
                return ip;
            }
        }

        function consentedCookiesView (cookies) {
            if (cookies.length === 0) {
                return null;
            }
            var text = [];
            var cssClass = '';

            if (cookies.length === 1 && cookies[0] === COOKIE_CATEGORIES.essential) {
                text = [gettextCatalog.getString(CookieBannerHelperService.getCookiePreferenceLabel(cookies[0]))];
                cssClass = 'label-danger';
            } else if (cookies.length > 1 && cookies.length < 5) {
                cookies.forEach(function (cookie) {
                    // Don't show essential cookie on the view
                    if (cookie !== COOKIE_CATEGORIES.essential) {
                        text.push(gettextCatalog.getString(CookieBannerHelperService.getCookiePreferenceLabel(cookie)));
                    }
                });
                cssClass = 'label-warning';
            } else if (cookies.length === 5) {
                text = [gettextCatalog.getString('All')];
                cssClass = 'label-success';
            }

            return {
                text: text,
                cssClass: cssClass,
            };
        }

        function generateData () {
            return [
                {
                    timestamp: '2021-07-27T08:09:26.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.164 Safari/537.36',
                    country_code: 'DK',
                    anon_ip: '2a02:aa2:3252:3ad6::',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzEg9wZXJzb25hbGlzYXRpb24YhZqEhrUvIiRhZGYwNzAwYS03NWM3LTQ5OTktYTVmZi1hNzZhYTgzN2EzNzcqxwEKBGNjcGEKBGdkcHISABgBIAEoAzAAOABAAEoFZW4tVVNSQGh0dHBzOi8vbW9uc2lkby5jb20vZGF0YS1wcm90ZWN0aW9uLWFuZC1wcml2YWN5LXBvbGljeS1zdGF0ZW1lbnRaIWh0dHBzOi8vbW9uc2lkby5jb20vY29va2llLXBvbGljeWAAagsKByNmNTAwNTcSAHIFZW4tVVN6BmNvb2tpZYIBIAgBEhojY29va2llLW92ZXJ2aWV3LWNvbnRhaW5lchgAMgoIARABGAEgASgB',
                },
                {
                    timestamp: '2021-07-27T08:09:26.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.164 Safari/537.36',
                    country_code: 'DK',
                    anon_ip: '87.50.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzEg9wZXJzb25hbGlzYXRpb24YhZqEhrUvIiRhZGYwNzAwYS03NWM3LTQ5OTktYTVmZi1hNzZhYTgzN2EzNzcqxwEKBGNjcGEKBGdkcHISABgBIAEoAzAAOABAAEoFZW4tVVNSQGh0dHBzOi8vbW9uc2lkby5jb20vZGF0YS1wcm90ZWN0aW9uLWFuZC1wcml2YWN5LXBvbGljeS1zdGF0ZW1lbnRaIWh0dHBzOi8vbW9uc2lkby5jb20vY29va2llLXBvbGljeWAAagsKByNmNTAwNTcSAHIFZW4tVVN6BmNvb2tpZYIBIAgBEhojY29va2llLW92ZXJ2aWV3LWNvbnRhaW5lchgAMgoIARABGAEgASgB',
                },
                {
                    timestamp: '2021-07-27T08:09:15.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.164 Safari/537.36',
                    country_code: 'DK',
                    anon_ip: '87.50.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzEg9wZXJzb25hbGlzYXRpb24YhZqEhrUvIiRhZGYwNzAwYS03NWM3LTQ5OTktYTVmZi1hNzZhYTgzN2EzNzcqxwEKBGNjcGEKBGdkcHISABgBIAEoAzAAOABAAEoFZW4tVVNSQGh0dHBzOi8vbW9uc2lkby5jb20vZGF0YS1wcm90ZWN0aW9uLWFuZC1wcml2YWN5LXBvbGljeS1zdGF0ZW1lbnRaIWh0dHBzOi8vbW9uc2lkby5jb20vY29va2llLXBvbGljeWAAagsKByNmNTAwNTcSAHIFZW4tVVN6BmNvb2tpZYIBIAgBEhojY29va2llLW92ZXJ2aWV3LWNvbnRhaW5lchgAMgoIARABGAEgASgB',
                },
                {
                    timestamp: '2021-07-26T15:02:04.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Linux; Android 6.0.1; Moto G (4)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.164 Mobile Safari/537.36',
                    country_code: 'DK',
                    anon_ip: '87.50.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzGIzw9Ia1LyIkYWRmMDcwMGEtNzVjNy00OTk5LWE1ZmYtYTc2YWE4MzdhMzc3KscBCgRjY3BhCgRnZHByEgAYASABKAMwADgAQABKBWVuLVVTUkBodHRwczovL21vbnNpZG8uY29tL2RhdGEtcHJvdGVjdGlvbi1hbmQtcHJpdmFjeS1wb2xpY3ktc3RhdGVtZW50WiFodHRwczovL21vbnNpZG8uY29tL2Nvb2tpZS1wb2xpY3lgAGoLCgcjZjUwMDU3EgByBWVuLVVTegZjb29raWWCASAIARIaI2Nvb2tpZS1vdmVydmlldy1jb250YWluZXIYADIKCAEQARgBIAEoAA==',
                },
                {
                    timestamp: '2021-07-26T15:00:44.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Linux; Android 8.0.0; Pixel 2 XL Build/OPD1.170816.004) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.164 Mobile Safari/537.36',
                    country_code: 'DK',
                    anon_ip: '87.50.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzEg9wZXJzb25hbGlzYXRpb24YhZqEhrUvIiRhZGYwNzAwYS03NWM3LTQ5OTktYTVmZi1hNzZhYTgzN2EzNzcqxwEKBGNjcGEKBGdkcHISABgBIAEoAzAAOABAAEoFZW4tVVNSQGh0dHBzOi8vbW9uc2lkby5jb20vZGF0YS1wcm90ZWN0aW9uLWFuZC1wcml2YWN5LXBvbGljeS1zdGF0ZW1lbnRaIWh0dHBzOi8vbW9uc2lkby5jb20vY29va2llLXBvbGljeWAAagsKByNmNTAwNTcSAHIFZW4tVVN6BmNvb2tpZYIBIAgBEhojY29va2llLW92ZXJ2aWV3LWNvbnRhaW5lchgAMgoIARABGAEgASgB',
                },
                {
                    timestamp: '2021-07-26T14:45:32.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.164 Safari/537.36',
                    country_code: 'DK',
                    anon_ip: '87.50.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSD3BlcnNvbmFsaXNhdGlvbhijtPOGtS8iJGFkZjA3MDBhLTc1YzctNDk5OS1hNWZmLWE3NmFhODM3YTM3NyrHAQoEY2NwYQoEZ2RwchIAGAEgASgDMAA4AEAASgVlbi1VU1JAaHR0cHM6Ly9tb25zaWRvLmNvbS9kYXRhLXByb3RlY3Rpb24tYW5kLXByaXZhY3ktcG9saWN5LXN0YXRlbWVudFohaHR0cHM6Ly9tb25zaWRvLmNvbS9jb29raWUtcG9saWN5YABqCwoHI2Y1MDA1NxIAcgVlbi1VU3oGY29va2llggEgCAESGiNjb29raWUtb3ZlcnZpZXctY29udGFpbmVyGAAyCggBEAEYACAAKAE',
                },
                {
                    timestamp: '2021-07-26T14:21:57.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.55',
                    country_code: 'DK',
                    anon_ip: '31.3.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzEg9wZXJzb25hbGlzYXRpb24YhZqEhrUvIiRhZGYwNzAwYS03NWM3LTQ5OTktYTVmZi1hNzZhYTgzN2EzNzcqxwEKBGNjcGEKBGdkcHISABgBIAEoAzAAOABAAEoFZW4tVVNSQGh0dHBzOi8vbW9uc2lkby5jb20vZGF0YS1wcm90ZWN0aW9uLWFuZC1wcml2YWN5LXBvbGljeS1zdGF0ZW1lbnRaIWh0dHBzOi8vbW9uc2lkby5jb20vY29va2llLXBvbGljeWAAagsKByNmNTAwNTcSAHIFZW4tVVN6BmNvb2tpZYIBIAgBEhojY29va2llLW92ZXJ2aWV3LWNvbnRhaW5lchgAMgoIARABGAEgASgB',
                },
                {
                    timestamp: '2021-07-26T14:16:21.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.55',
                    country_code: 'DK',
                    anon_ip: '31.3.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSD3BlcnNvbmFsaXNhdGlvbhijtPOGtS8iJGFkZjA3MDBhLTc1YzctNDk5OS1hNWZmLWE3NmFhODM3YTM3NyrHAQoEY2NwYQoEZ2RwchIAGAEgASgDMAA4AEAASgVlbi1VU1JAaHR0cHM6Ly9tb25zaWRvLmNvbS9kYXRhLXByb3RlY3Rpb24tYW5kLXByaXZhY3ktcG9saWN5LXN0YXRlbWVudFohaHR0cHM6Ly9tb25zaWRvLmNvbS9jb29raWUtcG9saWN5YABqCwoHI2Y1MDA1NxIAcgVlbi1VU3oGY29va2llggEgCAESGiNjb29raWUtb3ZlcnZpZXctY29udGFpbmVyGAAyCggBEAEYACAAKAE',
                },
                {
                    timestamp: '2021-07-26T14:16:19.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.55',
                    country_code: 'DK',
                    anon_ip: '31.3.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzEg9wZXJzb25hbGlzYXRpb24YhZqEhrUvIiRhZGYwNzAwYS03NWM3LTQ5OTktYTVmZi1hNzZhYTgzN2EzNzcqxwEKBGNjcGEKBGdkcHISABgBIAEoAzAAOABAAEoFZW4tVVNSQGh0dHBzOi8vbW9uc2lkby5jb20vZGF0YS1wcm90ZWN0aW9uLWFuZC1wcml2YWN5LXBvbGljeS1zdGF0ZW1lbnRaIWh0dHBzOi8vbW9uc2lkby5jb20vY29va2llLXBvbGljeWAAagsKByNmNTAwNTcSAHIFZW4tVVN6BmNvb2tpZYIBIAgBEhojY29va2llLW92ZXJ2aWV3LWNvbnRhaW5lchgAMgoIARABGAEgASgB',
                },
                {
                    timestamp: '2021-07-26T14:16:02.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1 Edg/92.0.4515.107',
                    country_code: 'DK',
                    anon_ip: '31.3.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSCW1hcmtldGluZxIJYW5hbHl0aWNzGIzw9Ia1LyIkYWRmMDcwMGEtNzVjNy00OTk5LWE1ZmYtYTc2YWE4MzdhMzc3KscBCgRjY3BhCgRnZHByEgAYASABKAMwADgAQABKBWVuLVVTUkBodHRwczovL21vbnNpZG8uY29tL2RhdGEtcHJvdGVjdGlvbi1hbmQtcHJpdmFjeS1wb2xpY3ktc3RhdGVtZW50WiFodHRwczovL21vbnNpZG8uY29tL2Nvb2tpZS1wb2xpY3lgAGoLCgcjZjUwMDU3EgByBWVuLVVTegZjb29raWWCASAIARIaI2Nvb2tpZS1vdmVydmlldy1jb250YWluZXIYADIKCAEQARgBIAEoAA==',
                },
                {
                    timestamp: '2021-07-26T14:15:49.000+00:00',
                    page_url: 'http://localhost:8000/',
                    user_agent:
                        'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1 Edg/92.0.4515.107',
                    country_code: 'DK',
                    anon_ip: '31.3.0.0',
                    tc_string:
                        'CgASCWVzc2VudGlhbBIFYmFzaWMSD3BlcnNvbmFsaXNhdGlvbhijtPOGtS8iJGFkZjA3MDBhLTc1YzctNDk5OS1hNWZmLWE3NmFhODM3YTM3NyrHAQoEY2NwYQoEZ2RwchIAGAEgASgDMAA4AEAASgVlbi1VU1JAaHR0cHM6Ly9tb25zaWRvLmNvbS9kYXRhLXByb3RlY3Rpb24tYW5kLXByaXZhY3ktcG9saWN5LXN0YXRlbWVudFohaHR0cHM6Ly9tb25zaWRvLmNvbS9jb29raWUtcG9saWN5YABqCwoHI2Y1MDA1NxIAcgVlbi1VU3oGY29va2llggEgCAESGiNjb29raWUtb3ZlcnZpZXctY29udGFpbmVyGAAyCggBEAEYACAAKAE',
                },
            ];
        }
    }
})();
