(function () {
    'use strict';

    angular.module('modules.extension').component('extensionVisualLinkButton', {
        templateUrl: 'app/modules/extension/components/visual-link-button/visual-link-button.html',
        controller: ExtensionVisualLinkButtonController,
        controllerAs: 'vm',
        bindings: {
            type: '@?',
            page: '<',
            data: '<?',
            monDisabled: '<?',
            tooltipPlacement: '@?',
        },
    });

    ExtensionVisualLinkButtonController.$inject = ['extensionService', 'ng2GlobalHelperService'];
    /* @ngInject */
    function ExtensionVisualLinkButtonController (extensionService, ng2GlobalHelperService) {
        var vm = this;

        vm.$onInit = activate;
        vm.onClick = onClick;

        function activate () {
            vm.tooltipOpen = false;
            vm.loading = true;
            vm.extensionInstalled = null;
            extensionService.isInstalled().then(function (res) {
                vm.extensionInstalled = res;
                vm.loading = false;
            });
            vm.tooltipPlacement = vm.tooltipPlacement || 'top';
            vm.moduleName = ng2GlobalHelperService.getCurrentModule();
        }

        async function onClick () {
            const params = {
                page: vm.page,
            };

            if (vm.type) {
                params.type = vm.type;
            }

            if (vm.data) {
                params.data = vm.data;
            }

            await extensionService.openExtensionDeeplink(params);
        }
    }
})();
