<mon-spinner [loading]="loading">
    <mon-issue [id]="issueId"
              [title]="title"
              [status]="status"
              [reason]="reason"
              [issueNotFound]="!loading && !snippet && !error"
              class="accessibility-issue">
        <div slot="issue-action-button">
            <button class="btn btn-border-radius btn-secondary mr-1"
                    ngbTooltip="{{'Inspect page with browser extension' | translate}}"
                    (click)="openInExtension(currentPage)"
                    [container]="'body'"
                    *ngIf="currentPage && status !== issueStatusEnum.fixed">
                <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                <span class="sr-only" translate>Open browser extension</span>
            </button>
            <mon-action-menu class="mr-1" [monActionMenuItems]="dropdownOptions" *ngIf="status !== issueStatusEnum.fixed">
                <span translate>Action</span>
            </mon-action-menu>
        </div>

      <div slot="issue-overview" *ngIf="snippet && (error || currentPage)">
          <h3 class="font-weight-300 mt-3 mx-4" translate>Issue details</h3>
          <hr>
          <div class="row ml-1 mt-4">
              <div class="col-12">
                  <b translate>Effect on overall compliance level</b><br>
                  <span>{{ snippet.effect_on_compliance | number : '1.2-2' }} %</span>
              </div>
              <div class="col-12">
                  <b translate>Element</b><br>
                  <span>{{ snippetType }}</span>
              </div>
              <div class="col-12">
                  <b translate>Date found</b><br>
                  <span>{{ snippet.created_at }}</span>
              </div>
          </div>
          <div class="row ml-1 mr-4 mt-4">
              <div class="col">
                  <div class="row">
                      <div class="col">
                          <b translate>Snippet</b>
                      </div>
                  </div>
                  <div class="row mr-0">
                      <div class="col-48 bg-danger ml-3 mt-1 p-2" *ngIf="snippetType === 'Image'">
                        <div class="row">
                          <div class="col-8">
                            <mon-page-details-section-accessibility-image [model]="error" [page]="currentPage"></mon-page-details-section-accessibility-image>
                          </div>
                          <div class="col-40 pt-3">
                            {{ snippet.source_code }}
                          </div>
                        </div>
                      </div>
                      <div class="col-48 bg-danger ml-3 mt-1 p-2" *ngIf="snippetType !== 'Image'">
                            {{ snippet.source_code }}
                      </div>
                  </div>
              </div>
          </div>
          <div class="row ml-1 mr-4 my-4">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <b translate>Found on page</b>
                    </div>
                </div>
                <div class="ml-0">
                    <div class="row">
                        <div *ngIf="currentPage" class="col-48">
                            <mon-url-title [title]="currentPage.title" [url]="currentPage.url" [active]="false"></mon-url-title>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-48" *ngIf="issue">
            <div class="table-container-body">
              <table class="table table-fixed table-hover table-with-labels">
                <thead>
                  <tr>
                    <th class="col-3 pt-0"></th>
                    <th class="col-20 pt-0" translate>Check</th>
                    <th class="col-5 text-right pt-0" translate>Help center</th>
                    <th class="col-5 text-right pt-0" translate>Responsibility</th>
                    <th class="col-5 text-right pt-0" translate>Success criteria</th>
                    <th class="col-5 text-right pt-0">
                      <span translate>Difficulty</span>
                      <span class="fas fa-question-circle pl-1"
                            [attr.aria-hidden]="true"
                            ngbTooltip="{{'Indicates the difficulty to apply this fix' | translate}}">
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-v-middle text-center">
                      <mon-table-policy-type [tooltip]="(issue.result_type | translate) | titlecase"
                                              class="inline-block"
                                              [color]="getIconColorClass(issue.issue_abbr)"
                                              [isMobileCheck]="issue.mobile"
                                              [passed]="(issue.errors_count === 0 || issue.pages_count === 0) && issue.ignored === false"
                                              [target]="issue.result_type">
                      </mon-table-policy-type>
                    </td>
                    <td
                      class="text-v-middle"
                      [ngClass]="{ 'text-grey': issue.ignored === true || issue.errors_count === 0 }">
                      <p tabindex="0" class="my-0" [innerHTML]="issue.name"></p>
                    </td>
                    <td class="text-v-middle pr-4 text-right">
                      <mon-help-center-btn [check]="issue"></mon-help-center-btn>
                    </td>
                    <td class="text-v-middle text-right">{{ issue.responsibilities && issue.responsibilities.sort().join(', ') }}</td>
                    <td class="text-v-middle text-right">
                      <span tabindex="0">
                        <mon-icon [icon]="['USERS', 'DICTIONARY']"></mon-icon>
                        <span class="sr-only" translate>Success criteria</span>
                        {{ issue.issue_number }}
                      </span>
                    </td>
                    <td class="text-right text-v-middle">
                      <span class="label"
                            [ngClass]="{'label-info': issue.difficulty === 'Easy',
                                        'label-warning': issue.difficulty === 'Moderate',
                                        'label-danger': issue.difficulty === 'Difficult'}">
                            {{ issue.difficulty }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>

      <div slot="issue-content" *ngIf="issues">
        <h3 class="font-weight-300 mt-3 mx-4" translate>Other checks for this snippet</h3>
        <hr>

        <div class="col-48">
          <mon-table-container [collection]="issues"
                              (pageChangeEvent)="onContentPageChange($event, getPagesParams)"
                              (perPageChange)="onContentPerPageChange($event, getPagesParams)"
                              class="table-vertial-align"
                              [loading]="loading">
            <div class="table-container-body">
              <table class="table table-fixed table-hover table-with-labels">
                <thead>
                  <tr>
                    <th class="col-3"></th>
                    <th class="col-20" translate>Check</th>
                    <th class="col-5 text-right" translate>Help center</th>
                    <th class="col-5 text-right" translate>Responsibility</th>
                    <th class="col-5 text-right" translate>Success criteria</th>
                    <th class="col-5 text-right">
                      <span translate>Difficulty</span>
                      <span class="fas fa-question-circle pl-1"
                            [attr.aria-hidden]="true"
                            ngbTooltip="{{'Indicates the difficulty to apply this fix' | translate}}">
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let issue of issues;">
                    <td class="text-v-middle text-center">
                      <mon-table-policy-type [tooltip]="(issue.result_type | translate) | titlecase"
                                             class="inline-block"
                                             [color]="getIconColorClass(issue.issue_abbr)"
                                             [isMobileCheck]="issue.mobile"
                                             [passed]="(issue.errors_count === 0 || issue.pages_count === 0) && issue.ignored === false"
                                             [target]="issue.result_type">
                      </mon-table-policy-type>
                    </td>
                    <td
                      class="text-v-middle"
                      [ngClass]="{ 'text-grey': issue.ignored === true || issue.errors_count === 0 }">
                      <p tabindex="0" class="my-0" [innerHTML]="issue.name"></p>
                    </td>
                    <td class="text-v-middle pr-4 text-right">
                      <mon-help-center-btn [check]="issue"></mon-help-center-btn>
                    </td>
                    <td class="text-v-middle text-right">{{ issue.responsibilities && issue.responsibilities.sort().join(', ') }}</td>
                    <td class="text-v-middle text-right">
                      <span tabindex="0">
                        <mon-icon [icon]="['USERS', 'DICTIONARY']"></mon-icon>
                        <span class="sr-only" translate>Success criteria</span>
                        {{ issue.issue_number }}
                      </span>
                    </td>
                    <td class="text-right text-v-middle">
                      <span class="label"
                            [ngClass]="{'label-info': issue.difficulty === 'Easy',
                                        'label-warning': issue.difficulty === 'Moderate',
                                        'label-danger': issue.difficulty === 'Difficult'}">
                            {{ issue.difficulty }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mon-table-container>
        </div>
      </div>
    <div slot="issue-documents" *ngIf="pages">
        <h3 class="font-weight-300 mt-3 mx-4" translate>All pages with this accessibility issue</h3>
        <hr>
        <div class="m-4">
            <mon-table-container [collection]="pages"
                                [loading]="loading"
                                [noResultMessage]="'No content was found'"
                                (pageChangeEvent)="onContentPageChange($event, getPagesParams)"
                                (perPageChange)="onContentPerPageChange($event, getPagesParams)">
                <div class="table-container-body">
                    <table class="table table-hover table-fixed table-with-labels">
                        <thead>
                        <tr>
                            <th
                                monTableSort
                                [monSortBy]="'title'"
                                [monSortDirection]="getPagesParams.value.sort_dir"
                                [monCurrentlySortedBy]="getPagesParams.value.sort_by"
                                (monOnSorted)="onSortContent($event, getPagesParams)">
                                {{ 'Title and URL' | translate }}
                            </th>
                            <th class="col-6 text-right"
                                monTableSort
                                [monSortBy]="'priority_score'"
                                [monSortDirection]="getPagesParams.value.sort_dir"
                                [monCurrentlySortedBy]="getPagesParams.value.sort_by"
                                (monOnSorted)="onSortContent($event, getPagesParams)">
                                {{ 'Priority' | translate }}
                            </th>
                            <th class="pl-5 col-7 text-right"
                                monTableSort
                                *ngIf="viewsColumIsAvailable"
                                [monSortBy]="'hits'"
                                [monSortDirection]="getPagesParams.value.sort_dir"
                                [monCurrentlySortedBy]="getPagesParams.value.sort_by"
                                (monOnSorted)="onSortContent($event, getPagesParams)">
                                {{ 'Views' | translate }}
                            </th>
                            <th class="col-20 col-lg-8 col-md-12 col-sm-16 col-xl-6">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let page of pages">
                            <td>
                                <div class="text-ellipsis">
                                    <mon-url-title [title]="page.title" [url]="page.url" [active]="false"></mon-url-title>
                                </div>
                            </td>
                            <td class="text-v-middle text-right">
                                <mon-priority class="vcenter" [priority]="page.priority_score_as_text"></mon-priority>
                            </td>
                            <td *ngIf="viewsColumIsAvailable" class="pl-5 text-v-center text-right">
                                <mon-visits [title]="page.title" [visits]="page.hits" [totalVisits]="pages.pageHits || -1"></mon-visits>
                            </td>
                            <td class="text-v-middle">
                                <div class="d-flex align-items-center justify-content-end">
                                    <button class="btn btn-border-radius btn-secondary"
                                            ngbTooltip="{{'Open page details' | translate}}"
                                            (click)="goToPageDetails(page)"
                                            [container]="'body'">
                                        <mon-icon [icon]="['TABLES', 'PAGEDETAILS']"></mon-icon>
                                        <span class="sr-only" translate>Open page details</span>
                                    </button>
                                    <button class="btn btn-border-radius btn-secondary ml-1"
                                            ngbTooltip="{{'Inspect page with browser extension' | translate}}"
                                            (click)="openInExtension(page)"
                                            [container]="'body'">
                                        <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                                        <span class="sr-only" translate>Open browser extension</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mon-table-container>
        </div>
    </div>
    </mon-issue>
</mon-spinner>
