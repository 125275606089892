export const PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG = {
    '1': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '2': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '3': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '4': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '5': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '6': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '7': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '11': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '16': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
    },
    '18': {
        type: 'accessibility_check',
        imagePath: '/images/page-fix/links/new-window-th.png',
        snippet: '<a></a>',
        translation: 'Set confirmation \'{{value}}\' when user leaves the domain',
        check_description: 'The user should always be warned beforehand when a link opens a new window or tab.',
        work_types: ['links'],
        difficulty: 'easy',
        formComponent: 'pageFixFormNewWindow',
    },
    '27': {
        type: 'accessibility_check',
        imagePath: '/images/page-fix/design/blink-th.png',
        snippet: '<blink></blink>',
        translation: 'Element will be replaced with \'{{value}}\'',
        check_description:
            'The <blink> tag is obsolete. For the same effect CSS or Javascript should be used instead.',
        work_types: ['design'],
        difficulty: 'easy',
        formComponent: 'pageFixFormBlink',
    },
    '28': {
        type: 'accessibility_check',
        imagePath: '/images/page-fix/website-structure/skip-to-content-th.png',
        snippet: '<body data-mon-page-fix="done"></body>',
        translation: 'Set the text \'{{value}}\' on the Skip to Content container',
        check_description:
            'A “skip to content” link allows the user to jump over the material that is repeated on multiple pages (like the navigation menu) and land directly on the main content.',
        work_types: ['website_structure'],
        difficulty: 'hard',
        formComponent: 'pageFixFormSkipToContent',
    },
    '69': {
        type: 'accessibility_check',
        imagePath: '/images/page-fix/design/marquee-th.png',
        snippet: '<marquee></marquee>',
        translation: 'Element will be replaced with \'{{value}}\'',
        check_description: 'The <marquee> tag is obsolete and should not be used.',
        work_types: ['design'],
        difficulty: 'easy',
        formComponent: 'pageFixFormMarquee',
    },
    '116': {
        type: 'accessibility_check',
        imagePath: '/images/page-fix/design/bold-th.png',
        snippet: '<b></b>',
        translation: 'Element will be replaced with \'{{value}}\'',
        check_description:
            'Most screen readers do not notify the user of the bold element. <strong> tag should be used instead.',
        work_types: ['design'],
        difficulty: 'medium',
        formComponent: 'pageFixFormBold',
    },
    '117': {
        type: 'accessibility_check',
        imagePath: '/images/page-fix/design/italic-th.png',
        snippet: '<i></i>',
        translation: 'Element will be replaced with \'{{value}}\'',
        check_description:
            'Most screen readers do not notify the user of the italic element. <em> tag should be used instead.',
        work_types: ['design'],
        difficulty: 'medium',
        formComponent: 'PageFixFormItalic',
    },
    '178': {
        type: 'accessibility_source_code',
        translation: 'Alt attribute will have the text \'{{value}}\'',
        work_types: ['website_structure'],
        difficulty: 'medium', // TODO: Get the Correct values for difficulty & types here. https://zube.io/monsido/monsido/c/21757
    },
} as const;
