(function () {
    'use strict';

    angular.module('services.api').factory('PageAccessibilityRepo', PageAccessibilityRepo);

    PageAccessibilityRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function PageAccessibilityRepo (ng2ApiService, ng2SessionService) {
        var service = {
            updateError: updateError,
            getAllErrors: getAllErrors,
            updateCheck: updateCheck,
            getSummary: getSummary,
            getAllChecks: getAllChecks,
            getAllCheckErrors: getAllCheckErrors,
            rescan: rescan,
            markIssueAsFixed: markIssueAsFixed,
            markCheckAsFixed: markCheckAsFixed,
            updateCheckErrors: updateCheckErrors,
        };

        return service;

        // /////////////

        function getSummary (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/accessibility/summary',
                params,
                config,
            );
        }

        function getAllErrors (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/accessibility/errors', params, config);
        }

        function updateCheck (page_id, check, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/accessibility/checks/' + check.id,
                params,
                config,
            );
        }

        function getAllChecks (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/accessibility/checks/',
                params,
                config,
            );
        }

        function getAllCheckErrors (page_id, check_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/accessibility/checks/' + check_id + '/errors',
                params,
                config,
            );
        }

        function updateError (page_id, error, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/accessibility/errors/' + error.id,
                error,
                params,
                config,
            );
        }

        function rescan (pageId, data, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.put('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + pageId + '/accessibility/', data, params, config);
        }

        function markIssueAsFixed (pageId, error, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.domainGroup ? ng2SessionService.domainGroup.id : null;
            }
            return ng2ApiService.delete(
                'domains/' + ng2SessionService.domain.id + '/pages/' + pageId + '/accessibility/errors/' + error.id,
                params,
                config,
            );
        }

        function markCheckAsFixed (pageId, checkId, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.domainGroup ? ng2SessionService.domainGroup.id : null;
            }
            return ng2ApiService.delete(
                'domains/' + ng2SessionService.domain.id + '/pages/' + pageId + '/accessibility/checks/' + checkId + '/errors',
                params,
                config,
            );
        }

        function updateCheckErrors (page_id, check, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.domainGroup ? ng2SessionService.domainGroup.id : null;
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.domain.id + '/pages/' + page_id + '/accessibility/checks/' + check.id + '/errors',
                check,
                params,
                config,
            );
        }
    }
})();
