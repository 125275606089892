(function () {
    'use strict';

    angular.module('services.api').factory('MeReportingsRepo', MeReportingsRepo);

    MeReportingsRepo.$inject = ['ng2ApiService'];
    /* @ngInject */
    function MeReportingsRepo (ng2ApiService) {
        var service = {
            get: get,
            update: update,
            create: create,
        };

        return service;

        // /////////////

        function get (params, headers) {
            params = params || {};
            return ng2ApiService.get('me/reportings', params, headers);
        }

        function update (id, data, headers) {
            return ng2ApiService.patch('me/reportings/' + id, data, null, headers);
        }

        function create (params, headers) {
            return ng2ApiService.post('me/reportings', params, headers);
        }
    }
})();
