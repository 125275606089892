(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name domainService
     * @description Service to handle domains
     */
    angular.module('services.entities').factory('domainService', domainService);

    domainService.$inject = ['DomainRepo', 'ng2MonEventsService', 'ng2SessionService'];
    /* @ngInject*/
    function domainService (DomainRepo, ng2MonEventsService, ng2SessionService) {
        return {
            get: get,
            create: create,
            update: update,
            rescan: rescan,
            destroy: destroy,
            stopCrawl: stopCrawl,
        };

        /**
         * @memberOf domainService
         * @desc Get a Domain
         * @param {Number} id The Domain ID
         * @param {object} params Query Parameters
         * @param {object} config $http configuration
         * @returns {Promise} Promise containing the domain that is created
         */
        function get (id, params, config) {
            return DomainRepo.get(id, params, config);
        }

        /**
         * @memberOf domainService
         * @desc Create domain
         * @param {object} domain The domain to create
         * @returns {Promise} Promise containing the domain that is created
         */
        function create (domain) {
            return DomainRepo.create(domain).then(function (returnDomain) {
                ng2MonEventsService.run('domainAdded', returnDomain);
                return returnDomain;
            });
        }

        /**
         * @memberOf domainService
         * @desc Update domain
         * @param {number} id Id of the domain
         * @param {object} params Params of the domain to update
         * @returns {Promise} Promise containing the domain that is updated
         */
        function update (id, params) {
            return DomainRepo.update(id, params).then(function (returnDomain) {
                ng2MonEventsService.run('domainUpdated', returnDomain);
                return returnDomain;
            });
        }

        function rescan (id, params) {
            if (!id) {
                id = ng2SessionService.getDomainId();
            }
            return DomainRepo.rescan(id, params);
        }

        function destroy (id, params, headers) {
            return DomainRepo.destroy(id, params, headers);
        }

        function stopCrawl (id) {
            return DomainRepo.stopCrawl(id);
        }
    }
})();
