(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').component('privacyIgnoredChecks', {
        templateUrl: 'app/modules/data-privacy/pages/ignored-checks/ignored-checks.html',
        controller: DataPrivacyIgnoredChecksController,
        controllerAs: 'vm',
    });

    DataPrivacyIgnoredChecksController.$inject = [
        'PageRepo',
        'pagesTabsHelper',
        'activeFeatures',
        '$controller',
        'REGULATIONS',
        'RISKS',
        'gettextCatalog',
        '$filter',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DataPrivacyIgnoredChecksController (
        PageRepo,
        pagesTabsHelper,
        activeFeatures,
        $controller,
        REGULATIONS,
        RISKS,
        gettextCatalog,
        $filter,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.pageTitle = pageTitle;
        vm.getSubHeader = getSubHeader;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.currentSort = { by: 'notification_count', direction: 'desc' };
            vm.search = null;
            vm.pages = [];
            vm.tabs = pagesTabsHelper.getTabs();
            vm.activeTab = pagesTabsHelper.getActiveTab();
            vm.showFilters = activeFeatures.table_filters;
            vm.showNotifications = ['data-privacy'];
            vm.hideNotifications = vm.hideNotifications || [];
            vm.target = 'page-details-section-data-privacy';
            vm.filters = { 'Data law': [], 'Data risk': [] };
            vm.availableFilters = {
                'Data law': REGULATIONS,
                'Data risk': RISKS,
            };

            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                data_protection_violations: true,
                search: vm.search || null,
            };
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            if (vm.selectedLanguages && vm.selectedLanguages.length > 0) {
                params['languages[]'] = vm.selectedLanguages;
            }

            if (vm.activeTab) {
                params['types[]'] = [vm.activeTab];
            }

            vm.promise = PageRepo.getAll(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }

        function getSubHeader () {
            var single = '1 page with one or more failing checks';
            var plural = '{{pages}} pages with ignored checks';

            var scope = {
                pages: vm.pages.total,
            };

            return $filter('translateWithPlural')(single, vm.pages.total, plural, scope);
        }

        function pageTitle (title) {
            return title || '(' + gettextCatalog.getString('No title found') + ')';
        }
    }
})();
