(function () {
    'use strict';

    angular.module('pages.qa').component('searchLastScan', {
        templateUrl: 'app/pages/search/dashboard/last-scan/last-scan.html',
        controller: LastScanController,
        controllerAs: 'vm',
        bindings: {
            domainId: '<',
        },
    });

    LastScanController.$inject = ['CrawlsRepo', 'SearchRepo', '$filter', 'gettextCatalog', 'ng2SessionService', '$q', 'moment'];

    function LastScanController (CrawlsRepo, SearchRepo, $filter, gettextCatalog, ng2SessionService, $q, moment) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.notificationsList = [];
            setupLineChartSettings();
            var promises = [];
            promises.push(setupDoughnutChart());
            promises.push(setupLineChart());
            vm.promise = $q.all(promises).then(function (values) {
                setupNotifications(values[0], values[1]);
            }, angular.noop);
            vm.domain = ng2SessionService.domain;
        }

        function setupLineChartSettings () {
            vm.lineChartData = [];
            vm.lineChartLabels = [];
            vm.lineOverride = [
                {
                    type: 'line',
                    fill: false,
                    backgroundColor: '#da5c5b',
                    borderColor: '#da5c5b',
                },
                {
                    type: 'line',
                    fill: false,
                    backgroundColor: '#5bc0de',
                    borderColor: '#5bc0de',
                },
            ];
            vm.lineChartSeries = [gettextCatalog.getString('Policy matches'), gettextCatalog.getString('Searches')];
            vm.lineColors = ['#da5c5b', '#5bc0de'];
            vm.lineOptions = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                data.datasets[tooltipItem.datasetIndex].label +
                                ': ' +
                                $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            );
                        },
                    },
                },
            };
        }

        function setupLineChart () {
            var deferred = $q.defer();
            var params = {
                page_size: 5,
                domainId: vm.domainId,
            };
            if (ng2SessionService.domainGroup) {
                deferred.resolve([]);
            } else {
                for (var i = 0; i < vm.lineChartSeries.length; i++) {
                    vm.lineOverride.push({
                        fill: false,
                        type: 'line',
                        yAxisID: 'y-axis-0',
                    });
                }
                CrawlsRepo.getAll(params).then(function (data) {
                    var plainData = data.reverse();
                    var arr1 = [];
                        var arr2 = [];
                    for (var k = 0; k < plainData.length; k++) {
                        vm.lineChartLabels.push(moment(plainData[k].date).format('MMM DD'));
                        arr1.push(plainData[k].searches_with_unwanted_violations_count);
                        arr2.push(plainData[k].searches_with_normal_violations_count);
                    }
                    vm.lineChartData.push(arr1);
                    vm.lineChartData.push(arr2);
                    deferred.resolve(plainData);
                }, angular.noop);
            }
            return deferred.promise;
        }

        function setupDoughnutChart () {
            vm.doughnutLabels = ['', ''];
            vm.doughnutColors = ['#4dc0bd', '#DCDCDC'];
            vm.total = 0;
            vm.totalPages = 0;

            var params = {
                domainId: vm.domainId,
            };

            return SearchRepo.getSummary(params).then(function (data) {
                var pageCount = data.pages_count;
                    var pagesWithErrors = data.pages_with_policy_unwanted_violations;
                    var documentsWithErrors = data.documents_with_policy_unwanted_violations;
                    var totalErrors = pagesWithErrors + documentsWithErrors;
                    var totalCount = pageCount + data.documents_count;
                    var percentage = (totalErrors / totalCount) * 100;
                vm.doughnutData = [totalCount - totalErrors, totalErrors];

                var totalLabel = 'of ' + $filter('number')(pageCount) + ' ' + $filter('translateWithPlural')('page', pageCount, 'pages');
                if (vm.domain.scan.scan_documents) {
                    totalLabel +=
                        ' and ' +
                        $filter('number')(documentsWithErrors) +
                        ' ' +
                        $filter('translateWithPlural')('document', documentsWithErrors, 'documents');
                }

                vm.doughnutOptions = {
                    centerText: {
                        percentageLabel: $filter('number')(100 - percentage, 2) + ' %',
                        totalLabel: totalLabel,
                        subLabel: 'are in compliance',
                    },
                    cutoutPercentage: 70,
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                            },
                        },
                    },
                };
                return data;
            }, angular.noop);
        }

        function setupNotifications (summary, history) {
            var miniDigit;
            if (history.length > 1) {
                miniDigit =
                    add(['policies_with_unwanted_violations_count'], summary) -
                    add(['searches_with_unwanted_violations_count'], history[history.length - 2]);
            } else {
                miniDigit = 0;
            }
            vm.notificationsList = [
                {
                    colorClass: 'btn-danger',
                    title: gettextCatalog.getString('Policy matches'),
                    digit: add(['policies_with_unwanted_violations_count'], summary),
                    miniDigit: miniDigit,
                    affections: summary.pages_with_policy_unwanted_violations,
                    affectionsDocuments: summary.documents_with_policy_unwanted_violations,
                    tooltipTitle: gettextCatalog.getString('Contains policies that matches unwanted and required content'),
                },
            ];

            if (ng2SessionService.domainGroup) {
                // Clean history until domain groups also gets history data
                vm.notificationsList = vm.notificationsList.map(function (notification) {
                    delete notification.miniDigit;
                    return notification;
                });
            }
        }

        function add (ar, obj) {
            obj = obj || {};
            var i;
                var result = 0;
            for (i = 0; i < ar.length; i++) {
                result += obj[ar[i]] || 0;
            }
            return result;
        }
    }
})();
