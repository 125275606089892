(function () {
    'use strict';
    angular.module('modules.light.pages').component('domainsCardView', {
        templateUrl: 'app/modules/light/components/domainsCardView/domainsCardView.template.html',
        controller: DomainsCardViewComponent,
        controllerAs: 'vm',
        bindings: {
            domains: '<',
        },
    });

    DomainsCardViewComponent.$inject = [
        'FORM_CONSTANT',
        'LITE_DOMAIN_EVENTS',
        'activeFeatures',
        'coreDomainService',
        'domainService',
        'LabelRepo',
        'ng2MonEventsService',
    ];

    function DomainsCardViewComponent (
        FORM_CONSTANT,
        LITE_DOMAIN_EVENTS,
        activeFeatures,
        coreDomainService,
        domainService,
        LabelRepo,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.createDomain = createDomain;
        vm.pinnedDomains = [];

        vm.updateDomainByLabel = updateDomainByLabel;
        vm.getPage = getPage;

        function activate () {
            vm.showLabels = activeFeatures.labels;
            getLabels();
        }

        function updateDomainByLabel (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });
            return domainService.update(domain.id, params);
        }

        function getPage () {
            var paramsOptions = {};
            ng2MonEventsService.run(LITE_DOMAIN_EVENTS.LITE_DOMAINS_LIST_UPDATE_REQUIRED, paramsOptions);
        }

        function createDomain () {
            coreDomainService.openDomainForm(null, FORM_CONSTANT.LITE_DOMAIN_FORM).then(function (domain) {
                ng2MonEventsService.run(LITE_DOMAIN_EVENTS.LITE_DOMAIN_UPDATED, domain);
            });
        }

        function getLabels () {
            LabelRepo.getLabels().then(function (data) {
                vm.labels = data;
            }, angular.noop);
        }

        function onChanges (changes) {
            if (changes.domains) {
                vm.pinnedDomains = vm.domains.filter(function (domain) {
                    return domain.favorite;
                });
            }
        }
    }
})();
