(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('pageDetailsSectionQaSpell', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/subsections/spell/spell.html',
        controller: SpellingErrorsIndex,
        controllerAs: 'vm',
        bindings: {
            pageObj: '<',
            type: '@',
            headline: '@?',
            refreshPage: '&',
        },
    });

    SpellingErrorsIndex.$inject = [
        'ng2DirectNavigationService',
        'SpellingRepo',
        '$controller',
        'gettextCatalog',
        '$filter',
        'ng2ActiveFeatureService',
        'spellService',
        'ng2MonEventsService',
        'ng2MonModalService',
    ];
    /* @ngInject */
    function SpellingErrorsIndex (
        ng2DirectNavigationService,
        SpellingRepo,
        $controller,
        gettextCatalog,
        $filter,
        ng2ActiveFeatureService,
        spellService,
        ng2MonEventsService,
        ng2MonModalService,
    ) {
        var vm = this;
        vm.getPage = getPage;
        vm.confirmMisspelling = confirmMisspelling;
        vm.updatePage = updatePage;
        vm.$onInit = activate;
        vm.markMisspellingFixed = markMisspellingFixed;
        vm.ignoreMisspelling = ignoreMisspelling;
        vm.addToDictionary = addToDictionary;
        vm.addToAllDictionaries = addToAllDictionaries;
        vm.openIssueModal = openIssueModal;

        function activate () {
            vm.showAutofix = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_misspelling') && vm.type !== 'potential';
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.selected = [];
            vm.type = vm.type || 'confirmed';
            vm.search = null;
            vm.headline = vm.headline || gettextCatalog.getString('Misspellings');
            vm.words = [];
            vm.dropdowns = {};
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    faIcon: 'fas fa-language',
                    value: 'all',
                },
            ];

            SpellingRepo.getAllLanguages({ domainId: vm.domainId }).then(function (data) {
                angular.forEach(data, function (item) {
                    vm.tabs.push({
                        name: $filter('translateLanguage')(item.lang),
                        faIcon: 'fas fa-language',
                        value: item.lang,
                    });
                });

                if (vm.tabs.length > 0) {
                    vm.activeTab = vm.tabs[0].value;
                }

                if (vm.pageObj) {
                    vm.exports = [
                        {
                            name:
                                // prettier-ignore
                                vm.type === 'confirmed' ? gettextCatalog.getString('Confirmed misspellings') : gettextCatalog.getString('Potential misspellings'),
                            data: {
                                category: vm.type + '_misspelling',
                                category_ref: vm.pageObj.id,
                            },
                        },
                    ];
                }

                getPage();
            }, angular.noop);
            ng2MonEventsService.addListener('reloadMisspelling', function () {
                getPage();
                vm.refreshPage();
            });
        }

        function getPage () {
            vm.selected = [];
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                language: vm.activeTab === 'all' ? null : vm.activeTab,
                search: vm.search,
                classification: vm.type,
            };

            params.page_fixed = false;

            vm.promise = SpellingRepo.getAllPageSpellingErrors(vm.pageObj.id, params).then(function (data) {
                vm.words = data;
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const word of vm.words) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Add to dictionary'),
                        leftIcon: 'faBook',
                        action: ()=>addToDictionary(word),
                        eventParams: { action: 'add-to-dictionary-btn' },
                    },
                    {
                        label: gettextCatalog.getString('Add to dictionary for all languages'),
                        leftIcon: 'faAtlas',
                        action: ()=>addToAllDictionaries(word),
                        eventParams: { action: 'add-to-all-language-btn' },
                    },
                    {
                        label: gettextCatalog.getString('Ignore misspelling'),
                        leftIcon: 'faEyeSlash',
                        action: ()=>ignoreMisspelling(word),
                        eventParams: { action: 'ignore-misspelling-btn' },
                    },
                    {
                        label: gettextCatalog.getString('Mark as fixed'),
                        leftIcon: 'faCheck',
                        action: ()=>markMisspellingFixed(word),
                        eventParams: { action: 'mark-misspelling-fixed-btn' },
                    },
                ];

                word.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        disabled: item.disabled,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);

                            item.action();
                        },
                    };
                });
            }
        }

        function promptConfirmation (message, callback) {
            ng2MonModalService.confirm(message).then(callback);
        }

        function confirmMisspelling (word) {
            spellService.confirmWord(word.id, {}).then(function () {
                updatePage();
            }, angular.noop);
        }

        function markMisspellingFixed (word) {
            const message = gettextCatalog.getString('Are you sure you want to mark this misspelling as fixed?');
            promptConfirmation(message,()=>{
                spellService.destroyPageSpellingError(vm.pageObj.id, word.id).then(function () {
                    updatePage();
                }, angular.noop);
            });
        }

        function ignoreMisspelling (word) {
            const message = gettextCatalog.getString('Are you sure you want to ignore this misspelling?');
            promptConfirmation(message, ()=>{
                spellService.updatePageIgnoreSpellingError(vm.pageObj.id, word.id).then(function () {
                    updatePage();
                }, angular.noop);
            });
        }

        function updatePage () {
            vm.refreshPage();
            getPage();
        }

        function addToDictionary (word) {
            const message = gettextCatalog.getString('Are you sure you want to add the word <strong>{{word}}</strong> to the dictionary?', { word: word.word });
            promptConfirmation(message, ()=>{
                const params = {
                    word: word.word,
                    language: word.language,
                };

                SpellingRepo.addToDictionary(params).then(function () {
                    updatePage();
                }, angular.noop);
            });
        }

        function addToAllDictionaries (word) {
            const message = gettextCatalog.getString('Are you sure you want to add the word <strong>{{word}}</strong> to the dictionary for all languages?', { word: word.word });
            promptConfirmation(message,()=>{
                const params = {
                    word: word.word,
                };
                SpellingRepo.addToDictionary(params).then(function () {
                    updatePage();
                }, angular.noop);
            });
        }

        function openIssueModal (issue) {

            ng2DirectNavigationService.navigateToIssueOverlay({
                issueOverlayIssueId: issue.id,
                issueOverlayIssueType: vm.type === 'potential' ? 'potentialMisspellings' : 'misspellings',
                issueOverlayPageId: vm.pageObj?.id,
            });

        }
    }
})();
