(function () {
    'use strict';

    angular.module('modules.terms').component('privacyDialog', {
        templateUrl: 'app/modules/terms/popup/popup.html',
        controller: PrivacyPopupController,
        controllerAs: 'vm',
        bindings: {
            user: '<',
            close: '&',
            dismiss: '&',
            resolve: '&',
        },
    });

    PrivacyPopupController.$inject = ['terms', 'privacy_terms', '$sce', 'meService', 'ng2MonEventsService'];
    /* @ngInject */
    function PrivacyPopupController (terms, privacy_terms, $sce, meService, ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;

        function activate () {
            ng2MonEventsService.run('actionTrigger', { action: 'accept-terms-dialog-show' });
            vm.terms = $sce.trustAsHtml(privacy_terms);
        }

        function submit () {
            vm.saving = true;
            meService.update({ privacy_policy_version_accepted: terms.version }).then(
                function () {
                    ng2MonEventsService.run('actionTrigger', { action: 'accept-terms-dialog-accepted' });
                    vm.close();
                },
                function () {
                    vm.saving = false;
                },
            );
        }
    }
})();
