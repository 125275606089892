(function () {
    'use strict';

    angular.module('services.api').factory('PageRepo', PageRepo);

    PageRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'CacheFactory', 'ng2PageModelFactoryService'];
    /* @ngInject */
    function PageRepo (ng2ApiService, ng2SessionService, CacheFactory, ng2PageModelFactoryService) {
        var service = {
            getStatSummary: getStatSummary,
            getStatVisitors: getStatVisitors,
            updateSeoIssue: updateSeoIssue,
            getSeoIssues: getSeoIssues,
            getSeoIssueContent: getSeoIssueContent,
            get: get,
            getIncomingLinks: getIncomingLinks,
            getMetadata: getMetadata,
            getAll: getAll,
            update: update,
            allExistingPages: allExistingPages,
            rescan: rescan,
            getCmsUrl: getCmsUrl,
            getScanStatus: getScanStatus,
        };

        return service;

        // /////////////

        function getStatSummary (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/statistics/summary', params, config);
        }

        function getStatVisitors (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            config.cache = CacheFactory.get('stats');
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/statistics/visitors', params, config);
        }

        function updateSeoIssue (page_id, issue_id, data, params, headers) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/seo_issues/' + issue_id,
                data,
                params,
                headers,
            );
        }

        function getSeoIssueContent (page_id, issue_id, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/seo_issues/' + issue_id + '/content',
                params,
                headers,
            );
        }

        function getSeoIssues (page_id, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/seo_issues', params, headers);
        }

        function get (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id, params, config).then(toModel);
        }

        function getMetadata (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/metadata', params, config);
        }

        function getIncomingLinks (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/incoming', params, config);
        }

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages', params, config).then(toModels);
        }

        function update (page_id, data, params, headers) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id, data, params, headers);
        }

        function rescan (pageId, data, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId() + '/pages/' + pageId + '/rescan', data, params, config);
        }

        function getCmsUrl (pageId, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/pages/' + pageId + '/cms_url', params, config);
        }

        function getScanStatus (pageId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/pages/' + pageId + '/rescan/', params, config);
        }

        // Not under domains... & should not be. This function should ONLY be used by the spotlight application
        function allExistingPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get('pages', params, config).then(toModels);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return ng2PageModelFactoryService.getModel(model);
        }
    }
})();
