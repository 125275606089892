import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    MonMultiselectNextComponent,
    Option,
} from '@monsido/angular-shared-components/dist/angular-shared-components';

import { cloneDeep, debounce } from 'lodash';
import { DomainRepo, DomainRepoGetAllParams } from '@monsido/ng2/angularjs-providers/domain-repo/domain-repo.types';
import { Domain } from '@monsido/modules/models/api/domain';
import { DomainGroupsEntity } from '@monsido/modules/models/api/interfaces/domain.interface';
import { JSONValue } from '@monsido/modules/report-center/components/dashboard/types';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@client/app/modules/translate.module';

export enum DomainPickerValueType {
    'Domain' = 'Domain',
    'DomainGroup' = 'DomainGroup'
}

export interface DomainValue extends Domain {
    type: DomainPickerValueType.Domain
}
export interface DomainGroupValue extends DomainGroupsEntity {
    type: DomainPickerValueType.DomainGroup
}

export type DomainPickerValue = DomainValue | DomainGroupValue;

@Component({
    selector: 'mon-domain-picker',
    standalone: true,
    templateUrl: './domain-picker.component.html',
    imports: [
        MonMultiselectNextComponent,
        TranslateModule,
        FormsModule,
        CommonModule,
    ],
})
export class DomainPickerComponent implements OnInit, OnChanges {
    page = 1;
    pageSize = 50;
    domainsOptions: Option[] = [];
    flattenedDomains: (DomainValue | DomainGroupValue)[] = [];
    allDomainsFetched = false;
    domainsInfoMap: Record<Domain['id'], Domain> = {};
    domainGroupsInfoMap: Record<DomainGroupsEntity['id'], DomainGroupsEntity> = {};
    hasSelectedValueOnInit = false;
    search = '';

    @Input() value: DomainPickerValue[] | undefined;
    @Output() valueChange = new EventEmitter<DomainPickerValue[]>();

    _model: Option[] | undefined;
    set model (selectedOptions: Option[] | undefined) {
        this._model = selectedOptions;

        if (!selectedOptions) {
            this.valueChange.emit([]);
            return;
        }

        this.valueChange.emit(
            selectedOptions.map(option => option.value) as unknown as DomainPickerValue[],
        );
    }
    get model (): Option[] | undefined {
        return this._model;
    }

    constructor (
        @Inject('DomainRepo') private domainRepo: DomainRepo,
    ) {}

    async ngOnInit (): Promise<void> {
        if (this.hasSelectedValueOnInit) {
            return;
        }
        this.flattenedDomains = this.flattenedDomains.concat(await this.getDomains());
        this.setDomainsOptions(this.flattenedDomains);
    }

    async ngOnChanges (changes: SimpleChanges): Promise<void> {
        // Set selected value if it exists
        if (changes.value?.firstChange && changes.value?.currentValue?.length > 0) {
            this.hasSelectedValueOnInit = true;
            await this.setInitialSelectedOptions(changes.value.currentValue);
        }
    }

    async getMoreDomains (): Promise<void> {
        if (this.allDomainsFetched) {
            return;
        }

        this.page++;
        const nextPageFlattenDomains = await this.getDomains();
        if (nextPageFlattenDomains.length < 50) {
            this.allDomainsFetched = true;
        }
        this.flattenedDomains = this.flattenedDomains.concat(nextPageFlattenDomains);
        this.setDomainsOptions(this.flattenedDomains);
    }

    searchDomains = debounce(async (searchQuery: string) => {
        this.allDomainsFetched = false;
        this.page = 1;
        this.search = searchQuery;
        this.flattenedDomains = await this.getDomains();
        this.setDomainsOptions(this.flattenedDomains);
    }, 500);

    async getDomains (): Promise<DomainPickerValue[]> {
        const params: DomainRepoGetAllParams = {
            page: this.page,
            page_size: this.pageSize,
            sort_by: 'title',
            sort_dir: 'asc',
            mini: true,
        };
        if (this.search) {
            params.search = this.search;
        }

        const domains = await this.domainRepo.getAll(params);
        for (const domain of domains) {
            this.domainsInfoMap[domain.id] = domain;
        }
        return this.flattenDomains(domains);
    }

    private setDomainsOptions (domains: DomainPickerValue[]): void {
        this.domainsOptions = domains.map(domain => {
            return {
                value: domain as unknown as JSONValue,
                name: domain.title,
            };
        });

        if (!this._model) {
            return;
        }

        // If there are selected values, and it is not existing in the searched domains list,
        // Prepend them to the domains options list
        const domainIds = domains.map(domain => domain.id);
        const selectedOptions: Option[] = [];

        for (const option of this._model) {
            if (!domainIds.includes((option.value as unknown as DomainPickerValue).id)) {
                selectedOptions.push(option);
            }
        }
        this.domainsOptions = this.domainsOptions.concat(selectedOptions);
    }

    private flattenDomains (domains: Domain[], includeDomainGroups = true): (DomainValue | DomainGroupValue)[] {
        const result: (DomainValue | DomainGroupValue)[] = [];
        for (const domain of domains) {
            const domainValue: DomainValue = cloneDeep(domain) as DomainValue;
            domainValue.type = DomainPickerValueType.Domain;

            this.domainsInfoMap[domain.id] = domain;

            result.push(domainValue);

            if (domainValue.domain_groups && includeDomainGroups) {
                for (const group of domainValue.domain_groups) {
                    this.domainGroupsInfoMap[group.id] = group;
                    const domainGroupValue = cloneDeep(group) as DomainGroupValue;
                    domainGroupValue.type = DomainPickerValueType.DomainGroup;
                    result.push(domainGroupValue);
                }
            }
        }

        return result;
    }

    private async setInitialSelectedOptions (value: DomainPickerValue[]): Promise<void> {
        const selectedDomains: DomainValue[] = [];
        const selectedDomainGroups: DomainGroupValue[] = [];

        for (const selectedValue of value) {
            if (selectedValue.type === 'Domain') {
                selectedDomains.push(selectedValue);
            }

            if (selectedValue.type === 'DomainGroup') {
                this.domainGroupsInfoMap[selectedValue.id] = selectedValue;
                selectedDomainGroups.push(selectedValue);
            }
        }

        // Fetch all domains details by ids to get domains' URLs for displaying favicons
        Promise.all(
            selectedDomains.map(domainValue => {
                return this.domainRepo.get(domainValue.id);
            }),
        )
            .then(domainInfos => {
                for (const domainInfo of domainInfos) {
                    this.domainsInfoMap[domainInfo.id] = domainInfo;
                }
            })
        ;

        // Set the selected domain + domain group values to the multiselect
        const selectedFlattenedDomains = this.flattenDomains(selectedDomains, false);
        this.model = selectedFlattenedDomains
            .concat(selectedDomainGroups)
            .map(domainPickerValue => {
                return {
                    value: domainPickerValue as unknown as JSONValue,
                    name: domainPickerValue.title,
                };
            })
        ;

        // Fetch first PAGE_SIZE entries of domains
        this.flattenedDomains = await this.getDomains();
        this.setDomainsOptions(this.flattenedDomains);
    }
}
