(function () {
    'use strict';
    angular.module('modules.light.pages').component('lightDomains', {
        templateUrl: 'app/modules/light/pages/domains/domains.html',
        controller: LightDomainsComponent,
        controllerAs: 'vm',
        bindings: {
            onPageSort: '&',
            currentSort: '<?',
        },
    });

    LightDomainsComponent.$inject = [
        'LITE_DOMAIN_EVENTS',
        'LITE_DOMAINS_VIEWS',
        'DomainRepo',
        'ng2ErrorHandler',
        'domainService',
        'coreDomainService',
        'ng2MonEventsService',
        'ParamService',
        '$controller',
        'FORM_CONSTANT',
        'Lodash',
        '$stateParams',
    ];
    /* @ngInject */
    function LightDomainsComponent (
        LITE_DOMAIN_EVENTS,
        LITE_DOMAINS_VIEWS,
        DomainRepo,
        ng2ErrorHandler,
        domainService,
        coreDomainService,
        ng2MonEventsService,
        ParamService,
        $controller,
        FORM_CONSTANT,
        Lodash,
        $stateParams,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPage = getPage;
        vm.setView = setView;
        vm.tableViewParamName = LITE_DOMAINS_VIEWS.TABLE_VIEW;
        vm.showTableView = undefined;
        vm.showMonsidoNewsArea = false;
        vm.viewOptions = ['Card view', 'List view'];
        vm.changeView = changeView;

        vm.updateDomain = updateDomain;
        vm.updateDomainByLabel = updateDomainByLabel;

        vm.onFiltersChanges = onFiltersChanges;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.search = null;
            vm.domains = [];
            vm.showTableView = $stateParams.view === vm.tableViewParamName;
            getPage();

            ng2MonEventsService.addListener(LITE_DOMAIN_EVENTS.LITE_DOMAIN_UPDATED, changedDomain);
            ng2MonEventsService.addListener(LITE_DOMAIN_EVENTS.LITE_DOMAINS_LIST_UPDATE_REQUIRED, getPage);
        }

        function onDestroy () {
            ng2MonEventsService.remove(LITE_DOMAIN_EVENTS.LITE_DOMAIN_UPDATED);
            ng2MonEventsService.remove(LITE_DOMAIN_EVENTS.LITE_DOMAINS_LIST_UPDATE_REQUIRED);
        }

        function getPage (paramsOptions) {
            var params = {
                search: vm.search,
            };

            if (!vm.showTableView) {
                params.page_size = 0;
            } else {
                params.sort_by = 'favorite';
                params.sort_dir = 'asc';
            }

            if (paramsOptions) {
                params = Lodash.assign(params, paramsOptions);
            }

            if (vm.selectedLabels.length > 0) {
                params.labels = vm.selectedLabels;
            }

            vm.promise = DomainRepo.getAll(params).then(
                function (data) {
                    vm.domains = data;
                },
                function (error) {
                    ng2ErrorHandler.noopError(error);
                },
            );
        }

        function updateDomainByLabel (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });

            return domainService.update(domain.id, params);
        }

        function updateDomain (domain) {
            coreDomainService.openDomainForm(domain, FORM_CONSTANT.LITE_DOMAIN_FORM).then(function (updatedDomain) {
                changedDomain(updatedDomain);
            });
        }

        function changedDomain (domain) {
            if (!domain) {
                return;
            }
            // This will be executed when updating a domain
            var index = Lodash.findIndex(vm.domains, { id: domain.id });
            if (index > -1) {
                if (vm.domains[index].favorite !== domain.favorite) {
                    getPage();
                } else {
                    vm.domains[index] = domain;
                    vm.domains = Lodash.assign([], vm.domains);
                }
            } else {
                // This will be executed when creating a new domain
                if (vm.domains.length < vm.pageSize) {
                    vm.domains.push(domain);
                } else {
                    getPage();
                }
            }
        }

        function changeView (type) {
            if (type === 0) {
                setView(null);
            } else {
                setView(vm.tableViewParamName);
            }
        }

        function setView (view) {
            ParamService.setParams({
                view: view,
            });
            vm.showTableView = view === vm.tableViewParamName;
            getPage();
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }
    }
})();
