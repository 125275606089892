(function () {
    'use strict';
    /**
     * IconButton
     *
     * @memberof app.components
     * @ngdoc component
     * @name iconButton
     * @param {String} faIcon The fa icon so show
     * @param {Object} number Number to show (one way binding)
     * @param {String} positive Positive number
     * @param {String} negative Negative number
     * @param {String} neutral Negative number
     * @param {Object} changeTextColor Change the text color (one way binding)
     * @param {Object} disabled Disable icon (one way binding)
     * @param {Function} callback Callback function
     *
     */
    angular.module('app.components').component('iconButton', {
        transclude: true,
        templateUrl: 'app/components/icon-button/icon-button.html',
        controller: IconButtonController,
        controllerAs: 'vm',
        bindings: {
            faIcon: '@',
            number: '<',
            positive: '@?',
            tooltip: '@?',
            negative: '@?',
            neutral: '@?',
            changeTextColor: '<?',
            disabled: '<?',
            callback: '&?',
            monAriaLabel: '@?',
            monAriaIconText: '@?',
        },
    });

    IconButtonController.$inject = [];

    function IconButtonController () {
        var vm = this;

        vm.$onInit = activate;
        vm.evaluate = evaluate;

        function activate () {
            vm.textClass = {};
            vm.positive = vm.positive || 'text-success';
            vm.negative = vm.negative || 'text-danger';
            vm.neutral = vm.neutral || 'text-primary';
            vm.tooltip = vm.tooltip || '';
            vm.disabled = vm.disabled || false;
            vm.changeTextColor = vm.changeTextColor === undefined ? true : vm.changeTextColor;
        }

        function evaluate (change) {
            if (!change) {
                return {};
            }
            if (vm.disabled) {
                return 'text-grey';
            }
            var result = '';
            if (vm.number > 0) {
                result = vm.positive;
            } else if (vm.number < 0) {
                result = vm.negative;
            } else {
                result = vm.neutral;
            }
            return result;
        }
    }
})();
