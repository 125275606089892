(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name spellService
     * @description Service to handle Spell requests
     */
    angular.module('services.entities').factory('spellService', spellService);

    spellService.$inject = ['SpellingRepo'];

    /* @ngInject*/
    function spellService (SpellingRepo) {
        return {
            confirmWord: confirmWord,
            destroyIgnoredPageSpellingError: destroyIgnoredPageSpellingError,
            destroyPageSpellingError: destroyPageSpellingError,
            destroySpellingError: destroySpellingError,
            destroyWord: destroyWord,
            updatePageIgnoreSpellingError: updatePageIgnoreSpellingError,
        };

        function confirmWord (wordId, params, config) {
            return SpellingRepo.confirm(wordId, params, config);
        }

        function updatePageIgnoreSpellingError (pageId, errorId) {
            return SpellingRepo.updatePageIgnoreSpellingError(pageId, errorId);
        }

        function destroyPageSpellingError (page_id, error_id) {
            return SpellingRepo.destroyPageSpellingError(page_id, error_id);
        }

        function destroySpellingError (error_id) {
            return SpellingRepo.destroySpellingError(error_id);
        }

        function destroyIgnoredPageSpellingError (page_id, spelling_id, params, config) {
            return SpellingRepo.destroyIgnoredPageSpellingError(page_id, spelling_id, params, config);
        }

        function destroyWord (wordId, config) {
            return SpellingRepo.destroyWord(wordId, config);
        }
    }
})();
