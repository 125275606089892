(function () {
    'use strict';
    /**
     * @memberof modules.fastTrack
     * @ngdoc service
     * @name FastTrackTableService
     * @description Service to handle Fast Track Table Logical operations
     */
    angular.module('modules.fastTrack').service('FastTrackTableService', FastTrackTableService);

    FastTrackTableService.$inject = ['ng2FastTrackSessionService'];
    /* @ngInject*/
    function FastTrackTableService (ng2FastTrackSessionService) {
        var vm = this;
        vm.sourceCodeProperty = '';
        vm.complianceProperty = '';

        function init () {
            ng2FastTrackSessionService.obsSnippetComplianceProperty.subscribe(function (property) {
                vm.complianceProperty = property;
            });
            ng2FastTrackSessionService.obsSnippetSourceCodeProperty.subscribe(function (property) {
                vm.sourceCodeProperty = property;
            });

            return {
                floorOrCeil: floorOrCeil,
                getSourceCode: getSourceCode,
                getCompliance: getCompliance,
            };
        }
        return init();

        /**
         * @memberOf FastTrackTableService
         * @desc Customized rounding method, round only up to the 2 latest decimals
         * @param val - Number to evaluate
         */
        function floorOrCeil (val) {
            var num = val * 100;
            return (val > 99 ? Math.floor(num) : val < 1 ? Math.ceil(num) : Math.round(num)) / 100;
        }

        /**
         * @memberOf FastTrackTableService
         * @desc Get the source code property of the Snippet
         * @param snippet - Fast Track Snippet
         */
        function getSourceCode (snippet) {
            return snippet[vm.sourceCodeProperty];
        }

        /**
         * @memberOf FastTrackTableService
         * @desc The Compliance score of the Snippet
         * @param snippet - Fast Track Snippet
         */
        function getCompliance (snippet) {
            return snippet[vm.complianceProperty];
        }

        // PROTECTED
    }
})();
