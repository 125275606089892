(function () {
    'use strict';

    angular.module('services.api').factory('ReadabilityRepo', ReadabilityRepo);

    ReadabilityRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function ReadabilityRepo (ng2ApiService, ng2SessionService) {
        var service = {
            summary: summary,
            pages: pages,
        };

        return service;

        // /////////////

        function summary (params, config) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/readability/', params, config);
        }

        function pages (params, config) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/readability/pages', params, config);
        }
    }
})();
