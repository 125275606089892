(function () {
    'use strict';
    /**
     * @memberof modules.data_privacy
     * @ngdoc service
     * @name DataPrivacyService
     * @description Collection of helpers for Data Privacy Module
     */
    angular.module('modules.data_privacy.services').service('DataPrivacyService', DataPrivacyService);

    DataPrivacyService.$inject = ['ng2DataPrivacylikelihoodService', 'gettextCatalog', 'supportRequestService', 'Lodash'];

    /* @ngInject*/
    function DataPrivacyService (ng2DataPrivacylikelihoodService, gettextCatalog, supportRequestService, Lodash) {
        var vm = this;
        return {
            formatText: formatText,
            formatTextArray: formatTextArray,
            getViolationName: getViolationName,
            getViolationLikelihood: getViolationLikelihood,
            requestDataPrivacySupport: requestDataPrivacySupport,
            getPagesWithActiveViolationCount: getPagesWithActiveViolationCount,
            getTotalPageCount: getTotalPageCount,
        };

        /**
         * @memberOf DataPrivacyService
         * @desc Capitalize and replace underscore with space character
         * @param {String} text - Input text that need to be formatted
         * @return {String}
         */
        function formatText (text) {
            if (text) {
                return (
                    text.charAt(0).toUpperCase() +
                    text
                        .substring(1, text.length)
                        .toLowerCase()
                        .replace(/_/g, ' ')
                );
            } else {
                return '';
            }
        }

        /**
         * @memberOf DataPrivacyService
         * @desc Capitalize and replace underscore with space character
         * @param {String Array} textArray - Input String Array that need to be formatted into concatenated string
         * @param {Boolean} regulations - bool to determine if regulations an therefor preserve capitalization
         * @return {String}
         */
        function formatTextArray (textArray, regulations) {
            var text = '';
            if (textArray.length > 0 && !regulations) {
                for (var i = 0; i < textArray.length; i++) {
                    if (i == textArray.length - 1) {
                        text += formatText(textArray[i]);
                    } else {
                        text += formatText(textArray[i]) + ', ';
                    }
                }
            }
            if (textArray.length > 0 && regulations) {
                text = textArray.join(', ');
            }
            return text;
        }

        /**
         * @desc Return translated violation info type name
         * @param violation - violation we want translation for.
         */
        function getViolationName (violation) {
            if (violation) {
                return gettextCatalog.getString(violation.info_type.name);
            } else {
                return gettextCatalog.getString('Violation\'s info_type name not set');
            }
        }
        function requestDataPrivacySupport (msg) {
            var params = {
                code: 'data-privacy',
                message: msg,
            };
            return supportRequestService.create(params);
        }

        function getViolationLikelihood (violation) {
            return ng2DataPrivacylikelihoodService.getViolationLikelihood(violation);
        }

        /**
         * @desc Return info type domain summary pages_with_active_violations_count value
         * @param infoType - The info type object that we want to access.
         */
        function getPagesWithActiveViolationCount (infoType) {
            return Lodash.get(infoType, 'info_type_domain_summary[0][\'pages_with_active_violations_count\']', 0);
        }

        /**
         * @desc Return info type domain summary total_pages_count value
         * @param infoType - The info type object that will be accessed
         */
        function getTotalPageCount (infoType) {
            return (
                Lodash.get(infoType, 'info_type_domain_summary[0][\'pages_with_active_violations_count\']', 0) +
                Lodash.get(infoType, 'info_type_domain_summary[0][\'pages_without_active_violations_count\']', 0)
            );
        }
    }
})();
