import { AuditLogModel } from 'app/models/audit-log.model';

(function () {
    'use strict';

    angular.module('services.api').factory('AuditRepo', AuditRepo);

    AuditRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function AuditRepo (ng2ApiService, ng2SessionService) {
        return {
            getAuditLogs: getAuditLogs,
        };

        // /////////////

        function getAuditLogs (params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('audit_logs', params, headers).then(toModels);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new AuditLogModel(model);
        }
    }
})();
