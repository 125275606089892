(function () {
    'use strict';

    angular.module('filters.monsido').filter('isPastDate', IsPastDateFilter);

    IsPastDateFilter.$inject = ['moment'];

    /* @ngInject */
    function IsPastDateFilter (moment) {
        return isPastDate;

        // //////////

        function isPastDate (start, end) {
            if (angular.isDefined(start)) {
                end = angular.isDefined(end) ? moment(end) : moment();
                return moment(start).isBefore(end);
            }
        }
    }
})();
