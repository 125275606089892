(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditScoreService
     * @description Categorize performance audit score
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceAuditScoreService', PagePerformanceAuditScoreService);

    PagePerformanceAuditScoreService.$inject = [];

    /* @ngInject*/
    function PagePerformanceAuditScoreService () {
        return {
            getProperScoreNo: getProperScoreNo,
            hasScorePassed: hasScorePassed,
            hasScoreAverage: hasScoreAverage,
            hasScoreFailed: hasScoreFailed,
        };

        /**
         * @memberOf PagePerformanceAuditScoreService
         * @desc Get Audit Score value
         * @param {Number | null} score - Audit Object
         * @return {Number}
         */
        function getProperScoreNo (score) {
            if (score === null) {
                return 0;
            }
            return score;
        }

        /**
         * @memberOf PagePerformanceAuditScoreService
         * @desc Check if the audit score is a passed value
         * @param {Number} score - Score number 0 - 100
         * @return {Boolean}
         */
        function hasScorePassed (score) {
            return score >= 90 && score <= 100;
        }

        /**
         * @memberOf PagePerformanceAuditScoreService
         * @desc Check if the audit score is a Average value
         * @param {Number} score - Score number 0 - 100
         * @return {Boolean}
         */
        function hasScoreAverage (score) {
            return score >= 50 && score <= 89;
        }

        /**
         * @memberOf PagePerformanceAuditScoreService
         * @desc Check if the audit score is a Failed value
         * @param {Number} score - Score number 0 - 100
         * @return {Boolean}
         */
        function hasScoreFailed (score) {
            return score !== null && score >= 0 && score <= 49;
        }
    }
})();
