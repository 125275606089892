(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditDetailsService
     * @description Service to handle data of the Page Performance display "binary"
     */
    angular.module('modules.page-performance').service('PagePerformanceAuditDetailsService', PagePerformanceAuditDetailsService);

    PagePerformanceAuditDetailsService.$inject = [
        '$filter',
        'moment',
        'gettextCatalog',
        'PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE',
    ];

    /* @ngInject*/
    function PagePerformanceAuditDetailsService ($filter, moment, gettextCatalog, PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE) {
        return {
            getFormatAuditItem: getFormatAuditItem,
            isTypeTable: isTypeTable,
            isTypeOpportunity: isTypeOpportunity,
            isTypeCriticalRequestChain: isTypeCriticalRequestChain,
            getTableHeadingClass: getTableHeadingClass,
            getBytesSnippet: getBytesSnippet,
            getMSSnippet: getMSSnippet,
            getTextHTMLSnippet: getTextHTMLSnippet,
        };

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get class of heading type
         * @param {Object} heading - Table heading object
         */
        function getTableHeadingClass (heading) {
            var type = heading.itemType || heading.valueType;
            if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.THUMBNAIL) {
                return 'table-column-thumbnail';
            }

            return '';
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Check if Audit Detail type is of type table
         * @param {String} type - Audit Detail Type
         */
        function isTypeTable (type) {
            return type === 'table';
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Check if Audit Detail type is of type Opportunity
         * @param {String} type - Audit Detail Type
         */
        function isTypeOpportunity (type) {
            return type === 'opportunity';
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Check if Audit Detail type is of type Critical Request Chain
         * @param {String} type - Audit Detail Type
         */
        function isTypeCriticalRequestChain (type) {
            return type === 'criticalrequestchain';
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Format the audit item by it's heading type
         * @param {String} item - Audit Item reported
         * @param {String} heading - Audit Heading
         */
        function getFormatAuditItem (item, heading) {
            var type = heading.itemType || heading.valueType;
            if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.URL) {
                return getURLHTMLSnippet(item);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.NODE) {
                return getNodeHTMLSnippet(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.TEXT) {
                return getTextHTMLSnippet(item[heading.key]);
            } else if (
                type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.CODE &&
                item.subItems &&
                item.subItems.items &&
                item.subItems.items.length > 0
            ) {
                return getCodeOfSubItems(item.subItems);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.CODE) {
                return getCodeHTMLSnippet(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.NUMERIC) {
                return getNumericHTMLSnippet(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.THUMBNAIL) {
                return getThumbnailSnippet(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.MS) {
                return getMSSnippet(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.TIMESPAN_MS) {
                return getTimespanMs(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.BYTES) {
                return getBytesSnippet(item[heading.key]);
            } else if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.LINK) {
                return getLinkSnippet(item[heading.key]);
            }
            return item[heading.key];
        }

        // PROTECTED

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get Plain Text of Table Detail
         * @param {String} text
         */
        function getTextHTMLSnippet (text) {
            return text;
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get text value of code object
         * @param {Object|String} code
         */
        function getCodeOfSubItems (code) {
            if (code.value) {
                var preEl = document.createElement('pre');
                preEl.innerText = code.value;
                return preEl.outerHTML;
            } else if (typeof code === 'string') {
                return code;
            }
            // TODO: Handle subitems
            return '';
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get text value of code object
         * @param {Object|String} code
         */
        function getCodeHTMLSnippet (code) {
            if (code.value) {
                var preEl = document.createElement('pre');
                preEl.innerText = code.value;
                return preEl.outerHTML;
            } else if (typeof code === 'string') {
                return code;
            }
            return '';
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Format number value of argument
         * @param {Number} numeric
         */
        function getNumericHTMLSnippet (numeric) {
            return $filter('monNumber')(numeric);
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get string HTML value of node
         * @param {Object} item
         */
        function getNodeHTMLSnippet (item) {
            if (!item) {
                return '';
            }
            var containerEl = document.createElement('div');
            var strongEl = document.createElement('strong');
            var newLineEl = document.createElement('br');
            var preEl = document.createElement('pre');
            strongEl.innerText = item.nodeLabel;
            preEl.innerText = item.snippet;
            containerEl.append(strongEl);
            containerEl.append(newLineEl);
            containerEl.append(preEl);
            return containerEl.innerHTML;
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get URL HTML snippet
         * @param {Object} item
         */
        function getURLHTMLSnippet (item) {
            var containerEl = document.createElement('div');
            var anchorEl = document.createElement('a');
            var spanEl = document.createElement('span');
            anchorEl.target = '_blank';
            anchorEl.href = item.href || item.url;
            if (anchorEl.pathname.length > 1) {
                anchorEl.innerText = anchorEl.pathname;
                spanEl.innerHTML = '&nbsp;-&nbsp;(' + anchorEl.hostname + ')';
                spanEl.classList.add('text-grey');
            } else {
                anchorEl.innerText = anchorEl.hostname;
            }

            containerEl.append(anchorEl);
            containerEl.append(spanEl);
            return containerEl.innerHTML;
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get image HTML value
         * @param {String} url
         */
        function getThumbnailSnippet (url) {
            var imageEl = document.createElement('img');
            imageEl.src = url;
            imageEl.title = url;
            imageEl.alt = '';
            imageEl.classList.add('page-performance-thumbnail');
            return imageEl.outerHTML;
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get ms formatted value
         * @param {Number} ms
         */
        function getMSSnippet (ms) {
            if (ms === 0) {
                return gettextCatalog.getString('None');
            } else if (ms < 30000) {
                return gettextCatalog.getString('{{ms}} ms', { ms: $filter('monNumber')(Math.ceil(ms)) });
            }

            return moment.duration(ms, 'ms').humanize();
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get timespan formatted value
         * @param {Number} ms
         */
        function getTimespanMs (ms) {
            if (ms === 0) {
                return gettextCatalog.getString('None');
            }

            return gettextCatalog.getString('{{ms}} ms', { ms: Math.ceil(ms) });
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get Bytes formatted value
         * @param {Number} bytes
         */
        function getBytesSnippet (bytes) {
            return $filter('numeraljs')(bytes, '0 ib');
        }

        /**
         * @memberOf PagePerformanceAuditDetailsService
         * @desc Get HTML entity value
         * @param {Number} entity
         */
        function getLinkSnippet (entity) {
            if (entity.type === 'link') {
                var anchorEl = document.createElement('a');
                anchorEl.href = entity.url;
                anchorEl.innerText = entity.text;
                anchorEl.target = '_blank';
                return anchorEl.outerHTML;
            }
            return entity;
        }
    }
})();
