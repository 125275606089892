import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { OauthService } from '@monsido/ng2/external/oauth/oauth.service';
import { tokenErrorMessages } from '../token-issue-messages.constant';


@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

    private waitUntilTokenRefreshed$?: Subject<null>;
    private canRequestRefreshToken = true;
    private refreshTokenRequestInterval = 300000;

    constructor (
        private oauthService: OauthService,
    ) {}

    intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (this.waitUntilTokenRefreshed$ && !this.isOauthRequest(request.url)) {
            return this.waitUntilTokenRefreshed$.pipe(
                switchMap(() => {
                    return next.handle(
                        this.oauthService.updateRequestToken(request),
                    );
                }),
            );
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (this.issueWithAccessToken(error)) {
                    // In case the request was sent before the new token acquired
                    if (this.oauthService.requestUsesWrongToken(request)) {
                        return next.handle(
                            this.oauthService.updateRequestToken(request),
                        );
                    }

                    if (this.canRequestRefreshToken) {
                        this.pauseRefreshRequests();

                        if (!this.waitUntilTokenRefreshed$) {
                            this.waitUntilTokenRefreshed$ = new Subject();
                        }

                        return this.oauthService.refreshAccessToken().pipe(
                            switchMap(() => {
                                return next.handle(
                                    this.oauthService.updateRequestToken(request),
                                );
                            }),
                            tap(() => {
                                setTimeout(() => {
                                    this.releaseWaitUntilToken();
                                });
                            }),
                            catchError(() => {
                                this.releaseWaitUntilToken();
                                // If we weren't able to refresh the token,
                                // we just pass the original error further,
                                // so it may be handled by the request sender
                                return throwError(error);
                            }),
                        );
                    }
                }
                return throwError(error);
            }),
        );
    }

    private isOauthRequest (url: string): boolean {
        return url.endsWith('/oauth/token');
    }

    private releaseWaitUntilToken (): void {
        this.waitUntilTokenRefreshed$?.next(null);
        this.waitUntilTokenRefreshed$?.complete();
        delete this.waitUntilTokenRefreshed$;
    }

    private issueWithAccessToken (error: HttpErrorResponse): boolean {
        return Boolean(error && error.status === 401 && error.error && tokenErrorMessages.includes(error.error.message));
    }

    private pauseRefreshRequests (): void {
        this.canRequestRefreshToken = false;

        setTimeout(() => {
            this.canRequestRefreshToken = true;
        }, this.refreshTokenRequestInterval);
    }

}
