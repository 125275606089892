import { Component, Inject, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DomainRepo } from '../../../../../angularjs-providers/domain-repo/domain-repo.types';
import { MeService } from '../../../../../angularjs-providers/me-service/me-service.types';
import {
    ReportCenterService,
} from '../../../../../angularjs-providers/report-center-service/report-center-service.types';
import { TextSearchService } from '@monsido/services/text-search/text-search.service';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Domain, DomainType } from '@monsido/modules/models/api/domain';
import { Report } from '@monsido/modules/models/api/report';

@Component({
    selector: 'mon-send-me-report-form',
    templateUrl: './send-me-report-form.component.html',
    styleUrls: ['./send-me-report-form.component.scss'],
})
export class SendMeReportFormComponent implements OnInit {
    private domainCollections: Domain[] = [];
    domainType = DomainType;
    saving: boolean = false;
    search: string = '';
    domains: Domain[] = [];
    report: Report | null = null;
    open: boolean = true;
    targetDomain: {id: number; type: DomainType} | null = null;
    promise?: Promise<unknown>;
    loading: boolean = false;

    constructor (
        @Inject('DomainRepo') private domainRepo: DomainRepo,
        @Inject('meService') private meService: MeService,
        @Inject('ReportCenterService') private reportCenterService: ReportCenterService,
        private searchService: TextSearchService,
        private activeDialog: ActiveDialog,
    ) {}

    ngOnInit (): void {
        this.promise = this.getDomains();
    }

    private async getDomains (): Promise<void> {
        try {
            this.loading = true;
            const domains = await this.domainRepo.getAll({ page_size: 1000 });
            if (Array.isArray(domains)) {
                if (this.report) {
                    this.domains = this.reportCenterService.filterDomainsByReport(this.report, domains);
                }
                this.domainCollections = cloneDeep(this.domains);
            }
        } finally {
            this.loading = false;
        }
    }

    save (): void {
        if (this.targetDomain) {
            this.saving = true;

            this.meService.createReporting({
                template_id: this.report?.template.id || -1,
                target_type: this.targetDomain.type,
                target_id: this.targetDomain.id,
            })
                .then(this.activeDialog.close)
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    onSearch (search: string): void {
        this.search = search;
        this.domains = this.domainCollections.map((domain: Domain) => {
            const domainGroups = domain.domain_groups?.filter((domainGroup) => {
                return this.searchService.fuzzySearch(search, domainGroup.title);
            });

            return {
                ...domain,
                domain_groups: domainGroups,
            };
        }).filter((domain: Domain) => domain.domain_groups?.length ||
            this.searchService.fuzzySearch(search, domain.title) ||
            this.searchService.fuzzySearch(search, domain.url));
    }
}
