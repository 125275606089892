(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableAccessibility', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/accessibility/accessibility.html',
        controller: AccessibilityController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
            domainGroup: '<',
        },
    });

    AccessibilityController.$inject = [
        '$filter',
        'complianceService',
        'historyHelper',
        'ng2AccessibilityGuidelineVersionService',
        'ng2ActiveFeatureService',
        'exporter',
        'historyPrepareService',
        'ng2MonUtilsService',
    ];
    /* @ngInject */
    function AccessibilityController (
        $filter,
        complianceService,
        historyHelper,
        ng2AccessibilityGuidelineVersionService,
        ng2ActiveFeatureService,
        exporter,
        historyPrepareService,
        ng2MonUtilsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.$onChanges = update;
        vm.historyOutput = [];
        vm.totalCount = 100;
        vm.allowVerticalAverage = false;

        function activate () {
            exporter.connectToCSVExporter('accessibility', function () {
                return vm.historyOutput;
            });
        }

        function onDestroy () {
            exporter.disconnectFromCSVExporter('accessibility');
        }

        function update (changes) {
            vm.crawlAttribute = 'accessiblity_issues_count';
            vm.guidelines = [
                {
                    icon: 'bubble wcag-a',
                    attr: 'A',
                    guidelineId: 'WCAG2-A',
                },
                {
                    icon: 'bubble wcag-aa',
                    attr: 'AA',
                    guidelineId: 'WCAG2-AA',
                },
                {
                    icon: 'bubble wcag-aaa',
                    attr: 'AAA',
                    guidelineId: 'WCAG2-AAA',
                },
                {
                    icon: 'bubble wcag-a',
                    attr: 'A',
                    guidelineId: 'WCAG21-A',
                },
                {
                    icon: 'bubble wcag-aa',
                    attr: 'AA',
                    guidelineId: 'WCAG21-AA',
                },
                {
                    icon: 'bubble wcag-aaa',
                    attr: 'AAA',
                    guidelineId: 'WCAG21-AAA',
                },
                {
                    icon: 'bubble wcag-a',
                    attr: 'A',
                    guidelineId: 'WCAG21-A_mobile',
                },
                {
                    icon: 'bubble wcag-aa',
                    attr: 'AA',
                    guidelineId: 'WCAG21-AA_mobile',
                },
                {
                    icon: 'bubble wcag-aaa',
                    attr: 'AAA',
                    guidelineId: 'WCAG21-AAA_mobile',
                },
            ];
            if (changes.history) {
                prepareHistoryOutput(vm.history).then(function (result) {
                    vm.historyOutput = result;
                }, angular.noop);
            }
            vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
            vm.historyOutput = historyPrepareService.accessibility(vm.history);
        }

        function prepareHistoryOutput (history) {
            return ng2AccessibilityGuidelineVersionService.getVersions(history).then(function (versionChanges) {
                var result = [];
                var len = history.length;
                var story;
                var entry;
                for (var i = 0; i < len; i += 1) {
                    story = history[i];
                    entry = {
                        id: story.id,
                        post_processing_done_at: story.post_processing_done_at,
                        date: story.date,
                        page_count: $filter('monNumber')(story.page_count),
                        error: getError(story),
                        changePercentageValue: $filter('monNumber')(getChangePercentageValue(story.id) / 100, '0.[00]%'),
                        hasCompliance: hasCompliance(story),
                        changeValueColorClass: getChangeValueColorClass(story),
                        changeValueIconClass: getChangeValueIconClass(story),
                        accessibility_checks: story.accessibility_checks,
                        accessiblity_issues_count: $filter('monNumber')(story.accessiblity_issues_count),
                    };
                    var totalCount = vm.totalCount || 1;
                    entry.compliancePercentage = ng2MonUtilsService.withinHundred(1 - entry.error / totalCount) * 100;

                    if (vm.allowVerticalAverage && story.vertical_scores && entry.hasCompliance) {
                        var comparisonToAverage = Math.round(entry.compliancePercentage - story.vertical_scores.accessibility) / 100;
                        entry.comparisonToAverage = $filter('monNumber')(comparisonToAverage, '0.[00]%');
                        entry.comparisonToAverageStyle = ng2MonUtilsService.getTextStyleByNumberValue(comparisonToAverage);
                    }

                    for (var j = 0; j < versionChanges.length; j++) {
                        var versionChange = versionChanges[j];
                        if (story.post_processing_done_at === versionChange.time) {
                            entry.versionChange = versionChange;
                            break;
                        }
                    }
                    result.push(entry);
                }

                return result;
            }, angular.noop);
        }

        function getChangePercentageValue (id) {
            return historyHelper.getChangePercentageValue(id, vm.history, vm.crawlAttribute, 'accessibility');
        }

        function hasCompliance (history) {
            return getError(history) !== null;
        }

        function getError (history) {
            var complianceAr = complianceService.accessibilityHistoryCompliance(history);
            if (Array.isArray(complianceAr)) {
                if (complianceService.hasLegacyCompliance()) {
                    vm.totalCount = complianceAr[0] + complianceAr[1];
                }
                return complianceAr[1];
            } else {
                return null;
            }
        }

        function getChangeValueColorClass (story) {
            var value = getChangeValue(story.id);
            return historyHelper.getChangeColorClass(value);
        }

        function getChangeValueIconClass (story) {
            var value = getChangeValue(story.id);
            return historyHelper.getChangeIconClass(value);
        }

        // PROTECTED

        function getChangeValue (id) {
            return historyHelper.getChangeValue(id, vm.history, vm.crawlAttribute, 'accessibility');
        }

    }
})();
