import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/ng2/modules/endpoints/api/api-client';

type ApiConfigType = {
    headers?: Record<string, string>;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor (
        private client: ApiClient,
    ) {
        this.client.setHeader('Accept-Version', 'v1');
    }

    setHeader (name: string, value: string): void {
        this.client.setHeader(name, value);
    }

    removeHeader (name: string): void {
        this.client.removeHeader(name);
    }

    setUrlPrefix (urlPart: string): void {
        this.client.setUrlPrefix(urlPart);
    }

    get<T = unknown> (url: string, params: Record<string, string> = {}, config?: ApiConfigType): Promise<T> {
        return this.client.getPromise(url, {
            headers: this.prepareHeadersValues(config),
            params,
        });
    }
    delete<T = unknown> (url: string): Promise<T> {
        return this.client.deletePromise(url);
    }

    post<T = unknown> (url: string, body: unknown): Promise<T> {
        return this.client.postPromise(url, body);
    }

    patch<T = unknown> (url: string, body: unknown): Promise<T> {
        return this.client.patchPromise(url, body);
    }

    private prepareHeadersValues (config?: ApiConfigType): Record<string, string> {
        const headers: Record<string, string> = {};

        if (config?.headers) {
            Object.keys(config.headers).forEach(i => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                headers[i] = String(config!.headers![i]);
            });
        }

        return headers;
    }
}
