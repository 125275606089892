(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name accessibilityHasGuideline
     * @description Check if domain uses selected guideline
     */

    angular.module('filters.monsido').filter('accessibilityHasGuideline', AccessibilityHasGuidelineFilter);

    AccessibilityHasGuidelineFilter.$inject = ['ng2SessionService'];

    /* @ngInject */
    function AccessibilityHasGuidelineFilter (sessionService) {
        var guidelines = {
            WCAG2: ['WCAG2-A', 'WCAG2-AA', 'WCAG2-AAA'],
            WCAG21: ['WCAG21-A', 'WCAG21-AA', 'WCAG21-AAA', 'WCAG21-A_MOBILE', 'WCAG21-AA_MOBILE', 'WCAG21-AAA_MOBILE'],
            WCAG22: ['WCAG22-A', 'WCAG22-AA', 'WCAG22-AAA'],
        };

        return filter;

        /**
         * @param {String} guideline
         * @param {Object} [domain] - Selected domain
         * @return {Boolean}
         */
        function filter (guideline, domain) {
            if (typeof guideline !== 'string') {
                return false;
            }

            guideline = guideline.toUpperCase();
            var conformanceLevels = guidelines[guideline];
            var selectedDomain = domain || sessionService.domain;

            if (typeof conformanceLevels === 'undefined') {
                return false;
            }

            if (selectedDomain === null || typeof selectedDomain === 'undefined') {
                return false;
            }

            if (typeof selectedDomain.features.accessibility !== 'string') {
                return false;
            }

            let accessibility = selectedDomain.features.accessibility;

            const indexOfVersion = accessibility.search(/_v(\d+)?/);
            /* Makes accessibility guidelines independent of version for displaying */
            if (indexOfVersion > -1) {
                accessibility = accessibility.slice(0, indexOfVersion);
            }

            return conformanceLevels.includes(accessibility.toUpperCase());
        }
    }
})();
