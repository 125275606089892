(function () {
    'use strict';

    angular.module('services.api').factory('AccountRepo', AccountRepo);

    AccountRepo.$inject = ['ng2ApiService', 'AccountModel'];
    /* @ngInject */
    function AccountRepo (ng2ApiService, AccountModel) {
        var service = {
            get: get,
            update: update,
        };

        return service;

        // /////////////

        function get () {
            return ng2ApiService.get('account').then(toModel);
        }

        function update (data, headers) {
            return ng2ApiService.patch('account', data, null, headers).then(toModel);
        }

        function toModel (model) {
            return new AccountModel(model);
        }
    }
})();
