(function () {
    'use strict';

    angular.module('services.api').factory('PageClarityRepo', CommentRepo);

    CommentRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function CommentRepo (ng2ApiService, ng2SessionService) {
        return {
            getAllChecks: getAllChecks,
            getCheckErrors: getCheckErrors,
            updateCheckError: updateCheckError,
        };

        // /////////////

        function getAllChecks (page_id, params, config) {
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/pages/' + page_id + '/clarity/checks', params, config);
        }

        function getCheckErrors (page_id, check_id, params, config) {
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId() + '/pages/' + page_id + '/clarity/checks/' + check_id + '/errors',
                params,
                config,
            );
        }

        function updateCheckError (page_id, check_id, error_id, data, params, config) {
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId() + '/pages/' + page_id + '/clarity/checks/' + check_id + '/errors/' + error_id,
                data,
                params,
                config,
            );
        }
    }
})();
