import { Component, OnDestroy, OnInit } from '@angular/core';
import { MonPromptService, SortType } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { UIRouter } from '@uirouter/core';
import { MonTableCollection } from 'app/modules/accessibility/interfaces/mon-table-collection.interface';
import moment from 'moment';
import { QaIssueInterface } from '../../interfaces/qa-broken-snippet.interface';
import { QaDocumentInterface } from '../../interfaces/qa-document.interface';
import { QaIssueRepoService } from '../../repos/qa-issue.repo';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IssueStatusEnum } from 'app/modules/common/enum/issue-status.enum';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { ISSUE_TYPES } from '@monsido/modules/deeplink/constants/deeplink.constant';
import { GetPagesParamsInterface } from '../../repos/qa-issue-repo.interface';
import { QaPageInterface } from '../../interfaces/qa-page.interface';
import { IssueService } from '@monsido/modules/issue/services/issue.service';
import { Page } from '@monsido/modules/page-details/models';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import type { ActionMenuItemType } from '@monsido/ng2/shared/components/action-menu-panel/action-menu-panel.type';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'mon-qa-broken-issue',
    templateUrl: './qa-broken-issue.component.html',
    styleUrls: ['./qa-broken-issue.component.scss'],
})
export class QaBrokenIssueComponent implements OnInit, OnDestroy {

    issueId: number = -1;
    issueTitle: string = '';
    issueData?: QaIssueInterface;
    issueStatus: IssueStatusEnum = IssueStatusEnum.dead;
    IssueStatusEnum = IssueStatusEnum;
    texts: Record<string, string> = {};
    loading = false;
    issueType: string = '';
    contentTableName: string = '';
    documentTableName: string = '';
    elementType: string = '';
    issueDataUrl: string = '';
    characterLimit: number = 150;
    page: Page = {} as Page;
    pages: MonTableCollection<QaPageInterface> = [];
    loadingPages = false;
    getPagesParams: BehaviorSubject<GetPagesParamsInterface> = new BehaviorSubject({
        page: 1,
        page_size: 10,
        sort_by: 'title',
        sort_dir: 'desc',
    });

    documents: MonTableCollection<QaDocumentInterface> = [];
    loadingDocuments = false;
    getDocumentsParams: BehaviorSubject<GetPagesParamsInterface> = new BehaviorSubject({
        page: 1,
        page_size: 10,
        sort_by: 'title',
        sort_dir: 'desc',
    });

    translationKeys: Record<string, Record<string, string>> = {
        qaBrokenLink: {
            elementTypeText: 'Link',
            contentTableText: 'All pages with this Broken Link',
            documentTableText: 'All documents with this Broken Link',
        },
        qaBrokenImage: {
            elementTypeText: 'Image',
            contentTableText: 'All pages with this Broken Image',
            documentTableText: 'All documents with this Broken Image',
        },
    };

    dropdownOptions: ActionMenuItemType[] = [];

    openingPageDetails = false;
    openingDocumentDetails = false;
    openingExtensionDeeplink = false;
    loadingIssueData = false;

    subscriptions: Subscription[] = [];

    viewsColumIsAvailable: boolean = true;
    private pageId: number = -1;
    private pageLinkId: number = -1;
    private pageDetailsOpeningMs = 1000;
    private pageDetailsOpeningTimeout: number | null = null;
    private confirmationRequiredText: string = '';

    constructor (
        private uiRouter: UIRouter,
        private qaIssueRepoService: QaIssueRepoService,
        private eventsService: MonEventService,
        private monPromptService: MonPromptService,
        private translateService: TranslateService,
        private issueService: IssueService,
        private activeFeatureService: ActiveFeatureService,
    ) {
        ({
            issueOverlayIssueId: this.issueId,
            issueOverlayIssueType: this.issueType,
            issueOverlayPageId: this.pageId,
            issueOverlayPageLinkId: this.pageLinkId,
        } = this.uiRouter.urlService.search());
        const title = this.isBrokenLink() ? ISSUE_TYPES.BrokenLinks.name : ISSUE_TYPES.BrokenImages.name;
        this.issueTitle = this.translateService.getString(title);
        if (this.issueType) {
            this.elementType = this.translateService.getString(this.translationKeys[this.issueType].elementTypeText);
            this.contentTableName = this.translateService.getString(this.translationKeys[this.issueType].contentTableText);
            this.documentTableName = this.translateService.getString(this.translationKeys[this.issueType].documentTableText);
        }

        this.pages.total = 0;
        this.documents.total = 0;
        const type = this.isBrokenLink() ? 'link' : 'image';
        this.confirmationRequiredText = this.translateService.getString('Confirmation required');
        this.texts = {
            fix: this.translateService.getString(`Are you sure you want to mark this ${type} as fixed?`),
            unfix: this.translateService.getString(`Are you sure you want to mark this ${type} as not fixed?`),
            ignore: this.translateService.getString(`Are you sure you want to mark this ${type} as ignored?`),
            unignore: this.translateService.getString(`Are you sure you want to unignore this ${type}?`),
        };
    }

    async ngOnInit (): Promise<void> {
        if (this.issueId && this.pageId && this.pageLinkId) {
            await this.getIssueData();

            if (this.issueData) {
                await this.getPageDetails();
                this.subscriptions = [
                    this.getPagesParams.subscribe(params => {
                        this.getPages(params);
                    }),
                    this.getDocumentsParams.subscribe(params => {
                        this.getDocuments(params);
                    }),
                ];
            }
        }
        this.viewsColumIsAvailable = this.activeFeatureService.isFeatureActive('script_setup_guide');
    }

    ngOnDestroy (): void {
        if (Array.isArray(this.subscriptions)) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    onSortContent (sortPayload: Record<string, string>, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        const { direction, by } = sortPayload;
        const params: GetPagesParamsInterface = {
            ...subject.value,
            sort_dir: direction as SortType,
            sort_by: by,
        };

        if (!direction) {
            params.sort_dir = 'asc';
        }

        if (!by) {
            params.sort_by = '';
        }

        subject.next(params);
    }

    onContentPageChange (page: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page,
        });
    }

    onContentPerPageChange (perPage: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page: 1,
            page_size: perPage,
        });
    }

    goToPageDetails (page: QaPageInterface): void {
        if (!this.pageDetailsOpeningTimeout) {
            this.openingPageDetails = true;
            const target = 'page-details-section-qa';
            const subTarget = this.isBrokenLink() ? 'brokenlinks' : 'brokenimages';
            const callback = (): void => {
                this.openingPageDetails = false;
            };
            this.issueService.goToPageDetails(page, target, subTarget, false, callback);

            this.pageDetailsOpeningTimeout = setTimeout(() => {
                this.pageDetailsOpeningTimeout = null;
            }, this.pageDetailsOpeningMs) as unknown as number;
        }
    }

    goToDocumentDetails (document: QaDocumentInterface): void {
        this.openingDocumentDetails = true;
        const target = 'page-details-section-qa';
        const subTarget = this.isBrokenLink() ? 'brokenlinks' : 'brokenimages';
        const callback = (): void => {
            this.openingDocumentDetails = false;
        };
        this.issueService.goToPageDetails(document, target, subTarget, true, callback);
    }

    openInExtension (page: QaPageInterface | Page): void {
        const params = {
            type: this.isBrokenLink() ? ISSUE_TYPES.BrokenLinks.id : ISSUE_TYPES.BrokenImages.id,
            issueId: this.issueId,
            pageId: this.pageId,
            pageLinkId: this.pageLinkId,
        };
        this.issueService.openInExtension(page, params);
    }

    toggleCharacterLimit (): void {
        if (!this.issueData) {
            return;
        }
        this.issueDataUrl = (this.issueData.url === this.issueDataUrl) ? this.issueData.url.slice(0, this.characterLimit) + '...' : this.issueData.url;
    }

    async markAs (status: string): Promise<void> {
        switch (status) {
            case IssueStatusEnum.fixed: {
                try {
                    await this.monPromptService.confirm(this.texts.fix, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });

                    await this.qaIssueRepoService.markAsFixed(this.pageId, this.pageLinkId);
                    this.getIssueData(true);
                } catch (_e) {}
                break;
            }
            case IssueStatusEnum.ignored: {
                try {
                    await this.monPromptService.confirm(this.texts.ignore, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });
                    await this.qaIssueRepoService.markAsIgnored(this.pageId, this.pageLinkId);
                    this.getIssueData(true);
                } catch (_e) {}
                break;
            }
            case 'unignored': {
                try {
                    await this.monPromptService.confirm(this.texts.unignore, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });
                    await this.qaIssueRepoService.markAsDead(this.pageId, this.pageLinkId);
                    this.getIssueData(true);
                } catch (_e) {}
                break;
            }
            case 'unfixed': {
                try {
                    await this.monPromptService.confirm(this.texts.unfix, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });
                    await this.qaIssueRepoService.markAsDead(this.pageId, this.pageLinkId);
                    this.getIssueData(true);
                } catch (_e) {}
                break;
            }
        }

    }

    private updateDropdown (): void {
        const dropdown: Array<ActionMenuItemType & { shouldShow?: boolean}> = [
            {
                label: this.translateService.getString('Mark as fixed'),
                leftIcon: 'faCheck',
                action: ()=>this.markAs('fixed'),
                shouldShow: this.issueStatus !== this.IssueStatusEnum.fixed && this.issueStatus !== this.IssueStatusEnum.ignored,
            },
            {
                label: this.translateService.getString('Mark as not fixed'),
                leftIcon: 'faBan',
                action: ()=>this.markAs('unfixed'),
                shouldShow: this.issueStatus === this.IssueStatusEnum.fixed,
            },
            {
                label: this.translateService.getString('Mark as ignored'),
                leftIcon: 'faEyeSlash',
                action: ()=>this.markAs('ignored'),
                shouldShow: this.issueStatus !== this.IssueStatusEnum.ignored,
            },
            {
                label: this.translateService.getString('Unignore'),
                leftIcon: 'faEye',
                action: ()=>this.markAs('unignored'),
                shouldShow: this.issueStatus === this.IssueStatusEnum.ignored,
            },
        ];

        this.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
            return {
                label: item.label,
                leftIcon: item.leftIcon,
                action: item.action,
            };
        });
    }

    private async getIssueData (statusChanged = false): Promise<void> {
        try {
            this.loadingIssueData = true;
            const data = await this.qaIssueRepoService.getPageIssueContent(this.pageId, this.pageLinkId);
            if (data) {
                this.issueData = data.link;
                this.issueStatus = data.status;
                this.issueData.created_at = moment(this.issueData.created_at).format('DD MMM. YYYY');
                if (this.issueData.url) {
                    if (this.issueData.url.length < this.characterLimit) {
                        this.issueDataUrl = this.issueData.url;
                    } else {
                        this.issueDataUrl = this.issueData.url.slice(0, this.characterLimit) + '...';
                    }
                }
                this.updateDropdown();
            }

            if (statusChanged) {
                if (this.isBrokenLink()) {
                    this.eventsService.run(MON_EVENTS.RELOAD_QA_BROKEN_LINKS);
                } else {
                    this.eventsService.run(MON_EVENTS.RELOAD_QA_BROKEN_IMAGES);
                }
                this.eventsService.run(MON_EVENTS.RELOAD_QA_BROKEN_PAGE_DETAILS);
            }
        } finally {
            this.loadingIssueData = false;
        }
    }

    private async getPages (params: GetPagesParamsInterface): Promise<void> {
        this.loadingPages = true;
        try {
            const pages = await this.qaIssueRepoService.getIssuePages(this.issueId, params);
            if (pages) {
                this.pages = pages;
                if (pages.length === 0) {
                    this.pages.total = 0;
                }
            }
        } finally {
            this.loadingPages = false;
        }
    }

    private async getDocuments (params: GetPagesParamsInterface): Promise<void> {
        this.loadingDocuments = true;
        try {
            const documents = await this.qaIssueRepoService.getIssueDocuments(this.issueId, params);
            if (documents) {
                this.documents = documents;
                if (documents.length === 0) {
                    this.documents.total = 0;
                }
            }
        } finally {
            this.loadingDocuments = false;
        }
    }

    private isBrokenLink (): boolean {
        return Boolean(this.issueType === 'qaBrokenLink');
    }

    private async getPageDetails (): Promise<void> {
        this.issueService.getPage(this.pageId).then((page: Page) => {
            this.page = page;
        });
    }
}
