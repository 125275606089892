(function () {
    'use strict';

    angular.module('pages.domain').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.customer.global',
                config: {
                    url: '',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { anonymous_access: false },
                    resolve: {
                        removeDomain: [
                            'coreDomainService',
                            'ng2MonEventsService',
                            '$q',
                            function (coreDomainService, ng2MonEventsService, $q) {
                                var deferred = $q.defer();
                                coreDomainService.setDomain(null);
                                coreDomainService.removeDomainGroup();
                                ng2MonEventsService.run('changed-domain'); // Make sure that no domain is set when entering global pages
                                deferred.resolve(true);
                                return deferred.promise;
                            },
                        ],
                    },
                },
            },
        ];
    }
})();
