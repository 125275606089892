(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroupsRules', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/rules/rules.html',
        controller: DomainGroupsRulesController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
        },
    });

    DomainGroupsRulesController.$inject = [
        '$scope',
        '$timeout',
        'API_CONSTANTS',
        'ng2SessionService',
        'monDialog',
        'FORM_CONSTANT',
        'LabelRepo',
        'smartScroll',
    ];
    /* @ngInject */
    function DomainGroupsRulesController (
        $scope,
        $timeout,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        API_CONSTANTS,
        ng2SessionService,
        monDialog,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FORM_CONSTANT,
        LabelRepo,
        smartScroll,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.addRule = addRule;
        vm.onConfirmRemove = onConfirmRemove;
        vm.onExcludeChange = onExcludeChange;
        vm.onSearch = onSearch;
        vm.importRules = importRules;

        function activate () {
            vm.isSudo = ng2SessionService.isSudo;
            vm.page = 1;
            vm.pageSize = API_CONSTANTS.PAGE_SIZE;
            vm.search = '';
            vm.rules = [];
            vm.labels = [];

            getLabels();

            if (vm.ngModel.rules.length === 0) {
                addRule();
            }

            setupWatchers();
        }

        function getLabels () {
            vm.loadingLabels = true;
            return LabelRepo.getLabels().then(function (labels) {
                vm.labels = labels;
            })
                .finally(function () {
                    vm.loadingLabels = false;
                });
        }

        function importRules () {
            var params = {
                body: FORM_CONSTANT.FORM_IMPORT_GROUP_RULES,
                size: 'sm',
                data: {
                    ngModel: vm.ngModel,
                },
            };

            monDialog.create(params, function (ngModel) {
                vm.ngModel = ngModel;
                $timeout(function () {
                    $scope.$apply();
                });
            });
        }


        function setupWatchers () {
            $scope.$watch('vm.ngModel.rules', function (newRules, oldRules) {
                if (angular.isArray(newRules) && newRules.length > 0) {
                    angular.forEach(newRules, function (newRule) {
                        newRule.exclude_as_string = newRule.exclude ? 'true' : 'false';
                    });
                }
            });
        }

        function onSearch (search) {
            vm.page = 1;
            vm.search = search;
        }

        function addRule () {
            vm.search = '';
            vm.ngModel.rules.push({ match: 'full_url', match_rule: 'contains', exclude_as_string: 'false' });
            const pageNumber = vm.page;
            const total = vm.ngModel.rules.length;
            if (total >= vm.pageSize) {
                vm.page = total % vm.pageSize !== 0 ? Math.floor(total / vm.pageSize + 1) : total / vm.pageSize;
                if (vm.page > pageNumber) {
                    const container = document.querySelector('.group-form-container');
                    smartScroll.scrollTo(container, 'domainGroupsRules', 220);
                }
            }

            $timeout(function () {
                $scope.$apply();
            });
        }

        function onConfirmRemove (rule) {
            var index = vm.ngModel.rules.indexOf(rule);

            if (index !== -1) {
                vm.ngModel.rules.splice(index, 1);
            }
        }

        function onExcludeChange () {
            $timeout(function () {
                angular.forEach(vm.ngModel.rules, function (newRule) {
                    newRule.exclude = newRule.exclude_as_string === 'true';
                });
            });
        }
    }
})();
