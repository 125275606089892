(function () {
    'use strict';

    angular.module('modules.page_fix.components').component('pageFixAccessibilityChecks', {
        templateUrl: 'app/modules/page-fix/components/accessibility-checks/accessibility-checks.html',
        controller: PageFixAccessibilityChecksController,
        controllerAs: 'vm',
        bindings: {
            tab: '<',
        },
    });

    PageFixAccessibilityChecksController.$inject = [
        'pageFixService',
        'PAGE_FIX_FIXABLE_TYPES',
        'AccessibilityChecksRepo',
        'PageFixAccessibilityChecksService',
        'PageFixService',
        'ng2MonEventsService',
        '$location',
        'ParamService',
        'PageFixAccessibilityChecksMapService',
        'ng2MonLogService',
    ];
    /* @ngInject */
    function PageFixAccessibilityChecksController (
        pageFixService,
        PAGE_FIX_FIXABLE_TYPES,
        AccessibilityChecksRepo,
        PageFixAccessibilityChecksService,
        PageFixService,
        ng2MonEventsService,
        $location,
        ParamService,
        PageFixAccessibilityChecksMapService,
        ng2MonLogService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.onSearch = onSearch;
        vm.onSortPageChange = onSortPageChange;
        vm.updatePageFix = updatePageFix;
        vm.createPageFix = createPageFix;
        vm.getDifficulty = getDifficulty;
        vm.getDescription = getDescription;
        vm.getImage = getImage;
        vm.isHardDifficulty = isHardDifficulty;
        vm.isProcessingPageFix = isProcessingPageFix;
        vm.getLoadingScriptUrl = getLoadingScriptUrl;
        vm.getCategoryIcon = getCategoryIcon;
        vm.getTabTranslation = getTabTranslation;

        function activate () {
            vm.search = '';
            vm.pageFixChecks = {};
            vm.accessibilityChecksOrg = [];
            vm.accessibilityChecks = [];
            vm.loading = true;
            vm.currentSort = {
                by: 'name', // name, status..
                direction: 'asc',
            };

            AccessibilityChecksRepo.getAll().then(function (accessibilityChecks) {
                vm.accessibilityChecksOrg = accessibilityChecks;
                getPage();
            }, (e) => {
                ng2MonLogService.warn(e);
            });
            loadLocations();
        }

        function getPage () {
            vm.loading = true;
            var params = {
                page: 1,
                page_size: 0,
                fixable_type: PAGE_FIX_FIXABLE_TYPES.accessibility_check,
            };

            pageFixService
                .getAll(params)
                .then(function (pageFixChecks) {
                    pageFixChecks.forEach(function (check) {
                        PageFixAccessibilityChecksMapService.clearAccessibilityCheck(check.fixable_id);
                        if (PageFixAccessibilityChecksMapService.hasDeletedPageFix(check)) {
                            delete vm.pageFixChecks[check.fixable_id];
                            return;
                        }
                        vm.pageFixChecks[check.fixable_id] = check;
                    });
                    filterAndSortAccessibilityChecks();
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function isProcessingPageFix (check) {
            return PageFixAccessibilityChecksMapService.hasDirtyAccessibilityCheck(check);
        }

        function onSearch (search) {
            vm.search = search;
            filterAndSortAccessibilityChecks();
        }

        function onSortPageChange ({ by, direction }) {
            vm.currentSort = { by, direction }
            ng2MonEventsService.run('actionTrigger', { action: 'table-sort', params: { by, direction } });
            filterAndSortAccessibilityChecks();
        }

        function createPageFix (accessibilityCheck) {
            PageFixService.add(
                PAGE_FIX_FIXABLE_TYPES.accessibility_check,
                accessibilityCheck,
                accessibilityCheck.id,
                undefined,
                function () {
                    if (PageFixAccessibilityChecksMapService.hasAccessibilityCheckUpdated(accessibilityCheck)) {
                        getPage();
                    }
                    PageFixAccessibilityChecksMapService.removeUpdatedAccessibilityCheck(accessibilityCheck);
                },
            );
        }

        function updatePageFix (accessibilityCheck) {
            var pageFix = vm.pageFixChecks[accessibilityCheck.id];
            var model = {
                id: pageFix.change.check_id,
            };

            PageFixService.update(pageFix.normalizeFixableType(), model, pageFix.change.attribute, pageFix, function (result) {
                if (result === 'destroyed') {
                    delete vm.pageFixChecks[pageFix.fixable_id];
                    getPage();
                }

                if (PageFixAccessibilityChecksMapService.hasAccessibilityCheckUpdated(accessibilityCheck)) {
                    getPage();
                }

                PageFixAccessibilityChecksMapService.removeUpdatedAccessibilityCheck(accessibilityCheck);
            });
        }

        function getDifficulty (accessibilityCheck) {
            return PageFixAccessibilityChecksService.getDifficulty(accessibilityCheck);
        }

        function isHardDifficulty (check) {
            return PageFixAccessibilityChecksService.isHardDifficulty(check);
        }

        function getDescription (check) {
            return PageFixAccessibilityChecksService.getDescription(check);
        }

        function getImage (accessibilityCheck) {
            return PageFixAccessibilityChecksService.getImage(accessibilityCheck);
        }

        function getLoadingScriptUrl () {
            return PageFixAccessibilityChecksService.getLoadingScriptUrl();
        }

        function getCategoryIcon (check) {
            return PageFixAccessibilityChecksService.getTabIcon(check);
        }

        function getTabTranslation (check) {
            return PageFixAccessibilityChecksService.getTabTranslation(check);
        }

        // PROTECTED

        function filterAndSortAccessibilityChecks () {
            ParamService.setParams({
                search: vm.search || null,
            });

            vm.accessibilityChecks = PageFixAccessibilityChecksService.filterAccessibilityChecks(
                vm.accessibilityChecksOrg,
                vm.search,
                vm.tab,
            );

            vm.accessibilityChecks = PageFixAccessibilityChecksService.sortAccessibilityChecks(
                vm.accessibilityChecks,
                vm.pageFixChecks,
                vm.currentSort.by,
                vm.currentSort.direction,
            );
        }

        function loadLocations () {
            vm.search = $location.search().search || null;
        }
    }
})();
