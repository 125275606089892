import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { ArgsInterface, ToastService, IndividualConfig } from '@angular-shared-components';
import { Customer } from '@monsido/modules/models/api/customer';
import { AccountRepo } from '@monsido/modules/endpoints/api/account.repo';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { HttpRequestOptionsType } from '@monsido/ng2/external/http/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class AccountSettingsService {
    constructor (
        private ng2SessionService: SessionService,
        private translateService: TranslateService,
        private accountRepo: AccountRepo,
        private toasterService: ToastService,
    ) {}

    public updateCustomer (data, params: HttpRequestOptionsType = {}): Promise<Customer> {
        return this.accountRepo.update(data, params);
    }

    public getString (text: string, args?: ArgsInterface, context?: string): string {
        return this.translateService.getString(text, args, context);
    }

    public getCustomer (): Promise<Customer> {
        return this.accountRepo.get();
    }

    public getSessionCustomer (): Customer | null {
        return this.ng2SessionService.customer;
    }

    public setSessionCustomer (customer: Customer): void {
        this.ng2SessionService.customer = customer;
    }

    public success (title: string, message?: string, override?: Partial<IndividualConfig>): void {
        this.toasterService.success(title, message, override);
    }

    public error (title: string, message?: string, override?: Partial<IndividualConfig>): void {
        this.toasterService.error(title, message, override);
    }
}
