(function () {
    'use strict';

    angular.module('pages.policy').component('pagesPolicyIgnoredPages', {
        templateUrl: 'app/pages/policy/pages-with-ignored-checks/pages-with-ignored-checks.html',
        controller: PagesPolicyIgnoredPagesController,
        controllerAs: 'vm',
    });

    PagesPolicyIgnoredPagesController.$inject = ['PolicyRepo', '$controller', 'gettextCatalog', '$filter', 'ng2ActiveFeatureService'];

    /* @ngInject */
    function PagesPolicyIgnoredPagesController (PolicyRepo, $controller, gettextCatalog, $filter, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.subheaderTranslation = '';
            vm.currentSort = {
                by: 'ignored_policies_count',
                direction: 'desc',
            };

            getPage();
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.pages.length, '0,0'),
                '1 page with one or more failing checks',
                '{{$count}} pages with ignored checks',
                {},
            );
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
            };
            params = vm.updateParamsWithFilters(params);
            vm.progress = PolicyRepo.getPagesWithPolicyIgnoredChecks(params).then(function (data) {
                vm.pages = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            getPage();
        }
    }
})();
