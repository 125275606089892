(function () {
    'use strict';
    angular.module('modules.page_fix.services').factory('PageFixModelHelperService', PageFixModelHelperService);

    PageFixModelHelperService.$inject = ['Lodash'];
    /* @ngInject*/
    function PageFixModelHelperService (Lodash) {
        return {
            getSelectorValue: getSelectorValue,
        };

        // ///////////////

        function getSelectorValue (target, attrs) {
            var res1;
                var res2 = [];
                var query;
                var tempQ = '';
                var i;
            if (typeof attrs !== 'undefined' && !Array.isArray(attrs)) {
                attrs = [attrs];
            }
            res1 = target.match(/<([\w-]+)(.[^>]*)>/);
            if (!res1) {
                return target;
            }

            if (res1.length > 2) {
                res2 = res1[2].match(/([\w-]*=(["'])(?:(?!\2).)*?\2)/g);
            }

            query = res1[1];
            if (res2 !== null) {
                for (i = 0; i < res2.length; ++i) {
                    if (isInAttrs(res2[i], attrs)) {
                        tempQ += '[' + res2[i] + ']';
                    }
                }
            }

            if (tempQ) {
                query += tempQ;
            }
            return query;
        }

        // PROTECTED

        function isInAttrs (selector, attrs) {
            if (!attrs) {
                return true;
            }

            for (var i = 0; i < attrs.length; i++) {
                if (Lodash.startsWith(selector, attrs[i])) {
                    return true;
                }
            }

            return false;
        }
    }
})();
