(function () {
    'use strict';
    angular.module('modules.cookie_banner.forms').service('CookieBannerSettingsService', CookieBannerSettingsService);

    CookieBannerSettingsService.$inject = [
        '$deepmerge',
        'gettextCatalog',
        'COOKIE_BANNER_SETTINGS',
        'WIDGET_SETTINGS',
        'COOKIE_BANNER_I18N',
        'LOCALE_LANGUAGE_MAP',
    ];
    /* @ngInject*/
    function CookieBannerSettingsService (
        $deepmerge,
        gettextCatalog,
        COOKIE_BANNER_SETTINGS,
        WIDGET_SETTINGS,
        COOKIE_BANNER_I18N,
        LOCALE_LANGUAGE_MAP,
    ) {
        var vm = this;
        var availableLanguages = Object.values(COOKIE_BANNER_I18N);

        vm.languages = availableLanguages.map(function (key) {
            var languageLabel = LOCALE_LANGUAGE_MAP[key];
            return {
                name: languageLabel ? languageLabel : key,
                value: key,
            };
        });

        vm.directions = [
            {
                name: gettextCatalog.getString('Top left'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.TOPLEFT,
            },
            {
                name: gettextCatalog.getString('Top center'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.TOPCENTER,
            },
            {
                name: gettextCatalog.getString('Top right'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.TOPRIGHT,
            },
            {
                name: gettextCatalog.getString('Left middle'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.LEFT,
            },
            {
                name: gettextCatalog.getString('Right middle'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.RIGHT,
            },
            {
                name: gettextCatalog.getString('Bottom left'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.BOTTOMLEFT,
            },
            {
                name: gettextCatalog.getString('Bottom center'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.BOTTOMCENTER,
            },
            {
                name: gettextCatalog.getString('Bottom right'),
                value: COOKIE_BANNER_SETTINGS.DIRECTION.BOTTOMRIGHT,
            },
        ];

        vm.privacyRegulations = [
            {
                name: gettextCatalog.getString('CCPA'),
                value: COOKIE_BANNER_SETTINGS.PRIVACY_REGULATIONS.CCPA,
                tooltip: gettextCatalog.getString('California Consumer Privacy Act'),
            },
            {
                name: gettextCatalog.getString('GDPR'),
                value: COOKIE_BANNER_SETTINGS.PRIVACY_REGULATIONS.GDPR,
                tooltip: gettextCatalog.getString('General Data Protection Regulation'),
            },
            {
                name: gettextCatalog.getString('Both CCPA & GDPR'),
                value: [COOKIE_BANNER_SETTINGS.PRIVACY_REGULATIONS.CCPA, COOKIE_BANNER_SETTINGS.PRIVACY_REGULATIONS.GDPR],
            },
        ];

        vm.icons = [
            {
                icon: 'fa-fw fa-2x fas fa-cookie',
                value: COOKIE_BANNER_SETTINGS.ICON.COOKIE,
                aria_label: gettextCatalog.getString('Chocolate chip cookie'),
            },
            {
                icon: 'fa-fw fa-2x fas fa-cookie-bite',
                value: COOKIE_BANNER_SETTINGS.ICON.COOKIE_BITE,
                aria_label: gettextCatalog.getString('Chocolate chip cookie with a bite out of it'),
            },
            {
                icon: 'fa-fw fa-2x fas fa-badge',
                value: COOKIE_BANNER_SETTINGS.ICON.BADGE,
                aria_label: gettextCatalog.getString('Gingerbread cookie'),
            },
            {
                icon: 'fa-fw fa-2x fas fa-stroopwafel',
                value: COOKIE_BANNER_SETTINGS.ICON.STROOPWAFEL,
                aria_label: gettextCatalog.getString('Peanut-butter criss cross cookie'),
            },
        ];

        return {
            getDirections: getDirections,
            getPrivacyRegulations: getPrivacyRegulations,
            getDefaultSettings: getDefaultSettings,
            getIcons: getIcons,
            getLanguages: getLanguages,
        };

        // ///////////////

        function getLanguages () {
            return $deepmerge([], vm.languages);
        }

        function getDirections () {
            return $deepmerge([], vm.directions);
        }

        function getPrivacyRegulations () {
            return $deepmerge([], vm.privacyRegulations);
        }

        function getDefaultSettings () {
            return {
                enabled: true,
                direction: COOKIE_BANNER_SETTINGS.DIRECTION.BOTTOMRIGHT,
                privacyRegulation: getPrivacyRegulations()[0].value,
                cookie_policy_url: '',
                optOutUrl: '',
                cookies_overview: [],
                per_category_consent: true,
                hasOverlay: false,
                explicit_reject_option: false,
                enable_iab_and_consent_fw: false,
                support_google_consent_mode: false,
                icon_picture_url: COOKIE_BANNER_SETTINGS.ICON.COOKIE,
                icon_shape: WIDGET_SETTINGS.ICON_SHAPE.CIRCLE,
                included_languages: [COOKIE_BANNER_I18N.EN_US],
                defaultLanguage: COOKIE_BANNER_I18N.EN_US,
                theme: {
                    // mainColor: COOKIE_BANNER_SETTINGS.BUTTON_COLOR,
                    buttonColor: COOKIE_BANNER_SETTINGS.BUTTON_COLOR,
                    buttonTextColor: COOKIE_BANNER_SETTINGS.BUTTON_TEXT_COLOR,
                },
            };
        }

        function getIcons () {
            return $deepmerge([], vm.icons);
        }
    }
})();
