(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceAuditDisplayNumeric', {
        templateUrl: 'app/modules/page-performance/components/display-modes/numeric/numeric.html',
        controller: PagePerformanceAuditDisplayNumericController,
        controllerAs: 'vm',
        bindings: {
            audit: '<',
        },
    });

    PagePerformanceAuditDisplayNumericController.$inject = ['PagePerformanceAuditDisplayNumericService'];
    /* @ngInject */
    function PagePerformanceAuditDisplayNumericController (PagePerformanceAuditDisplayNumericService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getAuditText = getAuditText;
        vm.isDetailTypeTable = isDetailTypeTable;
        vm.hasDetails = hasDetails;
        vm.hasDetailsTypeContent = hasDetailsTypeContent;
        vm.isDetailTypeOpportunity = isDetailTypeOpportunity;
        vm.getTableClass = getTableClass;

        function activate () {}

        function getAuditText (item, heading) {
            return PagePerformanceAuditDisplayNumericService.getFormatAuditItem(item, heading);
        }

        function isDetailTypeTable () {
            return PagePerformanceAuditDisplayNumericService.isDetailTypeTable(vm.audit.details.type);
        }

        function isDetailTypeOpportunity () {
            return PagePerformanceAuditDisplayNumericService.isDetailTypeOpportunity(vm.audit.details.type);
        }

        function hasDetails () {
            return Boolean(vm.audit.details);
        }

        function hasDetailsTypeContent () {
            return vm.audit.details.headings.length > 0 && vm.audit.details.items.length > 0;
        }

        function getTableClass (heading) {
            return PagePerformanceAuditDisplayNumericService.getTableHeadingClass(heading);
        }

        // PROTECTED
    }
})();
