import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.data_privacy.components').component('domainDataPrivacySettings', {
        templateUrl: 'app/modules/data-privacy/components/domain-settings/domain-settings.html',
        controller: PrivacyDomainSettingsController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            monDialogId: '@',
            setForm: '&?',
        },
    });

    PrivacyDomainSettingsController.$inject = [
        'DataPrivacyInfoTypeRepo',
        '$controller',
        'gettextCatalog',
        'monDialog',
        'REGIONS',
        'activeFeatures',
        'INFOTYPE_GROUPS',
        'Lodash',
        'DataPrivacyService',
        'DomainRepo',
        'REGULATIONS',
        '$q',
        '$filter',
        '$timeout',
        'ng2MonModalService',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function PrivacyDomainSettingsController (
        DataPrivacyInfoTypeRepo,
        $controller,
        gettextCatalog,
        monDialog,
        REGIONS,
        activeFeatures,
        INFOTYPE_GROUPS,
        Lodash,
        DataPrivacyService,
        DomainRepo,
        REGULATIONS,
        $q,
        $filter,
        $timeout,
        ng2MonModalService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openHelpCenter = openHelpCenter;
        vm.getPage = getPage;
        vm.formatText = DataPrivacyService.formatText;
        vm.goToPages = goToPages;
        vm.onFiltersChanges = onFiltersChanges;
        vm.updateRegulation = updateRegulation;
        vm.toggleRegulation = toggleRegulation;
        vm.toggleExtraExclude = toggleExtraExclude;
        vm.getGroupTooltip = getGroupTooltip;
        vm.toggleRegion = toggleRegion;
        vm.onSearchChange = onSearchChange;
        vm.submit = submit;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.infoTypes = [];
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    value: 'all',
                },
                {
                    name: gettextCatalog.getString('High'),
                    value: 'high',
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 'medium',
                },
                {
                    name: gettextCatalog.getString('Low'),
                    value: 'low',
                },
            ];
            vm.activeTab = vm.tabs[0].value;
            vm.currentSort = { by: 'name', direction: 'asc' };
            vm.filters = { 'Issue type': [], Country: [] };
            vm.availableFilters = {
                'Issue type': INFOTYPE_GROUPS,
                Country: REGIONS,
            };
            vm.showFilters = activeFeatures.table_filters;
            vm.isFiltersOpen = false;
            vm.filteredPages = [];
            vm.regulations = REGULATIONS;
            vm.selectedRegulations = {
                GDPR: false,
                CCPA: false,
                APA: false,
                SHIELD: false,
                PIPEDA: false,
            };
            vm.saving = false;
            vm.disabled = true;

            getPage();
            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.dpmForm });
                }
            });
            vm.originalSelectedRegulations = Lodash.merge({}, vm.selectedRegulations);
            vm.originalCustomRuleset = Lodash.merge({}, vm.domain.settings.data_protection.custom_ruleset);

        }

        function getPage () {
            var params = {};
            if (vm.search) {
                params.search = vm.search;
            }
            params.page = vm.page;
            params.page_size = 999;
            params.sort_dir = vm.currentSort.direction;
            params.sort_by = vm.currentSort.by;
            if (vm.activeTab !== 'all') {
                params.priority = vm.activeTab;
            }
            params['type[]'] = vm.filters['Issue type'].map(function (type) {
                return type.value;
            });
            var promises = [DataPrivacyInfoTypeRepo.getAll(params)];
            if (vm.domain.id > 0) {
                promises.push(DomainRepo.get(vm.domain.id));
            }
            vm.progress = $q.all(promises).then(function (values) {
                vm.infoTypes = values[0];
                if (vm.domain.id > 0) {
                    vm.domain = values[1];
                }
                vm.domain.settings.data_protection.enabled_regulations.forEach(function (regulation) {
                    vm.selectedRegulations[regulation] = true;
                });
                onFiltersChanges({ Country: REGIONS, 'Issue type': [] });
                updateRegulation();
                vm.disabled = false;
            }, angular.noop);
        }

        function openHelpCenter (infoType) {
            var params = {
                size: 'lg',
                body: 'dataPrivacyDialogsHelpCenter',
                data: { infoType: infoType },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function goToPages (infoType) {
            var params = {
                size: 'lg',
                body: 'datePrivacyInfoTypePages',
                data: { issue: infoType, totalPagesCount: vm.pageCount },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function onFiltersChanges (change, searchedInfoTypes) {
            vm.filteredPages = [];
            var infoTypeToUse = searchedInfoTypes || vm.infoTypes;
            if (change.Country.length === 0) {
                return onFiltersChanges({ Country: REGIONS, 'Issue type': change['Issue type'] }, searchedInfoTypes);
            } else {
                change.Country.forEach(function (region) {
                    if (region.value !== 'global') {
                        vm.filteredPages.push({
                            name: region.value,
                            selectAll: false,
                            pages: infoTypeToUse.filter(function (infoType) {
                                return infoType.regions.indexOf(region.value) !== -1;
                            }),
                        });
                    } else {
                        vm.filteredPages.push({
                            name: region.value,
                            selectAll: false,
                            pages: infoTypeToUse.filter(function (infoType) {
                                return infoType.regions.indexOf(region.value) !== -1 || infoType.regions.length === 0;
                            }),
                        });
                    }
                });

                vm.filteredPages.sort(function (a, b) {
                    if (a.name > b.name || (a.name === 'gobal' || b.name === 'global')) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
            }
            if (change['Issue type'].length > 0) {
                vm.filteredPages.forEach(function (page) {
                    page.pages = page.pages.filter(function (o) {
                        return Lodash.findIndex(change['Issue type'], { value: o.group }) !== -1;
                    });
                });
            }
        }

        function onSearchChange (search) {
            var searchedInfoTypes = [];
            if (search === '') {
                searchedInfoTypes = vm.infoTypes.slice();
            } else {
                searchedInfoTypes = vm.infoTypes.filter(function (infoType) {
                    return infoType.name.toUpperCase().includes(search.toUpperCase());
                });
            }
            onFiltersChanges(vm.filters, searchedInfoTypes);
        }

        function toggleRegulation (regulation) {
            if (!Lodash.isEqual(vm.originalSelectedRegulations, vm.selectedRegulations)) {
                vm.dpmForm.$setDirty();
            }
            $timeout(function () {
                var isRegulationSelected = vm.selectedRegulations[regulation.name];
                var ruleset = getRuleset(regulation);
                var list = '<ul>';
                ruleset.forEach(function (id) {
                    list = list + '<li>' + Lodash.find(vm.infoTypes, { id: id }).name + '</li>';
                });
                list = list + '</ul>';
                if (ruleset.length > 0) {
                    var msg = gettextCatalog.getString(
                        '{{msg}} <strong>{{regulationName}}</strong> will result in the custom {{opposite}} rules ' +
                            'for the following issues being overwritten, meaning they will {{negate}} be flagged by future scans: {{list}}',
                        {
                            // prettier-ignore
                            msg: isRegulationSelected ? gettextCatalog.getString('Enabling') : gettextCatalog.getString('Disabling'),
                            // prettier-ignore
                            opposite: isRegulationSelected ? gettextCatalog.getString('disabling') : gettextCatalog.getString('enabling'),
                            negate: isRegulationSelected ? '' : gettextCatalog.getString('not'),
                            regulationName: regulation.name,
                            list: list,
                        },
                    );
                    ng2MonModalService.confirm(msg).then(
                        function () {
                            vm.dpmForm.$setDirty();
                            updateRegulation(ruleset, regulation);
                        },
                        function () {
                            vm.selectedRegulations[regulation.name] = !isRegulationSelected;
                        },
                    );
                } else {
                    vm.dpmForm.$setDirty();
                    updateRegulation();
                }
            });
        }

        function getRuleset (regulation) {
            var ruleset;
            if (vm.selectedRegulations[regulation.name]) {
                ruleset = vm.domain.settings.data_protection.custom_ruleset.exclusions.filter(function (id) {
                    return Lodash.find(vm.infoTypes, { id: id, regulations: [regulation.name] });
                });
            } else {
                ruleset = vm.domain.settings.data_protection.custom_ruleset.extras.filter(function (id) {
                    return Lodash.find(vm.infoTypes, { id: id, regulations: [regulation.name] });
                });
            }
            return ruleset;
        }

        function updateRegulation (toOverwrite, regulation) {
            // transform selected regulations map to array
            // need timeout to wait 1 extra cycle for ng-model to set value
            $timeout(function () {
                vm.domain.settings.data_protection.enabled_regulations = [];
                for (var key in vm.selectedRegulations) {
                    if (vm.selectedRegulations.hasOwnProperty(key)) {
                        if (vm.selectedRegulations[key]) {
                            vm.domain.settings.data_protection.enabled_regulations.push(key);
                        }
                    }
                }
                if (toOverwrite) {
                    enableInfoTypes(toOverwrite, regulation);
                } else {
                    enableInfoTypes();
                }
            });
        }

        function enableInfoTypes (toOverwrtie, regulation) {
            var inList;
            if (regulation) {
                inList = vm.selectedRegulations[regulation.name] === false ? 'extras' : 'exclusions';
            }
            if (toOverwrtie && inList) {
                vm.domain.settings.data_protection.custom_ruleset[
                    inList
                ] = vm.domain.settings.data_protection.custom_ruleset[inList].filter(function (id) {
                    return !Lodash.includes(toOverwrtie, id);
                });
            }
            vm.infoTypes.forEach(function (infoType) {
                infoType.enabled = vm.domain.settings.data_protection.enabled_regulations.some(function (r) {
                    return infoType.regulations.includes(r);
                });

                var inExtras = Lodash.includes(vm.domain.settings.data_protection.custom_ruleset.extras, infoType.id);
                if (inExtras) {
                    infoType.enabled = true;
                }

                var inExcludes = Lodash.includes(
                    vm.domain.settings.data_protection.custom_ruleset.exclusions,
                    infoType.id,
                );
                if (inExcludes) {
                    infoType.enabled = false;
                }
            });
        }

        function toggleRegion (region) {
            region.pages.forEach(function (infoType) {
                infoType.enabled = region.selectAll;
            });

            var ids = region.pages.map(function (i) {
                return i.id;
            });

            if (region.selectAll) {
                Lodash.pullAll(vm.domain.settings.data_protection.custom_ruleset.exclusions, ids);
                vm.domain.settings.data_protection.custom_ruleset.extras = Lodash.uniq(
                    Lodash.flatten([vm.domain.settings.data_protection.custom_ruleset.extras, ids]),
                );
            } else {
                Lodash.pullAll(vm.domain.settings.data_protection.custom_ruleset.extras, ids);
                vm.domain.settings.data_protection.custom_ruleset.exclusions = Lodash.uniq(
                    Lodash.flatten([vm.domain.settings.data_protection.custom_ruleset.exclusions, ids]),
                );
            }
            isDisabled();
        }

        function toggleExtraExclude (infoType, region) {
            if (infoType.enabled) {
                Lodash.pull(vm.domain.settings.data_protection.custom_ruleset.extras, infoType.id);
                vm.domain.settings.data_protection.custom_ruleset.exclusions.push(infoType.id);
            } else {
                Lodash.pull(vm.domain.settings.data_protection.custom_ruleset.exclusions, infoType.id);
                vm.domain.settings.data_protection.custom_ruleset.extras.push(infoType.id);
            }
            infoType.enabled = !infoType.enabled;
            var allSelected = region.pages.every(function (infoType) {
                return infoType.enabled === true;
            });
            if (allSelected) {
                region.selectAll = true;
            } else {
                region.selectAll = false;
            }
            isDisabled();
            if (!Lodash.isEqual(vm.originalCustomRuleset, vm.domain.settings.data_protection.custom_ruleset)) {
                vm.dpmForm.$setDirty();
            }
        }
        function isDisabled () {
            vm.disabled = !vm.filteredPages.some(function (page) {
                return page.pages.some(function (infoType) {
                    return infoType.enabled === true;
                });
            });
        }
        function submit () {
            vm.saving = true;
            if (vm.domain.id > 0) {
                DomainRepo.update(vm.domain.id, {
                    settings: { data_protection: vm.domain.settings.data_protection },
                }).then(function (response) {
                    if (vm.dpmForm) {
                        vm.dpmForm.$setPristine();
                    }
                    if (response) {
                        close(vm.domain.settings.data_protection);
                    }
                }, angular.noop);
            } else {
                close(vm.domain.settings.data_protection);
            }
        }
        function close (val) {
            monDialog.close(vm.monDialogId, val);
        }

        function getGroupTooltip (text) {
            return $filter('translate')(vm.formatText(text || 'other'));
        }
    }
})();
