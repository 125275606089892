(function () {
    'use strict';

    angular.module('modules.cache').factory('userCacheService', userCacheService);

    userCacheService.$inject = ['cacheBaseService', '$deepmerge'];
    /* @ngInject*/
    function userCacheService (cacheBaseService, $deepmerge) {
        var defaultOptions = {
            maxAge: 60 * 60 * 1000, // 1 hour
            deleteOnExpire: 'aggressive',
            storageMode: 'sessionStorage',
        };
        var service = {
            get: get,
            getAll: getAll,
            put: put,
            getCache: getCache,
            updateDefaultOptions: updateDefaultOptions,
        };

        return service;

        // ///////////

        function updateDefaultOptions (options) {
            defaultOptions = $deepmerge(defaultOptions, options);
        }

        function getCache () {
            return cacheBaseService.get('users', defaultOptions);
        }

        function get (user_id) {
            return cacheBaseService.get('users', defaultOptions)[user_id];
        }

        function getAll () {
            return cacheBaseService.get('users', defaultOptions);
        }

        function put (user) {
            var baseObj = cacheBaseService.get('users', defaultOptions);
            delete user.domain_users;
            baseObj[user.id] = user;
            cacheBaseService.put('users', baseObj);
        }
    }
})();
