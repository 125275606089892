(function () {
    'use strict';
    angular.module('modules.light.components').component('domainCard', {
        templateUrl: 'app/modules/light/components/domain-card/domain-card.template.html',
        controller: domainCard,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            labels: '<',
        },
    });

    domainCard.$inject = ['FORM_CONSTANT', 'domainService', 'coreDomainService', 'LightDomainViewService', 'ng2ActiveFeatureService', 'MON_MODULES'];
    /* @ngInject */
    function domainCard (FORM_CONSTANT, domainService, coreDomainService, LightDomainViewService, ng2ActiveFeatureService, MON_MODULES) {
        var vm = this;

        vm.$onInit = activate;
        vm.updateDomain = updateDomain;
        vm.updateDomainByLabel = updateDomainByLabel;
        vm.openScriptGuide = openScriptGuide;
        vm.goToDashboard = LightDomainViewService.goToDashboard;

        function activate () {
            vm.isConsentManagerEnabled = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.cookie_banner, vm.domain);
            vm.isPageCorrectEnabled = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.page_fix, vm.domain);
        }

        function updateDomain (domain) {
            coreDomainService.openDomainForm(domain, FORM_CONSTANT.LITE_DOMAIN_FORM);
        }

        function updateDomainByLabel (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });

            return domainService.update(domain.id, params);
        }

        function openScriptGuide (domain) {
            coreDomainService.openScriptGuide(domain, FORM_CONSTANT.FORM_DOMAIN_SCRIPT_GUIDE);
        }
    }
})();
