import { Injectable } from '@angular/core';
import { CrawlType, AccessibilityVersionChangeType, ChangeTypeEnum, AccessibilityGuidelineVersion } from './accessibility-guideline-version.interface';
import { AccessibilityGuidelinesInfoService } from 'app/modules/accessibility/services/mon-accessibility-guidelines/accessibility-guideline-info.service';
import { AccessibilityGuidelineInterface } from 'app/modules/accessibility/interfaces/acc-info-interface';
import { TranslateService } from '@client/app/services/translate/translate.service';

/** Service that computes the accessibility guideline changes between crawl history entries
 */
@Injectable()
export class AccessibilityGuidelineVersionService {
    v2UpdateText: string;
    accessibilityGuidelineVersions: Record<string, AccessibilityGuidelineVersion>;
    a11yGuidelineLink =
        'https://help.monsido.com/en/articles/5951206-visual-indication-when-you-have-switched-accessibility-guideline';
    a11yGuidelineLinkV2 = 'https://help.monsido.com/en/articles/6639492-accessibility-checks-update-november-2022';

    constructor (
        private accessibilityGuideLinesInfoService: AccessibilityGuidelinesInfoService,
        private translateService: TranslateService,
    ) {
        this.v2UpdateText = this.translateService.getString('Accessibility checks update');
        this.accessibilityGuidelineVersions = {
            'WCAG2-AAA': {
                title: 'WCAG 2.0 AAA',
            },
            'WCAG2-AA': {
                title: 'WCAG 2.0 AA',
            },
            'WCAG2-A': {
                title: 'WCAG 2.0 A',
            },
            'WCAG2-AAA_v2': {
                title: 'WCAG 2.0 AAA',
            },
            'WCAG2-AA_v2': {
                title: 'WCAG 2.0 AA',
            },
            'WCAG2-A_v2': {
                title: 'WCAG 2.0 A',
            },
            'WCAG21-AAA': {
                title: 'WCAG 2.1 AAA',
            },
            'WCAG21-AA': {
                title: 'WCAG 2.1 AA',
            },
            'WCAG21-A': {
                title: 'WCAG 2.1 A',
            },
            'WCAG21-AAA_mobile': {
                title: 'WCAG 2.1 AAA Mobile',
            },
            'WCAG21-AA_mobile': {
                title: 'WCAG 2.1 AA Mobile',
            },
            'WCAG21-A_mobile': {
                title: 'WCAG 2.1 A Mobile',
            },
            'WCAG21-AAA_mobile_v2': {
                title: 'WCAG 2.1 AAA Mobile',
            },
            'WCAG21-AA_mobile_v2': {
                title: 'WCAG 2.1 AA Mobile',
            },
            'WCAG21-A_mobile_v2': {
                title: 'WCAG 2.1 A Mobile',
            },
            'WCAG21-AAA_v2': {
                title: 'WCAG 2.1 AAA',
            },
            'WCAG21-AA_v2': {
                title: 'WCAG 2.1 AA',
            },
            'WCAG21-A_v2': {
                title: 'WCAG 2.1 A',
            },
            'WCAG22-AAA': {
                title: 'WCAG 2.2 AAA',
            },
            'WCAG22-AA': {
                title: 'WCAG 2.2 AA',
            },
            'WCAG22-A': {
                title: 'WCAG 2.2 A',
            },
            'WCAG22-AAA_v2': {
                title: 'WCAG 2.2 AAA',
            },
            'WCAG22-AA_v2': {
                title: 'WCAG 2.2 AA',
            },
            'WCAG22-A_v2': {
                title: 'WCAG 2.2 A',
            },
        };
    }

    /** Get accessibility guidelines list then computes and
     * returns a Promise of an array of accessibility version change objects from crawls
     * @param crawls - Sorted array of crawl history entries
     * */
    public async getVersions (crawls: CrawlType[]): Promise<AccessibilityVersionChangeType[]> {
        const result: AccessibilityVersionChangeType[] = [];

        if (crawls.length <= 1) {
            return result;
        }

        const abbrs = crawls.map((crawl) => crawl.accessibility_guideline);

        const guidelines = await this.accessibilityGuideLinesInfoService.get(abbrs);
        return this.getVersionsFromGuidelines(crawls, guidelines);
    }

    /** Returns an array of accessibility version change objects from crawls
     * @param crawls - Sorted array of crawl history entries
     * @param {AccessibilityGuidelineInterface[]} guidelines - Accessibility guidelines array
     * */
    private getVersionsFromGuidelines (
        crawls: CrawlType[],
        guidelines: AccessibilityGuidelineInterface[],
    ): AccessibilityVersionChangeType[] {
        const result: AccessibilityVersionChangeType[] = [];

        if (crawls.length <= 1 || guidelines.length === 0) {
            return result;
        }

        for (let i = 1; i < crawls.length; i++) {
            const curCrawl = crawls[i];
            const prevCrawl = crawls[i - 1];

            if (curCrawl.accessibility_guideline !== prevCrawl.accessibility_guideline) {
                const change = this.getChangeType([prevCrawl, curCrawl], guidelines);
                const version = this.accessibilityGuidelineVersions[curCrawl.accessibility_guideline];

                if (version) {
                    const res: AccessibilityVersionChangeType = {
                        title: '',
                        infoLink: '',
                        time: curCrawl.post_processing_done_at,
                        changeType: change,
                    };
                    switch (change) {
                        case ChangeTypeEnum.BY_USER:
                            res.title = version.title;
                            res.infoLink = this.a11yGuidelineLink;
                            break;
                        case ChangeTypeEnum.NEW_VERSION:
                            res.title = this.v2UpdateText;
                            res.infoLink = this.a11yGuidelineLinkV2;
                            break;
                        case ChangeTypeEnum.NEW_VERSION_AND_BY_USER:
                            res.title = `${version.title} - ${this.v2UpdateText}`;
                            res.infoLink = this.a11yGuidelineLinkV2;
                            break;
                        default: break;
                    }

                    result.push(res);

                }
            }
        }
        return result;
    }

    private getChangeType (crawls: [CrawlType, CrawlType], guidelines: AccessibilityGuidelineInterface[]): ChangeTypeEnum {
        const [prev, current] = crawls;

        let currentGuideline: AccessibilityGuidelineInterface | undefined;
        let prevGuideline: AccessibilityGuidelineInterface | undefined;


        for (const guideline of guidelines) {
            if (guideline.abbr === current.accessibility_guideline) {
                currentGuideline = guideline;
            }
            if (guideline.abbr === prev.accessibility_guideline) {
                prevGuideline = guideline;
            }
            if (prevGuideline && currentGuideline) {
                break;
            }
        }

        if (currentGuideline && prevGuideline) {
            const [areBothNewVersion, areBothSameVersion] = this.areBothNewAndSameVersion(currentGuideline, prevGuideline);

            if (currentGuideline.previous_guideline_id === prevGuideline.id) {
                return ChangeTypeEnum.NEW_VERSION;
            }

            if (!areBothNewVersion &&
                !areBothSameVersion &&
                prevGuideline.previous_guideline_id !== currentGuideline.previous_guideline_id &&
                currentGuideline.previous_guideline_id !== prevGuideline.id) {
                return ChangeTypeEnum.NEW_VERSION_AND_BY_USER;
            }
        }

        return ChangeTypeEnum.BY_USER;
    }

    private areBothNewAndSameVersion (curr: AccessibilityGuidelineInterface, prev: AccessibilityGuidelineInterface): boolean[] {
        const target = '_v';

        const currVersion = curr.abbr.slice(-3);
        const prevVersion = prev.abbr.slice(-3);

        return [currVersion.includes(target) && prevVersion.includes(target), curr.abbr === prev.abbr];
    }
}
