(function () {
    'use strict';

    angular.module('services.api').factory('UptimeRepo', UptimeRepo);

    UptimeRepo.$inject = ['ng2ApiService', 'ng2SessionService'];

    /* @ngInject */
    function UptimeRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            get: get,
            create: create,
            update: update,
            destroy: destroy,
            getReport: getReport,
            getLogs: getLogs,
            getDowntimes: getDowntimes,
            getAllScheduledDowntimes: getAllScheduledDowntimes,
            createScheduledDowntime: createScheduledDowntime,
            updateScheduledDowntime: updateScheduledDowntime,
            destroyScheduledDowntime: destroyScheduledDowntime,
        };

        return service;

        // /////////////

        function getAll (params, config, domain_id) {
            domain_id = domain_id || ng2SessionService.getDomainId(params);
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + domain_id + '/uptimes', params, config);
        }

        function getReport (id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + id + '/report', params, config);
        }

        function getLogs (id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + id + '/logs', params, config);
        }

        function getDowntimes (id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + id + '/downtimes', params, config);
        }

        function get (id, config) {
            config = config || {};
            var params = {};
            params.group_id = ng2SessionService.getGroupId();
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/uptimes/' + id, params, config);
        }

        function destroy (id, config) {
            config = config || {};
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/uptimes/' + id, {}, config);
        }

        function create (params, config) {
            config = config || {};
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/uptimes', params, config);
        }

        function update (data, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + data.id, data, params, config);
        }

        function getAllScheduledDowntimes (uptimeId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                '/domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + uptimeId + '/scheduled_downtimes',
                params,
                config,
            );
        }

        function createScheduledDowntime (uptimeId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.post(
                '/domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + uptimeId + '/scheduled_downtimes',
                params,
                config,
            );
        }

        function updateScheduledDowntime (uptimeId, id, data, params, config) {
            config = config || {};
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                '/domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + uptimeId + '/scheduled_downtimes/' + id,
                data,
                params,
                config,
            );
        }

        function destroyScheduledDowntime (uptimeId, id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.delete(
                '/domains/' + ng2SessionService.getDomainId(params) + '/uptimes/' + uptimeId + '/scheduled_downtimes/' + id,
                params,
                config,
            );
        }
    }
})();
