(function () {
    'use strict';
    angular.module('app.components').component('pagesWithDuplicateTitlePages', {
        templateUrl: 'app/pages/seo/dialogs/pages-with-duplicate-title/blocks/pages/pages.html',
        controller: PagesWithDuplicateTitlePagesController,
        controllerAs: 'vm',
        bindings: {
            selectedTitle: '<',
            issue: '<',
            getTitles: '&',
        },
    });

    PagesWithDuplicateTitlePagesController.$inject = [
        '$scope',
        'ParamService',
        '$controller',
        'SeoRepo',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2ActiveFeatureService',
    ];

    function PagesWithDuplicateTitlePagesController (
        $scope,
        ParamService,
        $controller,
        SeoRepo,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.pages = [];
            vm.promise = null;
            vm.onPageDetailsClose = onPageDetailsClose;

            setWatchers();
            if (vm.selectedTitle !== null) {
                getPage();
            }
        }

        function setWatchers () {
            $scope.$watch('vm.selectedTitle', function () {
                if (vm.selectedTitle !== null) {
                    getPage();
                }
            });
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.sortBy,
                sort_dir: vm.sortDirection,
                search: vm.search || null,
                status: vm.activeTab || null,
                title: vm.selectedTitle.title,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                status: vm.activeTab,
                search: vm.activeTab === 'filtered' ? vm.search : null,
                sort_by: vm.sortBy,
                sort_dir: vm.sortDirection,
                content: vm.selectedTitle.title,
            };
            vm.promise = SeoRepo.getSeoIssuePages(vm.issue.id, params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }

        function onPageDetailsClose () {
            vm.getTitles();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
