(function () {
    'use strict';

    angular.module('modules.demodata.endpoints').factory('DemoClarityRepo', DemoClarityRepo);

    DemoClarityRepo.$inject = ['$q', '$timeout'];

    /* @ngInject */
    function DemoClarityRepo ($q, $timeout) {
        var vm = this;

        function init () {
            generateData();

            return {
                getAllChecks: getAllChecks,
                getSummary: getSummary,
            };
        }

        return init();

        // /////////////

        function getAllChecks () {
            return getData('getAllChecks');
        }

        function getSummary () {
            return getData('summary');
        }

        // PROTECTED

        function getData (fnName) {
            var defer = $q.defer();
            var timer = Math.round(1000 * Math.random());
            $timeout(function () {
                switch (fnName) {
                    case 'summary':
                        defer.resolve(angular.copy(vm.summary));
                        break;
                    case 'getAllChecks':
                        defer.resolve(angular.copy(vm.checks));
                        break;
                    default:
                        defer.resolve([]);
                }
            }, timer);
            return defer.promise;
        }

        function generateData () {
            vm.summary = {
                checks: 45,
                pdfs: 1682,
                pdfs_with_issues: 0,
                pdfs_with_issues_by_result_type: {},
                checks_with_issues: 0,
                checks_with_issues_by_result_type: {},
                queued_count: 2,
                tokens: 0,
            };

            vm.checks = [
                {
                    id: 45,
                    name: '4.1.2 Name, Role, Value',
                    errors_count: 0,
                },
                {
                    id: 1,
                    name: 'Alternative Representation for Figures',
                    errors_count: 0,
                },
                {
                    id: 4,
                    name: 'Alternative Representation for Forms',
                    errors_count: 0,
                },
                {
                    id: 2,
                    name: 'Alternative Representation for Formulas',
                    errors_count: 0,
                },
                {
                    id: 3,
                    name: 'Alternative Representation for Links',
                    errors_count: 0,
                },
                {
                    id: 5,
                    name: 'Alternative Representation for Other Annotations',
                    errors_count: 0,
                },
                {
                    id: 29,
                    name: 'Article Threads',
                    errors_count: 0,
                },
                {
                    id: 6,
                    name: 'Captions',
                    errors_count: 0,
                },
                {
                    id: 42,
                    name: 'Change of context',
                    errors_count: 0,
                },
                {
                    id: 16,
                    name: 'Correct Structure - Ruby',
                    errors_count: 0,
                },
                {
                    id: 21,
                    name: 'Correct Structure - Warichu',
                    errors_count: 0,
                },
                {
                    id: 22,
                    name: 'Correct Structure - WT and WP',
                    errors_count: 0,
                },
                {
                    id: 7,
                    name: 'Form Annotations - Valid Tagging',
                    errors_count: 0,
                },
                {
                    id: 31,
                    name: 'Format, layout and color',
                    errors_count: 0,
                },
                {
                    id: 44,
                    name: 'Form fields value validation',
                    errors_count: 0,
                },
                {
                    id: 25,
                    name: 'Header Cells',
                    errors_count: 0,
                },
                {
                    id: 41,
                    name: 'Header/Footer pagination artifacts',
                    errors_count: 0,
                },
                {
                    id: 23,
                    name: 'Heading Levels',
                    errors_count: 0,
                },
                {
                    id: 37,
                    name: 'Headings defined',
                    errors_count: 0,
                },
                {
                    id: 33,
                    name: 'Images of text - OCR',
                    errors_count: 0,
                },
                {
                    id: 40,
                    name: 'Language specified',
                    errors_count: 0,
                },
                {
                    id: 8,
                    name: 'Lbl - Valid Parent',
                    errors_count: 0,
                },
                {
                    id: 9,
                    name: 'LBody - Valid Parent',
                    errors_count: 0,
                },
                {
                    id: 10,
                    name: 'Link Annotations',
                    errors_count: 0,
                },
                {
                    id: 11,
                    name: 'Links',
                    errors_count: 0,
                },
                {
                    id: 13,
                    name: 'List',
                    errors_count: 0,
                },
                {
                    id: 12,
                    name: 'List Item',
                    errors_count: 0,
                },
                {
                    id: 24,
                    name: 'ListNumbering',
                    errors_count: 0,
                },
                {
                    id: 28,
                    name: 'Meaningful Sequence',
                    errors_count: 0,
                },
                {
                    id: 39,
                    name: 'Metadata - Title and Viewer Preferences',
                    errors_count: 0,
                },
                {
                    id: 32,
                    name: 'Minimum Contrast',
                    errors_count: 0,
                },
                {
                    id: 14,
                    name: 'Other Annotations - Valid Tagging',
                    errors_count: 0,
                },
                {
                    id: 38,
                    name: 'Outlines (Bookmarks)',
                    errors_count: 0,
                },
                {
                    id: 43,
                    name: 'Required fields',
                    errors_count: 0,
                },
                {
                    id: 15,
                    name: 'RP, RT and RB - Valid Parent',
                    errors_count: 0,
                },
                {
                    id: 27,
                    name: 'Scope attribute',
                    errors_count: 0,
                },
                {
                    id: 34,
                    name: 'Server-side image maps',
                    errors_count: 0,
                },
                {
                    id: 26,
                    name: 'Summary attribute',
                    errors_count: 0,
                },
                {
                    id: 20,
                    name: 'Table',
                    errors_count: 0,
                },
                {
                    id: 17,
                    name: 'Table Cells',
                    errors_count: 0,
                },
                {
                    id: 19,
                    name: 'Table Rows',
                    errors_count: 0,
                },
                {
                    id: 30,
                    name: 'Tabs Key',
                    errors_count: 0,
                },
                {
                    id: 18,
                    name: 'THead, TBody and TFoot',
                    errors_count: 0,
                },
                {
                    id: 36,
                    name: 'Three Flashes or Below Threshold',
                    errors_count: 0,
                },
                {
                    id: 35,
                    name: 'Timing Adjustable',
                    errors_count: 0,
                },
            ];
        }
    }
})();
