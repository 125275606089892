(function () {
    'use strict';

    angular.module('pages.pages').component('pagesOverview', {
        templateUrl: 'app/pages/pages/index.html',
        controller: PagesOverviewController,
        controllerAs: 'vm',
    });

    PagesOverviewController.$inject = [
        'PageRepo',
        '$location',
        'ParamService',
        'pagesTabsHelper',
        '$controller',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'gettextCatalog',
        '$filter',
    ];
    /* @ngInject */
    function PagesOverviewController (
        PageRepo,
        $location,
        ParamService,
        pagesTabsHelper,
        $controller,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        gettextCatalog,
        $filter,
    ) {
        var vm = this;

        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.subheaderTranslation = '';
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.issues = [];
            vm.onSearch = onSearch;
            vm.pages = [];
            vm.tabs = pagesTabsHelper.getTabs();

            loadLocations();
            getPage();
        }

        function loadLocations () {
            vm.search = $location.search().search || null;
            vm.currentSort = {};
            vm.currentSort.by = $location.search().sort_by || 'priority_score';
            vm.currentSort.direction = $location.search().sort_dir || 'desc';
            vm.activeTab = pagesTabsHelper.getActiveTab();
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search,
                type: vm.activeTab,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                notifications: true,
            };

            if (vm.activeTab) {
                params['types[]'] = [vm.activeTab];
            }

            params = vm.updateParamsWithFilters(params);
            params = updateParamsWithIssuesFilters(params);

            vm.promise = PageRepo.getAll(params).then(function (data) {
                vm.pages = data;
                createHeaderTranslation();
            }, angular.noop);
        }

        function createHeaderTranslation () {
            var params = {
                total: $filter('monNumber')(vm.pages.total),
            };
            vm.subheaderTranslation = gettextCatalog.getString(
                '{{total}} pages. Prioritize and fix all content issues across your website from one consolidated view.',
                params,
            );
        }

        function updateParamsWithIssuesFilters (params) {
            for (var i = 0; i < vm.issues.length; i++) {
                params[vm.issues[i].id] = true;
            }
            return params;
        }

        function onSearch (search) {
            if (search === undefined || search === '') {
                vm.search = null;
            } else {
                vm.search = search;
            }

            vm.page = 1;
            getPage();
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            if (changes.contains) {
                vm.issues = changes.contains;
            }
            vm.getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
