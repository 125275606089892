(function () {
    'use strict';

    angular.module('modules.support').run([
        'ng2MonEventsService',
        'SupportApi',
        'ng2OauthService',
        function (ng2MonEventsService, SupportApi, oauthService) {
            ng2MonEventsService.addListener('beforeLoadUser', function () {
                SupportApi.config.headers.Authorization = oauthService.getTokenHeaderValue();
            });

            ng2MonEventsService.addListener('newAccessToken', () => {
                SupportApi.config.headers.Authorization = oauthService.getTokenHeaderValue();
            });

            ng2MonEventsService.addListener('logout', function () {
                delete SupportApi.config.headers.Authorization;
            });
        },
    ]);
})();
