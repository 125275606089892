(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsDevices', {
        templateUrl: 'app/pages/statistics/visitors/devices/devices.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [
        'StatsRepo',
        'API_CONSTANTS',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$filter',
        '$q',
        'ng2SessionService',
        'dateRangeService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsVisitorsController (
        StatsRepo,
        API_CONSTANTS,
        $stateParams,
        ParamService,
        gettextCatalog,
        $filter,
        $q,
        ng2SessionService,
        dateRangeService,
        DATE_FORMAT,
    ) {
        var vm = this;

        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.itemClass = itemClass;
        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.totalVisits = 0;
            vm.pageSize = 100;
            vm.summary = {};
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;

            vm.pages = [];
            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.barData = [];
            vm.chartColors = ['#3669d3', '#89cdfd', '#3da3e9'];
            vm.doughnutOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: function (element) {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: function (element) {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
            vm.barOptions = {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]) +
                                ' ' +
                                gettextCatalog.getString('Visits')
                            );
                        },
                    },
                },
            };

            vm.barOverride = [
                {
                    borderWidth: 0,
                    borderColor: ['#3669d3', '#89cdfd', '#3da3e9'],
                    backgroundColor: ['#3669d3', '#89cdfd', '#3da3e9'],
                },
            ];

            updateDateRange();
        }

        function getPage () {
            vm.loading = true;
            vm.promise = $q.all([getPages(), getSummary()]).finally(function () {
                vm.loading = false;
            });
        }

        function getPages () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export visitors devices'),
                    name: gettextCatalog.getString('Visitors devices export'),
                    data: {
                        category: 'visitors_devices',
                        category_ref: vm.date.startDate?.format('YYYY-MM-DD') + ',' + vm.date.endDate?.format('YYYY-MM-DD'),
                    },
                },
            ];
            var others = 0;
            return StatsRepo.getDevices(params).then(function (data) {
                vm.pages = data.filter(function (device) {
                    return device.visits > 0;
                });
                vm.totalVisits = data.reduce(function (total, page) {
                    return total + parseInt(page.visits);
                }, 0);
                vm.doughnutLabels = [];
                vm.doughnutData = [];
                for (var i = 0; i < vm.pages.length; i++) {
                    if (i < 5) {
                        vm.doughnutLabels.push(vm.pages[i].label);
                        vm.doughnutData.push(vm.pages[i].visits);
                    } else {
                        others += parseInt(vm.pages[i].visits);
                    }
                }
                if (vm.pages.length > 5) {
                    vm.doughnutLabels.push(gettextCatalog.getString('Others'));
                    vm.doughnutData.push(others);
                }

                vm.barData = [vm.doughnutData];
            }, angular.noop);
        }

        function getSummary () {
            var params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
                type: 'total',
            };
            return StatsRepo.getSummary(params).then(function (data) {
                vm.summary = data;
            }, angular.noop);
        }

        function pageChange (page) {
            vm.page = page;
            getPage();
        }

        function perPageChange (pageSize) {
            vm.pageSize = pageSize;
            getPage();
        }

        function updateDateRange () {
            if (vm.date.startDate || vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
                getPage();
            }
        }

        function average (array, prop) {
            var sum = 0;
            if (!angular.isArray(array) || array.length === 0) {
                return 0;
            }
            angular.forEach(array, function (item) {
                sum += parseInt(item[prop]);
            });
            return sum / array.length;
        }

        function itemClass (index) {
            switch (index) {
                case 0:
                    return 'visitor-summary-devices__first';
                case 1:
                    return 'visitor-summary-devices__second';
                case 2:
                    return 'visitor-summary-devices__third';
            }
        }
    }
})();
