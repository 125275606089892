(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name accessibilityService
     * @description Service to handle ada
     */
    angular.module('services.entities').factory('accessibilityService', accessibilityService);

    accessibilityService.$inject = ['AccessibilityFastTrackRepo', 'AccessibilityChecksRepo', 'ng2MonEventsService'];
    /* @ngInject*/
    function accessibilityService (AccessibilityFastTrackRepo, AccessibilityChecksRepo, ng2MonEventsService) {
        return {
            fastTrackUpdateCheck: fastTrackUpdateCheck,
            updateCheck: updateCheck,
            fastTrackCloseCaption: fastTrackCloseCaption,
            undoAccessibilityCheck: undoAccessibilityCheck,
        };

        /**
         * @memberOf accessibilityService
         * @desc Update Fasttrack check
         * @param {number} id The id of the issue
         * @param {object} content Check object
         * @param {string} type The type of update
         * @returns {Promise}
         */
        function fastTrackUpdateCheck (id, content, type) {
            return AccessibilityFastTrackRepo.updateCheck(id, content).then(function (data) {
                ng2MonEventsService.run('fasttrack' + type, content.id);

                return data;
            }, angular.noop);
        }

        /**
         * @memberOf accessibilityService
         * @desc Update Fasttrack close caption
         * @param {number} accId The id of the accessibility
         * @param {object} data Check object
         * @param {string} type The type of update
         * @returns {Promise}
         */
        function fastTrackCloseCaption (accId, data, params, config) {
            return AccessibilityFastTrackRepo.closeCaption(accId, data, params, config);
        }

        /**
         * @memberOf accessibilityService
         * @desc Update Accessibility check
         * @param {object} check The modified check object
         * @returns {Promise}
         */
        function updateCheck (check, params) {
            return AccessibilityChecksRepo.update(check, params);
        }

        /**
         * @memberOf accessibilityService
         * @desc Undo Accessibility check
         * @param {domainId} domainId The id of the domain
         * @param {number} accessibilitySourceCodeId The id of the accessibility source code
         * @param {number} checkId Check id
         * @returns {Promise}
         */
        function undoAccessibilityCheck (domainId, accessibilitySourceCodeId, checkId, params) {
            return AccessibilityFastTrackRepo.undoAccessibilityCheck(domainId, accessibilitySourceCodeId, checkId, params);
        }
    }
})();
