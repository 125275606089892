import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';
import { MonUIRouterTransitionService } from '@monsido/ng2/services/ui-router-transition-service/ui-router-transition-service';
import { Ng2StateDeclaration, StateService } from '@uirouter/angular';
import { Ng1ApiUsersDashboardComponent } from 'app/modules/api-users/pages/dashboard/ng1-dashboard.component';

export const ADMIN_API_USERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.customer.admin.api_users',
        url: '/api_users',
        abstract: true,
    },
    {
        name: 'base.customer.admin.api_users.index',
        url: '?page_size&page',
        views: {
            '^.^.^.^.$default': {
                component: Ng1ApiUsersDashboardComponent,
            },
        },
        resolve: [{
            token: 'apiUsersResolveFn',
            deps: [
                ActiveFeatureService, MonUIRouterTransitionService, StateService,
            ],
            resolveFn: async (
                activeFeatureService: ActiveFeatureService,
                transitionService: MonUIRouterTransitionService,
                state: StateService,
            ): Promise<void> => {
                if (!activeFeatureService.isFeatureActive('api_users')) {
                    await (new Promise((resolve) => {
                        transitionService.onTransitionFinished(
                            () => {
                                state.go('base.dashboard');
                                resolve(undefined);
                            },
                        );
                    }));
                }
            },
        }],

    },
];
