(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSummaryTrafficSources', {
        templateUrl: 'app/pages/statistics/summary/traffic-sources/traffic-sources.html',
        controller: StatisticsSummaryTrafficSourcesController,
        controllerAs: 'vm',
        bindings: {
            startDate: '<',
            endDate: '<',
        },
    });

    StatisticsSummaryTrafficSourcesController.$inject = [
        'ParamService',
        'StatsRepo',
        '$q',
        '$filter',
        'gettextCatalog',
        'Chart',
        'moment',
        '$deepmerge',
    ];
    /* @ngInject */
    function StatisticsSummaryTrafficSourcesController (
        ParamService,
        StatsRepo,
        $q,
        $filter,
        gettextCatalog,
        Chart,
        moment,
        $deepmerge,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = update;

        function activate () {
            vm.remoteData = {};
            vm.counts = {
                total: 0,
            };
            setupChartSettings();
        }

        function update (changes) {
            if (changes === undefined) {
                return;
            }
            setupChart();
        }

        function setupChartSettings () {
            vm.lineChartLabels = [];
            vm.lineChartOverride = [];
            vm.lineChartSeries = [
                gettextCatalog.getString('Search engine'),
                gettextCatalog.getString('Direct visits'),
                gettextCatalog.getString('Referring visits'),
                gettextCatalog.getString('Social visits'),
                gettextCatalog.getString('Campaign visits'),
            ];
            vm.lineChartColors = ['#71a3f6', '#57B754', '#EAA646', '#9500AC', '#D24747'];
            vm.chart = new Chart();
            vm.chart.setTooltipCustom({
                mode: 'label',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        return (
                            data.datasets[tooltipItem.datasetIndex].label +
                            ': ' +
                            $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                        );
                    },
                },
            });
            vm.chart.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 4,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                            min: 0,
                        },
                        stacked: true,
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        stacked: true,
                        ticks: {
                            callback: function (value) {
                                if (value) {
                                    return moment(value, 'll').format('MMM DD');
                                }
                                return '';
                            },
                        },
                    },
                ],
            });

            for (var i = 0; i < vm.lineChartSeries.length; i++) {
                vm.lineChartOverride.push({
                    type: 'bar',
                    fill: false,
                    borderColor: vm.lineChartColors[i],
                    borderWidth: 1,
                });
            }
        }

        function setupChart () {
            var promises = [];
            var params = {
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            var types = ['organic', 'direct', 'referring', 'social', 'paid'];
            for (var j = 0; j < types.length; j++) {
                promises.push(getVisitorsForType(types[j], params));
            }

            vm.lineChartData = [];
            vm.loading = true;
            $q.all(promises)
                .then(function () {
                    var typeWithMostData = types.reduce(function (primaryType, type) {
                        if (vm.remoteData[type].length < vm.remoteData[primaryType].length) {
                            return type;
                        }
                        return primaryType;
                    }, 'organic');

                    vm.counts.total = 0;

                    types.forEach(function (type) {
                        vm.lineChartData.push(
                            vm.remoteData[type].map(function (typeData) {
                                return +typeData.visits || 0;
                            }),
                        );
                        vm.counts[type] = vm.lineChartData[vm.lineChartData.length - 1].reduce(function (
                            num,
                            currentValue,
                        ) {
                            return num + currentValue;
                        },
                        0);
                        vm.counts.total += vm.counts[type];
                    });

                    vm.lineChartLabels = vm.remoteData[typeWithMostData].map(function (typeData) {
                        return moment(typeData.date)
                            .utc()
                            .format('ll');
                    });
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getVisitorsForType (type, params) {
            return StatsRepo.getVisitors($deepmerge(params, { type: type })).then(
                function (data) {
                    vm.remoteData[type] = data;
                },
                function () {
                    vm.remoteData[type] = [];
                },
            );
        }
    }
})();
