(function () {
    'use strict';
    angular.module('modules.extension').service('extensionService', extensionService);

    extensionService.$inject = [
        'Chrome',
        '$document',
        '$q',
        '$window',
        'gettextCatalog',
        'chromeExtensionId',
        'edgeExtensionId',
        'firefoxExtensionId',
        'ng2SessionService',
        'deviceDetectorService',
        'firefoxExtensionURL',
        'coreLoginService',
        'EXTENSION_DEEPLINK_MODULE',
        'ng2MonEventsService',
        'MON_EVENTS',
    ];
    /* @ngInject*/
    function extensionService (
        Chrome,
        $document,
        $q,
        $window,
        gettextCatalog,
        chromeExtensionId,
        edgeExtensionId,
        firefoxExtensionId,
        ng2SessionService,
        deviceDetectorService,
        firefoxExtensionURL,
        coreLoginService,
        EXTENSION_DEEPLINK_MODULE,
        ng2MonEventsService,
        MON_EVENTS,
    ) {
        var vm = this;

        function init () {
            vm.hasV2 = false;
            vm.hasExtension = null;
            vm.currentBrowser = deviceDetectorService.browser;
            vm.extensions = {
                chrome: {
                    url: 'https://chrome.google.com/webstore/detail/monsido-extension/' + chromeExtensionId,
                    downloadLabel: gettextCatalog.getString('Download the Chrome extension'),
                    label: gettextCatalog.getString('Chrome extension'),
                    image: 'images/monsido-extension-chrome.png',
                },
                edge: {
                    url: 'https://microsoftedge.microsoft.com/addons/detail/' + edgeExtensionId,
                    downloadLabel: gettextCatalog.getString('Download the Edge add-on'),
                    label: gettextCatalog.getString('Edge add-on'),
                    image: 'images/monsido-extension-edge.png',
                },
                firefox: {
                    url: firefoxExtensionURL,
                    downloadLabel: gettextCatalog.getString('Download the Firefox add-on'),
                    label: gettextCatalog.getString('Firefox add-on'),
                    image: 'images/monsido-extension-firefox.png',
                },
            };

            return {
                isInstalled: isInstalled,
                getExtensionDeepLink: getExtensionDeepLink,
                getCurrentExtensionInfo: getCurrentExtensionInfo,
                sendDeeplinkMessage: sendDeeplinkMessage,
                getExtensionDeeplinkParams: getExtensionDeeplinkParams,
                getDeeplinkMethod: getDeeplinkMethod,
                openExtensionDeeplink: openExtensionDeeplink,
            };
        }

        return init();

        function isInstalled () {
            var deferred = $q.defer();
            if (vm.hasExtension !== null) {
                deferred.resolve(vm.hasExtension);
                return deferred.promise;
            }

            var extensionId;

            switch (deviceDetectorService.browser) {
                case 'chrome':
                    extensionId = chromeExtensionId;
                    return isChromeExtensionInstalled(extensionId);
                case 'edge':
                    extensionId = edgeExtensionId;
                    return isChromeExtensionInstalled(extensionId);
                case 'firefox':
                    extensionId = firefoxExtensionId;
                    return isFirefoxExtensionInstalled(extensionId);
                default:
                    vm.hasExtension = false;
                    deferred.resolve(false);
                    break;
            }

            return deferred.promise;
        }

        function getExtensionDeepLink (params) {
            var domainId = params.page.domain_id;
            var accountId = ng2SessionService.agreement.account.id;
            var issueId = params.issueId;

            var res; var accType; var accCheck;
            var baseUrl = params.page.url;
            if (baseUrl.indexOf('?') === -1) {
                baseUrl += '?';
            } else {
                baseUrl += '&';
            }

            if (!params.type) {
                return baseUrl + 'monsidoDomainId=' + domainId + '&monsidoAccountId=' + accountId;
            }
            if (!params.data) {
                return baseUrl + 'monsidoDomainId=' + domainId + '&monsidoAccountId=' + accountId + '&monsidoModule=' + params.type;
            }
            switch (params.type) {
                case EXTENSION_DEEPLINK_MODULE.BrokenImages.id:
                case EXTENSION_DEEPLINK_MODULE.BrokenLinks.id:
                    issueId = issueId || params.data.link_id;
                    break;
                case EXTENSION_DEEPLINK_MODULE.Misspelling.id:
                case EXTENSION_DEEPLINK_MODULE.PolicyIssue.id:
                case EXTENSION_DEEPLINK_MODULE.SeoIssue.id:
                    issueId = issueId || params.data.id;
                    break;
                case EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id:
                    accType = params.data.check.issue_abbr.toUpperCase();
                    accCheck = params.data.check.id;
                    issueId = issueId || (params.data.error ? params.data.error.id : '');
                    break;
                case EXTENSION_DEEPLINK_MODULE.Readability.id:
                case EXTENSION_DEEPLINK_MODULE.Heatmaps.id:
                default:
                    issueId = null;
            }

            if (params.type === EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id) {
                res =
                    baseUrl +
                    'monsidoDomainId=' +
                    domainId +
                    '&monsidoAccountId=' +
                    accountId +
                    '&monsidoModule=' +
                    params.type +
                    '&monsidoAccType=' +
                    accType +
                    '&monsidoAccCheckId=' +
                    accCheck;
                if (issueId) {
                    res += '&monsidoIssueId=' + issueId;
                }
            } else {
                res = baseUrl + 'monsidoDomainId=' + domainId + '&monsidoAccountId=' + accountId + '&monsidoModule=' + params.type;
                if (issueId) {
                    res += '&monsidoIssueId=' + issueId;
                }
            }

            return res;
        }

        function getCurrentExtensionInfo () {
            return vm.extensions[vm.currentBrowser];
        }

        function sendDeeplinkMessage (action, params) {
            var message = {
                type: 'deeplink',
                action: action,
                params: params || {},
            };

            if (ng2SessionService.isSudo) {
                message.params.isSudo = true;
                message.params.sudoParams = {
                    accountId: ng2SessionService.agreement.account.id,
                    token: coreLoginService.getAuthToken(),
                    refreshToken: coreLoginService.getRefreshToken(),
                    baseURL: coreLoginService.getApiPath() + '/api/',
                };
            }
            $window.postMessage(sanitizePayload(message));
        }

        function getExtensionDeeplinkParams (params) {
            const domainId = params?.page?.domain_id;
            const accountId = ng2SessionService.agreement.account.id;
            const res = Object.assign(params, { accountId, domainId });
            delete res.page;

            Object.keys(res).forEach(key => {
                if (res[key] == null) {
                    delete res[key];
                }
            });
            let issueId = params.issueId;
            let issueAbbr; let checkId; let pageLinkId; let pageId; let word;

            if (!params.type) {
                return res;
            }
            if (!params.data) {
                res.module = params.type;
                return res;
            }

            switch (params.type) {
                case EXTENSION_DEEPLINK_MODULE.BrokenImages.id:
                case EXTENSION_DEEPLINK_MODULE.BrokenLinks.id:
                    issueId = issueId || params.data.link_id;
                    pageId = params.data.page_id || null;
                    pageLinkId = params.data.link_id || null;
                    break;
                case EXTENSION_DEEPLINK_MODULE.Misspelling.id:
                case EXTENSION_DEEPLINK_MODULE.PolicyIssue.id:
                case EXTENSION_DEEPLINK_MODULE.SeoIssue.id:
                    issueId = issueId || params.data.id;
                    word = params.data.word || null;
                    break;
                case EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id:
                    issueAbbr = params.data.check.issue_abbr.toUpperCase();
                    checkId = params.data.check.id;
                    issueId = issueId || (params.data.error ? params.data.error.id : '');
                    break;
                case EXTENSION_DEEPLINK_MODULE.Readability.id:
                case EXTENSION_DEEPLINK_MODULE.Heatmaps.id:
                default:
                    issueId = null;
            }
            if (params.type === EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id) {
                res.module = EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id;
                res.issueAbbr = issueAbbr;
                res.checkId = checkId;
                res.issueId = issueId;
            } else {
                res.module = params.type;
                if (issueId) {
                    res.issueId = issueId;
                }

                if (pageId) {
                    res.pageId = pageId;
                }

                if (pageLinkId) {
                    res.pageLinkId = pageLinkId;
                }

                if (word) {
                    res.word = word;
                }
            }
            return res;
        }

        function getDeeplinkMethod () {
            return vm.hasV2 ? 'beacon' : 'query';
        }

        async function openExtensionDeeplink (payload) {
            const isExtensionInstalled = await isInstalled();

            if (!isExtensionInstalled) {
                openExtensionGuideDialog(payload.callback);
                return;
            }
            const url = payload.page.url;
            let deepLinkParams = {};
            deepLinkParams = Object.assign(deepLinkParams, payload);
            delete deepLinkParams.callback;

            const params = getExtensionDeeplinkParams(deepLinkParams);

            if (getDeeplinkMethod() === 'beacon') {
                sendDeeplinkMessage('deeplink/openPage', {
                    url: url,
                    params: params,
                });
            } else if (typeof getExtensionDeeplink === 'function') {
                $window.open(getExtensionDeeplink(deepLinkParams), '_blank');
            }
        }

        // PROTECTED

        function sanitizePayload (payload) {
            return JSON.parse(JSON.stringify(payload));
        }

        function isChromeExtensionInstalled (extensionId) {
            var deferred = $q.defer();
            if (Chrome !== null && typeof Chrome === 'object' && Chrome.runtime !== null && typeof Chrome.runtime === 'object') {
                Chrome.runtime.sendMessage(extensionId, { message: 'monsido-extension-installed' }, function (reply) {
                    if (Chrome.runtime.lastError && Chrome.runtime.lastError.message) {
                        vm.hasExtension = false;
                        deferred.resolve(false);
                    } else {
                        vm.hasExtension = Boolean(reply && reply.installed);
                        isExtensionV2Installed(extensionId);
                        deferred.resolve(vm.hasExtension);
                    }
                });
            } else {
                vm.hasExtension = false;
                deferred.resolve(false);
            }

            return deferred.promise;
        }

        function isFirefoxExtensionInstalled (extensionId) {
            var beacon = document.querySelectorAll('[data-monsido-extension-id=\'' + extensionId + '\']');
            isExtensionV2Installed(extensionId);
            return $q.resolve(beacon.length > 0);
        }

        function isExtensionV2Installed (extensionId) {
            var beacon = document.querySelectorAll(
                '[data-monsido-extension-id=\'' + extensionId + '\'][monsido-extension-version=\'' + 2 + '\']',
            );
            vm.hasV2 = beacon.length > 0;
            return vm.hasV2;
        }

        function openExtensionGuideDialog (cb) {
            const params = {
                body: 'extensionGuideDialog',
                size: 'sm',
                classes: 'fade-animation middle center',
            };

            const callback = typeof cb === 'function' ? cb : () => {};

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }
    }
})();
