(function () {
    'use strict';

    angular.module('services.api').factory('GlobalAccessibilityChecksRepo', AccessibilityChecksRepo);

    AccessibilityChecksRepo.$inject = ['ng2ApiService'];
    /* @ngInject */
    function AccessibilityChecksRepo (ng2ApiService) {
        return {
            getAll: getAll,
            update: update,
        };

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.get('accessibility_checks', params, config);
        }

        // /////////////

        function update (check, params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.patch('accessibility_checks/' + check.id, check, params, config);
        }
    }
})();
