(function () {
    'use strict';

    angular.module('services.entities.user').run([
        'userService',
        'userCacheService',
        'ng2MonEventsService',
        'ng2SessionService',
        function (userService, userCacheService, ng2MonEventsService, ng2SessionService) {
            ng2MonEventsService.addListener('afterCustomer', function () {
                if (ng2SessionService.agreement.admin) {
                    userCacheService.updateDefaultOptions({
                        onExpire: function (key, value) {
                            if (gettingUsers === false) {
                                gettingUsers = true;
                                getUsers({ page_size: 50, page: 1 }).finally(function () {
                                    gettingUsers = false;
                                });
                            }
                        },
                    });

                    var gettingUsers = false;
                    var getUsers = function (params) {
                        return userService.getAll(params).then(function (users) {
                            for (var i = 0; i < users.length; i++) {
                                userCacheService.put(users[i]);
                            }
                            if (users.length === params.page_size) {
                                params.page++;
                                return getUsers(params);
                            }
                        }, angular.noop);
                    };

                    gettingUsers = true;
                    return getUsers({ page_size: 50, page: 1 }).finally(function () {
                        gettingUsers = false;
                    });
                }
            });
        },
    ]);
})();
