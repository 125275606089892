(function () {
    'use strict';

    angular.module('modules.notifications').run([
        'ng2MonEventsService',
        'ng2MonNotificationsService',
        'NotificationRepo',
        'ng2ActiveFeatureService',
        function (ng2MonEventsService, ng2MonNotificationsService, NotificationRepo, ng2ActiveFeatureService) {
            var lastUpdate = null;
            var updateParams = {
                page_size: 100,
                filter: 'new',
            };

            Object.defineProperty(updateParams, 'updated_after', {
                enumerable: true,
                get: function () {
                    return lastUpdate ? encodeURIComponent(lastUpdate) : lastUpdate;
                },
            });

            ng2MonNotificationsService.notifications.subscribe(function (notifications) {
                var latest = notifications[0];

                if (latest) {
                    lastUpdate = latest.done_at || latest.updated_at || latest.created_at;
                }
            });

            ng2MonEventsService.addListener('setAccount', function () {
                if (ng2ActiveFeatureService.isFeatureActive('notifications')) {
                    ng2MonEventsService.addListener('finishCustomer', function () {
                        const params = { ...updateParams };
                        if (params.lastUpdate == null) {
                            delete params.lastUpdate;
                        }
                        if (params.updated_after == null) {
                            delete params.updated_after;
                        }
                        ng2MonNotificationsService.init(NotificationRepo.getAll, params);
                    });
                    ng2MonEventsService.addListener('exitCustomer', function () {
                        ng2MonNotificationsService.cancelPromise();
                    });

                    ng2MonEventsService.addListener('logout', function () {
                        ng2MonNotificationsService.destroy();
                    });
                }
            });
        },
    ]);
})();
