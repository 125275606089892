(function () {
    'use strict';

    angular.module('core.services.login').factory('coreLoginService', coreLoginService);

    coreLoginService.$inject = [
        'ng2SessionService',
        'monsidoPath',
        'oauthClientId',
        'oauthScopes',
        'oauthClientSecret',
        '$window',
        '$rootScope',
        'ng2MonEventsService',
        'ng2OauthService',
        'ng2ErrorHandler',
        '$q',
        'MeRepo',
        'ng2MonLoading',
        'ng2LocalStorageService',
        'ng2HttpLegacyService',
    ];

    /* @ngInject*/
    function coreLoginService (
        ng2SessionService,
        monsidoPath,
        oauthClientId,
        oauthScopes,
        oauthClientSecret,
        $window,
        $rootScope,
        ng2MonEventsService,
        oauthService,
        ng2ErrorHandler,
        $q,
        MeRepo,
        monLoading,
        ng2LocalStorageService,
        ng2HttpLegacyService,
    ) {

        var service = {
            login: function (data) {
                return this.loadUser(data);
            },

            getUser: function () {
                var me = this;
                if (ng2SessionService.isUserLoaded()) {
                    var deferred = $q.defer();
                    deferred.resolve(ng2SessionService.me);
                    return deferred.promise;
                } else {
                    return me.loadUser().then(function (data) {
                        return data;
                    }, ng2ErrorHandler.noopError);
                }
            },

            loadUser: function (loginData) {
                var me = this;
                return ng2MonEventsService.run('beforeLoadUser').then(function () {
                    var config = {
                        headers: {
                            noGlobal: true,
                        },
                    };
                    return MeRepo.get(config).then(
                        function (data) {
                            if (loginData && loginData.accessible_mode === true && data.settings.accessibility === false) {
                                var meUser = angular.copy(data);
                                meUser.settings.accessibility = true;
                                monLoading.show();
                                return MeRepo.update(meUser).then(function (returnUser) {
                                    if (!returnUser) {
                                        ng2MonEventsService.run('newException', 'No user returned upon login', 'critical');
                                    }
                                    return ng2MonEventsService.run('afterLoadUser', returnUser, ng2SessionService.isSudo).then(function () {
                                        monLoading.hide();
                                        return returnUser;
                                    });
                                });
                            } else {
                                if (!data) {
                                    ng2MonEventsService.run('newException', 'No user returned upon login', 'critical');
                                }
                                return ng2MonEventsService.run('afterLoadUser', data, ng2SessionService.isSudo).then(function () {
                                    return data;
                                });
                            }
                        },
                        function (data) {
                            if (data.status !== 423) {
                                if (!ng2SessionService.me || !ng2SessionService.customer) {
                                    ng2ErrorHandler.standardMsg('Missing user or customer', 'info');
                                }
                                ng2ErrorHandler.noopError(data);
                                return me.logout();
                            }
                            return data;
                        },
                    );
                }, angular.noop);
            },
            getAuthorizeUrl: function () {
                return oauthService.getAuthUrlAndSetStateParam(monsidoPath, oauthClientId, oauthScopes);
            },

            getAuthToken: function () {
                return oauthService.getToken();
            },
            getRefreshToken: function () {
                return oauthService.getRefreshToken();
            },
            isLoggedIn: function () {
                return oauthService.isAuthenticated();
            },
            getApiPath: function () {
                return oauthService.getApiPath();
            },
            logout: function () {
                var me = this;
                var token = me.getAuthToken();
                var bodyParams = {
                    token: token,
                    client_id: oauthClientId,
                    client_secret: oauthClientSecret,
                };

                ng2SessionService.me = null;
                ng2SessionService.customer = null;
                ng2SessionService.agreements = null;
                // oauthService.clear();
                me.setMe(null);
                disposeMe();
                ng2LocalStorageService.setItem('roles', [].toString());

                return ng2HttpLegacyService.post(monsidoPath + 'oauth/revoke.json', bodyParams).then(function () {
                    var scope = $rootScope.$$childHead;

                    ng2MonEventsService.run('logout').then(function () {
                        while (scope) {
                            var nextScope = scope.$$nextSibling;
                            scope.$destroy();
                            scope = nextScope;
                        }
                        $window.onbeforeunload = null;
                        var url = oauthService.getLogoutUrl();
                        if (url) {
                            oauthService.clearLogoutUrl();
                            $window.location.href = url;
                        } else {
                            $window.location.href = monsidoPath + 'logout?token=' + token + '&redirect_uri=' + getEncodedBaseUrl();
                        }
                    }, angular.noop);
                }, angular.noop);
            },
            setMe: function (user) {
                ng2SessionService.me = user;
                ng2SessionService.meObs.next(user);
                if (user !== null) {
                    user.settings = user.settings || {};
                    $rootScope.accessibilityMode = !!user.settings.accessibility;
                }
            },
            setup2FA: function () {
                $window.location.href = oauthService.generateOTPUrl(
                    monsidoPath,
                    'setup',
                    encodeURIComponent($window.location.pathname + $window.location.search),
                );
            },
        };

        ng2MonEventsService.addListener('force logout', () => {
            service.logout();
        });

        return service;

        function getEncodedBaseUrl () {
            var baseUrl = $window.location.protocol + '//' + $window.location.host + $window.location.pathname;
            return encodeURIComponent(baseUrl);
        }

        function disposeMe () {
            if (!ng2SessionService.meObs.isStopped) {
                ng2SessionService.meObs.unsubscribe();
            }
        }
    }
})();
