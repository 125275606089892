import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc service
     * @name PagePerformanceAssginProfileService
     * @description Get Page Performance assgin profile
     */
    angular.module('modules.page-performance').service('PagePerformanceAssginProfileService', PagePerformanceAssginProfileService);

    PagePerformanceAssginProfileService.$inject = [
        'PagePerformanceAssignedProfile',
        'PagePerformanceService',
        'ng2MonModalService',
        'gettextCatalog',
        '$q',
        'ng2MonEventsService',
        'PagePerformanceRescanRepo',
        'ng2ToastService',
        'PagePerformanceAssignedProfileObsService',
        'FORM_CONSTANT',
    ];

    /* @ngInject*/
    function PagePerformanceAssginProfileService (
        PagePerformanceAssignedProfile,
        PagePerformanceService,
        ng2MonModalService,
        gettextCatalog,
        $q,
        ng2MonEventsService,
        PagePerformanceRescanRepo,
        toastService,
        PagePerformanceAssignedProfileObsService,
        FORM_CONSTANT,
    ) {
        var rescanProfileStarted = false;
        return {
            assignProfile: assignProfile,
            rescanProfile: rescanProfile,
            isRescanProfileStarted: isRescanProfileStarted,
            getReachedMaximumPageMessageDialog: getReachedMaximumPageMessageDialog,
            requestMorePages: requestMorePages,
        };

        /**
         * @memberOf PagePerformanceAssginProfileService
         * @desc Opens the Performance Page Form
         * @param page - Page Object
         */
        function assignProfile (page) {
            var deferred = $q.defer();
            var assignedProfilesCount = PagePerformanceAssignedProfileObsService.assignedProfilesCount;
            if (PagePerformanceService.getMaxPageCount() <= assignedProfilesCount) {
                getReachedMaximumPageMessageDialog();
                deferred.resolve();
            } else {
                var params = {
                    body: FORM_CONSTANT.FORM_PAGE_PERFORMANCE_PAGE,
                    size: 'md',
                    data: {
                        page: page,
                    },
                };
                const callback = function (assignedProfile) {
                    if (assignedProfile) {
                        var chain = $q.when();
                        assignedProfile.profile_ids.forEach(function (profileId) {
                            chain = chain.then(
                                function () {
                                    rescanProfileStarted = true;
                                    return rescanProfile(page.id, profileId);
                                },
                                angular.noop,
                                chain.finally(function () {
                                    deferred.resolve();
                                }),
                            );
                        });

                        chain.finally(function () {
                            ng2MonEventsService.run('pagePerformanceRefreshProfiles');
                            rescanProfileStarted = false;
                        });
                    } else {
                        deferred.resolve();
                    }
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
            }

            return deferred.promise;
        }

        /**
         * @memberOf PagePerformanceAssginProfileService
         * @desc Start a page performance scan
         * @param pageId - ID of the Page
         * @param profileId - ID of the Profile
         */
        function rescanProfile (pageId, profileId) {
            var params = {
                page_id: pageId,
                profile_id: profileId,
            };
            var config = {
                headers: {
                    noGlobal: true,
                },
            };
            return PagePerformanceRescanRepo.create(params, {}, config);
        }

        /**
         * @memberOf PagePerformanceAssginProfileService
         * @desc Return a status of rescanProfileStarted
         */
        function isRescanProfileStarted () {
            return rescanProfileStarted;
        }

        /**
         * @memberOf PagePerformanceAssginProfileService
         * @desc Confirms whether user wants to request the Backend for more pages in the Perfornance Module
         */
        function getReachedMaximumPageMessageDialog () {
            var text = gettextCatalog.getString(
                'You have reached maximum number of Pages <br><br> To discuss further options and packages with our team please press ok, and we will have someone reach out to you.',
                {
                    newLine: '<br/>',
                },
            );

            ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-pages-confirm-shown' });
            ng2MonModalService.confirm(text).then(requestMorePages, function () {
                ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-pages-confirm-rejected' });
            });
        }

        /**
         * @memberOf PagePerformanceAssginProfileService
         * @desc Requests the Backend for more page in the Performance Module
         */
        function requestMorePages () {
            ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-pages-confirm-confirmed' });
            PagePerformanceService.requestMorePages(gettextCatalog.getString('Contact me about more Performance Pages')).then(function () {
                toastService.success(gettextCatalog.getString('Our team has been notified of your request. We will soon be in touch.'));
            }, angular.noop);
        }
    }
})();
