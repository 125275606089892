(function () {
    'use strict';

    angular.module('models').factory('HeatmapModel', HeatmapModel);

    HeatmapModel.$inject = [];
    function HeatmapModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
            if (this.page !== null) {
                this.page.domain_id = this.domain_id;
                this.page.type = 'html';
            }
        }

        return Model;

        // /////////
    }
})();
