(function () {
    'use strict';

    angular.module('pages.login').run(appRun);

    appRun.$inject = ['routerHelper', 'ng2MonEventsService', 'gettextCatalog','rx'];
    /* @ngInject */
    function appRun (routerHelper, ng2MonEventsService, gettextCatalog) {
        routerHelper.configureStates(getStates(ng2MonEventsService, gettextCatalog));
    }

    var auth = [
        '$location',
        'ng2OauthService',
        'ng2ErrorHandler',
        '$state',
        'ng2MonEventsService',
        'coreLoginService',
        'ng2MonUIRouterTransitionService',
        function (
            $location,
            oauthService,
            ng2ErrorHandler,
            $state,
            ng2MonEventsService,
            coreLoginService,
            ng2MonUIRouterTransitionService,
        ) {
            if ($location.search().hasOwnProperty('code')) {
                ng2MonEventsService.run('beforeLogin').then(function () {
                    return oauthService
                        .getAccessToken($location.search().code)
                        .subscribe(
                            () => {
                                coreLoginService.login.bind(coreLoginService);
                                $location.search('code', null);
                                if ($location.search().hasOwnProperty('logout_url')) {
                                    oauthService.setLogoutUrl($location.search().logout_url);
                                    $location.search('logout_url', null);
                                }
                                if (oauthService.getState()) {
                                    var state = oauthService.getState();
                                    var params = oauthService.getStateParams();
                                    oauthService.clearParams();
                                    return ng2MonUIRouterTransitionService
                                        .onTransitionFinishedAsync()
                                        .then(() => {
                                            return $state.go(state, params).then(() => {
                                                // TODO: add a handler for multiple overlays here
                                                const {
                                                    issueOverlay,
                                                    issueOverlayIssueId,
                                                    issueOverlayIssueType,
                                                    issueOverlaySourceCodeId,
                                                    issueOverlayContentId,
                                                    issueOverlayPageId,
                                                    issueOverlayCheckId,
                                                    issueOverlayPageLinkId,
                                                } = params;

                                                if (issueOverlay && issueOverlayIssueId && issueOverlayIssueType) {
                                                    $location.search(Object.assign($location.search(), {
                                                        issueOverlay,
                                                        issueOverlayIssueId,
                                                        issueOverlayIssueType,
                                                        issueOverlaySourceCodeId,
                                                        issueOverlayContentId,
                                                        issueOverlayPageId,
                                                        issueOverlayCheckId,
                                                        issueOverlayPageLinkId,
                                                    }));
                                                }
                                            });
                                        });
                                }
                                if ($location.search().customer_id) {
                                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('base.customer.dashboard', {
                                            customerId: $location.search().customer_id,
                                        }),
                                    );
                                } else {
                                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('base.dashboard'),
                                    );
                                }
                            },
                            (error) => {
                                ng2MonEventsService.run('force logout').finally(() => {
                                    ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('login.login'),
                                    );
                                });
                                ng2ErrorHandler.noopError(error);
                            },
                        );
                });
            }
        },
    ];

    function getStates (ng2MonEventsService, gettextCatalog) {
        return [
            {
                state: 'login',
                config: {
                    url: '',
                    abstract: true,
                    template: '<div class="container-fluid" ui-view></div>',
                    data: { anonymous_access: true, title: gettextCatalog.getString('Monsido') },
                },
            },
            {
                state: 'login.login',
                config: {
                    url: '/login',
                    template: '<login></login>',
                    controllerAs: 'vm',
                    data: { anonymous_access: true, title: gettextCatalog.getString('Monsido') },
                },
            },
            {
                state: 'login.reset',
                config: {
                    url: '/reset',
                    template: '<login-reset></login-reset>',
                    controllerAs: 'vm',
                    data: { anonymous_access: true, title: gettextCatalog.getString('Monsido') },
                },
            },
            {
                state: 'login.auth',
                config: {
                    url: '/auth',
                    data: { anonymous_access: true, title: gettextCatalog.getString('Monsido') },
                    resolve: {
                        auth: auth,
                    },
                    onExit: function () {
                        return ng2MonEventsService.run('exitLogin');
                    },
                    onEnter: function () {
                        return ng2MonEventsService.run('afterLogin').then(function () {
                            return ng2MonEventsService.run('finishLogin');
                        });
                    },
                },
            },
        ];
    }
})();
