(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSummaryVisitors', {
        templateUrl: 'app/pages/statistics/summary/visitors/visitors.html',
        controller: StatisticsSummaryVisitorsController,
        controllerAs: 'vm',
        bindings: {
            startDate: '<',
            endDate: '<',
        },
    });

    StatisticsSummaryVisitorsController.$inject = ['StatsRepo', '$filter', 'gettextCatalog', 'moment'];
    /* @ngInject */
    function StatisticsSummaryVisitorsController (StatsRepo, $filter, gettextCatalog, moment) {
        var vm = this;

        vm.$onChanges = update;
        vm.setChartData = setChartData;

        function update (changes) {
            // It is a hack to avoid dobbelt data in chart - I don't like it, but we need this for now.
            // We need to change the date to an observable
            if (changes === undefined) {
                return;
            }
            vm.summaryData = {
                visits: [],
                uniqueVisits: [],
                pageViews: [],
                avgVisitTime: [],
                bounceRate: [],
            };
            vm.type = 'visits';
            var params = {
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            setSummaryChartData(params);
            setupChart();
        }

        function setSummaryChartData (params) {
            vm.loading = true;
            StatsRepo.getSummaryPerDate(params)
                .then(function (data) {
                    if (Array.isArray(data)) {
                        for (var i = 0; i < data.length; i++) {
                            vm.lineChartLabels.push(moment(data[i].date)
                                .utc()
                                .format('ll'));
                            vm.summaryData.visits.push(data[i].visits);
                            vm.summaryData.uniqueVisits.push(data[i].visitors);
                            vm.summaryData.pageViews.push(data[i].pageviews);
                            vm.summaryData.avgVisitTime.push(data[i].avg_time);
                            vm.summaryData.bounceRate.push(data[i].bounce_rate.replace('%', ''));
                        }
                        vm.lineChartData = [vm.summaryData.visits];
                    } else if (data) {
                        vm.lineChartLabels.push(moment(data.date).format('ll'));
                        vm.summaryData.visits.push(data.visits);
                        vm.summaryData.uniqueVisits.push(data.visitors);
                        vm.summaryData.pageViews.push(data.pageviews);
                        vm.summaryData.avgVisitTime.push(data.avg_time);
                        vm.summaryData.bounceRate.push(data.bounce_rate.replace('%', ''));
                        vm.lineChartData = [vm.summaryData.visits];
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setupChart () {
            vm.lineChartSeries = [gettextCatalog.getString('Visits')];
            vm.lineChartData = [];
            vm.lineChartColors = ['#50a6fb'];
            vm.lineChartLabels = [];
            vm.lineChartOverride = [
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderColor: '#50a6fb',
                },
            ];

            vm.lineChartOptions = {
                tooltips: {
                    mode: 'label',
                    intersect: false,
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: function (tooltipItem, data) {
                            if (vm.type === 'avgVisitTime') {
                                return (
                                    getChartLabel() +
                                    $filter('numeraljs')(
                                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                        '00:00:00',
                                    )
                                );
                            } else if (vm.type === 'bounceRate') {
                                return (
                                    getChartLabel() +
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
                                    '%'
                                );
                            }
                            return (
                                getChartLabel() +
                                $filter('numeraljs', '0,0')(
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                )
                            );
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    if (vm.type === 'avgVisitTime') {
                                        return $filter('numeraljs')(value, '00:00:00');
                                    } else if (vm.type === 'bounceRate') {
                                        return parseInt(value) + '%';
                                    }
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: function (value) {
                                    if (value) {
                                        return moment(value, 'll').format('MMM DD');
                                    }
                                    return '';
                                },
                            },
                        },
                    ],
                },
            };
        }

        function setChartData (type) {
            vm.type = type;
            vm.lineChartData = [vm.summaryData[type]];
        }

        // PROTECTED
        function getChartLabel () {
            var txt = gettextCatalog.getString('Visits');
            switch (vm.type) {
                case 'visits':
                    txt = gettextCatalog.getString('Visits');
                    break;
                case 'uniqueVisits':
                    txt = gettextCatalog.getString('Total Visitors');
                    break;
                case 'pageViews':
                    txt = gettextCatalog.getString('Page Views');
                    break;
                case 'avgVisitTime':
                    txt = gettextCatalog.getString('Avg. Visit Time');
                    break;
                case 'bounceRate':
                    txt = gettextCatalog.getString('Bounce rates');
                    break;
            }

            return ' ' + txt + ': ';
        }
    }
})();
