(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditService
     * @description Confirms details of a Lighthouse Report Audit
     */
    angular.module('modules.page-performance').service('PagePerformanceAuditService', PagePerformanceAuditService);

    PagePerformanceAuditService.$inject = ['PAGE_PERFORMANCE_DISPLAY_MODE', 'PagePerformanceAuditScoreService'];

    /* @ngInject*/
    function PagePerformanceAuditService (PAGE_PERFORMANCE_DISPLAY_MODE, PagePerformanceAuditScoreService) {
        return {
            getColorByScore: getColorByScore,
            scoreDisplayModeIsNumeric: scoreDisplayModeIsNumeric,
            scoreDisplayModeIsBinary: scoreDisplayModeIsBinary,
            scoreDisplayModeIsManual: scoreDisplayModeIsManual,
            scoreDisplayModeIsInformative: scoreDisplayModeIsInformative,
            scoreDisplayModeIsNotApplicable: scoreDisplayModeIsNotApplicable,
        };

        /**
         * @memberOf PagePerformanceAuditService
         * @desc Determine if provided value is a Numeric display mode
         * @param {String} scoreDisplayMode - display mode value of audit
         * @return {Boolean}
         */
        function scoreDisplayModeIsNumeric (scoreDisplayMode) {
            return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.NUMERIC;
        }

        /**
         * @memberOf PagePerformanceAuditService
         * @desc Determine if provided value is a Binary display mode
         * @param {String} scoreDisplayMode - display mode value of audit
         * @return {Boolean}
         */
        function scoreDisplayModeIsBinary (scoreDisplayMode) {
            return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.BINARY;
        }

        /**
         * @memberOf PagePerformanceAuditService
         * @desc Determine if provided value is a Manual display mode
         * @param {String} scoreDisplayMode - display mode value of audit
         * @return {Boolean}
         */
        function scoreDisplayModeIsManual (scoreDisplayMode) {
            return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.MANUAL;
        }

        /**
         * @memberOf PagePerformanceAuditService
         * @desc Determine if provided value is a Informative display mode
         * @param {String} scoreDisplayMode - display mode value of audit
         * @return {Boolean}
         */
        function scoreDisplayModeIsInformative (scoreDisplayMode) {
            return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.INFORMATIVE;
        }

        /**
         * @memberOf PagePerformanceAuditService
         * @desc Determine if provided value is a Not Applicable display mode
         * @param {String} scoreDisplayMode - display mode value of audit
         * @return {Boolean}
         */
        function scoreDisplayModeIsNotApplicable (scoreDisplayMode) {
            return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.NOT_APPLICABLE;
        }

        /**
         * @memberOf PagePerformanceAuditService
         * @desc Get class color value that fits the score of the audit, empty will grey value
         * @param {Object} audit - Audit Object
         * @return {String}
         */
        function getColorByScore (audit) {
            if (audit.scoreDisplayMode !== PAGE_PERFORMANCE_DISPLAY_MODE.MANUAL && audit.score !== null) {
                var val = PagePerformanceAuditScoreService.getProperScoreNo(audit.score) * 100;
                if (PagePerformanceAuditScoreService.hasScoreFailed(val)) {
                    return 'text-danger';
                } else if (PagePerformanceAuditScoreService.hasScoreAverage(val)) {
                    return 'text-warning';
                } else if (PagePerformanceAuditScoreService.hasScorePassed(val)) {
                    return 'text-success';
                }
            }
            return '';
        }

        // PROTECTED
    }
})();
