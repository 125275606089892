import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.admin.customer_info').component('adminCustomerInfo', {
        templateUrl: 'app/pages/admin/customer-info/customer-info.html',
        controller: CustomerInfoController,
        controllerAs: 'vm',
    });

    CustomerInfoController.$inject = ['ng2SessionService', 'ng2MonEventsService', 'AccountRepo', 'FORM_CONSTANT'];

    /* @ngInject */
    function CustomerInfoController (ng2SessionService, ng2MonEventsService, AccountRepo, FORM_CONSTANT) {
        var vm = this;

        vm.$onInit = activate;

        vm.getPage = getPage;
        vm.editCustomer = editCustomer;

        function activate () {
            vm.collection = [];
            vm.customer = {};
            getPage();
        }

        function getPage () {
            if (ng2SessionService.customer === null) {
                AccountRepo.get().then(function (data) {
                    ng2SessionService.customer = data;
                    vm.customer = data;
                }, angular.noop);
            } else {
                vm.customer = ng2SessionService.customer;
            }
        }

        function editCustomer (customer) {
            var params = {
                body: FORM_CONSTANT.FORM_CUSTOMER_INFO,
                size: 'md',
                data: {
                    customer: angular.copy(customer),
                    useLegacy: true,
                },
            };
            const callback = function () {
                AccountRepo.get().then(function (data) {
                    ng2SessionService.customer = data;
                    vm.customer = data;
                }, angular.noop);
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }
    }
})();
