(function () {
    'use strict';

    angular.module('modules.export').factory('exporterSeoParser', exporterSeoParser);

    exporterSeoParser.$inject = ['historyHelper', '$filter', 'gettextCatalog', 'complianceService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function exporterSeoParser (historyHelper, $filter, gettextCatalog, complianceService, ng2ActiveFeatureService) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines (allowVerticalAverage) {
            var result = [];

            result.push(gettextCatalog.getString('Crawled at'));
            result.push(gettextCatalog.getString('Pages crawled'));
            result.push(gettextCatalog.getString('Domain compliance'));
            if (allowVerticalAverage) {
                result.push(gettextCatalog.getString('Comparison to average'));
            }
            result.push(gettextCatalog.getString('SEO opportunities - high priority'));
            result.push(gettextCatalog.getString('SEO opportunities - medium priority'));
            result.push(gettextCatalog.getString('SEO opportunities - low priority'));
            result.push(gettextCatalog.getString('SEO opportunities - technical SEO issues'));
            result.push(gettextCatalog.getString('Changes since previous crawl'));

            return result;
        }

        function parse (data) {
            var csv = [];
                var entry = [];
                var issuesStack = ['seo_errors_alerts_count', 'seo_errors_warnings_count', 'seo_errors_infos_count'];
            var allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
            csv.push(getHeadlines(allowVerticalAverage));

            for (var i = 0; i < data.length; i++) {
                entry = [];
                entry.push($filter('userDate')(data[i].post_processing_done_at || data[i].date, 'LL LT'));
                entry.push(data[i].page_count);
                if (data[i].compliancePercentage != null) {
                    entry.push($filter('monNumber')(data[i].compliancePercentage / 100, '0.[00]%'));
                } else {
                    entry.push(getCompliance(data[i]));
                }

                if (allowVerticalAverage) {
                    entry.push(data[i].comparisonToAverage);
                }

                if (data[i].opportunities) {
                    entry.push(data[i].opportunities.seo_errors_alerts_count);
                    entry.push(data[i].opportunities.seo_errors_warnings_count);
                    entry.push(data[i].opportunities.seo_errors_infos_count);
                    entry.push(data[i].opportunities.seo_errors_technical_count);
                } else {
                    // Old format
                    entry.push(data[i].seo_errors_alerts_count);
                    entry.push(data[i].seo_errors_warnings_count);
                    entry.push(data[i].seo_errors_infos_count);
                    entry.push(data[i].seo_errors_technical_count);
                }


                entry.push(
                    getCaret(data[i].id, data, issuesStack) +
                        ' ' +
                        $filter('monNumber')(historyHelper.getChangePercentageValue(data[i].id, data, issuesStack) / 100, '0.[00]%'),
                );
                csv.push(entry);
            }
            return csv;
        }

        function getCompliance (history) {
            if (!history.seoComplianceData) {
                history.seoComplianceData = complianceService.seoHistoryCompliance(history);
            }
            if (history.seoComplianceData && history.seoComplianceData[0]) {
                return $filter('monNumber')(history.seoComplianceData[0] / 100, '0.[00]%');
            }
            return '';
        }

        function getCaret (id, data, issueStack) {
            var value = historyHelper.getChangeValue(id, data, issueStack);
            if (value > 0) {
                return String.fromCharCode(94);
            } else if (value < 0) {
                return 'ˇ';
            }
            return '';
        }
    }
})();
