(function () {
    'use strict';

    angular.module('pages.search').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.customer.domain.search',
                config: {
                    url: '/search',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'search',
                    },
                },
            },
            {
                state: 'base.customer.domain.search.dashboard',
                config: {
                    url: '/',
                    template: '<policy-dashboard></policy-dashboard>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.search.content',
                config: {
                    url: '/content',
                    template: '<policies-content></policies-content>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.search.violations',
                config: {
                    url: '/violations',
                    template: '<policy-violations></policy-violations>',
                    data: {},
                },
            },
        ];
    }
})();
