(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsLanguage', {
        templateUrl: 'app/pages/statistics/visitors/language/language.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [
        'StatsRepo',
        'API_CONSTANTS',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$filter',
        'ng2SessionService',
        'dateRangeService',
    ];
    /* @ngInject */
    function StatisticsVisitorsController (
        StatsRepo,
        API_CONSTANTS,
        $stateParams,
        ParamService,
        gettextCatalog,
        $filter,
        ng2SessionService,
        dateRangeService,
    ) {
        var vm = this;

        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.totalVisits = 0;
        vm.pageSize = 100;
        vm.loading = false;
        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.chartType = 'chart';
            vm.date = dateRangeService.setRangeDates();

            updateDateRange();

            getPage();

            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.doughnutOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: function (element) {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: function (element) {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
        }

        function getPage () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format('YYYY-MM-DD'),
                to: vm.date.endDate?.format('YYYY-MM-DD'),
            };
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export visitors language'),
                    name: gettextCatalog.getString('Visitors language export'),
                    data: {
                        category: 'visitors_language',
                        category_ref: vm.date.startDate?.format('YYYY-MM-DD') + ',' + vm.date.endDate?.format('YYYY-MM-DD'),
                    },
                },
            ];
            var others = 0;
            vm.loading = true;
            vm.promise = StatsRepo.getLanguages(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.doughnutLabels = [];
                    vm.doughnutData = [];
                    for (var i = 0; i < vm.pages.length; i++) {
                        if (i < 5) {
                            vm.doughnutLabels.push(vm.pages[i].label);
                            vm.doughnutData.push(vm.pages[i].visits);
                        } else {
                            others += parseInt(vm.pages[i].visits);
                        }
                    }
                    if (vm.pages.length > 5) {
                        vm.doughnutLabels.push(gettextCatalog.getString('Others'));
                        vm.doughnutData.push(others);
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function pageChange (page) {
            vm.page = page;
            getPage();
        }

        function perPageChange (pageSize) {
            vm.pageSize = pageSize;
            getPage();
        }

        function updateDateRange () {
            if (vm.date.startDate || vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
                getPage();
            }
        }

        function average (array, prop) {
            var sum = 0;
            if (!angular.isArray(array) || array.length === 0) {
                return 0;
            }
            angular.forEach(array, function (item) {
                sum += parseInt(item[prop]);
            });
            return sum / array.length;
        }
    }
})();
