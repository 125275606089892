import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.data_privacy.dialogs').component('privacyViolationPages', {
        templateUrl: 'app/modules/data-privacy/dialogs/violation-pages/violation-pages.html',
        controller: DataPrivacyViolationPagesController,
        controllerAs: 'vm',
        bindings: {
            infoType: '<?',
            violation: '<?',
        },
    });

    DataPrivacyViolationPagesController.$inject = [
        '$controller',
        'PageRepo',
        'gettextCatalog',
        'ng2MonEventsService',
        'DataPrivacyHelpCenterRepo',
        '$q',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DataPrivacyViolationPagesController (
        $controller,
        PageRepo,
        gettextCatalog,
        ng2MonEventsService,
        DataPrivacyHelpCenterRepo,
        $q,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.pageTitle = pageTitle;
        vm.getSubHeader = getSubHeader;
        vm.openHelpCenter = openHelpCenter;
        vm.onPageDetailsClose = onPageDetailsClose;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.target = 'page-details-section-data-privacy';
            vm.currentSort = { by: 'title', direction: 'desc' };
            vm.totalPagesCount = 0;
            // Pages without errors for the active domain.
            vm.pagesDone = 0;
            vm.totalPageViolations = 0;
            vm.infoType = vm.infoType || vm.violation.info_type;
            setupTotalPageCount();
            getPage();
            getDescription();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.violation) {
                params.data_protection_violation_id = vm.violation.id;
            } else if (vm.infoType) {
                params.info_type_id = vm.infoType.id;
            }
            vm.progress = pageGetAll(params).then(function (pages) {
                vm.pages = pages;
            }, angular.noop);
        }

        function pageTitle (title) {
            return title || '(' + gettextCatalog.getString('No title found') + ')';
        }

        function getSubHeader () {
            return gettextCatalog.getString('{{pageCount}} pages found with this issue', {
                pageCount: vm.pages.total,
            });
        }

        function openHelpCenter (infoType) {
            var params = {
                size: 'lg',
                body: 'privacyDialogsHelpCenter',
                data: { infoType: infoType },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getDescription () {
            var params = {
                identifier: vm.infoType.identifier,
            };

            DataPrivacyHelpCenterRepo.get(params).then(function (res) {
                if (Array.isArray(res) && res.length > 0) {
                    // TODO: As per our discussion on Code Standards, this practice is prohibited
                    vm.infoType.description = res[0].description;
                }
            });
        }

        function onPageDetailsClose () {
            getPage();
        }

        // PROTECTED

        function setupTotalPageCount () {
            vm.loading = true;
            var infoTypeId;
            var violationId;
            if (vm.violation) {
                violationId = vm.violation.id;
            } else {
                infoTypeId = vm.infoType.id;
            }

            $q.all([getPages(), getPages(infoTypeId, violationId)])
                .then(function (values) {
                    vm.totalPagesCount = parseInt(values[0].total, 10);
                    vm.totalPageViolations = parseInt(values[1].total, 10);
                    vm.pagesDone = vm.totalPagesCount - vm.totalPageViolations;
                    calculatePercentage();
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        /**
         * @desc Use page endpoint to get the full page count of the active domain
         */
        function getPages (infoTypeId, violationId) {
            var params = {
                page_size: 1,
                page: 1,
                'types[]': ['html'],
                info_type_id: infoTypeId,
                data_protection_violation_id: violationId,
            };

            return pageGetAll(params);
        }

        function calculatePercentage () {
            vm.compliancePercentage = vm.pagesDone / vm.totalPagesCount || 0;
            if (vm.pagesDone > 0 && Math.floor(vm.compliancePercentage * 10000) === 0) {
                vm.compliancePercentage = 0.0001;
            } else if (vm.pagesDone < vm.totalPagesCount && Math.ceil(vm.compliancePercentage * 10000) === 10000) {
                vm.compliancePercentage = 0.9999;
            }
        }

        function pageGetAll (params) {
            return PageRepo.getAll(params);
        }
    }
})();
