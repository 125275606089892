(function () {
    'use strict';

    angular.module('app.components').component('pinDomain', {
        templateUrl: 'app/components/pin-domain/pin-domain.html',
        controller: PinDomainController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
            active: '<',
            cb: '&?',
            showDomainIcon: '<',
            goto: '&?',
        },
    });

    PinDomainController.$inject = ['meService', 'ng2SessionService'];
    /* @ngInject */
    function PinDomainController (meService, ng2SessionService) {
        var vm = this;
        vm.pinClick = pinClick;
        vm.stopPro = stopPro;
        vm.$onInit = activate;

        function activate () {
            vm.adaMode = ng2SessionService.adaMode();
        }

        function pinClick (ev) {
            if (ev !== undefined) {
                ev.stopPropagation();
            }

            meService.updateDomain(vm.ngModel.id, { favorite: !vm.ngModel.favorite }).then(function (data) {
                vm.ngModel.favorite = data.favorite;
                if (vm.cb) {
                    vm.cb();
                }
            }, angular.noop);
        }

        function stopPro (ev) {
            ev.stopPropagation();
        }
    }
})();
