(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuPageSelector', {
        templateUrl: 'app/layout/menu/side-menu-search/side-menu-search.html',
        controller: SideMenuSearchController,
        controllerAs: 'vm',
        bindings: {
            search: '<',
            updateSideMenu: '&',
            switchDomain: '&',
            show: '<',
        },
    });

    SideMenuSearchController.$inject = [
        'ng2SessionService',
        'hotkeys',
        'gettextCatalog',
        'ng2MonEventsService',
        '$location',
        '$anchorScroll',
        '$timeout',
        '$scope',
    ];

    function SideMenuSearchController (
        ng2SessionService,
        hotkeys,
        gettextCatalog,
        ng2MonEventsService,
        $location,
        $anchorScroll,
        $timeout,
        $scope,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.resetFocus = resetFocus;
        vm.monListener = null;

        function activate () {
            update();
            vm.monListener = ng2MonEventsService.addListener('setAgreementNavigationCompleted', update);
        }

        function update () {
            vm.workspaces = [];
            vm.projects = [];
            vm.domains = [];
            vm.collectionIndex = -1;
            setupHotkeys();

            ng2SessionService.domains.subscribe(function (domains) {
                if (domains.length > 0) {
                    vm.domains = domains && domains.flattenDomains && domains.flattenDomains() || [];
                }
            });
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.monListener);
        }

        function setupHotkeys () {
            hotkeys
                .bindTo($scope)
                .add({
                    combo: 'up',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Move focus up on domain picker'),
                    callback: function () {
                        if (vm.show && vm.collectionIndex > 0) {
                            vm.collectionIndex--;
                            scrollTo();
                        }
                    },
                })
                .add({
                    combo: 'down',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Move focus down on domain picker'),
                    callback: function () {
                        if (vm.show && vm.collectionIndex < vm.domains.length - 1) {
                            vm.collectionIndex++;
                            scrollTo();
                        }
                    },
                })
                .add({
                    combo: 'enter',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Select Domain on domain picker'),
                    callback: function () {
                        if (vm.show && vm.collectionIndex >= 0) {
                            angular
                                .element('#domain_' + vm.collectionIndex)
                                .find('div')[0]
                                .click();
                        }

                        // TODO: Remove this key-handler and to add it through ng-click: https://zube.io/monsido/monsido/c/28240
                    },
                });
        }

        function resetFocus () {
            vm.collectionIndex = -1;
        }

        // PROTECTED

        function scrollTo () {
            $location.hash('domain_' + vm.collectionIndex);
            $anchorScroll();
            $timeout(function () {
                angular.element('#project-search-input-field')[0].focus();
            });
        }
    }
})();
