(function () {
    'use strict';

    angular.module('services.api').factory('QualityRepo', QualityRepo);

    QualityRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function QualityRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            getSummary: getSummary,
        };

        return service;

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/quality/pages', params, config);
        }

        function getSummary (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/quality/summary', params, config);
        }
    }
})();
