(function () {
    'use strict';

    angular.module('services.api').factory('MeRepo', MeRepo);

    MeRepo.$inject = ['ng2ApiService', 'Me', 'Domain'];
    /* @ngInject */
    function MeRepo (ng2ApiService, Me, Domain) {
        return {
            update: update,
            get: get,
            createReport: createReport,
            updateReport: updateReport,
            deleteReport: deleteReport,
            updateDomain: updateDomain,
            getExports: getExports,
        };

        // /////////////

        function update (data, config) {
            config = config || {};
            return ng2ApiService.patch('me', data, null, config).then(toModel);
        }

        function get (config) {
            config = config || {};
            return ng2ApiService.get('me', {}, config).then(toModel);
        }

        function toModel (model) {
            return new Me(model);
        }

        function createReport (params, headers) {
            return ng2ApiService.post('me/reports', params, headers);
        }

        function updateReport (reportId, data, headers) {
            return ng2ApiService.patch('me/reports/' + reportId, data, null, headers);
        }

        function deleteReport (reportId, params, headers) {
            return ng2ApiService.delete(`me/reports/${reportId}`, params, headers);
        }

        function updateDomain (domainId, data, headers) {
            return ng2ApiService.patch(`/me/domains/${domainId}`, data, null, headers).then(toDomainModel);
        }

        function getExports (params, headers) {
            return ng2ApiService.get('me/exports', params, headers);
        }

        // PROTECTED

        function toDomainModel (model) {
            return new Domain(model);
        }
    }
})();
