(function () {
    'use strict';

    angular.module('services.api').factory('ReportRepo', ReportRepo);

    ReportRepo.$inject = ['ng2ApiService', 'ReportModel'];
    /* @ngInject */
    function ReportRepo (ng2ApiService, ReportModel) {
        var service = {
            get: get,
            update: update,
            create: create,
            remove: remove,
        };

        return service;

        // /////////////

        function get (params, headers) {
            params = params || {};
            return ng2ApiService.get('reports/', params, headers).then(toModels);
        }

        function update (id, params, headers) {
            return ng2ApiService.patch('reports/' + id, params, null, headers).then(toModel);
        }

        function create (params, headers) {
            return ng2ApiService.post('reports/', params, headers).then(toModel);
        }

        function remove (id, params, headers) {
            return ng2ApiService.delete('reports/' + id, params, headers);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new ReportModel(model);
        }
    }
})();
