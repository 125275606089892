(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc service
     * @name PagePerformanceService
     * @description Service that contains logical operations used throughout the entire Performance Module
     */
    angular.module('modules.page-performance').service('PagePerformanceService', PagePerformanceService);

    PagePerformanceService.$inject = ['supportRequestService', 'ng2SessionService'];

    /* @ngInject*/
    function PagePerformanceService (supportRequestService, ng2SessionService) {
        return {
            requestMorePages: requestMorePages,
            requestMoreProfiles: requestMoreProfiles,
            getMaxPageCount: getMaxPageCount,
            getMaxProfileCount: getMaxProfileCount,
        };

        /**
         * @memberOf PagePerformanceService
         * @desc Request Support for more Pages to the Customer Account
         * @param msg - Message to inform the Support team about
         */
        function requestMorePages (msg) {
            return requestPerformanceSupport(msg);
        }

        /**
         * @memberOf PagePerformanceService
         * @desc Request Support for more Profiles to the Customer Account
         * @param msg - Message to inform the Support team about
         */
        function requestMoreProfiles (msg) {
            return requestPerformanceSupport(msg);
        }

        /**
         * @memberOf PagePerformanceService
         * @desc Get the number of maximum allowed pages for this account
         */
        function getMaxPageCount () {
            if (ng2SessionService.customer !== null) {
                return ng2SessionService.customer.plan_traits.performance_tracking_max_pages;
            } else {
                return 0;
            }
        }

        /**
         * @memberOf PagePerformanceService
         * @desc Get the number of maximum allowed profiles for this account
         */
        function getMaxProfileCount () {
            if (ng2SessionService.customer !== null && !isNaN(ng2SessionService.customer.plan_traits.performance_tracking_max_profiles)) {
                return ng2SessionService.customer.plan_traits.performance_tracking_max_profiles;
            } else {
                return 0;
            }
        }

        // PROTECTED

        /**
         * @memberOf PagePerformanceService
         * @desc Send a post request to the support request endpoint
         * @param msg - Message to inform the Support team about
         */
        function requestPerformanceSupport (msg) {
            var params = {
                code: 'performance',
                message: msg,
            };
            return supportRequestService.create(params);
        }
    }
})();
