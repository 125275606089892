(function () {
    'use strict';

    angular.module('models').factory('PolicyModel', PolicyModel);

    PolicyModel.$inject = ['ng2SessionService', 'Lodash', 'POLICY_TYPES'];

    function PolicyModel (ng2SessionService, Lodash, POLICY_TYPES) {
        var vm = this;
        function Model (attributes) {
            vm.targetType = POLICY_TYPES.domain;
            if (ng2SessionService.domainGroup !== null) {
                vm.targetType = POLICY_TYPES.domain_group;
            }
            angular.extend(this, attributes);
            setPagesAndMatchesCount(this);
        }

        Model.prototype.clone = clone;

        return Model;

        // /////////

        function clone () {
            var clone = angular.copy(this);
            delete clone.updated_at;
            delete clone.created_at;
            delete clone.id;
            delete clone.name;
            return clone;
        }

        // PROTECTED

        function setPagesAndMatchesCount (policy) {
            if (policy.state === 'running') {
                setupRunningPolicyData(policy);
            }
        }

        function setupRunningPolicyData (policy) {
            policy.pages_count = 0;
            policy.matches_count = 0;
            policy.links_count = 0;
            policy.targets = policy.targets.map(function (domain) {
                domain.matches_count = 0;
                domain.pages_count = 0;
                domain.links_count = 0;
                return domain;
            });
        }
    }
})();
