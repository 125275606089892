(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name seoService
     * @description Service to handle seo
     */
    angular.module('services.entities').factory('seoService', seoService);

    seoService.$inject = ['SeoRepo'];
    /* @ngInject*/
    function seoService (SeoRepo) {
        return {
            update: update,
        };

        function update (issue_id, data, params, headers) {
            return SeoRepo.update(issue_id, data, params, headers);
        }
    }
})();
