(function () {
    'use strict';

    angular.module('filters.monsido').filter('translateSeoIssue', TranslateSeoIssueController);

    TranslateSeoIssueController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function TranslateSeoIssueController (gettextCatalog) {
        var dictionary = {
            seo_missing_title: 'Missing title',
            seo_duplicate_title: 'Title found on more than one page',
            seo_too_short_copy: 'Too short content on page',
            seo_too_long_url: 'Too long URL',
            seo_missing_h1: 'Missing H1',
            seo_multiple_h1: 'Multiple H1 on page',
            seo_duplicate_h1: 'H1 found on more than one page',
            seo_missing_meta_keyword: 'Missing META keyword',
            seo_missing_meta_description: 'Missing META description',
            seo_too_short_meta_description: 'Too short META description',
            seo_too_long_meta_description: 'Too long META description',
            seo_too_many_page_links: 'Too many internal links',
            seo_missing_subheadlines: 'Missing sub headings',
            seo_missing_alt: 'Images missing ALT',
            seo_no_index: 'Pages with - No index',
            seo_no_follow: 'Links with - No follow',
        };

        return result;

        // //////////

        function result (word) {
            // This is only a temporary solution until POEditor translates the words correctly
            return gettextCatalog.getString(dictionary[word]); // How should we handle none existing words?
        }
    }
})();
