import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActiveDialog, PromptOptions } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { DialogOptionsAdapter } from '@monsido/core/modules/dialog-container/dialog-container.component';
import { SessionService } from '@monsido/core/session/session.service';
import { DomainGroupMembersEntity, DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { User } from '@monsido/modules/models/api/user';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { UserRepo } from 'ng2/angularjs-providers/user-repo/user-repo.types';
import { ScheduleReportAdminNonUsersComponent, TempUser } from '../schedule-report-admin-non-users/schedule-report-admin-non-users.component';

@Component({
    selector: 'mon-schedule-report-admin-users',
    templateUrl: './schedule-report-admin-users.component.html',
    styleUrls: ['./schedule-report-admin-users.component.scss'],
})
export class ScheduleReportAdminUsersComponent implements OnInit {
    @Input() users: User[] = [];
    @Input() domainId: number | undefined = undefined;
    @Input() domainType: string | undefined = undefined;

    progress?: Promise<void>;
    currentUser?: User;
    userList: User[] = [];
    selectedUsers: Record<number, boolean> = {};
    selectedTempUsers: TempUser[] = [];
    loading = false;
    promptOptions: PromptOptions = {
        parentSelector: '#common-dialog-wrapper',
    };

    constructor (
        @Inject('UserRepo') private userRepo: UserRepo,
        private sessionService: SessionService,
        private eventsService: MonEventService,
        private activeDialog: ActiveDialog,
    ) {}

    ngOnInit (): void {
        this.currentUser = this.sessionService.me;
        this.selectedTempUsers = this.users?.filter(user => user.user === null || user.user === undefined) || [];
        this.getUsers();
    }

    getUsers (): void {
        this.loading = true;
        const userIds = this.users
            ?.map(user => user?.user?.id)
            .filter(Boolean) || [];

        this.progress = this.userRepo.getAll({ page_size: 0 }).then(users => {
            this.userList = users.filter(user => {
                const domain = this.getRelatedDomainFromUser(user);
                return user.customer_admin || (domain && domain.visible) || userIds.includes(user.id);
            });

            this.users.forEach(user => {
                if (user.user?.id) {
                    this.selectedUsers[user.user.id] = true;
                }
            });
        })
            .finally(() => this.loading = false);
    }
    getRelatedDomainFromUser (user: User): DomainGroupMembersEntity | DomainUsersEntity | undefined {
        const { domain_users, domain_group_members } = user;

        if (this.domainType === 'Domain' && domain_users) {
            return this.searchById(domain_users, 'domain_id');
        }

        if (domain_group_members) {
            return this.searchById(domain_group_members, 'domain_group_id');
        }
    }

    searchById (
        domains: Array<DomainGroupMembersEntity | DomainUsersEntity>,
        id: string,
    ): DomainUsersEntity | DomainGroupMembersEntity | undefined {
        return domains.find(domain => domain[id] === this.domainId);
    }

    removeTempUser (index: number): void {
        this.selectedTempUsers.splice(index, 1);
    }

    openNonUserDialog (): void {
        const params: DialogOptionsAdapter<ScheduleReportAdminNonUsersComponent> = {
            component: ScheduleReportAdminNonUsersComponent,
            dialogOptions: {
                size: 'md',
                cb: (result: Record<string, TempUser>): void => {
                    if (result?.users) {
                        this.selectedTempUsers = this.selectedTempUsers.concat(result.users);
                    }
                },
            },
        };

        this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

    close (): void {
        const users: Array<User | TempUser> = [];

        for (const userId in this.selectedUsers) {
            if (this.selectedUsers[userId]) {
                const foundUser = this.userList.find(user => user?.id === Number(userId)) || null;
                if (foundUser) {
                    users.push(
                        new User({
                            email: foundUser.email,
                            first_name: foundUser.first_name,
                            last_name: foundUser.last_name,
                            user: foundUser as Partial<User>,
                        } as User),
                    );
                }
            }
        }

        this.selectedTempUsers.forEach(user => users.push(new User(user as User)));

        this.activeDialog.close({ users: users });
    }
}
