(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PageClarityService
     * @description Service to handle Page Clarity Accessibility requests
     */
    angular.module('services.entities').factory('PageClarityService', PageClarityService);

    PageClarityService.$inject = ['PageClarityRepo', 'ng2DemoHasAccessService', 'DemoPageClarityRepo'];
    /* @ngInject*/
    function PageClarityService (PageClarityRepo, ng2DemoHasAccessService, DemoPageClarityRepo) {
        return {
            getAllChecks: getAllChecks,
            getCheckErrors: getCheckErrors,
            updateCheckError: updateCheckError,
        };

        /**
         * @memberOf PageClarityService
         * @desc Get Checks collection for a single PDF document
         * @param {object} documentId - The document id
         * @param {object} params - The parameters to inject to the get request
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing checks collection
         */
        function getAllChecks (documentId, params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return DemoPageClarityRepo.getAllChecks(documentId, params);
            }

            return PageClarityRepo.getAllChecks(documentId, params, config);
        }

        /**
         * @memberOf PageClarityService
         * @desc Get Errors collection of a single Accessibility Check
         * @param {object} documentId - The document id
         * @param {object} checkId - The Accessibility Check ID
         * @param {object} params - The parameters to inject to the get request
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the error collection
         */
        function getCheckErrors (documentId, checkId, params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return DemoPageClarityRepo.getCheckErrors(documentId, checkId, params, config);
            }

            return PageClarityRepo.getCheckErrors(documentId, checkId, params, params, config);
        }

        /**
         * @deprecated - Currently not being used anywhere
         * @memberOf PageClarityService
         * @desc Update the error of a Accessibility Check
         * @param {object} pageId - The page id
         * @param {object} checkId - The Accessibility check id
         * @param {object} errorId - The ID of the error in the database
         * @param {object} data - The data object to add to the request
         * @param {object} params - The query parameters
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the updated error
         */
        function updateCheckError (pageId, checkId, errorId, data, params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return DemoPageClarityRepo.updateCheckError(params, config);
            }

            return PageClarityRepo.updateCheckError(pageId, checkId, errorId, data, params, config);
        }
    }
})();
