(function () {
    'use strict';
    angular.module('core.services.redirect-whitelist').service('redirectWhitelistHelper', redirectWhitelistHelper);

    redirectWhitelistHelper.$inject = ['PATH_WHITELIST', 'Lodash'];

    /* @ngInject*/
    function redirectWhitelistHelper (PATH_WHITELIST, Lodash) {
        return {
            isPathWhitelisted: isPathWhitelisted,
        };

        function isPathWhitelisted (path) {
            return PATH_WHITELIST.indexOf(getNameString(path)) !== -1;
        }

        // #####PROTECTED#######
        function getNameString (paths) {
            var isWhitelisted = Lodash.find(paths, function (path) {
                return Lodash.some(PATH_WHITELIST, function (whitelist) {
                    return path.state.name === whitelist;
                });
            });
            if (isWhitelisted) {
                return isWhitelisted.state.name;
            }

            return '';
        }
    }
})();
