(function () {
    'use strict';

    angular.module('modules.cookie_banner.pages').component('pageDetailsCookieAcceptanceRate', {
        templateUrl: 'app/modules/cookie-banner/pagedetails/cookie-acceptance-rate/cookie-acceptance-rate.html',
        controller: CookieAcceptanceRateOverviewController,
        controllerAs: 'vm',
    });

    CookieAcceptanceRateOverviewController.$inject = [
        'CookieBannerEntitiesStatisticsService',
        'moment',
        'COOKIE_CATEGORIES',
        'gettextCatalog',
        '$filter',
        'Lodash',
        'ParamService',
        'ng2DemoHasAccessService',
        'dateRangeService',
        'CookieCategoriesLocalizeService',
    ];

    /* @ngInject */
    function CookieAcceptanceRateOverviewController (
        CookieBannerEntitiesStatisticsService,
        moment,
        COOKIE_CATEGORIES,
        gettextCatalog,
        $filter,
        Lodash,
        ParamService,
        ng2DemoHasAccessService,
        dateRangeService,
        CookieCategoriesLocalizeService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;
        vm.loading = true;
        vm.subheaderTranslation = gettextCatalog.getString('An overview of your customer\'s behavior with cookies on your website');
        vm.regulationsTitle = '';
        vm.acceptanceRateString = gettextCatalog.getString('Acceptance Rate');
        vm.lineChartSeries = [vm.acceptanceRateString];

        function activate () {
            setupLineChart();
            vm.date = dateRangeService.setRangeDates();
            updateDateRange();
            getStatisticsData();
        }

        function updateDateRange () {
            var dateParams = ParamService.returnPrevParam();
            var prevStartDate = moment(dateParams.from);
            var currenStartDate = moment(vm.date.startDate);
            var prevEndDate = moment(dateParams.to);
            var currenEndDate = moment(vm.date.endDate);
            if (!prevStartDate.isSame(currenStartDate) || !prevEndDate.isSame(currenEndDate)) {
                vm.loading = true;
                getStatisticsData();
            }
            ParamService.setParams({
                from: currenStartDate,
                to: currenEndDate,
            });
        }

        function getStatisticsData () {
            var request = {
                from: vm.date.startDate,
                to: vm.date.endDate,
            };
            vm.isDemo = ng2DemoHasAccessService.isDemoConsentManager();
            if (vm.isDemo) {
                updateStatisticsData(generateData());
                vm.loading = false;
            } else {
                CookieBannerEntitiesStatisticsService.getAll(request)
                    .then(function (statistics) {
                        updateStatisticsData(statistics);
                    }, angular.noop)
                    .finally(function () {
                        vm.loading = false;
                    });
            }
        }

        function updateStatisticsData (statistics) {
            vm.totalCount = statistics.total_count || 0;
            var dailyTotalCountsArr = statistics.daily_total_counts || [];
            var categoriesMap = getCategoriesMap(statistics.daily_consent_counts);
            var dailyEssentialCountMap = getDailyEssentialCountsMap(statistics.daily_consent_counts);
            vm.categoriesArr = getSortedCategoriesArr(categoriesMap);
            setupLineChartData(dailyEssentialCountMap, dailyTotalCountsArr);
            setupDoughnutChart(vm.categoriesArr);
        }

        function getSortedCategoriesArr (categoryMap) {
            var categoriesArr = [];
            if (categoryMap.size) {
                categoryMap.forEach(function (categoryObj) {
                    if (categoryObj) {
                        categoriesArr.push({
                            name: categoryObj.category,
                            percentage: vm.totalCount === 0 ? 0 : (categoryObj.count / vm.totalCount) * 100,
                            order: categoryObj.order,
                        });
                    }
                });
            }

            return categoriesArr.sort(function (a, b) {
                var aOrder = a.order || 100;
                var bOrder = b.order || 100;

                if (aOrder < bOrder) {
                    return -1;
                }

                if (aOrder > bOrder) {
                    return 1;
                }

                return 0;
            });
        }

        function setupLineChartData (dailyEssentialCountMap, dailyTotalCountsArr) {
            setupLineChart();
            if (dailyEssentialCountMap.size && dailyTotalCountsArr.length) {
                dailyEssentialCountMap.forEach(function (el) {
                    var dailyTotalCount = Lodash.find(dailyTotalCountsArr, function (day) {
                        return day.day === el.day;
                    });

                    var simpleThreeValue = dailyTotalCount && dailyTotalCount.count !== 0 ? (el.count * 100) / dailyTotalCount.count : 0;
                    vm.lineChartData[0].push(simpleThreeValue.toFixed(2));
                    vm.lineChartLabels.push(moment(el.day).format('MMM DD'));
                });
            }
        }

        function setupLineChart () {
            vm.lineChartData = [[]];
            vm.lineChartLabels = [];
            vm.lineChartColors = ['#34C6D0'];
            vm.lineChartOptions = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            grid: {
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: vm.acceptanceRateString,
                                fontStyle: 400,
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return parseInt(value) + '%';
                                },
                                stepSize: 25,
                                min: 0,
                                max: 100,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 11,
                        boxWidth: 8,
                        boxHeight: 8,
                    },
                },
            };

            vm.lineChartOverride = [
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 3,
                    pointHitRadius: 10,
                },
            ];
        }

        function setupDoughnutChart (categoriesArr) {
            vm.doughnutLabels = ['', ''];
            vm.doughnutColors = ['#34C6D0', '#dcdcdc'];
            vm.doughnutData = [];
            if (categoriesArr.length) {
                // summing percentages from all categories
                var acceptanceRateSum = categoriesArr.reduce(function (acc, el) {
                    acc += el.percentage;
                    return acc;
                }, 0);

                // getting the overallRate by diving sum over number of categories
                var overallRate = acceptanceRateSum / categoriesArr.length;
                vm.doughnutData = [overallRate, 100 - overallRate];

                vm.doughnutOptions = {
                    centerText: {
                        percentageLabel: $filter('monNumber')(overallRate / 100, '0.[00] %'),
                        totalLabel: gettextCatalog.getString('Overall acceptance rate'),
                        totalFontSize: 150,
                        subFontSize: 150,
                    },
                    cutoutPercentage: 80,
                    tooltips: {
                        enabled: false,
                    },
                };
            }
        }

        function getCategoriesMap (dailyConsentCounts) {
            var categoriesOrderMap = getCategoriesOrderMap();
            if (dailyConsentCounts && dailyConsentCounts.length) {
                return dailyConsentCounts.reduce(function (acc, el) {
                    // only handle cases where consent is !== null (i.e 'essential')
                    if (el.consent !== null) {
                        if (!acc.has(el.consent)) {
                            acc.set(el.consent, {
                                count: el.count,
                                category: el.consent,
                                order: categoriesOrderMap.get(el.consent),
                            });
                        } else {
                            var count = acc.get(el.consent).count;
                            acc.set(el.consent, {
                                count: count + el.count,
                                category: el.consent,
                                order: categoriesOrderMap.get(el.consent),
                            });
                        }
                    }
                    return acc;
                }, new Map());
            }
            return new Map();
        }

        // getting daily essential counts for calculating acceptance rate over time for essential cookies
        function getDailyEssentialCountsMap (dailyConsentCounts) {
            if (dailyConsentCounts && dailyConsentCounts.length) {
                return dailyConsentCounts.reduce(function (acc, el) {
                    if (el.consent === COOKIE_CATEGORIES.essential) {
                        acc.set(el.day, { count: el.count, day: el.day });
                    }
                    return acc;
                }, new Map());
            }

            return new Map();
        }

        function getCategoriesOrderMap () {
            var map = new Map();
            map.set(COOKIE_CATEGORIES.essential, {
                order: 1,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.essential],
            });
            map.set(COOKIE_CATEGORIES.marketing, {
                order: 2,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.marketing],
            });
            map.set(COOKIE_CATEGORIES.analytics, {
                order: 3,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.analytics],
            });
            map.set(COOKIE_CATEGORIES.personalisation, {
                order: 4,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.personalisation],
            });

            return map;
        }

        function generateData () {
            return {
                daily_total_counts: [
                    { day: '2021-07-26', count: 210 },
                    { day: '2021-07-27', count: 75 },
                ],
                daily_consent_counts: [
                    { day: '2021-07-26', consent: null, count: 163 },
                    { day: '2021-07-26', consent: 'analytics', count: 18 },
                    { day: '2021-07-26', consent: 'essential', count: 47 },
                    { day: '2021-07-26', consent: 'marketing', count: 19 },
                    { day: '2021-07-26', consent: 'personalisation', count: 19 },
                    { day: '2021-07-27', consent: null, count: 20 },
                    { day: '2021-07-27', consent: 'basic', count: 1 },
                    { day: '2021-07-27', consent: 'analytics', count: 3 },
                    { day: '2021-07-27', consent: 'essential', count: 55 },
                    { day: '2021-07-27', consent: 'marketing', count: 3 },
                    { day: '2021-07-27', consent: 'personalisation', count: 3 },
                ],
                total_count: 285,
            };
        }
    }
})();
