(function () {
    'use strict';

    angular.module('modules.page-performance.pages.domain').component('pagePerformancePages', {
        templateUrl: 'app/modules/page-performance/pages/domain/pages/pages.html',
        controller: PagePerformancePagesController,
        controllerAs: 'vm',
    });

    PagePerformancePagesController.$inject = [
        '$controller',
        'ng2MonEventsService',
        'Lodash',
        'PagePerformanceAssignedProfile',
        'PagePerformanceRescanRepo',
        'PagePerformanceScanService',
        'rx',
        'ng2MonModalService',
        'gettextCatalog',
        'PagePerformanceResults',
        '$q',
        'PerformancePagesService',
        '$stateParams',
        'pageService',
        'ng2SessionService',
        'PagePerformanceAssginProfileService',
        'PagePerformanceAssignedProfileObsService',
        'FORM_CONSTANT',
        'MON_EVENTS',
        'ng2ActiveFeatureService',
        'PagePerformanceVersionService',
    ];

    /* @ngInject */
    function PagePerformancePagesController (
        $controller,
        ng2MonEventsService,
        Lodash,
        PagePerformanceAssignedProfile,
        PagePerformanceRescanRepo,
        PagePerformanceScanService,
        rx,
        ng2MonModalService,
        gettextCatalog,
        PagePerformanceResults,
        $q,
        PerformancePagesService,
        $stateParams,
        pageService,
        ng2SessionService,
        PagePerformanceAssginProfileService,
        PagePerformanceAssignedProfileObsService,
        FORM_CONSTANT,
        MON_EVENTS,
        ng2ActiveFeatureService,
        PagePerformanceVersionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPage = getPage;
        vm.openPageForm = openPageForm;
        vm.performanceRescanPage = performanceRescanPage;
        vm.deleteAssignedProfile = deleteAssignedProfile;
        vm.setUncollapsedAssignedProfile = setUncollapsedAssignedProfile;
        vm.assignedProfileIsScanning = assignedProfileIsScanning;
        vm.hasProfile = hasProfile;
        vm.getProfileDeviceName = PerformancePagesService.getProfileDeviceName;
        vm.getProfileLocation = PerformancePagesService.getProfileLocation;
        vm.getProfileCountryCode = PerformancePagesService.getProfileCountryCode;
        vm.getProfileConnectionName = PerformancePagesService.getProfileConnectionName;
        vm.getAssignedProfiles = PerformancePagesService.getAssignedProfiles;
        vm.canRescan = PerformancePagesService.canRescan;
        vm.hasDefaultProfile = hasDefaultProfile;
        vm.dropdownActionOptions = [];

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = '';
            vm.scanStatus = {};
            vm.uncollapedAssignedProfile = null;
            vm.scanResult = null;
            vm.loadingScanResult = false;
            vm.progress = null;
            vm.metrics = [];
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            var chain = $q.when();
            chain = chain.then(getAssignedProfiles());
            vm.promise = chain.finally(function () {
                if ($stateParams.showPage) {
                    var targetPageId = $stateParams.showPage;
                    getAssignedProfilesByPage(targetPageId).then(function (res) {
                        if (res && res.length > 0) {
                            getPageDetails(targetPageId).then(goToPageDetails);
                        } else {
                            ng2MonModalService
                                .alert(gettextCatalog.getString('The Requested Page is not a part of the Performance Module'))
                                .then(angular.noop, angular.noop);
                        }
                    }, angular.noop);
                }
            });
        }

        function onDestroy () {
            Lodash.forEach(vm.scanStatus, function (profileObject, assignedProfileKey) {
                Lodash.forEach(profileObject, function (scanStatus, profileKey) {
                    if (scanStatus !== null && !scanStatus.isDisposed) {
                        vm.scanStatus[assignedProfileKey][profileKey].dispose();
                        vm.scanStatus[assignedProfileKey][profileKey] = null;
                    }
                });
            });
        }

        function getPage () {
            vm.progress = getAssignedProfiles();
        }

        function openPageForm (assignedProfile) {
            var assignedProfileCount = PagePerformanceAssignedProfileObsService.assignedProfilesCount;
            if (!assignedProfile && PerformancePagesService.getMaxPageCount() <= assignedProfileCount) {
                PagePerformanceAssginProfileService.getReachedMaximumPageMessageDialog();
            } else {
                var params = {
                    body: FORM_CONSTANT.FORM_PAGE_PERFORMANCE_PAGE,
                    size: 'md',
                    data: {
                        assignedProfile: assignedProfile,
                    },
                };
                const callback = function (updatedAssignedProfile) {
                    if (typeof updatedAssignedProfile !== 'undefined') {
                        var defer = $q.defer();
                        if (vm.canRescan()) {
                            if (!assignedProfile) {
                                rescanAllProfilesInAssignedProfile(updatedAssignedProfile);
                            }
                            defer.resolve();
                        } else {
                            defer.resolve(
                                ng2MonModalService
                                    .alert(gettextCatalog.getString('The Scan will take effect within the next few hours'))
                                    .then(angular.noop, angular.noop),
                            );
                        }
                        defer.promise.then(getPage);
                    }
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
            }
        }

        function performanceRescanPage (assignedProfile) {
            var profile = Lodash.find(PerformancePagesService.getProfiles(), {
                id: getDefaultProfileIdFromAssignedProfile(assignedProfile),
            });

            PagePerformanceScanService.performanceRescan(assignedProfile.page, profile).then(function () {
                observePagePerformanceScanStatus(assignedProfile, profile);
            }, angular.noop);
        }

        function deleteAssignedProfile (assignedProfile) {
            var scope = {
                title: assignedProfile.page.title,
                titleTag: '<span class=\'text-link\'>',
                titleTagEnd: '</span>',
                deleteTag: '<code>',
                deleteTagEnd: '</code>',
                newline: '<br/>',
            };
            var msg = gettextCatalog.getString(
                'You are about to remove {{titleTag}}{{title}}{{titleTagEnd}} <br> Please Type {{deleteTag}}Delete{{deleteTagEnd}} to confirm this action',
                scope,
            );
            return ng2MonModalService.prompt(msg).then(function (input) {
                if ('delete' !== input.toLowerCase()) {
                    return;
                }
                var params = {
                    profile_id: assignedProfile.profile_id,
                    page_id: assignedProfile.page.id,
                };

                PagePerformanceAssignedProfile.destroy(assignedProfile, params).then(function () {
                    getPage();
                }, angular.noop);
            }, angular.noop);
        }

        function setUncollapsedAssignedProfile (assignedProfile) {
            vm.uncollapedAssignedProfile = assignedProfile;
            if (assignedProfile === null) {
                return;
            }
            vm.loadingScanResult = true;
            getScanResult(assignedProfile)
                .then(function (scanResults) {
                    vm.scanResult = scanResults[0];
                    vm.metrics = PagePerformanceVersionService.getVersionInfo(vm.scanResult.version).metrics.map(metric => {
                        return {
                            title: metric.label,
                            data: vm.scanResult[metric.id],
                            unit: metric.unit,
                            rulerClass: metric.rulerClass,
                        };
                    });
                }, angular.noop)
                .finally(function () {
                    vm.loadingScanResult = false;
                });
        }

        function assignedProfileIsScanning (assignedProfile) {
            return Boolean(
                vm.scanStatus[assignedProfile.id] &&
                Lodash.some(vm.scanStatus[assignedProfile.id], function (observable) {
                    return observable !== null;
                }),
            );
        }

        function hasProfile (profile) {
            var profileDict = PerformancePagesService.getProfiles();
            return (
                profile.profile_ids &&
                profile.profile_ids.length &&
                profile.profile_ids.some(function (profileId) {
                    return profileDict[profileId];
                })
            );
        }

        function hasDefaultProfile (assignedProfile) {
            var defaultId = getDefaultProfileIdFromAssignedProfile(assignedProfile);
            return Boolean(PerformancePagesService.getProfiles()[defaultId]);
        }

        // PROTECTED

        function observePagePerformanceScanStatus (assignedProfile, profile) {
            if (!assignedProfile.profile_ids.length === 0) {
                return;
            }

            if (!vm.scanStatus[assignedProfile.id]) {
                vm.scanStatus[assignedProfile.id] = {};
            }

            vm.scanStatus[assignedProfile.id][profile.id] = rx.Observable.interval(40000).subscribe(function () {
                var params = {
                    page_id: assignedProfile.page.id,
                    profile_id: profile.id,
                };
                var config = {
                    headers: {
                        noGlobal: true,
                    },
                };
                PagePerformanceRescanRepo.get(params, config).then(function (data) {
                    if (data[0]) {
                        if (data[0].status === 'finished') {
                            if (
                                vm.scanStatus[assignedProfile.id][profile.id] !== null &&
                                !vm.scanStatus[assignedProfile.id][profile.id].isDisposed
                            ) {
                                vm.scanStatus[assignedProfile.id][profile.id].dispose();
                                vm.scanStatus[assignedProfile.id][profile.id] = null;
                            }

                            if (getDefaultProfileIdFromAssignedProfile(assignedProfile) === profile.id) {
                                getAssignedProfiles();
                            }
                        }
                    }
                    return data;
                }, angular.noop);
            });
        }

        function getDefaultProfileIdFromAssignedProfile (assignedProfile) {
            return assignedProfile.default_profile_id || assignedProfile.profile_ids[0];
        }

        function getScanResult (assignedProfile) {
            var params = {
                limit: 1,
                profile_id: getDefaultProfileIdFromAssignedProfile(assignedProfile),
                page_id: assignedProfile.page.id,
            };
            return PagePerformanceResults.getAll(params);
        }

        function rescanAllProfilesInAssignedProfile (assignedProfile) {
            var chain = $q.when();
            Lodash.forEach(assignedProfile.profile_ids, function (profileId) {
                chain = chain.then(function () {
                    var profile = Lodash.find(PerformancePagesService.getProfiles(), { id: profileId });
                    return rescanAssignedProfile(assignedProfile.page.id, profile.id).then(function () {
                        observePagePerformanceScanStatus(assignedProfile, profile);
                    });
                }, angular.noop);
            });
        }

        function rescanAssignedProfile (pageId, profileId) {
            return PagePerformanceAssginProfileService.rescanProfile(pageId, profileId);
        }

        function getAssignedProfiles () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                domain_id: ng2SessionService.getDomainId(),
            };

            vm.loadingInProgress = true;

            return getAllAssignedProfiles(params)
                .then(function (assignedProfiles) {
                    const profiles = assignedProfiles || [];
                    profiles.forEach(assignedProfile => {
                        const actions = [];
                        if (ng2SessionService.hasRole('customer_admin')) {
                            actions.push({
                                label: gettextCatalog.getString('Edit'),
                                action: () => vm.openPageForm(assignedProfile),
                                leftIcon: 'faCog',
                            });
                        }
                        actions.push({
                            label: gettextCatalog.getString('Delete'),
                            action: () => vm.deleteAssignedProfile(assignedProfile),
                            leftIcon: 'faTrashAlt',
                        });

                        vm.dropdownActionOptions[assignedProfile.id] = actions;
                    });

                    PerformancePagesService.setAssignedProfiles(assignedProfiles);
                }, angular.noop)
                .finally(function () {
                    vm.loadingInProgress = false;
                });
        }

        function getPageDetails (pageId) {
            return pageService.get(pageId).then(
                function (page) {
                    return page;
                },
                function () {
                    return null;
                },
            );
        }

        function getAssignedProfilesByPage (pageId) {
            var params = {
                page_id: pageId,
                domain_id: ng2SessionService.getDomainId(),
            };
            return getAllAssignedProfiles(params).then(
                function (assignedProfiles) {
                    return assignedProfiles;
                },
                function () {
                    return null;
                },
            );
        }

        function goToPageDetails (page) {
            if (!page) {
                return;
            }
            var data = {
                page: page,
                target: 'page-details-section-page-performance',
            };

            var params = {
                body: 'pageDetails',
                size: 'page-details',
                classes: 'page-details-app',
                data: data,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getAllAssignedProfiles (params) {
            return PagePerformanceAssignedProfile.getAll(params);
        }
    }
})();
