(function () {
    'use strict';

    angular.module('app.forms').component('formFeedback', {
        templateUrl: 'app/forms/feedback/feedback.html',
        controller: FormFeedbackController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@',
        },
    });

    FormFeedbackController.$inject = ['monDialog', 'feedbackService', 'gettextCatalog', 'ng2ToastService'];
    /* @ngInject */
    function FormFeedbackController (monDialog, feedbackService, gettextCatalog, toastService) {
        var vm = this;
        vm.submit = submit;
        vm.$onInit = activate;

        function activate () {
            vm.feedback = {};
            vm.feedback.recipient_name = vm.feedback.recipient_name || '';
            vm.feedback.recipient_email = vm.feedback.recipient_email || '';
            vm.feedback.message = vm.feedback.message || '';
        }

        function submit () {
            vm.onSave = true;

            var params = {
                recipient_name: vm.feedback.recipient_name,
                recipient_email: vm.feedback.recipient_email,
                message: vm.feedback.message,
            };

            feedbackService
                .create(params)
                .then(function () {
                    toastService.success(gettextCatalog.getString('Feedback submitted'));
                    close();
                }, angular.noop)
                .finally(function () {
                    vm.onSave = false;
                });
        }

        function close () {
            monDialog.close(vm.monDialogId);
        }
    }
})();
