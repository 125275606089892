(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceConnectionProfileService
     * @description Service to manage profile connection options.
     */
    angular.module('modules.page-performance').service('ConnectionProfileService', ConnectionProfileService);

    ConnectionProfileService.$inject = [
        'PAGE_PERFORMANCE_CONNECTION_PROFILES',
        'gettextCatalog',
        'Lodash',
        'ng2GlobalHelperService',
    ];

    /* @ngInject*/
    function ConnectionProfileService (
        PAGE_PERFORMANCE_CONNECTION_PROFILES,
        gettextCatalog,
        Lodash,
        ng2GlobalHelperService,
    ) {
        var vm = this;
        vm.connectionKeys = [];
        function init () {
            vm.connectionKeys = Lodash.keys(PAGE_PERFORMANCE_CONNECTION_PROFILES[0].settings);
            return {
                getProfile: getProfile,
                getProfileName: getProfileName,
                getProfileDescription: getProfileDescription,
            };
        }

        return init();

        /**
         * @desc Return Connection Profile for provided settings
         * @param settings - settings we want to find a connection profile for
         */
        function getProfile (settings) {
            var stringifiedSettings = stringify(settings);
            return Lodash.find(PAGE_PERFORMANCE_CONNECTION_PROFILES, function (connectionProfile) {
                return stringify(connectionProfile.settings) === stringifiedSettings;
            });
        }

        /**
         * @desc Return Connection Profile Translated name
         * @param settings - settings we want to find a connection profile for
         */
        function getProfileName (settings) {
            var profile = getProfile(settings);
            if (profile) {
                return gettextCatalog.getString(profile.name); // Constant values cannot be translated before hand, so we do that in the service
            } else {
                return gettextCatalog.getString('Custom Profile');
            }
        }

        /**
         * @desc Return Connection Profile Translated Description
         * @param settings - settings we want to find a connection profile for
         */
        function getProfileDescription (settings) {
            var profile = getProfile(settings);
            if (profile) {
                return gettextCatalog.getString(profile.description); // Constant values cannot be translated before hand, so we do that in the service
            } else {
                return gettextCatalog.getString('Custom Profile');
            }
        }

        // PROTECTED

        /**
         * @desc stringify inserted value
         * @param val- value object, that is gonna be stringified with defined parameters from the vm.connectionKeys array
         * @return {*}
         */
        function stringify (val) {
            var profileSettings = getProfileKeySettings(val);
            return ng2GlobalHelperService.stringify(profileSettings);
        }

        /**
         * @desc Get Only Connection Profile key values
         * @param val - value object, that should only return, the throttling options, that corresponds to the key values defined in vm.connectionKeys
         * @return {{}}
         */
        function getProfileKeySettings (val) {
            var result = {};
            for (var i = 0; i < vm.connectionKeys.length; i++) {
                result[vm.connectionKeys[i]] = val[vm.connectionKeys[i]];
            }
            return result;
        }
    }
})();
