(function () {
    'use strict';

    angular.module('pages.uptimes').component('formUptimeScheduledDowntime', {
        templateUrl: 'app/forms/uptime/scheduled_downtimes/scheduled_downtime/scheduled_downtime.html',
        controller: FormUptimeScheduledDownTimeController,
        controllerAs: 'vm',
        bindings: {
            uptimeId: '<',
            schedule: '<',
            monDialogId: '@?',
        },
    });

    FormUptimeScheduledDownTimeController.$inject = [
        'monDialog',
        'gettextCatalog',
        'moment',
        'uptimeService',
        '$scope',
    ];
    /* @ngInject */
    function FormUptimeScheduledDownTimeController (monDialog, gettextCatalog, moment, uptimeService, $scope) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.submit = submit;
        vm.onChangeRecurringOrStartDate = onChangeRecurringOrStartDate;

        function activate () {
            setupRecurringOptions();
            vm.scheduledDowntime = angular.copy(vm.schedule || {});
            vm.scheduledDowntime.register_downtime = vm.scheduledDowntime.register_downtime || false;
            vm.scheduledDowntime.recurring = vm.scheduledDowntime.recurring || vm.recurringOptions[0].value;
            vm.startDateOptions = {
                initDate: moment().toDate(),
            };

            vm.endDateOptions = {
                initDate: moment()
                    .add(1, 'hour')
                    .toDate(),
            };
            setupDateParams();
            onChangeRecurringOrStartDate();

            // UI Boostrap datepicker don't have a on-change method
            $scope.$watch('vm.scheduledDowntime.start_at', function (oldValue, newValue) {
                onChangeRecurringOrStartDate();
            });
        }

        function setupDateParams () {
            if (angular.isUndefined(vm.scheduledDowntime.start_at)) {
                vm.scheduledDowntime.start_at = moment().toDate();
            } else {
                vm.scheduledDowntime.start_at = moment(vm.scheduledDowntime.start_at).toDate();
            }
            if (angular.isUndefined(vm.scheduledDowntime.end_at)) {
                vm.scheduledDowntime.end_at = moment()
                    .add(1, 'hour')
                    .toDate();
            } else {
                vm.scheduledDowntime.end_at = moment(vm.scheduledDowntime.end_at).toDate();
            }
        }

        function setupRecurringOptions () {
            vm.recurringOptions = [
                {
                    name: gettextCatalog.getString('None'),
                    value: 'no',
                },
                {
                    name: gettextCatalog.getString('Daily'),
                    value: 'daily',
                },
                {
                    name: gettextCatalog.getString('Weekly'),
                    value: 'weekly',
                },
                {
                    name: gettextCatalog.getString('Monthly'),
                    value: 'monthly',
                },
            ];
        }

        function onChangeRecurringOrStartDate () {
            var currentEndAt = moment(vm.scheduledDowntime.end_at);
            var currentStartAt = moment(vm.scheduledDowntime.start_at);
            var maxDate = moment(vm.scheduledDowntime.start_at);

            vm.endDateOptions.minDate = currentStartAt.toDate();
            if (currentEndAt.isBefore(currentStartAt)) {
                vm.scheduledDowntime.end_at = currentStartAt.add(1, 'hour').toDate();
            }

            switch (vm.scheduledDowntime.recurring) {
                case 'daily':
                    maxDate = maxDate.add(24, 'hours');
                    break;
                case 'weekly':
                    maxDate = maxDate.add(24, 'hours').add(7, 'days');
                    break;
                case 'monthly':
                    maxDate = maxDate.add(24, 'hours').add(1, 'month');
                    break;
                default:
                    vm.endDateOptions.maxDate = null;
                    return;
            }

            vm.endDateOptions.maxDate = maxDate.toDate();

            if (currentEndAt.isAfter(maxDate)) {
                vm.scheduledDowntime.end_at = maxDate.toDate();
            }
        }

        function submit () {
            var promise;
            if (angular.isUndefined(vm.scheduledDowntime.id)) {
                promise = uptimeService.createScheduledDowntime(vm.uptimeId, vm.scheduledDowntime);
            } else {
                promise = uptimeService.updateScheduledDowntime(
                    vm.uptimeId,
                    vm.scheduledDowntime.id,
                    vm.scheduledDowntime,
                );
            }
            promise.then(function () {
                close();
            }, angular.noop);
        }

        function close () {
            monDialog.close(vm.monDialogId);
        }
    }
})();
