(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name ReportCenterService
     * @description Manage report center domains
     */
    angular.module('blocks.service').factory('ReportCenterService', ReportCenterService);

    ReportCenterService.$inject = [];

    function ReportCenterService () {
        return {
            filterDomainsByReport: filterDomainsByReport,
        };

        /**
         * @memberOf ReportCenterService
         * @desc Filter domains by what the report is looking for
         * @param {Object} report
         * @param {Array} domains
         * @return {Array}
         */
        function filterDomainsByReport (report, domains) {
            const result = domains.filter((domain) => {
                const name = report.template.name;
                if (name.includes('accessibility')) {
                    const acc = domain.features.accessibility;
                    return typeof acc === 'string' && acc.length > 0;
                }

                if (name.includes('readability')) {
                    const readability = domain.features.readability_test;
                    return typeof readability === 'string' && readability.length > 0;
                }

                if (name.includes('stats')) {
                    return domain.features.statistics;
                }

                return true;
            });

            if (report.template.domain_only) {
                return result.map(domain => ({ ...domain, domain_groups: [] }));
            }

            return result;
        }
    }
})();
