(function () {
    'use strict';

    angular.module('models').factory('Domain', Domain);

    Domain.$inject = ['moment', 'PAGE_ASSIST_SETTINGS', 'PAGE_CORRECT_DOMAIN_SETTINGS_MODULE', 'CRAWL_STATUS', 'WIDGET_SETTINGS'];

    function Domain (moment, PAGE_ASSIST_SETTINGS, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE, CRAWL_STATUS, WIDGET_SETTINGS) {
        var vm = this;
        vm.canStopCrawl = {};
        var offset = moment().utcOffset(); // Timezone in minutes
        var weekDayOptions = ['any', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        function Model (attributes) {
            this.active = false;
            this.domain_users = [];
            this.additional_internal_urls = [];
            this.accessibility_source_code_excludes = [];
            this.source_code_excludes = [];
            this.scan = {
                case_sensitive: true,
                connections_per_minute: 60,
                day: 'any',
                time: moment()
                    .set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
                    .toISOString(),
                use_lang_attribute: true,
            };

            this.path_constraints = [];
            this.link_excludes = [];

            this.page_assist_settings = {
                elements_to_hide: [],
                navigation: [],
                main: '',
            };

            this.features = {
                statistics: false,
                accessibility: null,
                clarity_guideline: null,
                readability_test: null,
                readability_test_min_words: 0,
            };
            this.settings = {
                scripts: {
                    page_assist: {
                        enabled: false,
                        theme: null,
                        direction: null,
                        skip_to: null,
                        manual_startup: false,
                    },
                    page_assist_v2: {
                        enabled: false,
                        theme: null,
                        direction: null,
                        language: null,
                        included_languages: [],
                        greeting: null,
                        title: null,
                        title_text: null,
                        main_color: null,
                        text_color: null,
                        icon_picture_url: null,
                        icon_shape: null,
                        logo_picture_url: null,
                        logo_picture_base64: null,
                    },
                    page_correct: {
                        admin_only: false,
                        enabled_checks: [],
                    },
                },
                data_protection: {
                    enabled_regulations: [],
                    custom_ruleset: {
                        extras: [],
                        exclusions: [],
                    },
                },
            };

            angular.extend(this, attributes);

            if (this.scan.login === null || typeof this.scan.login !== 'object') {
                this.scan.login = {
                    type: 'none',
                };
            }

            if (this.last_scan) {
                this.last_scan = moment(this.last_scan, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toISOString();
            }

            if (typeof this.scan.origTime !== 'string') {
                this.scan.origTime = this.scan.time;
                this.scan.time = moment(this.scan.time, 'HH:mm:ss')
                    .add(offset, 'minutes')
                    .toISOString();
                this.scan.day = getCurrentScanDay(this);
            }

            if (this.settings) {
                if (!this.settings.scripts.page_assist_v2) {
                    this.settings.scripts.page_assist_v2 = {};
                }
                if (typeof this.settings.scripts.page_assist_v2.enabled !== 'boolean') {
                    this.settings.scripts.page_assist_v2.enabled = !!this.settings.scripts.page_assist.enabled;
                }

                if (!this.settings.scripts.page_assist_v2.theme) {
                    this.settings.scripts.page_assist_v2.theme = PAGE_ASSIST_SETTINGS.THEME.LIGHT;
                }

                if (!this.settings.scripts.page_assist_v2.direction) {
                    this.settings.scripts.page_assist_v2.direction = PAGE_ASSIST_SETTINGS.DIRECTION.BOTTOMLEFT;
                }

                if (typeof this.settings.scripts.page_assist_v2.language !== 'string') {
                    this.settings.scripts.page_assist_v2.language = '';
                }

                if (!Array.isArray(this.settings.scripts.page_assist_v2.included_languages)) {
                    this.settings.scripts.page_assist_v2.included_languages = [];
                }

                if (!this.settings.scripts.page_assist_v2.greeting || typeof this.settings.scripts.page_assist_v2.greeting !== 'string') {
                    this.settings.scripts.page_assist_v2.greeting = 'Discover your personalization options';
                }

                if (!this.settings.scripts.page_assist_v2.title || typeof this.settings.scripts.page_assist_v2.title !== 'string') {
                    this.settings.scripts.page_assist_v2.title = 'Personalization Options';
                }

                if (
                    !this.settings.scripts.page_assist_v2.title_text ||
                    typeof this.settings.scripts.page_assist_v2.title_text !== 'string'
                ) {
                    this.settings.scripts.page_assist_v2.title_text =
                        'Welcome to PageAssist™ toolbar! Adjust the options below to cater the website to your accessibility needs.';
                }

                if (typeof this.settings.scripts.page_assist_v2.mainColor !== 'string') {
                    this.settings.scripts.page_assist_v2.mainColor = '';
                }

                if (typeof this.settings.scripts.page_assist_v2.text_color !== 'string') {
                    this.settings.scripts.page_assist_v2.text_color = '';
                }

                if (typeof this.settings.scripts.page_assist_v2.icon_picture_url !== 'string') {
                    this.settings.scripts.page_assist_v2.icon_picture_url = PAGE_ASSIST_SETTINGS.ICON.DEFAULT;
                }

                if (
                    !this.settings.scripts.page_assist_v2.icon_shape ||
                    typeof this.settings.scripts.page_assist_v2.icon_shape !== 'string'
                ) {
                    this.settings.scripts.page_assist_v2.icon_shape = WIDGET_SETTINGS.ICON_SHAPE.CIRCLE;
                }

                if (typeof this.settings.scripts.page_assist_v2.logo_picture_url !== 'string') {
                    this.settings.scripts.page_assist_v2.logo_picture_url = '';
                }

                if (typeof this.settings.scripts.page_assist_v2.logo_picture_base64 !== 'string') {
                    this.settings.scripts.page_assist_v2.logo_picture_base64 = '';
                }

                if (typeof this.settings.scripts.page_assist_v2.skip_to !== 'boolean') {
                    this.settings.scripts.page_assist_v2.skip_to = false;
                }
            }

            if (this.id > 0) {
                this.updated_at = moment(this.updated_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
                this.created_at = moment(this.created_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
            }

            if (this.settings) {
                if (!this.settings.scripts.page_correct.enabled_checks || this.settings.scripts.page_correct.enabled_checks.length === 0) {
                    this.settings.scripts.page_correct.enabled_checks = [
                        PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR,
                        PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE,
                        PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK,
                        PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.LINK,
                        PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.CUSTOM,
                    ];
                }
            }
            if (this.crawl_status) {
                vm.canStopCrawl[this.id] =
                    [CRAWL_STATUS.started, CRAWL_STATUS.queued, CRAWL_STATUS.backoff, CRAWL_STATUS.retry].indexOf(
                        this.crawl_status.step,
                    ) !== -1;
            } else {
                vm.canStopCrawl[this.id] = false;
            }
        }

        Model.prototype.getScanDay = getScanDay;
        Model.prototype.getScanTime = getScanTime;
        Model.prototype.getNextScan = getNextScan;
        Model.prototype.clone = clone;
        Model.prototype.getCanStopCrawl = getCanStopCrawl;

        return Model;

        // /////////

        function getScanDay () {
            if (this.scan.day !== 'any') {
                const weekDayNo = moment(this.scan.time, 'HH:mm:ss')
                    .locale('en')
                    .utcOffset(0) // it is crucial to set offset before day
                    .day(this.scan.day)
                    .isoWeekday();
                return weekDayOptions[weekDayNo];
            }
            return this.scan.day;
        }

        function getScanTime () {
            return moment(this.scan.time)
                .subtract(offset, 'minutes')
                .format('HH:mm:ss');
        }

        function getNextScan (scanInterval) {
            const current = moment(this.last_scan || undefined);
            let weekday = current.day();
            if (this.scan.day !== 'any') {
                weekday = moment(this.scan.time).day(this.scan.day)
                    .utcOffset(0)
                    .isoWeekday();
            }
            const days = (scanInterval + weekday - current.day());
            return current.clone().add(days, 'd')
                .set('hour', moment(this.scan.time).get('hour'))
                .set('minute', moment(this.scan.time).get('minute'));
        }

        function clone () {
            var clone = angular.copy(this);

            clone.domain_users.map(function (user) {
                delete user.id;
                return user;
            });
            clone.link_excludes = clone.link_excludes.map(function (exclude) {
                delete exclude.id;
                return exclude;
            });
            clone.path_constraints = clone.path_constraints.map(function (constraint) {
                delete constraint.id;
                return constraint;
            });
            clone.domain_groups = [];
            clone.scan.time = clone.scan.origTime;

            delete clone.scan.origTime;
            delete clone.crawl_history;
            delete clone.updated_at;
            delete clone.created_at;
            delete clone.crawl_status;
            delete clone.id;
            delete clone.url;
            delete clone.title;
            return new Model(clone);
        }

        function getCanStopCrawl () {
            return !!vm.canStopCrawl[this.id];
        }

        // PROTECTED

        function getCurrentScanDay (model) {
            if (model.scan.day && model.scan.day !== 'any') {
                const weekDayNo = moment(model.scan.origTime, 'HH:mm:ss')
                    .locale('en')
                    .add(offset, 'minutes') // it is crucial to set offset before day
                    .day(model.scan.day)
                    .isoWeekday();

                return weekDayOptions[weekDayNo];
            }

            return model.scan.day;
        }
    }
})();
