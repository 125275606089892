(function () {
    'use strict';

    angular.module('models').factory('PageFixModel', PageFixModel);

    PageFixModel.$inject = ['PAGE_FIX_FIXABLE_TYPES'];

    function PageFixModel (PAGE_FIX_FIXABLE_TYPES) {
        function Model (attributes) {
            angular.extend(this, attributes);
            this.custom_selector = getSelectorValue(this);
        }
        Model.prototype.normalizeFixableType = normalizeFixableType;
        return Model;

        // /////////

        function normalizeFixableType () {
            switch (this.fixable_type) {
                case 'SpellingError':
                    return PAGE_FIX_FIXABLE_TYPES.misspelling;
                case 'AccessibilitySourceCode':
                    return PAGE_FIX_FIXABLE_TYPES.accessibility_source_code;
                case 'AccessibilityCheck':
                    return PAGE_FIX_FIXABLE_TYPES.accessibility_check;
                case 'Link':
                    return PAGE_FIX_FIXABLE_TYPES.link;
                default:
                    return PAGE_FIX_FIXABLE_TYPES.custom;
            }
        }

        function getSelectorValue (pageFix) {
            if (pageFix.selector) {
                if (pageFix.normalizeFixableType() === PAGE_FIX_FIXABLE_TYPES.link) {
                    return JSON.parse(pageFix.selector.match(';(.*)')[1]);
                } else {
                    return pageFix.selector.match(';(.*)')[1];
                }
            }
            return '';
        }
    }
})();
