(function () {
    'use strict';

    angular.module('services.api').factory('CrawlsRepo', CrawlsRepo);

    CrawlsRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'DomainGroupsRepo', 'CrawlModel', 'ApiRequestCancellerService'];

    /* @ngInject */
    function CrawlsRepo (ng2ApiService, ng2SessionService, DomainGroupsRepo, CrawlModel, ApiRequestCancellerService) {
        var service = {
            getScanDetails: getScanDetails,
            getAll: getAll,
        };

        return service;

        // /////////////

        function getScanDetails (params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            const domainId = ng2SessionService.getDomainId(params);

            if (domainId != null) {
                return ng2ApiService.get('domains/' + domainId + '/crawls/running', params, headers);
            } else {
                return Promise.reject(new Error('Missing Domain Id'));
            }
        }

        function getAll (params, headers) {
            params = params || {};
            if (ng2SessionService.domainGroup !== null || angular.isNumber(params.group_id)) {
                return getHistoryForDomainGroup(params);
            } else {
                return getHistoryForDomain(params, headers);
            }
        }

        function getHistoryForDomainGroup (params) {
            return DomainGroupsRepo.getHistory(params);
        }

        function getHistoryForDomain (params, config) {
            var domainId = ng2SessionService.getDomainId(params);
            var settings = {
                route: 'domains/' + domainId + '/crawls',
                params: params,
                config: config,
                verb: 'get',
            };
            return ApiRequestCancellerService.add(ng2ApiService, settings, toModels);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new CrawlModel(model);
        }
    }
})();
