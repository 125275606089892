(function () {
    'use strict';
    angular.module('app.forms').service('FormDomainScriptCookieBannerModelService', FormDomainScriptCookieBannerModelService);

    FormDomainScriptCookieBannerModelService.$inject = ['CookieBannerSettingsService', 'Lodash'];

    /* @ngInject*/
    function FormDomainScriptCookieBannerModelService (CookieBannerSettingsService, Lodash) {
        function Model (settings) {
            var defaultSettings = CookieBannerSettingsService.getDefaultSettings();
            var settingsObject = Lodash.cloneDeep(settings);

            Object.keys(defaultSettings).forEach(function (key) {
                if (!settingsObject.hasOwnProperty(key)) {
                    settingsObject[key] = Lodash.cloneDeep(defaultSettings[key]);
                }
            });
            this.canSave = true;
            this.settings = settingsObject;
        }

        Model.prototype.setSettings = setSettings;
        Model.prototype.canSaveModel = canSaveModel;
        return Model;

        function setSettings (newSettings) {
            var settingsObject = this.settings;
            if (newSettings != null) {
                Object.keys(newSettings).forEach(function (key) {
                    settingsObject[key] = Lodash.cloneDeep(newSettings[key]);
                });
                this.settings = settingsObject;
            }
        }

        function canSaveModel () {
            return !!this.canSave;
        }
    }
})();
