(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name pageFixService
     * @description Service to handle page fixes
     */
    angular.module('services.entities').factory('pageFixService', pageFixService);

    pageFixService.$inject = ['PageFixRepo'];
    /* @ngInject*/
    function pageFixService (PageFixRepo) {
        return {
            getAll: getAll,
            destroy: destroy,
            create: create,
            update: update,
            deleteAll: deleteAll,
        };

        function getAll (params, config) {
            return PageFixRepo.getAll(params, config);
        }

        function create (data, params, config) {
            return PageFixRepo.create(data, params, config);
        }

        function destroy (id) {
            return PageFixRepo.destroy(id);
        }

        function update (pageFix, data, params, config) {
            return PageFixRepo.update(pageFix, data, params, config);
        }

        function deleteAll (params) {
            return PageFixRepo.deleteAll(params);
        }
    }
})();
