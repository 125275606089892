(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name commentService
     * @description Service to handle comments
     */
    angular.module('services.entities').factory('commentService', commentService);

    commentService.$inject = ['CommentRepo'];
    /* @ngInject*/
    function commentService (CommentRepo) {
        return {
            update: update,
            destroy: destroy,
            create: create,
        };

        function create (comment, config) {
            return CommentRepo.create(comment, config);
        }

        function update (comment, config) {
            return CommentRepo.update(comment, config);
        }

        function destroy (commentId, params, config) {
            return CommentRepo.destroy(commentId, params, config);
        }
    }
})();
