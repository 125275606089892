(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuDataPrivacy', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', 'coreLoginService', 'ng2SessionService', '$filter'];
    function SideMenuController (gettextCatalog, coreLoginService, ng2SessionService, $filter) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            coreLoginService.getUser().then(function (user) {
                buildMenu(user);
            });
        }

        function buildMenu (user) {
            vm.menu = [];
            if ($filter('hasAccess')(user, 'dataprivacy')) {
                vm.menu.push(
                    {
                        title: gettextCatalog.getString('Summary'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'SUMMARY']),
                        href: 'base.customer.domain.data_privacy.summary',
                    },
                    {
                        title: gettextCatalog.getString('Fast Track'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'FASTTRACK']),
                        dialogParams: {
                            body: 'privacyDialogsFastTrack',
                            size: 'fw',
                        },
                    },
                    {
                        title: gettextCatalog.getString('Content with Data Issues'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'LIST']),
                        href: 'base.customer.domain.data_privacy.index',
                    },
                    {
                        title: gettextCatalog.getString('Data Issues'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'ISSUES']),
                        href: 'base.customer.domain.data_privacy.issues',
                    },
                    {
                        title: gettextCatalog.getString('Checklist'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CHECKLIST']),
                        href: 'base.customer.domain.data_privacy.checklist',
                    },
                    // {
                    //    title: gettextCatalog.getString("Pages with Ignored Checks"),
                    //    icon: $filter("monIcons")(["SIDEMENUES", "DATA_PRIVACY", "IGNORED_CHECKS"]),
                    //    href: "base.customer.domain.data_privacy.ignored-checks",
                    // },
                );
            }
            consentManagerSideMenu();
        }

        function consentManagerSideMenu () {
            var hasConsentManagerPlanTrait = ng2SessionService.customer && ng2SessionService.customer.plan_traits.cookie_banner;
            if (hasConsentManagerPlanTrait && ($filter('monCustomerHasPlan')('demo') || ng2SessionService.domain.features.cookie_banner)) {
                vm.menu.push({
                    title: gettextCatalog.getString('Consent Overview'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'COOKIE']),
                    href: 'base.customer.domain.data_privacy.consent_overview',
                    activeRoutes: [
                        'base.customer.domain.data_privacy.consent_overview.cookie_acceptance_rate',
                        'base.customer.domain.data_privacy.consent_overview.consent_log',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Cookie Acceptance Rate'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'COOKIE_BITE']),
                            href: 'base.customer.domain.data_privacy.consent_overview.cookie_acceptance_rate',
                        },
                        {
                            title: gettextCatalog.getString('Consent Log'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'CONSENT_LOG']),
                            href: 'base.customer.domain.data_privacy.consent_overview.consent_log',
                        },
                    ],
                });
                cookieOverviewSideMenu();
            }
        }

        function cookieOverviewSideMenu () {
            var isAdmin = ng2SessionService.hasRole('customer_admin');
            if (isAdmin) {
                vm.menu[vm.menu.length - 1].activeRoutes.push('base.customer.domain.data_privacy.consent_overview.cookie_overview');
                vm.menu[vm.menu.length - 1].subs.push({
                    title: gettextCatalog.getString('Cookie Overview'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'COOKIE']),
                    href: 'base.customer.domain.data_privacy.consent_overview.cookie_overview',
                });
            }
        }
    }
})();
