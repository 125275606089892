import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.uptimes').component('formUptimeScheduledDowntimes', {
        templateUrl: 'app/forms/uptime/scheduled_downtimes/scheduled_downtimes.html',
        controller: FormUptimeScheduledDowntimesController,
        controllerAs: 'vm',
        bindings: {
            uptimeId: '<',
            monDialogId: '<',
        },
    });

    FormUptimeScheduledDowntimesController.$inject = [
        'ng2MonEventsService',
        '$controller',
        'UptimeRepo',
        'ng2MonModalService',
        'gettextCatalog',
        'uptimeService',
        'FORM_CONSTANT',
    ];
    /* @ngInject */
    function FormUptimeScheduledDowntimesController (
        ng2MonEventsService,
        $controller,
        UptimeRepo,
        ng2MonModalService,
        gettextCatalog,
        uptimeService,
        FORM_CONSTANT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openExemption = openExemption;
        vm.removeScheduledDowntime = removeScheduledDowntime;
        vm.getRecurringTranslation = getRecurringTranslation;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };

            vm.progress = UptimeRepo.getAllScheduledDowntimes(vm.uptimeId, params).then(
                function (schedules) {
                    vm.downtimeSchedules = schedules;
                },
                function () {
                    vm.downtimeSchedules = {
                        total: '0',
                    };
                },
            );
        }

        function getRecurringTranslation (recurring) {
            switch (recurring) {
                case 'no':
                    return gettextCatalog.getString('None');
                case 'daily':
                    return gettextCatalog.getString('Daily');
                case 'weekly':
                    return gettextCatalog.getString('Weekly');
                case 'monthly':
                    return gettextCatalog.getString('Monthly');
            }
        }

        function removeScheduledDowntime (schedule) {
            var text = gettextCatalog.getString('Are you sure you want to delete this exemption?');
            ng2MonModalService.confirm(text).then(function () {
                uptimeService.destroyScheduledDowntime(vm.uptimeId, schedule.id).then(
                    function (schedules) {
                        getPage();
                    },
                    function () {
                        // Error could be, because another user deleted it first
                        getPage();
                    },
                );
            }, angular.noop);
        }

        function openExemption (schedule) {
            var params = {
                body: FORM_CONSTANT.FORM_UPTIME_SCHEDULED_DOWNTIME,
                size: 'md',
                data: {
                    schedule: schedule || {},
                    uptimeId: vm.uptimeId,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }
    }
})();
