(function () {
    'use strict';
    angular.module('modules.extension').service('deviceDetectorService', deviceDetectorService);

    deviceDetectorService.$inject = ['deviceDetector'];
    /* @ngInject*/
    function deviceDetectorService (deviceDetector) {
        var vm = this;

        function init () {
            var result = deviceDetector;
            var userAgent = result.raw.userAgent;
            if (userAgent.indexOf('Edg/') > -1) {
                result.browser = 'edge';
            }
            return result;
        }

        return init();
    }
})();
