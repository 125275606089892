(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof filters.monsido
     * @ngdoc factory
     * @name AddObjectAttributesController
     * @description
     * Add the objects parameters together like  {a:1, b: 2}; return 3;
     */
    angular.module('filters.monsido').filter('addObjectAttributes', AddObjectAttributesController);

    AddObjectAttributesController.$inject = [];

    /* @ngInject */
    function AddObjectAttributesController () {
        /**
         * @memberOf AddObjectAttributesController
         * @description Add the selected parameters from the stack array together
         * @param {Object} obj
         * @param {Array} stack
         * @return {Number}
         */
        function add (obj, stack) {
            stack = stack || [];
            obj = obj || {};
            var result = 0;

            for (var i = 0; i < stack.length; i++) {
                result += obj[stack[i]] || 0;
            }

            return result;
        }

        return add;
    }
})();
