(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name policyService
     * @description Service to handle policies
     */
    angular.module('services.entities').factory('policyService', policyService);

    policyService.$inject = ['PolicyRepo'];
    /* @ngInject*/
    function policyService (PolicyRepo) {
        return {
            create: create,
            update: update,
            destroy: destroy,
            run: run,
        };

        function create (params, config) {
            return PolicyRepo.create(params, config);
        }

        function update (data, params, config) {
            return PolicyRepo.update(data, params, config);
        }

        function destroy (id, config) {
            return PolicyRepo.destroy(id, config);
        }

        function run (id, params, config) {
            return PolicyRepo.run(id, params, config);
        }
    }
})();
