(function () {
    'use strict';

    angular.module('app.components.pages').component('pagesEmail', {
        templateUrl: 'app/components/pages/emails/emails.html',
        controller: PagesEmailController,
        controllerAs: 'vm',
        bindings: {
            email: '<',
            type: '<',
        },
    });

    PagesEmailController.$inject = ['LinkPagesRepo', '$controller', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function PagesEmailController (LinkPagesRepo, $controller, ng2ActiveFeatureService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.pages.total = 0;
            vm.search = '';
            vm.currentSort = { by: 'hits', direction: 'desc' };
            vm.getPage();
        }

        function getPage () {
            var params = {
                link_id: vm.email.id,
                page: vm.page,
                page_size: vm.pageSize,

                search: vm.search || null,
                'types[]': 'html',
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.type == 'document') {
                params['types[]'] = ['document', 'pdf'];
            }

            vm.promise = LinkPagesRepo.get(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }
    }
})();
