(function () {
    'use strict';

    angular.module('services.api').factory('AccessibilityGuidelinesRepo', AccessibilityGuidelinesRepo);

    AccessibilityGuidelinesRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function AccessibilityGuidelinesRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            getAllChecksForSubGroup: getAllChecksForSubGroup,
        };

        return service;

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/groups', params, config);
        }

        function getAllChecksForSubGroup (acc_group_id, sub_group_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' +
                    ng2SessionService.getDomainId(params) +
                    '/accessibility/groups/' +
                    acc_group_id +
                    '/subgroups/' +
                    sub_group_id +
                    '/checks',
                params,
                config,
            );
        }
    }
})();
