import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('modules.notifications').service('notificationRedirectService', notificationRedirectService);

    notificationRedirectService.$inject = [
        '$state',
        'ng2MonEventsService',
        'Lodash',
        'ng2SessionService',
        'NOTIFICATION_CONSTANT',
        'pageService',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject*/
    function notificationRedirectService (
        $state,
        ng2MonEventsService,
        Lodash,
        sessionService,
        NOTIFICATION_CONSTANT,
        pageService,
        $stateParams,
        ng2MonUIRouterTransitionService,
    ) {
        var domains = [];
        if (sessionService.domains) {
            sessionService.domains.subscribe(function (updatedDomains) {
                domains = updatedDomains;
            });
        }

        return {
            redirect: redirect,
        };

        // ///////////////

        function getScopeId (scopes, type) {
            var domainData = Lodash.find(scopes, { type: type });
            return domainData && domainData.id;
        }

        function getAvailableDomainId () {
            var currentDomainId = sessionService.getDomainId();
            if (currentDomainId !== null && !isNaN(currentDomainId)) {
                return currentDomainId;
            }

            return ((Array.isArray(domains) && Lodash.find(domains, { active: true })) || {}).id;
        }

        function openPage (id) {
            pageService.get(id).then(function (page) {
                var data = {
                    page: page,
                };

                var params = {
                    body: 'pageDetails',
                    size: 'lg',
                    classes: 'page-details-app',
                    data: data,
                };

                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
            }, angular.noop);
        }

        function redirect (args) {
            let domainId = getScopeId(args.scopes, 'Domain');
            const action = args.action;

            switch (args.target) {
                case NOTIFICATION_CONSTANT.TARGET.PAGE:
                    if (domainId !== null) {
                        if ($stateParams.domainId && parseInt($stateParams.domainId) === domainId) {
                            openPage(args.target_id);
                        } else {
                            ng2MonUIRouterTransitionService.onTransitionFinished(
                                () => $state.go('base.customer.domain.dashboard', {
                                    domainId: domainId,
                                    page_id: args.target_id,
                                }),
                            );
                        }
                    }
                    break;

                case NOTIFICATION_CONSTANT.TARGET.LINK:
                    if (domainId !== null) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.qa.broken.links', { domainId: domainId }),
                        );
                    }
                    break;

                case NOTIFICATION_CONSTANT.TARGET.SPELLING_DICTIONARY:
                    domainId = getAvailableDomainId();
                    if (domainId) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.qa.spellcheck.dictionary', { domainId: domainId }),
                        );
                    }
                    break;
                case NOTIFICATION_CONSTANT.TARGET.SPELLING_CONFIRMED:
                case NOTIFICATION_CONSTANT.TARGET.SPELLING_IGNORED:
                    domainId = getAvailableDomainId();
                    if (domainId) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.qa.spellcheck.pages', { domainId: domainId }),
                        );
                    }
                    break;

                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_ERRORS_PAGE:
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_SOURCE_CODE:
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_CHECK:
                case NOTIFICATION_CONSTANT.TARGET.ACCESSIBILITY_ERROR:
                    if (
                        [
                            NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_CHECK_REVIEWED,
                            NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_CHECK_IGNORED,
                        ].indexOf(action) > -1
                    ) {
                        if (domainId != null) {
                            ng2MonUIRouterTransitionService.onTransitionFinished(
                                () => $state.go('base.customer.domain.accessibility.ignored_pages', { domainId: domainId }),
                            );
                        }
                    } else {
                        if (domainId != null) {
                            ng2MonUIRouterTransitionService.onTransitionFinished(
                                () => $state.go('base.customer.domain.accessibility.pages', { domainId: domainId }),
                            );
                        }
                    }
                    break;

                case NOTIFICATION_CONSTANT.TARGET.SEO_ISSUE:
                    if (domainId != null) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.seo.issues', { domainId: domainId }),
                        );
                    }
                    break;
                case NOTIFICATION_CONSTANT.TARGET.SEO_PAGE_ISSUE:
                    if (domainId != null) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.seo.pages', { domainId: domainId }),
                        );
                    }
                    break;

                case NOTIFICATION_CONSTANT.TARGET.REQUEST_SCAN:
                    if (domainId != null && args.target_id != null) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.dashboard', { domainId: domainId, page_id: args.target_id }),
                        );
                    }
                    break;

                case NOTIFICATION_CONSTANT.TARGET.EXPORT:
                    var params = {
                        body: 'csvExportsOverlay',
                    };
                    ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
                    break;

                case NOTIFICATION_CONSTANT.TARGET.DOMAIN:
                    if (action === NOTIFICATION_CONSTANT.ACTION.COOKIES_FOUND) {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.data_privacy.consent_overview.cookie_overview',
                                { domainId: args.target_id }),
                        );
                    } else {
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.dashboard', { domainId: args.target_id }),
                        );
                    }
                    break;
            }
        }
    }
})();
