(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name ReadabilityLineChartFactory
     * @description
     * Create the necessary objects to shape the readability line chart
     */

    angular.module('blocks.service').service('ReadabilityLineChartService', ReadabilityLineChartFactory);

    ReadabilityLineChartFactory.$inject = [
        '$filter',
        'gettextCatalog',
        'LineChart',
        'ChartsDataHelper',
        'moment',
        'QAReadabilityService',
        'LegendCursorService',
    ];

    function ReadabilityLineChartFactory (
        $filter,
        gettextCatalog,
        LineChart,
        ChartsDataHelper,
        moment,
        QAReadabilityService,
        LegendCursorService,
    ) {
        var vm = this;
        /**
         * @memberOf ReadabilityLineChartFactory
         * @description Initialize the ReadabilityLineChartFactory factory
         * @return {Object}
         */
        function init (domain) {
            vm.lineChartSettings = new LineChart();
            setReadabilityTest(domain.features.readability_test);
            return {
                getOption: getOption,
                getColors: getColors,
                getSeries: getSeries,
                getOverride: getOverride,
                getLabels: getLabels,
                getData: getData,
            };
        }

        return init;

        /**
         * @memberOf ReadabilityLineChartFactory
         * @description The option object for the readability line chart
         * @return {Object}
         */
        function getOption () {
            vm.lineChartSettings.setTooltipSettings({
                intersect: false,
                mode: 'x-axis',
                callbacks: {
                    label: function (tooltipItem, data) {
                        var result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
                        return (
                            data.datasets[tooltipItem.datasetIndex].label + ': ' + $filter('numeraljs', '0,0')(result)
                        );
                    },
                    title: function (tooltipItem, data) {
                        return moment(tooltipItem[0].xLabel).format('MMM DD');
                    },
                },
            });

            vm.lineChartSettings.setLegendSettingsOverride({
                display: true,
                position: 'bottom',
                labels: {
                    fontSize: 11,
                    boxWidth: 10,
                },
            });

            vm.lineChartSettings.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Issues'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Content'),
                            fontSize: 11,
                        },
                    },
                ],
                xAxes: [
                    {
                        id: 'x-axis-0',
                        type: 'time',
                        gridLines: {
                            offsetGridLines: true,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            maxTicksLimit: 30,
                        },
                        time: {
                            minUnit: 'day',
                            displayFormats: {
                                // Since the scans always only appear once or twice a week, there's no need for any other date formats to be displayed
                                millisecond: 'MMM D',
                                second: 'MMM D',
                                minute: 'MMM D',
                                hour: 'MMM D',
                                day: 'MMM D',
                                week: 'MMM D',
                                month: 'MMM D',
                                quarter: 'MMM D',
                                year: 'MMM D',
                            },
                        },
                    },
                ],
            });
            vm.lineChartSettings.options = LegendCursorService.setHoverState(vm.lineChartSettings.options);
            return vm.lineChartSettings.options;
        }

        /**
         * @memberOf ReadabilityLineChartFactory
         * @description The colors for the readability line chart
         * @return {Array<String>}
         */
        function getColors () {
            vm.lineChartSettings.addColors(QAReadabilityService.getColorPallete());
            return vm.lineChartSettings.colors;
        }

        /**
         * @memberOf ReadabilityLineChartFactory
         * @description The series for the readability line chart
         * @return {Array<String>}
         */
        function getSeries () {
            var series = QAReadabilityService.getReadabilityLevels(vm.readabilityTest).map(function (level) {
                return QAReadabilityService.getTranslationScore(level, vm.readabilityTest);
            });
            vm.lineChartSettings.addSeries(series);
            return vm.lineChartSettings.series;
        }

        /**
         * @memberOf ReadabilityLineChartFactory
         * @description The override object for the readability line chart
         * @return {Array<Object>}
         */
        function getOverride () {
            var overrides = QAReadabilityService.getReadabilityLevels(vm.readabilityTest).map(function (level) {
                return {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                };
            });
            vm.lineChartSettings.addOverrides(overrides);
            return vm.lineChartSettings.override;
        }

        /**
         * @memberOf ReadabilityLineChartFactory
         * @description The labels for the readability line chart
         * @return {Array<any>}
         */
        function getLabels (crawls) {
            crawls = crawls || [];
            return crawls.map(function (crawl) {
                return new Date(crawl.date).getTime();
            });
        }

        /**
         * @memberOf ReadabilityLineChartFactory
         * @description The data object for the readability line chart
         * @return {Array<Array>}
         */
        function getData (crawls) {
            if (!crawls || crawls.length === 0) {
                return [];
            }
            var helper = new ChartsDataHelper('time');
            var readabilityLevels = QAReadabilityService.getReadabilityLevels(vm.readabilityTest);

            return readabilityLevels.map(function (level) {
                return crawls.map(function (crawl) {
                    var ReadabilityDistributionScore = 0;
                    if (crawl.readability_distribution) {
                        ReadabilityDistributionScore = crawl.readability_distribution[level];
                    }
                    return helper.setData(
                        {
                            result: ReadabilityDistributionScore,
                            date: crawl.date,
                        },
                        'result',
                    );
                });
            });
        }

        function setReadabilityTest (readabilityTest) {
            vm.readabilityTest = readabilityTest;
        }
    }
})();
