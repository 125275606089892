(function () {
    'use strict';

    angular.module('app.layout').run(appRun);

    appRun.$inject = ['routerHelper', 'ng2MonEventsService', 'gettextCatalog'];

    /* @ngInject */
    function appRun (routerHelper, ng2MonEventsService, gettextCatalog) {
        routerHelper.configureStates(getStates(ng2MonEventsService, gettextCatalog));
    }

    function getStates (ng2MonEventsService, gettextCatalog) {
        return [
            {
                state: 'base',
                config: {
                    url: '',
                    abstract: true,
                    templateUrl: 'app/layout/base.html',
                    data: { anonymous_access: false, title: gettextCatalog.getString('Monsido') },
                    controller: 'BaseController',
                    controllerAs: 'vm',
                },
            },
            {
                state: 'base.customer',
                config: {
                    url: '/{customerId}',
                    abstract: true,
                    template: '<ui-view></ui-view>',
                    data: { anonymous_access: false, title: gettextCatalog.getString('Monsido') },
                    resolve: {
                        validate: validateCustomer,
                    },
                    onExit: function () {
                        return ng2MonEventsService.run('exitCustomer');
                    },
                    onEnter: onCustomerEnter,
                },
            },
            {
                state: 'base.customer.domain',
                config: {
                    url: '/domains/{domainId}?domain_group',
                    abstract: true,
                    template: '<ui-view></ui-view>',
                    data: { anonymous_access: false, topmenu: 'default' },
                    resolve: {
                        validate: validateDomain,
                        hasAccessToDomain: hasAccessToDomain,
                    },
                    onExit: function () {
                        return ng2MonEventsService.run('exitDomain');
                    },
                    onEnter: function () {
                        return ng2MonEventsService.run('afterDomain').then(function () {
                            return ng2MonEventsService.run('finishDomain');
                        }, angular.noop);
                    },
                },
            },
        ];
    }

    var onCustomerEnter = [
        'ng2SessionService',
        'ng2MonEventsService',
        '$stateParams',
        function (ng2SessionService, ng2MonEventsService, $stateParams) {
            return ng2MonEventsService.run('loadCustomer', ng2SessionService.isSudo).then(function () {
                return ng2MonEventsService.run('afterCustomer').then(function () {
                    return ng2MonEventsService.run('finishCustomer', { customerId: $stateParams.customerId });
                }, angular.noop);
            });
        },
    ];

    var hasAccessToDomain = [
        'ng2SessionService',
        '$state',
        '$q',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        async function (ng2SessionService, $state, $q, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2SessionService.isLiteCustomer()) {
                await ng2MonUIRouterTransitionService.onTransitionFinishedAsync();
                return $state.go('base.customer.light.domains', $stateParams);
            }

            return $q.resolve(true);
        },
    ];

    var validateDomain = [
        '$q',
        'ng2SessionService',
        '$state',
        '$stateParams',
        'DomainRepo',
        '$timeout',
        'ng2MonEventsService',
        'coreDomainService',
        'DomainGroupsRepo',
        'ng2MonUIRouterTransitionService',
        function ($q,
            ng2SessionService,
            $state,
            $stateParams,
            DomainRepo,
            $timeout,
            ng2MonEventsService,
            coreDomainService,
            DomainGroupsRepo,
            ng2MonUIRouterTransitionService,
        ) {
            return ng2MonEventsService.run('beforeDomain').then(function () {
                var deferred = $q.defer();
                if (ng2SessionService.domain === null ||
                    (ng2SessionService.domain && ng2SessionService.domain.id !== $stateParams.domainId)
                ) {
                    $timeout(function () {
                        var params = {};
                        deferred.resolve(
                            DomainRepo.get($stateParams.domainId, params).then(
                                function (domain) {
                                    coreDomainService.setDomain(domain);
                                    if ($stateParams.domain_group > 0) {
                                        return DomainGroupsRepo.get(domain.id, $stateParams.domain_group).then(
                                            function (group) {
                                                coreDomainService.setDomainGroup(group);
                                                return ng2MonEventsService.run('changed-domain');
                                            },
                                            function () {
                                                // Send the client to the Domains overview page if domain group errors.

                                                return ng2MonUIRouterTransitionService.onTransitionFinished(
                                                    () => $state.go('base.dashboard'),
                                                );
                                            },
                                        );
                                    } else {
                                        return ng2MonEventsService.run('changed-domain');
                                    }
                                },
                                function () {
                                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('base.dashboard'),
                                    );
                                },
                            ),
                        );
                    });
                } else {
                    deferred.resolve(true);
                }
                return deferred.promise;
            }, angular.noop);
        },
    ];

    var validateCustomer = [
        '$q',
        'ng2SessionService',
        '$state',
        '$stateParams',
        'AgreementRepo',
        'ng2MonEventsService',
        'coreLoginService',
        'ng2CoreAgreementService',
        'ng2MonUIRouterTransitionService',
        function (
            $q,
            ng2SessionService,
            $state,
            $stateParams,
            AgreementRepo,
            ng2MonEventsService,
            coreLoginService,
            coreAgreementService,
            ng2MonUIRouterTransitionService,
        ) {
            return ng2MonEventsService.run('beforeCustomer').then(function () {
                var deferred = $q.defer();
                var setAgreement = function (agreement, agreements) {
                    coreAgreementService.setAgreement(agreement).then(function () {
                        coreAgreementService.setAgreements(agreements);
                        deferred.resolve(true);
                    }, angular.noop);
                };

                coreLoginService.getUser().then(
                    function (user) {
                        if (user && ng2SessionService.customer && ng2SessionService.customer.id === parseInt($stateParams.customerId)) {
                            deferred.resolve(true);
                        } else {
                            var selected = false;
                            AgreementRepo.getAll().then(function (agreements) {
                                for (var i = 0; i < agreements.length; i++) {
                                    if (agreements[i].account.id === parseInt($stateParams.customerId)) {
                                        selected = true;
                                        setAgreement(agreements[i], agreements);
                                    }
                                }
                                if (selected === false) {
                                    return deferred.reject('Can\'t find customer');
                                }
                            }, angular.noop);
                        }
                    },
                    function () {
                        return deferred.reject('Can\'t fetch user');
                    },
                );

                return deferred.promise.catch(function () {
                    ng2MonUIRouterTransitionService.onTransitionFinished(
                        () => $state.go('base.dashboard'),
                    );
                });
            }, angular.noop);
        },
    ];
})();
