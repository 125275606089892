import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.page-performance.page-details').component('pagePerformancePageDetailsOpportunities', {
        templateUrl: 'app/modules/page-performance/page-details/opportunities/opportunities.html',
        controller: PagePerformancePageDetailsOpportunitiesController,
        controllerAs: 'vm',
    });

    PagePerformancePageDetailsOpportunitiesController.$inject = [
        'PagePerformancePageDetailsService',
        'PagePerformanceAuditImpactService',
        'PagePerformanceOpportunitiesService',
        'PagePerformanceAuditScoreService',
        'PerformanceAuditRelations',
        'Lodash',
        'gettextCatalog',
        'ng2MonEventsService',
        'PagePerformanceAuditInfoService',
        '$scope',
        'exporter',
        '$timeout',
    ];
    /* @ngInject */
    function PagePerformancePageDetailsOpportunitiesController (
        PagePerformancePageDetailsService,
        PagePerformanceAuditImpactService,
        PagePerformanceOpportunitiesService,
        PagePerformanceAuditScoreService,
        PerformanceAuditRelations,
        Lodash,
        gettextCatalog,
        ng2MonEventsService,
        PagePerformanceAuditInfoService,
        $scope,
        exporter,
        $timeout,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getLoadingStatus = PagePerformancePageDetailsService.getLoadingReportStatus;
        vm.getLabelClass = getLabelClass;
        vm.getImpactTranslation = getImpactTranslation;
        vm.setTab = setTab;
        vm.onSearch = onSearch;
        vm.openDetails = openDetails;
        vm.getDifficulty = getDifficulty;
        vm.onSortPage = onSortPage;
        vm.exportCsv = exportCsv;
        vm.csvFileName = csvFileName;
        vm.onFiltersChange = onFiltersChange;

        function activate () {
            initFilters();
            vm.tab = 'errors';
            vm.search = '';
            vm.minSearchCharacterLimit = 3;
            vm.sortType = 'title';
            vm.sortDirection = 'asc';
            vm.isFiltersOpen = false;
            vm.audits = [];
            vm.allAudits = PagePerformanceOpportunitiesService.getAudits().map(function (entry) {
                var updatedEntry = angular.copy(entry);
                updatedEntry.metrics = PerformanceAuditRelations.metrics[entry.id] || [];
                return updatedEntry;
            });
            getPage();
        }

        function initFilters () {
            var filters = PagePerformancePageDetailsService.initFilters();
            vm.filters = filters.filters;
            vm.availableFilters = filters.availableFilters;
        }

        function setTab (tabValue) {
            vm.tab = tabValue;
            getPage();
        }

        function onSearch (search) {
            vm.search = search;
            getPage(true);
        }

        function onFiltersChange (changes) {
            if (!changes.audits.length) {
                initFilters();
            }

            if (changes.audits) {
                getPage();
            }
        }

        function getDifficulty (id) {
            return PagePerformanceAuditInfoService.getDifficulty(id);
        }

        function getLabelClass (audit) {
            return PagePerformanceAuditImpactService.getImpactClass(audit);
        }

        function getImpactTranslation (audit) {
            return PagePerformanceAuditImpactService.getImpactTranslation(audit);
        }

        function openDetails (audit) {
            var params = {
                body: 'pagePerformanceDialogsAuditDetails',
                size: 'sm',
                data: {
                    audit: audit,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function onSortPage ({ direction, by }) {
            vm.sortDirection = direction;
            vm.sortType = by;
            getPage(true);
        }

        // PROTECTED

        function getPage (forceDomUpdate) {
            const filteredAudits = PagePerformancePageDetailsService.filterAudits(vm.filters, vm.allAudits, vm.tab, vm.search);
            vm.audits = sortAudits(filteredAudits);
            // Renews the Tab Count when filtering search results.
            setupTabs();
            if (forceDomUpdate) {
                // ng-repeat track by can't register reordering of track elements if they still exists, it seems..
                $timeout(() => $scope.$digest());
            }
        }

        function sortAudits (audits) {
            return Lodash.orderBy(
                audits,
                function (audit) {
                    if (vm.sortType !== null) {
                        if (vm.sortType === 'difficulty') {
                            return PagePerformanceAuditInfoService.getDifficultyLevel(audit.id);
                        } else if (vm.sortType === 'priority') {
                            return PagePerformanceAuditImpactService.getImpactValue(audit);
                        } else {
                            return audit[vm.sortType];
                        }
                    }

                    return null;
                },
                vm.sortDirection,
            );
        }

        function setupTabs () {
            var errorAudits;
            var passedAudits;
            if (vm.tab === 'errors') {
                errorAudits = vm.audits.length;
                passedAudits = vm.allAudits.length - errorAudits;
            } else {
                passedAudits = vm.audits.length;
                errorAudits = vm.allAudits.length - passedAudits;
            }
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Errors ({{audits}})', { audits: errorAudits }),
                    value: 'errors',
                },
                {
                    name: gettextCatalog.getString('Passed ({{audits}})', { audits: passedAudits }),
                    value: 'passed',
                },
            ];
        }

        function exportCsv () {
            if (vm.audits) {
                return exporter.parsePerformanceAudits(vm.audits);
            } else {
                return null;
            }
        }

        function csvFileName () {
            if (vm.tab === 'errors') {
                return gettextCatalog.getString('opportunities_errors.csv');
            }
            return gettextCatalog.getString('opportunities_passed.csv');
        }
    }
})();
