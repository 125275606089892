import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { DialogOptionsAdapter } from '@monsido/core/modules/dialog-container/dialog-container.component';
import { Domain, DomainType } from '@monsido/modules/models/api/domain';
import { DomainGroup } from '@monsido/modules/models/api/domain-group';
import { ReportWithTemplateId, Targets } from '@monsido/modules/models/api/interfaces/report.interface';
import { Report } from '@monsido/modules/models/api/report';
import { User } from '@monsido/modules/models/api/user';
import { FormScheduleReportService } from '@monsido/modules/report-center/services/form-schedule-report/form-schedule-report.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { DomainRepo } from 'ng2/angularjs-providers/domain-repo/domain-repo.types';
import { ReportCenterService } from 'ng2/angularjs-providers/report-center-service/report-center-service.types';
import { ReportService } from 'ng2/angularjs-providers/report-service/report-service.types';
import { ScheduleReportAdminUsersComponent } from '../schedule-report-admin-users/schedule-report-admin-users.component';

@Component({
    selector: 'mon-schedule-report-admin',
    templateUrl: './schedule-report-admin.component.html',
    styleUrls: ['./schedule-report-admin.component.scss'],
})
export class ScheduleReportAdminComponent implements OnInit {
    @Input() report: Report | null = null;

    maxIcons: number = 0;
    saving = false;
    loading = false;
    promise?: Promise<void>;
    domains: Domain[] = [];
    users: User[] = [];
    domainType = DomainType;

    constructor (
        private eventsService: MonEventService,
        private scheduleReportService: FormScheduleReportService,
        private activeDialog: ActiveDialog,
        @Inject('DomainRepo') private domainRepo: DomainRepo,
        @Inject('ReportCenterService') private reportCenterService: ReportCenterService,
        @Inject('reportService') private reportService: ReportService,
    ) {}

    ngOnInit (): void {
        this.maxIcons = 10;
        this.promise = this.getDomains();
        this.users = this.setupExistingUsers();
    }

    async getDomains (): Promise<void> {
        if (!this.report) {
            throw new Error('No report');
        }
        this.loading = true;
        const domains = await this.domainRepo.getAll({ page_size: 0 });
        const filteredDomains = this.reportCenterService.filterDomainsByReport(this.report, domains);
        this.domains = this.scheduleReportService.setupDomainUsers(filteredDomains, this.report);
        this.loading = false;
    }

    setupExistingUsers (): User[] {
        const flattenedUsers = this.report?.targets.flatMap(target => target.users);
        return [...new Map(flattenedUsers?.map(user => [user.id, user])).values()];
    }


    sendReport (): void {
        if (!this.report) {
            return;
        }
        this.saving = true;
        const params = {
            targets: this.report.targets,
        };

        params.targets = params.targets.map(target => {
            const users: Partial<User>[] =
                target.users.map(function (user) {
                    if (user?.user?.id) {
                        // Internal user
                        return { user_id: user.user.id };
                    } else {
                        // External user
                        return { email: user.email, first_name: user.first_name, last_name: user.last_name };
                    }
                });

            target.users = users as User[];
            return target;
        });

        if (!this.report.id) {
            this.createReport(params);
        } else {
            this.updateReport(params);
        }
    }

    targetByTitle (index: number, target: Targets): number {
        return target.id;
    }

    domainGroupById (index: number, domainGroup: DomainGroup): number {
        return domainGroup.id;
    }

    userByNameEmail (index: number, user: User): string {
        return user.first_name + user.last_name + user.email;
    }

    openUsersDialog (event: Event, domain: Domain | DomainGroup, type: DomainType): void {
        if (!this.report) {
            return;
        }
        event.preventDefault();
        let targetIndex = this.report.targets.findIndex(target => {
            return domain.id === target.id && type === target.type;
        });

        if (targetIndex === -1) {
            // If target doesn't exist, add a new one
            this.report.targets.push({ id: domain.id, title: domain.title, type, users: [] });
            targetIndex = this.report.targets.length - 1;
        }

        const callback = (result: {users: User[]}): void => {
            // Set new users list
            if (this.report && result?.users) {
                domain.users = result.users;
                this.report.targets[targetIndex].users = result.users;
                this.users = this.setupExistingUsers();
            }
        };

        const params: DialogOptionsAdapter<ScheduleReportAdminUsersComponent> = {
            component: ScheduleReportAdminUsersComponent,
            dialogOptions: {
                size: 'md',
                cb: callback,
            },
            data: {
                users: domain.users,
                domainId: domain.id,
                domainType: type,
            },
        };


        this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

    closeDialog (report: Report): void {
        this.activeDialog.close(report);
    }

    private createReport (params: Partial<ReportWithTemplateId>): void {
        if (!this.report) {
            return;
        }
        params.template_id = this.report.template.id;
        this.reportService.create(params)
            .then(report => {
                this.report = report;
                this.closeDialog(report);
            })
            .finally(() => this.saving = false);
    }

    private updateReport (params: Partial<ReportWithTemplateId>): void {
        if (this.report) {
            this.reportService.update(this.report.id, params)
                .then(updatedReport => this.closeDialog(updatedReport))
                .finally(() => this.saving = false);
        }
    }
}
