(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name UserLocaleService
     * @description Get region details of current active region
     */
    angular.module('blocks.service').factory('UserLocaleService', UserLocaleFactory);

    UserLocaleFactory.$inject = ['gettextCatalog', 'Lodash'];
    /* @ngInject*/
    function UserLocaleFactory (gettextCatalog, Lodash) {
        var vm = this;

        function init () {
            vm.localeOptions = [
                {
                    name: gettextCatalog.getString('English'),
                    value: 'en',
                    numeralValue: 'en-gb',
                },
                {
                    name: gettextCatalog.getString('Danish'),
                    value: 'da',
                    numeralValue: 'da-dk',
                },
                {
                    name: gettextCatalog.getString('Dutch'),
                    value: 'nl',
                    numeralValue: 'nl-nl',
                },
                {
                    name: gettextCatalog.getString('Norwegian'),
                    value: 'nb',
                    numeralValue: 'no',
                },
                {
                    name: gettextCatalog.getString('Swedish'),
                    value: 'sv',
                    numeralValue: 'da-dk', // Swedish format is not supported but is the same as danish
                },
                {
                    name: gettextCatalog.getString('Japanese'),
                    value: 'ja',
                    numeralValue: 'ja',
                },
            ];

            return {
                getLocaleOptions: getLocaleOptions,
                getNumeralValue: getNumeralValue,
            };
        }
        return init();

        /**
         * @memberOf UserLocaleService
         * @desc Get all locale options
         * @return {array}
         */
        function getLocaleOptions () {
            return vm.localeOptions;
        }

        /**
         * @memberOf UserLocaleService
         * @desc Get the numeral value of selected locale
         * @return {string}
         */
        function getNumeralValue (locale) {
            var option = Lodash.find(vm.localeOptions, { value: locale });
            if (!option) {
                option = Lodash.find(vm.localeOptions, { value: 'en' });
            }
            return option.numeralValue;
        }

        // PROTECTED
    }
})();
