(function () {
    'use strict';

    angular.module('modules.extension').component('extensionGuideDialog', {
        templateUrl: 'app/modules/extension/components/extension-guide-dialog/extension-guide-dialog.html',
        controller: ExtensionGuideDialogController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@',
        },
    });

    ExtensionGuideDialogController.$inject = ['monDialog', 'gettextCatalog', 'extensionService'];
    /* @ngInject */
    function ExtensionGuideDialogController (monDialog, gettextCatalog, extensionService) {
        var vm = this;

        vm.$onInit = activate;
        vm.close = close;

        function activate () {
            vm.currentExtension = extensionService.getCurrentExtensionInfo();
        }

        function close () {
            monDialog.close(vm.monDialogId);
        }
    }
})();
