(function () {
    'use strict';

    angular.module('modules.extension').component('extensionGuidePanel', {
        templateUrl: 'app/modules/extension/components/extension-guide-panel/extension-guide-panel.html',
        controller: ExtensionGuidePanelController,
        controllerAs: 'vm',
    });

    ExtensionGuidePanelController.$inject = ['gettextCatalog', 'extensionService'];
    /* @ngInject */
    function ExtensionGuidePanelController (gettextCatalog, extensionService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.currentExtension = extensionService.getCurrentExtensionInfo();
        }
    }
})();
