(function () {
    'use strict';
    angular.module('pages.seo').component('seoPages', {
        templateUrl: 'app/pages/seo/pages/pages.html',
        controller: SeoPagesController,
        controllerAs: 'vm',
    });

    SeoPagesController.$inject = [
        'SeoRepo',
        '$controller',
        'gettextCatalog',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
    ];

    function SeoPagesController (SeoRepo, $controller, gettextCatalog, ng2MonEventsService, ENTITY_CONSTANT, BaseEntityService) {
        var vm = this;

        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.pages = [];
            vm.issues = [];
            vm.search = '';
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Pages With Opportunities'),
                    data: {
                        category: 'seo_pages',
                    },
                },
            ];
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                'types[]': ['html'],
                sort_by: vm.currentSort.by === 'notification_count' ? 'seo_issues_count' : vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
            };

            params = updateParamsWithIssuesFilters(params);
            params = vm.updateParamsWithFilters(params);

            vm.promise = SeoRepo.getAllPages(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }

        function updateParamsWithIssuesFilters (params) {
            for (var i = 0; i < vm.issues.length; i++) {
                params[vm.issues[i].id] = true;
            }
            return params;
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);

            if (changes.contains) {
                vm.issues = changes.contains;
            } else {
                vm.issues = [];
            }

            getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
