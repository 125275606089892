(function () {
    'use strict';

    angular.module('models').factory('AccessibilityCheckModel', AccessibilityCheck);

    AccessibilityCheck.$inject = [
        'PAGE_FIX_FIXABLE_TYPES',
        'PageFixModelHelperService',
        'accessibilityAutofixService',
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
    ];
    function AccessibilityCheck (
        PAGE_FIX_FIXABLE_TYPES,
        PageFixModelHelperService,
        accessibilityAutofixService,
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
    ) {
        function Model (attributes) {
            angular.extend(this, attributes);
            this.nameEscaped = this.name.replace(/<[^>]*>/g, '');
            if (typeof this.issue_number === 'string') {
                this.issueNumberEscaped = Number(this.issue_number.replace(/\./g, ''));
            } else if (typeof this.issue_number !== 'number') {
                this.issueNumberEscaped = 0;
            }
        }

        Model.prototype.getPageFixType = getPageFixType;
        Model.prototype.getPageFixSelector = getPageFixSelector;
        Model.prototype.getPageFixFixableId = getPageFixFixableId;

        return Model;

        // /////////

        function getPageFixFixableId (sourceCodeId) {
            if (accessibilityAutofixService.useCheckId(this.id)) {
                return this.id;
            }
            return sourceCodeId;
        }

        function getPageFixType () {
            if (accessibilityAutofixService.useCheckId(this.id)) {
                return PAGE_FIX_FIXABLE_TYPES.accessibility_check;
            }
            return PAGE_FIX_FIXABLE_TYPES.accessibility_source_code;
        }

        function getPageFixSelector (attrs) {
            var snippet = this.html_snippet || PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[this.id].snippet || '';
            return PageFixModelHelperService.getSelectorValue(snippet, attrs);
        }
    }
})();
