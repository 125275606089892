(function () {
    'use strict';

    angular.module('services.api').factory('PolicyRepo', PolicyRepo);

    PolicyRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'PolicyModel', 'ng2MonEventsService'];

    /* @ngInject */
    function PolicyRepo (ng2ApiService, ng2SessionService, PolicyModel, ng2MonEventsService) {
        var service = {
            getAll: getAll,
            create: create,
            update: update,
            destroy: destroy,
            run: run,
            domainGetAll: domainGetAll,
            domainGetPages: domainGetPages,
            domainGetPolicyPages: domainGetPolicyPages,
            domainGetPolicyLinks: domainGetPolicyLinks,
            getPagesWithPolicyIgnoredChecks: getPagesWithPolicyIgnoredChecks,
            getPolicyAuthors: getPolicyAuthors,
        };

        return service;

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('policies/', params, config).then(toModels);
        }

        function create (params, config) {
            config = config || {};
            return ng2ApiService.post('policies', params, config).then(function (policy) {
                ng2MonEventsService.run('policyAdded', policy);
                return toModel(policy);
            }, angular.noop);
        }

        function update (data, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('policies/' + data.id, data, params, config).then(function (policy) {
                ng2MonEventsService.run('policyUpdated', policy);
                return toModel(policy);
            }, angular.noop);
        }

        function destroy (id, config) {
            config = config || {};
            return ng2ApiService.delete('policies/' + id, {}, config);
        }

        function run (id, params, config) {
            config = config || {};
            return ng2ApiService.post('policies/' + id + '/run', params, config);
        }

        function domainGetAll (params, config) {
            config = config || {};
            params = params || {};
            var domainId = params.domain_id ? params.domain_id : ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + domainId + '/policies', params, config).then(toModels);
        }

        function domainGetPages (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/policies/pages', params, config);
        }

        function domainGetPolicyPages (policyId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/policies/' + policyId + '/pages', params, config);
        }

        function domainGetPolicyLinks (policyId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/policies/' + policyId + '/links', params, config);
        }

        function getPagesWithPolicyIgnoredChecks (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId() + '/policies/ignored_policies/pages', params, config);
        }

        function getPolicyAuthors (params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.get('policies/authors', params, config);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new PolicyModel(model);
        }
    }
})();
