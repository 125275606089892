(function () {
    'use strict';

    angular.module('services.api').factory('PdfRepo', PdfRepo);

    PdfRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function PdfRepo (ng2ApiService, ng2SessionService) {
        var service = {
            get: get,
            update: update,
            createClarity: createClarity,
            deleteClarity: deleteClarity,
            createRemediation: createRemediation,
            createAllClarity: createAllClarity,
            markAllPdfsAsReviewed: markAllPdfsAsReviewed,
            requestRemeditionQuoteForAllPdfs: requestRemeditionQuoteForAllPdfs,
        };

        return service;

        // /////////////

        function get (params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pdfs', params, headers);
        }

        function createClarity (pdf, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.post(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pdfs/' + pdf.id + '/clarity-report',
                params,
                headers,
            );
        }

        function deleteClarity (pdf, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.delete(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pdfs/' + pdf.id + '/clarity-report',
                params,
                headers,
            );
        }

        function update (pdf, data, params) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pdfs/' + pdf.id, data, params);
        }

        function createRemediation (pdf, data, params, config) {
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.post(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pdfs/' + pdf.id + '/clarity-remediation',
                data,
                params,
                config,
            );
        }

        function createAllClarity (model, params, headers) {
            params = params || {};
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/pdfs/clarity-report', model, params, headers);
        }

        function markAllPdfsAsReviewed () {
            return ng2ApiService.post('domains/' + sessionService.getDomainId() + '/accessibility/pdfs/review-all');
        }

        function requestRemeditionQuoteForAllPdfs () {
            return ng2ApiService.post('domains/' + sessionService.getDomainId() + '/accessibility/pdfs/clarity-remediation');
        }

    }
})();
