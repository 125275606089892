import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').component('privacySummary', {
        templateUrl: 'app/modules/data-privacy/pages/summary/summary.html',
        controller: DataPrivacySummaryController,
        controllerAs: 'vm',
    });

    DataPrivacySummaryController.$inject = [
        'DataPrivacyInfoTypeRepo',
        'DataPrivacySummaryRepo',
        'CrawlsRepo',
        'RISKS',
        'Lodash',
        'ng2SessionService',
        '$q',
        'ng2MonEventsService',
        '$filter',
        'gettextCatalog',
        'complianceDataPrivacyService',
        'ChangesHistoryService',
        'DataPrivacyService',
        'DoughnutChart',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DataPrivacySummaryController (
        DataPrivacyInfoTypeRepo,
        DataPrivacySummaryRepo,
        CrawlsRepo,
        RISKS,
        Lodash,
        ng2SessionService,
        $q,
        ng2MonEventsService,
        $filter,
        gettextCatalog,
        complianceDataPrivacyService,
        ChangesHistoryService,
        DataPrivacyService,
        DoughnutChart,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getNumbers = getNumbers;
        vm.goToPages = goToPages;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;
        vm.getPagesWithActiveViolationCount = DataPrivacyService.getPagesWithActiveViolationCount;
        vm.getTotalPageCount = DataPrivacyService.getTotalPageCount;

        vm.exports = [
            {
                name: gettextCatalog.getString('Data Privacy Summary Export'),
                data: {
                    category: 'data_protection_summary',
                },
            },
        ];

        function activate () {
            vm.chartColors = ['#4dc0bd', '#dcdcdc'];
            vm.infoTypes = [];
            vm.summaryData = null;
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.risks = RISKS;
            vm.riskCounts = null;
            vm.history = [];
            vm.loading = false;
            vm.label = [gettextCatalog.getString('No violations were found'), gettextCatalog.getString('Content with violations')];
            vm.risksLabel = [gettextCatalog.getString('Fixed or ignored violations'), gettextCatalog.getString('Violations')];
            vm.mostSevere = [];

            getData();
        }

        function getData () {
            vm.loading = true;
            var promises = [
                DataPrivacyInfoTypeRepo.getAllForDomain(ng2SessionService.domain.id, { page_size: 0 }),
                DataPrivacySummaryRepo.getSummary(ng2SessionService.domain.id),
                CrawlsRepo.getAll({ page_size: 5 }),
            ];
            vm.progress = $q
                .all(promises)
                .then(function (values) {
                    vm.infoTypes = values[0];
                    vm.summaryData = values[1];
                    vm.history = values[2].reverse();
                    vm.riskCounts = {
                        low: getNumbers('low'),
                        medium: getNumbers('medium'),
                        high: getNumbers('high'),
                    };

                    setupDiagnosticsDoughnutChart(vm.summaryData);
                    setupSeverityDoughnutCharts(vm.riskCounts);
                    mostSevereInfoTypes();
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function mostSevereInfoTypes () {
            var priorities = { high: 2, medium: 1, low: 0 };
            if (vm.infoTypes) {
                vm.mostSevere = vm.infoTypes
                    .filter(function (type) {
                        if (type.info_type_domain_summary[0]) {
                            return vm.getPagesWithActiveViolationCount(type) > 0;
                        }
                    })
                    .sort(function (a, b) {
                        var firstPriority = priorities[a.priority];
                        var secondPriority = priorities[b.priority];
                        var firstViolationCount = vm.getPagesWithActiveViolationCount(a);
                        var secondViolationCount = vm.getPagesWithActiveViolationCount(b);

                        if (firstPriority === secondPriority) {
                            if (firstViolationCount > secondViolationCount) {
                                return -1;
                            } else {
                                return firstViolationCount < secondViolationCount ? 1 : 0;
                            }
                        } else if (firstPriority === undefined || secondPriority === undefined) {
                            return firstPriority === undefined ? 1 : -1;
                        } else if (firstPriority > secondPriority) {
                            return -1;
                        } else if (firstPriority < secondPriority) {
                            return 1;
                        }
                        return 0;
                    })
                    .slice(0, 5);
            }
        }

        function getNumbers (risk) {
            var numbers = {
                errors: 0,
                total: 0,
            };

            Lodash.filter(vm.infoTypes, { priority: risk }).forEach(function (infoType) {
                if (infoType.info_type_domain_summary[0]) {
                    numbers.errors += vm.getPagesWithActiveViolationCount(infoType);
                    numbers.total += vm.getTotalPageCount(infoType);
                }
            });
            return numbers;
        }

        function goToPages (infoType) {
            var params = {
                size: 'lg',
                body: 'privacyViolationPages',
                data: {
                    infoType: infoType,
                    totalPagesCount: vm.getPagesWithActiveViolationCount(infoType),
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getData });
        }

        function setupDiagnosticsDoughnutChart (summary) {
            var percentage = complianceDataPrivacyService.dataPrivacyCompliancePercentage(summary);
            vm.doughnutDiagnosticsData = complianceDataPrivacyService.dataPrivacyCompliance(summary);
            vm.doughnutDiagnosticsOptions = {
                cutoutPercentage: 80,
                centerText: {
                    percentageLabel: $filter('monNumber')(percentage / 100, '0.[00] %'),
                    totalLabel: gettextCatalog.getString('Data Privacy'),
                    subLabel: gettextCatalog.getString('Compliance'),
                    subFontSize: 170,
                    totalFontSize: 170,
                },
                hover: {
                    mode: null,
                },
            };
        }

        function setupSeverityDoughnutCharts (riskCounts) {
            vm.doughnutRisks = {};
            Object.keys(riskCounts).forEach(function (key) {
                var errors = riskCounts[key].errors;
                var total = riskCounts[key].total - errors;
                total = total > 0 ? total : 1;
                vm.doughnutRisks[key] = {
                    data: [total, errors],
                    options: getSeverityDoughnutOption(key, riskCounts),
                };
            });
        }

        function getSeverityDoughnutOption (level, riskCounts) {
            var doughnutChart = new DoughnutChart();
            var compliant = riskCounts[level].total - riskCounts[level].errors;
            var total = riskCounts[level].total;
            var percentage;
            if (compliant === 0 && total === 0) {
                percentage = 100;
            } else {
                percentage = (compliant / total) * 100;
            }
            doughnutChart.setCutOutPercentage(80);
            doughnutChart.setCenterText($filter('monNumber')(percentage / 100, '0.[00] %'));
            doughnutChart.setTooltipSettings({
                enabled: true,
            });
            doughnutChart.setTooltipCustom({});
            return doughnutChart.options;
        }

        function getHistoryCenterParameters (module) {
            return ChangesHistoryService.getHistoryCenterParameters(module);
        }
    }
})();
