<mon-table-container
    [noResultMessage]="'You have not received any reports' | translate"
    [collection]="receivedReports"
    [loading]="loading"
    [disablePagination]="!showPagination"
    (perPageChange)="this.pageSize$.next($event)"
    (pageChangeEvent)="this.page$.next($event)"
    >
    <div class="table-container-body">
        <table class="table table-hover table-fixed table-with-labels">
            <caption class="sr-only">{{ 'List of received reports' | translate }}</caption>
            <thead>
                <tr>
                    <th
                        class="col-md-8 pl-5"
                        monTableSort
                        [monThTitle]="'Date' | translate"
                        [monSortBy]="'created_at'"
                        [monSortDirection]="currentSort$.value.direction"
                        [monCurrentlySortedBy]="currentSort$.value.by"
                        (monOnSorted)="onSortContent($event)"
                        >
                    </th>
                        <th
                            class="col-md-34"
                            monTableSort
                            [monThTitle]="'Report' | translate"
                            [monSortBy]="'title'"
                            [monSortDirection]="currentSort$.value.direction"
                            [monCurrentlySortedBy]="currentSort$.value.by"
                            (monOnSorted)="onSortContent($event)"
                            >
                        </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of receivedReports">
                    <td>
                        <img class="ml-3 report-img"
                             src="/images/report-center/report-icon1.jpg"
                             alt="{{'Report Center Image' | translate}}" />
                    </td>
                    <td>
                        <div class="report-block">
                            <div class="report-title">
                                <p class="title text-link text-ellipsis mb-1">{{report.title}}</p>
                                <span class="text-grey">{{'Created' | translate}} {{report.created_at | date:'MMM dd'}}</span>
                            </div>
                            <mon-icon *ngIf="report.state === 'processing'" [monIconDirect]="'faSpinner'" aria-hidden="true"></mon-icon>
                            <div *ngIf="report.state == 'ready' && report.token">
                                <a href="{{report.url}}"
                                    rel="noopener noreferrer"
                                    target="_blank" aria-describedby="linkOpensInNewTab"
                                    translate>
                                    Open
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</mon-table-container>

