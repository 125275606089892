(function () {
    'use strict';

    angular.module('services.api').factory('PagePerformanceReportRepo', PagePerformanceReportRepo);

    PagePerformanceReportRepo.$inject = ['ng2ApiService', 'PagePerformanceReportModel'];

    /* @ngInject */
    function PagePerformanceReportRepo (ng2ApiService, PagePerformanceReportModel) {
        return {
            getAll: getAll,
        };

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.get('performance/reports', params, config).then(toModel);
        }

        // PROTECTED

        function toModel (model) {
            return new PagePerformanceReportModel(model);
        }
    }
})();
