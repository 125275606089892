(function () {
    'use strict';

    angular.module('filters.monsido').filter('isStatsSampleData', isStatsSampleData);

    isStatsSampleData.$inject = ['$filter'];
    /* @ngInject */
    function isStatsSampleData ($filter) {
        return useSampleData;

        // //////////

        function useSampleData (domain) {
            var statistics = domain !== null && domain.features.statistics;
            return !statistics && $filter('monCustomerHasPlan')('demo');
        }
    }
})();
