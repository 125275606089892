(function () {
    'use strict';

    angular.module('models').factory('PagePerformanceRescanModel', PagePerformanceRescanModel);

    PagePerformanceRescanModel.$inject = [];

    function PagePerformanceRescanModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        return Model;

        // /////////
    }
})();
