(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionInventoryHeader', {
        templateUrl: 'app/components/pagedetails/details/sections/inventory/subsections/header/header.html',
        controller: HeaderController,
        controllerAs: 'vm',
        bindings: {
            pageObj: '<',
        },
    });

    HeaderController.$inject = ['$controller', 'PageRepo'];
    /* @ngInject */
    function HeaderController ($controller, PageRepo) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.pageObj = vm.pageObj || {};
            vm.data = [];
            vm.search = null;
            getPage();
        }

        function getPage () {
            vm.selected = [];
            var params = {
                type: 'header',
                page_size: vm.pageSize,
                page: vm.page,
                search: vm.search,
            };

            vm.promise = PageRepo.getMetadata(vm.pageObj.id, params).then(function (data) {
                vm.data = data;
            }, angular.noop);
        }
    }
})();
