(function () {
    'use strict';
    angular.module('pages.admin.domain').service('AdminDomainsService', AdminDomainsService);

    AdminDomainsService.$inject = [
        'ng2MonModalService',
        'domainService',
        'gettextCatalog',
        'FormDomainScriptGuideService',
    ];
    /* @ngInject*/
    function AdminDomainsService (ng2MonModalService, domainService, gettextCatalog, FormDomainScriptGuideService) {
        return {
            updateDomainSettings: updateDomainSettings,
        };

        // ///////////////

        function updateDomainSettings (domain) {
            if (FormDomainScriptGuideService.isScriptBlockEdited()) {
                var settings = FormDomainScriptGuideService.getDomainSettings();
                ng2MonModalService
                    .confirm(gettextCatalog.getString('Do you want to update the Script Settings, for this Domain?'))
                    .then(function () {
                        updateDomain(domain, {
                            settings: {
                                scripts: settings,
                            },
                        });
                    }, angular.noop);
            }
        }

        // PROTECTED

        function updateDomain (domain, domainParams) {
            return domainService.update(domain.id, domainParams);
        }
    }
})();
