export const PAGE_PERFORMANCE_LOCATIONS = {
    'asia-east1': {
        city: 'Changhua County',
        country: 'Taiwan',
        country_code: 'tw',
    },
    'asia-east2': {
        city: 'Hong Kong',
        country: 'Hong Kong',
        country_code: 'hk',
    },
    'asia-northeast1': {
        city: 'Tokyo',
        country: 'Japan',
        country_code: 'jp',
    },
    'asia-northeast2': {
        city: 'Osaka',
        country: 'Japan',
        country_code: 'jp',
    },
    'asia-south1': {
        city: 'Mumbai',
        country: 'India',
        country_code: 'in',
    },
    'asia-southeast1': {
        city: 'Jurong West',
        country: 'Singapore',
        country_code: 'sg',
    },
    'australia-southeast1': {
        city: 'Sydney',
        country: 'Australia',
        country_code: 'au',
    },
    'europe-north1': {
        city: 'Hamina',
        country: 'Finland',
        country_code: 'fi',
    },
    'europe-west1': {
        city: 'St. Ghislain',
        country: 'Belgium',
        country_code: 'be',
    },
    'europe-west2': {
        city: 'London',
        country: 'England, UK',
        country_code: 'gb',
    },
    'europe-west3': {
        city: 'Frankfurt',
        country: 'Germany',
        country_code: 'de',
    },
    'europe-west4': {
        city: 'Eemshaven',
        country: 'Netherlands',
        country_code: 'nl',
    },
    'europe-west6': {
        city: 'Zürich',
        country: 'Switzerland',
        country_code: 'ch',
    },
    'northamerica-northeast1': {
        city: 'Montréal, Québec',
        country: 'Canada',
        country_code: 'ca',
    },
    'southamerica-east1': {
        city: 'Osasco (São Paulo)',
        country: 'Brazil',
        country_code: 'br',
    },
    'us-central1': {
        city: 'Council Bluffs, Iowa',
        country: 'USA',
        country_code: 'us',
    },
    'us-east1': {
        city: 'Moncks Corner, South Carolina',
        country: 'USA',
        country_code: 'us',
    },
    'us-east4': {
        city: 'Ashburn, Northern Virginia',
        country: 'USA',
        country_code: 'us',
    },
    'us-west1': {
        city: 'The Dalles, Oregon',
        country: 'USA',
        country_code: 'us',
    },
    'us-west2': {
        city: 'Los Angeles, California',
        country: 'USA',
        country_code: 'us',
    },
} as const;
