(function () {
    'use strict';

    angular.module('services.api').factory('ClarityRepo', ClarityRepo);

    ClarityRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function ClarityRepo (ng2ApiService, ng2SessionService) {
        return {
            getAllChecks: getAllChecks,
            getSummary: getSummary,
        };

        // /////////////

        function getAllChecks (params, config) {
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/clarity/checks', params, config);
        }

        function getSummary (params, config) {
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/clarity/summary', params, config);
        }
    }
})();
