(function () {
    'use strict';
    angular.module('app.forms').service('FormDomainScriptGuideService', FormDomainScriptGuideService);

    FormDomainScriptGuideService.$inject = [
        '$deepmerge',
        'rx',
        'ng2MonModalService',
        '$state',
        'gettextCatalog',
        'ng2MonEventsService',
        'ng2MonUIRouterTransitionService',
    ];

    /* @ngInject*/
    function FormDomainScriptGuideService (
        $deepmerge,
        rx,
        ng2MonModalService,
        $state,
        gettextCatalog,
        monEventsService,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;
        vm.domain = null;
        vm.editedScriptBlock = false;
        vm.scriptSettings = {};
        vm.isPageAssistEdited = false;
        vm.showScriptObs = new rx.BehaviorSubject(true);

        function init () {
            monEventsService.addListener('change-editedScriptBlock-status', function () {
                vm.editedScriptBlock = false;
            });

            return {
                setShowScript: setShowScript,
                setDomain: setDomain,
                getDomain: getDomain,
                getParameters: getParameters,
                getCookieBannerParameters: getCookieBannerParameters,
                isScriptBlockEdited: isScriptBlockEdited,
                setScriptSettingBlock: setScriptSettingBlock,
                getDomainSettings: getDomainSettings,
                setPageAssistEdit: setPageAssistEdit,
                setCookieBannerEdit: setCookieBannerEdit,
                tab: tab,
                showScriptObs: vm.showScriptObs,
                confirmOpenCookieOverviewPagePopup: confirmOpenCookieOverviewPagePopup,
            };
        }

        return init();

        function setShowScript (value) {
            vm.showScriptObs.onNext(value);
        }

        function setDomain (domain) {
            vm.domain = domain;
            vm.editedScriptBlock = false;
            vm.isPageAssistEdited = false;
        }

        function setPageAssistEdit (pageAssistModel) {
            if (pageAssistModel && pageAssistModel.legacySettings) {
                vm.scriptSettings.page_assist = pageAssistModel.legacySettings;
            }
            if (pageAssistModel && pageAssistModel.settings) {
                vm.scriptSettings.page_assist_v2 = pageAssistModel.settings;
            }
        }

        function setCookieBannerEdit (cookieBannerModel) {
            vm.scriptSettings.cookie_banner = cookieBannerModel.settings;
        }

        function getDomain () {
            return $deepmerge({}, vm.domain);
        }

        function isScriptBlockEdited () {
            return vm.editedScriptBlock || vm.isPageAssistEdited;
        }

        function setScriptSettingBlock (key, setting, manualEdit) {
            if (manualEdit) {
                vm.editedScriptBlock = true;
            }
            vm.scriptSettings[key] = setting;
        }

        function getDomainSettings () {
            return vm.scriptSettings;
        }

        function getParameters (domain, pageAssistModel, options) {
            var parameters = '';
            var statisticsOptions;
            var heatmapsOptions;
            var pageFixOptions;
            var pageAssistOptions;
            options = options || {};
            statisticsOptions = options.statistics || {};
            heatmapsOptions = options.heatmaps || {};
            pageFixOptions = options.pageFix || {};
            pageAssistOptions = options.pageAssist || {};
            parameters += tab(2) + 'token: "' + domain.token + '",\n';
            // STATISTICS START
            if (statisticsOptions.value) {
                parameters += tab(2) + 'statistics: {\n';
                parameters += tab(3) + 'enabled: true,\n';
                parameters += tab(3) + 'cookieLessTracking: ' + statisticsOptions.cookieLessTracking + ',\n';
                parameters += tab(3) + 'documentTracking: {\n';
                parameters += tab(4) + 'enabled: ' + statisticsOptions.documentTracking + ',\n';
                parameters += tab(4) + 'documentCls: "' + statisticsOptions.documentClass + '",\n';
                parameters += tab(4) + 'documentIgnoreCls: "' + statisticsOptions.documentIgnoreClass + '",\n';
                parameters += tab(4) + 'documentExt: ' + stringify(statisticsOptions.filledDocumentExtensions) + ',\n';
                parameters += tab(3) + '},\n';
                parameters += tab(2) + '},\n';
            }
            // STATISTICS END

            // HEATMAP START
            if (heatmapsOptions.value) {
                parameters += tab(2) + 'heatmap: {\n';
                parameters += tab(3) + 'enabled: true,\n';
                parameters += tab(2) + '},\n';
            }
            // HEATMAP END

            // PAGECORRECT START
            if (pageFixOptions.value) {
                parameters += tab(2) + 'pageCorrect: {\n';
                parameters += tab(3) + 'enabled: true,\n';
                parameters += tab(2) + '},\n';
            }
            // PAGECORRECT END

            // PAGEASSIST START
            if (pageAssistOptions.accessToDomain && pageAssistModel.getVersionState() === 'v2' && pageAssistModel.getIsEnabled()) {
                parameters += tab(2) + 'pageAssistV2: {\n';
                parameters += tab(3) + 'enabled: true,\n';
                parameters += tab(3) + 'theme: "' + pageAssistModel.settings.theme + '",\n';
                parameters += tab(3) + 'mainColor: "' + pageAssistModel.settings.mainColor + '",\n';
                parameters += tab(3) + 'textColor: "' + pageAssistModel.settings.textColor + '",\n';
                parameters += tab(3) + 'linkColor: "' + pageAssistModel.settings.linkColor + '",\n';
                parameters += tab(3) + 'buttonHoverColor: "' + pageAssistModel.settings.buttonHoverColor + '",\n';
                parameters += tab(3) + 'mainDarkColor: "' + pageAssistModel.settings.mainDarkColor + '",\n';
                parameters += tab(3) + 'textDarkColor: "' + pageAssistModel.settings.textDarkColor + '",\n';
                parameters += tab(3) + 'linkColorDark: "' + pageAssistModel.settings.linkColorDark + '",\n';
                parameters += tab(3) + 'buttonHoverDarkColor: "' + pageAssistModel.settings.buttonHoverDarkColor + '",\n';
                if (pageAssistModel.settings.mainButtonBorderColorEnabled) {
                    parameters += tab(3) + 'mainButtonBorderColor: "' + pageAssistModel.settings.mainButtonBorderColor + '",\n';
                }
                parameters += tab(3) + 'greeting: "' + (pageAssistModel.settings.greeting || '') + '",\n';
                parameters += tab(3) + 'direction: "' + pageAssistModel.settings.direction + '",\n';
                parameters +=
                    tab(3) +
                    'coordinates: "' +
                    pageAssistModel.settings.pixelsOnTop +
                    ' ' +
                    pageAssistModel.settings.pixelsOnRight +
                    ' ' +
                    pageAssistModel.settings.pixelsOnBottom +
                    ' ' +
                    pageAssistModel.settings.pixelsOnLeft +
                    '",\n';
                parameters += tab(3) + 'iconShape: "' + pageAssistModel.settings.icon_shape + '",\n';
                parameters += tab(3) + 'title: "' + pageAssistModel.settings.title + '",\n';
                parameters += tab(3) + 'titleText: "' + pageAssistModel.settings.title_text + '",\n';
                parameters += tab(3) + 'iconPictureUrl: "' + pageAssistModel.settings.icon_picture_url + '",\n';
                parameters += tab(3) + 'logoPictureUrl: "' + pageAssistModel.settings.logo_picture_url + '",\n';
                parameters += tab(3) + 'logoPictureBase64: "' + pageAssistModel.settings.logo_picture_base64 + '",\n';
                parameters += tab(3) + 'languages: ["' + pageAssistModel.settings.included_languages.join('", "') + '"],\n';
                parameters += tab(3) + 'defaultLanguage: "' + (pageAssistModel.settings.language || '') + '",\n';
                parameters += tab(3) + 'skipTo: ' + pageAssistModel.settings.skip_to + ',\n';
                if (pageAssistModel.settings.switchAccessibilityStatementURL) {
                    parameters +=
                        tab(3) + 'accessibilityStatementURL: "' + (pageAssistModel.settings.accessibilityStatementURL || '') + '",\n';
                }
                if (pageAssistModel.settings.customInformationTextEnabled) {
                    parameters += tab(3) + 'customInformationText: "' + (pageAssistModel.settings.customInformationText || '') + '",\n';
                }
                parameters += tab(3) + 'alwaysOnTop: ' + pageAssistModel.settings.alwaysOnTop + ',\n';
                parameters += tab(2) + '},\n';
            }

            if (pageAssistOptions.accessToDomain && pageAssistModel.getVersionState() === 'v1' && pageAssistModel.getIsEnabled()) {
                parameters += tab(2) + 'pageAssist: {\n';
                parameters += tab(3) + 'enabled: true,\n';
                parameters += tab(3) + 'theme: "' + pageAssistModel.legacySettings.theme + '",\n';
                parameters += tab(3) + 'direction: "' + pageAssistModel.legacySettings.direction + '",\n';
                parameters += tab(3) + 'skipTo: ' + pageAssistModel.legacySettings.skip_to + ',\n';
                parameters += tab(2) + '},\n';
            }
            // PAGEASSIST END
            return parameters;
        }

        function getCookieBannerParameters (domain, cookieBannerModel, options) {
            var parameters = '';
            var cookieBannerOptions;
            var cmSettings = cookieBannerModel.settings;
            options = options || {};
            cookieBannerOptions = options.cookieBanner || {};
            parameters += tab(2) + 'token: "' + domain.token + '",\n';

            if (cmSettings) {
                if (cookieBannerOptions.accessToDomain) {
                    parameters += tab(2) + 'privacyRegulation: ' + JSON.stringify(cmSettings.privacyRegulation) + ',\n';
                    // settings
                    parameters += tab(2) + 'settings: {\n';
                    parameters += tab(3) + 'manualStartup: false,\n';
                    parameters += tab(3) + 'hideOnAccepted: false,\n';
                    parameters += tab(3) + 'perCategoryConsent: ' + cmSettings.per_category_consent + ',\n';
                    parameters += tab(3) + 'explicitRejectOption: ' + cmSettings.explicit_reject_option + ',\n';
                    parameters += tab(3) + 'hasOverlay: ' + cmSettings.hasOverlay + ',\n';
                    parameters += tab(2) + '},\n';
                    // i18n
                    parameters += tab(2) + 'i18n: {\n';
                    parameters += tab(3) + 'languages: ["' + cmSettings.included_languages.join('", "') + '"], \n';
                    parameters += tab(3) + 'defaultLanguage: "' + cmSettings.defaultLanguage + '"\n';
                    parameters += tab(2) + '},\n';
                    // theme
                    parameters += tab(2) + 'theme: {\n';
                    parameters += tab(3) + 'buttonColor: "' + cmSettings.theme.buttonColor + '",\n';
                    parameters += tab(3) + 'buttonTextColor: "' + cmSettings.theme.buttonTextColor + '",\n';
                    parameters += tab(3) + 'iconPictureUrl: "' + cmSettings.icon_picture_url + '",\n';
                    parameters += tab(3) + 'iconShape: "' + cmSettings.icon_shape + '",\n';
                    parameters += tab(3) + 'position: "' + cmSettings.direction + '",\n';
                    parameters += tab(2) + '},\n';
                    // links
                    parameters += tab(2) + 'links: {\n';
                    parameters += tab(3) + 'cookiePolicyUrl: "' + (cmSettings.cookie_policy_url || '') + '",\n';
                    if (cmSettings.cookieOverviewLinkEnabled) {
                        parameters += tab(3) + 'linkToCookieOverview: "' + (cmSettings.linkToCookieOverview || '') + '",\n';
                    }

                    parameters += tab(3) + 'optOutUrl: "' + cmSettings.optOutUrl + '",\n';
                    parameters += tab(2) + '},\n';
                }
                parameters += tab(1) + '};\n';
                if (cmSettings.add_empty_callback) {
                    parameters += '\n';
                    parameters += tab(1) + 'window.MonsidoCookieOnLoad = function() {};\n';
                    parameters += tab(1) + 'window.MonsidoCookieOnShow = function() {};\n';
                    parameters += tab(1) + 'window.MonsidoCookieOnHide = function() {};\n';
                    parameters += tab(1) + 'window.MonsidoCookieOnAccept = function() {};\n';
                }

                if (cmSettings.add_empty_event_listeners) {
                    parameters += '\n';
                    parameters += tab(1) + 'window.addEventListener(\'MonsidoCookieOnLoad\', function() {});\n';
                    parameters += tab(1) + 'window.addEventListener(\'MonsidoCookieOnShow\', function() {});\n';
                    parameters += tab(1) + 'window.addEventListener(\'MonsidoCookieOnHide\', function() {});\n';
                    parameters += tab(1) + 'window.addEventListener(\'MonsidoCookieOnAccept\', function() {});\n';
                }
            }

            return parameters;
        }

        function stringify (value) {
            return JSON.stringify(value);
        }

        function tab (times) {
            times = times || 1;
            var result = '';
            for (var i = 0; i < times; i++) {
                result = result + '&nbsp;&nbsp;&nbsp;&nbsp;';
            }
            return result;
        }

        function confirmOpenCookieOverviewPagePopup (domainId, PageHref, isLiteUser) {
            var text;
            text = isLiteUser ? gettextCatalog.getString('Consent Manager') : gettextCatalog.getString('Data Privacy');
            ng2MonModalService.customDialog({
                title: gettextCatalog.getString('Finish the setup by adding your cookies'),
                message:
                    gettextCatalog.getString('To finish setting up your manual Cookie Overview go to the selected domain’s ') + text + '.',
                buttons: [
                    {
                        label: gettextCatalog.getString('I’ll do it later'),
                        className: 'btn btn-secondary',
                        value: 'openDataPrivacy',
                    },
                    {
                        label: text,
                        className: 'btn btn-primary',
                        value: 'close',
                        callback: function () {
                            return ng2MonUIRouterTransitionService.onTransitionFinished(() =>
                                $state.go(PageHref, {
                                    domainId: domainId,
                                }),
                            );
                        },
                    },
                ],
            });
        }
    }
})();
