import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.seo').component('seoSummaryCommonOpportunities', {
        templateUrl: 'app/pages/seo/summary/blocks/common-opportunities/common-opportunities.html',
        controller: SeoSummaryCommonOpportunitiesController,
        controllerAs: 'vm',
    });

    SeoSummaryCommonOpportunitiesController.$inject = [
        'SeoRepo',
        'API_CONSTANTS',
        'ng2MonEventsService',
        'ng2SessionService',
        'seoService',
    ];
    /* @ngInject */
    function SeoSummaryCommonOpportunitiesController (SeoRepo, API_CONSTANTS, ng2MonEventsService, ng2SessionService, seoService) {
        var vm = this;
        vm.ignoreIssue = ignoreIssue;
        vm.getPagesFromSeoIssue = getPagesFromSeoIssue;
        vm.$onInit = activate;

        function activate () {
            vm.seoClassifications = API_CONSTANTS.SEO.CLASSIFICATION;
            vm.pageCount = 0;
            vm.seoIssuesHighestPageCount = 0;
            vm.loading = true;
            if (ng2SessionService.domain.crawl_history) {
                vm.pageCount = ng2SessionService.domain.crawl_history.page_count;
                vm.pageCount = vm.pageCount + ng2SessionService.domain.crawl_history.documents_count;
            }
            getSeoIssues();
        }

        function getSeoIssues () {
            var params = {
                page_size: 7,
                page: 1,
                sort_by: 'pages_count',
                sort_dir: 'desc',
                status: 'active',
            };

            SeoRepo.getAll(params)
                .then(function (data) {
                    if (data.length > 0) {
                        vm.seoIssuesHighestPageCount = data[0].pages_count;
                    }
                    vm.seoIssues = data.filter(function (data) {
                        return data.pages_count > 0;
                    });
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function ignoreIssue (issue) {
            issue.ignored = true;
            seoService.update(issue.id, issue).then(function () {
                getSeoIssues();
            }, angular.noop);
        }

        function getPagesFromSeoIssue (issue) {
            var dialogBody;

            switch (issue.name) {
                case 'seo_duplicate_title':
                case 'seo_duplicate_h1':
                    dialogBody = 'seoDialogPagesWithDuplicateTitle';
                    break;
                default:
                    dialogBody = 'pagesFromSeoIssues';
            }

            var params = {
                body: dialogBody,
                size: 'lg',
                data: {
                    issue: issue,
                    pageCount: vm.pageCount,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
