(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PagePerformanceReport
     * @description Service to handle Page Performance Report
     */
    angular.module('services.entities').factory('PagePerformanceReport', PagePerformanceReport);

    PagePerformanceReport.$inject = ['PagePerformanceReportRepo'];
    /* @ngInject*/
    function PagePerformanceReport (PagePerformanceReportRepo) {
        return {
            getAll: getAll,
        };

        function getAll (params, config) {
            return PagePerformanceReportRepo.getAll(params, config);
        }
    }
})();
