(function () {
    'use strict';

    angular.module('modules.data_privacy.dialogs').component('privacyDialogsHelpCenter', {
        templateUrl: 'app/modules/data-privacy/dialogs/help-center/help-center.html',
        controller: DataPrivacyHelpCenterController,
        controllerAs: 'vm',
        bindings: {
            infoType: '<',
        },
    });

    DataPrivacyHelpCenterController.$inject = ['DataPrivacyService', 'DataPrivacyHelpCenterRepo'];
    /* @ngInject */
    function DataPrivacyHelpCenterController (DataPrivacyService, DataPrivacyHelpCenterRepo) {
        var vm = this;
        vm.$onInit = activate;
        vm.formatText = DataPrivacyService.formatText;
        vm.formatTextArray = DataPrivacyService.formatTextArray;

        function activate () {
            vm.infoType.description = '';
            vm.infoType.how_to_mitigate = '';

            var params = {
                identifier: vm.infoType.identifier,
            };

            DataPrivacyHelpCenterRepo.get(params).then(function (res) {
                if (Array.isArray(res) && res.length > 0) {
                    vm.infoType.description = res[0].description;
                    vm.infoType.how_to_mitigate = res[0].how_to_mitigate;
                }
            });
        }
    }
})();
