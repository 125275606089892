import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.uptimes').component('uptimesOverview', {
        templateUrl: 'app/pages/uptimes/uptimes.html',
        controller: UptimesOverviewController,
        controllerAs: 'vm',
    });

    UptimesOverviewController.$inject = [
        'UptimeRepo',
        'ng2MonEventsService',
        '$controller',
        '$location',
        'ParamService',
        '$state',
        'ng2MonModalService',
        'uptimeService',
        'ng2MonUIRouterTransitionService',
        'gettextCatalog',
    ];
    /* @ngInject */
    function UptimesOverviewController (
        UptimeRepo,
        ng2MonEventsService,
        $controller,
        $location,
        ParamService,
        $state,
        ng2MonModalService,
        uptimeService,
        ng2MonUIRouterTransitionService,
        gettextCatalog,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openUptime = openUptime;
        vm.uptimeShow = uptimeShow;
        vm.uptimeDelete = uptimeDelete;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.loading = true;
            vm.uptimes = [];
            vm.date = {
                startDate: '',
                endDate: '',
            };

            loadLocations();
            getPage();
        }

        function loadLocations () {
            vm.search = $location.search().search || 'all';
            vm.sortBy = $location.search().sort_by || '';
            vm.sortDirection = $location.search().sort_dir || '';
            vm.activeTab = $location.search().status || 'active';
            vm.date.startDate = vm.date.startDate || moment().startOf('day')
                .subtract(7, 'days');
            vm.date.endDate = vm.date.endDate || moment().subtract(1, 'days')
                .endOf('day');
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.sortBy || null,
                sort_dir: vm.sortDirection || null,
                search: vm.search || null,
                status: vm.activeTab || null,
                start_date: vm.date.startDate,
                end_date: vm.date.endDate,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                status: vm.activeTab,
                search: vm.activeTab === 'filtered' ? vm.search : null,
                sort_by: vm.sortBy,
                sort_direction: vm.sortDirection,
                start_date: vm.date.startDate.format('YYYY-MM-DD'),
                end_date: vm.date.endDate.format('YYYY-MM-DD'),
            };
            vm.promise = UptimeRepo.getAll(params)
                .then(function (data) {
                    vm.uptimes = data;
                    updateDropdowns();
                }, angular.noop)
                .finally(function () {
                    if (vm.uptimes.length === 1) {
                        uptimeShow(vm.uptimes[0]);
                    } else {
                        vm.loading = false;
                    }
                });
        }

        function updateDropdowns () {
            for (const uptime of vm.uptimes) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faCog',
                        action: ()=>openUptime(uptime),
                        eventParams: { action: 'heartbeat-edit' },
                    },
                    {
                        label: gettextCatalog.getString('Remove checkpoint'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>uptimeDelete(uptime),
                        eventParams: { action: 'heartbeat-remove-check' },
                    },
                ];

                uptime.dropdown = dropdown.map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                            item.action();
                        },
                    };
                });
            }
        }

        function openUptime (uptime) {
            var params = {
                body: 'uptime',
                size: 'md',
                data: {
                    uptime: uptime,
                },
            };
            const callback = function (uptime) {
                if (angular.isObject(uptime)) {
                    getPage();
                }
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function uptimeDelete (uptime) {
            var msg = 'Are you sure you want to delete the checkpoint for <strong>' + uptime.url + '</strong>?';
            ng2MonModalService.confirm(msg).then(function () {
                uptimeService.destroy(uptime.id).then(function () {
                    getPage();
                }, angular.noop);
            }, angular.noop);
        }

        function uptimeShow (uptime) {
            var to = 'base.customer.domain.uptimes.show';
            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(to, { uptimeId: uptime.id }),
            );
        }
    }
})();
