(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditDisplayBinaryService
     * @description Service to handle data of the Page Performance display "binary"
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceAuditDisplayBinaryService', PagePerformanceAuditDisplayBinaryService);

    PagePerformanceAuditDisplayBinaryService.$inject = ['PagePerformanceAuditDetailsService'];
    /* @ngInject*/
    function PagePerformanceAuditDisplayBinaryService (PagePerformanceAuditDetailsService) {
        return {
            getFormatAuditItem: getFormatAuditItem,
            isDetailTypeTable: isDetailTypeTable,
            getTableHeadingClass: getTableHeadingClass,
        };

        function isDetailTypeTable (type) {
            return PagePerformanceAuditDetailsService.isTypeTable(type);
        }

        function getFormatAuditItem (item, heading) {
            return PagePerformanceAuditDetailsService.getFormatAuditItem(item, heading);
        }

        function getTableHeadingClass (heading) {
            return PagePerformanceAuditDetailsService.getTableHeadingClass(heading);
        }
    }
})();
