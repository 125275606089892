(function () {
    'use strict';

    function CookieBannerConsentModel (attributes) {
        angular.extend(this, attributes);
    }

    angular.module('modules.cookie_banner.services.api').service('CookieBannerRepo', CookieBannerRepo);

    CookieBannerRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function CookieBannerRepo (ng2ApiService, ng2SessionService) {
        return {
            getConsents: getConsents,
            getStatistics: getStatistics,
        };

        // /////////////

        function getConsents (params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/cookie_banner/consents', params, config).then(
                toConsentModels,
            );
        }

        function getStatistics (params, config) {
            config = config || {};
            params = params || {};

            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/cookie_banner/statistics', params, config).then(
                toConsentModels,
            );
        }

        // PROTECTED

        function toConsentModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toConsentModel(models[i]);
            }

            return models;
        }

        function toConsentModel (model) {
            return new CookieBannerConsentModel(model);
        }
    }
})();
