export const generateRandomString = (): string => (Math.random() + 1).toString(36).substring(7);
export const objectToArray = (object: {[key: string]: unknown} | unknown[]): unknown[] => {
    if (Array.isArray(object)) {
        return object;
    }
    return Object.keys(object).map(key => object[key]);
};
export const isStringContainsLineBreak = (str?: unknown): boolean => {
    if (typeof str === 'string') {
        return str.includes('\n');
    }
    return false;
};

export const stringifyRequestParams = <T extends Record<string, unknown>>(params?: T): Record<string, string> => {
    const requestParams: Record<string, string> = {};

    Object.keys((params || {})).forEach(k => {
        requestParams[k] = String(requestParams[k]);
    });

    return requestParams;
};
