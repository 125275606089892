(function () {
    'use strict';

    angular.module('app.forms').component('formDomainUsers', {
        templateUrl: 'app/forms/domain/steps/users/user.html',
        controller: FormDomainUsersController,
        controllerAs: 'vm',
        bindings: {
            domain: '=',
            users: '<',
        },
    });

    FormDomainUsersController.$inject = ['Lodash'];
    /* @ngInject */
    function FormDomainUsersController (Lodash) {
        var vm = this;
        vm.$onInit = activate;
        vm.getDomainUserId = getDomainUserId;
        vm.isVisible = isVisible;
        vm.hasSendReport = hasSendReport;
        vm.toggleVisible = toggleVisible;
        vm.toggleSendReport = toggleSendReport;

        function activate () {
            vm.domain.domain_users = vm.domain.domain_users || [];
        }

        function getDomainUserId (user) {
            var index = Lodash.findIndex(vm.domain.domain_users, function (domainUser) {
                return domainUser.user_id === user.id;
            });

            if (index === -1) {
                setDomainUser(user);
                return vm.domain.domain_users.length - 1;
            } else {
                return index;
            }
        }

        function setDomainUser (user) {
            vm.domain.domain_users.push({
                user_id: user.id,
                visible: Boolean(user.customer_admin),
                send_report: false,
            });
        }

        function isVisible (user) {
            var index = getDomainUserId(user);
            return user.customer_admin || Boolean(vm.domain.domain_users[index].visible);
        }

        function hasSendReport (user) {
            var index = getDomainUserId(user);
            return isVisible(user) && Boolean(vm.domain.domain_users[index].send_report);
        }

        function toggleVisible (user) {
            var index = getDomainUserId(user);
            vm.domain.domain_users[index].visible = !vm.domain.domain_users[index].visible;
        }

        function toggleSendReport (user) {
            var index = getDomainUserId(user);
            vm.domain.domain_users[index].send_report = !vm.domain.domain_users[index].send_report;
        }
    }
})();
