(function () {
    'use strict';

    angular.module('models').factory('SpellingModel', SpellingModel);
    SpellingModel.$inject = ['PAGE_FIX_FIXABLE_TYPES', 'PageFixModelHelperService'];
    function SpellingModel (PAGE_FIX_FIXABLE_TYPES, PageFixModelHelperService) {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getPageFixType = getPageFixType;
        Model.prototype.getPageFixSelector = getPageFixSelector;

        return Model;

        // /////////

        function getPageFixType () {
            return PAGE_FIX_FIXABLE_TYPES.misspelling;
        }

        function getPageFixSelector () {
            return PageFixModelHelperService.getSelectorValue(this.word);
        }
    }
})();
