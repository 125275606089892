(function () {
    'use strict';

    angular.module('services.api').factory('ShortcutRepo', ShortcutRepo);

    ShortcutRepo.$inject = ['ng2ApiService', 'ShortcutModel'];
    /* @ngInject */
    function ShortcutRepo (ng2ApiService, ShortcutModel) {
        return {
            getAll: getAll,
            create: create,
            get: get,
            update: update,
            destroy: destroy,
        };

        // /////////////

        function getAll (params, headers) {
            params = params || {};
            return ng2ApiService.get('shortcuts', params, headers).then(toModels);
        }

        function get (id, params, headers) {
            params = params || {};
            return ng2ApiService.get('shortcuts/' + id, params, headers).then(toModel);
        }

        function create (shortcut, headers) {
            shortcut = shortcut || {};
            return ng2ApiService.post('shortcuts', shortcut, headers).then(toModel);
        }

        function update (shortcut, headers) {
            shortcut = shortcut || {};
            return ng2ApiService.patch('shortcuts/' + shortcut.id, shortcut, null, headers).then(toModel);
        }

        function destroy (id, params, headers) {
            params = params || {};
            return ng2ApiService.delete('shortcuts/' + id, params, headers);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new ShortcutModel(model);
        }
    }
})();
