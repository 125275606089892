(function () {
    'use strict';

    angular.module('modules.chat.intercom').run([
        'intercomService',
        'chatSystems',
        'ng2MonEventsService',
        '$transitions',
        'Intercom',
        'extensionService',
        'ng2SessionService',
        function (intercomService, chatSystems, ng2MonEventsService, $transitions, Intercom, extensionService, ng2SessionService) {
            ng2MonEventsService.addListener('afterCustomer', function (account, agreement, me, isSudo) {
                if (!isSudo) {
                    Intercom.addScript();
                    var interComObj = {
                        app_id: chatSystems.intercom.intercomId,
                        custom_launcher_selector: '.mon_intercom_link',
                        hide_default_launcher: true,
                    };

                    extensionService.isInstalled().then(
                        function (res) {
                            if (me) {
                                interComObj.email = me.email;
                                interComObj.name = me.getName();
                                interComObj.user_id = me.id;
                                interComObj.user_hash = me.intercom_user_hash;
                                interComObj.have_extension_installed = res;
                            }

                            intercomService.boot(interComObj);

                            var intercomSettings = {
                                company: {
                                    id: agreement.account.id,
                                    name: agreement.account.name,
                                },
                            };

                            intercomService.update(intercomSettings);
                        },
                        function () {
                            if (me) {
                                interComObj.email = me.email;
                                interComObj.name = me.getName();
                                interComObj.user_id = me.id;
                                interComObj.user_hash = me.intercom_user_hash;
                                interComObj.have_extension_installed = 'N/A';
                            }

                            intercomService.boot(interComObj);

                            var intercomSettings = {
                                company: {
                                    id: agreement.account.id,
                                    name: agreement.account.name,
                                },
                            };

                            intercomService.update(intercomSettings);
                        },
                    );
                } else {
                    intercomService.shutdown();
                }
                if (ng2SessionService.getChatSystemOptions() === 'intercom') {
                    $transitions.onSuccess({}, function () {
                        intercomService.update();
                    });

                    intercomService.onUnreadCountChange(function (count) {
                        if (count > 0) {
                            ng2MonEventsService.run('intercomUnread', count);
                        }
                    });

                    intercomService.onHide(function () {
                        ng2MonEventsService.run('intercomHide');
                    });

                    intercomService.onShow(function () {
                        ng2MonEventsService.run('intercomShow');
                    });
                }

                ng2MonEventsService.addListener('logout', function () {
                    intercomService.shutdown();
                });
            });
        },
    ]);
})();
