(function () {
    'use strict';

    angular.module('modules.roles').factory('RolesService', RolesService);

    RolesService.$inject = ['$interval', 'RolesApiService', 'rx', 'RoleSectionsApiService', '$q'];

    /* @ngInject */
    function RolesService ($interval, RolesApiService, rx, RoleSectionsApiService, $q) {
        var vm = this;
        vm.intervalPromise = null;
        var service = {
            roles: new rx.BehaviorSubject(null),
            userRoleSections: new rx.BehaviorSubject(null),
            init: init, // Initialize and reset all variables in the service
            destroy: destroy,
        };
        return service;

        // /////////////

        function init (user) {
            return setupRefresh(user);
        }

        function destroy () {
            service.roles.onNext(null);
            service.userRoleSections.onNext(null);
            if (vm.intervalPromise !== null) {
                $interval.cancel(vm.intervalPromise);
            }
            vm.intervalPromise = null;
        }

        // PROTECTED
        function setupRefresh (user) {
            var promises = [refreshRoles(), refreshUserRoleSections(user)];
            vm.intervalPromise = $interval(function () {
                refreshRoles();
                refreshUserRoleSections(user);
            }, 20000);
            return $q.all(promises);
        }

        function refreshRoles () {
            var params = {
                page_size: 0,
            };
            var config = {
                headers: {
                    noGlobal: true,
                },
            };
            return RolesApiService.getAll(params, config).then(function (roles) {
                service.roles.onNext(roles);
            }, angular.noop);
        }

        function refreshUserRoleSections (user) {
            return RoleSectionsApiService.getUserRoleSections(user.id).then(function (roleSections) {
                service.userRoleSections.onNext(roleSections);
            }, angular.noop);
        }
    }
})();
