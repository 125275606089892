(function () {
    'use strict';

    angular.module('filters.monsido').filter('isBeforeDate', IsBeforeDateFilter);

    IsBeforeDateFilter.$inject = ['moment'];

    /* @ngInject */
    function IsBeforeDateFilter (moment) {
        return isBeforeDate;

        // //////////

        function isBeforeDate (start, end) {
            if (angular.isDefined(start)) {
                end = angular.isDefined(end) ? moment(end) : moment();
                return moment(start).isAfter(end);
            }
        }
    }
})();
